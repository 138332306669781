import { ResponsiveBar } from "@nivo/bar"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"
import CustomLegend from "../../CustomLegend"
import { useMemo } from "react"
import Box from "@mui/material/Box"

const NivoGroupBarChart = ({
  reportTitle,
  reportData,
  chartInput,
  showAiDropdown,
  reportUid,
  defaultGroupBarLegendLabel,
  showLegend,
  showTooltipPrevious,
}) => {
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  let axisLeft = defaultGroupBarLegendLabel.axisLeft
  let axisBottom = defaultGroupBarLegendLabel.axisBottom

  const colorScheme = [
    "rgba(54, 162, 235, 1)",
    "rgba(161, 123, 200, 1)",
    "rgba(23, 162, 184, 1)",
    "rgba(0, 123, 255, 1)",
    "rgba(108, 117, 125, 1)",
    "rgba(255, 99, 132, 1)",
    "#FF85E1",

    "rgba(52, 58, 64, 1)",
    "rgba(233, 236, 239, 1)",
  ]

  // const lowestBarColor = "rgba(255, 99, 132, 1)"

  // const globalLowestEntry = useMemo(() => {
  //   let lowestValue = Infinity
  //   let lowestKey = null
  //   let lowestDay = null

  //   chartInput?.forEach((dayData) => {
  //     Object.keys(dayData).forEach((key) => {
  //       const value = dayData[key]
  //       if (value < lowestValue) {
  //         lowestValue = value
  //         lowestKey = key
  //         lowestDay = dayData.day
  //       }
  //     })
  //   })

  //   return { lowestValue, lowestKey, lowestDay }
  // }, [])

  const keys = useMemo(() => {
    const allKeys = new Set()

    chartInput?.forEach((item) => {
      Object.keys(item)?.forEach((key) => {
        if (key !== "day" && key !== "tooltipFields") {
          allKeys.add(key)
        }
      })
    })

    return Array.from(allKeys)
  }, [chartInput])

  const legendsData = useMemo(() => {
    if (!showLegend) return []

    return (
      keys?.map((item, index) => ({
        label: item,
        color: colorScheme[index % colorScheme.length],
      })) || []
    )
  }, [keys, showLegend])

  // Define the tooltip logic based on reportUid
  const tooltip =
    reportUid === "8D69C58A-3051-4701-968D-07C8CA186C12"
      ? ({ id, data }) => {
          const tooltipData = data?.tooltipFields?.[id] || {}
          return (
            <CustomTooltip
              data={tooltipData}
              showCurrentPrevDate={true}
              showTooltipPrevious={showTooltipPrevious}
            />
          )
        }
      : ({ id, data }) => {
          const index = { a: 0, b: 1, c: 2, d: 3, e: 4 }[id]
          const tooltipFields = data?.tooltipFields?.[index]
          if (!tooltipFields) return null
          const {
            day,
            currentDate,
            previousDate,
            reportType,
            tooltipData = {},
          } = tooltipFields

          const customTooltipData = {
            day,
            currentDate,
            previousDate,
            reportType,
            tooltipData: {
              current: tooltipData.current,
              ...(tooltipData.previous && { previous: tooltipData.previous }),
            },
          }

          return (
            <CustomTooltip
              data={customTooltipData}
              showCurrentPrevDate={true}
              showTooltipPrevious={showTooltipPrevious}
            />
          )
        }

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: "rgba(29, 71, 206, 0.3)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      <Box height="350px">
        <ResponsiveBar
          data={chartInput}
          keys={keys}
          indexBy="day"
          theme={customTheme}
          margin={{ top: 30, right: 40, bottom: 40, left: 80 }}
          padding={0.3}
          innerPadding={2}
          enableLabel={false}
          borderRadius={2}
          groupMode="grouped"
          // colors={({ id, data }) => {
          //   const { lowestValue, lowestKey, lowestDay } = globalLowestEntry

          //   if (data.day === lowestDay && id === lowestKey) {
          //     return lowestBarColor
          //   }

          //   return colorScheme[["a", "b", "c", "d", "e"].indexOf(id)]
          // }}
          colors={colorScheme}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: axisBottom,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: axisLeft,
            legendPosition: "middle",
            legendOffset: -50,
          }}
          tooltip={tooltip}
        />
      </Box>

      <CustomLegend
        legendsData={legendsData}
        reportTitle={reportTitle}
        reportData={chartInput}
        showAiDropdown={showAiDropdown}
      />
    </Box>
  )
}

export default NivoGroupBarChart
