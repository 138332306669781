import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { useTheme } from "../contexts/theme"
import Deletesvg from "../assets/svgs/Deletesvg"

const DeleteModal = ({ open, onCancel, onDelete }) => {
  const { theme } = useTheme()

  const colors = {
    accentColor: theme.palette.mode === "dark" ? "#F3884A" : "#1D47CE",
    accentHoverColor: theme.palette.mode === "dark" ? "#EB7E41" : "#1C3DBD",
  }

  return (
    <Modal open={open} onClose={onCancel}>
      <Stack
        display="flex"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        sx={{
          padding: "1.2rem",
          borderRadius: "10px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          minWidth: "400px",
        }}
        gap="20px"
      >
        <Deletesvg />
        <Typography variant="h5" component="div">
          Delete Item
        </Typography>
        <Typography variant="body1" color="inherit" sx={{ opacity: "80%" }}>
          Are you sure you want to delete this item?
        </Typography>

        <Box display="flex" flexDirection="row" gap="20px">
          <Button
            variant="contained"
            onClick={onCancel}
            sx={{
              backgroundColor: colors.accentColor,
              borderRadius: "20px",
              color: "white",
              textTransform: "none",
              "&:hover": {
                backgroundColor: colors.accentHoverColor,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onDelete}
            sx={{
              backgroundColor: colors.accentColor,
              borderRadius: "20px",
              color: "white",
              textTransform: "none",
              "&:hover": {
                backgroundColor: colors.accentHoverColor,
              },
            }}
          >
            Delete
          </Button>
        </Box>
      </Stack>
    </Modal>
  )
}

export default DeleteModal
