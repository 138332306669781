// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vendors_grid_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

@media (max-width: 600px) {
  .vendors_grid_item_1,
  .vendors_grid_item_2,
  .vendors_grid_item_3,
  .vendors_grid_item_4,
  .vendors_grid_item_5,
  .vendors_grid_item_6,
  .vendors_grid_item_other {
    grid-column: span 2;
  }
}
@media (min-width: 600px) {
  .vendors_grid_item_1,
  .vendors_grid_item_2 {
    grid-column: span 2;
  }
  .vendors_grid_item_3,
  .vendors_grid_item_4,
  .vendors_grid_item_5,
  .vendors_grid_item_6,
  .vendors_grid_item_other {
    grid-column: span 1;
  }
}
@media (min-width: 900px) {
  .vendors_grid_item_1,
  .vendors_grid_item_2 {
    grid-column: span 2;
  }
  .vendors_grid_item_3,
  .vendors_grid_item_4,
  .vendors_grid_item_5,
  .vendors_grid_item_6,
  .vendors_grid_item_other {
    grid-column: span 2;
  }
}
@media (min-width: 1100px) {
  .vendors_grid_item_1,
  .vendors_grid_item_2 {
    grid-column: span 2;
  }
  .vendors_grid_item_3,
  .vendors_grid_item_4,
  .vendors_grid_item_5,
  .vendors_grid_item_6,
  .vendors_grid_item_other {
    grid-column: span 1;
  }
}
@media (min-width: 1400px) {
  .vendors_grid_item_1,
  .vendors_grid_item_2 {
    grid-column: span 2;
  }
  .vendors_grid_item_3,
  .vendors_grid_item_4,
  .vendors_grid_item_5,
  .vendors_grid_item_6,
  .vendors_grid_item_other {
    grid-column: span 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/vendors/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AACF;;AAEA;EACE;;;;;;;IAOE,mBAAA;EACF;AACF;AAEA;EACE;;IAEE,mBAAA;EAAF;EAGA;;;;;IAKE,mBAAA;EADF;AACF;AAIA;EACE;;IAEE,mBAAA;EAFF;EAKA;;;;;IAKE,mBAAA;EAHF;AACF;AAMA;EACE;;IAEE,mBAAA;EAJF;EAOA;;;;;IAKE,mBAAA;EALF;AACF;AAQA;EACE;;IAEE,mBAAA;EANF;EASA;;;;;IAKE,mBAAA;EAPF;AACF","sourcesContent":[".vendors_grid_container {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 20px;\n}\n\n@media (max-width: 600px) {\n  .vendors_grid_item_1,\n  .vendors_grid_item_2,\n  .vendors_grid_item_3,\n  .vendors_grid_item_4,\n  .vendors_grid_item_5,\n  .vendors_grid_item_6,\n  .vendors_grid_item_other {\n    grid-column: span 2;\n  }\n}\n\n@media (min-width: 600px) {\n  .vendors_grid_item_1,\n  .vendors_grid_item_2 {\n    grid-column: span 2;\n  }\n\n  .vendors_grid_item_3,\n  .vendors_grid_item_4,\n  .vendors_grid_item_5,\n  .vendors_grid_item_6,\n  .vendors_grid_item_other {\n    grid-column: span 1;\n  }\n}\n\n@media (min-width: 900px) {\n  .vendors_grid_item_1,\n  .vendors_grid_item_2 {\n    grid-column: span 2;\n  }\n\n  .vendors_grid_item_3,\n  .vendors_grid_item_4,\n  .vendors_grid_item_5,\n  .vendors_grid_item_6,\n  .vendors_grid_item_other {\n    grid-column: span 2;\n  }\n}\n\n@media (min-width: 1100px) {\n  .vendors_grid_item_1,\n  .vendors_grid_item_2 {\n    grid-column: span 2;\n  }\n\n  .vendors_grid_item_3,\n  .vendors_grid_item_4,\n  .vendors_grid_item_5,\n  .vendors_grid_item_6,\n  .vendors_grid_item_other {\n    grid-column: span 1;\n  }\n}\n\n@media (min-width: 1400px) {\n  .vendors_grid_item_1,\n  .vendors_grid_item_2 {\n    grid-column: span 2;\n  }\n\n  .vendors_grid_item_3,\n  .vendors_grid_item_4,\n  .vendors_grid_item_5,\n  .vendors_grid_item_6,\n  .vendors_grid_item_other {\n    grid-column: span 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
