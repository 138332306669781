export const ReviewClassificationData = [
  {
    id: "Spam Reviews",
    value: 2432.56,
    tooltipData: {
      current: {
        "Total Reviews": {
          value: 80,
          format: "Number",
        },
        "Spam Detection Rate": {
          value: 95,
          format: "Percentage",
        },
      },
      previous: {
        "Total Reviews": {
          value: 70,
          format: "Number",
        },
        "Spam Detection Rate": {
          value: 90,
          format: "Percentage",
        },
      },
    },
  },
  {
    id: "Negative Reviews",
    value: 12345.89,
    tooltipData: {
      current: {
        "Total Reviews": {
          value: 745,
          format: "Number",
        },
        "Rating Average": {
          value: 2.1,
          format: "Rating",
        },
        "Negative Percentage": {
          value: 15,
          format: "Percentage",
        },
      },
      previous: {
        "Total Reviews": {
          value: 680,
          format: "Number",
        },
        "Rating Average": {
          value: 2.3,
          format: "Rating",
        },
        "Negative Percentage": {
          value: 18,
          format: "Percentage",
        },
      },
    },
  },
  {
    id: "Neutral Reviews",
    value: 13456.78,
    tooltipData: {
      current: {
        "Total Reviews": {
          value: 300,
          format: "Number",
        },
        "Rating Average": {
          value: 3.0,
          format: "Rating",
        },
        "Neutral Percentage": {
          value: 40,
          format: "Percentage",
        },
      },
      previous: {
        "Total Reviews": {
          value: 280,
          format: "Number",
        },
        "Rating Average": {
          value: 3.2,
          format: "Rating",
        },
        "Neutral Percentage": {
          value: 35,
          format: "Percentage",
        },
      },
    },
  },
  {
    id: "Positive Reviews",
    value: 15678.9,
    tooltipData: {
      current: {
        "Total Reviews": {
          value: 745,
          format: "Number",
        },
        "Rating Average": {
          value: 4.7,
          format: "Rating",
        },
        "Positive Percentage": {
          value: 85,
          format: "Percentage",
        },
      },
      previous: {
        "Total Reviews": {
          value: 680,
          format: "Number",
        },
        "Rating Average": {
          value: 4.6,
          format: "Rating",
        },
        "Positive Percentage": {
          value: 82,
          format: "Percentage",
        },
      },
    },
  },
  {
    id: "Mixed Reviews",
    value: 5678.9,
    tooltipData: {
      current: {
        "Total Reviews": {
          value: 180,
          format: "Number",
        },
        "Rating Average": {
          value: 3.5,
          format: "Rating",
        },
        "Mixed Percentage": {
          value: 10,
          format: "Percentage",
        },
      },
      previous: {
        "Total Reviews": {
          value: 160,
          format: "Number",
        },
        "Rating Average": {
          value: 3.6,
          format: "Rating",
        },
        "Mixed Percentage": {
          value: 12,
          format: "Percentage",
        },
      },
    },
  },
]
