import { useDispatch, useSelector } from "react-redux"
import DatePicker from "../../components/DatePicker"
import RouteTitle from "../../components/RouteTitle"
import { useEffect, useState } from "react"
import { getData } from "../../api/api"
import { loadData, setChartData } from "../../redux/slices/vendorSlice"
import CustomPaper from "../../components/CustomPaper"
import KPICard from "../../components/KPICard"
import Masonry from "@mui/lab/Masonry"
import Box from "@mui/material/Box"
import "./styles.scss"
import Table from "../../components/datagrid/Table"
import RouteLoader from "../../components/loader/RouteLoader"
import LineChart from "../../components/charts/line/LineChart"
import PieChart from "../../components/charts/pie/PieChart"
import BarChart from "../../components/charts/bar/BarChart"
import LineBarChart from "../../components/charts/lineBar/LineBarChart"
import HeatMapChart from "../../components/charts/heatmap/HeatMapChart"
import ScatterPlotChart from "../../components/charts/scatterplot/ScatterPlotChart"
import StackedBarChart from "../../components/charts/stackedBar/StackedBarChart"
import ComingSoon from "../../components/ComingSoon"
import Typography from "@mui/material/Typography"
import Logo from "../../assets/svgs/Logo"
import { useTheme } from "../../contexts/theme"
import ProgressBar from "../../components/charts/progressBar/ProgressBarChart"
import CompareBy from "../../components/CompareBy.jsx"
import DashLineChart from "../../components/charts/dashLine/DashLineChart"
import HorizontalBarChart from "../../components/charts/bar/horizontalBarGraph/HorizontalBarChart"

const Vendors = () => {
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const vendorsData = useSelector((state) => state.vendors)
  const dateRangeData = useSelector((state) => state.calendar)

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await getData("vendors", {})
      dispatch(loadData(response))
    } catch (error) {
      console.warn("Error fetching data:", error.message)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (vendorsData?.reports?.length === 0) {
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [vendorsData?.reports?.length])

  const countReports = vendorsData?.reports?.filter((report) => {
    return report.reportTypes.some(
      (type) => type.ReportType === "Count" || type.ReportType === "Calendar"
    )
  })

  const calendarReports = vendorsData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Calendar")
  })
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const otherReports = vendorsData?.reports?.filter(
    (report) => !report.reportTypes.some((type) => type.ReportType === "Count")
  )
  return (
    <>
      {/* <ComingSoon /> */}
      {isLoading ? (
        <RouteLoader />
      ) : (
        <>
          <RouteTitle title="Vendors">{/* <DatePicker /> */}</RouteTitle>

          <Box
            // height="100%"
            sx={{
              width: "100%",
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                gap: 1,
              }}
            >
              <Typography variant="h4">Coming soon..</Typography>
              <Logo
                fill={theme.palette.text.primary}
                yfill={
                  theme.palette.mode === "light"
                    ? theme.palette.accent
                    : "white"
                }
              />
              {/* 
              <CompareBy dateRangeData={dateRangeData} />

              <DatePicker /> */}
            </Box>
          </Box>
          {/* <Box
            padding="5px 20px 15px 20px"
            // sx={{
            //   position: "relative",
            // }}
          >
            <Masonry
              columns={{ xs: 1, sm: 1, md: 2 }}
              spacing={2}
              id="masonry"
              sx={{ margin: "0px" }}
            >
              <section className="vendors_grid_container">
                {countReports?.map((report, index) => (
                  <CustomPaper
                    key={report.report_uid}
                    className={
                      index > 5
                        ? "vendors_grid_item_other"
                        : `vendors_grid_item_${index + 1}`
                    }
                    watermarkBg={true}
                    CustomDrawerComponent={true}
                  >
                    <KPICard
                      title={report.reportName}
                      parameters={report.reportTypes[0].reportParameterTypes}
                      postUrl="vendors/data"
                      description={report.reportDescription}
                      dateRangeData={dateRangeData}
                      reportUid={report.report_uid}
                      width="100%"
                      height="6rem"
                      comparisonPeriod={comparisonPeriod}

                    />
                  </CustomPaper>
                ))}
              </section>
              {otherReports?.map(
                (report) =>
                  ({
                    Line: (
                      <CustomPaper key={report.report_uid}>
                        <LineChart
                          route="vendors"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="vendors/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Pie: (
                      <CustomPaper key={report.report_uid}>
                        <PieChart
                          route="vendors"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          comparisonPeriod={comparisonPeriod}

                          postUrl="vendors/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                        />
                      </CustomPaper>
                    ),
                    List: (
                      <Table
                        route="vendors"
                        title={report.reportName}
                        hidePageNumbers={true}
                        dateRangeData={dateRangeData}
                        reportUId={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        postUrl="vendors/list"
                      />
                    ),
                    Bar: (
                      <CustomPaper key={report.report_uid}>
                        <BarChart
                          route="vendors"
                          setChartData={setChartData}
                          comparisonPeriod={comparisonPeriod}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="vendors/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                        />
                      </CustomPaper>
                    ),
                    LineBar: (
                      <CustomPaper
                        key={report.report_uid}
                        // CustomDrawerComponent={
                        //   report.reportTypes[0].ReportType !== "List"
                        // }
                      >
                        <LineBarChart
                          route="vendors"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="vendors/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}

                        />
                      </CustomPaper>
                    ),
                    Heatmap: (
                      <CustomPaper key={report.report_uid}>
                        <HeatMapChart
                          route="vendors"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="vendors/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}

                        />
                      </CustomPaper>
                    ),
                    ProgressBar: (
                      <CustomPaper key={report.report_uid}>
                        <ProgressBar
                          route="vendors"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="vendors/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    ScatterPlot: (
                      <ScatterPlotChart
                        route="vendors"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="vendors/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}

                      />
                    ),
                    StackedBar: (
                      <StackedBarChart
                        route="vendors"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="vendors/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}
                      />
                    ),
                    DualHorizontalBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HorizontalBarChart
                          route="vendors"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="vendors/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    DashLine: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <DashLineChart
                          route="vendors"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="vendors/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                  }[report.reportTypes[0].ReportType])
              )}
            </Masonry>
            {calendarReports?.map(
              (report) =>
                ({
                  Calendar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{ overflow: "visible", margin: "10px" }}
                    >
                      <CalendarChart
                        route="vendors"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        comparisonPeriod={comparisonPeriod}
                        postUrl="vendors/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}
          </Box> */}
        </>
      )}
    </>
  )
}

export default Vendors
