import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import VerifiedIcon from "@mui/icons-material/Verified"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import ArrowRise from "../assets/svgs/ArrowRise"
import ArrowFall from "../assets/svgs/ArrowFall"

const StarLowPerformer = ({
  StarPerformer,
  lowPerformer,
  StarPerformerDate,
  lowPerformerDate,
  showCurrentPrevColors,
  StarPerformerLabel,
  lowPerformerLabel,
  chartType = "",
  percentageChange,
}) => {
  const isUptrend = percentageChange > 0
  return (
    <>
      <Box>
        {StarPerformer && (
          <>
            {chartType === "DashLine" || chartType === "NewVsRepeat" ? (
              <>
                <Box display="flex" gap="40px" mt={"10px"}>
                  <Box display="flex" alignItems="top" gap={"10px"}>
                    <Box
                      sx={{
                        width: "5px",
                        height: "19px",
                        borderRadius: "3px",
                        marginTop: "4px",
                        backgroundColor: showCurrentPrevColors?.current,
                      }}
                    />

                    <Box display="flex" flexDirection={"column"}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: showCurrentPrevColors?.current,
                        }}
                      >
                        {chartType === "NewVsRepeat"
                          ? StarPerformerLabel
                          : "Actual"}
                      </Typography>
                      <Typography color="#A6A6A6" sx={{ fontSize: "12px" }}>
                        <Box display="flex" alignItems={"center"}>
                          {`${(StarPerformer ?? "NA").replace(/[+-]/g, "")}`}
                          {StarPerformer &&
                            (StarPerformer.startsWith("+") ? (
                              <ArrowRise />
                            ) : StarPerformer.startsWith("-") ? (
                              <ArrowFall />
                            ) : null)}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="top" gap={"10px"}>
                    <Box
                      sx={{
                        width: "5px",
                        height: "19px",
                        borderRadius: "3px",
                        marginTop: "4px",
                        backgroundColor: showCurrentPrevColors?.previous,
                      }}
                    />

                    <Box display="flex" flexDirection={"column"}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: showCurrentPrevColors?.previous,
                        }}
                      >
                        {chartType === "NewVsRepeat"
                          ? lowPerformerLabel
                          : "Forecasted"}
                      </Typography>
                      <Typography color="#A6A6A6" sx={{ fontSize: "12px" }}>
                        <Box display="flex" alignItems="center">
                          {`${(lowPerformer ?? "NA").replace(/[+-]/g, "")}`}
                          {lowPerformer && (
                            <>
                              {lowPerformer.startsWith("+") ? (
                                <ArrowRise />
                              ) : lowPerformer.startsWith("-") ? (
                                <ArrowFall />
                              ) : null}
                            </>
                          )}
                        </Box>
                      </Typography>
                    </Box>

                    </Box>
              
                  <Box display="flex" alignItems="top" gap={"10px"}>
                    <Box
                      sx={{
                        width: "5px",
                        height: "19px",
                        borderRadius: "3px",
                        marginTop: "4px",
                        backgroundColor: "",
                      }}
                    />
                    
                    <Box display="flex" flexDirection={"column"}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: isUptrend ? "#23BF6F" : "#EC2D30" ,
                        }}
                      >
                        {chartType === "NewVsRepeat"
                          ? lowPerformerLabel
                          : "Change(%)"}
                      </Typography>
                      <Typography color="#A6A6A6" sx={{ fontSize: "12px" }}>
                        <Box display="flex" alignItems="center">
                          {chartType === "DashLine" &&
                            percentageChange !== undefined && (
                              <Box
                                ml={1}
                                display="flex"
                                justifyContent="center"
                                margin-left={0}
                              >
                                {`${(percentageChange ?? "NA").replace(
                                  /[+-]/g,
                                  ""
                                )}%`}
                                {isUptrend ? <ArrowRise /> : <ArrowFall />}
                              </Box>
                            )}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                  </Box>
              </>
            ) : (
              <>
                <Box display="flex" gap={"40px"}>
                  <Box display="flex" gap={"10px"}>
                    <VerifiedIcon
                      height={15}
                      width={15}
                      sx={{ fill: "#23BF6F" }}
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#23BF6F",
                        }}
                      >
                        {`${StarPerformer ?? "NA"}`}
                      </Typography>

                      <Typography color="#A6A6A6" sx={{ fontSize: "10px" }}>
                        {`${StarPerformerDate ?? "NA"}`}
                      </Typography>
                      <Typography color="#A6A6A6" sx={{ fontSize: "12px" }}>
                        Star Performer
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" gap={"10px"}>
                    <NewReleasesIcon
                      height={15}
                      width={15}
                      sx={{ fill: "#EC2D30" }}
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#EC2D30",
                        }}
                      >
                        {`${lowPerformer ?? "NA"}`}
                      </Typography>

                      <Typography color="#A6A6A6" sx={{ fontSize: "10px" }}>
                        {`${lowPerformerDate ?? "NA"}`}
                      </Typography>
                      <Typography color="#A6A6A6" sx={{ fontSize: "12px" }}>
                        Low Performer
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default StarLowPerformer
