export const CustomerDetailsBarChartData = [
  {
    WeekDayNumber: 1,
    id: "January",
    "Gross Sales": 16279.43,
    Customers: "404",
    Transactions: "666",
    "Average Ticket": "$24.44",
    "Return Amount": "$38.16",
    "Discount Amount": "$225.84",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 2,
    id: "February",
    "Gross Sales": 11485.24,
    Customers: "276",
    Transactions: "442",
    "Average Ticket": "$25.98",
    "Return Amount": "$12.20",
    "Discount Amount": "$197.09",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 3,
    id: "March",
    "Gross Sales": 8434.82,
    Customers: "226",
    Transactions: "306",
    "Average Ticket": "$27.56",
    "Return Amount": "$15.48",
    "Discount Amount": "$34.90",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 4,
    id: "April",
    "Gross Sales": 12943.94,
    Customers: "267",
    Transactions: "393",
    "Average Ticket": "$32.94",
    "Return Amount": "$61.24",
    "Discount Amount": "$785.86",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 5,
    id: "May",
    "Gross Sales": 13943.17,
    Customers: "314",
    Transactions: "519",
    "Average Ticket": "$26.87",
    "Return Amount": "$51.40",
    "Discount Amount": "$224.18",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 6,
    id: "June",
    "Gross Sales": 14037.91,
    Customers: "327",
    Transactions: "539",
    "Average Ticket": "$26.04",
    "Return Amount": "$32.69",
    "Discount Amount": "$184.13",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 7,
    id: "July",
    "Gross Sales": 17203.39,
    Customers: "402",
    Transactions: "611",
    "Average Ticket": "$28.16",
    "Return Amount": "$35.05",
    "Discount Amount": "$183.76",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 8,
    id: "August",
    "Gross Sales": 16279.43,
    Customers: "404",
    Transactions: "666",
    "Average Ticket": "$24.44",
    "Return Amount": "$38.16",
    "Discount Amount": "$225.84",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 9,
    id: "September",
    "Gross Sales": 11485.24,
    Customers: "276",
    Transactions: "442",
    "Average Ticket": "$25.98",
    "Return Amount": "$12.20",
    "Discount Amount": "$197.09",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 10,
    id: "October",
    "Gross Sales": 8434.82,
    Customers: "226",
    Transactions: "306",
    "Average Ticket": "$27.56",
    "Return Amount": "$15.48",
    "Discount Amount": "$34.90",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 11,
    id: "November",
    "Gross Sales": 12943.94,
    Customers: "267",
    Transactions: "393",
    "Average Ticket": "$32.94",
    "Return Amount": "$61.24",
    "Discount Amount": "$785.86",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
  {
    WeekDayNumber: 12,
    id: "December",
    "Gross Sales": 13943.17,
    Customers: "314",
    Transactions: "519",
    "Average Ticket": "$26.87",
    "Return Amount": "$51.40",
    "Discount Amount": "$224.18",
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4734.49,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.69,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2587.92,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
      },
    },
  },
]
