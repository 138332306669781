import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import NivoCalendarChart from "./NivoCalendarChart"
import { CalendarChartData } from "./CalendarChartData"
import { CalendarChartParameters } from "./CalendarChartParameters"
import { aiReportApi, postData } from "../../../api/api"
import { getDateFormatFromLocalStorage } from "../../../utils/dateUtils"
import ExportChartsData from "../../ExportChartsData"
import dayjs from "dayjs"
import { LoadingButton } from "@mui/lab"
import DownloadIcon from "@mui/icons-material/Download"
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import XLSX from "xlsx"
const CalendarChart = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showLegend = true,
  showAiDropdown = true,
  defaultColor = ["#eeeeee", "#61cdbb", "#e8c1a0", "#f47560"],
}) => {
  const { theme } = useTheme()

  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [pdfloading, setPdfLoading] = useState(false)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  const staticAiResponse = {
    success: true,
    msg: [
      {
        Analysis:
          "\n1. The actual sales for August 2024 ranged from approximately 6,182 to 15,386 units, with the highest sales recorded on August 4th.\n2. The forecasted sales for the same period ranged from about 7,024 to 14,287 units, peaking on August 11th, 25th, and 31st.\n3. The actual sales surpassed the forecast on several days, particularly on August 4th, 15th, and 25th.\n",
      },
      {
        Recommendations:
          "\n1. Consider investigating the factors that led to the higher than forecasted sales on August 4th, 15th, and 25th, as understanding these could help replicate such success in the future.\n2. Keep a close eye on the days where sales are consistently lower than forecasted to identify any potential issues or trends.\n3. Use this data to refine your forecasting model, taking into account any external factors that may have influenced sales.\n",
      },
      {
        Anomalies:
          "\n1. There was a significant discrepancy between the actual and forecasted sales on August 4th, with actual sales much higher than predicted.\n2. The actual sales on August 11th were lower than forecasted, despite the forecast predicting a peak in sales.\n3. The actual sales on August 25th significantly surpassed the forecast, indicating an unexpected surge in sales.",
      },
    ],
  }
  const dispatch = useDispatch()
  const svgColor = theme.palette.accentSecondary

  extractedParameters.chartContainerDimensions ||
    (extractedParameters.chartContainerDimensions = {
      height: "600px",
      minHeight: "600px",
      maxHeight: "unset",
    })

  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "Test",
    Recommendations: "Test",
    Anomalies: "Test",
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const exportMenu = Boolean(anchorEl)

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const exportData = async (type, data = apiResponse) => {
    setPdfLoading(true)
    const aiResponses = await aiReportApi(title, data)

    setAiData({
      Analysis: aiResponses?.msg?.[0]?.["Analysis"],
      Recommendations: aiResponses?.msg?.[1]?.["Recommendations"],
      Anomalies: aiResponses?.msg?.[2]?.["Anomalies"],
    })

    // Check if the data is an array before attempting to map
    const transformedData = Array.isArray(data)
      ? data.map((item) => {
          const flatItem = {}

          for (const key of Object.keys(item)) {
            if (typeof item[key] === "object") {
              flatItem[`${key} Current`] = item[key].current
              flatItem[`${key} Previous`] = item[key].previous
              flatItem[`${key} Change Percent`] = item[key].changePercent
            } else if (typeof item[key] === "string") {
              flatItem[key] = item[key]
            }
          }

          return flatItem
        })
      : [] // If data is not an array, set transformedData as an empty array

    if (type === "pdf") {
      setAnchorEl(null)
      setPdfOpen(true)
      return
    }

    const worksheet = XLSX.utils.json_to_sheet(transformedData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data")
    XLSX.writeFile(workbook, `data.${type}`, { bookType: type })
    setAnchorEl(null)
    setPdfLoading(false)
  }

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "value",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Returns",
      format: "Currency",
      color: "",
    },
    {
      name: "Discounts",
      format: "Currency",
      color: "",
    },
  ]

  const parseNumber = (value) => {
    try {
      return typeof value === "number"
        ? value
        : parseFloat(value.replace(/[^0-9.]/g, ""))
    } catch (error) {
      return undefined
    }
  }
  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  function transformData(input) {
    if (!input || !input.reportDataCurrent || !input.reportDataPrevious) {
      console.error("Invalid input data structure:", input)
      return []
    }

    // Create a map of current data by datekey
    const currentDataMap = input.reportDataCurrent.reduce((map, item) => {
      if (item.datekey) {
        map[item.datekey] = item
      }
      return map
    }, {})

    // Helper function to adjust datekey by a fixed offset
    const adjustDatekey = (datekey, yearsOffset) => {
      const dateStr = datekey.toString()
      const year = parseInt(dateStr.substring(0, 4), 10)
      const newYear = year - yearsOffset
      return parseInt(`${newYear}${dateStr.substring(4)}`, 10)
    }

    let data = []

    input.reportDataCurrent.forEach((currentItem) => {
      // Adjust the datekey for matching
      const adjustedDatekey = adjustDatekey(currentItem.datekey, 1) // Assuming a 1-year offset

      // Find the corresponding current item
      const previousItem = currentDataMap[adjustedDatekey] || {}

      let eachElement = {
        day: currentItem.day,
        value: currentItem.value,
        reportType: "Calendar",
        previousDate: previousItem.day,
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      tooltip_data.forEach((tooltipDataItem) => {
        const tooltipName = tooltipDataItem.name

        // Handle current data
        eachElement.tooltipData.current[tooltipName] = {
          value: parseNumber(currentItem[tooltipName]) || 0,
          format: tooltipDataItem.format,
        }

        // Handle previous data
        eachElement.tooltipData.previous[tooltipName] = {
          value: parseNumber(previousItem[tooltipName]) || 0,
          format: tooltipDataItem.format,
        }
      })

      data.push(eachElement)
    })

    return data
  }

  const fetchData = async () => {
    try {
      const response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        reportInstanceId: reportUid,
        fromDate: "20220101",
        toDate: "20241231",
        comparedBy: {
          previousFromDate: "20220101",
          previousToDate: "20231231",
        },
      })

      setApiResponse(response)

     
      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
      } else {
        dispatch(
          setChartData({
            reportUid,
            response: transformData(response),
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBy])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })
  const chartInput = response || CalendarChartData

 function transformReportData(apiResponse) {
   const transformedData = []

   const currentData = apiResponse.reportDataCurrent || apiResponse
   const previousData = apiResponse.reportDataPrevious || []

   if (!Array.isArray(currentData)) {
     console.warn("Current data is missing or not an array")
     return transformedData
   }

   const currentLength = currentData.length

   const tooltip_data = extractedParameters.tooltip_data || [
     {
       name: "value",
       format: "Currency",
       color: "",
     },
     {
       name: "Customers",
       format: "Number",
       color: "",
     },
     {
       name: "Transactions",
       format: "Number",
       color: "",
     },
     {
       name: "Returns",
       format: "Currency",
       color: "",
     },
     {
       name: "Discounts",
       format: "Currency",
       color: "",
     },
   ]

   const dateFormat = getDateFormatFromLocalStorage() || "MM-DD-YYYY"

   for (let i = 0; i < currentLength; i++) {
     const current = currentData[i]
     const previous = previousData[i] || {}

     const transformedWeek = {
       date: dayjs(current["day"]).format(dateFormat),
     }

     tooltip_data.forEach((tooltip) => {
       const name = tooltip.name
       const currentVal = current[name] || 0

       if (Object.keys(previous).length > 0) {
         const previousVal = previous[name] || 0

         transformedWeek[name] = {
           current:
             tooltip.format === "Currency"
               ? `$${currentVal.toLocaleString()}`
               : currentVal.toString(),
           previous:
             previousVal > 0
               ? tooltip.format === "Currency"
                 ? `$${previousVal.toLocaleString()}`
                 : previousVal.toString()
               : tooltip.format === "Currency"
               ? "$0"
               : "0",
           changePercent:
             previousVal === 0
               ? "-"
               : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                 "%",
         }
       } else {
         transformedWeek[name] = {
           current:
             tooltip.format === "Currency"
               ? `$${currentVal.toLocaleString()}`
               : currentVal.toString(),
         }
       }
     })

     transformedData.push(transformedWeek)
   }

   return transformedData
 }

 const tableData = transformReportData(apiResponse)
  

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center" mb={"14px"}>
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  showLegend,
                  apiResponse,
                }}
              />
            )}
          </Box>
          {!loading && (
            <>
              <LoadingButton
                loading={loading}
                sx={{
                  minWidth: "104px",
                  textTransform: "none",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.accent}`,
                  color: theme.palette.accent,
                  "&:hover": {
                    border: `1px solid ${theme.palette.accent}`,
                  },
                  "& .MuiCircularProgress-colorInherit": {
                    color: theme.palette.accent,
                  },
                  "& .MuiLoadingButton-label": {
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  },
                }}
                color="warning"
                onClick={handleExportClick}
              >
                <Typography variant="body2" color="inherit">
                  Export
                </Typography>
                <DownloadIcon fontSize="small" />
              </LoadingButton>
              <Menu
                anchorEl={anchorEl}
                open={exportMenu}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ "aria-labelledby": "export-button" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {pdfloading ?
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    alignContent="center"
                    padding="10px"
                  >
                    <CircularProgress size={45} color="info" />
                    <Typography variant="body2" color="inherit">
                      Transforming data into PDF...
                    </Typography>
                  </Box>
                  :
                  <>
                <MenuItem onClick={() => exportData("pdf")}>
                  <ListItemIcon>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 36 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.12">
                        <path
                          d="M3.33301 19.6667C3.33301 16.8665 3.33301 15.4664 3.87798 14.3968C4.35734 13.456 5.12225 12.6911 6.06306 12.2117C7.13261 11.6667 8.53275 11.6667 11.333 11.6667H28.6663C31.4666 11.6667 32.8667 11.6667 33.9363 12.2117C34.8771 12.6911 35.642 13.456 36.1214 14.3968C36.6663 15.4664 36.6663 16.8665 36.6663 19.6667V23.3334C36.6663 24.8834 36.6663 25.6583 36.496 26.2942C36.0336 28.0196 34.6859 29.3674 32.9604 29.8297C32.3246 30.0001 31.5496 30.0001 29.9997 30.0001V28.6667C29.9997 26.7999 29.9997 25.8665 29.6364 25.1534C29.3168 24.5262 28.8069 24.0163 28.1796 23.6967C27.4666 23.3334 26.5332 23.3334 24.6663 23.3334H15.333C13.4662 23.3334 12.5327 23.3334 11.8197 23.6967C11.1925 24.0163 10.6826 24.5262 10.363 25.1534C9.99967 25.8665 9.99967 26.7999 9.99967 28.6667V30.0001C8.44972 30.0001 7.67475 30.0001 7.03891 29.8297C5.31345 29.3674 3.96571 28.0196 3.50338 26.2942C3.33301 25.6583 3.33301 24.8834 3.33301 23.3334V19.6667Z"
                          fill={theme.palette.accent}
                        />
                      </g>
                      <path
                        d="M29.9997 11.6666V8.66659C29.9997 6.79974 29.9997 5.86632 29.6364 5.15328C29.3168 4.52608 28.8069 4.01614 28.1796 3.69656C27.4666 3.33325 26.5332 3.33325 24.6663 3.33325H15.333C13.4662 3.33325 12.5327 3.33325 11.8197 3.69656C11.1925 4.01614 10.6826 4.52608 10.363 5.15328C9.99967 5.86632 9.99967 6.79974 9.99967 8.66659V11.6666M9.99967 29.9999C8.44972 29.9999 7.67475 29.9999 7.03891 29.8295C5.31345 29.3672 3.96571 28.0195 3.50338 26.294C3.33301 25.6582 3.33301 24.8832 3.33301 23.3333V19.6666C3.33301 16.8663 3.33301 15.4662 3.87798 14.3966C4.35734 13.4558 5.12224 12.6909 6.06306 12.2116C7.13261 11.6666 8.53275 11.6666 11.333 11.6666H28.6663C31.4666 11.6666 32.8667 11.6666 33.9363 12.2116C34.8771 12.6909 35.642 13.4558 36.1214 14.3966C36.6663 15.4662 36.6663 16.8663 36.6663 19.6666V23.3333C36.6663 24.8832 36.6663 25.6582 36.496 26.294C36.0336 28.0195 34.6859 29.3672 32.9604 29.8295C32.3246 29.9999 31.5496 29.9999 29.9997 29.9999M24.9997 17.4999H29.9997M15.333 36.6666H24.6663C26.5332 36.6666 27.4666 36.6666 28.1796 36.3033C28.8069 35.9837 29.3168 35.4738 29.6364 34.8466C29.9997 34.1335 29.9997 33.2001 29.9997 31.3333V28.6666C29.9997 26.7997 29.9997 25.8663 29.6364 25.1533C29.3168 24.5261 28.8069 24.0161 28.1796 23.6966C27.4666 23.3333 26.5332 23.3333 24.6663 23.3333H15.333C13.4662 23.3333 12.5327 23.3333 11.8197 23.6966C11.1925 24.0161 10.6826 24.5261 10.363 25.1533C9.99967 25.8663 9.99967 26.7997 9.99967 28.6666V31.3333C9.99967 33.2001 9.99967 34.1335 10.363 34.8466C10.6826 35.4738 11.1925 35.9837 11.8197 36.3033C12.5327 36.6666 13.4662 36.6666 15.333 36.6666Z"
                        stroke={theme.palette.accent}
                        strokeWidth="3.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2" color="inherit">
                      PDF
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => exportData("xlsx", tableData)}>
                  <ListItemIcon>
                    <svg
                      width="20"
                      height="20"
                      viewBox="-2 0 30 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3337 2.78247V9.66671C18.3337 10.6001 18.3337 11.0668 18.5153 11.4234C18.6751 11.737 18.9301 11.9919 19.2437 12.1517C19.6002 12.3334 20.0669 12.3334 21.0003 12.3334H27.8846M21.667 20.6666H8.33366M21.667 27.3333H8.33366M11.667 13.9999H8.33366M18.3337 2.33325H9.66699C6.86673 2.33325 5.4666 2.33325 4.39704 2.87822C3.45623 3.35759 2.69133 4.12249 2.21196 5.0633C1.66699 6.13286 1.66699 7.53299 1.66699 10.3333V27.6666C1.66699 30.4668 1.66699 31.867 2.21196 32.9365C2.69133 33.8773 3.45623 34.6423 4.39704 35.1216C5.4666 35.6666 6.86673 35.6666 9.66699 35.6666H20.3337C23.1339 35.6666 24.5341 35.6666 25.6036 35.1216C26.5444 34.6423 27.3093 33.8773 27.7887 32.9365C28.3337 31.867 28.3337 30.4668 28.3337 27.6666V12.3333L18.3337 2.33325Z"
                        stroke={theme.palette.accent}
                        strokeWidth="3.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2" color="inherit">
                      Excel
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => exportData("csv", tableData)}>
                  <ListItemIcon>
                    <svg
                      width="20"
                      height="20"
                      viewBox="-2 0 30 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3337 2.78247V9.66671C18.3337 10.6001 18.3337 11.0668 18.5153 11.4234C18.6751 11.737 18.9301 11.9919 19.2437 12.1517C19.6002 12.3334 20.0669 12.3334 21.0003 12.3334H27.8846M21.667 20.6666H8.33366M21.667 27.3333H8.33366M11.667 13.9999H8.33366M18.3337 2.33325H9.66699C6.86673 2.33325 5.4666 2.33325 4.39704 2.87822C3.45623 3.35759 2.69133 4.12249 2.21196 5.0633C1.66699 6.13286 1.66699 7.53299 1.66699 10.3333V27.6666C1.66699 30.4668 1.66699 31.867 2.21196 32.9365C2.69133 33.8773 3.45623 34.6423 4.39704 35.1216C5.4666 35.6666 6.86673 35.6666 9.66699 35.6666H20.3337C23.1339 35.6666 24.5341 35.6666 25.6036 35.1216C26.5444 34.6423 27.3093 33.8773 27.7887 32.9365C28.3337 31.867 28.3337 30.4668 28.3337 27.6666V12.3333L18.3337 2.33325Z"
                        stroke={theme.palette.accent}
                        strokeWidth="3.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2" color="inherit">
                      CSV
                    </Typography>
                  </ListItemText>
                </MenuItem>
                </>}
              </Menu>
            </>
          )}
        </Box>
      </Box>

      <Box ref={chartRef}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="550px"
            width="100%"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <NivoCalendarChart
            chartInput={chartInput}
            parameters={CalendarChartParameters}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            defaultColor={defaultColor}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <NivoCalendarChart
            chartInput={chartInput}
            parameters={CalendarChartParameters}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            defaultColor={defaultColor}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default CalendarChart
