import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import { useTheme } from "../../../contexts/theme"
import CustomPaper from "../../../components/CustomPaper"
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import SaveModal from "../../../components/SaveModal"

const EditUser = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const personalInfoFields = [
    { label: "Name", type: "text" },
    { label: "Phone Number", type: "tel" },
    { label: "Email Address", type: "email" },
    { label: "Street Address", type: "text" },
    { label: "City", type: "text" },
    { label: "Apt / Suite No", type: "text" },
    { label: "State", type: "text" },
    { label: "Country", type: "text" },
    { label: "Zip Code", type: "text" },
    { label: "Rank", type: "number" },
  ]

  const JobInfoFields = [
    { label: "Department", type: "text" },
    { label: "Group", type: "text" },
    { label: "Date of Joining", type: "text" },
  ]

  return (
    <>
      <Stack padding="30px 30px 15px 30px" gap="20px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "Management",
              // isActive: false,
              clickHandler: () => {
                navigate("/management")
              },
            },
            {
              text: "Users",
              // isActive: false,
              // clickHandler: () => {
              //   navigate("/employees/overview")
              // },
            },
            {
              text: "Edit",
              isActive: true,
            },
          ]}
        />
        <Typography
          variant="body1"
          style={{
            textAlign: "left",
            marginTop: "0.5rem",
            fontSize: "18px",
          }}
          color="inherit"
        >
          Edit User
        </Typography>
        <CustomPaper
          customStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography color="inherit">Personal Information</Typography>
          <CustomPaper
            elevation={0}
            customStyles={{
              background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
            }}
          >
            <Box
              display="flex"
              flexWrap="wrap"
              sx={{
                gap: "20px",
              }}
            >
              {personalInfoFields.map((field, index) => (
                <Box
                  key={index}
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    gap: "10px",
                    minWidth: {
                      sx: "300px",
                      sm: "300px",
                      md: "300px",
                      lg: "400px",
                    },
                  }}
                >
                  <label htmlFor={field.label}>{field.label}</label>
                  <input
                    type={field.type}
                    id={field.label}
                    name={field.label}
                    required
                    style={{
                      border: "2px solid #6382E9",
                      minHeight: "40px",
                      minWidth: "240px",
                      borderRadius: "12px",
                      paddingInline: "5px",
                      color: theme.palette.mode === "dark" ? "white" : "black",
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? "rgba(13, 34, 102, 1)"
                          : "white",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </CustomPaper>
        </CustomPaper>
        <CustomPaper
          customStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography color="inherit">Job Information</Typography>
          <CustomPaper
            elevation={0}
            customStyles={{
              background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
            }}
          >
            <Box
              display="flex"
              flexWrap="wrap"
              sx={{
                gap: "20px",
              }}
            >
              {JobInfoFields.map((field, index) => (
                <Box
                  key={index}
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    gap: "10px",
                    minWidth: {
                      sx: "300px",
                      sm: "300px",
                      md: "300px",
                      lg: "400px",
                    },
                  }}
                >
                  <label htmlFor={field.label}>{field.label}</label>
                  <input
                    type={field.type}
                    id={field.label}
                    name={field.label}
                    required
                    style={{
                      border: "2px solid #6382E9",
                      minHeight: "40px",
                      minWidth: "240px",
                      borderRadius: "12px",
                      paddingInline: "5px",
                      color: theme.palette.mode === "dark" ? "white" : "black",
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? "rgba(13, 34, 102, 1)"
                          : "white",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </CustomPaper>
          <Divider />
          <Box display="flex" gap="10px" justifyContent="flex-end">
            <Button
              variant="outlined"
              sx={{
                border: `1px solid ${theme.palette.accent}`,
                textTransform: "none",
                color: theme.palette.accent,
                borderRadius: "20px",
                "&:hover": {
                  border: `1px solid ${theme.palette.accent}`,
                  color: "",
                  boxShadow: "5px",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                bgcolor: theme.palette.accent,
                textTransform: "none",
                color: "white",
                borderRadius: "20px",
                height: "36px",
                "&:hover": {
                  bgcolor: theme.palette.accent,
                  boxShadow: "5px",
                },
              }}
            >
              save
            </Button>
            <SaveModal
              open={open}
              onClose={() => setOpen(false)}
              title="Success"
              message="User has been saved successfully!!"
            />
          </Box>
        </CustomPaper>
      </Stack>
    </>
  )
}

export default EditUser
