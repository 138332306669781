import { useTheme } from "../../contexts/theme"

const MngIcon = () => {
  const { theme } = useTheme()
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2722_11622)">
          <path
            d="M10.0003 8.33463C11.8413 8.33463 13.3337 6.84225 13.3337 5.0013C13.3337 3.16035 11.8413 1.66797 10.0003 1.66797C8.15938 1.66797 6.66699 3.16035 6.66699 5.0013C6.66699 6.84225 8.15938 8.33463 10.0003 8.33463Z"
            stroke={theme.palette.accent}
            strokeWidth="2"
          />
          <path
            opacity="0.5"
            d="M15 7.4987C16.3808 7.4987 17.5 6.56596 17.5 5.41536C17.5 4.26477 16.3808 3.33203 15 3.33203"
            stroke={theme.palette.accent}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            opacity="0.5"
            d="M5 7.4987C3.61929 7.4987 2.5 6.56596 2.5 5.41536C2.5 4.26477 3.61929 3.33203 5 3.33203"
            stroke={theme.palette.accent}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10 17.4987C12.7614 17.4987 15 16.0063 15 14.1654C15 12.3244 12.7614 10.832 10 10.832C7.23858 10.832 5 12.3244 5 14.1654C5 16.0063 7.23858 17.4987 10 17.4987Z"
            stroke={theme.palette.accent}
            strokeWidth="2"
          />
          <path
            opacity="0.5"
            d="M16.667 15.8346C18.1288 15.5141 19.167 14.7022 19.167 13.7513C19.167 12.8004 18.1288 11.9886 16.667 11.668"
            stroke={theme.palette.accent}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            opacity="0.5"
            d="M3.33301 15.8346C1.87113 15.5141 0.833008 14.7022 0.833008 13.7513C0.833008 12.8004 1.87113 11.9886 3.33301 11.668"
            stroke={theme.palette.accent}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2722_11622">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default MngIcon
