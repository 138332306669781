
const HappySentiments = () => {
  return (
    <svg
      width="79"
      height="78"
      viewBox="0 0 79 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.333 71.5C57.2821 71.5 71.833 56.9491 71.833 39C71.833 21.0507 57.2821 6.5 39.333 6.5C21.3837 6.5 6.83301 21.0507 6.83301 39C6.83301 56.9491 21.3837 71.5 39.333 71.5Z"
        fill="#50CD89"
      />
      <path
        opacity="0.5"
        d="M27.6249 50.5487C28.4266 49.4671 29.9532 49.2403 31.0346 50.0421C33.4019 51.7964 36.259 52.8127 39.3332 52.8127C42.4073 52.8127 45.2644 51.7964 47.6317 50.0421C48.7133 49.2403 50.2398 49.4671 51.0413 50.5487C51.8431 51.6303 51.6162 53.1569 50.5346 53.9583C47.3743 56.3009 43.5094 57.6877 39.3332 57.6877C35.1569 57.6877 31.292 56.3009 28.1317 53.9583C27.0502 53.1569 26.8233 51.6303 27.6249 50.5487Z"
        fill="#030816"
      />
      <path
        opacity="0.5"
        d="M49.083 39C50.878 39 52.333 36.8173 52.333 34.125C52.333 31.4326 50.878 29.25 49.083 29.25C47.288 29.25 45.833 31.4326 45.833 34.125C45.833 36.8173 47.288 39 49.083 39Z"
        fill="#030816"
      />
      <path
        opacity="0.5"
        d="M29.583 39C31.3779 39 32.833 36.8173 32.833 34.125C32.833 31.4326 31.3779 29.25 29.583 29.25C27.7881 29.25 26.333 31.4326 26.333 34.125C26.333 36.8173 27.7881 39 29.583 39Z"
        fill="#030816"
      />
    </svg>
  )
}

export default HappySentiments
