import Typography from "@mui/material/Typography"
import Switch from "@mui/material/Switch"
import Box from "@mui/material/Box"
import { useTheme } from "../../../../contexts/theme"

const ThemeToggleButton = () => {
  const { theme, toggleColorMode } = useTheme()

  const label = {
    inputProps: {
      "aria-label": "Toggle Color mode",
    },
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="subtitle2" component="h2">
        Light
      </Typography>
      <Switch
        size="medium"
        {...label}
        checked={theme.palette.mode === "dark"}
        color="inherit"
        onChange={toggleColorMode}
        sx={{
          "& .MuiSwitch-track": {
            opacity: "1 !important",
            backgroundColor:
              theme.palette.mode === "dark"
                ? "rgba(243, 136, 74, 1)"
                : "#1D47CE",
          },
        }}
      />
      <Typography variant="subtitle2" component="h2">
        Dark
      </Typography>
    </Box>
  )
}

export default ThemeToggleButton
