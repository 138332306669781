import Box from "@mui/material/Box"
import { ResponsiveHeatMap } from "@nivo/heatmap"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"
import CustomLegend from "../../CustomLegend"
import StarLowPerformer from "../../StarLowPerformer"

const NivoHeatMapChart = ({
  chartInput = [],
  chartConfig = {},
  reportTitle,
  reportData,
  showAiDropdown,
  defaultColor,
  showTooltipPrevious,
}) => {
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const {
    margin,
    valueFormat,
    axisTop,
    axisRight,
    axisLeft,
    colors,
    legends,
    chartContainerDimensions = {
      height: "350px",
    },
    performerFlag = { icon: false, legend: false },
  } = chartConfig

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      ticks: {
        line: {
          transform: "translateY(-2px)",
        },
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  // Find highest and lowest performer
  const findHighestAndLowestY = (data) => {
    if (!data || data.length === 0) {
      return { highestY: null, lowestY: null }
    }

    let highestY = null
    let lowestY = null
    let highestDay = null
    let highestTime = null
    let lowestDay = null
    let lowestTime = null

    data.forEach((day) => {
      day.data.forEach((item) => {
        const currentY = item.y

        if (highestY === null || currentY > highestY) {
          highestY = currentY
          highestDay = day.id
          highestTime = item.x
        }

        if (currentY !== 0 && (lowestY === null || currentY < lowestY)) {
          lowestY = currentY
          lowestDay = day.id
          lowestTime = item.x
        }
      })
    })

    const formattedHighestDate =
      highestDay && highestTime ? `${highestDay} at ${highestTime}` : null
    const formattedLowestDate =
      lowestDay && lowestTime ? `${lowestDay} at ${lowestTime}` : null

    return { highestY, formattedHighestDate, lowestY, formattedLowestDate }
  }

  const { highestY, formattedHighestDate, lowestY, formattedLowestDate } =
    findHighestAndLowestY(chartInput)

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      {performerFlag.legend && (
        <StarLowPerformer
          StarPerformer={highestY}
          StarPerformerDate={formattedHighestDate}
          lowPerformer={lowestY}
          lowPerformerDate={formattedLowestDate}
        />
      )}
      <Box sx={{ ...chartContainerDimensions }}>
        <ResponsiveHeatMap
          data={chartInput}
          theme={customTheme}
          valueFormat={valueFormat}
          margin={margin}
          axisTop={axisTop}
          axisRight={axisRight}
          axisLeft={axisLeft}
          colors={defaultColor ? defaultColor : colors}
          legends={legends}
          tooltip={({ cell }) => (
            <CustomTooltip
              data={cell.data}
              showCurrentPrevDate={true}
              heatmapCell={cell}
              showTooltipPrevious={showTooltipPrevious}
            />
          )}
        />
      </Box>
      {performerFlag.legend && (
        <CustomLegend
          StarPerformer={highestY}
          StarPerformerDate={formattedHighestDate}
          lowPerformer={lowestY}
          lowPerformerDate={formattedLowestDate}
          reportTitle={reportTitle}
          reportData={reportData}
          showAiDropdown={showAiDropdown}
        />
      )}
    </Box>
  )
}

export default NivoHeatMapChart
