import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state[action.payload.route] = {
        filters: action.payload.filters,
        filtersString: action.payload.filtersString || "",
        filtersSql: action.payload.filtersSql || "",
      }
    },
  },
})

export const { setFilters } = filtersSlice.actions

export default filtersSlice.reducer
