import NivoStreamChart from "./NivoStreamChart"
import {
  StreamChartData,
  StreamChartParameters,
} from "../../../constants/chartsData"
import Box from "@mui/material/Box"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import InfoToolTip from "../../InfoToolTip"
import { useTheme } from "../../../contexts/theme"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"

const StreamChart = ({ title, description, showAiDropdown = true }) => {
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary

  let aiResponse = {
    success: true,
    msg: [
      {
        Analysis:
          "\n1. The actual sales for August 2024 ranged from approximately 6,182 to 15,386 units, with the highest sales recorded on August 4th.\n2. The forecasted sales for the same period ranged from about 7,024 to 14,287 units, peaking on August 11th, 25th, and 31st.\n3. The actual sales surpassed the forecast on several days, particularly on August 4th, 15th, and 25th.\n",
      },
      {
        Recommendations:
          "\n1. Consider investigating the factors that led to the higher than forecasted sales on August 4th, 15th, and 25th, as understanding these could help replicate such success in the future.\n2. Keep a close eye on the days where sales are consistently lower than forecasted to identify any potential issues or trends.\n3. Use this data to refine your forecasting model, taking into account any external factors that may have influenced sales.\n",
      },
      {
        Anomalies:
          "\n1. There was a significant discrepancy between the actual and forecasted sales on August 4th, with actual sales much higher than predicted.\n2. The actual sales on August 11th were lower than forecasted, despite the forecast predicting a peak in sales.\n3. The actual sales on August 25th significantly surpassed the forecast, indicating an unexpected surge in sales.",
      },
    ],
  }

  const legendData = [
    { label: "Floyad Mines", color: "#6E8B3D" },
    { label: "Savannah Nguyan", color: "#C75D8A" },
    { label: "Brroklys Symons", color: "#DB7093" },
    { label: "Bessie Cooper", color: "#A96FFF" },
    { label: "Ronalds Rechards", color: "#FF85E1" },
    { label: "Theressa Webb", color: "#95C75B" },
  ]

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <div className="sales">{title}</div>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
      </Box>

      <NivoStreamChart
        chartInput={StreamChartData}
        parameters={StreamChartParameters}
        legendsData={legendData}
        reportTitle={title}
        reportData={StreamChartData}
        showAiDropdown={showAiDropdown}
      />
    </>
  )
}

export default StreamChart
