import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CustomPaper from "../../../../components/CustomPaper"
import LeavesCategorization from "./LeavesCategorization"
import { useTheme } from "../../../../contexts/theme"
import LeavesAvailablePieChart from "./LeavesAvailablePieChart"
import EmployeeLeaveLegends from "./EmployeeLeaveLegends"

const TotalLeaves = () => {
  const { theme } = useTheme()

  const ThemeMode = theme.palette.mode
  return (
    <CustomPaper>
      <Box display="flex">
        <Box sx={{ width: "30%" }}>
          <Typography
            component="div"
            variant="subtitle2"
            className="Payroll-Summary-Title"
          >
            Total Leaves
          </Typography>
          <Box sx={{ mt: 3 }}>
            <LeavesCategorization />
          </Box>
          <Typography
            component="div"
            variant="subtitle2"
            className="Payroll-Summary-Title"
            textAlign={"center"}
          >
            <span
              style={{
                fontWeight: "600px",
                fontSize: "22px",
                color: "rgba(80, 205, 137, 1)",
              }}
            >
              30
            </span>{" "}
            Available Leaves
          </Typography>
        </Box>
        <Box
          sx={{
            width: "70%",
          }}
        >
          <Typography
            component="div"
            variant="subtitle2"
            className="Payroll-Summary-Title"
          >
            Leave Balance
          </Typography>
          <Box
            display="flex"
            sx={{
              paddingTop: "30px",
              mt: 3,
              border:
                ThemeMode === "dark"
                  ? "1px solid rgba(26, 68, 203, 1)"
                  : "1px solid red",
              backgroundColor:
                ThemeMode === "dark" ? "rgba(13, 34, 112, 1)" : "none",
              borderRadius: "10px",
            }}
          >
            <Box display="flex">
              <Box
                display="flex"
                flexDirection="column"
                alignContent={"center"}
              >
                <Typography
                  component="div"
                  textAlign={"center"}
                  variant="body2"
                >
                  Contingency Leave (CL)
                </Typography>
                <Box display="flex">
                  <LeavesAvailablePieChart />
                  <Box sx={{ mt: 3 }}>
                    <EmployeeLeaveLegends
                      category="Available"
                      count={10}
                      colorItem="rgba(80, 205, 137, 1)"
                    />
                    <EmployeeLeaveLegends
                      category="Consumed"
                      count={2}
                      colorItem="red"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box display="flex" flexDirection="column">
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  component="div"
                >
                  Privilege Leave (PL)
                </Typography>
                <Box display="flex">
                  <LeavesAvailablePieChart />
                  <Box sx={{ mt: 3 }}>
                    <EmployeeLeaveLegends
                      category="Available"
                      count={10}
                      colorItem="rgba(80, 205, 137, 1)"
                    />
                    <EmployeeLeaveLegends
                      category="Consumed"
                      count={2}
                      colorItem="red"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box display="flex" flexDirection="column">
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  component="div"
                >
                  Option Leave
                </Typography>
                <Box display="flex">
                  <LeavesAvailablePieChart />
                  <Box sx={{ mt: 3 }}>
                    <EmployeeLeaveLegends
                      category="Available"
                      count={10}
                      colorItem="rgba(80, 205, 137, 1)"
                    />
                    <EmployeeLeaveLegends
                      category="Consumed"
                      count={2}
                      colorItem="red"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomPaper>
  )
}

export default TotalLeaves
