import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Analytica from "../../Analytica"
import InfoToolTip from "../../InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { WordCloudData } from "../../../constants/chartsData"
import ReactWordCloudChart from "./ReactWordCloudChart"

const WordCloudChart = ({ title, description }) => {
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary
  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              {/* <InfoLogoSvg svgColor={svgColor} /> */}
            </span>
          </InfoToolTip>
        </Box>
        {/* {extractedParameters.view_by_period && (
          <ViewBy
            route={route}
            parameters={
              extractedParameters && extractedParameters.view_by_period
            }
            viewBy={viewBy}
            setViewBy={setViewBy}
            reportUid={reportUid}
            dateRangeData={dateRangeData}
          />
        )} */}
        {/* {extractedParameters.focus_mode && focus_mode && (
          <Analytica
            // description={description}
            // component="modal"
            // reportType="Bar"
            // chartDetails={{
            //   route,
            //   setChartData,
            //   comparisonPeriod,
            //   title,
            //   description,
            //   postUrl,
            //   reportUid,
            //   parameters: parameters,
            //   dateRangeData,
            // }}
          />
        )} */}
      </Box>

      <Box minHeight="350px">
        <ReactWordCloudChart
          chartInput={WordCloudData}
          //   parameters={BumpChartParameters}
        />
      </Box>
    </>
  )
}

export default WordCloudChart
