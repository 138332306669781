import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import StarIcon from "../../assets/svgs/Star.jsx"
import DeleteModal from "../../components/DeleteModal"
import CustomPaper from "../../components/CustomPaper"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import Table from "../../components/datagrid/Table"
import {
  TargetedCustomersData,
  TargetedCustomersParameters,
} from "../../constants/chartsData"
import Grid from "@mui/material/Grid"
import Chip from "@mui/material/Chip"
import { useTheme } from "../../contexts/theme.jsx"

const CampaignDetails = () => {
  const navigate = useNavigate()
  const { theme } = useTheme()

  const campaignMetrics = {
    totalCustomers: {
      label: "Total Customers",
      value: 100,
      sub: "Overall",
    },
    responded: {
      label: "Responded",
      value: 75,
      sub: "Customers",
    },
    attended: {
      label: "Attended",
      value: 50,
      sub: "Customers",
    },
    conversionMatrix: {
      label: "Conversion Matrix",
      value: "50%",
      sub: "50 out 0f 100 Customers",
    },
    salesGenerated: {
      label: "Sales Generated",
      value: "$3,539",
      sub: "10% > last Campaign",
    },
    campaignBudget: {
      label: "Campaign Budget",
      value: "$1,524",
      sub: "in sales revenue",
    },
    overallRating: {
      label: "Overall Rating",
      value: (
        <Box display="flex" alignItems="center" gap="5px">
          4.3
          <StarIcon color="#F2CD4A" />
        </Box>
      ),
      sub: "10% Higher than last year",
    },
    status: {
      label: "Status",
      value: (
        <Chip
          size="small"
          label="Active"
          sx={{
            backgroundColor: "rgba(80, 205, 137, 0.3)",
            color: "rgb(80, 205, 137)",
            maxWidth: "fit-content",
          }}
        />
      ),
      sub: "Campaign",
    },
  }

  const campaignDetails = {
    title: {
      label: "Title",
      value: "HolidayPromo",
    },
    couponCode: {
      label: "Coupon Code",
      value: "SUMMER200",
    },
    modeOfCommunication: {
      label: "Mode of Communication",
      value: "Email",
    },
    createdOn: {
      label: "Created on",
      value: "16th Oct 2024",
    },
    description: {
      label: "Description",
      value: (
        <>
          Dear valued customer,
          <br /> <br />
          Get ready to beat the heat this summer with our delicious specials!
          Enjoy refreshing drinks, tasty appetizers, and cool desserts at
          discounted prices all summer long! Click here to view our summer
          specials menu and start planning your visit today!
        </>
      ),
    },
  }

  const faces = [
    {
      svg: (
        <svg
          width="79"
          height="78"
          viewBox="0 0 79 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.333 71.5C57.2821 71.5 71.833 56.9491 71.833 39C71.833 21.0507 57.2821 6.5 39.333 6.5C21.3837 6.5 6.83301 21.0507 6.83301 39C6.83301 56.9491 21.3837 71.5 39.333 71.5Z"
            fill="#50CD89"
          />
          <path
            opacity="0.5"
            d="M27.6249 50.5487C28.4266 49.4671 29.9532 49.2403 31.0346 50.0421C33.4019 51.7964 36.259 52.8127 39.3332 52.8127C42.4073 52.8127 45.2644 51.7964 47.6317 50.0421C48.7133 49.2403 50.2398 49.4671 51.0413 50.5487C51.8431 51.6303 51.6162 53.1569 50.5346 53.9583C47.3743 56.3009 43.5094 57.6877 39.3332 57.6877C35.1569 57.6877 31.292 56.3009 28.1317 53.9583C27.0502 53.1569 26.8233 51.6303 27.6249 50.5487Z"
            fill="#030816"
          />
          <path
            opacity="0.5"
            d="M49.083 39C50.878 39 52.333 36.8173 52.333 34.125C52.333 31.4326 50.878 29.25 49.083 29.25C47.288 29.25 45.833 31.4326 45.833 34.125C45.833 36.8173 47.288 39 49.083 39Z"
            fill="#030816"
          />
          <path
            opacity="0.5"
            d="M29.583 39C31.3779 39 32.833 36.8173 32.833 34.125C32.833 31.4326 31.3779 29.25 29.583 29.25C27.7881 29.25 26.333 31.4326 26.333 34.125C26.333 36.8173 27.7881 39 29.583 39Z"
            fill="#030816"
          />
        </svg>
      ),
      text: "28%",
    },
    {
      svg: (
        <svg
          width="78"
          height="78"
          viewBox="0 0 78 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39 71.5C56.9491 71.5 71.5 56.9491 71.5 39C71.5 21.0507 56.9491 6.5 39 6.5C21.0507 6.5 6.5 21.0507 6.5 39C6.5 56.9491 21.0507 71.5 39 71.5Z"
            fill="#FCBF49"
          />
          <path
            opacity="0.5"
            d="M48.75 39C50.545 39 52 36.8173 52 34.125C52 31.4326 50.545 29.25 48.75 29.25C46.955 29.25 45.5 31.4326 45.5 34.125C45.5 36.8173 46.955 39 48.75 39Z"
            fill="#030816"
          />
          <path
            opacity="0.5"
            d="M29.25 39C31.0449 39 32.5 36.8173 32.5 34.125C32.5 31.4326 31.0449 29.25 29.25 29.25C27.4551 29.25 26 31.4326 26 34.125C26 36.8173 27.4551 39 29.25 39Z"
            fill="#030816"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M51 53.5C51 54.8807 49.8807 56 48.5 56H29C27.6193 56 26.5 54.8807 26.5 53.5C26.5 52.1193 27.6193 51 29 51H48.5C49.8807 51 51 52.1193 51 53.5Z"
            fill="#7F632F"
          />
        </svg>
      ),
      text: "70%",
    },
    {
      svg: (
        <svg
          width="79"
          height="78"
          viewBox="0 0 79 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.666 71.5C57.6151 71.5 72.166 56.9491 72.166 39C72.166 21.0507 57.6151 6.5 39.666 6.5C21.7168 6.5 7.16602 21.0507 7.16602 39C7.16602 56.9491 21.7168 71.5 39.666 71.5Z"
            fill="#EE000C"
          />
          <path
            opacity="0.5"
            d="M27.9579 56.7013C28.7596 57.7829 30.2862 58.0097 31.3677 57.2079C33.7349 55.4536 36.592 54.4373 39.6662 54.4373C42.7403 54.4373 45.5974 55.4536 47.9647 57.2079C49.0463 58.0097 50.5728 57.7829 51.3743 56.7013C52.1761 55.6197 51.9492 54.0931 50.8676 53.2917C47.7073 50.9491 43.8424 49.5623 39.6662 49.5623C35.4899 49.5623 31.625 50.9491 28.4647 53.2917C27.3832 54.0931 27.1563 55.6197 27.9579 56.7013Z"
            fill="#030816"
          />
          <path
            opacity="0.5"
            d="M49.416 39C51.211 39 52.666 36.8173 52.666 34.125C52.666 31.4326 51.211 29.25 49.416 29.25C47.621 29.25 46.166 31.4326 46.166 34.125C46.166 36.8173 47.621 39 49.416 39Z"
            fill="#030816"
          />
          <path
            opacity="0.5"
            d="M29.916 39C31.7109 39 33.166 36.8173 33.166 34.125C33.166 31.4326 31.7109 29.25 29.916 29.25C28.1211 29.25 26.666 31.4326 26.666 34.125C26.666 36.8173 28.1211 39 29.916 39Z"
            fill="#030816"
          />
        </svg>
      ),
      text: "2%",
    },
  ]

  const renderDetails = (details) =>
    Object.entries(details).map(([key, value]) => (
      <Stack
        key={key}
        rowGap="5px"
        minWidth={{
          sx: "200px",
          sm: "200px",
          md: "200px",
          lg: "280px",
        }}
      >
        <Typography
          variant="body2"
          color="inherit"
          sx={{
            opacity: "70%",
            fontSize: "0.85rem",
          }}
        >
          {value.label}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
          {value.value}
        </Typography>
      </Stack>
    ))

  const [open, setOpen] = useState(false)

  const deleteRecord = (id = 0) => {
    setOpen(false)
  }

  return (
    <>
      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onDelete={() => deleteRecord()}
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        padding="30px 30px 15px 30px"
      >
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "Marketing Portal",
              // isActive: false,
              clickHandler: () => {
                navigate("/submenu/marketing")
              },
            },
            {
              text: "Campaign Details",
              isActive: true,
            },
          ]}
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        padding="15px 30px 15px 30px"
      >
        <Typography variant="h6" color="inherit">
          Campaign Details
        </Typography>
      </Box>

      <Stack padding="15px 30px 15px 30px" rowGap="20px">
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} justifyContent="space-between">
              {Object.entries(campaignMetrics).map(([key, value]) => (
                <Grid key={key} item xs={12} sm={6} md={6} lg={3}>
                  <CustomPaper
                    watermarkBg={true}
                    customStyles={{ height: "100%" }}
                  >
                    <Stack
                      display="flex"
                      justifyContent="space-between"
                      height="100%"
                      gap="5px"
                    >
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        gap={"2px"}
                      >
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{
                            marginBottom: "8px",
                            overflow: "visible",
                            overflowWrap: "break-word",
                            textOverflow: "none",
                          }}
                          textAlign={"left"}
                        >
                          {value.label}
                        </Typography>
                      </Box>
                      <Stack display="flex" gap="5px">
                        <Typography variant="h5" fontWeight="bold">
                          {value.value}
                        </Typography>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="body2"
                            color="inherit"
                            fontSize="0.8rem"
                          >
                            {value.sub}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </CustomPaper>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomPaper customStyles={{ height: "100%" }}>
              <Stack
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box mb={1}>Overall Rating</Box>
                  <Box display="flex" alignItems="center" gap="5px">
                    4.3
                    <StarIcon color="#F2CD4A" />
                    <StarIcon color="#F2CD4A" />
                    <StarIcon color="#F2CD4A" />
                    <StarIcon color="#F2CD4A" />
                    <StarIcon color="#F2CD4A50" />
                  </Box>
                </Box>

                <CustomPaper
                  customStyles={{
                    background: `#0a1a4e${
                      theme.palette.mode === "dark" ? "50" : "05"
                    }`,
                    border: "1px solid #1B3790",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-evenly"
                    gap={1}
                  >
                    {faces.map((face) => (
                      <Stack alignItems="center">
                        {face.svg}
                        {face.text}
                      </Stack>
                    ))}
                  </Box>
                </CustomPaper>
              </Stack>
            </CustomPaper>
          </Grid>
        </Grid>
        <CustomPaper>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            borderBottom="2px solid #091743"
            mb="15px"
          >
            <Typography mb="10px" color="inherit">
              Campaign Information
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="45px">
            {renderDetails(campaignDetails)}
          </Box>
        </CustomPaper>

        <Table
          title="Add Members"
          showExportButton={false}
          StaticData={TargetedCustomersData}
          StaticParameters={TargetedCustomersParameters}
        />
      </Stack>
    </>
  )
}

export default CampaignDetails
