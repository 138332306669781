import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { useTheme } from "../contexts/theme"
import Box from "@mui/material/Box"
import { Link, useNavigate } from "react-router-dom"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"

const CustomBreadcrumbs = ({ breadcrumbItems = [] }) => {
  const { theme } = useTheme()
  const palette = theme.palette
  const separatorColor = palette.mode === "dark" ? "white" : "gray"
  const navigate = useNavigate()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      width="100%"
    >
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            fontSize="small"
            viewBox="0 0 24 25"
            sx={{
              color: separatorColor,
            }}
          />
        }
      >
        {breadcrumbItems?.map((breadcrumbItem, index) => (
          <Typography
            key={`${breadcrumbItem.text}-${index}`}
            variant="subtitle1"
            color={
              breadcrumbItem.isActive ? palette.accent : palette.text.primary
            }
            onClick={breadcrumbItem.clickHandler}
            sx={{
              cursor: breadcrumbItem.clickHandler ? "pointer" : "text",
              "&:hover": {
                color: palette.accent,
              },
            }}
          >
            {breadcrumbItem.text}{" "}
          </Typography>
        ))}
      </Breadcrumbs>
      <Link
        underline="hover"
        onClick={(e) => {
          e.preventDefault()
          navigate(-1)
        }}
        style={{
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          color: palette.accent,
          gap: "5px",
        }}
      >
        <NavigateBeforeIcon
          fontSize="medium"
          viewBox="0 0 25 27"
          color="inherit"
        />
        <Typography variant="subtitle1">Back</Typography>
      </Link>
    </Box>
  )
}

export default CustomBreadcrumbs
