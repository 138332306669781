export const ComparisonofExpensesandRevenueData = [
  {
    id: "Camp1",
    "Gross Sales": 7285.47,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 99285.47,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.83,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2290.04,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 82,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.93,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Camp2",
    "Gross Sales": 11528.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Camp3",
    "Gross Sales": 4528.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Camp4",
    "Gross Sales": 18528.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Camp5",
    "Gross Sales": 16528.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Camp6",
    "Gross Sales": 28528.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
]
