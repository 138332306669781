import App, { ProtectedRoute } from "./App"
import AuthLayout from "./routes/authentication/AuthLayout"
import Configuration from "./routes/configuration/Configuration"
import Customers from "./routes/customers/Customers"
import CustomersList from "./routes/customers/CustomersList"
import CustomerDetails from "./routes/customers/CustomerDetails"
import Employees from "./routes/employees/Employees"
import EmployeesList from "./routes/employees/EmployeesList"
import EmployeeDetails from "./routes/employees/employeeDetails/EmployeeDetails"
import Integration from "./routes/integration/Integration"
import Inventory from "./routes/inventory/Inventory"
import InventoryList from "./routes/inventory/InventoryList"
import Management from "./routes/management/Management"
import Sales from "./routes/sales/Sales"
import Vendors from "./routes/vendors/Vendors"
import VendorsList from "./routes/vendors/VendorsList"
import VendorDetails from "./routes/vendors/VendorDetails"
import NotFound from "./routes/NotFound"
import UserProfileDetails from "../src/components/header/primary/profile/UserProfileDetails"
import Setting from "../src/components/header/primary/profile/Setting"
import CreateGroup from "./routes/management/groupManagement/CreateGroup"
import UserDetails from "./routes/management/userManagement/userDetails/UserDetails"
import CreateUser from "./routes/management/userManagement/CreateUser"
import EditUser from "./routes/management/userManagement/EditUser"
import GroupDetails from "./routes/management/groupManagement/groupDetails/GroupDetails"
import Login from "./routes/authentication/Login"
import Registration from "./routes/authentication/Registration"
import ForgotPassword from "./routes/authentication/ForgotPassword"
import NewPassword from "./routes/authentication/NewPassword"
import VerificationCode from "./routes/authentication/VerificationCode"
import Overview from "./routes/overview/Overview"
import Marketing from "./routes/marketingportal/Marketing"
import CreateCampaign from "./routes/marketingportal/CreateCampaign"
import CampaignDetails from "./routes/marketingportal/CampaignDetails"
import CampaignCustomerDetails from "./routes/marketingportal/CampaignCustomerDetails"
import Subscription from "./components/header/primary/profile/subscriptionPlan/Subscription"
import AllPackages from "./components/header/primary/profile/subscriptionPlan/AllPackages"
import SelectPOSPlatform from "./routes/authentication/SelectPOSPlatform"
import WelcomeOvvi from "./routes/authentication/WelcomeOvvi"
import InvoicesTable from "./components/header/primary/profile/subscriptionPlan/InvoicesTable"
import BusinessSynopsis from "./routes/businesssynopsis/BusinessSynopsis.jsx"
import BusinessSynopsisFallback from "./routes/businesssynopsis/BusinessSynopsisFallback.jsx"
import CustomerSentiment from "./routes/customers/CustomerSentiment.jsx"
import InventoryDetails from "./routes/inventory/inventoryDetails/InventoryDetails.jsx"

const Routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    errorElement: <NotFound />,
    children: [
      { path: "/overview", element: <Overview /> },
      { path: "/sales", element: <Sales /> },
      {
        path: "/businesssynopsis",
        element: <BusinessSynopsis />,
      },
      {
        path: "/businesssynopsis/in-process",
        element: <BusinessSynopsisFallback />,
      },
      { path: "/customers/dashboard", element: <Customers /> },
      { path: "/customers/allcustomers", element: <CustomersList /> },
      {
        path: "/customers/dashboard/customerSentiment",
        element: <CustomerSentiment />,
      },
      {
        path: "/customers/allcustomers/customer/:id",
        element: <CustomerDetails />,
      },
      { path: "/vendors/dashboard", element: <Vendors /> },
      { path: "/vendors/allvendors/vendor/:id", element: <VendorDetails /> },
      { path: "/vendors/allvendors", element: <VendorsList /> },
      { path: "/products/dashboard", element: <Inventory /> },
      { path: "/products/products", element: <InventoryList /> },
      {
        path: "/products/allproducts/product/:id",
        element: <InventoryDetails />,
      },
      { path: "/employees/dashboard", element: <Employees /> },
      { path: "/employees/allemployees", element: <EmployeesList /> },
      {
        path: "/employees/allemployees/employee/:id",
        element: <EmployeeDetails />,
      },
      { path: "/management", element: <Management /> },
      { path: "/management/users/user/:id", element: <UserDetails /> },
      { path: "/management/users/create", element: <CreateUser /> },
      { path: "/management/users/edit", element: <EditUser /> },
      { path: "/management/groups/group/:id", element: <GroupDetails /> },
      { path: "/management/groups/create", element: <CreateGroup /> },
      { path: "/management/groups/edit", element: <CreateGroup /> },
      { path: "/configuration", element: <Configuration /> },
      { path: "/setting", element: <Setting /> },
      { path: "/subscriptionPlan", element: <Subscription /> },
      { path: "/subscription/packages", element: <AllPackages /> },
      { path: "/subscription/invoices", element: <InvoicesTable /> },
      { path: "/integration", element: <Integration /> },
      { path: "/profile", element: <UserProfileDetails /> },
      { path: "/submenu/marketing", element: <Marketing /> },
      {
        path: "/submenu/marketing/createcampaign",
        element: <CreateCampaign />,
      },
      { path: "/submenu/marketing/campaign/1", element: <CampaignDetails /> },
      {
        path: "/submenu/marketing/campaigncustomerdetails/1",
        element: <CampaignCustomerDetails />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [
      { path: "login", element: <Login /> },
      { path: "/welcome", element: <WelcomeOvvi /> },
      { path: "/select-pos-platform", element: <SelectPOSPlatform /> },
      { path: "registration", element: <Registration /> },
      { path: "forgotpassword", element: <ForgotPassword /> },
      { path: "newpassword", element: <NewPassword /> },
      { path: "verificationCode", element: <VerificationCode /> },
    ],
  },
]

export default Routes
