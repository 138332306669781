import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useState, useEffect } from "react"
import { useTheme } from "../contexts/theme"
import CustomPaper from "./CustomPaper"
import { getFormattedValue } from "../utils/utils"

const FixedBottomComponent = ({ price = null }) => {
  const { theme } = useTheme()

  const [bottomPosition, setBottomPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset
      setBottomPosition(scrollY)
    }
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <CustomPaper
      watermarkBg={true}
      customStyles={{
        position: "fixed",
        bottom: 0,
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgba(0, 0, 0, 0.8) !important"
            : "rgba(233, 237, 252, 1)",
        padding: "16px",
        zIndex: 1000,
        height: "110px",
        width: "21%",
        left: "70%",
        borderRadius: "15px 15px 0 0",
        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "calc(100% - 2px) center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography component="div" variant="h3">
            {getFormattedValue(price, "Currency")}
          </Typography>
          <Typography component="div" variant="body1">
            per month
          </Typography>
        </Grid>
        <Grid item xs={6} mt={2}>
          {price && price !== 0 ? (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "accent",
                color: "rgba(255, 255, 255, 1)",
                border: "none",
                textTransform: "none",
                width: "139px",
                height: "40px",
                top: "10px",
                borderRadius: "24px",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 100, 0, 1)"
                      : "rgba(29, 71, 226, 1)",
                },
              }}
            >
              Pay Now
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </CustomPaper>
  )
}

export default FixedBottomComponent
