import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import DatePicker from "../../components/DatePicker"
import RouteTitle from "../../components/RouteTitle"
import CustomPaper from "../../components/CustomPaper"
import { useEffect, useState } from "react"
import TransitionAlerts from "../../components/alert/TransitionAlert"
import DrawerComponent from "../../components/alert/DrawerComponent"
import { useSelector, useDispatch } from "react-redux"
import { getData } from "../../api/api"
import { loadData, setChartData } from "../../redux/slices/overviewSlice"
import Masonry from "@mui/lab/Masonry"
import KPICard from "../../components/KPICard"
import LineChart from "../../components/charts/line/LineChart"
import "./styles.scss"
import RouteLoader from "../../components/loader/RouteLoader"
import PieChart from "../../components/charts/pie/PieChart"
import Table from "../../components/datagrid/Table"
import BarChart from "../../components/charts/bar/BarChart"
import LineBarChart from "../../components/charts/lineBar/LineBarChart"
import HeatMapChart from "../../components/charts/heatmap/HeatMapChart"
import ScatterPlotChart from "../../components/charts/scatterplot/ScatterPlotChart"
import StackedBarChart from "../../components/charts/stackedBar/StackedBarChart"
import ProgressBar from "../../components/charts/progressBar/ProgressBarChart"
import Locations from "../../components/Locations"
import DashLineChart from "../../components/charts/dashLine/DashLineChart"
import HorizontalBarChart from "../../components/charts/bar/horizontalBarGraph/HorizontalBarChart"
import CalendarChart from "../../components/charts/calendar/CalendarChart"
import RadialBarChart from "../../components/charts/radialBar/RadialBarChart"
import SwarmPlot from "../../components/charts/swarmPlot/SwarmPlot"

const Overview = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const overviewData = useSelector((state) => state.overview)
  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await getData("overview", {})
      await setTimeout(() => {}, 5000)
      dispatch(loadData(response))
    } catch (error) {
      console.error("Error fetching data:", error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (overviewData?.reports?.length === 0) {
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [overviewData?.reports?.length])

  const countReports = overviewData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Count")
  })

  const otherReports = overviewData?.reports?.filter(
    (report) =>
      !report.reportTypes.some(
        (type) => type.ReportType === "Count" || type.ReportType === "Calendar"
      )
  )
  const calendarReports = overviewData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Calendar")
  })

  // const [drawerOpen, setDrawerOpen] = useState(false)

  // const handleDrawerOpen = () => {
  //   setDrawerOpen(true)
  // }

  // const handleDrawerClose = () => {
  //   setDrawerOpen(false)
  // }

  // const alerts = [
  //   {
  //     message:
  //       "Two employee' schedules overlap. Please review and adjust the shifts to avoid conflicts.",
  //     btnName: "Check Now →",
  //     isOpen: handleDrawerOpen,
  //   },
  //   {
  //     message:
  //       "There are pending orders that have not been processed. Ensure prompt handling to avoid delays.",
  //     btnName: "Check Now →",
  //     isOpen: handleDrawerOpen,
  //   },
  // ]

  return (
    <>
      {isLoading ? (
        <RouteLoader />
      ) : (
        <>
          <RouteTitle
            title={`Good to See You again, ${localStorage.getItem("fname")}!`}
          >
            {/* <DatePicker /> */}
            <Locations />
          </RouteTitle>
          {/* <Stack paddingInline="30px" flexWrap="wrap">
            {alerts.map((alert, index) => (
              <TransitionAlerts
                key={index}
                message={alert.message}
                btnName={alert.btnName}
                isOpen={alert.isOpen}
                additionalStyle={{
                  marginBottom: index !== alerts.length - 1 ? "10px" : "0",
                }}
              />
            ))}
            <DrawerComponent open={drawerOpen} onClose={handleDrawerClose} />
          </Stack> */}
          <Box padding="5px 20px 15px 20px">
            <Masonry
              columns={{ xs: 1, sm: 1, md: 2 }}
              spacing={2}
              id="masonry"
              sx={{ margin: "0px" }}
            >
              <section className="dashboard_grid_container">
                {countReports?.map((report, index) => (
                  <CustomPaper
                    key={report.report_uid}
                    className={
                      index > 8
                        ? "dashboard_grid_item_other"
                        : `dashboard_grid_item_${index + 1}`
                    }
                    watermarkBg={true}
                    CustomDrawerComponent={true}
                  >
                    <KPICard
                      title={report.reportName}
                      parameters={report.reportTypes[0].reportParameterTypes}
                      dateRangeData={dateRangeData}
                      postUrl="overview/data"
                      reportUid={report.report_uid}
                      description={report.reportDescription}
                      comparisonPeriod={comparisonPeriod}
                      width="100%"
                      height="6rem"
                    />
                  </CustomPaper>
                ))}
              </section>
              {otherReports?.map(
                (report) =>
                  ({
                    Line: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <LineChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Pie: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <PieChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          comparisonPeriod={comparisonPeriod}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                        />
                      </CustomPaper>
                    ),
                    List: (
                      <Table
                        route="overview"
                        setChartData={setChartData}
                        title={report.reportName}
                        hidePageNumbers={true}
                        showExportButton={false}
                        dateRangeData={dateRangeData}
                        reportUid={report.report_uid}
                        postUrl="overview/list"
                        parameters={report.reportTypes[0].reportParameterTypes}
                        onRowClick={(params) =>
                          console.log("rowclicked", params)
                        }
                      />
                    ),
                    Bar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <BarChart
                          route="overview"
                          title={report.reportName}
                          comparisonPeriod={comparisonPeriod}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                        />
                      </CustomPaper>
                    ),
                    LineBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <LineBarChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Heatmap: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HeatMapChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    ProgressBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <ProgressBar
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    ScatterPlot: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <ScatterPlotChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    StackedBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <StackedBarChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    DualHorizontalBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HorizontalBarChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    DashLine: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <DashLineChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    RadialBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <RadialBarChart
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    SwarmPlot: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <SwarmPlot
                          route="overview"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="overview/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                  }[report.reportTypes[0].ReportType])
              )}
            </Masonry>
            {calendarReports?.map(
              (report) =>
                ({
                  Calendar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{
                        overflow: "visible",
                        margin: "10px",
                      }}
                      innerCustomStyles={{ margin: "unset" }}
                    >
                      <CalendarChart
                        route="overview"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        comparisonPeriod={comparisonPeriod}
                        postUrl="overview/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default Overview
