import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import SvgIcon from "@mui/material/SvgIcon"
import { useEffect, useState, useRef } from "react"
import Ovvi from "../../assets/svgs/Ovvi"
import { useNavigate } from "react-router-dom"
import gsap from "gsap"
import OnTheFlyLogo from "../../assets/svgs/OnTheFlyLogo"
import OvviPOSModal from "./SelectPOSModal/OvviPOSModal"
import OnTheFlyPOSModal from "./SelectPOSModal/OnTheFlyModal"

const CustomCheckIcon = ({ checked, ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M5 13l4 4L19 7"
      fill="none"
      stroke={checked ? "#ffffff" : "#ffffff90"}
      strokeWidth="3"
    />
  </SvgIcon>
)

const SelectPOSPlatform = () => {
  const [state, setState] = useState({
    isLoggedIn: false,
    ovviChecked: false,
    onTheFlyChecked: false,
    ovviOpen: false,
    onTheFlyOpen: false,
    selectedPlatform: null,
  })
  const [isOverlayVisible, setOverlayVisible] = useState(false)
  const navigate = useNavigate()
  const ovviButtonRef = useRef(null)
  const onTheFlyButtonRef = useRef(null)

  const handleClickOpen = (platform) => {
    if (!state.isLoggedIn) {
      setState((prevState) => ({
        ...prevState,
        [`${platform}Open`]: true,
        selectedPlatform: platform,
      }))
    }
  }

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      ovviOpen: false,
      onTheFlyOpen: false,
    }))
  }

  const handleLoginSuccess = () => {
    setState((prevState) => ({
      ...prevState,
      isLoggedIn: true,
      ovviChecked: prevState.selectedPlatform === "ovvi",
      onTheFlyChecked: prevState.selectedPlatform === "onTheFly",
    }))
  }

  const handleCheckboxChange = (platform, event) => {
    const checked = event.target.checked
    setState((prevState) => ({
      ...prevState,
      [`${platform}Checked`]: checked,
    }))
  }

  // const handleDoneClick = () => {
  //   if (state.ovviChecked || state.onTheFlyChecked) {
  //     navigate("/sales")
  //   }
  // }

  const handleDoneClick = async () => {
    if (!state.ovviChecked && !state.onTheFlyChecked) return

    const subscriptionStatus = localStorage.getItem("subscriptionStatus")
    // const subscriptionStatus =
    //   localStorage.getItem("subscriptionStatus") || "inactive"

    if (subscriptionStatus !== "Active") {
      navigate("/subscription/packages")
    } else {
      setOverlayVisible(true)

      setTimeout(() => {
        setOverlayVisible(false)
        navigate("/sales")
      }, 3000)
    }
  }

  // const navigateToDefaultPage = (defaultPage) => {
  //   setOverlayVisible(true)
  //   setTimeout(() => {
  //     switch (defaultPage) {
  //       case "Products Dashboard":
  //         navigate("/products/dashboard")
  //         break
  //       case "Customers Dashboard":
  //         navigate("/customers/dashboard")
  //         break
  //       case "Sales Dashboard":
  //         navigate("/sales")
  //         break
  //       case "Employees Dashboard":
  //         navigate("/employees/dashboard")
  //         break
  //       case "Customers List":
  //         navigate("/customers/allcustomers")
  //         break
  //       case "Products List":
  //         navigate("/products/products")
  //         break
  //       case "Employees List":
  //         navigate("/employees/allemployees")
  //         break
  //       default:
  //         navigate("/businesssynopsis")
  //         break
  //     }
  //     setOverlayVisible(false) // Hide overlay after navigating
  //   }, 3000) // Duration for overlay display
  // }

  useEffect(() => {
    gsap.from([ovviButtonRef.current, onTheFlyButtonRef.current], {
      opacity: 0,
      y: 20,
      duration: 1,
      ease: "power2.out",
      stagger: 0.2,
    })
  }, [])

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="body1"
          fontStyle="Comfortaa"
          color="inherit"
          textAlign="center"
          fontWeight={500}
          fontSize="20px"
          lineHeight="22px"
          mb={3}
        >
          Select Your POS Platform
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          textAlign="center"
          fontWeight={350}
          fontSize="14px"
          height="32px"
          width="465px"
          lineHeight="22px"
          mb={10}
        >
          Livelytics needs access to your POS platform to get data relevant to
          your business to serve you better.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <Button
          ref={ovviButtonRef}
          variant="outlined"
          onClick={() => handleClickOpen("ovvi")}
          sx={{
            borderColor:
              state.ovviOpen || state.ovviChecked
                ? "#1D47CE"
                : state.isLoggedIn
                ? "#1D47CE"
                : "#D9D9D9",
            borderWidth: state.ovviChecked || state.ovviOpen ? "2px" : "1px",
            borderStyle: "solid",
            marginBottom: 5,
            borderRadius: "10px",
            height: "40px",
            width: "150px",
            "&:hover": {
              borderColor: "#1D47CE90",
              borderWidth: "2px",
            },
          }}
        >
          <Checkbox
            checked={state.ovviChecked}
            onChange={(e) => handleCheckboxChange("ovvi", e)}
            disabled={!state.isLoggedIn}
            icon={<CustomCheckIcon checked={false} />}
            checkedIcon={<CustomCheckIcon checked={true} />}
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              border: state.ovviChecked ? "#1D47CE" : "#BFBFBF50",
              backgroundColor: state.ovviChecked ? "#1D47CE" : "#BFBFBF50",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: state.ovviChecked ? "#1D47CE" : "#1D47CE90",
                borderColor: state.ovviChecked ? "#1D47CE" : "#1D47CE90",
                opacity: "0.8",
              },
            }}
          />
          <Ovvi />
        </Button>

        <Button
          ref={onTheFlyButtonRef}
          variant="outlined"
          onClick={() => handleClickOpen("onTheFly")}
          sx={{
            borderColor:
              state.onTheFlyOpen || state.onTheFlyChecked
                ? "#1D47CE"
                : state.isLoggedIn
                ? "#1D47CE"
                : "#D9D9D9",
            borderWidth:
              state.onTheFlyChecked || state.onTheFlyOpen ? "2px" : "1px",
            borderStyle: "solid",
            marginBottom: 5,
            borderRadius: "10px",
            height: "40px",
            width: "150px",
            "&:hover": {
              borderColor: "#1D47CE90",
              borderWidth: "2px",
            },
          }}
        >
          <Checkbox
            checked={state.onTheFlyChecked}
            onChange={(e) => handleCheckboxChange("onTheFly", e)}
            disabled={!state.isLoggedIn}
            icon={<CustomCheckIcon checked={false} />}
            checkedIcon={<CustomCheckIcon checked={true} />}
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              border: state.onTheFlyChecked ? "#1D47CE" : "#BFBFBF50",
              backgroundColor: state.onTheFlyChecked ? "#1D47CE" : "#BFBFBF50",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: state.onTheFlyChecked
                  ? "#1D47CE"
                  : "#1D47CE90",
                borderColor: state.onTheFlyChecked ? "#1D47CE" : "#1D47CE90",
                opacity: "0.8",
              },
            }}
          />
          <OnTheFlyLogo width="110px" height="45px" />
        </Button>
      </Box>
      <OvviPOSModal
        open={state.ovviOpen}
        handleClose={handleClose}
        setIsLoggedIn={handleLoginSuccess}
      />
      <OnTheFlyPOSModal
        open={state.onTheFlyOpen}
        handleClose={handleClose}
        setIsLoggedIn={handleLoginSuccess}
      />

      <Button
        variant="contained"
        color="primary"
        disabled={!state.ovviChecked && !state.onTheFlyChecked}
        onClick={handleDoneClick}
        sx={{
          mt: 10,
          fontSize: "14px",
          fontWeight: 700,
          borderRadius: "24px",
          backgroundColor: "#1D47CE",
          textTransform: "none",
          width: "134px",
          height: "48px",
        }}
      >
        Done
      </Button>
    </>
  )
}

export default SelectPOSPlatform
