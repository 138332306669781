import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"

const InfoToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor:
      theme.palette.mode === "dark" ? "rgb(7, 19, 57)" : "#E9EDFC",
    boxShadow:
      theme.palette.mode === "dark"
        ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
        : "0px 4px 10px rgba(0, 0, 0, 0.1)",
    color: theme.palette.mode === "dark" ? "white" : "black",
  },
}))

export default InfoToolTip
