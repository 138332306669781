import React, { useEffect, useState } from "react"
import { ResponsiveBar } from "@nivo/bar"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"
import VerifiedIcon from "@mui/icons-material/Verified"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import CustomLegend from "../../CustomLegend"
import { getFormattedValue } from "../../../utils/utils"
import Box from "@mui/material/Box"
import StarLowPerformer from "../../StarLowPerformer"

const NivoBarChart = ({
  chartInput,
  parameters,
  reportTitle,
  reportData,
  showAiDropdown,
  colorFlag = "multi",
  showTooltipPrevious,
}) => {
  const { theme } = useTheme()
  const {
    keys,
    indexBy,
    margin,
    padding,
    borderRadius,
    axisTop,
    colors,
    axisRight,
    axisBottom,
    axisLeft,
    enableLabel,
    chartContainerDimensions = {
      height: "350px",
    },
    layout,
    meta_info,
    performerFlag = { icon: false, legend: false },
    groupMode,
    chartValue,
  } = parameters
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const [highestBarValue, setHighestBarValue] = useState(null)
  const [highestBarId, setHighestBarId] = useState(null)
  const [lowestBarValue, setLowestBarValue] = useState(null)
  const [lowestBarId, setLowestBarId] = useState(null)
  let stackedKeys = []

  if (groupMode === "stacked") {
    let finalIndexBy = [...new Set(chartInput.map((item) => item[indexBy]))]
    stackedKeys = [...new Set(chartInput.map((item) => item[keys]))]
    let finalOut = []
    finalIndexBy.forEach((element) => {
      const output = { [indexBy]: element }
      chartInput?.forEach((data) => {
        if (data[indexBy] === element) {
          output[data[keys]] = data[chartValue]
          output.tooltipFields = output.tooltipFields || []
          output.tooltipFields.push(data.tooltipData)
        }
      })

      finalOut.push(output)
    })

    chartInput = finalOut
  }

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: "rgba(29, 71, 206, 0.3)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  const ids = [...chartInput].map((item) => item[indexBy])

  let sortedChartInput = [...chartInput]

  days.forEach((day) => {
    if (ids.includes(day)) {
      sortedChartInput = [...chartInput].sort((a, b) => {
        const dayAIndex = days.indexOf(a[indexBy])
        const dayBIndex = days.indexOf(b[indexBy])

        return dayBIndex - dayAIndex
      })
    }
  })

  const getColor = (data) => {
    const isDarkMode = theme.palette.mode === "dark"

    if (colorFlag === "single") {
      return isDarkMode ? "#A4D0E9" : "#265BCA"
    } else {
      const colors = [
        "#7282FF",
        "#D084E4",
        "#A17BC8",
        "#2271B4",
        "#6E8B3D",
        "#C75D8A",
        "#DB7093",
        "#5C73F2",
        "#3D50F5",
        "#A17BC8",
        "#007BFF",
        "#17A2B8",
        "#8B93D1",
        "#E900EF",
        "#343AFF",
        "#8067E3",
        "#A96FFF",
        "#FF85E1",
        "#95C75B",
        "#BBC954",
      ]
      return colors[data.index % colors.length]
    }
  }

  const HighestPoint = ({ bars, yScale, xScale }) => {
    if (!bars) return null
    const highestValue = Math.max(...chartInput.map((d) => d["Gross Sales"]))
    const highestBar = bars.find((bar) => bar.data.value === highestValue)

    if (!highestBar) return null

    let x = 0
    let y = 0

    if (!!layout && layout === "vertical") {
      x = highestBar.x + highestBar.width / 2
      y = yScale(highestValue)
    } else if (!!layout && layout === "horizontal") {
      x = xScale(highestValue)
      y = highestBar.y + highestBar.height / 2
    } else return

    const colors = {
      dark: {
        fill: "#23BF6F",
        stroke: "#FFFFFF",
      },
      light: {
        fill: "#FFFFFF",
        stroke: "#23BF6F",
      },
    }

    return (
      // <circle
      //   cx={x}
      //   cy={y}
      //   r={5}
      //   fill={colors[theme.palette.mode].fill}
      //   stroke={colors[theme.palette.mode].stroke}
      //   strokeWidth={3}
      // />

      performerFlag.icon && (
        <>
          <circle cx={x} cy={y} r={12} fill="#FFFFFF" />
          <VerifiedIcon
            height={24}
            width={24}
            x={x - 12}
            y={y - 12}
            sx={{ fill: colors.dark.fill }}
          />
        </>
      )
    )
  }

  const LowestPoint = ({ bars, yScale, xScale }) => {
    if (!bars) return null
    const lowestValue = Math.min(...chartInput.map((d) => d["Gross Sales"]))
    const lowestBar = bars.find((bar) => bar.data.value === lowestValue)

    if (!lowestBar) return null

    let x = 0
    let y = 0

    if (!!layout && layout === "vertical") {
      x = lowestBar.x + lowestBar.width / 2
      y = yScale(lowestValue)
    } else if (!!layout && layout === "horizontal") {
      x = xScale(lowestValue)
      y = lowestBar.y + lowestBar.height / 2
    } else return

    const colors = {
      dark: {
        fill: "#EC2D30",
        stroke: "#FFFFFF",
      },
      light: {
        fill: "#FFFFFF",
        stroke: "#EC2D30",
      },
    }

    return (
      // <circle
      //   cx={x}
      //   cy={y}
      //   r={5}
      //   fill={colors[theme.palette.mode].fill}
      //   stroke={colors[theme.palette.mode].stroke}
      //   strokeWidth={3}
      // />

      performerFlag.icon && (
        <>
          <circle cx={x} cy={y} r={12} fill="#FFFFFF" />
          <NewReleasesIcon
            height={24}
            width={24}
            x={x - 12}
            y={y - 12}
            sx={{ fill: colors.dark.fill }}
          />
        </>
      )
    )
  }

  useEffect(() => {
    if (chartInput && chartInput.length > 0) {
      const highestBar = chartInput.reduce(
        (max, item) => (item["Gross Sales"] > max["Gross Sales"] ? item : max),
        chartInput[0]
      )

      const lowestBar = chartInput.reduce(
        (min, item) => (item["Gross Sales"] < min["Gross Sales"] ? item : min),
        chartInput[0]
      )

      setHighestBarValue(
        `${
          meta_info?.format === "Currency"
            ? getFormattedValue(highestBar["Gross Sales"], "Currency")
            : getFormattedValue(
                highestBar["Gross Sales"],
                meta_info?.format || "Number"
              )
        }`
      )
      setHighestBarId(highestBar.id)

      setLowestBarValue(
        `${
          meta_info?.format === "Currency"
            ? getFormattedValue(lowestBar["Gross Sales"], "Currency")
            : getFormattedValue(
                lowestBar["Gross Sales"],
                meta_info?.format || "Number"
              )
        }`
      )
      setLowestBarId(lowestBar.id)
    }
  }, [chartInput, meta_info])

  const calculateSalesPercentage = (apiResponse) => {
    let newId = ""
    let repeatId = ""
    let newPercentageChange = "N/A"
    let repeatPercentageChange = "N/A"

    apiResponse.forEach((item) => {
      const currentGrossSales =
        item.tooltipData?.current?.["Gross Sales"]?.value ?? null
      const previousGrossSales =
        item.tooltipData?.previous?.["Gross Sales"]?.value ?? null

      if (currentGrossSales !== null && previousGrossSales !== null) {
        let percentageChange = 0

        if (previousGrossSales === 0) {
          percentageChange = currentGrossSales * 100
        } else {
          percentageChange =
            ((currentGrossSales - previousGrossSales) / previousGrossSales) *
            100
        }

        if (item.id === "New") {
          newId = item.id
          newPercentageChange = `${
            percentageChange > 0 ? "+" : ""
          }${percentageChange.toFixed(2)}%`
        } else if (item.id === "Repeat") {
          repeatId = item.id
          repeatPercentageChange = `${
            percentageChange > 0 ? "+" : ""
          }${percentageChange.toFixed(2)}%`
        }
      }
    })

    return { newId, newPercentageChange, repeatId, repeatPercentageChange }
  }
  const { newId, newPercentageChange, repeatId, repeatPercentageChange } =
    calculateSalesPercentage(sortedChartInput)

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      {performerFlag.legend &&
        (layout === "horizontal" ? (
          <StarLowPerformer
            StarPerformer={highestBarValue}
            StarPerformerDate={highestBarId}
            lowPerformer={lowestBarValue}
            lowPerformerDate={lowestBarId}
          />
        ) : (
          <StarLowPerformer
            StarPerformerLabel={newId}
            StarPerformer={newPercentageChange}
            lowPerformerLabel={repeatId}
            lowPerformer={repeatPercentageChange}
            chartType="NewVsRepeat"
            showCurrentPrevColors={{
              current: "#febe10",
              previous: "#23bf6f",
            }}
          />
        ))}
      <Box sx={{ ...chartContainerDimensions }}>
        <ResponsiveBar
          data={sortedChartInput}
          theme={customTheme}
          keys={groupMode == "stacked" ? stackedKeys : keys}
          indexBy={indexBy}
          margin={margin}
          padding={padding}
          borderRadius={borderRadius}
          enableLabel={enableLabel}
          colors={groupMode == "stacked" ? colors : getColor}
          axisTop={axisTop}
          axisRight={axisRight}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          layout={layout ?? "vertical"}
          layers={["grid", "axes", "bars", HighestPoint, LowestPoint]}
          groupMode={groupMode}
          tooltip={(point) => {
            const isStacked = groupMode === "stacked"
            const tooltipData = isStacked
              ? (() => {
                  const Id = point?.id
                  const dataArray = point?.data?.tooltipFields || []

                  return dataArray.find((item) =>
                    Object.values(item?.current || {}).some(
                      (field) => field?.value === Id
                    )
                  )
                })()
              : point.data

            const CustomtooltipData =
              isStacked && tooltipData
                ? { tooltipData: { current: tooltipData.current } }
                : tooltipData

            return (
              <CustomTooltip
                data={CustomtooltipData}
                showCurrentPrevDate={true}
                showTooltipPrevious={showTooltipPrevious}
              />
            )
          }}
        />
      </Box>
      {performerFlag.legend ? (
        layout === "horizontal" ? (
          <CustomLegend
            StarPerformer={highestBarValue}
            StarPerformerDate={highestBarId}
            lowPerformer={lowestBarValue}
            lowPerformerDate={lowestBarId}
            reportTitle={reportTitle}
            reportData={reportData}
            showAiDropdown={showAiDropdown}
          />
        ) : (
          <CustomLegend
            StarPerformerLabel={newId}
            StarPerformer={newPercentageChange}
            lowPerformerLabel={repeatId}
            lowPerformer={repeatPercentageChange}
            chartType="NewVsRepeat"
            showCurrentPrevColors={{
              current: "#febe10",
              previous: "#23bf6f",
            }}
            reportTitle={reportTitle}
            reportData={reportData}
            showAiDropdown={showAiDropdown}
          />
        )
      ) : (
        <CustomLegend
          reportTitle={reportTitle}
          reportData={reportData}
          showAiDropdown={showAiDropdown}
        />
      )}
    </Box>
  )
}

export default NivoBarChart
