const SuccessSvg = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M58 0H0V58H58V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M28.9995 4.82812L35.3472 9.4585L43.2043 9.44355L45.618 16.9208L51.9833 21.5269L49.5411 28.9948L51.9833 36.4626L45.618 41.0688L43.2043 48.546L35.3472 48.5311L28.9995 53.1614L22.6517 48.5311L14.7947 48.546L12.3809 41.0688L6.01562 36.4626L8.45782 28.9948L6.01562 21.5269L12.3809 16.9208L14.7947 9.44355L22.6517 9.4585L28.9995 4.82812Z"
        fill="#50CD89"
        stroke="#E9FBF2"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.541 28.9948L26.5827 35.0365L38.666 22.9531"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <defs>
        <clipPath id="clip0_5779_49964">
          <rect width="58" height="58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SuccessSvg
