import { createSlice } from "@reduxjs/toolkit"

export const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employee: null,
    employee_uid: null,
    reports: [],
  },
  reducers: {
    loadData: (state, action) => {
      const { employee, employee_uid, reports } = action.payload
      state.employee = employee
      state.employee_uid = employee_uid
      state.reports = reports
    },
    setChartData: (state, action) => {
      const { reportUid, response, comparisonPeriod, viewBy } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )
      if (selectedReport) selectedReport.reportData = response
      if (selectedReport && comparisonPeriod)
        selectedReport.comparisonPeriod = comparisonPeriod
      if (selectedReport && viewBy) selectedReport.viewBy = viewBy
    },
  },
})

export const { loadData, setChartData } = employeeSlice.actions

export default employeeSlice.reducer
