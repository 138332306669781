import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import DownloadIcon from "@mui/icons-material/Download"
import CustomPaper from "../../../components/CustomPaper"
import { useTheme } from "../../../contexts/theme"

const OrderDetailsDrawer = ({ drawerOpen, handleDrawerClose }) => {
  const { theme } = useTheme()

  const colors = {
    drawerBg: theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
    accentColor: theme.palette.mode === "dark" ? "#F3884A" : "#1D47CE",
  }

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{
        style: {
          padding: "1.2rem",
          borderRadius: "10px 0px 0px 10px",
          backgroundColor: colors.drawerBg,
          backgroundImage: "unset",
          width: "450px",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
      >
        <Typography variant="h5" color="inherit" paddingY="1.2rem">
          Order Details
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon color={colors.svgStrokeColor} />
        </IconButton>
      </Box>
      <Stack display="flex" rowGap="20px">
        <CustomPaper>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="20px">
            <Stack
              rowGap="5px"
              minWidth={{
                sx: "165px",
                sm: "165px",
                md: "165px",
                lg: "165px",
              }}
            >
              <Typography
                variant="body2"
                color="inherit"
                sx={{
                  opacity: "70%",
                  fontSize: "0.85rem",
                }}
              >
                Order Number
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                sx={{ fontSize: "1.1rem" }}
              >
                #42346723
              </Typography>
            </Stack>
            <Stack
              rowGap="5px"
              minWidth={{
                sx: "165px",
                sm: "165px",
                md: "165px",
                lg: "165px",
              }}
            >
              <Button
                size="small"
                sx={{
                  textTransform: "none",
                  backgroundColor: "rgba(80, 205, 137, 0.5)",
                  border: "1px solid rgba(80, 205, 137, 0.5)",
                  color: "rgba(80, 205, 137, 1)",
                  borderRadius: "10px",
                  width: "95px",
                  padding: "2px",
                  "&:hover": {
                    backgroundColor: "rgba(80, 205, 137, 0.5)",
                  },
                  cursor: "default",
                }}
              >
                Delivered
              </Button>
            </Stack>
            <Stack
              rowGap="5px"
              minWidth={{
                sx: "165px",
                sm: "165px",
                md: "165px",
                lg: "165px",
              }}
            >
              <Typography
                variant="body2"
                color="inherit"
                sx={{
                  opacity: "70%",
                  fontSize: "0.85rem",
                }}
              >
                Order Date
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                sx={{ fontSize: "1.1rem" }}
              >
                10th Oct 2023
              </Typography>
            </Stack>
            <Stack
              rowGap="5px"
              minWidth={{
                sx: "165px",
                sm: "165px",
                md: "165px",
                lg: "165px",
              }}
            >
              <Typography
                variant="body2"
                color="inherit"
                sx={{
                  opacity: "70%",
                  fontSize: "0.85rem",
                }}
              >
                Expected Delivery Date
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                sx={{ fontSize: "1.1rem" }}
              >
                10th Oct 2023
              </Typography>
            </Stack>
            <Stack
              rowGap="5px"
              minWidth={{
                sx: "165px",
                sm: "165px",
                md: "165px",
                lg: "165px",
              }}
            >
              <Typography
                variant="body2"
                color="inherit"
                sx={{
                  opacity: "70%",
                  fontSize: "0.85rem",
                }}
              >
                Name
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                sx={{ fontSize: "1.1rem" }}
              >
                Jenny Wilson
              </Typography>
            </Stack>

            <Stack
              rowGap="5px"
              minWidth={{
                sx: "165px",
                sm: "165px",
                md: "165px",
                lg: "165px",
              }}
            >
              <Typography
                variant="body2"
                color="inherit"
                sx={{
                  opacity: "70%",
                  fontSize: "0.85rem",
                }}
              >
                Contact
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                sx={{ fontSize: "1.1rem" }}
              >
                (219) 555-0114
              </Typography>
            </Stack>

            <Stack rowGap="5px">
              <Typography
                variant="body2"
                color="inherit"
                sx={{
                  opacity: "70%",
                  fontSize: "0.85rem",
                }}
              >
                Address
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                sx={{ fontSize: "1.1rem" }}
              >
                2118 Thornridge Cir. Syracuse, Connecticut 35624
              </Typography>
            </Stack>
          </Box>
        </CustomPaper>

        <CustomPaper>
          <Stack display="flex" rowGap="20px">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Stack rowGap="5px">
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  Onions
                </Typography>
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  10kg
                </Typography>
              </Stack>
              <Typography
                fontWeight="bold"
                color="inherit"
                sx={{ fontSize: "1.15rem" }}
              >
                $20.00
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Stack rowGap="5px">
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  Tomoto
                </Typography>
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  5kg
                </Typography>
              </Stack>
              <Typography
                fontWeight="bold"
                color="inherit"
                sx={{ fontSize: "1.15rem" }}
              >
                $24.00
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Stack rowGap="5px">
                <Typography fontSize="1.25rem" color="inherit">
                  Total Amount
                </Typography>
              </Stack>
              <Typography
                fontWeight="bold"
                color="inherit"
                sx={{ fontSize: "1.15rem" }}
              >
                $54,00
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Stack rowGap="5px">
                <Typography fontSize="1.25rem" color="inherit">
                  Payment Status
                </Typography>
              </Stack>
              <Button
                size="small"
                sx={{
                  textTransform: "none",
                  backgroundColor: "rgba(80, 205, 137, 0.5)",
                  border: "1px solid rgba(80, 205, 137, 0.5)",
                  color: "rgba(80, 205, 137, 1)",
                  borderRadius: "10px",
                  width: "auto",
                  padding: "2px",
                  "&:hover": {
                    backgroundColor: "rgba(80, 205, 137, 0.5)",
                  },
                  cursor: "default",
                }}
              >
                Paid
              </Button>
            </Box>
          </Stack>
        </CustomPaper>
        <Button
          sx={{
            borderRadius: "20px",
            textTransform: "none",
          }}
          variant="outlined"
          color="warning"
          endIcon={<DownloadIcon color="warning" />}
        >
          Download Invoice
        </Button>
      </Stack>
    </Drawer>
  )
}

export default OrderDetailsDrawer
