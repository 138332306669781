// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employee_grid_container {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  gap: 20px;
}

@media (max-width: 600px) {
  .employee_grid_item_1,
  .employee_grid_item_2,
  .employee_grid_item_3,
  .employee_grid_item_4,
  .employee_grid_item_5,
  .employee_grid_item_6 {
    grid-column: span 3;
  }
}
@media (min-width: 600px) {
  .employee_grid_item_1,
  .employee_grid_item_2,
  .employee_grid_item_3,
  .employee_grid_item_4,
  .employee_grid_item_5,
  .employee_grid_item_6 {
    grid-column: span 3;
  }
}
@media (min-width: 900px) {
  .employee_grid_item_1,
  .employee_grid_item_2,
  .employee_grid_item_3,
  .employee_grid_item_4,
  .employee_grid_item_5,
  .employee_grid_item_6 {
    grid-column: span 3;
  }
}
@media (min-width: 1100px) {
  .employee_grid_item_1,
  .employee_grid_item_2,
  .employee_grid_item_3,
  .employee_grid_item_4,
  .employee_grid_item_5,
  .employee_grid_item_6 {
    grid-column: span 2;
  }
}
@media (min-width: 1400px) {
  .employee_grid_item_1,
  .employee_grid_item_2,
  .employee_grid_item_3,
  .employee_grid_item_4,
  .employee_grid_item_5,
  .employee_grid_item_6 {
    grid-column: span 2;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/employees/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AACF;;AAEA;EACE;;;;;;IAME,mBAAA;EACF;AACF;AAEA;EACE;;;;;;IAME,mBAAA;EAAF;AACF;AAGA;EACE;;;;;;IAME,mBAAA;EADF;AACF;AAIA;EACE;;;;;;IAME,mBAAA;EAFF;AACF;AAKA;EACE;;;;;;IAME,mBAAA;EAHF;AACF","sourcesContent":[".employee_grid_container {\n  display: grid;\n  grid-template-columns: repeat(4, 2fr);\n  gap: 20px;\n}\n\n@media (max-width: 600px) {\n  .employee_grid_item_1,\n  .employee_grid_item_2,\n  .employee_grid_item_3,\n  .employee_grid_item_4,\n  .employee_grid_item_5,\n  .employee_grid_item_6 {\n    grid-column: span 3;\n  }\n}\n\n@media (min-width: 600px) {\n  .employee_grid_item_1,\n  .employee_grid_item_2,\n  .employee_grid_item_3,\n  .employee_grid_item_4,\n  .employee_grid_item_5,\n  .employee_grid_item_6 {\n    grid-column: span 3;\n  }\n}\n\n@media (min-width: 900px) {\n  .employee_grid_item_1,\n  .employee_grid_item_2,\n  .employee_grid_item_3,\n  .employee_grid_item_4,\n  .employee_grid_item_5,\n  .employee_grid_item_6 {\n    grid-column: span 3;\n  }\n}\n\n@media (min-width: 1100px) {\n  .employee_grid_item_1,\n  .employee_grid_item_2,\n  .employee_grid_item_3,\n  .employee_grid_item_4,\n  .employee_grid_item_5,\n  .employee_grid_item_6 {\n    grid-column: span 2;\n  }\n}\n\n@media (min-width: 1400px) {\n  .employee_grid_item_1,\n  .employee_grid_item_2,\n  .employee_grid_item_3,\n  .employee_grid_item_4,\n  .employee_grid_item_5,\n  .employee_grid_item_6 {\n    grid-column: span 2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
