const PayCom = () => {
  return (
    <svg
      width="138"
      height="30"
      viewBox="0 0 138 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.91632 0C1.40808 0 0.920657 0.201898 0.561277 0.561277C0.201898 0.920657 0 1.40808 0 1.91632L0 28.0837C0 28.5919 0.201898 29.0793 0.561277 29.4387C0.920657 29.7981 1.40808 30 1.91632 30H5.95497C6.46321 30 6.95063 29.7981 7.31001 29.4387C7.66939 29.0793 7.87129 28.5919 7.87129 28.0837V9.7924C7.87129 9.28416 8.07318 8.79673 8.43256 8.43736C8.79194 8.07798 9.27937 7.87608 9.78761 7.87608H20.2076C20.7158 7.87608 21.2033 8.07798 21.5626 8.43736C21.922 8.79673 22.1239 9.28416 22.1239 9.7924V20.1836C22.1239 20.6919 21.922 21.1793 21.5626 21.5387C21.2033 21.8981 20.7158 22.1 20.2076 22.1H14.2095C13.7013 22.1 13.2139 22.3019 12.8545 22.6612C12.4951 23.0206 12.2932 23.508 12.2932 24.0163V28.0549C12.2932 28.5632 12.4951 29.0506 12.8545 29.41C13.2139 29.7694 13.7013 29.9713 14.2095 29.9713H28.1028C28.6111 29.9713 29.0985 29.7694 29.4579 29.41C29.8173 29.0506 30.0192 28.5632 30.0192 28.0549V1.91632C30.0192 1.40808 29.8173 0.920657 29.4579 0.561277C29.0985 0.201898 28.6111 0 28.1028 0L1.91632 0Z"
        fill="#00833E"
      />
      <path
        d="M36.6785 12.7437H39.2847V14.4061H39.3422C40.0656 13.5294 41.7376 12.4658 44.339 12.4658C48.6076 12.4658 50.6868 15.2636 50.6868 18.4256C50.6868 21.5875 48.8136 24.8021 44.3677 24.8021C41.7711 24.8021 40.2955 23.9925 39.4571 22.8858H39.4044V29.3534H36.6785V12.7437ZM43.7497 14.224C40.8081 14.224 39.3949 16.4613 39.3949 18.6555C39.3949 20.6198 40.6644 23.0439 43.7066 23.0439C46.6242 23.0439 47.8362 20.4377 47.7788 18.5885C47.8506 16.4613 46.696 14.224 43.7497 14.224Z"
        fill="black"
      />
      <path
        d="M54.0547 13.5771C55.5733 12.8405 57.239 12.4572 58.9269 12.4561C63.1428 12.4561 64.8483 14.1233 64.8483 17.3331V22.2149C64.8296 22.9863 64.8664 23.758 64.9585 24.5241H62.4194V22.9958H62.3619C61.7295 23.7144 60.2875 24.802 57.8059 24.802C54.6296 24.802 52.4066 23.6905 52.4066 21.1274C52.4066 18.1715 56.4453 17.2469 59.2479 17.2469C60.3162 17.2469 61.0636 17.2469 62.1224 17.2948C62.1224 15.2635 61.1977 14.2239 58.4861 14.2239C56.9779 14.2168 55.4976 14.6316 54.2128 15.4216L54.0547 13.5771ZM62.108 18.9093C61.5331 18.9093 60.9534 18.8661 60.3785 18.8661C58.9029 18.8661 55.2667 19.0482 55.2667 21.1274C55.2667 22.373 56.771 23.0437 58.0119 23.0437C60.6947 23.0437 62.108 21.7071 62.108 19.9489V18.9093Z"
        fill="black"
      />
      <path
        d="M67.3635 27.7096C67.7775 27.8229 68.204 27.8841 68.6331 27.8917C71.0572 27.8917 71.8669 25.2136 71.8669 24.9837C71.7447 24.5486 71.5693 24.1302 71.3447 23.7381L66.3527 12.7432H69.3278L73.2993 22.4877H73.3568L77.4002 12.7432H80.1406L74.6934 24.7777C73.6826 27.0389 72.619 29.6499 69.0403 29.6499C68.4204 29.6467 67.8019 29.5922 67.1911 29.487L67.3635 27.7096Z"
        fill="black"
      />
      <path
        d="M91.2935 14.7322C90.3444 14.4081 89.3499 14.2367 88.3471 14.2244C85.3146 14.2244 83.6138 16.3946 83.6138 18.6367C83.6138 20.7351 85.0511 23.0442 88.5196 23.0442C89.5301 23.0422 90.5272 22.813 91.4372 22.3735L91.6384 24.2899C90.5387 24.6428 89.3866 24.8048 88.2322 24.7689C83.7863 24.7689 80.725 21.9711 80.725 18.6032C80.725 15.0197 83.7288 12.4326 88.2322 12.4326C89.3404 12.454 90.4408 12.6231 91.5043 12.9357L91.2935 14.7322Z"
        fill="black"
      />
      <path
        d="M100.329 12.4561C104.804 12.4561 107.803 15.0431 107.803 18.6266C107.803 21.9802 104.746 24.7924 100.329 24.7924C95.9118 24.7924 92.8218 21.9945 92.8218 18.6266C92.8218 15.0527 95.816 12.4561 100.329 12.4561ZM100.329 23.0341C103.5 23.0341 104.919 20.725 104.919 18.6266C104.864 17.4419 104.356 16.3236 103.498 15.5044C102.641 14.6851 101.501 14.2279 100.315 14.2279C99.1286 14.2279 97.9883 14.6851 97.1309 15.5044C96.2734 16.3236 95.7647 17.4419 95.7106 18.6266C95.7106 20.7346 97.1239 23.0437 100.329 23.0437V23.0341Z"
        fill="black"
      />
      <path
        d="M110.462 12.7435H113.001V14.4538H113.063C114.103 13.0932 115.573 12.4656 117.739 12.4656C119.44 12.4656 121.145 13.1603 121.921 14.7556C122.927 13.0693 125.15 12.4561 126.477 12.4561C130.233 12.4561 131.589 14.3963 131.589 16.9594V24.5241H128.873V17.5008C128.873 16.0635 128.441 14.2239 126.247 14.2239C123.478 14.2239 122.381 16.3941 122.381 18.5883V24.5241H119.669V17.5008C119.669 16.0635 119.234 14.2239 117.039 14.2239C114.27 14.2239 113.173 16.3941 113.173 18.5883V24.5241H110.462V12.7435Z"
        fill="black"
      />
      <path
        d="M133.103 14.7894C133.099 14.3935 133.213 14.0054 133.43 13.6743C133.647 13.3433 133.958 13.0842 134.323 12.9301C134.687 12.7759 135.09 12.7336 135.479 12.8085C135.867 12.8834 136.225 13.0722 136.506 13.3508C136.788 13.6294 136.98 13.9853 137.059 14.3734C137.137 14.7614 137.099 15.1641 136.948 15.5302C136.797 15.8964 136.541 16.2096 136.213 16.4299C135.884 16.6503 135.497 16.768 135.101 16.768C134.574 16.768 134.069 16.5602 133.695 16.1897C133.321 15.8191 133.108 15.3159 133.103 14.7894ZM136.61 14.7894C136.626 14.5811 136.6 14.3716 136.531 14.1742C136.463 13.9767 136.355 13.7955 136.213 13.642C136.071 13.4885 135.899 13.366 135.708 13.2822C135.516 13.1984 135.31 13.1552 135.101 13.1552C134.892 13.1552 134.685 13.1984 134.494 13.2822C134.302 13.366 134.13 13.4885 133.988 13.642C133.847 13.7955 133.738 13.9767 133.67 14.1742C133.602 14.3716 133.575 14.5811 133.592 14.7894C133.575 14.9977 133.602 15.2071 133.67 15.4046C133.738 15.6021 133.847 15.7833 133.988 15.9368C134.13 16.0903 134.302 16.2128 134.494 16.2966C134.685 16.3804 134.892 16.4236 135.101 16.4236C135.31 16.4236 135.516 16.3804 135.708 16.2966C135.899 16.2128 136.071 16.0903 136.213 15.9368C136.355 15.7833 136.463 15.6021 136.531 15.4046C136.6 15.2071 136.626 14.9977 136.61 14.7894ZM134.334 13.6492H135.173C135.714 13.6492 136.011 13.8408 136.011 14.3151C136.017 14.3954 136.006 14.476 135.979 14.5517C135.951 14.6274 135.908 14.6964 135.852 14.754C135.796 14.8116 135.728 14.8565 135.653 14.8857C135.578 14.915 135.497 14.9279 135.417 14.9235L136.025 15.9152H135.58L134.995 14.9571H134.741V15.9152H134.32L134.334 13.6492ZM134.756 14.6073H135.125C135.374 14.6073 135.604 14.5738 135.604 14.2672C135.604 13.9606 135.359 13.9558 135.125 13.9558H134.751L134.756 14.6073Z"
        fill="black"
      />
    </svg>
  );
};

export default PayCom;
