import { useTheme } from "../../contexts/theme"

const Reload = () => {
  const { theme } = useTheme()
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3861_21226)">
          <path
            d="M8.66671 11.9413V13.288C11.3 12.9613 13.3334 10.7213 13.3334 8.0013C13.3334 5.2813 11.3 3.0413 8.66671 2.71464V0.667969L5.63338 3.7013L8.66671 6.66797V4.0613C10.56 4.3813 12 6.0213 12 8.0013C12 9.9813 10.56 11.6213 8.66671 11.9413ZM3.79338 11.2613L4.73338 10.3146C4.38671 9.81464 4.15338 9.25463 4.06005 8.66797H2.71338C2.82671 9.59464 3.19338 10.488 3.79338 11.2613ZM4.74005 5.68797L3.80005 4.7413C3.20005 5.51464 2.82671 6.40797 2.71338 7.33463H4.06005C4.15338 6.75463 4.38671 6.18797 4.74005 5.68797ZM7.33338 11.9346C6.75338 11.8346 6.19338 11.608 5.69338 11.248L4.73338 12.2146C5.50671 12.8146 6.40671 13.1746 7.33338 13.288V11.9346Z"
            fill={theme.palette.accent}
          />
        </g>
        <defs>
          <clipPath id="clip0_3861_21226">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Reload
