import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme } from "../contexts/theme"

const RouteTitle = ({ title, children }) => {
  const { theme } = useTheme()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="20px 30px 5px 30px"
      height="79px"
      sx={{
        position: "sticky",
        top: {
          xs: 153.5,
          sm: 110.5,
          md: 110,
        },
        zIndex: 5,
        backgroundColor: theme.palette.mode === "light" ? "#f6f7fe" : "#071339",
      }}
    >
      {title && (
        <Typography
          variant="subtitle1"
          color="inherit"
          sx={{ maxWidth: { md: "1250px" } }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  )
}

export default RouteTitle
