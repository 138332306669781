import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Chip from "@mui/material/Chip"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import Star from "../../../../assets/svgs/Star"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { useNavigate } from "react-router-dom"
import CustomPaper from "../../../../components/CustomPaper"
import { useTheme } from "../../../../contexts/theme"
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs"
import Table from "../../../../components/datagrid/Table"
import "./styles.scss"

const GroupDetailsCard = ({ title, children }) => (
  <Stack
    display="flex"
    flexDirection="column"
    // justifyContent="space-between"
    // height="100%"
  >
    <Typography color="inherit" variant="subtitle2">
      {title}
    </Typography>
    {children}
  </Stack>
)

const GroupDetails = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  const handleEditClick = (id) => {
    navigate("/management/groups/create")
  }

  return (
    <>
      <Box p="30px 30px 15px 30px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "Management",
              // isActive: false,
              clickHandler: () => {
                navigate("/management")
              },
            },
            {
              text: "Groups",
              // isActive: false,
              // clickHandler: () => {
              //   navigate("/employees/overview")
              // },
            },
            {
              text: "Manager",
              isActive: true,
            },
          ]}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        p="15px 30px 15px 30px"
      >
        <Typography component="div" variant="h6">
          Group Details
        </Typography>
        <Box>
          <IconButton size="small">
            <EditIcon sx={{ color: "inherit" }} />
          </IconButton>
          <IconButton size="small">
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        p="10px 30px"
      >
        <div className="group-details-grid">
          <div className="group-details-grid-item">
            <CustomPaper customStyles={{ height: "100%" }}>
              <Box>
                {/* Personal Information */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  borderBottom="2px solid #091743"
                >
                  <Typography mb="10px" color="inherit">
                    Personal Information
                  </Typography>
                </Box>

                {/* Personal Information Details */}
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  gap="30px"
                  mt={1.5}
                >
                  {Object.entries({
                    name: {
                      label: "Group Name",
                      value: "Manager",
                    },
                    streetAddress: {
                      label: "Group Members",
                      value: "7",
                    },
                    state: {
                      label: "Created Date",
                      value: "5th Nov 2023",
                    },
                    rank: {
                      label: "Description",
                      value:
                        "The Managers Group is a vital part of our organization, consisting of experienced and skilled individuals responsible for overseeing various departments and teams.",
                    },
                  }).map(([key, value]) => (
                    <Stack
                      key={key}
                      rowGap="5px"
                      minWidth={{
                        sx: "180px",
                        sm: "180px",
                        md: "180px",
                        lg: "250px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="inherit"
                        sx={{
                          opacity: "70%",
                          fontSize: "0.85rem",
                        }}
                      >
                        {value.label}
                      </Typography>
                      <Typography
                        variant="body1"
                        color={
                          value.label === "Total Purchased Amount"
                            ? "#50CD89"
                            : "inherit"
                        }
                        sx={{ fontSize: "1.1rem" }}
                      >
                        {value.value}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </CustomPaper>
          </div>
          <div className="group-details-grid-item">
            <div
              className="group-details-cards-grid"
              style={{ height: "100%" }}
            >
              {[
                {
                  title: "Total Leaves",
                  value: "36",
                },
                {
                  title: "Performance",
                  value: (
                    <>
                      4.3 <Star />
                    </>
                  ),
                },
                {
                  title: "Anual Income",
                  value: "$12,360",
                },
                {
                  title: "Status",
                  value: (
                    <Chip
                      size="small"
                      label="Active"
                      sx={{
                        backgroundColor: "rgba(80, 205, 137, 0.3)",
                        color: "rgb(80, 205, 137)",
                        maxWidth: "fit-content",
                      }}
                    />
                  ),
                },
              ].map((item) => (
                <Box
                  key={item.title}
                  classname="group-details-cards-grid-item"
                  sx={{ height: "100%" }}
                >
                  <CustomPaper
                    watermarkBg={true}
                    customStyles={{ height: "100%" }}
                  >
                    <GroupDetailsCard title={item.title}>
                      <Typography color="inherit" variant="h6">
                        {item.value}
                      </Typography>
                    </GroupDetailsCard>
                  </CustomPaper>
                </Box>
              ))}
            </div>
          </div>
        </div>
      </Box>

      <Box p="10px 30px">
        <CustomPaper
          customStyles={{
            background: "transparent",
            border:
              theme.palette.mode === "dark"
                ? "2px solid rgba(13, 34, 102, 1)"
                : "none",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            borderBottom="2px solid #091743"
            mb={1.5}
          >
            <Typography mb="10px" color="inherit">
              Roles
            </Typography>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
          >
            {[
              "Vendor Details",
              "Vendor Details",
              "Customer Details",
              "Employee Details",
              "Sales Details",
              "Sales Details",
              "Sales Details",
              "Sales Details",
              "Sales Details",
            ].map((item, index) => (
              <Typography
                key={index}
                variant="body1"
                color="inherit"
                sx={{
                  minWidth: {
                    sx: "200px",
                    sm: "200px",
                    md: "200px",
                    lg: "400px",
                  },
                }}
              >{`${index + 1}. ${item}`}</Typography>
            ))}
          </Box>
        </CustomPaper>
      </Box>

      <Box p="10px 30px 20px 30px">
        <Table title="Groups" />
      </Box>
    </>
  )
}

export default GroupDetails
