import Box from "@mui/material/Box"
import { ResponsiveStream } from "@nivo/stream"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"
import CustomLegend from "../../CustomLegend"

const NivoStreamChart = ({
  chartInput,
  parameters,
  legendsData,
  reportTitle,
  reportData,
  showAiDropdown,
}) => {
  const { theme } = useTheme()
  const {
    keys,
    margin,
    legends,
    dotBorderColor,
    dotBorderWidth,
    dotColor,
    dotSize,
    fill,
    defs,
    borderColor,
    fillOpacity,
    // colors,
    offsetType,
    axisTop,
    axisBottom,
    axisLeft,
    enableGridX,
    enableGridY,
    axisRight,
  } = parameters
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      ticks: {
        line: {
          transform: "translateY(-2px)",
        },
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  const colors = [
    "#6E8B3D",
    "#C75D8A",
    "#DB7093",
    "#A96FFF",
    "#FF85E1",
    "#95C75B",
  ]

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box height="420px">
        <ResponsiveStream
          data={chartInput}
          keys={keys}
          margin={margin}
          theme={customTheme}
          axisTop={axisTop}
          axisRight={axisRight}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          enableGridX={enableGridX}
          enableGridY={enableGridY}
          offsetType={offsetType}
          colors={colors}
          fillOpacity={fillOpacity}
          borderColor={borderColor}
          defs={defs}
          fill={fill}
          dotSize={dotSize}
          dotColor={dotColor}
          dotBorderWidth={dotBorderWidth}
          dotBorderColor={dotBorderColor}
          // legends={legends}
          // tooltip={(point) => (
          //   <CustomTooltip data={point.data} showCurrentPrevDate={true} />
          // )}
        />
      </Box>
      <CustomLegend
        legendsData={legendsData}
        alignment="center"
        reportTitle={reportTitle}
        reportData={reportData}
        showAiDropdown={showAiDropdown}
      />
    </Box>
  )
}

export default NivoStreamChart
