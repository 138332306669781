import { useTheme } from "../contexts/theme"
import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Collapse from "@mui/material/Collapse"
import { aiFeedbackCreate, aiFeedbackUpdate, aiReportApi } from "../api/api"
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import CloseIcon from "@mui/icons-material/Close"
import ThumbsDown from "@mui/icons-material/ThumbDownAlt"
import ThumbsUp from "@mui/icons-material/ThumbUpAlt"
import TextField from "@mui/material/TextField"
import SendIcon from "@mui/icons-material/Send"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import CircularProgress from "@mui/material/CircularProgress"

const CustomLegend = ({
  reportTitle = "",
  reportData = [],
  legendsData = [],
  alignment = "flex-end",
  additionalInfo = null,
  showAiDropdown,
}) => {
  const { theme } = useTheme()
  const [activeSection, setActiveSection] = useState(null)
  const [open, setOpen] = useState(false)
  const [aiResponse, setAiResponse] = useState([])
  const [loading, setLoading] = useState(false)
  const [createFeedbackResponse, setCreateFeedbackResponse] = useState(false)

  // analysis-start
  const [showAnalysisText, setAnalysisShowText] = useState(false)
  const [analysisfeedback, setAnalysisFeedback] = useState(null)
  const [analysisfeedbackText, setAnalysisFeedbackText] = useState("")
  const [analysisLoading, setAnalysisLoading] = useState(false)
  const [analysisFeedbackMessage, setAnalysisFeedbackMessage] = useState(false)
  const [analysisAlertOpen, setAnalysisAlertOpen] = useState(false)

  const handleAnalysisFeedback = (event, value) => {
    setAnalysisFeedback(value)
    setAnalysisShowText(!!value)
  }
  // analysis-end

  // recommendation-start
  const [showRecommendationText, setRecommendationShowText] = useState(false)
  const [recommendationfeedback, setRecommendationFeedback] = useState(null)
  const [recommendationfeedbackText, setRecommendationFeedbackText] =
    useState("")
  const [recommendationLoading, setRecommendationLoading] = useState(false)
  const [recommendationFeedbackMessage, setRecommendationFeedbackMessage] =
    useState(false)
  const [recommendationAlertOpen, setRecommendationAlertOpen] = useState(false)

  const handleRecommendationFeedback = (event, value) => {
    setRecommendationFeedback(value)
    setRecommendationShowText(!!value)
  }
  // recommendation-end

  // anomaly-start
  const [showAnomalyText, setAnomalyShowText] = useState(false)
  const [anomalyfeedback, setAnomalyFeedback] = useState(null)
  const [anomalyfeedbackText, setAnomalyFeedbackText] = useState("")
  const [anomalyLoading, setAnomalyLoading] = useState(false)
  const [anomalyFeedbackMessage, setAnomalyFeedbackMessage] = useState(false)
  const [anomalyAlertOpen, setAnomalyAlertOpen] = useState(false)

  const handleAnomalyFeedback = (event, value) => {
    setAnomalyFeedback(value)
    setAnomalyShowText(!!value)
  }
  // anomaly-end

  const handleFeedbackUpdate = (type, thumbs, comment) => {
    const enableLoading = {
      1: () => {
        setAnalysisLoading(true)
        setAnalysisFeedbackMessage(false)
      },
      2: () => {
        setRecommendationLoading(true)
        setRecommendationFeedbackMessage(false)
      },
      3: () => {
        setAnomalyLoading(true)
        setAnomalyFeedbackMessage(false)
      },
    }

    const disbaleLoading = {
      1: (success) => {
        setAnalysisLoading(false)
        setAnalysisShowText(false)
        setAnalysisFeedback(null)
        setAnalysisFeedbackText("")
        setAnalysisFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setAnalysisAlertOpen(true)
      },
      2: (success) => {
        setRecommendationLoading(false)
        setRecommendationShowText(false)
        setRecommendationFeedback(null)
        setRecommendationFeedbackText("")
        setRecommendationFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setRecommendationAlertOpen(true)
      },
      3: (success) => {
        setAnomalyLoading(false)
        setAnomalyShowText(false)
        setAnomalyFeedback(null)
        setAnomalyFeedbackText("")
        setAnomalyFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setAnomalyAlertOpen(true)
      },
    }

    enableLoading[type]()

    const feedbackId = createFeedbackResponse?.msg?.Id
    const like = thumbs === "like"

    if (feedbackId && type) {
      aiFeedbackUpdate(type, feedbackId, like, comment)
        .then(
          (feedbackUpdationResponse) => {
            disbaleLoading[type](true)
          }
          // Todo: Handle after successfull update
        )
        .catch((error) => {
          disbaleLoading[type](false)
        })
    } else {
      console.log("Error while submitting feedback")
    }
  }

  const fetchAiReport = async () => {
    setLoading(true)
    try {
      const aiResponseData = await aiReportApi(reportTitle, reportData)
      setAiResponse(aiResponseData)
    } finally {
      setLoading(false)
    }
  }

  const handleSectionClick = async (label) => {
    if (aiResponse.length === 0) {
      setLoading(true)

      fetchAiReport().then(() => {
        setLoading(false)
        const fname = localStorage.getItem("fname") || ""
        const lname = localStorage.getItem("lname") || ""
        const fullName = `${fname} ${lname}`
        const userData = JSON.parse(localStorage.getItem("user"))
        const feedbackCreationData = {
          userID: userData?.userId || 1,
          entityID: localStorage.getItem("entityId"),
          parentURL: window.location.href,
          question: null,
          fullAnswer: aiResponse?.msg,
          answerInsights: aiResponse?.msg?.["Analysis"]?.replace(/'/g, ""),
          answerRecommendations: aiResponse?.msg?.["Recommendations"]?.replace(
            /'/g,
            ""
          ),
          answerAnomalies: aiResponse?.msg?.["Anomalies"]?.replace(/'/g, ""),
          userName: fullName,
        }

        aiFeedbackCreate(feedbackCreationData).then(
          (feedbackCreationResponse) =>
            setCreateFeedbackResponse(feedbackCreationResponse)
        )
      })
    }

    if (activeSection === label) {
      setOpen(!open)
    } else {
      setActiveSection(label)
      setOpen(true)
    }
  }

  useEffect(() => {
    setActiveSection(null)
    setOpen(false)
  }, [reportData, reportTitle])

  const colors = ["#FCBF49", "#50CD89", "#FF0000"]
  const types = ["warning", "success", "error"]

  const sections =
    aiResponse?.msg?.map((item, idx) => {
      const key = Object.keys(item)[0]
      return {
        key,
        color: colors[idx] || "#505050",
        type: types[idx],
        string: item[key],
      }
    }) || []

  const handleSectionRender = (section) => (
    <Alert
      severity={section.type}
      sx={{
        borderLeft: `4px solid ${section.color}`,
        borderRadius: "10px",
        ".MuiAlert-message": {
          width: "100%",
        },
      }}
      icon={false}
    >
      <Stack display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="body2"
          color="inherit"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          {section.string || "Loading..."}
        </Typography>

        {createFeedbackResponse &&
          {
            Analysis: (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {!!analysisFeedbackMessage ? (
                    <Collapse in={analysisAlertOpen}>
                      <Alert
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setAnalysisAlertOpen(false)
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        variant="outlined"
                        severity={analysisFeedbackMessage?.severity}
                      >
                        {analysisFeedbackMessage?.message}
                      </Alert>
                    </Collapse>
                  ) : (
                    <div />
                  )}
                  <ToggleButtonGroup
                    value={analysisfeedback}
                    exclusive
                    onChange={handleAnalysisFeedback}
                    aria-label="feedback buttons"
                  >
                    <ToggleButton value="like" aria-label="good feedback">
                      <ThumbsUp fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="dislike" aria-label="bad feedback">
                      <ThumbsDown fontSize="small" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {showAnalysisText && (
                  <TextField
                    disabled={analysisLoading}
                    hiddenLabel
                    size="small"
                    multiline
                    rows={2}
                    value={analysisfeedbackText}
                    onChange={(e) => setAnalysisFeedbackText(e.target.value)}
                    placeholder="Please let us know your feedback on this response."
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "0.8rem",
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: "0.8rem",
                      },
                      minWidth: "300px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={
                              analysisfeedback === "dislike" &&
                              ["", null, undefined].includes(
                                analysisfeedbackText
                              )
                            }
                            onClick={() =>
                              handleFeedbackUpdate(
                                1,
                                analysisfeedback,
                                analysisfeedbackText
                              )
                            }
                            sx={{ borderRadius: "8px" }}
                          >
                            {analysisLoading ? (
                              <CircularProgress
                                size="20px"
                                sx={{
                                  color:
                                    theme.palette.mode === "dark"
                                      ? "#FFFFFF"
                                      : "#707070",
                                }}
                              />
                            ) : (
                              <SendIcon fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </>
            ),
            Recommendations: (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {!!recommendationFeedbackMessage ? (
                    <Collapse in={recommendationAlertOpen}>
                      <Alert
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setRecommendationAlertOpen(false)
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        variant="outlined"
                        severity={recommendationFeedbackMessage.severity}
                      >
                        {recommendationFeedbackMessage.message}
                      </Alert>
                    </Collapse>
                  ) : (
                    <div />
                  )}
                  <ToggleButtonGroup
                    value={recommendationfeedback}
                    exclusive
                    onChange={handleRecommendationFeedback}
                    aria-label="feedback buttons"
                  >
                    <ToggleButton value="like" aria-label="good feedback">
                      <ThumbsUp fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="dislike" aria-label="bad feedback">
                      <ThumbsDown fontSize="small" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {showRecommendationText && (
                  <TextField
                    disabled={recommendationLoading}
                    hiddenLabel
                    size="small"
                    multiline
                    rows={2}
                    value={recommendationfeedbackText}
                    onChange={(e) =>
                      setRecommendationFeedbackText(e.target.value)
                    }
                    placeholder="Please let us know your feedback on this response."
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "0.8rem",
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: "0.8rem",
                      },
                      minWidth: "300px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={
                              recommendationfeedback === "dislike" &&
                              ["", null, undefined].includes(
                                recommendationfeedbackText
                              )
                            }
                            onClick={() =>
                              handleFeedbackUpdate(
                                2,
                                recommendationfeedback,
                                recommendationfeedbackText
                              )
                            }
                            sx={{ borderRadius: "8px" }}
                          >
                            {recommendationLoading ? (
                              <CircularProgress
                                size="20px"
                                sx={{
                                  color:
                                    theme.palette.mode === "dark"
                                      ? "#FFFFFF"
                                      : "#707070",
                                }}
                              />
                            ) : (
                              <SendIcon fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </>
            ),
            Anomalies: (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {!!anomalyFeedbackMessage ? (
                    <Collapse in={anomalyAlertOpen}>
                      <Alert
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setAnomalyAlertOpen(false)
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        variant="outlined"
                        severity={anomalyFeedbackMessage.severity}
                      >
                        {anomalyFeedbackMessage.message}
                      </Alert>
                    </Collapse>
                  ) : (
                    <div />
                  )}
                  <ToggleButtonGroup
                    value={anomalyfeedback}
                    exclusive
                    onChange={handleAnomalyFeedback}
                    aria-label="feedback buttons"
                  >
                    <ToggleButton value="like" aria-label="good feedback">
                      <ThumbsUp fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="dislike" aria-label="bad feedback">
                      <ThumbsDown fontSize="small" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {showAnomalyText && (
                  <TextField
                    disabled={anomalyLoading}
                    hiddenLabel
                    size="small"
                    multiline
                    rows={2}
                    value={anomalyfeedbackText}
                    onChange={(e) => setAnomalyFeedbackText(e.target.value)}
                    placeholder="Please let us know your feedback on this response."
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "0.8rem",
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: "0.8rem",
                      },
                      minWidth: "300px",
                      "& label.Mui-focused": {
                        color: "none",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={
                              anomalyfeedback === "dislike" &&
                              ["", null, undefined].includes(
                                anomalyfeedbackText
                              )
                            }
                            onClick={() =>
                              handleFeedbackUpdate(
                                3,
                                anomalyfeedback,
                                anomalyfeedbackText
                              )
                            }
                            sx={{ borderRadius: "8px" }}
                          >
                            {anomalyLoading ? (
                              <CircularProgress
                                size="20px"
                                sx={{
                                  color:
                                    theme.palette.mode === "dark"
                                      ? "#FFFFFF"
                                      : "#707070",
                                }}
                              />
                            ) : (
                              <SendIcon fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </>
            ),
          }[section.key]}
      </Stack>
    </Alert>
  )

  return (
    <Stack display="flex" flexDirection="column">
      <Stack display="flex" flexDirection="column" gap={1}>
        <Box
          display="flex"
          justifyContent={alignment}
          rowGap={1}
          columnGap={2}
          flexWrap="wrap"
        >
          {legendsData.map((legend, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: legend.color || "grey",
                  borderRadius: "50%",
                }}
              />
              <Typography
                variant="subtitle2"
                color="inherit"
                sx={{ fontSize: "0.8rem" }}
              >
                {legend.label || "N/A"}
              </Typography>
            </Box>
          ))}
        </Box>

        {additionalInfo}

        {showAiDropdown ? (
          aiResponse?.length !== 0 ? (
            <Box
              display="flex"
              flexDirection="row"
              rowGap={1}
              columnGap={2}
              flexWrap="wrap"
              className="pdf-export-hidden"
            >
              {sections.map(({ key, color }) => (
                <Box
                  key={key}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                  onClick={() => handleSectionClick(key)}
                  sx={{ cursor: "pointer" }}
                >
                  <Box
                    height="12px"
                    width="12px"
                    borderRadius="4px"
                    bgcolor={color}
                  />
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight:
                        key === activeSection && open
                          ? "fontWeightBold"
                          : "fontWeightRegular",
                    }}
                  >
                    {key || "N/A"}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              rowGap={1}
              columnGap={2}
              flexWrap="wrap"
              className="pdf-export-hidden"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
                onClick={() => handleSectionClick("Analysis")}
                sx={{ cursor: "pointer" }}
              >
                <Box
                  height="12px"
                  width="12px"
                  borderRadius="4px"
                  bgcolor="#FCBF49"
                />
                <Typography
                  variant="subtitle2"
                  color="inherit"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "fontWeightRegular",
                  }}
                >
                  Analysis
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
                onClick={() => handleSectionClick("Recommendations")}
                sx={{ cursor: "pointer" }}
              >
                <Box
                  height="12px"
                  width="12px"
                  borderRadius="4px"
                  bgcolor="#50CD89"
                />
                <Typography
                  variant="subtitle2"
                  color="inherit"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "fontWeightRegular",
                  }}
                >
                  Recommendations
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
                onClick={() => handleSectionClick("Anomalies")}
                sx={{ cursor: "pointer" }}
              >
                <Box
                  height="12px"
                  width="12px"
                  borderRadius="4px"
                  bgcolor="#FF0000"
                />
                <Typography
                  variant="subtitle2"
                  color="inherit"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "fontWeightRegular",
                  }}
                >
                  Anomalies
                </Typography>
              </Box>
            </Box>
          )
        ) : (
          <div />
        )}
      </Stack>

      <Collapse
        in={open}
        timeout="auto"
        sx={{ mt: open ? "10px" : 0 }}
        className="pdf-export-hidden"
      >
        {loading ? (
          <Typography
            variant="body2"
            color="inherit"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            Generating AI insights...
          </Typography>
        ) : (
          sections
            .filter((section) => section.key === activeSection)
            .map(handleSectionRender)
        )}
      </Collapse>
    </Stack>
  )
}

export default CustomLegend
