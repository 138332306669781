import { createSlice } from "@reduxjs/toolkit"

export const businessSynopsisSlice = createSlice({
  name: "businessSynopsis",
  initialState: {
    businessSynopsis: null,
    businessSynopsisSlice_uid: null,
    reports: [],
  },
  reducers: {
    loadData: (state, action) => {
      const { businessSynopsis, businessSynopsis_uid, reports } = action.payload
      state.businessSynopsis = businessSynopsis
      state.businessSynopsis_uid = businessSynopsis_uid
      state.reports = reports
    },
    setChartData: (state, action) => {
      const { reportUid, response, comparisonPeriod, viewBy } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )
      if (selectedReport) selectedReport.reportData = response
      if (selectedReport && comparisonPeriod)
        selectedReport.comparisonPeriod = comparisonPeriod
      if (selectedReport && viewBy) selectedReport.viewBy = viewBy
    },
  },
})

export const { loadData, setChartData } = businessSynopsisSlice.actions

export default businessSynopsisSlice.reducer
