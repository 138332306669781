import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { useTheme } from "../contexts/theme"
import ArrowRise from "../assets/svgs/ArrowRise"
import ArrowFall from "../assets/svgs/ArrowFall"
import { calculatePercentageChange, getFormattedValue } from "../utils/utils"
import {
  calculatePreviousPeriod,
  calculatePreviousYear,
  formatDate,
  getDateFormatFromLocalStorage,
} from "../utils/dateUtils"
import { useSelector } from "react-redux"
import dayjs from "dayjs"

const CustomTooltip = ({
  data,
  label,
  dataDatum,
  heatmapCell,
  showCurrentPrevDate = false,
  showLegendName = false,
  showCurrentPrevColors,
  showDateRange = true,
  firstcolumName = "Current",
  secondcolumName = "Previous",
  showTooltipPrevious = true,
}) => {
  const { theme } = useTheme()
  const textPrimary = theme.palette.text.primary
  const { fromDate, toDate } = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const commonStyles = {
    padding: "8px 12px 8px 0px",
    color: textPrimary,
    whiteSpace: "nowrap",
    borderBottom: `1px solid ${
      theme.palette.mode === "dark" ? "#0D2266" : "#E6E6E6"
    }`,
  }

  const formatDateRange = (
    startDate,
    endDate,
    format = getDateFormatFromLocalStorage()
  ) => {
    return `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`
  }

  // const formatDay = (day) => {
  //   const [year, month, date] = day?.split("-") ?? [0, 0, 0]
  //   return year != 0 && month != 0 && date != 0
  //     ? `${month}-${date}-${year}`
  //     : ""
  // }
  const formatDay = (day) => {
    if (!day) return ""

    const [year, month, date] = day?.split("-") ?? [0, 0, 0]

    if (year != 0 && month != 0 && date != 0) {
      const formattedDate = formatDate(
        new Date(year, month - 1, date),
        getDateFormatFromLocalStorage()
      )

      return formattedDate
    }

    return ""
  }

  // const formatDay = (date) => {
  //   return date ? formatDate(date, getDateFormatFromLocalStorage()) : ""
  // }

  const previousPeriodDates =
    fromDate && toDate ? calculatePreviousPeriod(fromDate, toDate) : null
  const previousYearDates =
    fromDate && toDate ? calculatePreviousYear(fromDate, toDate) : null

  const length = Object.keys(data.tooltipData.current).length

  const areAllValuesUndefined = (data) => {
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key].value !== undefined) {
        return false
      }
    }
    return true
  }

  const noValues = areAllValuesUndefined(data.tooltipData.current)

  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? "#030C27" : "#FAFAFA",
        paddingX: "1rem",
        borderRadius: "8px",
        border: `1px solid ${
          theme.palette.mode === "dark" ? "#0D2266" : "#E6E6E6"
        }`,
        color: textPrimary,
      }}
    >
      {!noValues ? (
        <Table>
          <TableHead>
            <TableRow>
              {showLegendName ? (
                <>
                  <TableCell
                    sx={{
                      ...commonStyles,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "5px",
                          height: "20px",
                          borderRadius: "8px",
                          backgroundColor: dataDatum?.color,
                        }}
                      />
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "12px",
                        }}
                      >
                        {data.id || label.x}
                      </span>
                    </Box>
                  </TableCell>
                </>
              ) : (
                <TableCell
                  sx={{
                    ...commonStyles,
                    opacity: 0.7,
                    fontSize: "12px",
                  }}
                />
              )}
              <TableCell
                align="center"
                sx={{
                  ...commonStyles,
                  opacity: 0.7,
                  fontSize: "13px",
                }}
              >
                <Stack>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="row"
                    gap="5px"
                    alignItems="center"
                  >
                    {showCurrentPrevColors?.current && (
                      <Box
                        sx={{
                          width: "6px",
                          height: "13px",
                          borderRadius: "3px",
                          backgroundColor: showCurrentPrevColors?.current,
                        }}
                      />
                    )}
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      {firstcolumName}
                    </span>
                  </Box>

                  {showCurrentPrevDate &&
                    fromDate &&
                    toDate &&
                    showDateRange && (
                      <Box
                        sx={{
                          fontSize: "12px",
                          minWidth: "25ch",
                          fontWeight: 300,
                        }}
                      >
                        {data.reportType === "Calendar"
                          ? formatDay(data.day)
                          : data.reportType === "Heatmap"
                          ? `${heatmapCell.serieId} ${heatmapCell.data.x}`
                          : data.reportType === "GroupBar"
                          ? data.currentDate
                          : data.reportType === "DashLine"
                          ? data.actualDate
                          : formatDateRange(fromDate, toDate)}
                      </Box>
                    )}
                  {showCurrentPrevDate && data?.tooltipData?.currentDate && (
                    <Box
                      sx={{
                        fontSize: "12px",
                        fontWeight: 300,
                      }}
                    >
                      {data.tooltipData.currentDate}
                    </Box>
                  )}
                </Stack>
              </TableCell>
              {data.tooltipData.previous &&
                showTooltipPrevious &&
                (data.reportType == "Calendar"
                  ? data.previousDate != undefined
                  : true) && (
                  <TableCell
                    align="center"
                    sx={{
                      ...commonStyles,
                      opacity: 0.7,
                      fontSize: "13px",
                    }}
                  >
                    <Stack>
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap="5px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {showCurrentPrevColors?.previous && (
                          <Box
                            sx={{
                              width: "6px",
                              height: "13px",
                              borderRadius: "3px",
                              backgroundColor: showCurrentPrevColors?.previous,
                            }}
                          />
                        )}
                        <span style={{ fontSize: "14px", fontWeight: 600 }}>
                          {secondcolumName}
                        </span>
                      </Box>

                      {showDateRange &&
                      comparisonPeriod.selectedValue === "Previous Period" ? (
                        <Box
                          sx={{
                            fontSize: "12px",
                            minWidth: "25ch",
                            fontWeight: 300,
                          }}
                        >
                          {data.reportType === "Calendar"
                            ? formatDay(data.previousDate)
                            : data.reportType === "Heatmap"
                            ? `${heatmapCell.serieId} ${heatmapCell.data.x}`
                            : data.reportType === "GroupBar"
                            ? data.previousDate
                            : data.reportType === "DashLine"
                            ? data.forcastDate
                            : formatDateRange(
                                previousPeriodDates.previousFromDate,
                                previousPeriodDates.previousToDate
                              )}
                        </Box>
                      ) : (
                        showDateRange && (
                          <Box
                            sx={{
                              fontSize: "12px",
                              minWidth: "25ch",
                              fontWeight: 300,
                            }}
                          >
                            (
                            {formatDateRange(
                              previousYearDates.previousFromDate,
                              previousYearDates.previousToDate
                            )}
                            )
                          </Box>
                        )
                      )}
                      {showCurrentPrevDate &&
                        data?.tooltipData?.previousDate && (
                          <Box
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                            }}
                          >
                            {data.tooltipData.previousDate}
                          </Box>
                        )}
                    </Stack>
                  </TableCell>
                )}
              {data.tooltipData.previous &&
                showTooltipPrevious &&
                (data.reportType == "Calendar"
                  ? data.previousDate != undefined
                  : true) && (
                  <TableCell
                    align="center"
                    sx={{
                      ...commonStyles,
                      padding: "8px 8px 8px 0px",
                      opacity: 0.7,
                      fontSize: "14px",
                      fontWeight: 600,
                      minWidth: "12ch",
                    }}
                  >
                    Change (%)
                  </TableCell>
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data.tooltipData.current).map(
              ([tooltipName, tooltipValue], idx) => (
                <TableRow key={tooltipName}>
                  <TableCell
                    sx={{
                      ...commonStyles,
                      borderBottom:
                        idx + 1 === length
                          ? "none"
                          : `1px solid ${
                              theme.palette.mode === "dark"
                                ? "#0D2266"
                                : "#E6E6E6"
                            }`,
                      opacity: 0.7,
                      padding: "8px 16px 8px 0px",
                      fontSize: "12px",
                    }}
                  >
                    {tooltipName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      ...commonStyles,
                    }}
                  >
                    {!isNaN(tooltipValue.value) ? (
                      getFormattedValue(tooltipValue.value, tooltipValue.format)
                    ) : !tooltipValue.format ? (
                      getFormattedValue(tooltipValue.value, tooltipValue.format)
                    ) : (
                      <span
                        style={{
                          color: textPrimary,
                          display: "inline-block",
                          width: "1em",
                          textAlign: "center",
                        }}
                      >
                        &mdash;
                      </span>
                    )}
                  </TableCell>
                  {data.tooltipData.previous &&
                    showTooltipPrevious &&
                    (data.reportType == "Calendar"
                      ? data.previousDate != undefined
                      : true) && (
                      <TableCell
                        align="center"
                        sx={{
                          ...commonStyles,
                        }}
                      >
                        {!isNaN(
                          data.tooltipData.previous[tooltipName].value
                        ) ? (
                          getFormattedValue(
                            data.tooltipData.previous[tooltipName].value,
                            data.tooltipData.previous[tooltipName].format
                          )
                        ) : (
                          <span
                            style={{
                              color: textPrimary,
                              display: "inline-block",
                              width: "1em",
                              textAlign: "center",
                            }}
                          >
                            &mdash;
                          </span>
                        )}
                      </TableCell>
                    )}
                  {data.tooltipData.previous &&
                    showTooltipPrevious &&
                    (data.reportType == "Calendar"
                      ? data.previousDate != undefined
                      : true) && (
                      <TableCell
                        align="center"
                        sx={{
                          ...commonStyles,
                          color:
                            calculatePercentageChange(
                              tooltipValue.value,
                              data.tooltipData.previous[tooltipName].value
                            ) === 0
                              ? textPrimary
                              : calculatePercentageChange(
                                  tooltipValue.value,
                                  data.tooltipData.previous[tooltipName].value
                                ) > 0
                              ? "#50CD89"
                              : "#EE000C",
                          display: "flex",
                          flexDirection: "row",
                          rowGap: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color:
                              calculatePercentageChange(
                                tooltipValue.value,
                                data.tooltipData.previous[tooltipName].value
                              ) === 0
                                ? textPrimary
                                : "inherit",
                          }}
                        >
                          {isNaN(
                            calculatePercentageChange(
                              tooltipValue.value,
                              data.tooltipData.previous[tooltipName].value
                            )
                          ) ? (
                            <span
                              style={{
                                color: textPrimary,
                                display: "inline-block",
                                width: "1em",
                                textAlign: "center",
                              }}
                            >
                              &mdash;
                            </span>
                          ) : (
                            getFormattedValue(
                              calculatePercentageChange(
                                tooltipValue.value,
                                data.tooltipData.previous[tooltipName].value
                              ),
                              "Percentage"
                            )
                          )}
                        </span>
                        {isNaN(
                          calculatePercentageChange(
                            tooltipValue.value,
                            data.tooltipData.previous[tooltipName].value
                          )
                        ) ? (
                          ""
                        ) : calculatePercentageChange(
                            tooltipValue.value,
                            data.tooltipData.previous[tooltipName].value
                          ) === 0 ? (
                          ""
                        ) : calculatePercentageChange(
                            tooltipValue.value,
                            data.tooltipData.previous[tooltipName].value
                          ) > 0 ? (
                          <ArrowRise />
                        ) : (
                          <ArrowFall />
                        )}
                      </TableCell>
                    )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      ) : showLegendName ? (
        <Box
          sx={{
            padding: "8px",
            color: textPrimary,
            opacity: 0.7,
            fontSize: "12px",
          }}
        >
          <span
            style={{
              fontSize: "12px",
            }}
          >
            {data.id} : {data.value}
          </span>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "8px",
            color: textPrimary,
            opacity: 0.7,
            fontSize: "12px",
          }}
        >
          Inadequate Data!
        </Box>
      )}
      <div
        style={{
          position: "absolute",
          bottom: "-9px",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: `10px solid ${
            theme.palette.mode === "dark" ? "#0D2266" : "#FAFAFA"
          }`,
        }}
      />
    </TableContainer>
  )
}

export default CustomTooltip
