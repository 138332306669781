/* eslint-disable react/jsx-key */
import RouteTitle from "../../components/RouteTitle"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import CustomPaper from "../../components/CustomPaper"
import { useTheme } from "../../contexts/theme"
import Grid from "@mui/material/Grid"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Paypal from "../../assets/svgs/Paypal"
import Venmov from "../../assets/svgs/Venmov"
import Square from "../../assets/svgs/Square"
import ApplePay from "../../assets/svgs/ApplePay"
import Twitter from "../../assets/svgs/Twitter"
import Facebook from "../../assets/svgs/Facebook"
import Instagram from "../../assets/svgs/Instagram"
import Quicbook from "../../assets/svgs/Quicbook"
import Wave from "../../assets/svgs/Wave"
import FreshBook from "../../assets/svgs/FreshBook"
import UberEats from "../../assets/svgs/UberEats"
import Zomato from "../../assets/svgs/Zomato"
import Swiggy from "../../assets/svgs/Swiggy"
import FreshTeam from "../../assets/svgs/FreshTeam"
import PayCom from "../../assets/svgs/PayCom"
import PlanDay from "../../assets/svgs/PlanDay"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import FixedBottomComponent from "../../components/FixedBottomComponent"

const Integration = () => {
  const { theme } = useTheme()

  const platforms = [
    {
      name: "Social Media Integration",
      items: [<Twitter />, <Facebook />, <Instagram />],
    },
    {
      name: "Accounting Platforms",
      items: [<Quicbook />, <Wave />, <FreshBook />],
    },
    {
      name: "HR Platforms",
      items: [<FreshTeam />, <PayCom />, <PlanDay />],
    },
    {
      name: "Delivery Platforms",
      items: [<UberEats />, <Zomato />, <Swiggy />],
    },
    {
      name: "POS",
      items: [<Paypal />, <Venmov />, <Square />, <ApplePay />],
    },
  ]

  return (
    <>
      <RouteTitle title="Integration" />
      <Stack padding="15px 30px" gap="20px">
        <Box
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#1D47CE" : "#E9EDFC",
            padding: "5px 9px",
            borderRadius: "8px",
            position: "absolute",
            zIndex: 5,
            width: "fit-content",
            right: {
              sm: "69px",
              md: "69px",
              lg: "69px",
              xl: "calc((100vw - 1536px) / 2 + 70px)",
            },
            marginTop: "-17px",
          }}
        >
          Price Varies as per selection
        </Box>
        <CustomPaper>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h6">
                Integrate the apps you use for your business
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }} dense={true}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="This will help you get the best out of Livelytics." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          textWrap: "balance",
                        }}
                        variant="body2"
                      >
                        Livelytics can track and analyze all your business data
                        across platforms to give you
                        <br /> the best reports and insights to take your
                        business to the next level!
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Box>
            <Stack
              display="flex"
              justifyContent={{
                sm: "center",
              }}
              alignItems="stretch"
            >
              <Typography variant="h3">$500</Typography>
              <Typography variant="h6">per month</Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "accent",
                  color: "rgba(255, 255, 255, 1)",
                  whiteSpace: "nowrap",
                  border: "none",
                  textTransform: "none",
                  padding: "10px 16px",
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(255, 100, 0, 1)"
                        : "rgba(29, 71, 226, 1)",
                  },
                }}
              >
                Pay Now
              </Button>
            </Stack>
          </Box>
        </CustomPaper>
        <CustomPaper>
          <Stack gap="20px">
            <Typography variant="h6">Select App Integrations</Typography>
            <Grid container spacing={2}>
              {platforms.map((platform, index) => (
                <Grid key={index} item xs={12} sm={6} md={6} lg={6}>
                  <CustomPaper
                    customStyles={{
                      bgcolor:
                        theme.palette.mode === "dark" ? "black" : "white",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="inherit"
                      marginBottom="5px"
                    >
                      {platform.name}
                    </Typography>
                    <Box display="flex" gap="20px" flexWrap="wrap">
                      {platform.items.map((component, itemIndex) => (
                        <FormGroup
                          display="flex"
                          key={itemIndex}
                          sx={{
                            bgcolor: "#FFFFFFE9",
                            paddingLeft: "10px",
                            borderRadius: "7px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "black",
                                  },
                                }}
                              />
                            }
                            label={<Box display="flex">{component}</Box>}
                          />
                        </FormGroup>
                      ))}
                    </Box>
                  </CustomPaper>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </CustomPaper>

        <FixedBottomComponent price="500" />
      </Stack>
    </>
  )
}

export default Integration
