import RouteTitle from "../../components/RouteTitle"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import CustomPaper from "../../components/CustomPaper"
import Grid from "@mui/material/Grid"
import { useTheme } from "../../contexts/theme"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Switch from "@mui/material/Switch"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import ComingSoon from "../../components/ComingSoon"
import Logo from "../../assets/svgs/Logo"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from "../../components/stripe/CheckoutForm"

const Configuration = () => {
  const { theme } = useTheme()

  const configurations = [
    {
      category: "Sales",
      description:
        "Lorem Ipsum has roots in a piece of classical Latin literature from 45 BC.",
      mentions: [
        {
          type: "KPIs",
          data: ["Total Revenue", "Total Orders", "Upsell Rate"],
        },
        {
          type: "Insights",
          data: ["Sales Trend", "Sales Forecast", "Top-Selling Items"],
        },
      ],
      selects: [
        { type: "Select KPIs", data: ["Refunds", "Sales Growth Percentage"] },
        {
          type: "Select Insights",
          data: ["Cross-Selling Patterns", "Promotion Effectiveness"],
        },
      ],
    },
    {
      category: "Inventory",
      description:
        "Lorem Ipsum has roots in a piece of classical Latin literature from 45 BC.",
      mentions: [
        {
          type: "KPIs",
          data: ["Inventory Turnover", "Gross Profit Margin", "Stockout Rate"],
        },
        {
          type: "Insights",
          data: [
            "Real Time Inventory Tracking",
            "Inventory Turnover Rate",
            "Expiration Dates",
          ],
        },
      ],
      selects: [
        {
          type: "Select KPIs",
          data: ["Inventory-to-Sales Ratio", "Waste Percentage"],
        },
        {
          type: "Select Insights",
          data: ["Supplier Negotiation", "Stock Values"],
        },
      ],
    },
    {
      category: "Customers",
      description:
        "Lorem Ipsum has roots in a piece of classical Latin literature from 45 BC.",
      mentions: [
        {
          type: "KPIs",
          data: ["Total Revenue", "Total Orders", "Upsell Rate"],
        },
        {
          type: "Insights",
          data: ["Sales Trend", "Sales Forecast", "Top-Selling Items"],
        },
      ],
      selects: [
        {
          type: "Select KPIs",
          data: ["Refunds", "Sales Growth Percentage"],
        },
        {
          type: "Select Insights",
          data: [
            "Cross-Selling Patterns",
            "Promotion Effectiveness",
            "Customer Satisfaction",
          ],
        },
      ],
    },
    {
      category: "Vendor",
      description:
        "Lorem Ipsum has roots in a piece of classical Latin literature from 45 BC.",
      mentions: [
        {
          type: "KPIs",
          data: [
            "Total Vendor",
            "Completed Procurement",
            "Procurement In-Progress",
          ],
        },
        {
          type: "Insights",
          data: [
            "Supplier Reliability",
            "Cost Management",
            "Quality Assurance",
          ],
        },
      ],
      selects: [
        {
          type: "Select KPIs",
          data: ["Vendor Rating", "Avg Procurement Cycle"],
        },
        {
          type: "Select Insights",
          data: ["Supplier Relationship", "Cost of Handling Orders"],
        },
      ],
    },
    {
      category: "Employee",
      description:
        "Lorem Ipsum has roots in a piece of classical Latin literature from 45 BC.",
      mentions: [
        {
          type: "KPIs",
          data: ["Total Employees", "New Employees", "Employees On Leave"],
        },
        {
          type: "Insights",
          data: ["Employees Trend", "Salary Trend", "Employee Attendance"],
        },
      ],
      selects: [
        { type: "Select KPIs", data: ["Best Employees", "Employee"] },
        {
          type: "Select Insights",
          data: ["Employee Leave Trends", "Employee Holiday"],
        },
      ],
    },
  ]

  const openCheckOutForm = () => {
    const stripePromise = loadStripe(
      "pk_test_51PShPPP0NrxkYe28QAXtuyH49uJro0SkVJkgGqtdQptemgPPWv06ajvxjfjvv54luH0FKSrMtJmmY9wY9bnukmyI00NkzDbmDw"
    )
    const options = {
      // passing the client secret obtained from the server
      clientSecret:
        "sk_test_51PShPPP0NrxkYe28Sa2keqJuL6RzvMdEhymPZDZxw6b4auYrjv9EHPbp3Fw0UTnBZZess4CIcFdzkDxLV2akRSZz009jynvF1h",
    }

    return (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    )
  }

  return (
    <>
      {/* <ComingSoon /> */}
      <RouteTitle title="Configuration" />
      {/* <Box
        // height="100%"
        sx={{
          width: "100%",
          height: "50vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            gap: 1,
          }}
        >
          <Typography variant="h4">Coming soon..</Typography>
          <Logo
            fill={theme.palette.text.primary}
            yfill={
              theme.palette.mode === "light" ? theme.palette.accent : "white"
            }
          />
        </Box>
      </Box> */}
      <Stack padding="15px 30px" gap="20px">
        <Box
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#1D47CE" : "#E9EDFC",
            padding: "5px 9px",
            borderRadius: "8px",
            position: "absolute",
            zIndex: 5,
            width: "fit-content",
            right: {
              sm: "69px",
              md: "69px",
              lg: "69px",
              xl: "calc((100vw - 1536px) / 2 + 70px)",
            },
            marginTop: "-17px",
          }}
        >
          Price Varies as per selection
        </Box>
        <CustomPaper>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h6">
                Choose the insights that you need!
              </Typography>

              <List
                sx={{ listStyleType: "disc", pl: 4, textWrap: "balance" }}
                dense={true}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText
                    primary="Comprehensive suite including POS, inventory, staff, and
                  customer management."
                  />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText
                    primary="Offer add-on features that can be individually subscribed to,
                  such as online ordering, social media integration, marketing
                  tools, etc."
                  />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText
                    primary="Vary features and support levels based on the tier, allowing
                  scalability."
                  />
                </ListItem>
              </List>
            </Box>
            <Stack
              display="flex"
              justifyContent={{
                sm: "center",
              }}
              alignItems="stretch"
            >
              <Typography variant="h3">$500</Typography>
              <Typography variant="h6">per month</Typography>
              <Button
                onClick={openCheckOutForm}
                variant="contained"
                sx={{
                  backgroundColor: "accent",
                  color: "rgba(255, 255, 255, 1)",
                  whiteSpace: "nowrap",
                  border: "none",
                  textTransform: "none",
                  padding: "10px 16px",
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(255, 100, 0, 1)"
                        : "rgba(29, 71, 226, 1)",
                  },
                }}
              >
                Pay Now
              </Button>
            </Stack>
          </Box>
        </CustomPaper>

        <CustomPaper
          customStyles={{
            backgroundColor:
              theme.palette.mode === "dark" ? "black" : "#E9EDFC",
            borderRadius: "10px 10px 0px 0px",
            width: "300px",
            marginLeft: "auto",
            position: "fixed",
            bottom: 0,
            height: "unset",
            right: {
              sm: "59px",
              md: "59px",
              lg: "59px",
              xl: "calc((100vw - 1536px) / 2 + 59px)",
            },
            zIndex: "50",
          }}
          watermarkBg={true}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack display="flex" justifyContent="space-between">
              <Typography variant="h3">$500</Typography>
              <Typography variant="h6">per month</Typography>
            </Stack>
            <Button
              onClick={openCheckOutForm}
              sx={{
                backgroundColor: "accent",
                color: "rgba(255, 255, 255, 1)",
                whiteSpace: "nowrap",
                border: "none",
                height: "50px",
                textTransform: "none",
                padding: "10px 16px",
                borderRadius: "25px",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 100, 0, 1)"
                      : "rgba(29, 71, 226, 1)",
                },
              }}
            >
              Pay Now
            </Button>
          </Box>
        </CustomPaper>
        <Grid container spacing={2}>
          {configurations.map((config) => (
            <Grid item key={config.category} xs={12} sm={6} md={6} lg={6}>
              <CustomPaper>
                <Typography variant="h6">{config.category}</Typography>
                <Typography variant="body2" color="inherit" marginBottom="20px">
                  {config.description}
                </Typography>
                <Box display="flex" gap="20px" flexWrap="wrap">
                  <Grid container spacing={2}>
                    {config.mentions.map(({ type, data }) => (
                      <Grid key={type} item xs={12} sm={6} md={6} lg={6}>
                        <Box key={type}>
                          <Typography variant="subtitle2" color="inherit">
                            3 - {type}
                          </Typography>
                          <List dense={true}>
                            {data.map((item) => (
                              <ListItem
                                key={item}
                                sx={{
                                  display: "flex",
                                  gap: "24px",
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    minWidth: "unset",
                                  }}
                                >
                                  <svg
                                    width="18"
                                    height="12"
                                    viewBox="0 0 18 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 6.9L4.14286 10.5L12 1.5M17 1.5625L8.4284 10.5625L8 10"
                                      stroke="#50CD89"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </ListItemIcon>
                                <ListItemText primary={item} />
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid container spacing={2}>
                    {config.selects.map(({ type, data }) => (
                      <Grid key={type} item xs={12} sm={6} md={6} lg={6}>
                        <CustomPaper
                          customStyles={{
                            backgroundColor:
                              theme.palette.mode === "dark" ? "black" : "white",
                          }}
                          key={type}
                        >
                          <Typography variant="subtitle2" color="inherit">
                            {type}:
                          </Typography>
                          <List dense={true}>
                            {data.map((item) => (
                              <FormGroup key={item}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      disableRipple
                                      size="medium"
                                      defaultChecked
                                      color="inherit"
                                      sx={{
                                        height: "42px",
                                        width: "62px",
                                        "& .MuiSwitch-track": {
                                          borderRadius: 2,
                                          border:
                                            theme.palette.mode === "dark"
                                              ? "2px solid #895334"
                                              : "2px solid #8FA6EF",
                                          opacity: 0.3,
                                          backgroundColor:
                                            theme.palette.mode === "dark"
                                              ? "#441900"
                                              : "#E9EDFC",
                                        },
                                        "& .MuiSwitch-thumb": {
                                          backgroundColor:
                                            theme.palette.mode === "dark"
                                              ? "#ffffff"
                                              : "#8FA6EF",
                                          borderRadius: "45%",
                                          width: "10px",
                                          height: "10px",
                                          position: "relative",
                                          top: "7px",
                                          left: "7px",
                                        },
                                        "& .MuiSwitch-switchBase:hover": {
                                          backgroundColor: "#ffffff00",
                                          backgroundOrigin: "content-box",
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography component="div" color="inherit">
                                      {item}
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                            ))}
                          </List>
                        </CustomPaper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </CustomPaper>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  )
}

export default Configuration
