// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Comfortaa";
}
.hoverEffect {
  display: inline-block;
  transition: transform 0.5s ease;
}

.hoverEffect:hover {
  transform: translateX(6px);
}

.svg-rotate {
  transform-origin: 50% 50%;
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

ol {
  padding-inline-start: 20px;
}

.loading {
  clip-path: inset(0 8px 0 0);
  animation: l 2s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -3px 0 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,qBAAqB;EACrB,+BAA+B;AACjC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,iCAAiC;AACnC;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n* {\n  font-family: \"Comfortaa\";\n}\n.hoverEffect {\n  display: inline-block;\n  transition: transform 0.5s ease;\n}\n\n.hoverEffect:hover {\n  transform: translateX(6px);\n}\n\n.svg-rotate {\n  transform-origin: 50% 50%;\n  animation: rotate 3s linear infinite;\n}\n\n@keyframes rotate {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(-360deg);\n  }\n}\n\nol {\n  padding-inline-start: 20px;\n}\n\n.loading {\n  clip-path: inset(0 8px 0 0);\n  animation: l 2s steps(4) infinite;\n}\n\n@keyframes l {\n  to {\n    clip-path: inset(0 -3px 0 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
