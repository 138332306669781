import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useTheme } from "../contexts/theme"
import SuccessSvg from "../assets/svgs/SuccessSvg"

const SaveModal = ({ open, onClose, title, message }) => {
  const { theme } = useTheme()

  const colors = {
    accentColor: theme.palette.mode === "dark" ? "#F3884A" : "#1D47CE",
    accentHoverColor: theme.palette.mode === "dark" ? "#EB7E41" : "#1C3DBD",
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Stack
          display="flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 12,
            p: 2,
            width: "26vw",
            borderRadius: "12px",
            textAlign: "center",
          }}
          gap="20px"
        >
          <SuccessSvg />
          <Typography variant="h4" component="div" gutterBottom sx={{ m: 1 }}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mb: 1,
              color: "inherit",
              opacity: "80%",
            }}
          >
            {message}
          </Typography>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              py: 0.8,
              width: "10vw",
              backgroundColor: colors.accentColor,
              color: "rgba(255, 255, 255, 1)",
              textAlign: "center",
              borderRadius: "24px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: colors.accentHoverColor,
              },
            }}
          >
            Okay
          </Button>
        </Stack>
      </Modal>
    </>
  )
}

export default SaveModal
