export const CalendarChartData = [
  {
    day: "2022-01-01",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-02",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-03",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-04",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-05",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-06",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-07",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-08",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-09",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-10",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-11",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-12",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-13",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-14",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-15",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-16",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-17",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-18",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-19",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-20",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-21",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-22",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-23",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-24",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-25",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-26",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-27",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-28",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-29",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-30",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-31",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-01",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-02",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-03",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-04",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-05",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-06",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-07",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-08",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-09",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-10",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-11",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-12",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-13",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-14",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-15",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-16",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-17",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-18",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-19",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-20",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-21",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-22",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-23",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-24",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-25",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-26",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-27",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-28",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-01",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-02",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-03",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-04",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-05",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-06",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-07",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-08",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-09",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-10",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-11",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-12",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-13",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-14",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-15",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-16",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-17",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-18",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-19",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-20",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-21",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-22",
    value: 4.49,
    tooltipData: {
      current: {
        value: {
          value: 4.49,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 1,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-23",
    value: 0,
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-24",
    value: 2.75,
    tooltipData: {
      current: {
        value: {
          value: 2.75,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 2,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-25",
    value: 4.49,
    tooltipData: {
      current: {
        value: {
          value: 4.49,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 1,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-26",
    value: 7393.009999999991,
    tooltipData: {
      current: {
        value: {
          value: 7393.009999999991,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 229,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 801.4999999999994,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-27",
    value: 5782.699999999998,
    tooltipData: {
      current: {
        value: {
          value: 5782.699999999998,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 626.5200000000003,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-28",
    value: 2016.5,
    tooltipData: {
      current: {
        value: {
          value: 2016.5,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 81,
          format: "Number",
        },
        Returns: {
          value: 6.5,
          format: "Currency",
        },
        Discounts: {
          value: 8.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-29",
    value: 1875.620000000001,
    tooltipData: {
      current: {
        value: {
          value: 1875.620000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.29,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-30",
    value: 1506.76,
    tooltipData: {
      current: {
        value: {
          value: 1506.76,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 64,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-31",
    value: 2478.399999999998,
    tooltipData: {
      current: {
        value: {
          value: 2478.399999999998,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 10.96,
          format: "Currency",
        },
        Discounts: {
          value: 12.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-01",
    value: 3860.329999999999,
    tooltipData: {
      current: {
        value: {
          value: 3860.329999999999,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 381.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-02",
    value: 3458.85,
    tooltipData: {
      current: {
        value: {
          value: 3458.85,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 8.85,
          format: "Currency",
        },
        Discounts: {
          value: 383.1799999999997,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-03",
    value: 4246.720000000001,
    tooltipData: {
      current: {
        value: {
          value: 4246.720000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 27.49,
          format: "Currency",
        },
        Discounts: {
          value: 474.4099999999997,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-04",
    value: 2283.23,
    tooltipData: {
      current: {
        value: {
          value: 2283.23,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 7.709999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-05",
    value: 1878.800000000001,
    tooltipData: {
      current: {
        value: {
          value: 1878.800000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 4.05,
          format: "Currency",
        },
        Discounts: {
          value: 7.180000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-06",
    value: 2047.46,
    tooltipData: {
      current: {
        value: {
          value: 2047.46,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-07",
    value: 2749.24,
    tooltipData: {
      current: {
        value: {
          value: 2749.24,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 6.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-08",
    value: 2489.06,
    tooltipData: {
      current: {
        value: {
          value: 2489.06,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 13.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-09",
    value: 2980.55,
    tooltipData: {
      current: {
        value: {
          value: 2980.55,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.23,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-10",
    value: 2930.4,
    tooltipData: {
      current: {
        value: {
          value: 2930.4,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 13.35,
          format: "Currency",
        },
        Discounts: {
          value: 10.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-11",
    value: 2553.32,
    tooltipData: {
      current: {
        value: {
          value: 2553.32,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-12",
    value: 1727.140000000001,
    tooltipData: {
      current: {
        value: {
          value: 1727.140000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 87,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 15.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-13",
    value: 2511.03,
    tooltipData: {
      current: {
        value: {
          value: 2511.03,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 85,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 65.81,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-14",
    value: 2150.420000000001,
    tooltipData: {
      current: {
        value: {
          value: 2150.420000000001,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.73,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-15",
    value: 3131.730000000001,
    tooltipData: {
      current: {
        value: {
          value: 3131.730000000001,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 17.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-16",
    value: 3014.17,
    tooltipData: {
      current: {
        value: {
          value: 3014.17,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-17",
    value: 3632.459999999999,
    tooltipData: {
      current: {
        value: {
          value: 3632.459999999999,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-18",
    value: 1215.710000000001,
    tooltipData: {
      current: {
        value: {
          value: 1215.710000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 83,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 2.07,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-19",
    value: 1977.620000000001,
    tooltipData: {
      current: {
        value: {
          value: 1977.620000000001,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 12,
          format: "Currency",
        },
        Discounts: {
          value: 16.85,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-20",
    value: 1740.950000000001,
    tooltipData: {
      current: {
        value: {
          value: 1740.950000000001,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 80,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.96,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-21",
    value: 2295.09,
    tooltipData: {
      current: {
        value: {
          value: 2295.09,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 10,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-22",
    value: 3295.33,
    tooltipData: {
      current: {
        value: {
          value: 3295.33,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 28.34,
          format: "Currency",
        },
        Discounts: {
          value: 0.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-23",
    value: 3168.1,
    tooltipData: {
      current: {
        value: {
          value: 3168.1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-24",
    value: 4496.510000000001,
    tooltipData: {
      current: {
        value: {
          value: 4496.510000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 29.97,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-25",
    value: 1645.020000000001,
    tooltipData: {
      current: {
        value: {
          value: 1645.020000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 83,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-26",
    value: 2850.279999999999,
    tooltipData: {
      current: {
        value: {
          value: 2850.279999999999,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 7.49,
          format: "Currency",
        },
        Discounts: {
          value: 2.26,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-27",
    value: 3048.029999999999,
    tooltipData: {
      current: {
        value: {
          value: 3048.029999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 12.5,
          format: "Currency",
        },
        Discounts: {
          value: 1.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-28",
    value: 2961.999999999999,
    tooltipData: {
      current: {
        value: {
          value: 2961.999999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-29",
    value: 3518.929999999999,
    tooltipData: {
      current: {
        value: {
          value: 3518.929999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 8.309999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-30",
    value: 4711.629999999997,
    tooltipData: {
      current: {
        value: {
          value: 4711.629999999997,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 10.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-01",
    value: 4214.459999999999,
    tooltipData: {
      current: {
        value: {
          value: 4214.459999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 88.4,
          format: "Currency",
        },
        Discounts: {
          value: 29.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-02",
    value: 2097.84,
    tooltipData: {
      current: {
        value: {
          value: 2097.84,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 91,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-03",
    value: 2034.950000000001,
    tooltipData: {
      current: {
        value: {
          value: 2034.950000000001,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.85,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-04",
    value: 2118.19,
    tooltipData: {
      current: {
        value: {
          value: 2118.19,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        Returns: {
          value: 3.19,
          format: "Currency",
        },
        Discounts: {
          value: 11.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-05",
    value: 2962.789999999999,
    tooltipData: {
      current: {
        value: {
          value: 2962.789999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 92,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 2.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-06",
    value: 4182.83,
    tooltipData: {
      current: {
        value: {
          value: 4182.83,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 1.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-07",
    value: 3778.309999999998,
    tooltipData: {
      current: {
        value: {
          value: 3778.309999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-08",
    value: 4092.079999999999,
    tooltipData: {
      current: {
        value: {
          value: 4092.079999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-09",
    value: 1903.630000000001,
    tooltipData: {
      current: {
        value: {
          value: 1903.630000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 71,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-10",
    value: 2713.38,
    tooltipData: {
      current: {
        value: {
          value: 2713.38,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 3.34,
          format: "Currency",
        },
        Discounts: {
          value: 0.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-11",
    value: 2861.63,
    tooltipData: {
      current: {
        value: {
          value: 2861.63,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 21.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-12",
    value: 2887.28,
    tooltipData: {
      current: {
        value: {
          value: 2887.28,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-13",
    value: 3816.25,
    tooltipData: {
      current: {
        value: {
          value: 3816.25,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 1.8,
          format: "Currency",
        },
        Discounts: {
          value: 17.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-14",
    value: 4026.189999999999,
    tooltipData: {
      current: {
        value: {
          value: 4026.189999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 3.08,
          format: "Currency",
        },
        Discounts: {
          value: 4.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-15",
    value: 11015.78,
    tooltipData: {
      current: {
        value: {
          value: 11015.78,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 276,
          format: "Number",
        },
        Returns: {
          value: 16.44,
          format: "Currency",
        },
        Discounts: {
          value: 78.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-16",
    value: 2747,
    tooltipData: {
      current: {
        value: {
          value: 2747,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 0.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-17",
    value: 2262.2,
    tooltipData: {
      current: {
        value: {
          value: 2262.2,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 92,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-18",
    value: 3309.370000000001,
    tooltipData: {
      current: {
        value: {
          value: 3309.370000000001,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 1.2,
          format: "Currency",
        },
        Discounts: {
          value: 2.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-19",
    value: 3238.489999999999,
    tooltipData: {
      current: {
        value: {
          value: 3238.489999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        Returns: {
          value: 11.72,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-20",
    value: 2571.289999999999,
    tooltipData: {
      current: {
        value: {
          value: 2571.289999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-21",
    value: 3842.609999999997,
    tooltipData: {
      current: {
        value: {
          value: 3842.609999999997,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 10.4,
          format: "Currency",
        },
        Discounts: {
          value: 10.86,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-22",
    value: 5054.689999999996,
    tooltipData: {
      current: {
        value: {
          value: 5054.689999999996,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 6.770000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 8.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-23",
    value: 2209.76,
    tooltipData: {
      current: {
        value: {
          value: 2209.76,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 21.99,
          format: "Currency",
        },
        Discounts: {
          value: 5.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-24",
    value: 2633.28,
    tooltipData: {
      current: {
        value: {
          value: 2633.28,
          format: "Currency",
        },
        Customers: {
          value: 23,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-25",
    value: 1997.98,
    tooltipData: {
      current: {
        value: {
          value: 1997.98,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 7.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-26",
    value: 3775.110000000001,
    tooltipData: {
      current: {
        value: {
          value: 3775.110000000001,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 3.34,
          format: "Currency",
        },
        Discounts: {
          value: 2.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-27",
    value: 3862.689999999998,
    tooltipData: {
      current: {
        value: {
          value: 3862.689999999998,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 9.85,
          format: "Currency",
        },
        Discounts: {
          value: 22.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-28",
    value: 3211.799999999999,
    tooltipData: {
      current: {
        value: {
          value: 3211.799999999999,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 18.47,
          format: "Currency",
        },
        Discounts: {
          value: 6.250000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-29",
    value: 3841.290000000001,
    tooltipData: {
      current: {
        value: {
          value: 3841.290000000001,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 20.27,
          format: "Currency",
        },
        Discounts: {
          value: 13.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-30",
    value: 3424.519999999998,
    tooltipData: {
      current: {
        value: {
          value: 3424.519999999998,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-31",
    value: 2535.55,
    tooltipData: {
      current: {
        value: {
          value: 2535.55,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 14.13,
          format: "Currency",
        },
        Discounts: {
          value: 1.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-01",
    value: 2712.699999999999,
    tooltipData: {
      current: {
        value: {
          value: 2712.699999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-02",
    value: 3110.369999999999,
    tooltipData: {
      current: {
        value: {
          value: 3110.369999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 15.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-03",
    value: 3480.079999999997,
    tooltipData: {
      current: {
        value: {
          value: 3480.079999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 3.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-04",
    value: 3193.839999999998,
    tooltipData: {
      current: {
        value: {
          value: 3193.839999999998,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 7.6,
          format: "Currency",
        },
        Discounts: {
          value: 15.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-05",
    value: 3414.699999999999,
    tooltipData: {
      current: {
        value: {
          value: 3414.699999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 3.83,
          format: "Currency",
        },
        Discounts: {
          value: 11.38,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-06",
    value: 2675.96,
    tooltipData: {
      current: {
        value: {
          value: 2675.96,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 16.7,
          format: "Currency",
        },
        Discounts: {
          value: 8.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-07",
    value: 2719.7,
    tooltipData: {
      current: {
        value: {
          value: 2719.7,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-08",
    value: 6635.249999999997,
    tooltipData: {
      current: {
        value: {
          value: 6635.249999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 89,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-09",
    value: -306.1600000000016,
    tooltipData: {
      current: {
        value: {
          value: -306.1600000000016,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 3.88,
          format: "Currency",
        },
        Discounts: {
          value: 6.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-10",
    value: 3790.269999999999,
    tooltipData: {
      current: {
        value: {
          value: 3790.269999999999,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 10.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-11",
    value: 3331.259999999997,
    tooltipData: {
      current: {
        value: {
          value: 3331.259999999997,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 40.24,
          format: "Currency",
        },
        Discounts: {
          value: 5.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-12",
    value: 3312.17,
    tooltipData: {
      current: {
        value: {
          value: 3312.17,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.780000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-13",
    value: 2204.43,
    tooltipData: {
      current: {
        value: {
          value: 2204.43,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 85,
          format: "Number",
        },
        Returns: {
          value: 14.15,
          format: "Currency",
        },
        Discounts: {
          value: 4.48,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-14",
    value: 2064.979999999999,
    tooltipData: {
      current: {
        value: {
          value: 2064.979999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 9.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-15",
    value: 2781.819999999999,
    tooltipData: {
      current: {
        value: {
          value: 2781.819999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 1.35,
          format: "Currency",
        },
        Discounts: {
          value: 0.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-16",
    value: 2732.909999999999,
    tooltipData: {
      current: {
        value: {
          value: 2732.909999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 104,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-17",
    value: 3508.69,
    tooltipData: {
      current: {
        value: {
          value: 3508.69,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 14.61,
          format: "Currency",
        },
        Discounts: {
          value: 5.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-18",
    value: 4002.609999999999,
    tooltipData: {
      current: {
        value: {
          value: 4002.609999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 19.5,
          format: "Currency",
        },
        Discounts: {
          value: 2.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-19",
    value: 3579.069999999998,
    tooltipData: {
      current: {
        value: {
          value: 3579.069999999998,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-20",
    value: 2363.08,
    tooltipData: {
      current: {
        value: {
          value: 2363.08,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 87,
          format: "Number",
        },
        Returns: {
          value: 3.9,
          format: "Currency",
        },
        Discounts: {
          value: 10.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-21",
    value: 2223.18,
    tooltipData: {
      current: {
        value: {
          value: 2223.18,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 1.89,
          format: "Currency",
        },
        Discounts: {
          value: 2.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-22",
    value: 2712.68,
    tooltipData: {
      current: {
        value: {
          value: 2712.68,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-23",
    value: 2616.659999999999,
    tooltipData: {
      current: {
        value: {
          value: 2616.659999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-24",
    value: 3678.019999999999,
    tooltipData: {
      current: {
        value: {
          value: 3678.019999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-25",
    value: 4047.849999999997,
    tooltipData: {
      current: {
        value: {
          value: 4047.849999999997,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 8.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-26",
    value: 3960.229999999998,
    tooltipData: {
      current: {
        value: {
          value: 3960.229999999998,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-27",
    value: 2212.96,
    tooltipData: {
      current: {
        value: {
          value: 2212.96,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 90,
          format: "Number",
        },
        Returns: {
          value: 22.2,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-28",
    value: 3467.599999999999,
    tooltipData: {
      current: {
        value: {
          value: 3467.599999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-29",
    value: 3242.510000000001,
    tooltipData: {
      current: {
        value: {
          value: 3242.510000000001,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 10.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-30",
    value: 3308.349999999999,
    tooltipData: {
      current: {
        value: {
          value: 3308.349999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 8.43,
          format: "Currency",
        },
        Discounts: {
          value: 0.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-01",
    value: 3910.529999999999,
    tooltipData: {
      current: {
        value: {
          value: 3910.529999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-02",
    value: 4421.629999999997,
    tooltipData: {
      current: {
        value: {
          value: 4421.629999999997,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 15.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-03",
    value: 3323.679999999999,
    tooltipData: {
      current: {
        value: {
          value: 3323.679999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-04",
    value: 3468.319999999999,
    tooltipData: {
      current: {
        value: {
          value: 3468.319999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 45.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-05",
    value: 2805.739999999999,
    tooltipData: {
      current: {
        value: {
          value: 2805.739999999999,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 16.29,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-06",
    value: 2372.93,
    tooltipData: {
      current: {
        value: {
          value: 2372.93,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 6.01,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-07",
    value: 3336.669999999998,
    tooltipData: {
      current: {
        value: {
          value: 3336.669999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-08",
    value: 4835.469999999997,
    tooltipData: {
      current: {
        value: {
          value: 4835.469999999997,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 5.65,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-09",
    value: 3826.239999999999,
    tooltipData: {
      current: {
        value: {
          value: 3826.239999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-10",
    value: 3951.449999999998,
    tooltipData: {
      current: {
        value: {
          value: 3951.449999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 4.02,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-11",
    value: 1787.83,
    tooltipData: {
      current: {
        value: {
          value: 1787.83,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-12",
    value: 2382.210000000001,
    tooltipData: {
      current: {
        value: {
          value: 2382.210000000001,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-13",
    value: 3049.909999999999,
    tooltipData: {
      current: {
        value: {
          value: 3049.909999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-14",
    value: 2700.089999999998,
    tooltipData: {
      current: {
        value: {
          value: 2700.089999999998,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-15",
    value: 3750.759999999999,
    tooltipData: {
      current: {
        value: {
          value: 3750.759999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-16",
    value: 5006.899999999997,
    tooltipData: {
      current: {
        value: {
          value: 5006.899999999997,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 16.07,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-17",
    value: 4185.869999999996,
    tooltipData: {
      current: {
        value: {
          value: 4185.869999999996,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 14.08,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-18",
    value: 2233.190000000001,
    tooltipData: {
      current: {
        value: {
          value: 2233.190000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        Returns: {
          value: 12.34,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-19",
    value: 2634.6,
    tooltipData: {
      current: {
        value: {
          value: 2634.6,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-20",
    value: 2125.7,
    tooltipData: {
      current: {
        value: {
          value: 2125.7,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-21",
    value: 3441.800000000001,
    tooltipData: {
      current: {
        value: {
          value: 3441.800000000001,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 17.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-22",
    value: 3149.419999999996,
    tooltipData: {
      current: {
        value: {
          value: 3149.419999999996,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-23",
    value: 4066.219999999998,
    tooltipData: {
      current: {
        value: {
          value: 4066.219999999998,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 28.65,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-24",
    value: 3898.489999999998,
    tooltipData: {
      current: {
        value: {
          value: 3898.489999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 21.97,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-25",
    value: 2306.92,
    tooltipData: {
      current: {
        value: {
          value: 2306.92,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 12.62,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-26",
    value: 2843.189999999999,
    tooltipData: {
      current: {
        value: {
          value: 2843.189999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 31.79,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-27",
    value: 2774.510000000001,
    tooltipData: {
      current: {
        value: {
          value: 2774.510000000001,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 5.84,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-28",
    value: 3995.549999999999,
    tooltipData: {
      current: {
        value: {
          value: 3995.549999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 4.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-29",
    value: 4234.919999999996,
    tooltipData: {
      current: {
        value: {
          value: 4234.919999999996,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 16.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-30",
    value: 4411.85,
    tooltipData: {
      current: {
        value: {
          value: 4411.85,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-31",
    value: 4482.21,
    tooltipData: {
      current: {
        value: {
          value: 4482.21,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 26.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-01",
    value: 2119.219999999999,
    tooltipData: {
      current: {
        value: {
          value: 2119.219999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 14.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-02",
    value: 3288.579999999998,
    tooltipData: {
      current: {
        value: {
          value: 3288.579999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 26.53,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-03",
    value: 3637.299999999999,
    tooltipData: {
      current: {
        value: {
          value: 3637.299999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 20.44,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-04",
    value: 3812.909999999997,
    tooltipData: {
      current: {
        value: {
          value: 3812.909999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 10.29,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-05",
    value: 2640.28,
    tooltipData: {
      current: {
        value: {
          value: 2640.28,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 32.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-06",
    value: 4738.099999999998,
    tooltipData: {
      current: {
        value: {
          value: 4738.099999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 0.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-07",
    value: 4748.739999999998,
    tooltipData: {
      current: {
        value: {
          value: 4748.739999999998,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 10.61,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-08",
    value: 2728.399999999999,
    tooltipData: {
      current: {
        value: {
          value: 2728.399999999999,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-09",
    value: 3178.109999999997,
    tooltipData: {
      current: {
        value: {
          value: 3178.109999999997,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-10",
    value: 3209.429999999999,
    tooltipData: {
      current: {
        value: {
          value: 3209.429999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 18.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-11",
    value: 4422.829999999996,
    tooltipData: {
      current: {
        value: {
          value: 4422.829999999996,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 10.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-12",
    value: 4011.129999999999,
    tooltipData: {
      current: {
        value: {
          value: 4011.129999999999,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 27.84,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-13",
    value: 4099.189999999999,
    tooltipData: {
      current: {
        value: {
          value: 4099.189999999999,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 22.42,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-14",
    value: 6753.929999999992,
    tooltipData: {
      current: {
        value: {
          value: 6753.929999999992,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 53.02,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-15",
    value: 3076.339999999997,
    tooltipData: {
      current: {
        value: {
          value: 3076.339999999997,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.97,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-16",
    value: 3006.45,
    tooltipData: {
      current: {
        value: {
          value: 3006.45,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-17",
    value: 3249.269999999999,
    tooltipData: {
      current: {
        value: {
          value: 3249.269999999999,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-18",
    value: 3315.189999999999,
    tooltipData: {
      current: {
        value: {
          value: 3315.189999999999,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-19",
    value: 4145.089999999997,
    tooltipData: {
      current: {
        value: {
          value: 4145.089999999997,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 6.1,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-20",
    value: 6047.9,
    tooltipData: {
      current: {
        value: {
          value: 6047.9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 20.58,
          format: "Currency",
        },
        Discounts: {
          value: 4.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-21",
    value: 5687.36,
    tooltipData: {
      current: {
        value: {
          value: 5687.36,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 21.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-22",
    value: 3243.56,
    tooltipData: {
      current: {
        value: {
          value: 3243.56,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 33.15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-23",
    value: 2715.039999999999,
    tooltipData: {
      current: {
        value: {
          value: 2715.039999999999,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 3.08,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-24",
    value: 2665.179999999999,
    tooltipData: {
      current: {
        value: {
          value: 2665.179999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-25",
    value: 4076.119999999998,
    tooltipData: {
      current: {
        value: {
          value: 4076.119999999998,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 12.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-26",
    value: 3613.749999999998,
    tooltipData: {
      current: {
        value: {
          value: 3613.749999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 10.51,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-27",
    value: 6234.689999999996,
    tooltipData: {
      current: {
        value: {
          value: 6234.689999999996,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 1.95,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-28",
    value: 4736.449999999997,
    tooltipData: {
      current: {
        value: {
          value: 4736.449999999997,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 10.3,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-29",
    value: 2591.76,
    tooltipData: {
      current: {
        value: {
          value: 2591.76,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-30",
    value: 2880.359999999997,
    tooltipData: {
      current: {
        value: {
          value: 2880.359999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 23.91,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-31",
    value: 3388.129999999998,
    tooltipData: {
      current: {
        value: {
          value: 3388.129999999998,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-01",
    value: 3594.039999999998,
    tooltipData: {
      current: {
        value: {
          value: 3594.039999999998,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 22.99,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-02",
    value: 5431.159999999997,
    tooltipData: {
      current: {
        value: {
          value: 5431.159999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 13.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-03",
    value: 5421.589999999997,
    tooltipData: {
      current: {
        value: {
          value: 5421.589999999997,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 197,
          format: "Number",
        },
        Returns: {
          value: 4.48,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-04",
    value: 4081.449999999996,
    tooltipData: {
      current: {
        value: {
          value: 4081.449999999996,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        Returns: {
          value: 21.81,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-05",
    value: 3801.79,
    tooltipData: {
      current: {
        value: {
          value: 3801.79,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 34.18,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-06",
    value: 3345.75,
    tooltipData: {
      current: {
        value: {
          value: 3345.75,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 5.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-07",
    value: 3177.01,
    tooltipData: {
      current: {
        value: {
          value: 3177.01,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-08",
    value: 3117.54,
    tooltipData: {
      current: {
        value: {
          value: 3117.54,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-09",
    value: 4099.04,
    tooltipData: {
      current: {
        value: {
          value: 4099.04,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 7.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-10",
    value: 5468.189999999997,
    tooltipData: {
      current: {
        value: {
          value: 5468.189999999997,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 195,
          format: "Number",
        },
        Returns: {
          value: 0.9,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-11",
    value: 4988.450000000001,
    tooltipData: {
      current: {
        value: {
          value: 4988.450000000001,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 3.1,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-12",
    value: 2416.840000000001,
    tooltipData: {
      current: {
        value: {
          value: 2416.840000000001,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-13",
    value: 3409.609999999999,
    tooltipData: {
      current: {
        value: {
          value: 3409.609999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-14",
    value: 3525.049999999998,
    tooltipData: {
      current: {
        value: {
          value: 3525.049999999998,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-15",
    value: 2933.05,
    tooltipData: {
      current: {
        value: {
          value: 2933.05,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 22.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-16",
    value: 4255.489999999999,
    tooltipData: {
      current: {
        value: {
          value: 4255.489999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 21.59,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-17",
    value: 5358.349999999992,
    tooltipData: {
      current: {
        value: {
          value: 5358.349999999992,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-18",
    value: 4356.209999999996,
    tooltipData: {
      current: {
        value: {
          value: 4356.209999999996,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 5.09,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-19",
    value: 2778.63,
    tooltipData: {
      current: {
        value: {
          value: 2778.63,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-20",
    value: 3048.979999999999,
    tooltipData: {
      current: {
        value: {
          value: 3048.979999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 11.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-21",
    value: 2998.429999999998,
    tooltipData: {
      current: {
        value: {
          value: 2998.429999999998,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-22",
    value: 3385.139999999999,
    tooltipData: {
      current: {
        value: {
          value: 3385.139999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-23",
    value: 5157.499999999998,
    tooltipData: {
      current: {
        value: {
          value: 5157.499999999998,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 66.6,
          format: "Currency",
        },
        Discounts: {
          value: 18.97,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-24",
    value: 5693.779999999997,
    tooltipData: {
      current: {
        value: {
          value: 5693.779999999997,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 15.4,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-25",
    value: 6603.209999999994,
    tooltipData: {
      current: {
        value: {
          value: 6603.209999999994,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-26",
    value: 2393.249999999999,
    tooltipData: {
      current: {
        value: {
          value: 2393.249999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 3.95,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-27",
    value: 3015.21,
    tooltipData: {
      current: {
        value: {
          value: 3015.21,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-28",
    value: 2489.14,
    tooltipData: {
      current: {
        value: {
          value: 2489.14,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 5.98,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-29",
    value: 3490.239999999997,
    tooltipData: {
      current: {
        value: {
          value: 3490.239999999997,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 6.560000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 23.05,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-30",
    value: 4137.499999999999,
    tooltipData: {
      current: {
        value: {
          value: 4137.499999999999,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 14.33,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-01",
    value: 4587.91,
    tooltipData: {
      current: {
        value: {
          value: 4587.91,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 3.05,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-02",
    value: 5571.509999999996,
    tooltipData: {
      current: {
        value: {
          value: 5571.509999999996,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 236,
          format: "Number",
        },
        Returns: {
          value: 17.05,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-03",
    value: 3610.119999999995,
    tooltipData: {
      current: {
        value: {
          value: 3610.119999999995,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 3.99,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-04",
    value: 4293.929999999999,
    tooltipData: {
      current: {
        value: {
          value: 4293.929999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 19.14,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-05",
    value: 3656.919999999999,
    tooltipData: {
      current: {
        value: {
          value: 3656.919999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 1.77,
          format: "Currency",
        },
        Discounts: {
          value: 0.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-06",
    value: 3175.039999999998,
    tooltipData: {
      current: {
        value: {
          value: 3175.039999999998,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 10.2,
          format: "Currency",
        },
        Discounts: {
          value: 19.53,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-07",
    value: 4882.439999999995,
    tooltipData: {
      current: {
        value: {
          value: 4882.439999999995,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 8.98,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-08",
    value: 5297.339999999997,
    tooltipData: {
      current: {
        value: {
          value: 5297.339999999997,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 20.08,
          format: "Currency",
        },
        Discounts: {
          value: 0.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-09",
    value: 4947.079999999998,
    tooltipData: {
      current: {
        value: {
          value: 4947.079999999998,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 195,
          format: "Number",
        },
        Returns: {
          value: 14.76,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-10",
    value: 2815.59,
    tooltipData: {
      current: {
        value: {
          value: 2815.59,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 4.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-11",
    value: 3649.22,
    tooltipData: {
      current: {
        value: {
          value: 3649.22,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 16,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-12",
    value: 3651.659999999999,
    tooltipData: {
      current: {
        value: {
          value: 3651.659999999999,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 7.380000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 3.56,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-13",
    value: 2914.259999999998,
    tooltipData: {
      current: {
        value: {
          value: 2914.259999999998,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.63,
          format: "Currency",
        },
        Discounts: {
          value: 3.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-14",
    value: 4400.239999999998,
    tooltipData: {
      current: {
        value: {
          value: 4400.239999999998,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 22.5,
          format: "Currency",
        },
        Discounts: {
          value: 6.510000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-15",
    value: 4062.349999999997,
    tooltipData: {
      current: {
        value: {
          value: 4062.349999999997,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 6.27,
          format: "Currency",
        },
        Discounts: {
          value: 13.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-16",
    value: 4944.539999999999,
    tooltipData: {
      current: {
        value: {
          value: 4944.539999999999,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 203,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.88,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-17",
    value: 3418.499999999999,
    tooltipData: {
      current: {
        value: {
          value: 3418.499999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.81,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-18",
    value: 4174.279999999998,
    tooltipData: {
      current: {
        value: {
          value: 4174.279999999998,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 2.69,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-19",
    value: 3602.819999999997,
    tooltipData: {
      current: {
        value: {
          value: 3602.819999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 7.75,
          format: "Currency",
        },
        Discounts: {
          value: 3.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-20",
    value: 3988.659999999999,
    tooltipData: {
      current: {
        value: {
          value: 3988.659999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 16.9,
          format: "Currency",
        },
        Discounts: {
          value: 0.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-21",
    value: 5110.839999999994,
    tooltipData: {
      current: {
        value: {
          value: 5110.839999999994,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 201,
          format: "Number",
        },
        Returns: {
          value: 15.91,
          format: "Currency",
        },
        Discounts: {
          value: 8.629999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-22",
    value: 6432.709999999995,
    tooltipData: {
      current: {
        value: {
          value: 6432.709999999995,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 241,
          format: "Number",
        },
        Returns: {
          value: 37.26,
          format: "Currency",
        },
        Discounts: {
          value: 18.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-23",
    value: 6992.249999999991,
    tooltipData: {
      current: {
        value: {
          value: 6992.249999999991,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 263,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 17.69,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-24",
    value: 5347.509999999996,
    tooltipData: {
      current: {
        value: {
          value: 5347.509999999996,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 246,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.28,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-25",
    value: 2780.35,
    tooltipData: {
      current: {
        value: {
          value: 2780.35,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 8.06,
          format: "Currency",
        },
        Discounts: {
          value: 6.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-26",
    value: 3222.439999999999,
    tooltipData: {
      current: {
        value: {
          value: 3222.439999999999,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 4.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-27",
    value: 3268.719999999998,
    tooltipData: {
      current: {
        value: {
          value: 3268.719999999998,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 14.7,
          format: "Currency",
        },
        Discounts: {
          value: 12.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-28",
    value: 3953.589999999998,
    tooltipData: {
      current: {
        value: {
          value: 3953.589999999998,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 2.95,
          format: "Currency",
        },
        Discounts: {
          value: 7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-29",
    value: 4863.109999999997,
    tooltipData: {
      current: {
        value: {
          value: 4863.109999999997,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 33.09,
          format: "Currency",
        },
        Discounts: {
          value: 1.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-30",
    value: 5251.709999999998,
    tooltipData: {
      current: {
        value: {
          value: 5251.709999999998,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 5.96,
          format: "Currency",
        },
        Discounts: {
          value: 18.56,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-31",
    value: 2549.450000000001,
    tooltipData: {
      current: {
        value: {
          value: 2549.450000000001,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-01",
    value: 2673.94,
    tooltipData: {
      current: {
        value: {
          value: 2673.94,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-02",
    value: 3458.769999999998,
    tooltipData: {
      current: {
        value: {
          value: 3458.769999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 55.11,
          format: "Currency",
        },
        Discounts: {
          value: 15.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-03",
    value: 3828.899999999999,
    tooltipData: {
      current: {
        value: {
          value: 3828.899999999999,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-04",
    value: 4568.029999999995,
    tooltipData: {
      current: {
        value: {
          value: 4568.029999999995,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 178,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 6.71,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-05",
    value: 4912.199999999998,
    tooltipData: {
      current: {
        value: {
          value: 4912.199999999998,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-06",
    value: 5585.609999999994,
    tooltipData: {
      current: {
        value: {
          value: 5585.609999999994,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 203,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-07",
    value: 2788.329999999998,
    tooltipData: {
      current: {
        value: {
          value: 2788.329999999998,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 10.81,
          format: "Currency",
        },
        Discounts: {
          value: 0.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-08",
    value: 3503.029999999999,
    tooltipData: {
      current: {
        value: {
          value: 3503.029999999999,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 9.75,
          format: "Currency",
        },
        Discounts: {
          value: 5.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-09",
    value: 3719.299999999999,
    tooltipData: {
      current: {
        value: {
          value: 3719.299999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 13.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-10",
    value: 4005.209999999998,
    tooltipData: {
      current: {
        value: {
          value: 4005.209999999998,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-11",
    value: 5212.119999999994,
    tooltipData: {
      current: {
        value: {
          value: 5212.119999999994,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 6.61,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-12",
    value: 5633.819999999993,
    tooltipData: {
      current: {
        value: {
          value: 5633.819999999993,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 10.17,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-13",
    value: 5969.119999999999,
    tooltipData: {
      current: {
        value: {
          value: 5969.119999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 30.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-14",
    value: 2902.479999999998,
    tooltipData: {
      current: {
        value: {
          value: 2902.479999999998,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-15",
    value: 3491.849999999999,
    tooltipData: {
      current: {
        value: {
          value: 3491.849999999999,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 11.16,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-16",
    value: 2921.499999999999,
    tooltipData: {
      current: {
        value: {
          value: 2921.499999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 2.2,
          format: "Currency",
        },
        Discounts: {
          value: 0.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-17",
    value: 3221.639999999999,
    tooltipData: {
      current: {
        value: {
          value: 3221.639999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 47.98,
          format: "Currency",
        },
        Discounts: {
          value: 13.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-18",
    value: 4568.879999999998,
    tooltipData: {
      current: {
        value: {
          value: 4568.879999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 22.23,
          format: "Currency",
        },
        Discounts: {
          value: 2.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-19",
    value: 4705.219999999997,
    tooltipData: {
      current: {
        value: {
          value: 4705.219999999997,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 10.47,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-20",
    value: 4614.2,
    tooltipData: {
      current: {
        value: {
          value: 4614.2,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 32.05,
          format: "Currency",
        },
        Discounts: {
          value: 12.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-21",
    value: 3206.249999999997,
    tooltipData: {
      current: {
        value: {
          value: 3206.249999999997,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-22",
    value: 5104.769999999993,
    tooltipData: {
      current: {
        value: {
          value: 5104.769999999993,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 11.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-23",
    value: 7653.380000000001,
    tooltipData: {
      current: {
        value: {
          value: 7653.380000000001,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 253,
          format: "Number",
        },
        Returns: {
          value: 2.16,
          format: "Currency",
        },
        Discounts: {
          value: 35.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-24",
    value: 4051.399999999997,
    tooltipData: {
      current: {
        value: {
          value: 4051.399999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 178,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-25",
    value: 4147.48,
    tooltipData: {
      current: {
        value: {
          value: 4147.48,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 10.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-26",
    value: 4243.469999999998,
    tooltipData: {
      current: {
        value: {
          value: 4243.469999999998,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 3.3,
          format: "Currency",
        },
        Discounts: {
          value: 2.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-27",
    value: 4247.86,
    tooltipData: {
      current: {
        value: {
          value: 4247.86,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 2.42,
          format: "Currency",
        },
        Discounts: {
          value: 27.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-28",
    value: 2920.499999999999,
    tooltipData: {
      current: {
        value: {
          value: 2920.499999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 20.84,
          format: "Currency",
        },
        Discounts: {
          value: 5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-29",
    value: 3363.799999999999,
    tooltipData: {
      current: {
        value: {
          value: 3363.799999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 9.3,
          format: "Currency",
        },
        Discounts: {
          value: 4.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-30",
    value: 2854.250000000001,
    tooltipData: {
      current: {
        value: {
          value: 2854.250000000001,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-01",
    value: 3716.129999999999,
    tooltipData: {
      current: {
        value: {
          value: 3716.129999999999,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 9.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-02",
    value: 4246.569999999997,
    tooltipData: {
      current: {
        value: {
          value: 4246.569999999997,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 4.07,
          format: "Currency",
        },
        Discounts: {
          value: 4.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-03",
    value: 4710.859999999996,
    tooltipData: {
      current: {
        value: {
          value: 4710.859999999996,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 30.59,
          format: "Currency",
        },
        Discounts: {
          value: 1.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-04",
    value: 5195.45,
    tooltipData: {
      current: {
        value: {
          value: 5195.45,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 3.93,
          format: "Currency",
        },
        Discounts: {
          value: 6.310000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-05",
    value: 2545.109999999999,
    tooltipData: {
      current: {
        value: {
          value: 2545.109999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-06",
    value: 2973.4,
    tooltipData: {
      current: {
        value: {
          value: 2973.4,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 6.779999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-07",
    value: 3673.619999999999,
    tooltipData: {
      current: {
        value: {
          value: 3673.619999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-08",
    value: 3292.799999999999,
    tooltipData: {
      current: {
        value: {
          value: 3292.799999999999,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 2.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-09",
    value: 5014.449999999996,
    tooltipData: {
      current: {
        value: {
          value: 5014.449999999996,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 10,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-10",
    value: 4622.85,
    tooltipData: {
      current: {
        value: {
          value: 4622.85,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 9.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-11",
    value: 6188.189999999997,
    tooltipData: {
      current: {
        value: {
          value: 6188.189999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 210,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 11.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-12",
    value: 3267.159999999996,
    tooltipData: {
      current: {
        value: {
          value: 3267.159999999996,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 12.5,
          format: "Currency",
        },
        Discounts: {
          value: 17.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-13",
    value: 3533.29,
    tooltipData: {
      current: {
        value: {
          value: 3533.29,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 14.91,
          format: "Currency",
        },
        Discounts: {
          value: 4.19,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-14",
    value: 3097.799999999999,
    tooltipData: {
      current: {
        value: {
          value: 3097.799999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 8.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-15",
    value: 3633.369999999998,
    tooltipData: {
      current: {
        value: {
          value: 3633.369999999998,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 27.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-16",
    value: 4842.459999999998,
    tooltipData: {
      current: {
        value: {
          value: 4842.459999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 4,
          format: "Currency",
        },
        Discounts: {
          value: 1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-17",
    value: 5777.199999999995,
    tooltipData: {
      current: {
        value: {
          value: 5777.199999999995,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 15.46,
          format: "Currency",
        },
        Discounts: {
          value: 17.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-18",
    value: 5068.289999999995,
    tooltipData: {
      current: {
        value: {
          value: 5068.289999999995,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 201,
          format: "Number",
        },
        Returns: {
          value: 3.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-19",
    value: 3622.029999999999,
    tooltipData: {
      current: {
        value: {
          value: 3622.029999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 5.73,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-20",
    value: 4251,
    tooltipData: {
      current: {
        value: {
          value: 4251,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-21",
    value: 5705.099999999994,
    tooltipData: {
      current: {
        value: {
          value: 5705.099999999994,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 24.4,
          format: "Currency",
        },
        Discounts: {
          value: 0.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-22",
    value: 7282.069999999994,
    tooltipData: {
      current: {
        value: {
          value: 7282.069999999994,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 25.47,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-23",
    value: 3443.359999999998,
    tooltipData: {
      current: {
        value: {
          value: 3443.359999999998,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-24",
    value: 6120.939999999998,
    tooltipData: {
      current: {
        value: {
          value: 6120.939999999998,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 15.99,
          format: "Currency",
        },
        Discounts: {
          value: 31.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-25",
    value: 2972.43,
    tooltipData: {
      current: {
        value: {
          value: 2972.43,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 18.89,
          format: "Currency",
        },
        Discounts: {
          value: 16.29,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-26",
    value: 4626.279999999997,
    tooltipData: {
      current: {
        value: {
          value: 4626.279999999997,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 160,
          format: "Number",
        },
        Returns: {
          value: 8.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.38,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-27",
    value: 3771.909999999999,
    tooltipData: {
      current: {
        value: {
          value: 3771.909999999999,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 5.78,
          format: "Currency",
        },
        Discounts: {
          value: 28.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-28",
    value: 3670.759999999999,
    tooltipData: {
      current: {
        value: {
          value: 3670.759999999999,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 49.17,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-29",
    value: 4813.999999999996,
    tooltipData: {
      current: {
        value: {
          value: 4813.999999999996,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.91,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-30",
    value: 5485.479999999992,
    tooltipData: {
      current: {
        value: {
          value: 5485.479999999992,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 6.54,
          format: "Currency",
        },
        Discounts: {
          value: 34.29,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-31",
    value: 5886.669999999996,
    tooltipData: {
      current: {
        value: {
          value: 5886.669999999996,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 5.58,
          format: "Currency",
        },
        Discounts: {
          value: 59.67000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-01",
    value: 2269.190000000001,
    tooltipData: {
      current: {
        value: {
          value: 2269.190000000001,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 76,
          format: "Number",
        },
        Returns: {
          value: 15.5,
          format: "Currency",
        },
        Discounts: {
          value: 1.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-02",
    value: 4529.559999999998,
    tooltipData: {
      current: {
        value: {
          value: 4529.559999999998,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 19.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-03",
    value: 4394.889999999997,
    tooltipData: {
      current: {
        value: {
          value: 4394.889999999997,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.92000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-04",
    value: 3553.730000000001,
    tooltipData: {
      current: {
        value: {
          value: 3553.730000000001,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 67.66000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-05",
    value: 4362.479999999994,
    tooltipData: {
      current: {
        value: {
          value: 4362.479999999994,
          format: "Currency",
        },
        Customers: {
          value: 21,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 11.45,
          format: "Currency",
        },
        Discounts: {
          value: 32.98,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-06",
    value: 3780.489999999997,
    tooltipData: {
      current: {
        value: {
          value: 3780.489999999997,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 8.57,
          format: "Currency",
        },
        Discounts: {
          value: 16.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-07",
    value: 6485.009999999998,
    tooltipData: {
      current: {
        value: {
          value: 6485.009999999998,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 229,
          format: "Number",
        },
        Returns: {
          value: 49.76,
          format: "Currency",
        },
        Discounts: {
          value: 46.70999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-08",
    value: 6672.519999999992,
    tooltipData: {
      current: {
        value: {
          value: 6672.519999999992,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 7.69,
          format: "Currency",
        },
        Discounts: {
          value: 53.25000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-09",
    value: 3175.659999999999,
    tooltipData: {
      current: {
        value: {
          value: 3175.659999999999,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 4.8,
          format: "Currency",
        },
        Discounts: {
          value: 12.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-10",
    value: 4305.879999999997,
    tooltipData: {
      current: {
        value: {
          value: 4305.879999999997,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 41.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-11",
    value: 2807.74,
    tooltipData: {
      current: {
        value: {
          value: 2807.74,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 20.02,
          format: "Currency",
        },
        Discounts: {
          value: 22.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-12",
    value: 4102.739999999998,
    tooltipData: {
      current: {
        value: {
          value: 4102.739999999998,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 7.310000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 19.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-13",
    value: 4763.259999999996,
    tooltipData: {
      current: {
        value: {
          value: 4763.259999999996,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 6.6,
          format: "Currency",
        },
        Discounts: {
          value: 18.55,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-14",
    value: 7150.789999999985,
    tooltipData: {
      current: {
        value: {
          value: 7150.789999999985,
          format: "Currency",
        },
        Customers: {
          value: 15,
          format: "Number",
        },
        Transactions: {
          value: 255,
          format: "Number",
        },
        Returns: {
          value: 37.12,
          format: "Currency",
        },
        Discounts: {
          value: 57.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-15",
    value: 6011.119999999997,
    tooltipData: {
      current: {
        value: {
          value: 6011.119999999997,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 238,
          format: "Number",
        },
        Returns: {
          value: 7.1,
          format: "Currency",
        },
        Discounts: {
          value: 26.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-16",
    value: 4866.709999999997,
    tooltipData: {
      current: {
        value: {
          value: 4866.709999999997,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 5.62,
          format: "Currency",
        },
        Discounts: {
          value: 7.279999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-17",
    value: 3220.78,
    tooltipData: {
      current: {
        value: {
          value: 3220.78,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 15.77,
          format: "Currency",
        },
        Discounts: {
          value: 4.73,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-18",
    value: 3343.839999999997,
    tooltipData: {
      current: {
        value: {
          value: 3343.839999999997,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 29.05,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-19",
    value: 3894.759999999998,
    tooltipData: {
      current: {
        value: {
          value: 3894.759999999998,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 20.67,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-20",
    value: 4267.009999999998,
    tooltipData: {
      current: {
        value: {
          value: 4267.009999999998,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 32.84999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-21",
    value: 5930.419999999998,
    tooltipData: {
      current: {
        value: {
          value: 5930.419999999998,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 11.5,
          format: "Currency",
        },
        Discounts: {
          value: 46.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-22",
    value: 6556.449999999997,
    tooltipData: {
      current: {
        value: {
          value: 6556.449999999997,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 236,
          format: "Number",
        },
        Returns: {
          value: 17.46,
          format: "Currency",
        },
        Discounts: {
          value: 95.53999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-23",
    value: 3211.97,
    tooltipData: {
      current: {
        value: {
          value: 3211.97,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 33.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-24",
    value: 3188.65,
    tooltipData: {
      current: {
        value: {
          value: 3188.65,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 5.27,
          format: "Currency",
        },
        Discounts: {
          value: 51.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-25",
    value: 3727.979999999998,
    tooltipData: {
      current: {
        value: {
          value: 3727.979999999998,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 30.01,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-26",
    value: 3228.360000000001,
    tooltipData: {
      current: {
        value: {
          value: 3228.360000000001,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 23.34999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 13.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-27",
    value: 4965.729999999995,
    tooltipData: {
      current: {
        value: {
          value: 4965.729999999995,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 10.9,
          format: "Currency",
        },
        Discounts: {
          value: 23.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-28",
    value: 6441.749999999991,
    tooltipData: {
      current: {
        value: {
          value: 6441.749999999991,
          format: "Currency",
        },
        Customers: {
          value: 23,
          format: "Number",
        },
        Transactions: {
          value: 254,
          format: "Number",
        },
        Returns: {
          value: 32.09,
          format: "Currency",
        },
        Discounts: {
          value: 49.72000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-29",
    value: 5802.849999999996,
    tooltipData: {
      current: {
        value: {
          value: 5802.849999999996,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 16.71,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-30",
    value: 2541.249999999999,
    tooltipData: {
      current: {
        value: {
          value: 2541.249999999999,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 8.8,
          format: "Currency",
        },
        Discounts: {
          value: 36.73,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-31",
    value: 3930.529999999998,
    tooltipData: {
      current: {
        value: {
          value: 3930.529999999998,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 70.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-01",
    value: 3744.619999999998,
    tooltipData: {
      current: {
        value: {
          value: 3744.619999999998,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 40.25,
          format: "Currency",
        },
        Discounts: {
          value: 6.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-02",
    value: 3938.399999999999,
    tooltipData: {
      current: {
        value: {
          value: 3938.399999999999,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.26,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-03",
    value: 5415.709999999995,
    tooltipData: {
      current: {
        value: {
          value: 5415.709999999995,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 24.71,
          format: "Currency",
        },
        Discounts: {
          value: 47.43000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-04",
    value: 6161.499999999996,
    tooltipData: {
      current: {
        value: {
          value: 6161.499999999996,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 207,
          format: "Number",
        },
        Returns: {
          value: 16.18,
          format: "Currency",
        },
        Discounts: {
          value: 41.77999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-05",
    value: 6335.499999999997,
    tooltipData: {
      current: {
        value: {
          value: 6335.499999999997,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 233,
          format: "Number",
        },
        Returns: {
          value: 5.69,
          format: "Currency",
        },
        Discounts: {
          value: 50.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-06",
    value: 2768.29,
    tooltipData: {
      current: {
        value: {
          value: 2768.29,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 13.05,
          format: "Currency",
        },
        Discounts: {
          value: 41.69000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-07",
    value: 3516.460000000001,
    tooltipData: {
      current: {
        value: {
          value: 3516.460000000001,
          format: "Currency",
        },
        Customers: {
          value: 17,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 16.95,
          format: "Currency",
        },
        Discounts: {
          value: 35.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-08",
    value: 3232.429999999998,
    tooltipData: {
      current: {
        value: {
          value: 3232.429999999998,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 46.37,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-09",
    value: 3427.090000000001,
    tooltipData: {
      current: {
        value: {
          value: 3427.090000000001,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.55,
          format: "Currency",
        },
        Discounts: {
          value: 29.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-10",
    value: 4638.789999999999,
    tooltipData: {
      current: {
        value: {
          value: 4638.789999999999,
          format: "Currency",
        },
        Customers: {
          value: 17,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 23.64,
          format: "Currency",
        },
        Discounts: {
          value: 52.26000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-11",
    value: 7163.269999999997,
    tooltipData: {
      current: {
        value: {
          value: 7163.269999999997,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 240,
          format: "Number",
        },
        Returns: {
          value: 55.47,
          format: "Currency",
        },
        Discounts: {
          value: 64.19,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-12",
    value: 5418.139999999993,
    tooltipData: {
      current: {
        value: {
          value: 5418.139999999993,
          format: "Currency",
        },
        Customers: {
          value: 24,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 5.68,
          format: "Currency",
        },
        Discounts: {
          value: 92.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-13",
    value: 3145.439999999998,
    tooltipData: {
      current: {
        value: {
          value: 3145.439999999998,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 43.80000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-14",
    value: 3555.12,
    tooltipData: {
      current: {
        value: {
          value: 3555.12,
          format: "Currency",
        },
        Customers: {
          value: 24,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 75.80999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 76.52000000000004,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-15",
    value: 3207.759999999998,
    tooltipData: {
      current: {
        value: {
          value: 3207.759999999998,
          format: "Currency",
        },
        Customers: {
          value: 21,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 18.15,
          format: "Currency",
        },
        Discounts: {
          value: 32.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-16",
    value: 3897.809999999999,
    tooltipData: {
      current: {
        value: {
          value: 3897.809999999999,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 45,
          format: "Currency",
        },
        Discounts: {
          value: 34.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-17",
    value: 6278.299999999997,
    tooltipData: {
      current: {
        value: {
          value: 6278.299999999997,
          format: "Currency",
        },
        Customers: {
          value: 31,
          format: "Number",
        },
        Transactions: {
          value: 204,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 74.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-18",
    value: 6609.499999999993,
    tooltipData: {
      current: {
        value: {
          value: 6609.499999999993,
          format: "Currency",
        },
        Customers: {
          value: 38,
          format: "Number",
        },
        Transactions: {
          value: 241,
          format: "Number",
        },
        Returns: {
          value: 16.63,
          format: "Currency",
        },
        Discounts: {
          value: 125.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-19",
    value: 5367.639999999996,
    tooltipData: {
      current: {
        value: {
          value: 5367.639999999996,
          format: "Currency",
        },
        Customers: {
          value: 34,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 46.11,
          format: "Currency",
        },
        Discounts: {
          value: 105.81,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-20",
    value: 3786.489999999999,
    tooltipData: {
      current: {
        value: {
          value: 3786.489999999999,
          format: "Currency",
        },
        Customers: {
          value: 53,
          format: "Number",
        },
        Transactions: {
          value: 179,
          format: "Number",
        },
        Returns: {
          value: 9.61,
          format: "Currency",
        },
        Discounts: {
          value: 89.80999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-21",
    value: 4135.479999999996,
    tooltipData: {
      current: {
        value: {
          value: 4135.479999999996,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 21.35,
          format: "Currency",
        },
        Discounts: {
          value: 110.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-22",
    value: 2527.909999999999,
    tooltipData: {
      current: {
        value: {
          value: 2527.909999999999,
          format: "Currency",
        },
        Customers: {
          value: 51,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 11.3,
          format: "Currency",
        },
        Discounts: {
          value: 80.27000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-23",
    value: 4789.29,
    tooltipData: {
      current: {
        value: {
          value: 4789.29,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 34.19,
          format: "Currency",
        },
        Discounts: {
          value: 150.37,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-24",
    value: 4925.569999999996,
    tooltipData: {
      current: {
        value: {
          value: 4925.569999999996,
          format: "Currency",
        },
        Customers: {
          value: 53,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 4.99,
          format: "Currency",
        },
        Discounts: {
          value: 138.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-25",
    value: 6760.149999999999,
    tooltipData: {
      current: {
        value: {
          value: 6760.149999999999,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 249,
          format: "Number",
        },
        Returns: {
          value: 33.2,
          format: "Currency",
        },
        Discounts: {
          value: 162.0600000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-26",
    value: 5473.86,
    tooltipData: {
      current: {
        value: {
          value: 5473.86,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 17.95,
          format: "Currency",
        },
        Discounts: {
          value: 139.02,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-27",
    value: 3338.29,
    tooltipData: {
      current: {
        value: {
          value: 3338.29,
          format: "Currency",
        },
        Customers: {
          value: 35,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 16.58,
          format: "Currency",
        },
        Discounts: {
          value: 53.93999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-28",
    value: 2979.4,
    tooltipData: {
      current: {
        value: {
          value: 2979.4,
          format: "Currency",
        },
        Customers: {
          value: 35,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 15.49,
          format: "Currency",
        },
        Discounts: {
          value: 70.78999999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-01",
    value: 4400.32,
    tooltipData: {
      current: {
        value: {
          value: 4400.32,
          format: "Currency",
        },
        Customers: {
          value: 49,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 16.49,
          format: "Currency",
        },
        Discounts: {
          value: 127.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-02",
    value: 4530.489999999994,
    tooltipData: {
      current: {
        value: {
          value: 4530.489999999994,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 13.3,
          format: "Currency",
        },
        Discounts: {
          value: 120.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-03",
    value: 5387.759999999998,
    tooltipData: {
      current: {
        value: {
          value: 5387.759999999998,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 209,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 141.34,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-04",
    value: 5882.650000000002,
    tooltipData: {
      current: {
        value: {
          value: 5882.650000000002,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 220,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 101.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-05",
    value: 5723.079999999998,
    tooltipData: {
      current: {
        value: {
          value: 5723.079999999998,
          format: "Currency",
        },
        Customers: {
          value: 52,
          format: "Number",
        },
        Transactions: {
          value: 232,
          format: "Number",
        },
        Returns: {
          value: 20.84,
          format: "Currency",
        },
        Discounts: {
          value: 137.9900000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-06",
    value: 3897.819999999998,
    tooltipData: {
      current: {
        value: {
          value: 3897.819999999998,
          format: "Currency",
        },
        Customers: {
          value: 41,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 2.97,
          format: "Currency",
        },
        Discounts: {
          value: 94.74000000000004,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-07",
    value: 3453.77,
    tooltipData: {
      current: {
        value: {
          value: 3453.77,
          format: "Currency",
        },
        Customers: {
          value: 40,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 3.7,
          format: "Currency",
        },
        Discounts: {
          value: 72.55000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-08",
    value: 4138.600000000001,
    tooltipData: {
      current: {
        value: {
          value: 4138.600000000001,
          format: "Currency",
        },
        Customers: {
          value: 38,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 4.09,
          format: "Currency",
        },
        Discounts: {
          value: 74.21000000000002,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-09",
    value: 4176.239999999999,
    tooltipData: {
      current: {
        value: {
          value: 4176.239999999999,
          format: "Currency",
        },
        Customers: {
          value: 42,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 103.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-10",
    value: 5266.629999999992,
    tooltipData: {
      current: {
        value: {
          value: 5266.629999999992,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 4.3,
          format: "Currency",
        },
        Discounts: {
          value: 110.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-11",
    value: 6313.419999999997,
    tooltipData: {
      current: {
        value: {
          value: 6313.419999999997,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 4.85,
          format: "Currency",
        },
        Discounts: {
          value: 101.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-12",
    value: 5082.659999999994,
    tooltipData: {
      current: {
        value: {
          value: 5082.659999999994,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 4.21,
          format: "Currency",
        },
        Discounts: {
          value: 104.59,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-13",
    value: 236.84,
    tooltipData: {
      current: {
        value: {
          value: 236.84,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 10,
          format: "Number",
        },
        Returns: {
          value: 1.13,
          format: "Currency",
        },
        Discounts: {
          value: 11.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-14",
    value: 3883.909999999997,
    tooltipData: {
      current: {
        value: {
          value: 3883.909999999997,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 16.18,
          format: "Currency",
        },
        Discounts: {
          value: 78.74000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-15",
    value: 3953.479999999998,
    tooltipData: {
      current: {
        value: {
          value: 3953.479999999998,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 109.32,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-16",
    value: 4824.569999999999,
    tooltipData: {
      current: {
        value: {
          value: 4824.569999999999,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 16,
          format: "Currency",
        },
        Discounts: {
          value: 149.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-17",
    value: 5005.539999999996,
    tooltipData: {
      current: {
        value: {
          value: 5005.539999999996,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 110.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-18",
    value: 4816.479999999995,
    tooltipData: {
      current: {
        value: {
          value: 4816.479999999995,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 23.16,
          format: "Currency",
        },
        Discounts: {
          value: 126.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-19",
    value: 5344.339999999997,
    tooltipData: {
      current: {
        value: {
          value: 5344.339999999997,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 12.39,
          format: "Currency",
        },
        Discounts: {
          value: 121.47,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-20",
    value: 3942.719999999998,
    tooltipData: {
      current: {
        value: {
          value: 3942.719999999998,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 110.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-21",
    value: 4679.429999999997,
    tooltipData: {
      current: {
        value: {
          value: 4679.429999999997,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 4.55,
          format: "Currency",
        },
        Discounts: {
          value: 85.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-22",
    value: 4689.949999999995,
    tooltipData: {
      current: {
        value: {
          value: 4689.949999999995,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 181.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-23",
    value: 5484.429999999997,
    tooltipData: {
      current: {
        value: {
          value: 5484.429999999997,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 206,
          format: "Number",
        },
        Returns: {
          value: 37.4,
          format: "Currency",
        },
        Discounts: {
          value: 130.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-24",
    value: 5439.78,
    tooltipData: {
      current: {
        value: {
          value: 5439.78,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 18.71,
          format: "Currency",
        },
        Discounts: {
          value: 169.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-25",
    value: 6258.569999999996,
    tooltipData: {
      current: {
        value: {
          value: 6258.569999999996,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 18.98,
          format: "Currency",
        },
        Discounts: {
          value: 221.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-26",
    value: 5600.459999999999,
    tooltipData: {
      current: {
        value: {
          value: 5600.459999999999,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 29.4,
          format: "Currency",
        },
        Discounts: {
          value: 234.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-27",
    value: 2891.669999999998,
    tooltipData: {
      current: {
        value: {
          value: 2891.669999999998,
          format: "Currency",
        },
        Customers: {
          value: 57,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 119.28,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-28",
    value: 3597.6,
    tooltipData: {
      current: {
        value: {
          value: 3597.6,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 12.33,
          format: "Currency",
        },
        Discounts: {
          value: 132.0300000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-29",
    value: 4195.949999999999,
    tooltipData: {
      current: {
        value: {
          value: 4195.949999999999,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 3.4,
          format: "Currency",
        },
        Discounts: {
          value: 153.7200000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-30",
    value: 4222.910000000001,
    tooltipData: {
      current: {
        value: {
          value: 4222.910000000001,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 16.95,
          format: "Currency",
        },
        Discounts: {
          value: 179.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-31",
    value: 4680.469999999997,
    tooltipData: {
      current: {
        value: {
          value: 4680.469999999997,
          format: "Currency",
        },
        Customers: {
          value: 118,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 222.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-01",
    value: 5809.589999999997,
    tooltipData: {
      current: {
        value: {
          value: 5809.589999999997,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 225,
          format: "Number",
        },
        Returns: {
          value: 17.85,
          format: "Currency",
        },
        Discounts: {
          value: 223.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-02",
    value: 6543.489999999995,
    tooltipData: {
      current: {
        value: {
          value: 6543.489999999995,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 261,
          format: "Number",
        },
        Returns: {
          value: 31.81,
          format: "Currency",
        },
        Discounts: {
          value: 253.68,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-03",
    value: 3181.239999999999,
    tooltipData: {
      current: {
        value: {
          value: 3181.239999999999,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 31.64,
          format: "Currency",
        },
        Discounts: {
          value: 126.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-04",
    value: 3963.249999999998,
    tooltipData: {
      current: {
        value: {
          value: 3963.249999999998,
          format: "Currency",
        },
        Customers: {
          value: 63,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 2.53,
          format: "Currency",
        },
        Discounts: {
          value: 101.05,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-05",
    value: 3239.049999999998,
    tooltipData: {
      current: {
        value: {
          value: 3239.049999999998,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 9.19,
          format: "Currency",
        },
        Discounts: {
          value: 131.23,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-06",
    value: 4845.279999999996,
    tooltipData: {
      current: {
        value: {
          value: 4845.279999999996,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 20.15,
          format: "Currency",
        },
        Discounts: {
          value: 203.2500000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-07",
    value: 5605.299999999999,
    tooltipData: {
      current: {
        value: {
          value: 5605.299999999999,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 14.45,
          format: "Currency",
        },
        Discounts: {
          value: 229.3900000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-08",
    value: 7298.299999999995,
    tooltipData: {
      current: {
        value: {
          value: 7298.299999999995,
          format: "Currency",
        },
        Customers: {
          value: 151,
          format: "Number",
        },
        Transactions: {
          value: 260,
          format: "Number",
        },
        Returns: {
          value: 18.95,
          format: "Currency",
        },
        Discounts: {
          value: 339.1999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-09",
    value: 6652.359999999993,
    tooltipData: {
      current: {
        value: {
          value: 6652.359999999993,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 266,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 228.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-10",
    value: 3312.819999999998,
    tooltipData: {
      current: {
        value: {
          value: 3312.819999999998,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 9.1,
          format: "Currency",
        },
        Discounts: {
          value: 140.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-11",
    value: 4307.830000000001,
    tooltipData: {
      current: {
        value: {
          value: 4307.830000000001,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 172.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-12",
    value: 3576.09,
    tooltipData: {
      current: {
        value: {
          value: 3576.09,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 14.63,
          format: "Currency",
        },
        Discounts: {
          value: 124.34,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-13",
    value: 3490.949999999999,
    tooltipData: {
      current: {
        value: {
          value: 3490.949999999999,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 1.55,
          format: "Currency",
        },
        Discounts: {
          value: 139.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-14",
    value: 5578.959999999996,
    tooltipData: {
      current: {
        value: {
          value: 5578.959999999996,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 21.91,
          format: "Currency",
        },
        Discounts: {
          value: 214.85,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-15",
    value: 5853.330000000001,
    tooltipData: {
      current: {
        value: {
          value: 5853.330000000001,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 42.93,
          format: "Currency",
        },
        Discounts: {
          value: 282.53,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-16",
    value: 6665.929999999997,
    tooltipData: {
      current: {
        value: {
          value: 6665.929999999997,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 263,
          format: "Number",
        },
        Returns: {
          value: 12.69,
          format: "Currency",
        },
        Discounts: {
          value: 222.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-17",
    value: 3839.339999999999,
    tooltipData: {
      current: {
        value: {
          value: 3839.339999999999,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 164.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-18",
    value: 3883.580000000002,
    tooltipData: {
      current: {
        value: {
          value: 3883.580000000002,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 6.17,
          format: "Currency",
        },
        Discounts: {
          value: 142.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-19",
    value: 4633.449999999997,
    tooltipData: {
      current: {
        value: {
          value: 4633.449999999997,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 199.59,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-20",
    value: 6677.539999999995,
    tooltipData: {
      current: {
        value: {
          value: 6677.539999999995,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 10.8,
          format: "Currency",
        },
        Discounts: {
          value: 283.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-21",
    value: 4374.269999999999,
    tooltipData: {
      current: {
        value: {
          value: 4374.269999999999,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 173.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-22",
    value: 6564.269999999994,
    tooltipData: {
      current: {
        value: {
          value: 6564.269999999994,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 245,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 207.6600000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-23",
    value: 5596.109999999998,
    tooltipData: {
      current: {
        value: {
          value: 5596.109999999998,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 20.9,
          format: "Currency",
        },
        Discounts: {
          value: 211.6299999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-24",
    value: 3131.729999999998,
    tooltipData: {
      current: {
        value: {
          value: 3131.729999999998,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 39.58000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 143.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-25",
    value: 3850.599999999998,
    tooltipData: {
      current: {
        value: {
          value: 3850.599999999998,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 4.26,
          format: "Currency",
        },
        Discounts: {
          value: 148.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-26",
    value: 3767.429999999999,
    tooltipData: {
      current: {
        value: {
          value: 3767.429999999999,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 2.45,
          format: "Currency",
        },
        Discounts: {
          value: 136.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-27",
    value: 4367.629999999997,
    tooltipData: {
      current: {
        value: {
          value: 4367.629999999997,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 179,
          format: "Number",
        },
        Returns: {
          value: 97.17,
          format: "Currency",
        },
        Discounts: {
          value: 191.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-28",
    value: 4553.809999999999,
    tooltipData: {
      current: {
        value: {
          value: 4553.809999999999,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 216.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-29",
    value: 5565.259999999997,
    tooltipData: {
      current: {
        value: {
          value: 5565.259999999997,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 234,
          format: "Number",
        },
        Returns: {
          value: 33.05,
          format: "Currency",
        },
        Discounts: {
          value: 181.3900000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-30",
    value: 5990.809999999998,
    tooltipData: {
      current: {
        value: {
          value: 5990.809999999998,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 222,
          format: "Number",
        },
        Returns: {
          value: 5.46,
          format: "Currency",
        },
        Discounts: {
          value: 207.0600000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-01",
    value: 2928.46,
    tooltipData: {
      current: {
        value: {
          value: 2928.46,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 6.6,
          format: "Currency",
        },
        Discounts: {
          value: 91.93999999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-02",
    value: 4111.929999999999,
    tooltipData: {
      current: {
        value: {
          value: 4111.929999999999,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 187.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-03",
    value: 3873.739999999998,
    tooltipData: {
      current: {
        value: {
          value: 3873.739999999998,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 153.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-04",
    value: 4714.389999999996,
    tooltipData: {
      current: {
        value: {
          value: 4714.389999999996,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 197,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 180.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-05",
    value: 5284.06,
    tooltipData: {
      current: {
        value: {
          value: 5284.06,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 219,
          format: "Number",
        },
        Returns: {
          value: 44.14,
          format: "Currency",
        },
        Discounts: {
          value: 210.8899999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-06",
    value: 6266.209999999996,
    tooltipData: {
      current: {
        value: {
          value: 6266.209999999996,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 243,
          format: "Number",
        },
        Returns: {
          value: 37.57,
          format: "Currency",
        },
        Discounts: {
          value: 265.8200000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-07",
    value: 5688.579999999997,
    tooltipData: {
      current: {
        value: {
          value: 5688.579999999997,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 235,
          format: "Number",
        },
        Returns: {
          value: 78.25,
          format: "Currency",
        },
        Discounts: {
          value: 199.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-08",
    value: 3811.939999999999,
    tooltipData: {
      current: {
        value: {
          value: 3811.939999999999,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 7.45,
          format: "Currency",
        },
        Discounts: {
          value: 146.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-09",
    value: 4091.059999999998,
    tooltipData: {
      current: {
        value: {
          value: 4091.059999999998,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 148.0700000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-10",
    value: 4271.38,
    tooltipData: {
      current: {
        value: {
          value: 4271.38,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 4.09,
          format: "Currency",
        },
        Discounts: {
          value: 121.86,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-11",
    value: 5270.209999999997,
    tooltipData: {
      current: {
        value: {
          value: 5270.209999999997,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 1.74,
          format: "Currency",
        },
        Discounts: {
          value: 156.5900000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-12",
    value: 4890.319999999997,
    tooltipData: {
      current: {
        value: {
          value: 4890.319999999997,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-13",
    value: 5877.969999999998,
    tooltipData: {
      current: {
        value: {
          value: 5877.969999999998,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 219,
          format: "Number",
        },
        Returns: {
          value: 65.33,
          format: "Currency",
        },
        Discounts: {
          value: 184.8899999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-14",
    value: 5877.829999999997,
    tooltipData: {
      current: {
        value: {
          value: 5877.829999999997,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 205.5299999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-15",
    value: 2855.99,
    tooltipData: {
      current: {
        value: {
          value: 2855.99,
          format: "Currency",
        },
        Customers: {
          value: 44,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.38,
          format: "Currency",
        },
        Discounts: {
          value: 85.40999999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-16",
    value: 3736.989999999999,
    tooltipData: {
      current: {
        value: {
          value: 3736.989999999999,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 26.65,
          format: "Currency",
        },
        Discounts: {
          value: 129.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-17",
    value: 4080.979999999998,
    tooltipData: {
      current: {
        value: {
          value: 4080.979999999998,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 21.08,
          format: "Currency",
        },
        Discounts: {
          value: 129.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-18",
    value: 3762.079999999998,
    tooltipData: {
      current: {
        value: {
          value: 3762.079999999998,
          format: "Currency",
        },
        Customers: {
          value: 55,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 113.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-19",
    value: 4862.669999999996,
    tooltipData: {
      current: {
        value: {
          value: 4862.669999999996,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 21.17,
          format: "Currency",
        },
        Discounts: {
          value: 145.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-20",
    value: 5924.619999999993,
    tooltipData: {
      current: {
        value: {
          value: 5924.619999999993,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 5.7,
          format: "Currency",
        },
        Discounts: {
          value: 180.6500000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-21",
    value: 7024.989999999998,
    tooltipData: {
      current: {
        value: {
          value: 7024.989999999998,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 244,
          format: "Number",
        },
        Returns: {
          value: 4.45,
          format: "Currency",
        },
        Discounts: {
          value: 262.96,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-22",
    value: 3491.010000000001,
    tooltipData: {
      current: {
        value: {
          value: 3491.010000000001,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 113.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-23",
    value: 3628.650000000001,
    tooltipData: {
      current: {
        value: {
          value: 3628.650000000001,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 1.73,
          format: "Currency",
        },
        Discounts: {
          value: 129.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-24",
    value: 3343.979999999998,
    tooltipData: {
      current: {
        value: {
          value: 3343.979999999998,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 22.08,
          format: "Currency",
        },
        Discounts: {
          value: 115.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-25",
    value: 4383.919999999999,
    tooltipData: {
      current: {
        value: {
          value: 4383.919999999999,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 16.85,
          format: "Currency",
        },
        Discounts: {
          value: 188.7900000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-26",
    value: 4930.809999999999,
    tooltipData: {
      current: {
        value: {
          value: 4930.809999999999,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 20.79,
          format: "Currency",
        },
        Discounts: {
          value: 176.0300000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-27",
    value: 4649.289999999999,
    tooltipData: {
      current: {
        value: {
          value: 4649.289999999999,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 191,
          format: "Number",
        },
        Returns: {
          value: 18.6,
          format: "Currency",
        },
        Discounts: {
          value: 194.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-28",
    value: 4097.359999999997,
    tooltipData: {
      current: {
        value: {
          value: 4097.359999999997,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 155.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-29",
    value: 2988.97,
    tooltipData: {
      current: {
        value: {
          value: 2988.97,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 5.2,
          format: "Currency",
        },
        Discounts: {
          value: 106.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-30",
    value: 2729.240000000001,
    tooltipData: {
      current: {
        value: {
          value: 2729.240000000001,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 132.27,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-31",
    value: 3823.969999999999,
    tooltipData: {
      current: {
        value: {
          value: 3823.969999999999,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 6.109999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 176.0599999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-01",
    value: 2991.319999999997,
    tooltipData: {
      current: {
        value: {
          value: 2991.319999999997,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 19.66,
          format: "Currency",
        },
        Discounts: {
          value: 111.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-02",
    value: 3756.930000000001,
    tooltipData: {
      current: {
        value: {
          value: 3756.930000000001,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 130.38,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-03",
    value: 4271.119999999998,
    tooltipData: {
      current: {
        value: {
          value: 4271.119999999998,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 8.25,
          format: "Currency",
        },
        Discounts: {
          value: 156.7000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-04",
    value: 3971.909999999999,
    tooltipData: {
      current: {
        value: {
          value: 3971.909999999999,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 35.17,
          format: "Currency",
        },
        Discounts: {
          value: 140.64,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-05",
    value: 2839.48,
    tooltipData: {
      current: {
        value: {
          value: 2839.48,
          format: "Currency",
        },
        Customers: {
          value: 59,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 8.1,
          format: "Currency",
        },
        Discounts: {
          value: 107.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-06",
    value: 3218.469999999999,
    tooltipData: {
      current: {
        value: {
          value: 3218.469999999999,
          format: "Currency",
        },
        Customers: {
          value: 63,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.75,
          format: "Currency",
        },
        Discounts: {
          value: 154.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-07",
    value: 3084.560000000001,
    tooltipData: {
      current: {
        value: {
          value: 3084.560000000001,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 27.71,
          format: "Currency",
        },
        Discounts: {
          value: 112.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-08",
    value: 3338.510000000001,
    tooltipData: {
      current: {
        value: {
          value: 3338.510000000001,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 121.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-09",
    value: 3521.199999999997,
    tooltipData: {
      current: {
        value: {
          value: 3521.199999999997,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 4.97,
          format: "Currency",
        },
        Discounts: {
          value: 133.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-10",
    value: 4108.459999999998,
    tooltipData: {
      current: {
        value: {
          value: 4108.459999999998,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 4.45,
          format: "Currency",
        },
        Discounts: {
          value: 141.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-11",
    value: 3668.800000000002,
    tooltipData: {
      current: {
        value: {
          value: 3668.800000000002,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 9.39,
          format: "Currency",
        },
        Discounts: {
          value: 176.2900000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-12",
    value: 2797.57,
    tooltipData: {
      current: {
        value: {
          value: 2797.57,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 0.55,
          format: "Currency",
        },
        Discounts: {
          value: 133.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-13",
    value: 3206.809999999999,
    tooltipData: {
      current: {
        value: {
          value: 3206.809999999999,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 11.15,
          format: "Currency",
        },
        Discounts: {
          value: 114.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-14",
    value: 2774.52,
    tooltipData: {
      current: {
        value: {
          value: 2774.52,
          format: "Currency",
        },
        Customers: {
          value: 51,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 109.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-15",
    value: 3248.999999999999,
    tooltipData: {
      current: {
        value: {
          value: 3248.999999999999,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 136.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-16",
    value: 4078.439999999998,
    tooltipData: {
      current: {
        value: {
          value: 4078.439999999998,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 20.95,
          format: "Currency",
        },
        Discounts: {
          value: 169.2000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-17",
    value: 4478.489999999995,
    tooltipData: {
      current: {
        value: {
          value: 4478.489999999995,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 42.01,
          format: "Currency",
        },
        Discounts: {
          value: 177.76,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-18",
    value: 4191.849999999999,
    tooltipData: {
      current: {
        value: {
          value: 4191.849999999999,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 1.95,
          format: "Currency",
        },
        Discounts: {
          value: 180.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-19",
    value: 2550.679999999999,
    tooltipData: {
      current: {
        value: {
          value: 2550.679999999999,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 99.89999999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-20",
    value: 2267.46,
    tooltipData: {
      current: {
        value: {
          value: 2267.46,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 23.66,
          format: "Currency",
        },
        Discounts: {
          value: 116.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-21",
    value: 2800.909999999999,
    tooltipData: {
      current: {
        value: {
          value: 2800.909999999999,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 2.31,
          format: "Currency",
        },
        Discounts: {
          value: 115.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-22",
    value: 3662.83,
    tooltipData: {
      current: {
        value: {
          value: 3662.83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0.5,
          format: "Currency",
        },
        Discounts: {
          value: 163.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-23",
    value: 3453.05,
    tooltipData: {
      current: {
        value: {
          value: 3453.05,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 116.86,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-24",
    value: 4764.199999999996,
    tooltipData: {
      current: {
        value: {
          value: 4764.199999999996,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 63.83,
          format: "Currency",
        },
        Discounts: {
          value: 201.55,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-25",
    value: 4529.339999999997,
    tooltipData: {
      current: {
        value: {
          value: 4529.339999999997,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 28,
          format: "Currency",
        },
        Discounts: {
          value: 191.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-26",
    value: 3782.069999999997,
    tooltipData: {
      current: {
        value: {
          value: 3782.069999999997,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 207.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-27",
    value: 3708.779999999999,
    tooltipData: {
      current: {
        value: {
          value: 3708.779999999999,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 4.5,
          format: "Currency",
        },
        Discounts: {
          value: 227.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-28",
    value: 2466.32,
    tooltipData: {
      current: {
        value: {
          value: 2466.32,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        Returns: {
          value: 44.14,
          format: "Currency",
        },
        Discounts: {
          value: 101.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-29",
    value: 3932.549999999998,
    tooltipData: {
      current: {
        value: {
          value: 3932.549999999998,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 12.7,
          format: "Currency",
        },
        Discounts: {
          value: 127.56,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-30",
    value: 3208.599999999998,
    tooltipData: {
      current: {
        value: {
          value: 3208.599999999998,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 12.28,
          format: "Currency",
        },
        Discounts: {
          value: 96.27,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-01",
    value: 3704.899999999998,
    tooltipData: {
      current: {
        value: {
          value: 3704.899999999998,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 9.77,
          format: "Currency",
        },
        Discounts: {
          value: 139.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-02",
    value: 4811.309999999997,
    tooltipData: {
      current: {
        value: {
          value: 4811.309999999997,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 6.71,
          format: "Currency",
        },
        Discounts: {
          value: 157.84,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-03",
    value: 3050.450000000001,
    tooltipData: {
      current: {
        value: {
          value: 3050.450000000001,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 1.78,
          format: "Currency",
        },
        Discounts: {
          value: 132.68,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-04",
    value: 2277.230000000001,
    tooltipData: {
      current: {
        value: {
          value: 2277.230000000001,
          format: "Currency",
        },
        Customers: {
          value: 42,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 68.48999999999997,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-05",
    value: 2614.17,
    tooltipData: {
      current: {
        value: {
          value: 2614.17,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 6.199999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 89.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-06",
    value: 2709.08,
    tooltipData: {
      current: {
        value: {
          value: 2709.08,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 119.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-07",
    value: 3964.969999999996,
    tooltipData: {
      current: {
        value: {
          value: 3964.969999999996,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 6.34,
          format: "Currency",
        },
        Discounts: {
          value: 189.6900000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-08",
    value: 4187.799999999998,
    tooltipData: {
      current: {
        value: {
          value: 4187.799999999998,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 2.32,
          format: "Currency",
        },
        Discounts: {
          value: 161.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-09",
    value: 4770.229999999998,
    tooltipData: {
      current: {
        value: {
          value: 4770.229999999998,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 190,
          format: "Number",
        },
        Returns: {
          value: 34.61,
          format: "Currency",
        },
        Discounts: {
          value: 180.3299999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-10",
    value: 3567.189999999998,
    tooltipData: {
      current: {
        value: {
          value: 3567.189999999998,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 4.17,
          format: "Currency",
        },
        Discounts: {
          value: 132.27,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-11",
    value: 2631.189999999999,
    tooltipData: {
      current: {
        value: {
          value: 2631.189999999999,
          format: "Currency",
        },
        Customers: {
          value: 49,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 82.06000000000004,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-12",
    value: 4524.459999999999,
    tooltipData: {
      current: {
        value: {
          value: 4524.459999999999,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 8.6,
          format: "Currency",
        },
        Discounts: {
          value: 122.22,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-13",
    value: 4529.549999999996,
    tooltipData: {
      current: {
        value: {
          value: 4529.549999999996,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 11.57,
          format: "Currency",
        },
        Discounts: {
          value: 127.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-14",
    value: 4370.009999999999,
    tooltipData: {
      current: {
        value: {
          value: 4370.009999999999,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 144.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-15",
    value: 3978.57,
    tooltipData: {
      current: {
        value: {
          value: 3978.57,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 42.85,
          format: "Currency",
        },
        Discounts: {
          value: 180.7400000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-16",
    value: 4061.159999999999,
    tooltipData: {
      current: {
        value: {
          value: 4061.159999999999,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 195.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-17",
    value: 2928.899999999998,
    tooltipData: {
      current: {
        value: {
          value: 2928.899999999998,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 121.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-18",
    value: 3209.329999999998,
    tooltipData: {
      current: {
        value: {
          value: 3209.329999999998,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.84,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-19",
    value: 2465.479999999999,
    tooltipData: {
      current: {
        value: {
          value: 2465.479999999999,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 19.43,
          format: "Currency",
        },
        Discounts: {
          value: 118.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-20",
    value: 4280.5,
    tooltipData: {
      current: {
        value: {
          value: 4280.5,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 129.16,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-21",
    value: 10745.63999999998,
    tooltipData: {
      current: {
        value: {
          value: 10745.63999999998,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 299,
          format: "Number",
        },
        Returns: {
          value: 75.47999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 230.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-22",
    value: 6459.409999999995,
    tooltipData: {
      current: {
        value: {
          value: 6459.409999999995,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 127.3,
          format: "Currency",
        },
        Discounts: {
          value: 172.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-23",
    value: 6559.439999999991,
    tooltipData: {
      current: {
        value: {
          value: 6559.439999999991,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 3.9,
          format: "Currency",
        },
        Discounts: {
          value: 162.7100000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-24",
    value: 2962.170000000001,
    tooltipData: {
      current: {
        value: {
          value: 2962.170000000001,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 2.8,
          format: "Currency",
        },
        Discounts: {
          value: 114.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-25",
    value: 4431.549999999998,
    tooltipData: {
      current: {
        value: {
          value: 4431.549999999998,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 157.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-26",
    value: 4206.800000000001,
    tooltipData: {
      current: {
        value: {
          value: 4206.800000000001,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 8.899999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 120.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-27",
    value: 3876.869999999998,
    tooltipData: {
      current: {
        value: {
          value: 3876.869999999998,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-28",
    value: 4381.639999999999,
    tooltipData: {
      current: {
        value: {
          value: 4381.639999999999,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 4.6,
          format: "Currency",
        },
        Discounts: {
          value: 134.3200000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-29",
    value: 4856.24,
    tooltipData: {
      current: {
        value: {
          value: 4856.24,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 210.7699999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-30",
    value: 4634.819999999997,
    tooltipData: {
      current: {
        value: {
          value: 4634.819999999997,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 191,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 172.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-31",
    value: 3833.710000000001,
    tooltipData: {
      current: {
        value: {
          value: 3833.710000000001,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 3.6,
          format: "Currency",
        },
        Discounts: {
          value: 168.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-01",
    value: 2828.97,
    tooltipData: {
      current: {
        value: {
          value: 2828.97,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 2.13,
          format: "Currency",
        },
        Discounts: {
          value: 125.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-02",
    value: 3849.409999999999,
    tooltipData: {
      current: {
        value: {
          value: 3849.409999999999,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 40,
          format: "Currency",
        },
        Discounts: {
          value: 172.5699999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-03",
    value: 3314.41,
    tooltipData: {
      current: {
        value: {
          value: 3314.41,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 5.5,
          format: "Currency",
        },
        Discounts: {
          value: 124.1599999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-04",
    value: 4459.219999999998,
    tooltipData: {
      current: {
        value: {
          value: 4459.219999999998,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 18.26,
          format: "Currency",
        },
        Discounts: {
          value: 182.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-05",
    value: 5866.569999999997,
    tooltipData: {
      current: {
        value: {
          value: 5866.569999999997,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 3.09,
          format: "Currency",
        },
        Discounts: {
          value: 253.1599999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-06",
    value: 5567.559999999994,
    tooltipData: {
      current: {
        value: {
          value: 5567.559999999994,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 27.86,
          format: "Currency",
        },
        Discounts: {
          value: 203.5299999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-07",
    value: 3639.169999999997,
    tooltipData: {
      current: {
        value: {
          value: 3639.169999999997,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 2,
          format: "Currency",
        },
        Discounts: {
          value: 174.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-08",
    value: 3280.809999999998,
    tooltipData: {
      current: {
        value: {
          value: 3280.809999999998,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 128.76,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-09",
    value: 3019.710000000001,
    tooltipData: {
      current: {
        value: {
          value: 3019.710000000001,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 15.69,
          format: "Currency",
        },
        Discounts: {
          value: 113.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-10",
    value: 3826.110000000001,
    tooltipData: {
      current: {
        value: {
          value: 3826.110000000001,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 7.25,
          format: "Currency",
        },
        Discounts: {
          value: 121.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-11",
    value: 4453.059999999999,
    tooltipData: {
      current: {
        value: {
          value: 4453.059999999999,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 8.6,
          format: "Currency",
        },
        Discounts: {
          value: 184.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-12",
    value: 5944.909999999996,
    tooltipData: {
      current: {
        value: {
          value: 5944.909999999996,
          format: "Currency",
        },
        Customers: {
          value: 129,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 1.26,
          format: "Currency",
        },
        Discounts: {
          value: 246.9600000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-13",
    value: 5784.3,
    tooltipData: {
      current: {
        value: {
          value: 5784.3,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 238,
          format: "Number",
        },
        Returns: {
          value: 18.22,
          format: "Currency",
        },
        Discounts: {
          value: 265.69,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-14",
    value: 2730.439999999999,
    tooltipData: {
      current: {
        value: {
          value: 2730.439999999999,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 15.15,
          format: "Currency",
        },
        Discounts: {
          value: 102.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-15",
    value: 5585.919999999996,
    tooltipData: {
      current: {
        value: {
          value: 5585.919999999996,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 20,
          format: "Currency",
        },
        Discounts: {
          value: 187.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-16",
    value: 3763.429999999998,
    tooltipData: {
      current: {
        value: {
          value: 3763.429999999998,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 175.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-17",
    value: 4163.79,
    tooltipData: {
      current: {
        value: {
          value: 4163.79,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 31.97,
          format: "Currency",
        },
        Discounts: {
          value: 151.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-18",
    value: 4860.36,
    tooltipData: {
      current: {
        value: {
          value: 4860.36,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 193,
          format: "Number",
        },
        Returns: {
          value: 53.64999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 192.2100000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-19",
    value: 6210.199999999999,
    tooltipData: {
      current: {
        value: {
          value: 6210.199999999999,
          format: "Currency",
        },
        Customers: {
          value: 140,
          format: "Number",
        },
        Transactions: {
          value: 240,
          format: "Number",
        },
        Returns: {
          value: 9.54,
          format: "Currency",
        },
        Discounts: {
          value: 270.4399999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-20",
    value: 4047.78,
    tooltipData: {
      current: {
        value: {
          value: 4047.78,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 207,
          format: "Number",
        },
        Returns: {
          value: 21.75,
          format: "Currency",
        },
        Discounts: {
          value: 177.1799999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-21",
    value: 2442.409999999999,
    tooltipData: {
      current: {
        value: {
          value: 2442.409999999999,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 33.75,
          format: "Currency",
        },
        Discounts: {
          value: 114.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-22",
    value: 3807.49,
    tooltipData: {
      current: {
        value: {
          value: 3807.49,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 4.39,
          format: "Currency",
        },
        Discounts: {
          value: 325.9299999999997,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-23",
    value: 3149.379999999999,
    tooltipData: {
      current: {
        value: {
          value: 3149.379999999999,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 2.75,
          format: "Currency",
        },
        Discounts: {
          value: 158.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-24",
    value: 4299.569999999999,
    tooltipData: {
      current: {
        value: {
          value: 4299.569999999999,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 26.5,
          format: "Currency",
        },
        Discounts: {
          value: 336.91,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-25",
    value: 3683.539999999996,
    tooltipData: {
      current: {
        value: {
          value: 3683.539999999996,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 278.3400000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-26",
    value: 5328.859999999998,
    tooltipData: {
      current: {
        value: {
          value: 5328.859999999998,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 209,
          format: "Number",
        },
        Returns: {
          value: 15.71,
          format: "Currency",
        },
        Discounts: {
          value: 296.3199999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-27",
    value: 5989.529999999999,
    tooltipData: {
      current: {
        value: {
          value: 5989.529999999999,
          format: "Currency",
        },
        Customers: {
          value: 118,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 16.87,
          format: "Currency",
        },
        Discounts: {
          value: 382.1700000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-28",
    value: 3586.529999999999,
    tooltipData: {
      current: {
        value: {
          value: 3586.529999999999,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 282.68,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-29",
    value: 3611.869999999999,
    tooltipData: {
      current: {
        value: {
          value: 3611.869999999999,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 193.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-30",
    value: 4547.349999999997,
    tooltipData: {
      current: {
        value: {
          value: 4547.349999999997,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 278.19,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-31",
    value: 4056.719999999998,
    tooltipData: {
      current: {
        value: {
          value: 4056.719999999998,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 32.01000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 252.5199999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-01",
    value: 5547.919999999996,
    tooltipData: {
      current: {
        value: {
          value: 5547.919999999996,
          format: "Currency",
        },
        Customers: {
          value: 104,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 4.2,
          format: "Currency",
        },
        Discounts: {
          value: 482.4300000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-02",
    value: 5211.760000000001,
    tooltipData: {
      current: {
        value: {
          value: 5211.760000000001,
          format: "Currency",
        },
        Customers: {
          value: 128,
          format: "Number",
        },
        Transactions: {
          value: 228,
          format: "Number",
        },
        Returns: {
          value: 1.83,
          format: "Currency",
        },
        Discounts: {
          value: 314.8199999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-03",
    value: 5241.799999999999,
    tooltipData: {
      current: {
        value: {
          value: 5241.799999999999,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 21.79,
          format: "Currency",
        },
        Discounts: {
          value: 295.9400000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-04",
    value: 4372.679999999999,
    tooltipData: {
      current: {
        value: {
          value: 4372.679999999999,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 300.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-05",
    value: 3257.55,
    tooltipData: {
      current: {
        value: {
          value: 3257.55,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 15.6,
          format: "Currency",
        },
        Discounts: {
          value: 180.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-06",
    value: 4674.949999999997,
    tooltipData: {
      current: {
        value: {
          value: 4674.949999999997,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 8.99,
          format: "Currency",
        },
        Discounts: {
          value: 262.02,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-07",
    value: 4251.16,
    tooltipData: {
      current: {
        value: {
          value: 4251.16,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 55.2,
          format: "Currency",
        },
        Discounts: {
          value: 428.48,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-08",
    value: 3829.509999999999,
    tooltipData: {
      current: {
        value: {
          value: 3829.509999999999,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 10.89,
          format: "Currency",
        },
        Discounts: {
          value: 207.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-09",
    value: 5641.49,
    tooltipData: {
      current: {
        value: {
          value: 5641.49,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 6.84,
          format: "Currency",
        },
        Discounts: {
          value: 393.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-10",
    value: 4627.009999999999,
    tooltipData: {
      current: {
        value: {
          value: 4627.009999999999,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 8.4,
          format: "Currency",
        },
        Discounts: {
          value: 267.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-11",
    value: 2448.190000000001,
    tooltipData: {
      current: {
        value: {
          value: 2448.190000000001,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 131.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-12",
    value: 3221.719999999998,
    tooltipData: {
      current: {
        value: {
          value: 3221.719999999998,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.3,
          format: "Currency",
        },
        Discounts: {
          value: 299.6100000000004,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-13",
    value: 4317.190000000001,
    tooltipData: {
      current: {
        value: {
          value: 4317.190000000001,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 5.65,
          format: "Currency",
        },
        Discounts: {
          value: 309.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-14",
    value: 4068.429999999999,
    tooltipData: {
      current: {
        value: {
          value: 4068.429999999999,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 255.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-15",
    value: 5069.499999999995,
    tooltipData: {
      current: {
        value: {
          value: 5069.499999999995,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 71.5,
          format: "Currency",
        },
        Discounts: {
          value: 343.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-16",
    value: 5420.049999999995,
    tooltipData: {
      current: {
        value: {
          value: 5420.049999999995,
          format: "Currency",
        },
        Customers: {
          value: 138,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 30.27,
          format: "Currency",
        },
        Discounts: {
          value: 399.3800000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-17",
    value: 5947.769999999998,
    tooltipData: {
      current: {
        value: {
          value: 5947.769999999998,
          format: "Currency",
        },
        Customers: {
          value: 142,
          format: "Number",
        },
        Transactions: {
          value: 237,
          format: "Number",
        },
        Returns: {
          value: 32.59,
          format: "Currency",
        },
        Discounts: {
          value: 415.1100000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-18",
    value: 2450.379999999999,
    tooltipData: {
      current: {
        value: {
          value: 2450.379999999999,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 20.48,
          format: "Currency",
        },
        Discounts: {
          value: 118.86,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-19",
    value: 2660.349999999998,
    tooltipData: {
      current: {
        value: {
          value: 2660.349999999998,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 187.7700000000002,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-20",
    value: 3240.270000000001,
    tooltipData: {
      current: {
        value: {
          value: 3240.270000000001,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 209.7500000000002,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-21",
    value: 4305.939999999998,
    tooltipData: {
      current: {
        value: {
          value: 4305.939999999998,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 410.7700000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-22",
    value: 4585.48,
    tooltipData: {
      current: {
        value: {
          value: 4585.48,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 83.19999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 295.5599999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-23",
    value: 4468.279999999996,
    tooltipData: {
      current: {
        value: {
          value: 4468.279999999996,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 20.09,
          format: "Currency",
        },
        Discounts: {
          value: 232.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-24",
    value: 5151.010000000002,
    tooltipData: {
      current: {
        value: {
          value: 5151.010000000002,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 215,
          format: "Number",
        },
        Returns: {
          value: 34.17,
          format: "Currency",
        },
        Discounts: {
          value: 321.4799999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-25",
    value: 2728.72,
    tooltipData: {
      current: {
        value: {
          value: 2728.72,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 5.25,
          format: "Currency",
        },
        Discounts: {
          value: 143.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-26",
    value: 3589.56,
    tooltipData: {
      current: {
        value: {
          value: 3589.56,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 209.59,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-27",
    value: 3073.51,
    tooltipData: {
      current: {
        value: {
          value: 3073.51,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 227.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-28",
    value: 3999.439999999998,
    tooltipData: {
      current: {
        value: {
          value: 3999.439999999998,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 3.05,
          format: "Currency",
        },
        Discounts: {
          value: 308.1399999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-29",
    value: 4697.419999999996,
    tooltipData: {
      current: {
        value: {
          value: 4697.419999999996,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 10.3,
          format: "Currency",
        },
        Discounts: {
          value: 359.5499999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-30",
    value: 4226.109999999999,
    tooltipData: {
      current: {
        value: {
          value: 4226.109999999999,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 17.9,
          format: "Currency",
        },
        Discounts: {
          value: 237.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-01",
    value: 5034.969999999998,
    tooltipData: {
      current: {
        value: {
          value: 5034.969999999998,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 25.99,
          format: "Currency",
        },
        Discounts: {
          value: 273.9600000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-02",
    value: 3153.19,
    tooltipData: {
      current: {
        value: {
          value: 3153.19,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 163.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-03",
    value: 3536.229999999998,
    tooltipData: {
      current: {
        value: {
          value: 3536.229999999998,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 43.5,
          format: "Currency",
        },
        Discounts: {
          value: 198.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-04",
    value: 3549.459999999999,
    tooltipData: {
      current: {
        value: {
          value: 3549.459999999999,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 222.96,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-05",
    value: 4107.789999999999,
    tooltipData: {
      current: {
        value: {
          value: 4107.789999999999,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 1.15,
          format: "Currency",
        },
        Discounts: {
          value: 385.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-06",
    value: 4004.279999999997,
    tooltipData: {
      current: {
        value: {
          value: 4004.279999999997,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 10,
          format: "Currency",
        },
        Discounts: {
          value: 284.91,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-07",
    value: 5586.569999999997,
    tooltipData: {
      current: {
        value: {
          value: 5586.569999999997,
          format: "Currency",
        },
        Customers: {
          value: 137,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 349.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-08",
    value: 5238.729999999995,
    tooltipData: {
      current: {
        value: {
          value: 5238.729999999995,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 199,
          format: "Number",
        },
        Returns: {
          value: 7.02,
          format: "Currency",
        },
        Discounts: {
          value: 287.1600000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-09",
    value: 2934.710000000001,
    tooltipData: {
      current: {
        value: {
          value: 2934.710000000001,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 155.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-10",
    value: 3742.739999999998,
    tooltipData: {
      current: {
        value: {
          value: 3742.739999999998,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 1.87,
          format: "Currency",
        },
        Discounts: {
          value: 205.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-11",
    value: 3626.449999999997,
    tooltipData: {
      current: {
        value: {
          value: 3626.449999999997,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 257.2000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-12",
    value: 4891.029999999999,
    tooltipData: {
      current: {
        value: {
          value: 4891.029999999999,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 4.1,
          format: "Currency",
        },
        Discounts: {
          value: 501.2600000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-13",
    value: 4482.97,
    tooltipData: {
      current: {
        value: {
          value: 4482.97,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 308.0400000000002,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-14",
    value: 4812.289999999995,
    tooltipData: {
      current: {
        value: {
          value: 4812.289999999995,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 214,
          format: "Number",
        },
        Returns: {
          value: 13.27,
          format: "Currency",
        },
        Discounts: {
          value: 272.84,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-15",
    value: 4820.199999999995,
    tooltipData: {
      current: {
        value: {
          value: 4820.199999999995,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 24.87,
          format: "Currency",
        },
        Discounts: {
          value: 270.9900000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-16",
    value: 2468.990000000001,
    tooltipData: {
      current: {
        value: {
          value: 2468.990000000001,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 11.98,
          format: "Currency",
        },
        Discounts: {
          value: 138.8500000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-17",
    value: 3370.849999999998,
    tooltipData: {
      current: {
        value: {
          value: 3370.849999999998,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 2.33,
          format: "Currency",
        },
        Discounts: {
          value: 216.1700000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-18",
    value: 3788.979999999997,
    tooltipData: {
      current: {
        value: {
          value: 3788.979999999997,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 262.4199999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-19",
    value: 4254.069999999997,
    tooltipData: {
      current: {
        value: {
          value: 4254.069999999997,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 22.46,
          format: "Currency",
        },
        Discounts: {
          value: 294.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-20",
    value: 4818.709999999996,
    tooltipData: {
      current: {
        value: {
          value: 4818.709999999996,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 9.6,
          format: "Currency",
        },
        Discounts: {
          value: 388.6800000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-21",
    value: 4257.059999999997,
    tooltipData: {
      current: {
        value: {
          value: 4257.059999999997,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 35.8,
          format: "Currency",
        },
        Discounts: {
          value: 260.8700000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-22",
    value: 5872.779999999993,
    tooltipData: {
      current: {
        value: {
          value: 5872.779999999993,
          format: "Currency",
        },
        Customers: {
          value: 139,
          format: "Number",
        },
        Transactions: {
          value: 215,
          format: "Number",
        },
        Returns: {
          value: 4.4,
          format: "Currency",
        },
        Discounts: {
          value: 355.9299999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-23",
    value: 2672.01,
    tooltipData: {
      current: {
        value: {
          value: 2672.01,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 9.02,
          format: "Currency",
        },
        Discounts: {
          value: 152.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-24",
    value: 3924.599999999999,
    tooltipData: {
      current: {
        value: {
          value: 3924.599999999999,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 1.7,
          format: "Currency",
        },
        Discounts: {
          value: 267.4500000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-25",
    value: 4055.400000000001,
    tooltipData: {
      current: {
        value: {
          value: 4055.400000000001,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 7.42,
          format: "Currency",
        },
        Discounts: {
          value: 309.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-26",
    value: 5141.699999999999,
    tooltipData: {
      current: {
        value: {
          value: 5141.699999999999,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 465.9699999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-27",
    value: 6613.529999999997,
    tooltipData: {
      current: {
        value: {
          value: 6613.529999999997,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 7.97,
          format: "Currency",
        },
        Discounts: {
          value: 665.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-28",
    value: 4039.3,
    tooltipData: {
      current: {
        value: {
          value: 4039.3,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 7.48,
          format: "Currency",
        },
        Discounts: {
          value: 228.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-29",
    value: 4640.029999999998,
    tooltipData: {
      current: {
        value: {
          value: 4640.029999999998,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 204,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 229.3400000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-30",
    value: 2457.99,
    tooltipData: {
      current: {
        value: {
          value: 2457.99,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 5.47,
          format: "Currency",
        },
        Discounts: {
          value: 147,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-31",
    value: 3425.579999999998,
    tooltipData: {
      current: {
        value: {
          value: 3425.579999999998,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 246.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-01",
    value: 5042.070000000002,
    tooltipData: {
      current: {
        value: {
          value: 5042.070000000002,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 518.0399999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-02",
    value: 3525.52,
    tooltipData: {
      current: {
        value: {
          value: 3525.52,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 223.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-03",
    value: 3865.579999999999,
    tooltipData: {
      current: {
        value: {
          value: 3865.579999999999,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 227.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-04",
    value: 3871.679999999998,
    tooltipData: {
      current: {
        value: {
          value: 3871.679999999998,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 31.3,
          format: "Currency",
        },
        Discounts: {
          value: 205.91,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-05",
    value: 4730.809999999997,
    tooltipData: {
      current: {
        value: {
          value: 4730.809999999997,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 1.29,
          format: "Currency",
        },
        Discounts: {
          value: 222.55,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-06",
    value: 4564.879999999998,
    tooltipData: {
      current: {
        value: {
          value: 4564.879999999998,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 502.6399999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-07",
    value: 3889.479999999999,
    tooltipData: {
      current: {
        value: {
          value: 3889.479999999999,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 16.2,
          format: "Currency",
        },
        Discounts: {
          value: 397.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-08",
    value: 3656.979999999999,
    tooltipData: {
      current: {
        value: {
          value: 3656.979999999999,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 191.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-09",
    value: 4194.040000000001,
    tooltipData: {
      current: {
        value: {
          value: 4194.040000000001,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 268.2699999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-10",
    value: 4508.819999999998,
    tooltipData: {
      current: {
        value: {
          value: 4508.819999999998,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 253.37,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-11",
    value: 5936.259999999992,
    tooltipData: {
      current: {
        value: {
          value: 5936.259999999992,
          format: "Currency",
        },
        Customers: {
          value: 128,
          format: "Number",
        },
        Transactions: {
          value: 232,
          format: "Number",
        },
        Returns: {
          value: 53.35,
          format: "Currency",
        },
        Discounts: {
          value: 320.2899999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-12",
    value: 5720.279999999994,
    tooltipData: {
      current: {
        value: {
          value: 5720.279999999994,
          format: "Currency",
        },
        Customers: {
          value: 167,
          format: "Number",
        },
        Transactions: {
          value: 268,
          format: "Number",
        },
        Returns: {
          value: 8.129999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 278.32,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-13",
    value: 2223.98,
    tooltipData: {
      current: {
        value: {
          value: 2223.98,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 9.04,
          format: "Currency",
        },
        Discounts: {
          value: 123.49,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-14",
    value: 3178.479999999999,
    tooltipData: {
      current: {
        value: {
          value: 3178.479999999999,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 10.55,
          format: "Currency",
        },
        Discounts: {
          value: 215.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-15",
    value: 4102.179999999999,
    tooltipData: {
      current: {
        value: {
          value: 4102.179999999999,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 379.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-16",
    value: 3754.6,
    tooltipData: {
      current: {
        value: {
          value: 3754.6,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 248.9399999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-17",
    value: 3367.499999999998,
    tooltipData: {
      current: {
        value: {
          value: 3367.499999999998,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 41.92,
          format: "Currency",
        },
        Discounts: {
          value: 225.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-18",
    value: 3937.879999999999,
    tooltipData: {
      current: {
        value: {
          value: 3937.879999999999,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 7.15,
          format: "Currency",
        },
        Discounts: {
          value: 211.9,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-19",
    value: 4000.159999999998,
    tooltipData: {
      current: {
        value: {
          value: 4000.159999999998,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 23.4,
          format: "Currency",
        },
        Discounts: {
          value: 190.6300000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-20",
    value: 3118.199999999999,
    tooltipData: {
      current: {
        value: {
          value: 3118.199999999999,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 148.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-21",
    value: 3781.479999999999,
    tooltipData: {
      current: {
        value: {
          value: 3781.479999999999,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 270,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-22",
    value: 5076.360000000002,
    tooltipData: {
      current: {
        value: {
          value: 5076.360000000002,
          format: "Currency",
        },
        Customers: {
          value: 140,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 361.5799999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-23",
    value: 4688.46,
    tooltipData: {
      current: {
        value: {
          value: 4688.46,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 326.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-24",
    value: 3162.500000000001,
    tooltipData: {
      current: {
        value: {
          value: 3162.500000000001,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 14.5,
          format: "Currency",
        },
        Discounts: {
          value: 238.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-25",
    value: 3397.109999999997,
    tooltipData: {
      current: {
        value: {
          value: 3397.109999999997,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 8.59,
          format: "Currency",
        },
        Discounts: {
          value: 267.82,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-26",
    value: 3854.659999999999,
    tooltipData: {
      current: {
        value: {
          value: 3854.659999999999,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 6.99,
          format: "Currency",
        },
        Discounts: {
          value: 232.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-27",
    value: 2444.84,
    tooltipData: {
      current: {
        value: {
          value: 2444.84,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 109.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-28",
    value: 3639.68,
    tooltipData: {
      current: {
        value: {
          value: 3639.68,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 280.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-29",
    value: 3602.52,
    tooltipData: {
      current: {
        value: {
          value: 3602.52,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 265.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-30",
    value: 3773.599999999998,
    tooltipData: {
      current: {
        value: {
          value: 3773.599999999998,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 52.07000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 294.0099999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-01",
    value: 4205.669999999999,
    tooltipData: {
      current: {
        value: {
          value: 4205.669999999999,
          format: "Currency",
        },
        Customers: {
          value: 104,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 5.66,
          format: "Currency",
        },
        Discounts: {
          value: 268.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-02",
    value: 4110.829999999997,
    tooltipData: {
      current: {
        value: {
          value: 4110.829999999997,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 205.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-03",
    value: 5064.579999999998,
    tooltipData: {
      current: {
        value: {
          value: 5064.579999999998,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 362.1499999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-04",
    value: 2842.489999999998,
    tooltipData: {
      current: {
        value: {
          value: 2842.489999999998,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 5.94,
          format: "Currency",
        },
        Discounts: {
          value: 137.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-05",
    value: 3571.88,
    tooltipData: {
      current: {
        value: {
          value: 3571.88,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 4.2,
          format: "Currency",
        },
        Discounts: {
          value: 230.48,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-06",
    value: 3461.79,
    tooltipData: {
      current: {
        value: {
          value: 3461.79,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 5.33,
          format: "Currency",
        },
        Discounts: {
          value: 250.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-07",
    value: 4319.510000000002,
    tooltipData: {
      current: {
        value: {
          value: 4319.510000000002,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 412.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-08",
    value: 4889.469999999999,
    tooltipData: {
      current: {
        value: {
          value: 4889.469999999999,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 10.27,
          format: "Currency",
        },
        Discounts: {
          value: 439.4200000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-09",
    value: 4857.87,
    tooltipData: {
      current: {
        value: {
          value: 4857.87,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 2.05,
          format: "Currency",
        },
        Discounts: {
          value: 278.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-10",
    value: 5269.499999999998,
    tooltipData: {
      current: {
        value: {
          value: 5269.499999999998,
          format: "Currency",
        },
        Customers: {
          value: 155,
          format: "Number",
        },
        Transactions: {
          value: 222,
          format: "Number",
        },
        Returns: {
          value: 7.15,
          format: "Currency",
        },
        Discounts: {
          value: 315.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-11",
    value: 2061.11,
    tooltipData: {
      current: {
        value: {
          value: 2061.11,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 113.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-12",
    value: 4071.199999999997,
    tooltipData: {
      current: {
        value: {
          value: 4071.199999999997,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 6.5,
          format: "Currency",
        },
        Discounts: {
          value: 230.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-13",
    value: 3762.18,
    tooltipData: {
      current: {
        value: {
          value: 3762.18,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 370.0799999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-14",
    value: 3087.09,
    tooltipData: {
      current: {
        value: {
          value: 3087.09,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 13.52,
          format: "Currency",
        },
        Discounts: {
          value: 222.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-15",
    value: 4146.949999999999,
    tooltipData: {
      current: {
        value: {
          value: 4146.949999999999,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 12.78,
          format: "Currency",
        },
        Discounts: {
          value: 241.0700000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-16",
    value: 4368.839999999997,
    tooltipData: {
      current: {
        value: {
          value: 4368.839999999997,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 16.43,
          format: "Currency",
        },
        Discounts: {
          value: 254.7400000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-17",
    value: 3771.569999999997,
    tooltipData: {
      current: {
        value: {
          value: 3771.569999999997,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 216.61,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-18",
    value: 2519.41,
    tooltipData: {
      current: {
        value: {
          value: 2519.41,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 135.88,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-19",
    value: 4566.899999999998,
    tooltipData: {
      current: {
        value: {
          value: 4566.899999999998,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 10.6,
          format: "Currency",
        },
        Discounts: {
          value: 424.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-20",
    value: 2635.369999999999,
    tooltipData: {
      current: {
        value: {
          value: 2635.369999999999,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 147.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-21",
    value: 4653.339999999998,
    tooltipData: {
      current: {
        value: {
          value: 4653.339999999998,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 21.01,
          format: "Currency",
        },
        Discounts: {
          value: 383.9,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-22",
    value: 3652.749999999997,
    tooltipData: {
      current: {
        value: {
          value: 3652.749999999997,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 5.99,
          format: "Currency",
        },
        Discounts: {
          value: 270.3100000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-23",
    value: 4618.109999999997,
    tooltipData: {
      current: {
        value: {
          value: 4618.109999999997,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 263.4099999999999,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-24",
    value: 6150.459999999994,
    tooltipData: {
      current: {
        value: {
          value: 6150.459999999994,
          format: "Currency",
        },
        Customers: {
          value: 160,
          format: "Number",
        },
        Transactions: {
          value: 220,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 365.67,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-25",
    value: 2028.710000000001,
    tooltipData: {
      current: {
        value: {
          value: 2028.710000000001,
          format: "Currency",
        },
        Customers: {
          value: 52,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 100.37,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-26",
    value: 3248.469999999998,
    tooltipData: {
      current: {
        value: {
          value: 3248.469999999998,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 196.4600000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-27",
    value: 6052.729999999996,
    tooltipData: {
      current: {
        value: {
          value: 6052.729999999996,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 604.5700000000004,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-28",
    value: 3760.27,
    tooltipData: {
      current: {
        value: {
          value: 3760.27,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 4,
          format: "Currency",
        },
        Discounts: {
          value: 258.0899999999998,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-29",
    value: 3711.109999999999,
    tooltipData: {
      current: {
        value: {
          value: 3711.109999999999,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 13.3,
          format: "Currency",
        },
        Discounts: {
          value: 222.7800000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-30",
    value: 6432.989999999999,
    tooltipData: {
      current: {
        value: {
          value: 6432.989999999999,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 194,
          format: "Number",
        },
        Returns: {
          value: 44.66,
          format: "Currency",
        },
        Discounts: {
          value: 557.8300000000002,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-31",
    value: 4816.520000000002,
    tooltipData: {
      current: {
        value: {
          value: 4816.520000000002,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 37.08,
          format: "Currency",
        },
        Discounts: {
          value: 265.4599999999999,
          format: "Currency",
        },
      },
    },
  },
]
