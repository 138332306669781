import { useTheme } from "../../../contexts/theme"
import { ResponsiveBump } from "@nivo/bump"
import CustomTooltip from "../../CustomTooltip"
import Box from "@mui/material/Box"
import CustomLegend from "../../CustomLegend"

const NivoBumpChart = ({
  chartInput,
  parameters,
  reportTitle,
  reportData,
  showAiDropdown,
}) => {
  const { theme } = useTheme()
  const {
    inactiveLineWidth,
    margin,
    inactiveOpacity,
    pointSize,
    axisTop,
    activePointSize,
    axisBottom,
    axisLeft,
    inactivePointSize,
    pointColor,
    pointBorderColor,
    activePointBorderWidth,
    pointBorderWidth,
    activeLineWidth,
    lineWidth,
    colors,
  } = parameters
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }
  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      <Box height="350px">
        <ResponsiveBump
          data={chartInput}
          theme={customTheme}
          colors={colors}
          lineWidth={lineWidth}
          activeLineWidth={activeLineWidth}
          inactiveLineWidth={inactiveLineWidth}
          inactiveOpacity={inactiveOpacity}
          pointSize={pointSize}
          activePointSize={activePointSize}
          inactivePointSize={inactivePointSize}
          pointColor={pointColor}
          pointBorderWidth={pointBorderWidth}
          activePointBorderWidth={activePointBorderWidth}
          pointBorderColor={pointBorderColor}
          axisTop={axisTop}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          margin={margin}
          axisRight={null}
          useMesh={true}
          pointTooltip={(point) => (
            <CustomTooltip data={point.point.data} showCurrentPrevDate={true} />
          )}
        />
      </Box>
      <CustomLegend
        legendsData={parameters.legendsData || []}
        chartType="Bump"
        reportTitle={reportTitle}
        reportData={reportData}
        showAiDropdown={showAiDropdown}
      />
    </Box>
  )
}

export default NivoBumpChart
