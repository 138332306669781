import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import UploadIcon from "@mui/icons-material/Upload"
import { useTheme } from "../contexts/theme"

const FilePicker = () => {
  const { theme } = useTheme()
  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileChange = (acceptedFiles) => {
    const file = acceptedFiles[0]
    setSelectedFile(file)
  }

  const onDrop = useCallback((acceptedFiles) => {
    handleFileChange(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: "image/*",
  })

  return (
    <>
      <Typography sx={{ fontSize: "0.8rem", pb: "0.2rem" }}>
        Image/Banner
        <span style={{ color: "red" }}> *</span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "22rem",
          justifyContent: "flex-end",
          backgroundColor:
            theme.palette.mode === "dark" ? "rgba(13, 34, 102, 1)" : "white",
          border: "2px dashed #6382E9",
          borderRadius: "12px",
          padding: "10px",
          alignItems: "center",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // background: isDragActive ? "#f0f0f0" : "inherit",
            padding: isDragActive ? "10px" : "0",
            borderRadius: "8px",
          }}
        >
          {isDragActive ? (
            <Typography sx={{ color: "red" }}>
              Drop the files here ...
            </Typography>
          ) : (
            <Button
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                padding: "10px 20px",
                color: "#FF7935",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#FF7935",
                },
              }}
            >
              <UploadIcon sx={{ marginRight: "8px" }} />
              Choose File
            </Button>
          )}
        </Box>
      </Box>
      <Typography sx={{ fontSize: "0.8rem", py: "0.1rem" }}>
        Up to 25 mb
      </Typography>
      {selectedFile && (
        <Typography sx={{ fontSize: "0.8rem", pt: "0.2rem" }}>
          Selected file: {selectedFile.name}
        </Typography>
      )}
    </>
  )
}

export default FilePicker
