import { useEffect, useState } from "react"
import InfoToolTip from "../../InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import { useDispatch, useSelector } from "react-redux"
import { postData } from "../../../api/api"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import NivoSwarmPlotChart from "./NivoSwarmPlotChart"
import { SwarmPlotData, SwarmPlotParameters } from "./SwarmPlotData"
import { ResponsiveAreaBump } from "@nivo/bump"

const SwarmPlot = ({
  route,
  setChartData,
  title,
  description,
  postUrl,
  comparisonPeriod,
  reportUid,
  parameters,
  dateRangeData = { fromDate: "", toDate: "" },
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const dispatch = useDispatch()
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const tooltip_data = extractedParameters.tooltip_data || [
    { name: "Gross Sales", format: "Currency", color: "" },
    { name: "Customers", format: "Number", color: "" },
    { name: "Transactions", format: "Number", color: "" },
    { name: "Average Ticket", format: "Currency", color: "" },
  ]

  const indexBy = extractedParameters.indexBy || "id"
  const keys = extractedParameters.keys || "Gross Sales"

  const replaceEmployeeWithGroup = (data) => {
    const dataSource = Array.isArray(data?.reportDataCurrent)
      ? data.reportDataCurrent
      : data
    return dataSource.map((item, index) => {
      const { Employee, ...rest } = item // Extract Employee and the rest of the properties

      // Initialize current and previous value objects
      const current = {}
      const previous = {}

      // Iterate over keys in rest to dynamically build current and previous data
      for (const key in rest) {
        if (key.startsWith("previous")) {
          // Extract previous values
          const newKey = key.replace("previous", "").toLowerCase() // Transform key for tooltip structure
          previous[newKey] = {
            value: rest[key],
            format: newKey === "price" ? "Currency" : "Number", // Conditional formatting based on key
          }
        } else {
          // Extract current values
          current[key.toLowerCase()] = {
            value: rest[key],
            format: key === "price" ? "Currency" : "Number", // Conditional formatting based on key
          }
        }
      }

      return {
        id: `0.${index}`, // Generate dynamic ID based on the index
        group: Employee, // Replace Employee with group
        tooltipData: {
          current,
          //   previous,
        },
        ...rest, // Include any other fields that might exist in the original item
      }
    })
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = reportUid
        ? await postData(postUrl, {
            entityDb: localStorage.getItem("entityDb"),
            fromDate: combinedState.dateRangeData.fromDate,
            toDate: combinedState.dateRangeData.toDate,
            reportInstanceId: reportUid,
            comparedBy: {
              previousFromDate: combinedState.comparisonPeriod.previousFromDate,
              previousToDate: combinedState.comparisonPeriod.previousToDate,
            },
          })
        : SwarmPlotData
      const updatedData = replaceEmployeeWithGroup(response)
      setApiResponse(updatedData)
      dispatch(
        setChartData({
          reportUid,
          response: replaceEmployeeWithGroup(response),
        })
      )
    } catch (error) {
      setError("Inadequate data points to generate a report")
      setApiResponse(SwarmPlotData)
      dispatch(
        setChartData({
          reportUid,
          SwarmPlotData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const chartInput = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    return selectedReport?.reportData
  })

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box display="flex" gap={"2px"}>
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={extractedParameters.view_by_period}
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          {extractedParameters.focus_mode && focus_mode && (
            <Analytica
              description={description}
              component="modal"
              chartDetails={{
                route,
                setChartData,
                title,
                description,
                comparisonPeriod,
                postUrl,
                reportUid,
                parameters,
                dateRangeData,
                apiResponse,
              }}
            />
          )}
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="350px"
          width="100%"
          gap="10px"
        >
          <CircularProgress size={45} color="info" />
          <Typography variant="body2" color="inherit">
            Fetching data, please wait...
          </Typography>
        </Box>
      ) : error ? (
        <NivoSwarmPlotChart
          chartConfig={SwarmPlotParameters}
          chartInput={SwarmPlotData}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
        />
      ) : (
        <NivoSwarmPlotChart
          chartConfig={SwarmPlotParameters}
          chartInput={chartInput}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
        />
      )}
    </>
  )
}

export default SwarmPlot
