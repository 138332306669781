import { useState } from "react"
import Toolbar from "@mui/material/Toolbar"
import Box from "@mui/material/Box"
import { useTheme } from "../../../contexts/theme"
import Left from "./left/Left"
import Right from "./right/Right"
import Container from "@mui/material/Container"

const Secondary = () => {
  const { theme } = useTheme()
  const [activeButton, setActiveButton] = useState(null)
  const [secondaryNavEnabled, setSecondaryNavEnabled] = useState(
    localStorage.getItem("dataStatus")?.trim() === "Ready"
  )

  const handleSetActiveButton = (buttonName) => {
    setActiveButton(buttonName)
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor:
            theme.palette.mode === "light" ? "#E9EDFC" : "#030C27",
        }}
        position="relative"
      >
        {!secondaryNavEnabled && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background:
                theme.palette.mode === "dark" ? "#00000085" : "#FFFFFFA0",
              position: "absolute",
              zIndex: 1,
              top: "0px",
              left: "0px",
            }}
          />
        )}

        <Container maxWidth="xl">
          <Toolbar
            sx={{
              justifyContent: "space-between",
              flexWrap: "wrap",
              minHeight: "unset !important",
            }}
          >
            <Box display="flex" columnGap="1rem" flexWrap="wrap">
              <Left
                activeButton={activeButton}
                setActiveButton={handleSetActiveButton}
              />
            </Box>
            <Box display="flex" columnGap="1rem" flexWrap="wrap">
              <Right
                activeButton={activeButton}
                setActiveButton={handleSetActiveButton}
              />
            </Box>
          </Toolbar>
        </Container>
      </Box>
    </>
  )
}

export default Secondary
