// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.dashboard_grid_container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

/* X-Small (<400px) */
@media (max-width: 400px) {
  .dashboard_grid_item_1,
  .dashboard_grid_item_2,
  .dashboard_grid_item_3,
  .dashboard_grid_item_4,
  .dashboard_grid_item_5,
  .dashboard_grid_item_6,
  .dashboard_grid_item_7,
  .dashboard_grid_item_8,
  .dashboard_grid_item_9,
  .dashboard_grid_item_other {
    grid-column: span 6;
  }
}
/* X-Small (<576px) */
@media (max-width: 576px) {
  .dashboard_grid_item_1,
  .dashboard_grid_item_2,
  .dashboard_grid_item_3,
  .dashboard_grid_item_4,
  .dashboard_grid_item_5,
  .dashboard_grid_item_6,
  .dashboard_grid_item_7,
  .dashboard_grid_item_8,
  .dashboard_grid_item_9,
  .dashboard_grid_item_other {
    grid-column: span 3;
  }
}
/* X-Small (≥576px) */
@media (min-width: 576px) {
  .dashboard_grid_item_1,
  .dashboard_grid_item_2,
  .dashboard_grid_item_3,
  .dashboard_grid_item_4,
  .dashboard_grid_item_5,
  .dashboard_grid_item_6,
  .dashboard_grid_item_7,
  .dashboard_grid_item_8,
  .dashboard_grid_item_9,
  .dashboard_grid_item_other {
    grid-column: span 3;
  }
}
/* Small (≥768px) */
@media (min-width: 768px) {
  .dashboard_grid_item_1,
  .dashboard_grid_item_2,
  .dashboard_grid_item_3,
  .dashboard_grid_item_4,
  .dashboard_grid_item_5,
  .dashboard_grid_item_6,
  .dashboard_grid_item_7,
  .dashboard_grid_item_8,
  .dashboard_grid_item_9,
  .dashboard_grid_item_other {
    grid-column: span 2;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/overview/styles.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AAEF;;AACA,qBAAA;AACA;EACE;;;;;;;;;;IAUE,mBAAA;EAEF;AACF;AACA,qBAAA;AACA;EACE;;;;;;;;;;IAUE,mBAAA;EACF;AACF;AAEA,qBAAA;AACA;EACE;;;;;;;;;;IAUE,mBAAA;EAAF;AACF;AAGA,mBAAA;AACA;EACE;;;;;;;;;;IAUE,mBAAA;EADF;AACF","sourcesContent":[".dashboard_grid_container {\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n  gap: 20px;\n}\n\n/* X-Small (<400px) */\n@media (max-width: 400px) {\n  .dashboard_grid_item_1,\n  .dashboard_grid_item_2,\n  .dashboard_grid_item_3,\n  .dashboard_grid_item_4,\n  .dashboard_grid_item_5,\n  .dashboard_grid_item_6,\n  .dashboard_grid_item_7,\n  .dashboard_grid_item_8,\n  .dashboard_grid_item_9,\n  .dashboard_grid_item_other {\n    grid-column: span 6;\n  }\n}\n\n/* X-Small (<576px) */\n@media (max-width: 576px) {\n  .dashboard_grid_item_1,\n  .dashboard_grid_item_2,\n  .dashboard_grid_item_3,\n  .dashboard_grid_item_4,\n  .dashboard_grid_item_5,\n  .dashboard_grid_item_6,\n  .dashboard_grid_item_7,\n  .dashboard_grid_item_8,\n  .dashboard_grid_item_9,\n  .dashboard_grid_item_other {\n    grid-column: span 3;\n  }\n}\n\n/* X-Small (≥576px) */\n@media (min-width: 576px) {\n  .dashboard_grid_item_1,\n  .dashboard_grid_item_2,\n  .dashboard_grid_item_3,\n  .dashboard_grid_item_4,\n  .dashboard_grid_item_5,\n  .dashboard_grid_item_6,\n  .dashboard_grid_item_7,\n  .dashboard_grid_item_8,\n  .dashboard_grid_item_9,\n  .dashboard_grid_item_other {\n    grid-column: span 3;\n  }\n}\n\n/* Small (≥768px) */\n@media (min-width: 768px) {\n  .dashboard_grid_item_1,\n  .dashboard_grid_item_2,\n  .dashboard_grid_item_3,\n  .dashboard_grid_item_4,\n  .dashboard_grid_item_5,\n  .dashboard_grid_item_6,\n  .dashboard_grid_item_7,\n  .dashboard_grid_item_8,\n  .dashboard_grid_item_9,\n  .dashboard_grid_item_other {\n    grid-column: span 2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
