export const CustomerBarChartParameters = [
  {
    parameterType: "Base",
    reportParameters: [
      {
        parameterName: "data",
        parameterValue: "{data}",
      },
      {
        parameterName: "margin",
        parameterValue: "{ top: 30, right: 10, bottom: 50, left: 70 }",
      },
    ],
  },
  {
    parameterType: "Style",
    reportParameters: [
      {
        parameterName: "colors",
        parameterValue: "rgb(0, 108, 255)",
      },
      {
        parameterName: "borderColor",
        parameterValue: '{ from: "color", modifiers: [["darker", 1.6]] }',
      },
    ],
  },
  {
    parameterType: "LABELS",
    reportParameters: [
      {
        parameterName: "meta_info",
        parameterValue: '{"format":"Currency"}',
      },
    ],
  },
  {
    parameterType: "Base",
    reportParameters: [
      {
        parameterName: "indexBy",
        parameterValue: "id",
      },
      {
        parameterName: "keys",
        parameterValue: '["Gross Sales"]',
      },
    ],
  },
  {
    parameterType: "Style",
    reportParameters: [
      {
        parameterName: "padding",
        parameterValue: "0.5",
      },
      {
        parameterName: "borderRadius",
        parameterValue: "10",
      },
    ],
  },
  {
    parameterType: "GRID & AXES",
    reportParameters: [
      {
        parameterName: "axisTop",
        parameterValue: "null",
      },
      {
        parameterName: "axisRight",
        parameterValue: "null",
      },
      {
        parameterName: "axisBottom",
        parameterValue:
          '{ tickSize: 5, tickPadding: 5, tickRotation: -42, legendPosition: "middle", legendOffset: 32, }',
      },
      {
        parameterName: "axisLeft",
        parameterValue:
          '{ tickSize: 5, tickPadding: 5, tickRotation: 0, legendPosition: "middle", legendOffset: -40, }',
      },
    ],
  },
  {
    parameterType: "Style",
    reportParameters: [
      {
        parameterName: "labelTextColor",
        parameterValue: '{ from: "color", modifiers: [["darker", 1.6]] }',
      },
    ],
  },
  {
    parameterType: "Base",
    reportParameters: [
      {
        parameterName: "isInteractive",
        parameterValue: "false",
      },
      {
        parameterName: "layout",
        parameterValue: "vertical",
      },
      {
        parameterName: "enableLabel",
        parameterValue: "false",
      },
    ],
  },
  {
    parameterType: "CUSTOMIZATION",
    reportParameters: [
      {
        parameterName: "tooltip_fields",
        parameterValue:
          "'Customers','Transactions','Average Ticket','Return Amount','Discount Amount'",
      },
    ],
  },
]
