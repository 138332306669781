import { useRef, useState } from "react"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { signUpDetailSchema } from "./Validation"
import { useFormik } from "formik"
import MenuItem from "@mui/material/MenuItem"
import Logo from "../../assets/svgs/Logo"
import VerificationCode from "./VerificationCode"
import ReCAPTCHA from "react-google-recaptcha"

const roles = [
  {
    value: "USD",
    label: "FrontEnd developer",
  },
  {
    value: "EUR",
    label: "BackEnd Developer",
  },
  {
    value: "BTC",
    label: "Pega Developer",
  },
  {
    value: "JPY",
    label: "Data Scientist",
  },
]

const Registration = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState(false)
  const recaptcha = useRef()

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      company: "",
      companyadd: "",
      role: "",
      industry: "",
    },
    validationSchema: signUpDetailSchema,
    onSubmit: async (values) => {
      try {
        await signUpDetailSchema.validate(values, { abortEarly: false })
        // updateUser(values)
        setStep(true)
        navigate("/verificationCode")
      } catch (error) {
        console.error("Form validation failed. Do not navigate.", error)
      }
    },
  })

  const handleFormData = async (event) => {
    event.preventDefault()
    formik.setTouched({
      firstName: true,
      lastName: true,
      mobileNumber: true,
      email: true,
      company: true,
      companyadd: true,
      role: true,
      industry: true,
    })
    await formik.validateForm()
    if (!formik.isValid) {
      return
    }
    setStep(false)
    navigate("/verificationCode")
    // const captchaValue = recaptcha.current.getValue()
    // setStep(true);
  }

  return (
    <Stack display="flex" gap={2} alignItems="center">
      <Typography variant="h5" color="inherit" textAlign="center">
        Welcome to
      </Typography>
      <Logo fill="black" yfill="#1D47CE" />
      <Typography variant="h4" color="inherit" textAlign="center">
        Sign Up
      </Typography>

      {!step && (
        <>
          <div className="auth-input">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "35ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="First Name"
                    variant="standard"
                    fullWidth
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Last Name"
                    variant="standard"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "35ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Mobile Number"
                    id="standard-size-large"
                    variant="standard"
                    name="mobileNumber"
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mobileNumber && formik.errors.mobileNumber
                    }
                    helperText={
                      formik.touched.mobileNumber && formik.errors.mobileNumber
                    }
                    readOnly={formik.values.isMobileNumberVerified}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Email Address"
                    id="standard-size-normal"
                    variant="standard"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    readOnly={formik.values.isEmailVerified}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "35ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="company"
                    variant="standard"
                    name="company"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.company && Boolean(formik.errors.company)
                    }
                    helperText={formik.touched.company && formik.errors.company}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="company Address"
                    variant="standard"
                    fullWidth
                    name="companyadd"
                    value={formik.values.companyadd}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyadd &&
                      Boolean(formik.errors.companyadd)
                    }
                    helperText={
                      formik.touched.companyadd && formik.errors.companyadd
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "35ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Role"
                    variant="standard"
                    fullWidth
                    name="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="outlined-select-currency"
                    select
                    defaultValue="EUR"
                    variant="standard"
                    label="Industry"
                    fullWidth
                    name="industry"
                    value={formik.values.industry}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.industry && Boolean(formik.errors.industry)
                    }
                    helperText={
                      formik.touched.industry && formik.errors.industry
                    }
                  >
                    {roles.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </div>
          <Grid sx={{ ml: 22, mt: 2 }}>
            <ReCAPTCHA
              ref={recaptcha}
              size="invisible"
              sitekey={process.env.REACT_APP_SITE_KEY}
            />
          </Grid>
          <Box>
            <Button
              sx={{
                mt: "16px",
                backgroundColor: "#2642a8",
                padding: "10px 19px",
                borderRadius: 10,
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2642a8",
                },
              }}
              onClick={handleFormData}
            >
              Send Verification Code
            </Button>
          </Box>
          <Stack display="flex" alignItems="center">
            <Typography variant="body1" color="inherit" textAlign="center">
              Or
            </Typography>
            <Typography variant="body1" color="inherit" textAlign="center">
              Have an account?
            </Typography>
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                color: "#2642a8",
                padding: "10px 19px",
                borderRadius: 10,
                "&:hover": {
                  backgroundColor: "#2642a810",
                },
              }}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
            {/* <Button
              sx={{
                mt: "16px",
                backgroundColor: "#2642a8",
                padding: "10px 19px",
                borderRadius: 10,
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2642a8",
                },
              }}
              onClick={() => navigate("/login")}
            >
              Login
            </Button> */}
          </Stack>
        </>
      )}
      {step && <VerificationCode />}
    </Stack>
  )
}

export default Registration
