import Drawer from "@mui/material/Drawer"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import Logo from "../../assets/svgs/Logo"
import { useTheme } from "../../contexts/theme"

const DrawerComponent = ({ open, onClose }) => {
  const { theme } = useTheme()

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          padding: "1.2rem",
          borderRadius: "10px 0px 0px 10px",
          backgroundColor:
            theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
          backgroundImage: "unset",
          width: "450px",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" color="inherit" paddingY="1.2rem">
          Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Typography variant="h6" color="inherit">
          coming soon...
        </Typography>
        <Logo
          fill={theme.palette.text.primary}
          yfill={
            theme.palette.mode === "light" ? theme.palette.accent : "white"
          }
        />
      </Box>
    </Drawer>
  )
}

export default DrawerComponent
