import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme } from "../../../contexts/theme"

const CustomLegend = ({ legendsData = [] }) => {
  const { theme } = useTheme()

  const itemTextColor =
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.70)" : "#333333"
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      rowGap={1}
      columnGap={2}
      flexWrap="wrap"
    >
      {legendsData.map((legend, index) => (
        <Box key={index} display="flex" alignItems="center" gap={1}>
          <Box
            sx={{
              width: "14px",
              height: "6px",
              backgroundColor: legend.color || "grey",
              borderRadius: "2px",
            }}
          />
          <Typography
            variant="subtitle2"
            color={itemTextColor}
            sx={{ fontSize: "14px" }}
          >
            {legend.label || "N/A"}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default CustomLegend
