import { ResponsivePie } from "@nivo/pie"
import { useTheme } from "../../../../contexts/theme"

const LeavesAvailablePieChart = () => {
  const data = [
    {
      id: "1",
      label: "Total",
      value: "10",
    },
    {
      id: "2",
      label: 12,
      value: "2",
    },
  ]
  const { theme } = useTheme()

  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const colorScheme = ["rgba(80, 205, 137, 1)", "red"]
  const customTheme = {
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
  }
  return (
    <div
      style={{
        height: "230px",
        width: "225px",
      }}
    >
      <ResponsivePie
        data={data}
        theme={customTheme}
        margin={{
          top: 40,
          right: 0,
          bottom: 40,
          left: 0,
        }}
        startAngle={-5}
        innerRadius={0.7}
        padAngle={0}
        cornerRadius={2}
        activeInnerRadiusOffset={6}
        activeOuterRadiusOffset={6}
        colors={colorScheme}
        borderWidth={2}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        animate={true}
        legends={[
          {
            anchor: "center",
            direction: "row",
            justify: false,
            translateX: 5,
            translateY: 0,
            itemWidth: 30,
            itemHeight: 30,
            itemsSpacing: 0,
            symbolSize: 0,
            itemTextColor,
            itemDirection: "left-to-right",
          },
        ]}
      />
    </div>
  )
}

export default LeavesAvailablePieChart
