import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import ArrowRise from "../../assets/svgs/ArrowRise"
import ArrowFall from "../../assets/svgs/ArrowFall"

const SimpleTable = ({ data, columnStyles = {}, rowStyles = {} }) => {
  const theme = useTheme()
  const headerColor = theme.palette.mode === "dark" ? "#acacac" : "#747474"

  const columns = data.length > 0 ? Object.keys(data[0]) : []

  return (
    <TableContainer>
      <Table sx={{ borderCollapse: "collapse" }} aria-label="dynamic table">
        <TableHead>
          <TableRow>
            {columns.map((key, index) => (
              <TableCell
                key={index}
                sx={{
                  borderBottom: "none",
                  padding: "0.6rem 0.7rem",
                  ...columnStyles,
                }}
              >
                <Typography
                  fontSize="0.78rem"
                  color={headerColor}
                  fontWeight="fontWeightMedium"
                >
                  {key}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                ...rowStyles,
              }}
            >
              {columns.map((key, colIndex) => (
                <TableCell
                  key={colIndex}
                  align="left"
                  sx={{ border: "none", padding: "0.6rem 0.7rem" }}
                >
                  <Typography
                    fontWeight="fontWeightSemiBold"
                    fontSize="0.85rem"
                    color={
                      typeof row[key] === "object" && !Array.isArray(row[key])
                        ? row[key].value[0] === "+"
                          ? "#23BF6F"
                          : row[key].value[0] === "-"
                          ? "#EC2D30"
                          : null
                        : "inherit"
                    }
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    {typeof row[key] === "string" ? (
                      <>
                        {row[key]}
                        {row[key][0] === "+" ? (
                          <ArrowRise />
                        ) : row[key][0] === "-" ? (
                          <ArrowFall />
                        ) : null}
                      </>
                    ) : typeof row[key] === "object" &&
                      !Array.isArray(row[key]) ? (
                      <>
                        {row[key].value}
                        {row[key].value[0] === "+" ? (
                          <svg
                            width="16"
                            height="16"
                            viewBox="-2 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6673 10L8.00065 6L3.33398 10"
                              stroke="#50CD89"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        ) : row[key].value[0] === "-" ? (
                          <svg
                            width="16"
                            height="16"
                            viewBox="-2 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.6673 6L8.00065 10L3.33398 6"
                              stroke="#EE000C"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        ) : null}
                      </>
                    ) : (
                      row[key]
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SimpleTable
