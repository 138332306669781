import { useState } from "react";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import { useTheme } from "../../contexts/theme";

const TransitionAlerts = ({
  message,
  btnName,
  isOpen,
  additionalStyle = {},
}) => {
  const [open, setOpen] = useState(true);
  const { theme } = useTheme();
  const color = theme === "light" ? "#1D47CE" : "#FF7935";

  return (
    <Collapse in={open}>
      <Alert
        severity="error"
        onClose={() => setOpen(false)}
        sx={{
          borderLeft: "4px solid #EE000CCC",
          borderRadius: "10px",
          ...additionalStyle,
        }}
        iconMapping={{
          error: (
            <svg
              width="22"
              height="21"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M7.08163 14.3487C10.9728 7.44955 12.9183 4 15.9993 4C19.0803 4 21.0259 7.44955 24.9171 14.3487L25.4019 15.2084C28.6355 20.9415 30.2523 23.808 28.7909 25.904C27.3297 28 23.7145 28 16.4843 28H15.5144C8.2841 28 4.66891 28 3.2077 25.904C1.74647 23.808 3.36323 20.9415 6.59675 15.2084L7.08163 14.3487Z"
                stroke="#EE000C"
                strokeWidth="2.5"
              />
              <path
                d="M16 10.668V17.3346"
                stroke="#EE000C"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M15.9993 22.6667C16.7357 22.6667 17.3327 22.0697 17.3327 21.3333C17.3327 20.597 16.7357 20 15.9993 20C15.263 20 14.666 20.597 14.666 21.3333C14.666 22.0697 15.263 22.6667 15.9993 22.6667Z"
                fill="#EE000C"
              />
            </svg>
          ),
        }}
      >
        {message}
        <Link
          onClick={() => isOpen()}
          underline="hover"
          sx={{
            color,
            marginLeft: "10px",
            cursor: "pointer",
          }}
        >
          <Typography variant="body2" color={color} display="inline">
            {btnName}
          </Typography>
        </Link>
      </Alert>
    </Collapse>
  );
};

export default TransitionAlerts;
