import { useTheme } from "../../contexts/theme"

const Qricon = () => {
    const { theme } = useTheme()
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3335 3.33203V5.33203H3.3335V3.33203H5.3335ZM6.3335 2.33203H2.3335V6.33203H6.3335V2.33203ZM5.3335 8.66537V10.6654H3.3335V8.66537H5.3335ZM6.3335 7.66536H2.3335V11.6654H6.3335V7.66536ZM10.6668 3.33203V5.33203H8.66683V3.33203H10.6668ZM11.6668 2.33203H7.66683V6.33203H11.6668V2.33203ZM7.66683 7.66536H8.66683V8.66537H7.66683V7.66536ZM8.66683 8.66537H9.66683V9.66537H8.66683V8.66537ZM9.66683 7.66536H10.6668V8.66537H9.66683V7.66536ZM7.66683 9.66537H8.66683V10.6654H7.66683V9.66537ZM8.66683 10.6654H9.66683V11.6654H8.66683V10.6654ZM9.66683 9.66537H10.6668V10.6654H9.66683V9.66537ZM10.6668 8.66537H11.6668V9.66537H10.6668V8.66537ZM10.6668 10.6654H11.6668V11.6654H10.6668V10.6654ZM13.6668 3.66536H12.3335V1.66536H10.3335V0.332031H13.6668V3.66536ZM13.6668 13.6654V10.332H12.3335V12.332H10.3335V13.6654H13.6668ZM0.333496 13.6654H3.66683V12.332H1.66683V10.332H0.333496V13.6654ZM0.333496 0.332031V3.66536H1.66683V1.66536H3.66683V0.332031H0.333496Z"
        fill={theme.palette.accent}
      />
    </svg>
  )
}

export default Qricon
