
const SentimentDissatisfied = () => {
  return (
    <svg
      width="79"
      height="78"
      viewBox="0 0 79 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.666 71.5C57.6151 71.5 72.166 56.9491 72.166 39C72.166 21.0507 57.6151 6.5 39.666 6.5C21.7168 6.5 7.16602 21.0507 7.16602 39C7.16602 56.9491 21.7168 71.5 39.666 71.5Z"
        fill="#EE000C"
      />
      <path
        opacity="0.5"
        d="M27.9579 56.7013C28.7596 57.7829 30.2862 58.0097 31.3677 57.2079C33.7349 55.4536 36.592 54.4373 39.6662 54.4373C42.7403 54.4373 45.5974 55.4536 47.9647 57.2079C49.0463 58.0097 50.5728 57.7829 51.3743 56.7013C52.1761 55.6197 51.9492 54.0931 50.8676 53.2917C47.7073 50.9491 43.8424 49.5623 39.6662 49.5623C35.4899 49.5623 31.625 50.9491 28.4647 53.2917C27.3832 54.0931 27.1563 55.6197 27.9579 56.7013Z"
        fill="#030816"
      />
      <path
        opacity="0.5"
        d="M49.416 39C51.211 39 52.666 36.8173 52.666 34.125C52.666 31.4326 51.211 29.25 49.416 29.25C47.621 29.25 46.166 31.4326 46.166 34.125C46.166 36.8173 47.621 39 49.416 39Z"
        fill="#030816"
      />
      <path
        opacity="0.5"
        d="M29.916 39C31.7109 39 33.166 36.8173 33.166 34.125C33.166 31.4326 31.7109 29.25 29.916 29.25C28.1211 29.25 26.666 31.4326 26.666 34.125C26.666 36.8173 28.1211 39 29.916 39Z"
        fill="#030816"
      />
    </svg>
  )
}

export default SentimentDissatisfied
