export const TimeLineData = [
  {
    month: "Jan",
    Normal: 1300,
    Positive: 400,
    Negative: 300,
    tooltipData: {
      current: {
        TotalCustomers: { value: 2000, format: "Number" },
        PositiveRate: { value: 20, format: "Percentage" },
        NegativeRate: { value: 15, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1900, format: "Number" },
        PositiveRate: { value: 21, format: "Percentage" },
        NegativeRate: { value: 16, format: "Percentage" },
      },
    },
  },
  {
    month: "Feb",
    Normal: 800,
    Positive: 300,
    Negative: 200,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1300, format: "Number" },
        PositiveRate: { value: 23, format: "Percentage" },
        NegativeRate: { value: 15, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1200, format: "Number" },
        PositiveRate: { value: 25, format: "Percentage" },
        NegativeRate: { value: 17, format: "Percentage" },
      },
    },
  },
  {
    month: "Mar",
    Normal: 900,
    Positive: 200,
    Negative: 100,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1200, format: "Number" },
        PositiveRate: { value: 17, format: "Percentage" },
        NegativeRate: { value: 8, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1100, format: "Number" },
        PositiveRate: { value: 18, format: "Percentage" },
        NegativeRate: { value: 9, format: "Percentage" },
      },
    },
  },
  {
    month: "Apr",
    Normal: 1400,
    Positive: 350,
    Negative: 150,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1900, format: "Number" },
        PositiveRate: { value: 18, format: "Percentage" },
        NegativeRate: { value: 8, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1800, format: "Number" },
        PositiveRate: { value: 20, format: "Percentage" },
        NegativeRate: { value: 10, format: "Percentage" },
      },
    },
  },
  {
    month: "May",
    Normal: 900,
    Positive: 226,
    Negative: 122,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1248, format: "Number" },
        PositiveRate: { value: 18, format: "Percentage" },
        NegativeRate: { value: 10, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1150, format: "Number" },
        PositiveRate: { value: 20, format: "Percentage" },
        NegativeRate: { value: 11, format: "Percentage" },
      },
    },
  },
  {
    month: "Jun",
    Normal: 700,
    Positive: 228,
    Negative: 122,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1050, format: "Number" },
        PositiveRate: { value: 22, format: "Percentage" },
        NegativeRate: { value: 12, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 980, format: "Number" },
        PositiveRate: { value: 23, format: "Percentage" },
        NegativeRate: { value: 13, format: "Percentage" },
      },
    },
  },
  {
    month: "Jul",
    Normal: 850,
    Positive: 206,
    Negative: 185,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1241, format: "Number" },
        PositiveRate: { value: 17, format: "Percentage" },
        NegativeRate: { value: 15, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1150, format: "Number" },
        PositiveRate: { value: 19, format: "Percentage" },
        NegativeRate: { value: 16, format: "Percentage" },
      },
    },
  },
  {
    month: "Aug",
    Normal: 898,
    Positive: 216,
    Negative: 185,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1299, format: "Number" },
        PositiveRate: { value: 17, format: "Percentage" },
        NegativeRate: { value: 14, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1200, format: "Number" },
        PositiveRate: { value: 18, format: "Percentage" },
        NegativeRate: { value: 15, format: "Percentage" },
      },
    },
  },
  {
    month: "Sep",
    Normal: 912,
    Positive: 396,
    Negative: 245,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1553, format: "Number" },
        PositiveRate: { value: 25, format: "Percentage" },
        NegativeRate: { value: 16, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1450, format: "Number" },
        PositiveRate: { value: 26, format: "Percentage" },
        NegativeRate: { value: 17, format: "Percentage" },
      },
    },
  },
  {
    month: "Oct",
    Normal: 1612,
    Positive: 380,
    Negative: 225,
    tooltipData: {
      current: {
        TotalCustomers: { value: 2217, format: "Number" },
        PositiveRate: { value: 17, format: "Percentage" },
        NegativeRate: { value: 10, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 2100, format: "Number" },
        PositiveRate: { value: 18, format: "Percentage" },
        NegativeRate: { value: 12, format: "Percentage" },
      },
    },
  },
  {
    month: "Nov",
    Normal: 1212,
    Positive: 316,
    Negative: 185,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1713, format: "Number" },
        PositiveRate: { value: 18, format: "Percentage" },
        NegativeRate: { value: 11, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1600, format: "Number" },
        PositiveRate: { value: 20, format: "Percentage" },
        NegativeRate: { value: 12, format: "Percentage" },
      },
    },
  },
  {
    month: "Dec",
    Normal: 692,
    Positive: 261,
    Negative: 125,
    tooltipData: {
      current: {
        TotalCustomers: { value: 1078, format: "Number" },
        PositiveRate: { value: 24, format: "Percentage" },
        NegativeRate: { value: 12, format: "Percentage" },
      },
      previous: {
        TotalCustomers: { value: 1020, format: "Number" },
        PositiveRate: { value: 25, format: "Percentage" },
        NegativeRate: { value: 13, format: "Percentage" },
      },
    },
  },
]
