import Stack from "@mui/material/Stack"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Bg from "../../assets/svgs/Bg.svg"
import OnboardingSteps from "./OnboardingSteps"
import { Outlet } from "react-router-dom"
import { useTheme } from "../../contexts/theme"

const AuthLayout = () => {
  const { theme } = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        display="flex"
        flexDirection={{ sm: "column", lg: "row" }}
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
        sx={{
          backgroundColor: "white",
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          "& > *": {
            fontFamily: "Comfortaa",
          },
        }}
      >
        <Box height="100%" width="100%">
          <OnboardingSteps />
        </Box>
        <Stack
          display="flex"
          height="100%"
          width="100%"
          justifyContent={{
            md: "flex-start",
            lg: "center",
          }}
          alignContent="center"
          alignItems="center"
        >
          <Outlet />
        </Stack>
      </Box>
    </ThemeProvider>
  )
}

export default AuthLayout
