import { Backdrop, Box, Typography } from "@mui/material"
import React from "react"
import Logo from "../../assets/svgs/Logo"

const MagicOverlay = ({ open }) => {
  const status = localStorage.getItem("dataStatus")

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          zIndex: 6,
          display: open && status === "In Process" ? "block" : "none",
        }}
      >
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            // backgroundColor: "#000000B0",
            backgroundColor: "#000000",
          }}
          open={open && status === "In Process"}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            height="100vh"
          >
            <Typography variant="h5" color="white">
            Brace yourself for a magical experience!
            </Typography>
            <Logo width="177" height="80" />
          </Box>
        </Backdrop>
      </Box>
    </>
  )
}

export default MagicOverlay
