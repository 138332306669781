import { useTheme } from "../../contexts/theme"

const DescendingIcon = ({ svgStrokeColor }) => {
  const { theme } = useTheme()

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M6 10.6602H8.65377"
        stroke={svgStrokeColor || theme.palette.accent}
      />
      <path
        opacity="0.7"
        d="M4 7.33594H8.66667"
        stroke={svgStrokeColor || theme.palette.accent}
      />
      <path
        d="M2.66992 4H8.66992"
        stroke={svgStrokeColor || theme.palette.accent}
      />
      <path
        d="M13.334 13.3306L13.334 2.66393L11.334 5.33053"
        stroke={svgStrokeColor || theme.palette.accent}
      />
    </svg>
  )
}

export default DescendingIcon
