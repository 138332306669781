import { useTheme } from "../../contexts/theme"

const AscendingIcon = ({ svgStrokeColor }) => {
  const { theme } = useTheme()

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66602 10.6641H8.66602"
        stroke={svgStrokeColor || theme.palette.accent}
      />
      <path
        opacity="0.7"
        d="M4 7.33594H8.66667"
        stroke={svgStrokeColor || theme.palette.accent}
      />
      <path
        opacity="0.3"
        d="M5.33398 4H8.66732"
        stroke={svgStrokeColor || theme.palette.accent}
      />
      <path
        d="M11.334 2.66406V13.3307L13.334 10.6641"
        stroke={svgStrokeColor || theme.palette.accent}
      />
    </svg>
  )
}

export default AscendingIcon
