import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import React, { useEffect, useState } from "react"
import { useTheme } from "../../../../../contexts/theme"
import CustomPaper from "../../../../CustomPaper"
import CloseIconSubscription from "../../../../../assets/svgs/CloseIconSubscription"
import CheckMark from "../../../../../assets/svgs/CheckMark"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AccordionDetails from "@mui/material/AccordionDetails"
import { createCheckoutSession, postData } from "../../../../../api/api"
import ToggleBtn from "../../../../ToggleBtn"
import dayjs from "dayjs"
import { getFormattedValue } from "../../../../../utils/utils"
import staticPackageDetails from "./StaticData/PackageDetails.json"
import { loadStripe } from "@stripe/stripe-js"

const stripePromise = loadStripe(
  "pk_test_51PJKk6HbOHM8DwaAmBs8LN7KJ2oL1bQWo5bUjkXctj8Rzi31aEqGGkXAAl6om6UVxEwoaKQ0jB3vkhcjzjRHCYe000gnoBqODp"
)

const handlePayment = async (
  price,
  packageName,
  packageUid,
  packageTierId,
  selectedOption
) => {
  const items = [{ name: packageName, amount: price, quantity: 1 }]
  try {
    var requestPayload = {
      items: items,
      entityId: localStorage.getItem("entityId"),
      userId:
        localStorage.getItem("user") != undefined
          ? JSON.parse(localStorage.getItem("user")).userId
          : "",
      subscriptionId: packageUid,
      packageTierId: packageTierId,
      billingCycle: selectedOption,
    }
    const session = await createCheckoutSession(requestPayload)
    if (session) {
      const stripe = await stripePromise
      await stripe.redirectToCheckout({ sessionId: session.id })
    }
  } catch (error) {
    console.error("Payment error:", error)
    alert("An error occurred. Please try again.")
  }
}

const PackageDetails = ({
  open,
  handleClose,
  selectedPackageDetails,
  entitySubscription,
  selectedPackageTierId,
}) => {
  const { theme } = useTheme()
  const [loading, setLoading] = useState(true)
  const [packagesDetails, setPackagesDetails] = useState([])
  const [selectedOption, setSelectedOption] = useState("annually")

  const handleToggle = (value) => {
    setSelectedOption(value)
  }

  const options = [
    { value: "monthly", label: "Monthly" },
    { value: "annually", label: "Annually" },
  ]

  const responsestaticPackageDetails = staticPackageDetails

  useEffect(() => {
    const fetchPackagesDetails = async () => {
      setLoading(true)
      try {
        const response = await postData("subscription/packagedetail", {
          packageId: selectedPackageDetails.uid,
        })

        if (!response.success) {
          setPackagesDetails(responsestaticPackageDetails)
        }

        setPackagesDetails(response)
      } catch (error) {
        setPackagesDetails(responsestaticPackageDetails)
      } finally {
        setLoading(false)
      }
    }

    fetchPackagesDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackageDetails])

  const ThemeMode = theme.palette.mode
  const svgColor = theme.palette.accent

  const KeyFeatures = [
    "Robust, high-availability infrastructure to ensure continuous operation",
    "Advanced security and compliance features for data protection",
    "Fully customizable workflows and integrations to fit your specific business processes",
    "24/7 priority support and dedicated account management",
    "Scalable to match your growth without compromising performance",
  ]


  const title = packagesDetails?.result?.[0]?.pt || []
  if (!title.length) {
    console.error("Title is empty or undefined")
  }
  const FreeDashboards = title[0]?.ppt[0]?.pct[0]?.c || []
  const FreeReports = title[0]?.ppt[0]?.pct[1]?.c || []
  const PriceFree = title[0]?.ppt[0]?.price
  const discountedPriceFree =
    selectedOption === "annually" ? PriceFree * 12 * 0.8 : PriceFree

  if (!open) return null
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          },
        }}
      >
        <CustomPaper
          customStyles={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 800,
              sm: 800,
              md: 800,
              lg: 800,
              xl: 1200,
            },
            height: {
              xs: 900,
              sm: 900,
              md: 850,
              lg: 790,
              xl: 760,
            },
            minHeight: "auto",
            minWidth: "65vw",
            overflowY: "auto",
            background:
              ThemeMode === "dark"
                ? "var(--grade, linear-gradient(76deg, #153393 1.45%, #0D2266 95.77%))"
                : "#ffffff",
            boxShadow: "none",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            mb={1}
          >
            <Typography
              color="inherit"
              sx={{ fontSize: "20px", fontWeight: "700" }}
            >
              Details
            </Typography>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={"5px"}
            >
              <ToggleBtn
                options={options}
                selectedOption={selectedOption}
                handleToggle={handleToggle}
                themeMode={ThemeMode}
              />

              <IconButton onClick={handleClose}>
                <CloseIconSubscription svgColor={svgColor} />
              </IconButton>
            </Box>
          </Box>

          <CustomPaper
            customStyles={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "#001D4D"
                  : "rgba(233, 237, 252, 1)",
              border:
                theme.palette.mode === "dark" ? "1px solid #0044B3" : "none",
              borderRadius: "14px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                border: "1px solid var(--Primary-70, rgba(0, 68, 179, 1))",
                borderRadius: "14px",
                minHeight: "61px",
                alignItems: "center",
                padding: "1.2rem",
              }}
            >
              <Typography
                sx={{
                  Weight: "700px",
                  fontSize: "20px",
                }}
              >
                {selectedPackageDetails?.PackageName}
              </Typography>
              <Box
                sx={{
                  width: "fit-content",
                  height: "29px",
                  borderRadius: "20px",
                  background:
                    "linear-gradient(97.13deg, #5468A5 20.11%, #1D47CE 94.09%)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  color="#fff"
                  sx={{
                    fontSize: "12px",
                    p: "1rem",
                  }}
                >
                  {`Active Date: ${dayjs(
                    entitySubscription?.purchasedDate
                  ).format("Do MMMM YYYY")}`}
                </Typography>
              </Box>
            </Box>
          </CustomPaper>

          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="10px"
              minHeight="350px"
              width="100%"
            >
              <CircularProgress size={45} color="info" />
              <Typography variant="body2" color="inherit">
                Fetching All Packages, please wait...
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={2} sx={{ mt: "5px" }}>
              {/* First Inner Paper */}
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "#001D4D"
                        : "rgba(233, 237, 252, 1)",
                    border:
                      theme.palette.mode === "dark"
                        ? "1px solid #0044B3"
                        : "none",
                    borderRadius: "14px",
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      Weight: "700px",
                      fontSize: "18px",
                      lineHeight: "20.07px",
                      mb: 2,
                    }}
                  >
                    {title[0]?.PackageTierName}
                  </Typography>

                  <Grid item xs={12}>
                    <Accordion
                      square="false"
                      elevation={0}
                      defaultExpanded
                      sx={{
                        "&:before": { backgroundColor: "transparent" },
                        borderRadius: "8px",
                        boxShadow: "unset",
                        bgcolor:
                          theme.palette.mode === "dark" ? "#071339" : "#FFFFFF",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography color="inherit" variant="body1">
                          Dashboard
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container gap="12px">
                          {FreeDashboards.map((item, index) => (
                            <Grid container key={index} maxWidth={"237px"}>
                              <Box
                                key={item.componentName}
                                sx={{
                                  display: "flex",
                                  gap: "12px",
                                  flexWrap: "noWrap",
                                  alignItems: "center",
                                  m: "5px",
                                }}
                              >
                                <CheckMark />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.componentName}{" "}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Accordion
                      square="false"
                      elevation={0}
                      sx={{
                        "&:before": { backgroundColor: "transparent" },
                        borderRadius: "8px",
                        boxShadow: "unset",
                        bgcolor:
                          theme.palette.mode === "dark" ? "#071339" : "#FFFFFF",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography color="inherit" variant="body1">
                          Report
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container gap="12px">
                          {FreeReports.map((item, index) => (
                            <Grid container key={index} maxWidth={"237px"}>
                              <Box
                                key={item.componentName}
                                sx={{
                                  display: "flex",
                                  gap: "12px",
                                  flexWrap: "noWrap",
                                  alignItems: "center",
                                  m: "5px",
                                }}
                              >
                                <CheckMark />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.componentName}{" "}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={1}
                  >
                    <Box display="flex" alignItems="center" gap={"2px"}>
                      <Typography component="div" variant="h6">
                        {getFormattedValue(discountedPriceFree, "Currency")}
                      </Typography>

                      <Typography component="div" variant="body1">
                        {selectedOption === "monthly"
                          ? "per month"
                          : "per year"}
                      </Typography>
                    </Box>

                    {discountedPriceFree &&
                    discountedPriceFree !== 0 &&
                    title[0]?.PackageTierId != selectedPackageTierId ? (
                      <Button
                        onClick={() =>
                          handlePayment(
                            discountedPriceFree.toFixed(2),
                            selectedPackageDetails?.PackageName,
                            selectedPackageDetails?.uid,
                            title[0]?.PackageTierId,
                            selectedOption
                          )
                        }
                        sx={{
                          padding: "10px 16px",
                          height: "40px",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: 700,
                          textTransform: "none",
                          borderRadius: "24px",
                          border: `1px solid ${theme.palette.accent}`,
                          color: theme.palette.accent,
                          "&:hover": {
                            border: `1px solid ${theme.palette.accent}`,
                            color: "#fff",
                            backgroundColor: theme.palette.accent,
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "rgba(191, 191, 191, 1)",
                            color: "rgba(255, 255, 255, 1)",
                            border: `none`,
                          },
                        }}
                      >
                        Buy Now
                      </Button>
                    ) : (
                      <Button
                        disabled={true}
                        sx={{
                          padding: "10px 16px",
                          height: "40px",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: 700,
                          textTransform: "none",
                          borderRadius: "24px",
                          border: `1px solid ${theme.palette.accent}`,
                          color: theme.palette.accent,
                          "&:hover": {
                            border: `1px solid ${theme.palette.accent}`,
                            color: "#fff",
                            backgroundColor: theme.palette.accent,
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "rgba(191, 191, 191, 1)",
                            color: "rgba(255, 255, 255, 1)",
                            border: `none`,
                          },
                        }}
                      >
                        Subscribed
                      </Button>
                    )}
                  </Box>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "#001D4D"
                        : "rgba(233, 237, 252, 1)",
                    border:
                      theme.palette.mode === "dark"
                        ? "1px solid #0044B3"
                        : "none",
                    borderRadius: "14px",
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      Weight: "700px",
                      fontSize: "18px",
                      lineHeight: "20.07px",
                      mb: 2,
                    }}
                  >
                    Enterprise
                  </Typography>

                  <Grid item xs={12}>
                    <CustomPaper>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          lineHeight: "1.5",
                          color: ThemeMode === "dark" ? "#ffffff" : "#333333",
                          fontWeight: 400,
                        }}
                      >
                        Our Enterprise Tier PaaS solution offers unparalleled
                        scalability, security, and performance designed to meet
                        the needs of your organizations. With seamless
                        integration capabilities, advanced analytics, and
                        enterprise-grade infrastructure, this package is ideal
                        for companies looking to accelerate digital
                        transformation while maintaining complete control over
                        their data.
                      </Typography>
                    </CustomPaper>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Accordion
                      square="false"
                      elevation={0}
                      defaultExpanded
                      sx={{
                        "&:before": { backgroundColor: "transparent" },
                        borderRadius: "8px",
                        boxShadow: "unset",
                        bgcolor:
                          theme.palette.mode === "dark" ? "#071339" : "#FFFFFF",
                      }}
                    >
                      <AccordionSummary
                        sx={{ width: "100%", flexBasis: "100%" }}
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography color="inherit" variant="body1">
                          Key Features
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box component="ul" sx={{ paddingLeft: 2, margin: 0 }}>
                          {KeyFeatures.map((point, index) => (
                            <Box
                              component="li"
                              key={index}
                              sx={{ listStyleType: "disc", marginLeft: "20px" }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  lineHeight: "1.5",
                                  color:
                                    ThemeMode === "dark"
                                      ? "#ffffff"
                                      : "#333333",
                                  fontWeight: 400,
                                  display: "inline", // Keep text inline to avoid wrapping below bullet
                                }}
                              >
                                {point}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <CustomPaper>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          lineHeight: "1.5",
                          color: ThemeMode === "dark" ? "#ffffff" : "#333333",
                          fontWeight: 400,
                        }}
                      >
                        If your business has unique needs, we are here to help!
                        <strong> Contact us today</strong> to discuss tailored
                        solutions and how we can craft a PaaS solution
                        specifically for you.
                      </Typography>
                    </CustomPaper>
                  </Grid>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"end"}
                    mt={1}
                  >
                    <Button
                      onClick={() => console.log("navigate to contact us page")}
                      sx={{
                        padding: "10px 16px",
                        height: "40px",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: 700,
                        textTransform: "none",
                        borderRadius: "24px",
                        border: `1px solid ${theme.palette.accent}`,
                        color: theme.palette.accent,
                        "&:hover": {
                          border: `1px solid ${theme.palette.accent}`,
                          color: "#fff",
                          backgroundColor: theme.palette.accent,
                        },
                        "&.Mui-disabled": {
                          backgroundColor: "rgba(191, 191, 191, 1)",
                          color: "rgba(255, 255, 255, 1)",
                          border: `none`,
                        },
                      }}
                    >
                      Contact Us!
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
            </Grid>
          )}
        </CustomPaper>
      </Modal>
    </>
  )
}

export default PackageDetails
