const Square = () => {
  return (
    <svg
      width="120"
      height="30"
      viewBox="0 0 120 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.04545 0H24.9545C26.3182 0 27.5455 0.545455 28.5 1.5C29.4545 2.45455 30 3.68182 30 5.04545V24.9545C30 27.6818 27.8182 30 24.9545 30H5.04545C2.31818 30 0 27.8182 0 24.9545V5.04545C0 2.18182 2.18182 0 5.04545 0ZM22.9091 24.5455C23.7273 24.5455 24.5455 23.8636 24.5455 22.9091V6.95455C24.5455 6.13636 23.8636 5.45455 22.9091 5.45455H7.09091C6.68182 5.45455 6.27273 5.59091 5.86364 5.86364C5.59091 6.27273 5.45455 6.68182 5.45455 7.09091V23.0455C5.45455 23.8636 6.13636 24.5455 7.09091 24.5455H22.9091Z"
        fill="#3E4348"
      />
      <path
        d="M11.8636 19.091C11.3181 19.091 10.9091 18.6819 10.9091 18.1365V11.8637C10.9091 11.591 11.0454 11.4546 11.1818 11.1819C11.3181 11.0455 11.5909 10.9092 11.8636 10.9092H18.2727C18.5454 10.9092 18.6818 11.0455 18.9545 11.1819C18.9545 11.3183 19.0909 11.591 19.0909 11.8637V18.2728C19.0909 18.5455 18.9545 18.6819 18.8181 18.9546C18.6818 19.091 18.4091 19.2274 18.1363 19.2274L11.8636 19.091Z"
        fill="#3E4348"
      />
      <path
        d="M45.2727 13.6365C44.3181 13.3638 43.4999 13.0911 42.8181 12.8184C41.4545 12.2729 40.909 11.4547 40.909 10.3638C40.909 8.31836 42.9545 7.36381 44.9999 7.36381C46.909 7.36381 48.5454 8.182 49.7727 9.54563L49.909 9.682L51.5454 8.45472L51.409 8.31836C49.909 6.40927 47.7272 5.31836 45.1363 5.31836C43.4999 5.31836 41.8636 5.72745 40.7727 6.54563C39.5454 7.50018 38.8636 8.86381 38.8636 10.3638C38.8636 14.0456 42.409 15.0002 45.1363 15.682C47.9999 16.5002 49.7727 17.0456 49.7727 19.2275C49.7727 21.4093 47.9999 22.7729 45.409 22.7729C44.0454 22.7729 41.5909 22.3638 40.0909 20.0456L39.9545 19.9093L38.1818 21.2729V21.4093C39.6818 23.5911 42.1363 24.8184 45.1363 24.8184C49.0909 24.8184 51.8181 22.6365 51.8181 19.2275C51.8181 15.4093 48.1363 14.4547 45.2727 13.6365Z"
        fill="#3E4348"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7727 12.9541V10.9087H66.6818V29.9996H64.7727V22.4996C63.6818 23.9996 62.0455 24.8178 60.1364 24.8178C56.4545 24.8178 54 21.9541 54 17.7269C54 13.4996 56.5909 10.636 60.1364 10.636C62.0455 10.4996 63.6818 11.4541 64.7727 12.9541ZM56.0455 17.7269C56.0455 21.6814 58.2273 23.0451 60.4091 23.0451C63.1364 23.0451 64.9091 20.9996 64.9091 17.7269C64.9091 14.4541 63.1364 12.4087 60.4091 12.4087C57.1364 12.4087 56.0455 15.136 56.0455 17.7269Z"
        fill="#3E4348"
      />
      <path
        d="M79.3637 10.9092V18.4092C79.3637 21.0001 77.591 22.9092 75 22.9092C72.9546 22.9092 71.8637 21.6819 71.8637 19.091V10.9092H69.9546V19.6365C69.9546 22.9092 71.7273 24.8183 74.7273 24.8183C76.6364 24.8183 78.1364 24.0001 79.2273 22.6365V24.5455H81.1364V10.9092H79.3637Z"
        fill="#3E4348"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.9545 12.2727C86.3182 11.1818 88.2273 10.5 90.2727 10.5C93.2727 10.5 95.1818 12 95.1818 14.5909V24.5455H93.2727V23.0455C92.3182 24.2727 90.9545 24.8182 89.0454 24.8182C86.0454 24.8182 84.2727 23.1818 84.2727 20.5909C84.2727 17.1818 87.4091 16.7727 88.7727 16.5C89.0454 16.5 89.1818 16.3636 89.4545 16.3636C91.3636 16.0909 93.1363 15.8182 93.1363 14.1818C93.1363 12.4091 90.8182 12.2727 90.1363 12.2727C88.9091 12.2727 87.1363 12.6818 85.9091 13.6364V13.9091L84.9545 12.2727ZM86.1818 20.5909C86.1818 22.7727 88.2273 23.0455 89.1818 23.0455C91.0909 23.0455 93.1363 21.9545 93.1363 19.0909V17.1818C92.1818 17.7273 90.8182 18 89.7273 18.2727L89.1818 18.4091C87.1363 18.5455 86.1818 19.0909 86.1818 20.5909Z"
        fill="#3E4348"
      />
      <path
        d="M106.227 11.1822C105.818 10.9094 105 10.6367 104.318 10.6367C102.818 10.6367 101.318 11.4549 100.5 12.6822V10.9094H98.5909V24.5458H100.5V17.1822C100.5 13.9094 102.273 12.5458 104.182 12.5458C104.727 12.5458 105.273 12.6822 105.682 12.8185L105.818 12.9549L106.227 11.1822Z"
        fill="#3E4348"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.773 17.7273C106.773 13.5 109.364 10.5 113.182 10.5C116.864 10.5 119.318 13.0909 119.318 16.9091C119.318 17.1818 119.318 17.5909 119.318 17.8636V18H108.818C108.818 21 110.727 22.9091 113.455 22.9091C115.091 22.9091 116.455 22.2273 117.273 21.1364L117.409 21L118.773 22.2273L118.636 22.3636C117.682 23.4545 116.045 24.8182 113.318 24.8182C109.364 24.8182 106.773 21.9545 106.773 17.7273ZM113.045 12.4091C110.727 12.4091 109.091 13.9091 108.818 16.3636H117.273C117.136 14.4545 115.909 12.4091 113.045 12.4091Z"
        fill="#3E4348"
      />
    </svg>
  );
};

export default Square;
