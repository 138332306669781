export const MostWastedProducts = [
  {
    id: "Toast",
    "Gross Sales": 6.47,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 99285.47,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.83,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2290.04,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 82,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.93,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Egg Benedict",
    "Gross Sales": 8.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "CFS Breakfast",
    "Gross Sales": 10.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Tuna Sandwich",
    "Gross Sales": 20,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
]
