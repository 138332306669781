import { useState } from "react"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useFormik } from "formik"
import { newPasswordSchema } from "./Validation"
import { useNavigate } from "react-router-dom"
import { postData } from "../../api/api"
import LoadingButton from "@mui/lab/LoadingButton"

const NewPassword = () => {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const [isLoading, setIsLoading] = useState(false)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState({ header: "", message: "" })
  const [error, setError] = useState(false)

  const closeModal = (error) => {
    setShowModal(false)
    if (!error) {
      navigate("/login")
    }
  }

  const formik = useFormik({
    initialValues: {
      tempPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: newPasswordSchema,
    validate: (values) => {
      const errors = {}

      if (!values.tempPassword) {
        errors.tempPassword = "Required"
      }

      if (!values.newPassword) {
        errors.newPassword = "Required"
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = "Required"
      } else if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match"
      }

      return errors
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      const { tempPassword, newPassword, confirmPassword } = values
      try {
        await newPasswordSchema.validate(values, {
          abortEarly: false,
        })

        const user = JSON.parse(localStorage.getItem("user"))

        const response = await postData("auth/update-password", {
          email: user.email,
          tempPassword,
          newPassword,
        }).catch(function (error) {
          if (error.response) {
            return error.response.data
          }
        })
        setIsLoading(false)

        // const simulateSuccess = true
        // const response = {
        //   true: {
        //     success: true,
        //     msg: "Password updated successfully!",
        //   },
        //   false: {
        //     success: false,
        //     msg: "Incorrect Password! Please try again.",
        //   },
        // }[simulateSuccess]

        if (response.success) {
          setError(false)
          setModalMessage({ header: "Success", message: response.msg })
          setShowModal(true)
        } else {
          setError(true)
          setModalMessage({ header: "Failure", message: response.msg })
          setShowModal(true)
        }
      } catch (error) {
        setModalMessage({
          header: "Failure",
          message: error,
        })
        setShowModal(true)
      }
    },
  })

  const handleFormData = async (event) => {
    event.preventDefault()
    await formik.submitForm()
    formik.setTouched({
      tempPassword: true,
      newPassword: true,
      confirmPassword: true,
    })
    await formik.validateForm()
    if (!formik.isValid) {
      return
    }
  }

  return (
    <Stack display="flex" gap={2}>
      <Typography variant="h5" color="inherit" textAlign="center">
        Create new Password
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack display="flex" gap={2}>
          <TextField
            required
            id="tempPassword"
            name="tempPassword"
            label="Temporary Password"
            type={showPassword ? "text" : "password"}
            variant="standard"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.tempPassword}
            error={
              formik.touched.tempPassword && Boolean(formik.errors.tempPassword)
            }
            helperText={
              formik.touched.tempPassword && formik.errors.tempPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            id="newPassword"
            name="newPassword"
            label="New Password"
            type={showPassword ? "text" : "password"}
            variant="standard"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            variant="standard"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box width="100%" display="flex" justifyContent="center">
            <LoadingButton
              onClick={handleFormData}
              type="submit"
              loading={isLoading}
              sx={{
                backgroundColor: "#2642a8",
                padding: "10px 19px",
                borderRadius: 10,
                color: "#fff",
                width: "8rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2642a8",
                },
                "&:disabled": {
                  backgroundColor: "#2642a8a3",
                },
                "& .MuiCircularProgress-colorInherit": {
                  color: "white",
                },
              }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Stack>
      </form>
      <Modal open={showModal} onClose={() => closeModal(error)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">{modalMessage.header}</Typography>
          <Typography variant="body1">{modalMessage.message}</Typography>
        </Box>
      </Modal>
    </Stack>
  )
}

export default NewPassword
