import { useState } from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useTheme } from "../../../contexts/theme"
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs"
import { useNavigate } from "react-router-dom"
import CustomPaper from "../../../components/CustomPaper"
import Table from "../../../components/datagrid/Table"
import AddIcon from "@mui/icons-material/Add"
import List from "@mui/material/List"
import Switch from "@mui/material/Switch"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import SaveModal from "../../../components/SaveModal"

const CreateGroup = () => {
  const navigate = useNavigate()
  const { theme } = useTheme()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const businessMetrics = {
    Sales: {
      "Select KPIs": [
        "Refunds",
        "Sales Growth Percentage",
        "Sales Growth Percentage",
        "Sales Growth Percentage",
        "Sales Growth Percentage",
      ],
      "Select Insights": [
        "Sales Trend",
        "Sales Farecast",
        "Top Selling Items",
        "Top Selling Items",
        "Top Selling Items",
        "Promotions Effectiveness",
      ],
    },
    Customers: {
      "Select KPIs": [
        "Refunds",
        "Customer Growth",
        "Customer Percentage",
        "Customer Percentage",
        "Customer Percentage",
      ],
      "Select Insights": [
        "Customer Trend",
        "Customer Farecast",
        "Top Selling Items",
        "Top Selling Items",
        "Top Selling Items",
        "Promotions Effectiveness",
      ],
    },
    Inventory: {
      "Select KPIs": [
        "Refunds",
        "Inventory Growth",
        "Inventory Percentage",
        "Inventory Growth",
        "Inventory Percentage",
      ],
      "Select Insights": [
        "Inventory Trend",
        "Inventory Farecast",
        "Top Selling Items",
        "Top Selling Items",
        "Top Selling Items",
        "Promotions Effectiveness",
      ],
    },
    Vendors: {
      "Select KPIs": [
        "Refunds",
        "Vendor Growth Percentage",
        "Vendor Growth Percentage",
        "Vendor Growth Percentage",
        "Vendor Growth Percentage",
      ],
      "Select Insights": [
        "Vendor Trend",
        "Vendor Farecast",
        "Top Selling Items",
        "Top Selling Items",
        "Top Selling Items",
        "Promotions Effectiveness",
      ],
    },
    Employee: {
      "Select KPIs": [
        "Refunds",
        "Employee Percentage",
        "Employee Growth",
        "Employee Percentage",
        "Employee Growth",
      ],
      "Select Insights": [
        "Employee Trend",
        "Employee Farecast",
        "Top Selling Items",
        "Top Selling Items",
        "Top Selling Items",
        "Promotions Effectiveness",
      ],
    },
  }

  const [selectedGroup, setSelectedGroup] = useState("")

  const handleSelectChange = (event) => {
    setSelectedGroup(event.target.value)
  }

  return (
    <>
      <Box p="30px 30px 15px 30px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "Management",
              // isActive: false,
              clickHandler: () => {
                navigate("/management")
              },
            },
            {
              text: "Groups",
              // isActive: false,
              // clickHandler: () => {
              //   navigate("/employees/overview")
              // },
            },
            {
              text: "Create",
              isActive: true,
            },
          ]}
        />
      </Box>
      <Box p="15px 30px 15px 30px">
        <Typography
          variant="body1"
          style={{
            textAlign: "left",
            marginTop: "0.5rem",
            fontSize: "18px",
          }}
          color="inherit"
        >
          Create Group
        </Typography>
      </Box>
      <Box p="15px 30px 15px 30px">
        <CustomPaper>
          <Stack gap="20px">
            <CustomPaper
              elevation={0}
              customStyles={{
                background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
              }}
            >
              <Typography color="inherit" mb={1.5}>
                Group Name{" "}
                <Typography color="red" display="inline">
                  *
                </Typography>
              </Typography>
              <input
                type="text"
                name="Group Name"
                required
                placeholder="Enter Group Name"
                style={{
                  border: `2px solid ${
                    theme.palette.mode === "dark" ? "#1A44CB" : "#8C8C8C"
                  }`,
                  minHeight: "40px",
                  width: "100%",
                  borderRadius: "12px",
                  padding: "5px",
                  color: "inherit",
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "rgba(13, 34, 102, 1)"
                      : "white",
                }}
              />
            </CustomPaper>
            <CustomPaper
              elevation={0}
              customStyles={{
                background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
              }}
            >
              <Table title="Users" />
              <Typography color="inherit" my={1.5}>
                Select Members{" "}
                <Typography color="red" display="inline">
                  *
                </Typography>
              </Typography>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={selectedGroup}
                  onChange={handleSelectChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Selet Group" }}
                  sx={{
                    ".MuiSelect-icon": {
                      color: theme.palette.accent,
                    },
                    fontSize: "0.9rem",
                    width: "18rem",
                    height: "2.4rem",
                    borderRadius: "10px",
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "#1c2f72"
                        : "rgba(255, 255, 255, 1)",
                    border:
                      theme.palette.mode === "dark"
                        ? " 1px solid rgba(26, 68, 203, 1)"
                        : "1px solid rgba(166, 166, 166, 1)",
                  }}
                >
                  <MenuItem value="">Select Memebers</MenuItem>
                  <MenuItem value="John Doe" sx={{ fontSize: "0.9rem" }}>
                    John Doe
                  </MenuItem>
                  <MenuItem value="Jane Deo" sx={{ fontSize: "0.9rem" }}>
                    Jane Deo
                  </MenuItem>
                  <MenuItem value="b" sx={{ fontSize: "0.9rem" }}>
                    Pon Poe
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="text"
                startIcon={<AddIcon />}
                sx={{
                  color: theme.palette.accent,
                  textTransform: "none",
                  marginLeft: "10px",
                }}
              >
                Add Member
              </Button>
            </CustomPaper>
            <CustomPaper
              elevation={0}
              customStyles={{
                background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
              }}
            >
              <Typography color="inherit" mb={1.5}>
                Select Permissions
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(businessMetrics).map(([category, metrics]) => (
                  <Grid key={category} item xs={12} md={6} lg={6} height="100%">
                    <CustomPaper
                      customStyles={{
                        border: `1px solid ${
                          theme.palette.mode === "dark" ? "#1A44CB" : "#8C8C8C"
                        }`,
                        background: "transparent",
                      }}
                      elevation={0}
                    >
                      <Typography color="inherit" mb={1.5}>
                        {category}
                      </Typography>

                      <Grid container spacing={2}>
                        {Object.entries(metrics).map(([section, items]) => (
                          <Grid key={section} item xs={12} md={6} lg={6}>
                            <Typography color="inherit">{section}</Typography>
                            <List dense={true}>
                              {items.map((item) => (
                                <FormGroup key={item}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        disableRipple
                                        size="medium"
                                        defaultChecked
                                        color="inherit"
                                        sx={{
                                          height: "42px",
                                          width: "62px",
                                          "& .MuiSwitch-track": {
                                            borderRadius: 2,
                                            border:
                                              theme.palette.mode === "dark"
                                                ? "2px solid #895334"
                                                : "2px solid #8FA6EF",
                                            opacity: 0.3,
                                            backgroundColor:
                                              theme.palette.mode === "dark"
                                                ? "#441900"
                                                : "#E9EDFC",
                                          },
                                          "& .MuiSwitch-thumb": {
                                            backgroundColor:
                                              theme.palette.mode === "dark"
                                                ? "#ffffff"
                                                : "#8FA6EF",
                                            borderRadius: "45%",
                                            width: "10px",
                                            height: "10px",
                                            position: "relative",
                                            top: "7px",
                                            left: "7px",
                                          },
                                          "& .MuiSwitch-switchBase:hover": {
                                            backgroundColor: "#ffffff00",
                                            backgroundOrigin: "content-box",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        component="div"
                                        color="inherit"
                                      >
                                        {item}
                                      </Typography>
                                    }
                                  />
                                </FormGroup>
                              ))}
                            </List>
                          </Grid>
                        ))}
                      </Grid>
                    </CustomPaper>
                  </Grid>
                ))}
              </Grid>
            </CustomPaper>
            <Divider />
            <Box display="flex" gap="10px" justifyContent="flex-end">
              <Button
                variant="outlined"
                sx={{
                  border: `1px solid ${theme.palette.accent}`,
                  textTransform: "none",
                  color: theme.palette.accent,
                  borderRadius: "20px",
                  "&:hover": {
                    border: `1px solid ${theme.palette.accent}`,
                    color: "",
                    boxShadow: "5px",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleOpen}
                sx={{
                  bgcolor: theme.palette.accent,
                  textTransform: "none",
                  color: "white",
                  borderRadius: "20px",
                  "&:hover": {
                    bgcolor: theme.palette.accent,
                    boxShadow: "5px",
                  },
                }}
              >
                save
              </Button>
              <SaveModal
                open={open}
                onClose={() => setOpen(false)}
                title="Success"
                message="Group has been created successfully!!"
              />
            </Box>
          </Stack>
        </CustomPaper>
      </Box>
    </>
  )
}

export default CreateGroup
