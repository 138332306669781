const SubMenu = ({ stroke }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3335 4.5C1.3335 3.08579 1.3335 2.37868 1.69961 1.93934C2.06573 1.5 2.65498 1.5 3.8335 1.5C5.012 1.5 5.60128 1.5 5.96737 1.93934C6.3335 2.37868 6.3335 3.08579 6.3335 4.5V6C6.3335 7.4142 6.3335 8.12134 5.96737 8.56065C5.60128 9 5.012 9 3.8335 9C2.65498 9 2.06573 9 1.69961 8.56065C1.3335 8.12134 1.3335 7.4142 1.3335 6V4.5Z"
        stroke={stroke}
        strokeWidth="2"
      />
      <path
        d="M9.66699 12C9.66699 10.5858 9.66699 9.87868 10.0331 9.43934C10.3992 9 10.9885 9 12.167 9C13.3455 9 13.9348 9 14.3009 9.43934C14.667 9.87868 14.667 10.5858 14.667 12V13.5C14.667 14.9142 14.667 15.6213 14.3009 16.0606C13.9348 16.5 13.3455 16.5 12.167 16.5C10.9885 16.5 10.3992 16.5 10.0331 16.0606C9.66699 15.6213 9.66699 14.9142 9.66699 13.5V12Z"
        stroke={stroke}
        strokeWidth="2"
      />
      <path
        d="M9.66699 3.16667C9.66699 2.38099 9.66699 1.98816 10.0331 1.74408C10.3992 1.5 10.9885 1.5 12.167 1.5C13.3455 1.5 13.9348 1.5 14.3009 1.74408C14.667 1.98816 14.667 2.38099 14.667 3.16667V4C14.667 4.78567 14.667 5.17852 14.3009 5.42258C13.9348 5.66667 13.3455 5.66667 12.167 5.66667C10.9885 5.66667 10.3992 5.66667 10.0331 5.42258C9.66699 5.17852 9.66699 4.78567 9.66699 4V3.16667Z"
        stroke={stroke}
        strokeWidth="2"
      />
      <path
        d="M1.3335 13.9987C1.3335 13.213 1.3335 12.8202 1.69961 12.5761C2.06573 12.332 2.65498 12.332 3.8335 12.332C5.012 12.332 5.60128 12.332 5.96737 12.5761C6.3335 12.8202 6.3335 13.213 6.3335 13.9987V14.832C6.3335 15.6177 6.3335 16.0106 5.96737 16.2546C5.60128 16.4987 5.012 16.4987 3.8335 16.4987C2.65498 16.4987 2.06573 16.4987 1.69961 16.2546C1.3335 16.0106 1.3335 15.6177 1.3335 14.832V13.9987Z"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  )
}

export default SubMenu
