import axios from "axios"

const API_BASE_URL = "https://app-livelytics-platform-qa.azurewebsites.net"

const handleRequest = async (method, endpoint, params = {}, headers = {}) => {
  try {
    const url = `${API_BASE_URL}/${endpoint}`
    const response = await axios({ method, url, headers, data: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getData = async (endpoint, params = {}) => {
  const headers = { Authorization: localStorage.getItem("token") }
  return handleRequest("get", endpoint, params, headers)
}

export const postData = async (endpoint, params = {}) => {
  const headers = { Authorization: localStorage.getItem("token") }
  return handleRequest("post", endpoint, params, headers)
}

export const aiReportApi = async (reportName, data) => {
  try {
    reportName = "Summarize this data for me"
    const response = await postData("copilot/liveassistantonReport", {
      reportName,
      data,
    })
    return response
  } catch (error) {
    console.log("Ai response error", error)
    return null
  }
}

export const liveAssistanceApi = async (userPrompt, data) => {
  try {
    const response = await postData("copilot/liveassistant", {
      userPrompt,
      data,
    })
    return response
  } catch (error) {
    console.log("Ai response error", error)
    return null
  }
}

export const aiReportApiData = async (userPrompt,entityDb) => {
  try {
    const response = await postData("copilot/liveassistantWithDatabase", {
      userPrompt,
      entityDb
    })
    return response
  } catch (error) {
    console.log("Ai response error", error)
    return null
  }
}


export const aiFeedbackCreate = async (feedbackCreationData) => {
  try {
    const response = await postData("copilot/liveassistantFeedback", {
      ...feedbackCreationData,
    })

    return response
  } catch (error) {
    console.error("Ai response error", error)
    return null
  }
}

export const aiFeedbackUpdate = async (
  feedbackType,
  feedbackID,
  thumbs,
  comment
) => {
  try {
    const response = await postData("copilot/liveassistantFeedbackUpdate", {
      feedbackType,
      feedbackID,
      thumbs,
      comment,
    })

    return response
  } catch (error) {
    console.log("Ai response error", error)
    return null
  }
}

export const createCheckoutSession = async (items) => {
  try {
    const response = await postData("stripe/stripePayment", {
      items,
    })
    return response
  } catch (error) {
    console.log("Payment error", error)
    return null
  }
}
