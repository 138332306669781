const InfoLogoSvg = ({ svgColor }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 18"
      style={{ marginLeft: "10px" }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1366_51018)">
        <path
          opacity="0.6"
          d="M7.99967 14.6654C11.6663 14.6654 14.6663 11.6654 14.6663 7.9987C14.6663 4.33203 11.6663 1.33203 7.99967 1.33203C4.33301 1.33203 1.33301 4.33203 1.33301 7.9987C1.33301 11.6654 4.33301 14.6654 7.99967 14.6654Z"
          stroke={svgColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.10059 4.79642C7.10059 4.35657 7.45715 4 7.897 4H7.90417C8.34402 4 8.70059 4.35657 8.70059 4.79642C8.70059 5.23626 8.34402 5.59283 7.90417 5.59283H7.897C7.45715 5.59283 7.10059 5.23626 7.10059 4.79642Z"
          fill={svgColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.90059 6.28516C8.34241 6.28516 8.70059 6.64333 8.70059 7.08516V11.0852C8.70059 11.527 8.34241 11.8852 7.90059 11.8852C7.45876 11.8852 7.10059 11.527 7.10059 11.0852V7.08516C7.10059 6.64333 7.45876 6.28516 7.90059 6.28516Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1366_51018">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InfoLogoSvg
