const XaxisCustomer = () => {
  return (
    <>
      <svg
        width="89"
        height="12"
        viewBox="0 0 89 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6.12195H87.2222M87.2222 6.12195L81.6497 11M87.2222 6.12195L81.6497 1"
          stroke="#6180B6"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default XaxisCustomer
