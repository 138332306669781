import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  currentPage: "overview",
}

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
})

export const { setCurrentPage } = navigationSlice.actions

export default navigationSlice.reducer
