import { useTheme } from "../../contexts/theme"

const GraphIcon = () => {
  const { theme } = useTheme()
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M2.5 18.332H17.5"
          stroke={theme.palette.accent}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66665 6.98438H3.33333C2.875 6.98438 2.5 7.35937 2.5 7.81771V15.0011C2.5 15.4594 2.875 15.8344 3.33333 15.8344H4.66665C5.12498 15.8344 5.49998 15.4594 5.49998 15.0011V7.81771C5.49998 7.35937 5.12498 6.98438 4.66665 6.98438Z"
          stroke={theme.palette.accent}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6667 4.32422H9.33333C8.875 4.32422 8.5 4.69922 8.5 5.15755V14.9992C8.5 15.4575 8.875 15.8325 9.33333 15.8325H10.6667C11.125 15.8325 11.5 15.4575 11.5 14.9992V5.15755C11.5 4.69922 11.125 4.32422 10.6667 4.32422Z"
          stroke={theme.palette.accent}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 1.66797H15.3333C14.875 1.66797 14.5 2.04297 14.5 2.5013V15.0013C14.5 15.4596 14.875 15.8346 15.3333 15.8346H16.6667C17.125 15.8346 17.5 15.4596 17.5 15.0013V2.5013C17.5 2.04297 17.125 1.66797 16.6667 1.66797Z"
          stroke={theme.palette.accent}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default GraphIcon
