import React, { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import MuiProgressBar from "./MuiProgressBar"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import TablePagination from "@mui/material/TablePagination"
import { useDispatch, useSelector } from "react-redux"
import { ProgressBarData } from "../../../constants/chartsData"
import IconButton from "@mui/material/IconButton"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { postData } from "../../../api/api"
import CustomLegend from "../../CustomLegend"

const CustomPaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  return (
    <Box display="flex" alignItems="center" gap="8px">
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <Typography
        variant="body2"
        color="text.primary"
        sx={{ whiteSpace: "nowrap" }}
      >
        {`${page * rowsPerPage + 1}-${Math.min(
          count,
          (page + 1) * rowsPerPage
        )} of ${count}`}
      </Typography>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  )
}

const ProgressBar = ({
  route,
  setChartData,
  comparisonPeriod,
  title,
  description,
  postUrl,
  reportUid,
  parameters = [],
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  showPagination = false,
  focus_mode = true,
  showAiDropdown = true,
  staticData = ProgressBarData,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [rowsPerPage, setRowsPerPage] = useState(
    extractedParameters.defaultRowsPerPage || 10
  )
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [page, setPage] = useState(0)
  const [apiResponse, setApiResponse] = useState([])
  const [maxSliderValue, setMaxSliderValue] = useState(0)
  const [displayedData, setDisplayedData] = useState([])
  const dispatch = useDispatch()
  const svgColor = theme.palette.mode === "dark" ? "#BCCAF6" : "#1D47CE"
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  const beforePointerColors = [
    "Most Popular Brands",
    "Top 10 products bought",
    "Popular Items with Repeat Customers",
  ].includes(title)
    ? [
        "#95C75B",
        "#BBC954",
        "#D2CB50",
        "#A17BC8",
        "#5C73F2",
        "#D084E4",
        "#8B93D1",
        "#FF85E1",
        "#8067E3",
        "#7282FF",
        "#A96FFF",
      ]
    : [
        "#3D50F5",
        "#A17BC8",
        "#007BFF",
        "#17A2B8",
        "#E900EF",
        "#343AFF",
        "#8067E3",
        "#A96FFF",
        "#7282FF",
        "#D084E4",
      ]
  const navigationPaths = {
    customer: "/customers/allcustomers/customer/1",
    employee: "/employees/allemployees/employee/1",
    inventory: "/products/allproducts/product/1",
  }

  const navigationPath =
    navigationPaths[route] || "/customers/allcustomers/customer/1"

  const afterPointerColor = "rgba(7, 19, 57, 1)"

  const tooltip_data = extractedParameters.tooltip_data || []

  const fieldMap = extractedParameters.fieldMap || {}

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const enableNavigation =
    extractedParameters.enableNavigation === undefined
      ? false
      : extractedParameters.enableNavigation
  const showBubble =
    extractedParameters.showBubble === undefined
      ? true
      : extractedParameters.showBubble

  const transformData = (data) => {
    let transformedData = []

    if (data.reportDataCurrent && data.reportDataPrevious) {
      data.reportDataCurrent.forEach((item, index) => {
        let eachElement = {
          title: item?.[fieldMap.name],
          defaultValue: item?.[fieldMap.key],
          tooltipData: {
            current: {},
            previous: {},
          },
        }

        if (tooltip_data && Array.isArray(tooltip_data)) {
          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: item[tooltipDataItem.name] || 0,
              format: tooltipDataItem.format,
            }
          })

          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value:
                data.reportDataPrevious[index]?.[tooltipDataItem.name] || 0,
              format: tooltipDataItem.format,
            }
          })
        }

        transformedData.push(eachElement)
      })
    } else {
      data.forEach((item) => {
        let eachElement = {
          title: item?.[fieldMap.name],
          defaultValue: item?.[fieldMap.key],
          tooltipData: {
            current: {},
          },
        }

        if (tooltip_data && Array.isArray(tooltip_data)) {
          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: item[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })
        }

        transformedData.push(eachElement)
      })
    }

    return transformedData
  }

  const roundToNearest10 = (value) => Math.ceil(value / 10) * 10

  const fetchData = async () => {
    try {
      const response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: combinedState.dateRangeData.fromDate,
        toDate: combinedState.dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: combinedState.comparisonPeriod.previousFromDate,
          previousToDate: combinedState.comparisonPeriod.previousToDate,
        },
      })
      setApiResponse(response)
      if (!response || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(
          setChartData({
            reportUid,
            response: staticData,
          })
        )
      } else {
        const transformedData = transformData(response)
        const maxValue = Math.max(
          ...transformedData.map((item) => item.defaultValue || 0)
        )
        const roundedMaxValue = roundToNearest10(maxValue)

        setMaxSliderValue(roundedMaxValue)
        dispatch(
          setChartData({
            reportUid,
            response: transformedData,
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: staticData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    return selectedReport ? selectedReport.reportData : null
  })

  useEffect(() => {
    const updateDisplayedData = () => {
      const paginatedData = Array.isArray(response)
        ? response.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : staticData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

      if (paginatedData.length === 0) {
        console.warn("No data to display for current page")
      }

      setDisplayedData(paginatedData)

      if (paginatedData.length > 0) {
        const maxValue = Math.max(
          ...paginatedData.map((item) => item.defaultValue || 0)
        )
        const roundedMaxValue = roundToNearest10(maxValue)

        setMaxSliderValue(roundedMaxValue)

        // maxValue over an array like {2,98,678}
        // if (!maxSliderValue || maxSliderValue === 0) {
        //   setMaxSliderValue(roundedMaxValue)
        // }
      }
    }

    if (response && response.length > 0) {
      updateDisplayedData()
    } else {
      console.warn("Response data is invalid or empty")
      const staticMaxValue = Math.max(
        ...staticData.map((item) => item.defaultValue || 0)
      )
      setMaxSliderValue(roundToNearest10(staticMaxValue))
    }
  }, [response, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const totalItems = Array.isArray(response)
    ? response.length
    : staticData.length

  const staticExtraDetails = {
    "Jenna Nguyen": {
      email: "jenna.nguyen@livelytics.ai",
      phoneNumber: "555-123-4567",
      streetAddress: "123 Elm Street",
      aptSuitNo: "Apt 4B",
      city: "Springfield",
      state: "IL",
      zip: "62704",
      totalNoOfItemsBought: "35",
      allTimeSpend: "$3,546.43",
      allTimeAverageSpend: "$101.32",
      allTimeVisits: "45",
      address: "123 Elm Street, Apt 4B, Springfield, IL, 62704",
      amountSpent: "$3,546.43",
      mostBoughtItem: "Sukiyaki",
      comment:
        "The service was impeccable. The staff was attentive, friendly, and made us feel welcome.",
      totalVisits: "45",
      totalSales: "$4,567.89",
      averageSales: "$101.32",
      rating: "4.3",
      rate: "+11.01%",
      lastVisitedOn: "16th August 2024",
    },
    "Erika Sanchez": {
      email: "erika.sanchez@livelytics.ai",
      phoneNumber: "555-234-5678",
      streetAddress: "456 Oak Avenue",
      aptSuitNo: "Suite 301",
      city: "Lincoln",
      state: "NE",
      zip: "68508",
      totalNoOfItemsBought: "20",
      allTimeSpend: "$1,234.56",
      allTimeAverageSpend: "$61.73",
      allTimeVisits: "25",
      address: "456 Oak Avenue, Suite 301, Lincoln, NE, 68508",
      amountSpent: "$1,234.56",
      mostBoughtItem: "Tempura",
      comment: "Great atmosphere and delicious food. Will definitely return!",
      totalVisits: "25",
      totalSales: "$1,678.90",
      averageSales: "$67.16",
      rating: "4.3",
      rate: "+11.01%",
      lastVisitedOn: "28th July 2024",
    },
    "Devon Scott": {
      email: "devon.scott@livelytics.ai",
      phoneNumber: "555-345-6789",
      streetAddress: "789 Pine Road",
      aptSuitNo: "",
      city: "Madison",
      state: "WI",
      zip: "53703",
      totalNoOfItemsBought: "15",
      allTimeSpend: "$654.32",
      allTimeAverageSpend: "$43.62",
      allTimeVisits: "18",
      address: "789 Pine Road, Madison, WI, 53703",
      amountSpent: "$654.32",
      mostBoughtItem: "Ramen",
      comment:
        "Food is consistently good and staff is friendly. A solid choice for a meal.",
      totalVisits: "18",
      totalSales: "$872.45",
      averageSales: "$48.47",
      rating: "4.3",
      rate: "+11.01%",
      lastVisitedOn: "25th August 2024",
    },
    "Tiffany Bryan": {
      email: "tiffany.bryan@livelytics.ai",
      phoneNumber: "555-456-7890",
      streetAddress: "321 Maple Street",
      aptSuitNo: "Apt 2A",
      city: "Columbus",
      state: "OH",
      zip: "43215",
      totalNoOfItemsBought: "10",
      allTimeSpend: "$789.10",
      allTimeAverageSpend: "$78.91",
      allTimeVisits: "12",
      address: "321 Maple Street, Apt 2A, Columbus, OH, 43215",
      amountSpent: "$789.10",
      mostBoughtItem: "Sushi",
      comment: "Fresh and tasty sushi every time. Highly recommended!",
      totalVisits: "12",
      totalSales: "$945.25",
      averageSales: "$78.77",
      rating: "4.3",
      rate: "+11.01%",
      lastVisitedOn: "16th August 2024",
    },
    "Michael Patrick": {
      email: "michael.patrick@livelytics.ai",
      phoneNumber: "555-567-8901",
      streetAddress: "654 Birch Lane",
      aptSuitNo: "Unit 5",
      city: "Denver",
      state: "CO",
      zip: "80203",
      totalNoOfItemsBought: "22",
      allTimeSpend: "$2,345.67",
      allTimeAverageSpend: "$106.63",
      allTimeVisits: "30",
      address: "654 Birch Lane, Unit 5, Denver, CO, 80203",
      amountSpent: "$2,345.67",
      mostBoughtItem: "Yakitori",
      comment:
        "A must-visit place for amazing Japanese cuisine. Friendly staff and great service.",
      totalVisits: "30",
      totalSales: "$3,210.89",
      averageSales: "$107.03",
      rating: "4.3",
      rate: "+11.01%",
      lastVisitedOn: "12th July 2024",
    },
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <div className="sales">{title}</div>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                reportType="ProgressBar"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box minHeight="auto">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="auto"
            width="100%"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <Stack flexWrap="wrap" marginTop="10px">
            {staticData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <MuiProgressBar
                  showTooltipPrevious={showTooltipPrevious}
                  key={index}
                  item={{
                    ...(staticExtraDetails?.[item?.title] || {}),
                    ...item,
                  }}
                  defaultValue={item.defaultValue}
                  maxValue={maxSliderValue}
                  tooltipData={item.tooltipData}
                  beforePointerColor={
                    beforePointerColors[index % beforePointerColors.length]
                  }
                  afterPointerColor={afterPointerColor}
                  enableNavigation={enableNavigation}
                  navigationPath={navigationPath}
                  showBubble={showBubble}
                />
              ))}
            {showPagination && (
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                sx={{
                  ".MuiTablePagination-selectLabel": {
                    display: "none !important",
                  },
                  ".MuiTablePagination-displayedRows": {
                    display: "none !important",
                  },
                  ".MuiTablePagination-actions": {
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  },
                }}
                ActionsComponent={CustomPaginationActions}
              />
            )}
            <CustomLegend
              reportTitle={apiResponse}
              reportData={staticData}
              showAiDropdown={showAiDropdown}
            />
          </Stack>
        ) : (
          <Stack flexWrap="wrap" marginTop="10px">
            {displayedData.map((item, index) => (
              <MuiProgressBar
                showTooltipPrevious={showTooltipPrevious}
                key={index}
                item={{
                  ...(staticExtraDetails?.[item?.title] || {}),
                  ...item,
                }}
                defaultValue={item.defaultValue}
                maxValue={maxSliderValue}
                tooltipData={item.tooltipData}
                beforePointerColor={
                  beforePointerColors[index % beforePointerColors.length]
                }
                afterPointerColor={afterPointerColor}
                enableNavigation={enableNavigation}
                navigationPath={navigationPath}
                showBubble={showBubble}
              />
            ))}
            {showPagination && totalItems > 10 && (
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                sx={{
                  ".MuiTablePagination-selectLabel": {
                    display: "none !important",
                  },
                  ".MuiTablePagination-displayedRows": {
                    display: "none !important",
                  },
                  ".MuiTablePagination-actions": {
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  },
                }}
                ActionsComponent={CustomPaginationActions}
              />
            )}
            <CustomLegend
              reportTitle={title}
              reportData={apiResponse}
              showAiDropdown={showAiDropdown}
            />
          </Stack>
        )}
      </Box>
    </>
  )
}

export default ProgressBar
