export const CalendarChartParameters = {
  margin: {
    top: 40,
    right: 0,
    bottom: 40,
    left: 0,
  },
  from: "2015-03-01",
  to: "2016-07-12",

  emptyColor: "#eeeeee",
  colors: ["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"],
  yearSpacing: 40,
  monthBorderColor: "#ffffff",
  dayBorderWidth: 2,
  dayBorderColor: "#ffffff",
}
