import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Logo from "../assets/svgs/Logo"

const ComingSoon = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "black",
          opacity: 0.65,
          position: "absolute",
          zIndex: 5,
          width: "100%",
          height: "400%",
        }}
      >
        <Box
          sx={{
            mt: 12,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            gap: 1,
          }}
        >
          <Typography variant="h4">Coming soon..</Typography>
          <Logo />
        </Box>
      </Box>
    </>
  )
}

export default ComingSoon
