import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Logo from "../../assets/svgs/Logo"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import gsap from "gsap"

const WelcomeOvvi = () => {
  const navigate = useNavigate()

  useEffect(() => {
    gsap.from(".welcome-text", {
      opacity: 0,
      y: 50,
      duration: 1,
      ease: "power2.out",
    })

    const timer = setTimeout(() => {
      navigate("/select-pos-platform")
    }, 2000)

    return () => clearTimeout(timer)
  }, [navigate])

  return (
    <>
      <Box display="flex" flexDirection="column" gap={6} alignItems="center">
        <Box sx={{ height: "100px" }}>
          <Logo fill="black" yfill="#1D47CE" width="233px" height="80px" />
        </Box>
        <Typography
          className="welcome-text"
          variant="body1"
          color="inherit"
          textAlign="center"
          fontWeight={500}
          fontSize="34px"
          lineHeight="35.68px"
        >
          Welcome to Livelytics
        </Typography>
      </Box>
    </>
  )
}

export default WelcomeOvvi
