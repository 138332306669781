import { useCallback, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import gsap from "gsap"

const useDotButton = (emblaApi) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )
  const handleAnimation = () => {
    const tl = gsap.timeline({
      defaults: {
        duration: 0.75,
        ease: "ease-out",
        rotation: "-=72",
        svgOrigin: "400 400",
        repeatRefresh: true,
        repeatDelay: 0.75,
      },
    })
    tl.to("#orbit", {})
    tl.to(
      "#orbit > g",
      {
        duration: 0.75,
        rotation: "+=72",
        transformOrigin: "center center",
      },
      0
    )
    tl.to(
      "#pentagon",
      {
        duration: 0.75,
        rotation: "-=72",
        transformOrigin: "center center",
      },
      0
    )

    return () => {
      tl.kill()
    }
  }

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
    handleAnimation()
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on("reInit", onInit)
    emblaApi.on("reInit", onSelect)
    emblaApi.on("select", onSelect)
  }, [emblaApi, onInit, onSelect])

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  }
}

const OnboardingSteps = ({ carouselStyles }) => {
  const slides = [
    {
      variant: "nested",
      content: [
        {
          variant: "h4",
          style: {
            textWrap: "balance",
            fontSize: "1.7rem",
          },
          content: "Unleash the power of Livelytics AI and BI to make",
        },
        {
          variant: "h4",
          style: {
            fontWeight: "bold",
            fontSize: "1.4rem",
            marginY: "15px",
          },
          content: "Insightful Business Decisions",
        },
        {
          variant: "h6",
          style: {
            textWrap: "balance",
            fontSize: "1.1rem",
          },
          content:
            "Livelytics platform acts as your co-pilot for providing actionable insights and recommendations to enable business users to make informed business decisions for sustainable growth",
        },
      ],
    },
    {
      variant: "h4",
      style: {
        fontSize: "1.7rem",
        marginY: "15px",
        textWrap: "pretty",
      },
      content: "Register on the Livelytics Platform",
    },
    {
      variant: "h4",
      style: {
        fontSize: "1.7rem",
        marginY: "15px",
        textWrap: "pretty",
      },
      content: "Login and Connect to your POS system on the platform",
    },
    {
      variant: "h4",
      style: {
        fontSize: "1.7rem",
        marginY: "15px",
        textWrap: "pretty",
      },
      content: "Select or Build your Package of reports and recommendations",
    },
    {
      variant: "h4",
      style: {
        fontSize: "1.7rem",
        marginY: "15px",
        textWrap: "pretty",
      },
      content: "Start making informed business decisions with AI",
    },
  ]

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      axis: "y",
      watchDrag: false,
    },
    [Autoplay()]
  )

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  return (
    <>
      {/* Animated Pentagon */}
      <Box
        style={{
          position: "absolute",
          top: "0",
        }}
      >
        <svg
          style={{ display: "block" }}
          width="600"
          height="600"
          viewBox="300 460 800 800"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="pentagon"
            d="M149.213 728.566C82.037 712.896 32.5172 655.859 26.4321 587.149L13.2572 438.383L0.579781 289.575C-5.2755 220.843 33.6663 156.121 97.135 129.101L234.548 70.6007L372.155 12.5595C435.713 -14.2484 509.3 2.78758 554.611 54.7993L652.711 167.409L750.435 280.346C795.571 332.51 802.11 407.759 766.645 466.924L689.862 595.022L612.649 722.863C576.988 781.909 507.441 811.38 440.212 795.935L294.656 762.494L149.213 728.566Z"
            fill="#6382E9"
          />
          <g id="orbit">
            <g id="3">
              <path
                d="M615.608 457.166C607.279 457.166 599.009 456.082 590.794 453.914C582.581 451.632 575.622 448.438 569.918 444.331L580.699 423.112C585.262 426.42 590.567 429.044 596.613 430.983C602.659 432.923 608.762 433.893 614.923 433.893C621.882 433.893 627.358 432.524 631.35 429.786C635.344 427.048 637.34 423.283 637.34 418.492C637.34 413.929 635.572 410.335 632.035 407.711C628.499 405.087 622.794 403.775 614.923 403.775H602.26V385.465L626.866 357.572H575.907V335.326H659.757V353.294L632.903 383.972C636.93 384.671 646.41 386.378 653.939 392.995C661.469 399.612 665.233 408.11 665.233 418.492C665.233 425.222 663.465 431.554 659.928 437.486C656.392 443.305 650.972 448.039 643.672 451.69C636.37 455.34 627.016 457.166 615.608 457.166Z"
                fill="white"
              />
            </g>
            <g id="2">
              <path
                d="M409.376 240.625V222.657L455.58 179.021C459.23 175.713 461.911 172.747 463.622 170.123C465.333 167.498 466.474 165.103 467.045 162.935C467.729 160.767 468.072 158.771 468.072 156.945C468.072 152.155 466.417 148.504 463.109 145.994C459.914 143.37 455.18 142.058 448.906 142.058C443.886 142.058 439.209 143.028 434.873 144.967C430.653 146.906 427.059 149.93 424.093 154.037L403.9 141.031C408.463 134.186 414.852 128.767 423.066 124.775C431.28 120.782 440.748 118.785 451.472 118.785C460.371 118.785 468.128 120.268 474.745 123.234C481.477 126.087 486.667 130.136 490.318 135.384C494.082 140.632 495.965 146.906 495.965 154.208C495.965 158.087 495.451 161.965 494.425 165.845C493.512 169.609 491.572 173.601 488.606 177.823C485.755 182.044 481.533 186.779 475.943 192.026L447.762 218.085L499.901 218.037V240.625H409.376Z"
                fill="white"
              />
            </g>
            <g id="1">
              <path
                d="M165.431 331.109V232.689H141.06V209.27L194.664 209.314V331.109H165.431Z"
                fill="white"
              />
            </g>
            <g id="4">
              <path
                d="M424.212 677.184V658.212L481.65 580.93H511.24L457.471 654.209H537.174V677.184H424.212ZM489.831 702.77V677.184L490.701 654.209V631.407H517.332V702.77H489.831Z"
                fill="white"
              />
            </g>
            <g id="logo">
              <path
                d="M137.26 551.767H143.408L146.772 562.115C147.769 565.352 148.475 567.695 149.347 571.059C150.178 567.822 151.092 564.927 151.964 562.158L155.329 551.767H161.144L152.545 576.085H145.983L137.26 551.767Z"
                fill="white"
              />
              <path
                d="M165.879 561.094H176.803C176.845 557.559 174.893 555.387 171.612 555.387C168.538 555.387 166.419 557.219 165.879 561.094ZM160.105 563.522C160.105 555.898 164.55 550.916 171.694 550.916C178.797 550.916 183.325 555.856 182.619 565.097H165.797C166.211 569.527 168.413 571.485 171.944 571.485C174.976 571.485 176.97 570.038 177.468 567.568L182.411 568.845C181.497 573.189 177.385 576.085 171.86 576.085C164.965 576.085 160.105 571.528 160.105 563.522Z"
                fill="white"
              />
              <path
                d="M184.489 542.44H190.18V576.085H184.489V542.44Z"
                fill="white"
              />
              <path
                d="M228.602 542.355H234.251V548.147H228.602V542.355ZM228.56 551.767H234.251V576.085H228.56V551.767Z"
                fill="white"
              />
              <path
                d="M236.992 563.521C236.992 555.94 241.603 550.958 248.664 550.958C254.811 550.958 258.591 554.365 259.172 559.475L253.731 560.242C253.44 557.218 251.448 555.77 248.622 555.77C244.967 555.77 242.725 558.665 242.725 563.521C242.725 568.376 244.925 571.272 248.622 571.272C251.571 571.272 253.606 569.527 253.814 566.629L259.297 567.354C258.757 572.805 254.52 576.085 248.622 576.085C241.561 576.085 236.992 571.059 236.992 563.521Z"
                fill="white"
              />
              <path
                d="M259.755 568.121L265.237 567.44C265.237 570.208 267.066 571.996 270.14 571.996C272.715 571.996 274.292 571.06 274.292 568.973C274.292 566.29 271.592 566.12 267.771 565.225C263.244 564.203 260.544 562.372 260.544 558.284C260.544 553.769 264.325 550.916 270.14 550.916C275.955 550.916 279.444 553.599 279.776 558.156L274.376 558.88C274.376 556.367 272.839 554.919 269.931 554.919C267.564 554.919 266.276 556.026 266.276 557.602C266.276 559.774 268.311 560.328 272.507 561.179C276.826 562.074 280.15 563.522 280.15 568.547C280.15 573.062 276.744 576.085 269.931 576.085C263.659 576.085 260.088 573.062 259.755 568.121Z"
                fill="white"
              />
              <path
                d="M221.874 568.928V555.597L225.24 555.724L225.78 551.427L221.874 551.509V544.486L216.225 544.911V551.509H216.215V555.642H216.225V570.974C216.225 574.381 217.968 576.085 221.663 576.085C222.953 576.085 224.533 575.869 225.736 575.488V570.891C225.278 571.057 224.571 571.184 223.74 571.184C222.203 571.184 221.874 570.72 221.874 568.928Z"
                fill="white"
              />
              <path
                d="M225.78 551.427L225.445 555.724H213.517V551.426L225.78 551.427Z"
                fill="white"
              />
              <path
                d="M202.255 561.316L199.753 567.686L190.901 551.761L196.927 551.755L202.255 561.316Z"
                fill="white"
              />
              <path
                d="M205.466 567.686C204.458 570.239 202.221 575.222 196.705 575.897C195.839 576.003 194.523 576.032 193.393 576.013V571.239H193.721C194.119 571.25 194.896 571.228 195.637 571.094C198.908 570.504 199.771 567.669 199.771 567.669L205.466 567.686Z"
                fill="white"
              />
              <path
                d="M215.827 526.75H221.483L205.466 567.686H199.753L215.827 526.75Z"
                fill="white"
              />
              <path
                d="M104.529 586.943C104.419 586.943 104.328 586.906 104.257 586.833C104.185 586.759 104.15 586.667 104.15 586.553V579.541C104.15 579.428 104.185 579.336 104.257 579.262C104.328 579.189 104.419 579.152 104.529 579.152H109.092C109.196 579.152 109.285 579.187 109.359 579.257C109.434 579.327 109.472 579.418 109.472 579.531C109.472 579.631 109.434 579.717 109.359 579.79C109.285 579.864 109.196 579.9 109.092 579.9H104.909V582.653H107.953C108.064 582.653 108.155 582.69 108.226 582.763C108.297 582.836 108.333 582.926 108.333 583.033C108.333 583.138 108.297 583.228 108.226 583.301C108.155 583.375 108.064 583.412 107.953 583.412H104.909V586.195H109.092C109.196 586.195 109.285 586.231 109.359 586.304C109.434 586.378 109.472 586.464 109.472 586.563C109.472 586.676 109.434 586.768 109.359 586.838C109.285 586.907 109.196 586.943 109.092 586.943H104.529Z"
                fill="white"
              />
              <path
                d="M117.867 586.943C117.757 586.943 117.666 586.907 117.595 586.838C117.524 586.768 117.488 586.676 117.488 586.563V583.69C117.488 583.219 117.368 582.845 117.128 582.568C116.888 582.292 116.58 582.155 116.204 582.155C115.802 582.155 115.472 582.292 115.216 582.568C114.96 582.845 114.835 583.212 114.842 583.671H114.171C114.177 583.225 114.271 582.833 114.453 582.494C114.634 582.155 114.884 581.892 115.202 581.706C115.519 581.52 115.88 581.427 116.281 581.427C116.664 581.427 117.003 581.52 117.299 581.706C117.593 581.892 117.824 582.155 117.989 582.494C118.155 582.833 118.237 583.232 118.237 583.69V586.563C118.237 586.676 118.203 586.768 118.135 586.838C118.067 586.907 117.978 586.943 117.867 586.943ZM114.472 586.943C114.362 586.943 114.271 586.907 114.2 586.838C114.128 586.768 114.092 586.676 114.092 586.563V583.69C114.092 583.219 113.972 582.845 113.733 582.568C113.492 582.292 113.184 582.155 112.809 582.155C112.406 582.155 112.076 582.29 111.816 582.559C111.557 582.828 111.427 583.179 111.427 583.611H110.892C110.905 583.185 110.997 582.808 111.169 582.479C111.341 582.15 111.576 581.892 111.874 581.706C112.173 581.52 112.51 581.427 112.886 581.427C113.269 581.427 113.608 581.52 113.903 581.706C114.198 581.892 114.428 582.155 114.594 582.494C114.759 582.833 114.842 583.232 114.842 583.69V586.563C114.842 586.676 114.807 586.768 114.74 586.838C114.672 586.907 114.583 586.943 114.472 586.943ZM111.057 586.943C110.941 586.943 110.848 586.907 110.78 586.838C110.712 586.768 110.678 586.676 110.678 586.563V581.875C110.678 581.762 110.712 581.669 110.78 581.596C110.848 581.523 110.941 581.486 111.057 581.486C111.167 581.486 111.256 581.523 111.325 581.596C111.393 581.669 111.427 581.762 111.427 581.875V586.563C111.427 586.676 111.393 586.768 111.325 586.838C111.256 586.907 111.167 586.943 111.057 586.943Z"
                fill="white"
              />
              <path
                d="M122.479 586.292C122.855 586.292 123.192 586.202 123.491 586.022C123.789 585.843 124.024 585.595 124.196 585.279C124.368 584.963 124.454 584.609 124.454 584.217C124.454 583.818 124.368 583.462 124.196 583.149C124.024 582.837 123.789 582.589 123.491 582.407C123.192 582.224 122.855 582.132 122.479 582.132C122.11 582.132 121.775 582.224 121.477 582.407C121.178 582.589 120.945 582.837 120.777 583.149C120.608 583.462 120.524 583.818 120.524 584.217C120.524 584.609 120.608 584.963 120.777 585.279C120.945 585.595 121.178 585.843 121.477 586.022C121.775 586.202 122.11 586.292 122.479 586.292ZM120.164 589.274C120.047 589.274 119.954 589.239 119.886 589.169C119.819 589.099 119.784 589.005 119.784 588.885V584.177C119.79 583.658 119.912 583.191 120.149 582.776C120.385 582.36 120.707 582.032 121.112 581.793C121.517 581.554 121.973 581.433 122.479 581.433C122.998 581.433 123.462 581.555 123.87 581.798C124.279 582.041 124.601 582.371 124.838 582.79C125.075 583.209 125.194 583.684 125.194 584.217C125.194 584.742 125.078 585.214 124.848 585.633C124.618 586.052 124.305 586.383 123.909 586.626C123.514 586.869 123.066 586.989 122.567 586.989C122.132 586.989 121.738 586.895 121.385 586.705C121.031 586.516 120.747 586.265 120.534 585.953V588.885C120.534 589.005 120.499 589.099 120.431 589.169C120.363 589.239 120.274 589.274 120.164 589.274Z"
                fill="white"
              />
              <path
                d="M128.976 586.289C129.352 586.289 129.689 586.199 129.987 586.019C130.286 585.84 130.519 585.594 130.688 585.282C130.857 584.969 130.941 584.613 130.941 584.215C130.941 583.815 130.857 583.458 130.688 583.142C130.519 582.826 130.286 582.578 129.987 582.399C129.689 582.219 129.352 582.13 128.976 582.13C128.599 582.13 128.262 582.219 127.964 582.399C127.665 582.578 127.43 582.826 127.259 583.142C127.087 583.458 127.001 583.815 127.001 584.215C127.001 584.613 127.087 584.969 127.259 585.282C127.43 585.594 127.665 585.84 127.964 586.019C128.262 586.199 128.599 586.289 128.976 586.289ZM128.976 586.987C128.457 586.987 127.993 586.867 127.584 586.628C127.176 586.389 126.855 586.06 126.621 585.641C126.388 585.221 126.271 584.746 126.271 584.215C126.271 583.676 126.388 583.196 126.621 582.778C126.855 582.359 127.176 582.029 127.584 581.79C127.993 581.551 128.457 581.431 128.976 581.431C129.494 581.431 129.956 581.551 130.362 581.79C130.767 582.029 131.086 582.359 131.32 582.778C131.553 583.196 131.673 583.676 131.681 584.215C131.681 584.746 131.562 585.221 131.325 585.641C131.088 586.06 130.767 586.389 130.362 586.628C129.956 586.867 129.494 586.987 128.976 586.987Z"
                fill="white"
              />
              <path
                d="M133.948 586.943C133.863 586.943 133.789 586.919 133.724 586.873C133.659 586.826 133.614 586.766 133.588 586.693L132.071 582.025C132.031 581.859 132.035 581.727 132.08 581.631C132.125 581.535 132.216 581.486 132.353 581.486C132.437 581.486 132.511 581.509 132.576 581.556C132.641 581.602 132.69 581.675 132.722 581.775L134.075 586.014H133.861L135.115 581.785C135.141 581.699 135.185 581.628 135.247 581.571C135.308 581.515 135.387 581.486 135.485 581.486C135.583 581.486 135.662 581.513 135.724 581.567C135.785 581.619 135.829 581.692 135.854 581.785L137.051 585.915H136.886L138.209 581.775C138.274 581.583 138.394 581.486 138.569 581.486C138.711 581.486 138.809 581.541 138.861 581.651C138.913 581.761 138.913 581.885 138.861 582.025L137.343 586.693C137.317 586.766 137.274 586.826 137.212 586.873C137.15 586.919 137.077 586.943 136.993 586.943C136.908 586.943 136.833 586.919 136.764 586.873C136.697 586.826 136.652 586.766 136.633 586.693L135.407 582.524H135.524L134.298 586.693C134.272 586.773 134.226 586.835 134.162 586.877C134.097 586.921 134.026 586.943 133.948 586.943Z"
                fill="white"
              />
              <path
                d="M142.051 586.987C141.526 586.987 141.061 586.869 140.656 586.633C140.25 586.397 139.932 586.07 139.702 585.65C139.471 585.232 139.357 584.753 139.357 584.214C139.357 583.669 139.465 583.189 139.682 582.773C139.9 582.357 140.2 582.029 140.582 581.79C140.965 581.55 141.406 581.431 141.906 581.431C142.398 581.431 142.828 581.545 143.194 581.775C143.561 582.005 143.845 582.322 144.046 582.727C144.247 583.134 144.347 583.596 144.347 584.114C144.347 584.22 144.315 584.306 144.25 584.369C144.185 584.432 144.101 584.463 143.997 584.463H139.863V583.825H144.075L143.657 584.134C143.663 583.748 143.595 583.403 143.452 583.097C143.31 582.791 143.107 582.552 142.845 582.379C142.582 582.206 142.269 582.119 141.906 582.119C141.536 582.119 141.211 582.209 140.933 582.389C140.654 582.568 140.438 582.816 140.285 583.132C140.133 583.448 140.057 583.808 140.057 584.214C140.057 584.62 140.143 584.979 140.315 585.291C140.486 585.604 140.722 585.85 141.02 586.029C141.318 586.209 141.662 586.299 142.051 586.299C142.285 586.299 142.52 586.257 142.757 586.174C142.994 586.091 143.183 585.986 143.326 585.86C143.397 585.8 143.478 585.769 143.569 585.765C143.66 585.762 143.738 585.787 143.802 585.84C143.887 585.92 143.93 586.007 143.934 586.1C143.937 586.193 143.9 586.272 143.822 586.339C143.608 586.525 143.336 586.679 143.005 586.803C142.674 586.926 142.356 586.987 142.051 586.987Z"
                fill="white"
              />
              <path
                d="M145.817 583.581C145.837 583.169 145.942 582.799 146.133 582.473C146.325 582.147 146.575 581.892 146.883 581.706C147.19 581.52 147.532 581.426 147.909 581.426C148.207 581.426 148.438 581.47 148.6 581.556C148.762 581.643 148.823 581.769 148.785 581.935C148.759 582.035 148.714 582.102 148.653 582.134C148.591 582.168 148.517 582.181 148.429 582.175C148.341 582.168 148.243 582.161 148.133 582.154C147.77 582.121 147.447 582.16 147.164 582.269C146.883 582.379 146.658 582.547 146.494 582.773C146.328 582.999 146.245 583.268 146.245 583.581H145.817ZM145.876 586.943C145.759 586.943 145.668 586.909 145.603 586.843C145.539 586.777 145.506 586.684 145.506 586.563V581.865C145.506 581.746 145.539 581.652 145.603 581.586C145.668 581.52 145.759 581.486 145.876 581.486C145.992 581.486 146.083 581.52 146.148 581.586C146.213 581.652 146.245 581.746 146.245 581.865V586.563C146.245 586.684 146.213 586.777 146.148 586.843C146.083 586.909 145.992 586.943 145.876 586.943Z"
                fill="white"
              />
              <path
                d="M150.389 586.943C150.272 586.943 150.18 586.907 150.112 586.838C150.043 586.768 150.009 586.673 150.009 586.553V581.875C150.009 581.755 150.043 581.661 150.112 581.591C150.18 581.521 150.272 581.486 150.389 581.486C150.499 581.486 150.588 581.521 150.656 581.591C150.725 581.661 150.759 581.755 150.759 581.875V586.553C150.759 586.673 150.725 586.768 150.656 586.838C150.588 586.907 150.499 586.943 150.389 586.943ZM150.379 580.449C150.237 580.449 150.113 580.396 150.009 580.29C149.906 580.183 149.854 580.053 149.854 579.9C149.854 579.734 149.907 579.602 150.015 579.506C150.121 579.41 150.246 579.362 150.389 579.362C150.525 579.362 150.647 579.41 150.754 579.506C150.861 579.602 150.914 579.734 150.914 579.9C150.914 580.053 150.862 580.183 150.759 580.29C150.655 580.396 150.528 580.449 150.379 580.449Z"
                fill="white"
              />
              <path
                d="M157.121 586.943C157.011 586.943 156.92 586.906 156.849 586.833C156.778 586.759 156.742 586.67 156.742 586.563V583.97C156.742 583.558 156.666 583.219 156.514 582.953C156.361 582.687 156.153 582.487 155.891 582.354C155.628 582.221 155.332 582.155 155.001 582.155C154.683 582.155 154.396 582.218 154.14 582.344C153.883 582.471 153.68 582.644 153.532 582.862C153.382 583.082 153.308 583.332 153.308 583.611H152.772C152.786 583.192 152.896 582.818 153.104 582.489C153.311 582.16 153.586 581.9 153.931 581.711C154.274 581.521 154.657 581.427 155.079 581.427C155.539 581.427 155.95 581.525 156.314 581.721C156.677 581.917 156.964 582.205 157.175 582.584C157.386 582.963 157.491 583.425 157.491 583.97V586.563C157.491 586.67 157.455 586.759 157.384 586.833C157.313 586.906 157.225 586.943 157.121 586.943ZM152.938 586.943C152.822 586.943 152.729 586.907 152.661 586.838C152.593 586.768 152.559 586.676 152.559 586.563V581.875C152.559 581.755 152.593 581.661 152.661 581.591C152.729 581.521 152.822 581.486 152.938 581.486C153.048 581.486 153.138 581.521 153.206 581.591C153.274 581.661 153.308 581.755 153.308 581.875V586.563C153.308 586.676 153.274 586.768 153.206 586.838C153.138 586.907 153.048 586.943 152.938 586.943Z"
                fill="white"
              />
              <path
                d="M161.461 586.294C161.843 586.294 162.182 586.206 162.478 586.029C162.773 585.853 163.004 585.609 163.173 585.296C163.341 584.983 163.426 584.625 163.426 584.219C163.426 583.813 163.341 583.452 163.173 583.137C163.004 582.82 162.773 582.575 162.478 582.399C162.182 582.222 161.843 582.134 161.461 582.134C161.078 582.134 160.737 582.222 160.439 582.399C160.141 582.575 159.907 582.82 159.739 583.137C159.57 583.452 159.486 583.813 159.486 584.219C159.486 584.625 159.57 584.983 159.739 585.296C159.907 585.609 160.141 585.853 160.439 586.029C160.737 586.206 161.078 586.294 161.461 586.294ZM161.481 589.096C160.968 589.096 160.517 588.998 160.128 588.802C159.739 588.606 159.418 588.338 159.165 587.999C159.093 587.919 159.063 587.833 159.072 587.74C159.082 587.647 159.129 587.57 159.213 587.51C159.298 587.45 159.392 587.431 159.496 587.45C159.599 587.47 159.68 587.52 159.739 587.6C159.927 587.84 160.169 588.032 160.464 588.178C160.758 588.325 161.101 588.398 161.49 588.398C161.853 588.398 162.181 588.309 162.473 588.129C162.764 587.949 162.996 587.697 163.168 587.371C163.34 587.045 163.426 586.663 163.426 586.224V584.707L163.708 584.129L164.166 584.219V586.254C164.166 586.792 164.049 587.276 163.815 587.705C163.582 588.134 163.264 588.474 162.862 588.722C162.46 588.972 161.999 589.096 161.481 589.096ZM161.383 586.991C160.871 586.991 160.416 586.874 160.021 586.637C159.625 586.402 159.316 586.074 159.092 585.655C158.868 585.237 158.756 584.757 158.756 584.219C158.756 583.673 158.873 583.191 159.106 582.773C159.34 582.353 159.661 582.026 160.069 581.79C160.478 581.554 160.942 581.435 161.461 581.435C161.986 581.435 162.451 581.554 162.857 581.79C163.262 582.026 163.58 582.353 163.81 582.773C164.04 583.191 164.159 583.673 164.166 584.219L163.727 584.459C163.727 584.951 163.627 585.387 163.426 585.77C163.225 586.152 162.947 586.451 162.594 586.667C162.24 586.884 161.837 586.991 161.383 586.991Z"
                fill="white"
              />
              <path
                d="M168.582 585.082L168.873 584.414H172.823L173.115 585.082H168.582ZM167.94 586.937C167.79 586.937 167.684 586.874 167.618 586.747C167.554 586.621 167.547 586.498 167.599 586.378L170.459 579.416C170.531 579.236 170.654 579.147 170.829 579.147C170.998 579.147 171.118 579.236 171.189 579.416L174.059 586.388C174.117 586.515 174.109 586.638 174.035 586.758C173.96 586.877 173.855 586.937 173.718 586.937C173.64 586.937 173.571 586.917 173.509 586.877C173.447 586.838 173.397 586.778 173.358 586.698L170.683 580.044H170.984L168.29 586.698C168.257 586.778 168.21 586.838 168.148 586.877C168.087 586.917 168.017 586.937 167.94 586.937Z"
                fill="white"
              />
              <path
                d="M177.171 586.987C176.659 586.987 176.203 586.866 175.804 586.623C175.406 586.381 175.091 586.049 174.86 585.63C174.63 585.211 174.516 584.739 174.516 584.214C174.516 583.682 174.626 583.207 174.846 582.788C175.066 582.369 175.368 582.038 175.751 581.795C176.134 581.553 176.574 581.431 177.074 581.431C177.47 581.431 177.835 581.511 178.168 581.671C178.503 581.83 178.796 582.069 179.049 582.389C179.12 582.468 179.146 582.553 179.126 582.643C179.107 582.732 179.052 582.811 178.961 582.877C178.89 582.931 178.811 582.949 178.723 582.932C178.636 582.915 178.56 582.867 178.494 582.788C178.118 582.348 177.645 582.129 177.074 582.129C176.711 582.129 176.392 582.219 176.116 582.399C175.84 582.578 175.626 582.824 175.474 583.136C175.321 583.449 175.245 583.808 175.245 584.214C175.245 584.613 175.326 584.969 175.488 585.281C175.651 585.594 175.877 585.84 176.169 586.019C176.461 586.199 176.795 586.289 177.171 586.289C177.424 586.289 177.656 586.254 177.867 586.184C178.078 586.114 178.264 586.007 178.426 585.86C178.504 585.794 178.585 585.757 178.67 585.75C178.754 585.744 178.831 585.77 178.903 585.83C178.981 585.903 179.025 585.986 179.034 586.079C179.044 586.173 179.013 586.252 178.942 586.319C178.468 586.764 177.878 586.987 177.171 586.987Z"
                fill="white"
              />
              <path
                d="M182.562 586.943C182.217 586.943 181.911 586.86 181.642 586.693C181.373 586.527 181.161 586.299 181.005 586.01C180.85 585.721 180.772 585.39 180.772 585.018V580.139C180.772 580.027 180.806 579.934 180.873 579.86C180.942 579.788 181.03 579.751 181.141 579.751C181.251 579.751 181.342 579.788 181.413 579.86C181.485 579.934 181.521 580.027 181.521 580.139V585.018C181.521 585.357 181.618 585.634 181.813 585.85C182.007 586.066 182.257 586.175 182.562 586.175H182.824C182.928 586.175 183.012 586.211 183.077 586.284C183.142 586.358 183.175 586.451 183.175 586.563C183.175 586.676 183.136 586.768 183.058 586.838C182.98 586.907 182.883 586.943 182.766 586.943H182.562ZM180.139 582.354C180.042 582.354 179.961 582.323 179.896 582.259C179.831 582.196 179.799 582.118 179.799 582.025C179.799 581.925 179.831 581.844 179.896 581.78C179.961 581.717 180.042 581.686 180.139 581.686H182.62C182.718 581.686 182.798 581.717 182.863 581.78C182.928 581.844 182.961 581.925 182.961 582.025C182.961 582.118 182.928 582.196 182.863 582.259C182.798 582.323 182.718 582.354 182.62 582.354H180.139Z"
                fill="white"
              />
              <path
                d="M184.819 586.943C184.702 586.943 184.61 586.907 184.542 586.838C184.473 586.768 184.439 586.673 184.439 586.553V581.875C184.439 581.755 184.473 581.661 184.542 581.591C184.61 581.521 184.702 581.486 184.819 581.486C184.929 581.486 185.018 581.521 185.086 581.591C185.155 581.661 185.188 581.755 185.188 581.875V586.553C185.188 586.673 185.155 586.768 185.086 586.838C185.018 586.907 184.929 586.943 184.819 586.943ZM184.809 580.449C184.667 580.449 184.543 580.396 184.439 580.29C184.336 580.183 184.284 580.053 184.284 579.9C184.284 579.734 184.337 579.602 184.445 579.506C184.551 579.41 184.676 579.362 184.819 579.362C184.955 579.362 185.077 579.41 185.184 579.506C185.291 579.602 185.344 579.734 185.344 579.9C185.344 580.053 185.292 580.183 185.188 580.29C185.085 580.396 184.958 580.449 184.809 580.449Z"
                fill="white"
              />
              <path
                d="M189.411 586.289C189.787 586.289 190.124 586.199 190.422 586.019C190.721 585.84 190.954 585.594 191.123 585.282C191.291 584.969 191.376 584.613 191.376 584.215C191.376 583.815 191.291 583.458 191.123 583.142C190.954 582.826 190.721 582.578 190.422 582.399C190.124 582.219 189.787 582.13 189.411 582.13C189.034 582.13 188.697 582.219 188.399 582.399C188.1 582.578 187.865 582.826 187.693 583.142C187.522 583.458 187.435 583.815 187.435 584.215C187.435 584.613 187.522 584.969 187.693 585.282C187.865 585.594 188.1 585.84 188.399 586.019C188.697 586.199 189.034 586.289 189.411 586.289ZM189.411 586.987C188.892 586.987 188.428 586.867 188.019 586.628C187.611 586.389 187.29 586.06 187.056 585.641C186.823 585.221 186.705 584.746 186.705 584.215C186.705 583.676 186.823 583.196 187.056 582.778C187.29 582.359 187.611 582.029 188.019 581.79C188.428 581.551 188.892 581.431 189.411 581.431C189.929 581.431 190.391 581.551 190.797 581.79C191.202 582.029 191.521 582.359 191.755 582.778C191.988 583.196 192.108 583.676 192.115 584.215C192.115 584.746 191.997 585.221 191.76 585.641C191.523 586.06 191.202 586.389 190.797 586.628C190.391 586.867 189.929 586.987 189.411 586.987Z"
                fill="white"
              />
              <path
                d="M198.03 586.943C197.92 586.943 197.83 586.906 197.758 586.833C197.687 586.759 197.651 586.67 197.651 586.563V583.97C197.651 583.558 197.574 583.219 197.422 582.953C197.27 582.687 197.063 582.487 196.799 582.354C196.537 582.221 196.24 582.155 195.91 582.155C195.592 582.155 195.304 582.218 195.048 582.344C194.792 582.471 194.59 582.644 194.441 582.862C194.291 583.082 194.217 583.332 194.217 583.611H193.681C193.695 583.192 193.805 582.818 194.012 582.489C194.22 582.16 194.496 581.9 194.839 581.711C195.183 581.521 195.566 581.427 195.987 581.427C196.448 581.427 196.859 581.525 197.223 581.721C197.586 581.917 197.873 582.205 198.084 582.584C198.295 582.963 198.4 583.425 198.4 583.97V586.563C198.4 586.67 198.365 586.759 198.293 586.833C198.221 586.906 198.134 586.943 198.03 586.943ZM193.847 586.943C193.73 586.943 193.638 586.907 193.57 586.838C193.502 586.768 193.468 586.676 193.468 586.563V581.875C193.468 581.755 193.502 581.661 193.57 581.591C193.638 581.521 193.73 581.486 193.847 581.486C193.957 581.486 194.047 581.521 194.115 581.591C194.182 581.661 194.217 581.755 194.217 581.875V586.563C194.217 586.676 194.182 586.768 194.115 586.838C194.047 586.907 193.957 586.943 193.847 586.943Z"
                fill="white"
              />
              <path
                d="M204.694 586.937C204.584 586.937 204.493 586.902 204.422 586.832C204.35 586.762 204.314 586.671 204.314 586.558V584.852L204.499 584.064L205.074 584.214V586.558C205.074 586.671 205.038 586.762 204.966 586.832C204.895 586.902 204.804 586.937 204.694 586.937ZM202.369 586.289C202.745 586.289 203.08 586.199 203.375 586.019C203.671 585.84 203.904 585.592 204.076 585.276C204.248 584.96 204.334 584.607 204.334 584.214C204.334 583.815 204.248 583.459 204.076 583.147C203.904 582.834 203.671 582.586 203.375 582.404C203.08 582.221 202.745 582.13 202.369 582.13C201.999 582.13 201.665 582.221 201.367 582.404C201.068 582.586 200.831 582.834 200.657 583.147C200.481 583.459 200.394 583.815 200.394 584.214C200.394 584.607 200.481 584.96 200.657 585.276C200.831 585.592 201.068 585.84 201.367 586.019C201.665 586.199 201.999 586.289 202.369 586.289ZM202.291 586.987C201.792 586.987 201.344 586.866 200.948 586.623C200.552 586.381 200.24 586.049 200.009 585.63C199.779 585.211 199.664 584.739 199.664 584.214C199.664 583.682 199.783 583.207 200.02 582.788C200.256 582.369 200.579 582.038 200.987 581.795C201.396 581.553 201.856 581.431 202.369 581.431C202.881 581.431 203.34 581.553 203.746 581.795C204.151 582.038 204.472 582.369 204.708 582.788C204.945 583.207 205.067 583.682 205.074 584.214L204.771 584.453C204.771 584.932 204.663 585.363 204.446 585.745C204.228 586.127 203.934 586.43 203.561 586.653C203.188 586.876 202.764 586.987 202.291 586.987Z"
                fill="white"
              />
              <path
                d="M209.12 586.289C209.496 586.289 209.833 586.198 210.132 586.015C210.429 585.832 210.665 585.584 210.837 585.271C211.009 584.959 211.095 584.603 211.095 584.204C211.095 583.812 211.009 583.458 210.837 583.142C210.665 582.826 210.429 582.578 210.132 582.399C209.833 582.219 209.496 582.13 209.12 582.13C208.75 582.13 208.416 582.219 208.118 582.399C207.819 582.578 207.586 582.826 207.417 583.142C207.249 583.458 207.164 583.812 207.164 584.204C207.164 584.603 207.249 584.959 207.417 585.271C207.586 585.584 207.819 585.832 208.118 586.015C208.416 586.198 208.75 586.289 209.12 586.289ZM209.12 586.987C208.614 586.987 208.158 586.867 207.753 586.628C207.347 586.389 207.026 586.062 206.79 585.645C206.553 585.23 206.431 584.763 206.425 584.245V579.536C206.425 579.416 206.459 579.322 206.527 579.252C206.596 579.182 206.688 579.148 206.804 579.148C206.914 579.148 207.004 579.182 207.072 579.252C207.14 579.322 207.174 579.416 207.174 579.536V582.468C207.388 582.156 207.671 581.905 208.025 581.715C208.379 581.526 208.773 581.431 209.208 581.431C209.707 581.431 210.154 581.553 210.549 581.795C210.946 582.038 211.258 582.369 211.489 582.788C211.719 583.207 211.834 583.679 211.834 584.204C211.834 584.736 211.716 585.212 211.479 585.63C211.242 586.049 210.92 586.381 210.511 586.623C210.102 586.866 209.638 586.987 209.12 586.987Z"
                fill="white"
              />
              <path
                d="M214.343 586.943C214.084 586.943 213.852 586.869 213.648 586.723C213.444 586.576 213.284 586.374 213.171 586.115C213.057 585.855 213 585.556 213 585.217V579.531C213 579.418 213.035 579.327 213.103 579.257C213.171 579.187 213.26 579.152 213.371 579.152C213.481 579.152 213.57 579.187 213.638 579.257C213.706 579.327 213.74 579.418 213.74 579.531V585.217C213.74 585.503 213.797 585.736 213.911 585.915C214.024 586.095 214.168 586.184 214.343 586.184H214.586C214.684 586.184 214.762 586.22 214.82 586.289C214.878 586.359 214.907 586.451 214.907 586.563C214.907 586.676 214.867 586.768 214.785 586.838C214.705 586.907 214.599 586.943 214.47 586.943H214.343Z"
                fill="white"
              />
              <path
                d="M217.952 586.987C217.426 586.987 216.961 586.869 216.556 586.633C216.15 586.397 215.833 586.07 215.602 585.65C215.372 585.232 215.257 584.753 215.257 584.214C215.257 583.669 215.365 583.189 215.583 582.773C215.8 582.357 216.1 582.029 216.483 581.79C216.866 581.55 217.307 581.431 217.806 581.431C218.299 581.431 218.728 581.545 219.095 581.775C219.461 582.005 219.745 582.322 219.946 582.727C220.147 583.134 220.247 583.596 220.247 584.114C220.247 584.22 220.215 584.306 220.151 584.369C220.086 584.432 220.001 584.463 219.897 584.463H215.763V583.825H219.976L219.557 584.134C219.564 583.748 219.495 583.403 219.353 583.097C219.21 582.791 219.007 582.552 218.744 582.379C218.482 582.206 218.169 582.119 217.806 582.119C217.436 582.119 217.112 582.209 216.834 582.389C216.554 582.568 216.338 582.816 216.186 583.132C216.033 583.448 215.958 583.808 215.958 584.214C215.958 584.62 216.043 584.979 216.215 585.291C216.387 585.604 216.622 585.85 216.921 586.029C217.219 586.209 217.562 586.299 217.952 586.299C218.185 586.299 218.421 586.257 218.657 586.174C218.894 586.091 219.084 585.986 219.226 585.86C219.298 585.8 219.379 585.769 219.47 585.765C219.56 585.762 219.638 585.787 219.703 585.84C219.787 585.92 219.831 586.007 219.834 586.1C219.838 586.193 219.8 586.272 219.723 586.339C219.508 586.525 219.236 586.679 218.906 586.803C218.574 586.926 218.256 586.987 217.952 586.987Z"
                fill="white"
              />
              <path
                d="M224.628 586.943C224.517 586.943 224.427 586.906 224.355 586.833C224.284 586.759 224.248 586.667 224.248 586.553V579.541C224.248 579.428 224.284 579.336 224.355 579.262C224.427 579.189 224.517 579.152 224.628 579.152C224.738 579.152 224.829 579.189 224.9 579.262C224.971 579.336 225.007 579.428 225.007 579.541V586.553C225.007 586.667 224.971 586.759 224.9 586.833C224.829 586.906 224.738 586.943 224.628 586.943Z"
                fill="white"
              />
              <path
                d="M231.31 586.943C231.2 586.943 231.109 586.906 231.038 586.833C230.966 586.759 230.93 586.67 230.93 586.563V583.97C230.93 583.558 230.854 583.219 230.702 582.953C230.549 582.687 230.342 582.487 230.079 582.354C229.817 582.221 229.52 582.155 229.189 582.155C228.871 582.155 228.584 582.218 228.328 582.344C228.072 582.471 227.869 582.644 227.72 582.862C227.571 583.082 227.496 583.332 227.496 583.611H226.961C226.974 583.192 227.085 582.818 227.292 582.489C227.5 582.16 227.775 581.9 228.119 581.711C228.463 581.521 228.845 581.427 229.267 581.427C229.728 581.427 230.139 581.525 230.502 581.721C230.865 581.917 231.152 582.205 231.364 582.584C231.575 582.963 231.679 583.425 231.679 583.97V586.563C231.679 586.67 231.644 586.759 231.572 586.833C231.502 586.906 231.414 586.943 231.31 586.943ZM227.127 586.943C227.01 586.943 226.918 586.907 226.849 586.838C226.781 586.768 226.748 586.676 226.748 586.563V581.875C226.748 581.755 226.781 581.661 226.849 581.591C226.918 581.521 227.01 581.486 227.127 581.486C227.237 581.486 227.327 581.521 227.394 581.591C227.463 581.661 227.496 581.755 227.496 581.875V586.563C227.496 586.676 227.463 586.768 227.394 586.838C227.327 586.907 227.237 586.943 227.127 586.943Z"
                fill="white"
              />
              <path
                d="M235.309 586.943C234.965 586.943 234.658 586.86 234.389 586.693C234.12 586.527 233.908 586.299 233.752 586.01C233.596 585.721 233.519 585.39 233.519 585.018V580.139C233.519 580.027 233.552 579.934 233.62 579.86C233.689 579.788 233.777 579.751 233.888 579.751C233.998 579.751 234.089 579.788 234.161 579.86C234.232 579.934 234.268 580.027 234.268 580.139V585.018C234.268 585.357 234.365 585.634 234.559 585.85C234.754 586.066 235.003 586.175 235.309 586.175H235.571C235.675 586.175 235.759 586.211 235.824 586.284C235.889 586.358 235.921 586.451 235.921 586.563C235.921 586.676 235.883 586.768 235.805 586.838C235.727 586.907 235.63 586.943 235.512 586.943H235.309ZM232.886 582.354C232.789 582.354 232.707 582.323 232.643 582.259C232.578 582.196 232.545 582.118 232.545 582.025C232.545 581.925 232.578 581.844 232.643 581.78C232.707 581.717 232.789 581.686 232.886 581.686H235.366C235.464 581.686 235.545 581.717 235.61 581.78C235.675 581.844 235.708 581.925 235.708 582.025C235.708 582.118 235.675 582.196 235.61 582.259C235.545 582.323 235.464 582.354 235.366 582.354H232.886Z"
                fill="white"
              />
              <path
                d="M239.444 586.987C238.919 586.987 238.453 586.869 238.048 586.633C237.642 586.397 237.325 586.07 237.094 585.65C236.864 585.232 236.749 584.753 236.749 584.214C236.749 583.669 236.857 583.189 237.075 582.773C237.292 582.357 237.592 582.029 237.975 581.79C238.358 581.55 238.799 581.431 239.298 581.431C239.79 581.431 240.22 581.545 240.587 581.775C240.953 582.005 241.237 582.322 241.438 582.727C241.64 583.134 241.739 583.596 241.739 584.114C241.739 584.22 241.707 584.306 241.643 584.369C241.578 584.432 241.493 584.463 241.389 584.463H237.255V583.825H241.467L241.049 584.134C241.055 583.748 240.987 583.403 240.845 583.097C240.702 582.791 240.499 582.552 240.236 582.379C239.974 582.206 239.661 582.119 239.298 582.119C238.928 582.119 238.603 582.209 238.325 582.389C238.046 582.568 237.83 582.816 237.678 583.132C237.525 583.448 237.45 583.808 237.45 584.214C237.45 584.62 237.535 584.979 237.707 585.291C237.879 585.604 238.114 585.85 238.413 586.029C238.711 586.209 239.054 586.299 239.444 586.299C239.677 586.299 239.913 586.257 240.149 586.174C240.386 586.091 240.576 585.986 240.718 585.86C240.79 585.8 240.871 585.769 240.961 585.765C241.052 585.762 241.13 585.787 241.195 585.84C241.279 585.92 241.323 586.007 241.326 586.1C241.33 586.193 241.292 586.272 241.215 586.339C241 586.525 240.728 586.679 240.397 586.803C240.066 586.926 239.748 586.987 239.444 586.987Z"
                fill="white"
              />
              <path
                d="M244.045 586.943C243.786 586.943 243.553 586.869 243.35 586.723C243.146 586.576 242.986 586.374 242.873 586.115C242.759 585.855 242.702 585.556 242.702 585.217V579.531C242.702 579.418 242.737 579.327 242.805 579.257C242.873 579.187 242.962 579.152 243.072 579.152C243.183 579.152 243.272 579.187 243.34 579.257C243.408 579.327 243.442 579.418 243.442 579.531V585.217C243.442 585.503 243.498 585.736 243.612 585.915C243.725 586.095 243.87 586.184 244.045 586.184H244.288C244.386 586.184 244.463 586.22 244.522 586.289C244.58 586.359 244.609 586.451 244.609 586.563C244.609 586.676 244.569 586.768 244.488 586.838C244.407 586.907 244.301 586.943 244.172 586.943H244.045Z"
                fill="white"
              />
              <path
                d="M246.681 586.943C246.423 586.943 246.19 586.869 245.986 586.723C245.782 586.576 245.623 586.374 245.509 586.115C245.396 585.855 245.339 585.556 245.339 585.217V579.531C245.339 579.418 245.373 579.327 245.441 579.257C245.509 579.187 245.599 579.152 245.709 579.152C245.819 579.152 245.908 579.187 245.976 579.257C246.045 579.327 246.079 579.418 246.079 579.531V585.217C246.079 585.503 246.135 585.736 246.249 585.915C246.362 586.095 246.507 586.184 246.681 586.184H246.926C247.022 586.184 247.101 586.22 247.159 586.289C247.217 586.359 247.245 586.451 247.245 586.563C247.245 586.676 247.206 586.768 247.125 586.838C247.044 586.907 246.937 586.943 246.808 586.943H246.681Z"
                fill="white"
              />
              <path
                d="M248.705 586.943C248.588 586.943 248.496 586.907 248.428 586.838C248.36 586.768 248.325 586.673 248.325 586.553V581.875C248.325 581.755 248.36 581.661 248.428 581.591C248.496 581.521 248.588 581.486 248.705 581.486C248.816 581.486 248.904 581.521 248.973 581.591C249.04 581.661 249.074 581.755 249.074 581.875V586.553C249.074 586.673 249.04 586.768 248.973 586.838C248.904 586.907 248.816 586.943 248.705 586.943ZM248.695 580.449C248.553 580.449 248.429 580.396 248.325 580.29C248.222 580.183 248.171 580.053 248.171 579.9C248.171 579.734 248.223 579.602 248.331 579.506C248.438 579.41 248.563 579.362 248.705 579.362C248.841 579.362 248.962 579.41 249.07 579.506C249.177 579.602 249.231 579.734 249.231 579.9C249.231 580.053 249.178 580.183 249.074 580.29C248.971 580.396 248.844 580.449 248.695 580.449Z"
                fill="white"
              />
              <path
                d="M253.296 586.294C253.679 586.294 254.018 586.206 254.312 586.029C254.608 585.853 254.84 585.609 255.009 585.296C255.178 584.983 255.262 584.625 255.262 584.219C255.262 583.813 255.178 583.452 255.009 583.137C254.84 582.82 254.608 582.575 254.312 582.399C254.018 582.222 253.679 582.134 253.296 582.134C252.914 582.134 252.573 582.222 252.276 582.399C251.978 582.575 251.743 582.82 251.575 583.137C251.406 583.452 251.321 583.813 251.321 584.219C251.321 584.625 251.406 584.983 251.575 585.296C251.743 585.609 251.978 585.853 252.276 586.029C252.573 586.206 252.914 586.294 253.296 586.294ZM253.316 589.096C252.803 589.096 252.353 588.998 251.963 588.802C251.575 588.606 251.253 588.338 251.001 587.999C250.929 587.919 250.899 587.833 250.909 587.74C250.917 587.647 250.964 587.57 251.05 587.51C251.133 587.45 251.227 587.431 251.331 587.45C251.435 587.47 251.516 587.52 251.575 587.6C251.763 587.84 252.005 588.032 252.299 588.178C252.594 588.325 252.937 588.398 253.326 588.398C253.689 588.398 254.017 588.309 254.308 588.129C254.601 587.949 254.832 587.697 255.004 587.371C255.175 587.045 255.262 586.663 255.262 586.224V584.707L255.543 584.129L256.001 584.219V586.254C256.001 586.792 255.884 587.276 255.651 587.705C255.417 588.134 255.1 588.474 254.697 588.722C254.295 588.972 253.835 589.096 253.316 589.096ZM253.218 586.991C252.707 586.991 252.252 586.874 251.857 586.637C251.46 586.402 251.152 586.074 250.927 585.655C250.704 585.237 250.592 584.757 250.592 584.219C250.592 583.673 250.709 583.191 250.942 582.773C251.176 582.353 251.497 582.026 251.905 581.79C252.314 581.554 252.778 581.435 253.296 581.435C253.822 581.435 254.287 581.554 254.693 581.79C255.098 582.026 255.417 582.353 255.645 582.773C255.876 583.191 255.995 583.673 256.001 584.219L255.563 584.459C255.563 584.95 255.462 585.387 255.262 585.77C255.06 586.152 254.783 586.451 254.429 586.667C254.076 586.884 253.673 586.991 253.218 586.991Z"
                fill="white"
              />
              <path
                d="M259.766 586.987C259.241 586.987 258.776 586.869 258.369 586.633C257.964 586.397 257.648 586.07 257.416 585.65C257.187 585.232 257.071 584.753 257.071 584.214C257.071 583.669 257.18 583.189 257.396 582.773C257.613 582.357 257.915 582.029 258.297 581.79C258.679 581.55 259.121 581.431 259.62 581.431C260.113 581.431 260.542 581.545 260.909 581.775C261.276 582.005 261.56 582.322 261.76 582.727C261.962 583.134 262.061 583.596 262.061 584.114C262.061 584.22 262.029 584.306 261.965 584.369C261.899 584.432 261.816 584.463 261.712 584.463H257.578V583.825H261.79L261.371 584.134C261.378 583.748 261.31 583.403 261.168 583.097C261.024 582.791 260.822 582.552 260.559 582.379C260.296 582.206 259.984 582.119 259.62 582.119C259.251 582.119 258.925 582.209 258.647 582.389C258.368 582.568 258.152 582.816 258 583.132C257.848 583.448 257.771 583.808 257.771 584.214C257.771 584.62 257.858 584.979 258.028 585.291C258.202 585.604 258.436 585.85 258.735 586.029C259.033 586.209 259.377 586.299 259.766 586.299C259.999 586.299 260.234 586.257 260.471 586.174C260.707 586.091 260.898 585.986 261.041 585.86C261.112 585.8 261.193 585.769 261.284 585.765C261.374 585.762 261.452 585.787 261.517 585.84C261.601 585.92 261.645 586.007 261.648 586.1C261.652 586.193 261.615 586.272 261.537 586.339C261.322 586.525 261.051 586.679 260.72 586.803C260.389 586.926 260.07 586.987 259.766 586.987Z"
                fill="white"
              />
              <path
                d="M267.781 586.943C267.672 586.943 267.581 586.906 267.51 586.833C267.437 586.759 267.402 586.67 267.402 586.563V583.97C267.402 583.558 267.326 583.219 267.173 582.953C267.021 582.687 266.813 582.487 266.55 582.354C266.288 582.221 265.992 582.155 265.661 582.155C265.343 582.155 265.055 582.218 264.8 582.344C264.544 582.471 264.341 582.644 264.191 582.862C264.042 583.082 263.968 583.332 263.968 583.611H263.433C263.445 583.192 263.556 582.818 263.764 582.489C263.971 582.16 264.247 581.9 264.591 581.711C264.935 581.521 265.317 581.427 265.738 581.427C266.199 581.427 266.61 581.525 266.974 581.721C267.336 581.917 267.623 582.205 267.835 582.584C268.045 582.963 268.151 583.425 268.151 583.97V586.563C268.151 586.67 268.115 586.759 268.044 586.833C267.973 586.906 267.885 586.943 267.781 586.943ZM263.599 586.943C263.482 586.943 263.389 586.907 263.32 586.838C263.254 586.768 263.219 586.676 263.219 586.563V581.875C263.219 581.755 263.254 581.661 263.32 581.591C263.389 581.521 263.482 581.486 263.599 581.486C263.708 581.486 263.798 581.521 263.866 581.591C263.934 581.661 263.968 581.755 263.968 581.875V586.563C263.968 586.676 263.934 586.768 263.866 586.838C263.798 586.907 263.708 586.943 263.599 586.943Z"
                fill="white"
              />
              <path
                d="M272.073 586.987C271.56 586.987 271.105 586.866 270.706 586.623C270.308 586.381 269.992 586.049 269.762 585.63C269.532 585.211 269.417 584.739 269.417 584.214C269.417 583.682 269.528 583.207 269.748 582.788C269.968 582.369 270.269 582.038 270.653 581.795C271.035 581.553 271.476 581.431 271.976 581.431C272.371 581.431 272.735 581.511 273.07 581.671C273.404 581.83 273.697 582.069 273.95 582.389C274.022 582.468 274.048 582.553 274.028 582.643C274.008 582.732 273.953 582.811 273.863 582.877C273.791 582.931 273.713 582.949 273.624 582.932C273.536 582.915 273.461 582.867 273.396 582.788C273.019 582.348 272.546 582.129 271.976 582.129C271.612 582.129 271.292 582.219 271.017 582.399C270.741 582.578 270.527 582.824 270.374 583.136C270.222 583.449 270.147 583.808 270.147 584.214C270.147 584.613 270.228 584.969 270.39 585.281C270.552 585.594 270.778 585.84 271.071 586.019C271.362 586.199 271.696 586.289 272.073 586.289C272.326 586.289 272.557 586.254 272.768 586.184C272.979 586.114 273.165 586.007 273.327 585.86C273.406 585.794 273.487 585.757 273.57 585.75C273.654 585.744 273.732 585.77 273.804 585.83C273.882 585.903 273.926 585.986 273.936 586.079C273.946 586.173 273.914 586.252 273.843 586.319C273.37 586.764 272.779 586.987 272.073 586.987Z"
                fill="white"
              />
              <path
                d="M277.792 586.987C277.268 586.987 276.802 586.869 276.397 586.633C275.99 586.397 275.674 586.07 275.443 585.65C275.213 585.232 275.098 584.753 275.098 584.214C275.098 583.669 275.206 583.189 275.423 582.773C275.641 582.357 275.941 582.029 276.323 581.79C276.707 581.55 277.147 581.431 277.646 581.431C278.141 581.431 278.57 581.545 278.936 581.775C279.302 582.005 279.586 582.322 279.786 582.727C279.988 583.134 280.089 583.596 280.089 584.114C280.089 584.22 280.056 584.306 279.992 584.369C279.927 584.432 279.842 584.463 279.739 584.463H275.604V583.825H279.816L279.398 584.134C279.405 583.748 279.336 583.403 279.194 583.097C279.05 582.791 278.848 582.552 278.585 582.379C278.322 582.206 278.01 582.119 277.646 582.119C277.277 582.119 276.953 582.209 276.674 582.389C276.395 582.568 276.179 582.816 276.027 583.132C275.874 583.448 275.799 583.808 275.799 584.214C275.799 584.62 275.884 584.979 276.056 585.291C276.228 585.604 276.464 585.85 276.762 586.029C277.059 586.209 277.403 586.299 277.792 586.299C278.027 586.299 278.261 586.257 278.499 586.174C278.735 586.091 278.925 585.986 279.068 585.86C279.138 585.8 279.219 585.769 279.31 585.765C279.401 585.762 279.479 585.787 279.543 585.84C279.628 585.92 279.672 586.007 279.675 586.1C279.678 586.193 279.641 586.272 279.563 586.339C279.348 586.525 279.077 586.679 278.746 586.803C278.415 586.926 278.098 586.987 277.792 586.987Z"
                fill="white"
              />
              <path
                d="M104.15 542.837H110.332V570.612H127.325V576.085H104.15V542.837Z"
                fill="white"
              />
              <path
                d="M129.991 542.355H135.64V548.147H129.991V542.355ZM129.95 551.767H135.64V576.085H129.95V551.767Z"
                fill="white"
              />
            </g>
          </g>
        </svg>
      </Box>

      {/* Carousel */}
      <Stack
        display="flex"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <Box
          overflow="hidden"
          maxHeight="300px"
          maxWidth="700px"
          paddingInline="30px"
          ref={emblaRef}
        >
          <Stack
            sx={{
              backfaceVisibility: "hidden",
              touchAction: "pan-x",
              marginTop: "30px",
              height: "300px",
            }}
          >
            {slides.map((slide, index) => (
              <Stack
                display="flex"
                justifyContent="center"
                textAlign="left"
                key={index}
                minHeight="100%"
              >
                {slide.variant === "nested" ? (
                  slide.content.map((item, i) => (
                    <Typography
                      key={i}
                      variant={item.variant}
                      color="black"
                      sx={item.style}
                    >
                      {item.content}
                    </Typography>
                  ))
                ) : (
                  <Typography
                    variant={slide.variant}
                    color="black"
                    sx={slide.style}
                  >
                    {slide.content}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="6px"
        >
          {scrollSnaps.map((_, index) => (
            <button
              type="button"
              key={index}
              onClick={() => onDotButtonClick(index)}
              disabled
              style={{
                touchAction: "manipulation",
                display: "inline-flex",
                border: "none",
                padding: 0,
                width: index === selectedIndex ? "12px" : "10px",
                height: "6px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                background: index === selectedIndex ? " #1d47ce" : "#cccccc",
              }}
            ></button>
          ))}
        </Box>
      </Stack>
    </>
  )
}

export default OnboardingSteps
