import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Rating from "@mui/material/Rating"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Facebook from "@mui/icons-material/Facebook"
import Instagram from "@mui/icons-material/Instagram"
import XIcon from "@mui/icons-material/X"
import {
  PieChartData,
  PieChartParameters,
  RadarChartData,
  RadarChartParameters,
  SentimentReviewsList,
  SentimentReviewsParameters,
} from "../../constants/chartsData"
import RouteTitle from "../../components/RouteTitle"
import { setChartData } from "../../redux/slices/inventorySlice"
import DatePicker from "../../components/DatePicker"
import RatingCard from "./customerSenti/RatingCard"
import NivoPieChart from "../../components/charts/pie/NivoPieChart"
import { useSelector } from "react-redux"
import NivoRadarChart from "../../components/charts/radar/NivoRadarChart"
import CustomLegend from "../../components/CustomLegend"
import Table from "../../components/datagrid/Table"
import CustomPaper from "../../components/CustomPaper"
import { useTheme } from "../../contexts/theme"
import CompareBy from "../../components/CompareBy"
import WordCloudChart from "../../components/charts/wordCloud/WordCloudChart"
import StackedBarChart from "../../components/charts/stackedBar/StackedBarChart"

const PlatformReviews = ({ icon, name, percentages }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        alignItems="center"
        minWidth="125px"
      >
        {icon}
        <Typography variant="body2" color="inherit">
          {name}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        sx={{ borderRadius: "8px" }}
      >
        {percentages.map((data, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            flex={data.value / 100}
            bgcolor={data.color}
            sx={{
              borderRadius:
                index === 0
                  ? "8px 0px 0px 8px"
                  : index === percentages.length - 1
                  ? "0px 8px 8px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Typography color="inherit">{data.value}%</Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  )
}

const CustomerSentiment = () => {
  const platforms = [
    {
      name: "Facebook",
      icon: <Facebook />,
      percentages: [
        { value: 70, color: "#50CD89" },
        { value: 20, color: "#FEBE10" },
        { value: 10, color: "#EE000C" },
      ],
    },
    {
      name: "Instagram",
      icon: <Instagram />,
      percentages: [
        { value: 80, color: "#50CD89" },
        { value: 15, color: "#FEBE10" },
        { value: 5, color: "#EE000C" },
      ],
    },
    {
      name: "Twitter",
      icon: <XIcon />,
      percentages: [
        { value: 40, color: "#50CD89" },
        { value: 50, color: "#FEBE10" },
        { value: 20, color: "#EE000C" },
      ],
    },
  ]

  const { theme } = useTheme()
  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  return (
    <>
      <RouteTitle title={`Sentiments Dashboard`}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="auto"
          gap={2}
        >
          <CompareBy dateRangeData={dateRangeData} />
          <DatePicker />
        </Box>
      </RouteTitle>
      <Stack padding="15px 30px 15px 30px" gap={2}>
        <Box>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomPaper customStyles={{ height: "100%" }}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>OverAll Rating</Typography>
                    <Box display="flex" alignItems="right">
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "33px",
                          marginRight: "8px",
                          fontWeight: "bold",
                          color: "inherit",
                        }}
                      >
                        4.3
                      </Typography>
                      <Rating
                        value={4.3}
                        precision={0.1}
                        readOnly
                        size="large"
                        sx={{
                          "& .MuiRating-iconFilled": {
                            color: "#FFD700",
                          },
                          "& .MuiRating-iconEmpty": {
                            color: "#1B2A49",
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  <Stack gap={2}>
                    <CustomPaper
                      customStyles={{
                        background:
                          theme.palette.mode === "dark"
                            ? "#071339"
                            : "#1b4cf00a",
                      }}
                    >
                      <RatingCard />
                    </CustomPaper>
                  </Stack>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPaper customStyles={{ height: "100%" }}>
                  <Stack display="flex" flexDirection="column" gap={2}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography color="inherit">Social Media</Typography>
                      <CustomLegend
                        legendsData={[
                          { color: "#50CD89", label: "Positive" },
                          { color: "#FEBE10", label: "Normal" },
                          { color: "#EE000C", label: "Negative" },
                        ]}
                      />
                    </Box>
                    <Stack display="flex" flexDirection="column" gap={2}>
                      {platforms.map((data, index) => (
                        <PlatformReviews key={index} {...data} />
                      ))}
                    </Stack>
                  </Stack>
                </CustomPaper>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <Box>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    height: "100%",
                    background:
                      theme.palette.mode === "dark" ? "#071339" : "#1b4cf00a",
                    overflow: "visible",
                  }}
                >
                  <StackedBarChart
                    route="customersentiment"
                    showLegend={true}
                    setChartData={setChartData}
                    comparisonPeriod={comparisonPeriod}
                    title={"Age Analysis"}
                    description={"Age Analysis"}
                    postUrl="customerSentiment/data"
                    reportUid={"static-StackedBarchart"}
                    parameters={[]}
                  />
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="row" gap={2}>
                  <Grid item xs={12} md={6}>
                    <CustomPaper customStyles={{ height: "100%" }}>
                      <Typography>Specification</Typography>
                      <NivoRadarChart
                        chartInput={RadarChartData}
                        chartConfig={RadarChartParameters}
                      />
                    </CustomPaper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomPaper
                      customStyles={{ height: "100%", overflow: "visible" }}
                    >
                      <Typography>Review Classification</Typography>
                      <NivoPieChart
                        chartInput={PieChartData}
                        chartConfig={PieChartParameters}
                      />
                    </CustomPaper>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <Box>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomPaper customStyles={{ height: "100%" }}>
                  <Typography>What People are talking</Typography>
                  <WordCloudChart />
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{ height: "100%", overflow: "visible" }}
                >
                  <StackedBarChart
                    route="customersentiment"
                    showLegend={true}
                    setChartData={setChartData}
                    comparisonPeriod={comparisonPeriod}
                    title={"Timeline"}
                    description={"Timeline"}
                    postUrl="customerSentiment/data"
                    reportUid={"static-StackedBarchart"}
                    parameters={[]}
                  />
                </CustomPaper>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <Box>
          <Stack gap={2}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={6}></Grid> */}
              <Grid item xs={12} md={6}>
                <Table
                  route="Customer"
                  title="Latest Reviews"
                  showExportButton={false}
                  hidePageNumbers={true}
                  reportUid={"static-List"}
                  postUrl="Customer/list"
                  StaticData={SentimentReviewsList}
                  StaticParameters={SentimentReviewsParameters}
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Stack>
    </>
  )
}

export default CustomerSentiment
