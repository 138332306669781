import { useState, useEffect, useRef, useMemo } from "react"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid"
import CustomPaper from "../CustomPaper"
import Toolbar from "./Toolbar"
import Footer from "./Footer"
import AscendingIcon from "../../assets/svgs/AscendingIcon"
import DescendingIcon from "../../assets/svgs/DescendingIcon"
import UnsortedIcon from "../../assets/svgs/UnsortedIcon"
import CustomPagination from "./CustomPagination"
import { postData } from "../../api/api"
import { useTheme } from "../../contexts/theme"
import Loader from "./Loader"
import { useDispatch } from "react-redux"
import { ListData, ListParameters } from "../../constants/chartsData.js"
import { getFormattedValue } from "../../utils/utils.js"

export default function Table({
  route,
  setChartData,
  title = "Table title",
  showButton,
  showExportButton,
  showDropdown,
  dropdownOptions,
  buttonName,
  parameters,
  reportUid,
  postUrl,
  hidePageNumbers = false,
  onRowClick,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  showFiltersButton = false,
  StaticData = ListData,
  StaticParameters = ListParameters,
  checkboxSelection = false,
  selectedRows,
  setSelectedRows,
  showFooter = true,
}) {
  const extractColumns = (parameters) => {
    if (!parameters) return null
    let columnString = parameters[0].reportParameters[0].parameterValue
    return eval(columnString)
  }

  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [tableData, setTableData] = useState([])
  const [searchTrigger, setSearchTrigger] = useState(false)
  const [transformedRows, setTransformedRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })
  const apiRef = useGridApiRef()

  const fetchData = async () => {
    let response
    try {
      if (!postUrl) {
        console.error("postUrl is undefined")
        setLoading(false)
        throw new Error("postUrl is undefined")
      }
      setLoading(true)

      if (reportUid === "static-List") {
        response = false
      } else if (route === "subscription/invoices") {
        response = await postData(postUrl, {
          entityId: localStorage.getItem("entityId"),
        })
      } else {
        response = await postData(postUrl, {
          entityDb: localStorage.getItem("entityDb"),
          fromDate: dateRangeData.fromDate,
          toDate: dateRangeData.toDate,
          pageSize: paginationModel.pageSize,
          pageNumber: paginationModel.page + 1,
          searchText: searchTerm,
          reportInstanceId: reportUid,
        })
      }

      if (setChartData) {
        dispatch(
          setChartData({
            reportUid,
            data:
              route === "subscription/invoices"
                ? response.result
                : response?.[0]?.data || StaticData.data,
          })
        )
      }

      setTableData(
        route === "subscription/invoices" ? response.result : response || []
      )
    } catch (error) {
      console.log("Error fetching data:", error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportUid, postUrl, searchTrigger, paginationModel, dateRangeData])
  useEffect(() => {
    const rows =
      route === "subscription/invoices"
        ? tableData
        : tableData[0]?.data || (loading ? [] : StaticData.data)
    const transformRows = async (rows) => {
      const { page, pageSize } = paginationModel
      let transformed = await Promise.all(
        rows.map(async (item, index) => {
          let newItem = { ...item }
          newItem.serialNumber = page * pageSize + index + 1
          for (let key in item) {
            if (typeof item[key] === "number") {
              newItem[key] = getFormattedValue(item[key], "Number")
            } else {
              newItem[key] = item[key]
            }
          }
          return newItem
        })
      )
      setTransformedRows(transformed)
    }
    transformRows(rows)
  }, [tableData, loading, StaticData.data, paginationModel])

  const rowCountRef = useRef(tableData[0]?.totalRows || 0)

  const rowCount = useMemo(() => {
    rowCountRef.current =
      tableData[0]?.totalRows !== undefined ? tableData[0]?.totalRows : 10
    return rowCountRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData[0]?.totalRows])

  let columns = [
    {
      field: "serialNumber",
      headerName: "S.No",
      headerClassName: "datagrid-header",
      width: 70,
    },
    ...(extractColumns(parameters) || StaticParameters.columns),
  ]

  function getRowId(row) {
    return row.id ? `${row.id}` : `${row[columns[1].field]}-${Math.random()}`
  }

  const handleSelectionChange = () => {
    let selectedRows = apiRef.current.getSelectedRows()
    selectedRows = Array.from(selectedRows.values())

    let selectedEmailAddresses = selectedRows.map((row) => row.EmailAddress)

    if (setSelectedRows) {
      setSelectedRows(selectedEmailAddresses)
      console.log(selectedEmailAddresses)
    } else {
      console.log(selectedEmailAddresses)
    }
  }

  return (
    <CustomPaper
      customStyles={{
        padding: 0,
        height: "auto",
      }}
    >
      <DataGrid
        apiRef={apiRef}
        onRowSelectionModelChange={(_newRowSelectionModel) => {
          handleSelectionChange()
        }}
        checkboxSelection={checkboxSelection}
        keepNonExistentRowsSelected={checkboxSelection}
        getRowId={getRowId}
        rows={transformedRows || StaticData.data}
        rowCount={rowCount}
        columns={columns}
        autoHeight={true}
        initialState={{
          ...columns,
          pagination: {
            paginationModel: paginationModel.pageSize,
          },
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        pageSizeOptions={[10, 20, 30]}
        disableRowSelectionOnClick
        disableColumnMenu
        rowHeight={70}
        loading={loading}
        onRowClick={onRowClick}
        slots={{
          toolbar: Toolbar,
          pagination: CustomPagination,
          footer: Footer,
          columnSortedAscendingIcon: AscendingIcon,
          columnSortedDescendingIcon: DescendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          loadingOverlay: Loader,
        }}
        slotProps={{
          loadingOverlay: {
            color: "info",
          },
          toolbar: {
            title,
            searchTerm,
            setSearchTerm,
            setSearchTrigger,
            showButton,
            showDropdown,
            showExportButton,
            dropdownOptions,
            buttonName,
            columns,
            route,
            showFiltersButton,
            entityDb: localStorage.getItem("entityDb"),
            entityId: localStorage.getItem("entityId"),
            fromDate: dateRangeData.fromDate,
            toDate: dateRangeData.toDate,
            pageSize: tableData[0]?.totalRows,
            searchText: searchTerm,
            reportInstanceId: reportUid,
            postUrl: postUrl,
            loading: loading,
          },
          pagination: {
            labelRowsPerPage: "Items Per Page",
          },
          footer: {
            palette: theme.palette,
            hidePageNumbers: hidePageNumbers,
          },
        }}
        sx={{
          // "& .MuiDataGrid-row": {
          //   display: "flex",
          //   flexDirection: "row",
          //   alignItems: "center",
          //   maxHeight: "50px",
          //   minHeight: "50px",
          //   height: "50px",
          // },
          border: "none",
          boxShadow: "none",
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            backgroundColor: theme.palette.background.paper,
            borderColor: "#ABABAB30",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            px: "1.2rem",
            fontWeight: "fontWeightSemiBold",
            borderColor: "#ABABAB30",
          },
          "& .MuiDataGrid-withBorderColor": {
            borderColor: "#ABABAB30 !important",
          },
          ".MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
          },
          ".MuiDataGrid-sortIcon": {
            opacity: "inherit !important",
          },
          ".MuiCheckbox-root.Mui-checked": {
            color: theme.palette.accent,
          },
          ".MuiCheckbox-root": {
            color: theme.palette.accent,
          },
          ".datagrid-header": {
            px: "1.2rem",
            backgroundColor: theme.palette.background.paper,
            borderColor: "#ABABAB30 !important",
            fontSize: "0.8rem",
            color: theme.palette.mode === "dark" ? "#FFFFFFA0" : "#000000A0",
          },
          ".search_input:hover": {
            border: "1px solid #f3884a !important",
          },
        }}
      />
    </CustomPaper>
  )
}
