import { useState } from "react"
import Button from "@mui/material/Button"
import { useTheme } from "../../../../contexts/theme"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Link from "@mui/material/Link"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import CloseIcon from "@mui/icons-material/Close"

const Insights = ({ count = null }) => {
  const { theme } = useTheme()
  const svgStrokeColor =
    theme.palette.mode === "light" ? theme.palette.accent : "white"

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  return (
    <>
      <Button
        size="large"
        aria-label="multiple tools"
        color="inherit"
        onClick={handleDrawerOpen}
      >
        <Badge badgeContent={count} color="error">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M3.33301 22H21.333"
              stroke={svgStrokeColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.93299 8.37891H4.33301C3.78301 8.37891 3.33301 8.82891 3.33301 9.37891V17.9989C3.33301 18.5489 3.78301 18.9989 4.33301 18.9989H5.93299C6.48299 18.9989 6.93299 18.5489 6.93299 17.9989V9.37891C6.93299 8.82891 6.48299 8.37891 5.93299 8.37891Z"
              stroke={svgStrokeColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.1332 5.19141H11.5332C10.9832 5.19141 10.5332 5.64141 10.5332 6.19141V18.0014C10.5332 18.5514 10.9832 19.0014 11.5332 19.0014H13.1332C13.6832 19.0014 14.1332 18.5514 14.1332 18.0014V6.19141C14.1332 5.64141 13.6832 5.19141 13.1332 5.19141Z"
              stroke={svgStrokeColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.3334 2H18.7334C18.1834 2 17.7334 2.45 17.7334 3V18C17.7334 18.55 18.1834 19 18.7334 19H20.3334C20.8834 19 21.3334 18.55 21.3334 18V3C21.3334 2.45 20.8834 2 20.3334 2Z"
              stroke={svgStrokeColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Badge>
      </Button>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: {
            padding: "1.2rem",
            borderRadius: "10px 0px 0px 10px",
            backgroundColor:
              theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
            backgroundImage: "unset",
            width: "450px",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="h5" color="inherit" paddingY="1.2rem">
            AI-Recommendation
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon color={svgStrokeColor} />
          </IconButton>
        </Box>
        {/* <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              gap: 1,
            }}
          >
            <Typography variant="h4">Coming soon..</Typography>
            <Logo
              fill={theme.palette.text.primary}
              yfill={
                theme.palette.mode === "light" ? theme.palette.accent : "white"
              }
            />
          </Box>
        </Box> */}
        <Stack rowGap="20px">
          <Alert
            icon={false}
            severity="error"
            sx={{
              borderLeft: "4px solid #EE000CCC",
              borderRadius: "10px",
            }}
          >
            Two employee schedules overlap. Please review and adjust the shifts
            to avoid conflicts.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="error"
            sx={{
              borderLeft: "4px solid #EE000CCC",
              borderRadius: "10px",
            }}
          >
            An employee is approaching their maximum allowable working hours.
            Ensure compliance with labor laws.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="warning"
            sx={{
              borderLeft: "4px solid #FFBF00",
              borderRadius: "10px",
            }}
          >
            An employee is late for their shift. Send a reminder or find a
            replacement.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="warning"
            sx={{
              borderLeft: "4px solid #FFBF00",
              borderRadius: "10px",
            }}
          >
            Negative feedback has been received from a customer. Address the
            issue promptly
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="success"
            sx={{
              borderLeft: "4px solid #32CD32",
              borderRadius: "10px",
            }}
          >
            A customer is exhibiting unusual behaviour or dissatisfaction.
            Notify staff to provide better service
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="success"
            sx={{
              borderLeft: "4px solid #32CD32",
              borderRadius: "10px",
            }}
          >
            There are pending orders that have not been processed. Ensure prompt
            handling to avoid delays.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
        </Stack>
      </Drawer>
    </>
  )
}

export default Insights
