import { useState } from "react"
import Table from "../../../../datagrid/Table"
import {
  TargetedCustomersData,
  TargetedCustomersParameters,
} from "../../../../../constants/chartsData"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Modal from "@mui/material/Modal"
import CustomPaper from "../../../../CustomPaper"
import { useTheme } from "../../../../../contexts/theme"
import CloseIconSubscription from "../../../../../assets/svgs/CloseIconSubscription"
import { useSelector } from "react-redux"
import CustomBreadcrumbs from "../../../../CustomBreadcrumbs"
import { getFormattedValue } from "../../../../../utils/utils"

const InvoicesTable = () => {
  const { theme } = useTheme()
  const dateRangeData = useSelector((state) => state.calendar)
  const [selectedRows, setSelectedRows] = useState([])
  const [open, setOpen] = useState(false)
  const svgColor = theme.palette.accent

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        padding="30px 30px 15px 30px"
      >
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "All Invoices",
            },
          ]}
        />
      </Box>
      <Box padding="15px 30px 30px 30px">
        <Table
          title="View Invoices"
          showFiltersButton={true}
          showExportButton={true}
          dateRangeData={dateRangeData}
          postUrl="subscription/invoices"
          parameters={[
            {
              parameterType: "COLUMNS",
              reportParameters: [
                {
                  parameterName: "columns",
                  parameterValue: [
                    {
                      field: "InvoiceID",
                      headerName: "Invoice ID",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Date",
                      headerName: "Date",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "TotalAmount",
                      headerName: "Total Amount",
                      flex: 1,
                      minWidth: 100,
                      headerClassName: "datagrid-header",
                      renderCell: (params) => (
                        <Box
                          display="flex"
                          justifyContent="center"
                          sx={{ color: "#50DC89" }}
                        >
                          {getFormattedValue(params.value)}
                        </Box>
                      ),
                    },
                    {
                      field: "PaymentStatus",
                      headerName: "Payment Status",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                  ],
                },
              ],
            },
          ]}
          onRowClick={() => {
            handleOpen()
          }}
          route="subscription/invoices"
        />
      </Box>

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          },
        }}
      >
        <CustomPaper
          customStyles={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 800,
              sm: 800,
              md: 800,
              lg: 800,
              xl: 1200,
            },
            height: {
              xs: 900,
              sm: 900,
              md: 850,
              lg: 790,
              xl: 760,
            },
            minHeight: "auto",
            minWidth: "65vw",
            overflowY: "auto",
            background:
              theme.palette.mode === "dark"
                ? "var(--grade, linear-gradient(76deg, #153393 1.45%, #0D2266 95.77%))"
                : "#ffffff",
            boxShadow: "none",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            mb={1}
          >
            <Typography
              color="inherit"
              sx={{ fontSize: "20px", fontWeight: "700" }}
            >
              Invoices Details
            </Typography>

            <IconButton onClick={handleClose}>
              <CloseIconSubscription svgColor={svgColor} />
            </IconButton>
          </Box>{" "}
        </CustomPaper>
      </Modal> */}
    </>
  )
}

export default InvoicesTable
