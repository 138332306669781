import { useTheme } from "../../contexts/theme"

const UnsortedIcon = ({ svgStrokeColor }) => {
  const { theme } = useTheme()

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 10.6641H12" stroke={svgStrokeColor || theme.palette.accent} />
      <path
        opacity="0.7"
        d="M4 7.33594H12"
        stroke={svgStrokeColor || theme.palette.accent}
      />
      <path
        opacity="0.3"
        d="M4 4H12"
        stroke={svgStrokeColor || theme.palette.accent}
      />
    </svg>
  )
}

export default UnsortedIcon
