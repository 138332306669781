import RouteTitle from "../../components/RouteTitle"
import Table from "../../components/datagrid/Table"
import Box from "@mui/material/Box"
import DatePicker from "../../components/DatePicker"
import { useSelector } from "react-redux"
import { getFormattedValue } from "../../utils/utils"

const InventoryList = () => {
  const dateRangeData = useSelector((state) => state.calendar)

  const filtersData = useSelector((state) => {
    return state.filters.inventoryList
  })

  return (
    <>
      <RouteTitle title={`Products ${filtersData?.filtersString}`}>
        <DatePicker />
      </RouteTitle>
      <Box padding="15px 30px 30px 30px">
        <Table
          title="Products"
          dateRangeData={dateRangeData}
          reportUid="24243c8d-dbad-4c89-bd0f-c4f0d0413f51"
          postUrl="inventory/list"
          parameters={[
            {
              parameterType: "COLUMNS",
              reportParameters: [
                {
                  parameterName: "columns",
                  parameterValue: [
                    {
                      field: "ID",
                      headerName: "ID",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Brand",
                      headerName: "Brand",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Category",
                      headerName: "Category",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Department",
                      headerName: "Department",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Product",
                      headerName: "Product",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "TotalSalesAmount",
                      headerName: "Total Sales Amount",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                      renderCell: (params) => (
                        <div
                          style={{
                            color: "#50DC89",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          {getFormattedValue(params.value)}
                        </div>
                      ),
                    },
                    {
                      field: "TotalSalesQuantity",
                      headerName: "Total Sales Quantity",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                      renderCell: (params) => (
                        <Box display="flex" justifyContent="center">
                          {getFormattedValue(params.value)}
                        </Box>
                      ),
                    },
                  ],
                },
              ],
            },
          ]}
          route="inventoryList"
          showExportButton={true}
          showFiltersButton={true}
        />
      </Box>
    </>
  )
}

export default InventoryList
