import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./index.css"
import { ThemeContextProvider } from "./contexts/theme"
import { Provider } from "react-redux"
import store from "./redux/store.js"
import reportWebVitals from "./reportWebVitals"
import routes from "./Routes.jsx"

const router = createBrowserRouter(routes)

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ThemeContextProvider>
      <RouterProvider router={router} />
    </ThemeContextProvider>
  </Provider>
)

reportWebVitals()
