export const GroupEdData = [
  {
    day: "Sunday",
    tooltipFields: [
      {
        reportType: "GroupBar",
        currentDate: "08-04-2024",
        day: "Sunday",
        previousDate: "07-07-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 127,
              format: "Number",
            },
            Discounts: {
              value: 23.14,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3773.12,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 149,
              format: "Number",
            },
            Discounts: {
              value: 55.6,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4715.62,
              format: "Currency",
            },
            Returns: {
              value: 5.03,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-11-2024",
        day: "Sunday",
        previousDate: "07-14-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 115,
              format: "Number",
            },
            Discounts: {
              value: 64.64,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3534.91,
              format: "Currency",
            },
            Returns: {
              value: 8.16,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 108,
              format: "Number",
            },
            Discounts: {
              value: 27.16,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3412.24,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-18-2024",
        day: "Sunday",
        previousDate: "07-21-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 133,
              format: "Number",
            },
            Discounts: {
              value: 103.35,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4795.91,
              format: "Currency",
            },
            Returns: {
              value: 11,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 107,
              format: "Number",
            },
            Discounts: {
              value: 57,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3763.96,
              format: "Currency",
            },
            Returns: {
              value: 16.02,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-25-2024",
        day: "Sunday",
        previousDate: "07-28-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 116,
              format: "Number",
            },
            Discounts: {
              value: 34.71,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4175.49,
              format: "Currency",
            },
            Returns: {
              value: 19,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 96,
              format: "Number",
            },
            Discounts: {
              value: 163.11,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4457.98,
              format: "Currency",
            },
            Returns: {
              value: 5.45,
              format: "Number",
            },
          },
        },
      },
    ],
    a: 3773.12,
    b: 3534.91,
    c: 4795.91,
    d: 4175.49,
  },
  {
    day: "Monday",
    tooltipFields: [
      {
        reportType: "GroupBar",
        currentDate: "08-05-2024",
        day: "Monday",
        previousDate: "07-01-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 77,
              format: "Number",
            },
            Discounts: {
              value: 74.26,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2973.24,
              format: "Currency",
            },
            Returns: {
              value: 4.87,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 74,
              format: "Number",
            },
            Discounts: {
              value: 35.88,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2833.99,
              format: "Currency",
            },
            Returns: {
              value: 6.86,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-12-2024",
        day: "Monday",
        previousDate: "07-08-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 81,
              format: "Number",
            },
            Discounts: {
              value: 39.78,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3417.72,
              format: "Currency",
            },
            Returns: {
              value: 5.87,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 0,
              format: "Number",
            },
            Discounts: {
              value: 0,
              format: "Currency",
            },
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-19-2024",
        day: "Monday",
        previousDate: "07-15-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 76,
              format: "Number",
            },
            Discounts: {
              value: 19.48,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2375.58,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 83,
              format: "Number",
            },
            Discounts: {
              value: 18.46,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2718.32,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-26-2024",
        day: "Monday",
        previousDate: "07-22-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 83,
              format: "Number",
            },
            Discounts: {
              value: 63.57,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2718.7,
              format: "Currency",
            },
            Returns: {
              value: 1.46,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 83,
              format: "Number",
            },
            Discounts: {
              value: 11.3,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2797.87,
              format: "Currency",
            },
            Returns: {
              value: 9.6,
              format: "Number",
            },
          },
        },
      },
    ],
    a: 2973.24,
    b: 3417.72,
    c: 2375.58,
    d: 2718.7,
  },
  {
    day: "Tuesday",
    tooltipFields: [
      {
        reportType: "GroupBar",
        currentDate: "08-06-2024",
        day: "Tuesday",
        previousDate: "07-02-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 81,
              format: "Number",
            },
            Discounts: {
              value: 12.37,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2325.96,
              format: "Currency",
            },
            Returns: {
              value: 1.8,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 77,
              format: "Number",
            },
            Discounts: {
              value: 3.9,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2405.34,
              format: "Currency",
            },
            Returns: {
              value: 2.6,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-13-2024",
        day: "Tuesday",
        previousDate: "07-09-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 91,
              format: "Number",
            },
            Discounts: {
              value: 2.58,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3443.92,
              format: "Currency",
            },
            Returns: {
              value: 10.93,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 0,
              format: "Number",
            },
            Discounts: {
              value: 0,
              format: "Currency",
            },
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-20-2024",
        day: "Tuesday",
        previousDate: "07-16-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 78,
              format: "Number",
            },
            Discounts: {
              value: 19.95,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2664.94,
              format: "Currency",
            },
            Returns: {
              value: 2.75,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 91,
              format: "Number",
            },
            Discounts: {
              value: 3.04,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3471.69,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-27-2024",
        day: "Tuesday",
        previousDate: "07-23-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 80,
              format: "Number",
            },
            Discounts: {
              value: 17.18,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2485.69,
              format: "Currency",
            },
            Returns: {
              value: 1.54,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 78,
              format: "Number",
            },
            Discounts: {
              value: 1.79,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2854.63,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
    ],
    a: 2325.96,
    b: 3443.92,
    c: 2664.94,
    d: 2485.69,
  },
  {
    day: "Wednesday",
    tooltipFields: [
      {
        reportType: "GroupBar",
        currentDate: "08-07-2024",
        day: "Wednesday",
        previousDate: "07-03-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 100,
              format: "Number",
            },
            Discounts: {
              value: 233.82,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3768.57,
              format: "Currency",
            },
            Returns: {
              value: 7.05,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 111,
              format: "Number",
            },
            Discounts: {
              value: 3.87,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3740.12,
              format: "Currency",
            },
            Returns: {
              value: 3,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-14-2024",
        day: "Wednesday",
        previousDate: "07-10-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 98,
              format: "Number",
            },
            Discounts: {
              value: 321.6,
              format: "Currency",
            },
            "Gross Sales": {
              value: 5222.11,
              format: "Currency",
            },
            Returns: {
              value: 21.09,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 19,
              format: "Number",
            },
            Discounts: {
              value: 10.76,
              format: "Currency",
            },
            "Gross Sales": {
              value: 333.37,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-21-2024",
        day: "Wednesday",
        previousDate: "07-17-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 92,
              format: "Number",
            },
            Discounts: {
              value: 230.44,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3953.26,
              format: "Currency",
            },
            Returns: {
              value: 33.1,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 84,
              format: "Number",
            },
            Discounts: {
              value: 319.33,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3920.59,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-28-2024",
        day: "Wednesday",
        previousDate: "07-24-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 68,
              format: "Number",
            },
            Discounts: {
              value: 247.86,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3342.88,
              format: "Currency",
            },
            Returns: {
              value: 16.6,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 93,
              format: "Number",
            },
            Discounts: {
              value: 142.18,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3643.13,
              format: "Currency",
            },
            Returns: {
              value: 8.69,
              format: "Number",
            },
          },
        },
      },
    ],
    a: 3768.57,
    b: 5222.11,
    c: 3953.26,
    d: 3342.88,
  },
  {
    day: "Thursday",
    tooltipFields: [
      {
        reportType: "GroupBar",
        currentDate: "08-01-2024",
        day: "Thursday",
        previousDate: "07-04-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 89,
              format: "Number",
            },
            Discounts: {
              value: 72.45,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3283.68,
              format: "Currency",
            },
            Returns: {
              value: 44,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 99,
              format: "Number",
            },
            Discounts: {
              value: 115.22,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4188.27,
              format: "Currency",
            },
            Returns: {
              value: 1.43,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-08-2024",
        day: "Thursday",
        previousDate: "07-11-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 84,
              format: "Number",
            },
            Discounts: {
              value: 17.86,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3047.64,
              format: "Currency",
            },
            Returns: {
              value: 5,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 71,
              format: "Number",
            },
            Discounts: {
              value: 379,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4238.91,
              format: "Currency",
            },
            Returns: {
              value: 14.86,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-15-2024",
        day: "Thursday",
        previousDate: "07-18-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 84,
              format: "Number",
            },
            Discounts: {
              value: 47.25,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3807.56,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 102,
              format: "Number",
            },
            Discounts: {
              value: 35.08,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3796.93,
              format: "Currency",
            },
            Returns: {
              value: 2.2,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-22-2024",
        day: "Thursday",
        previousDate: "07-25-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 97,
              format: "Number",
            },
            Discounts: {
              value: 86.62,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3804.29,
              format: "Currency",
            },
            Returns: {
              value: 2.4,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 72,
              format: "Number",
            },
            Discounts: {
              value: 43.11,
              format: "Currency",
            },
            "Gross Sales": {
              value: 2455.66,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-29-2024",
        day: "Thursday",
        previousDate: null,
        tooltipData: {
          current: {
            Customers: {
              value: 101,
              format: "Number",
            },
            Discounts: {
              value: 39.85,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3300.89,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
    ],
    a: 3283.68,
    b: 3047.64,
    c: 3807.56,
    d: 3804.29,
    e: 3300.89,
  },
  {
    day: "Friday",
    tooltipFields: [
      {
        reportType: "GroupBar",
        currentDate: "08-02-2024",
        day: "Friday",
        previousDate: "07-05-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 94,
              format: "Number",
            },
            Discounts: {
              value: 47.41,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3321.99,
              format: "Currency",
            },
            Returns: {
              value: 11,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 94,
              format: "Number",
            },
            Discounts: {
              value: 55.33,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3676.93,
              format: "Currency",
            },
            Returns: {
              value: 7.4,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-09-2024",
        day: "Friday",
        previousDate: "07-12-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 97,
              format: "Number",
            },
            Discounts: {
              value: 78,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3449.76,
              format: "Currency",
            },
            Returns: {
              value: 1.39,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 89,
              format: "Number",
            },
            Discounts: {
              value: 37.9,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3275.42,
              format: "Currency",
            },
            Returns: {
              value: 47.49,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-16-2024",
        day: "Friday",
        previousDate: "07-19-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 85,
              format: "Number",
            },
            Discounts: {
              value: 25.91,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3173.44,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 100,
              format: "Number",
            },
            Discounts: {
              value: 96.71,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3922.97,
              format: "Currency",
            },
            Returns: {
              value: 0.63,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-23-2024",
        day: "Friday",
        previousDate: "07-26-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 106,
              format: "Number",
            },
            Discounts: {
              value: 32.81,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4092.72,
              format: "Currency",
            },
            Returns: {
              value: 20.3,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 108,
              format: "Number",
            },
            Discounts: {
              value: 87.17,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3815.25,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-30-2024",
        day: "Friday",
        previousDate: null,
        tooltipData: {
          current: {
            Customers: {
              value: 115,
              format: "Number",
            },
            Discounts: {
              value: 72.42,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4842.62,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
    ],
    a: 3321.99,
    b: 3449.76,
    c: 3173.44,
    d: 4092.72,
    e: 4842.62,
  },
  {
    day: "Saturday",
    tooltipFields: [
      {
        reportType: "GroupBar",
        currentDate: "08-03-2024",
        day: "Saturday",
        previousDate: "07-06-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 131,
              format: "Number",
            },
            Discounts: {
              value: 34.28,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4900.36,
              format: "Currency",
            },
            Returns: {
              value: 23.5,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 112,
              format: "Number",
            },
            Discounts: {
              value: 25.02,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3900.37,
              format: "Currency",
            },
            Returns: {
              value: 3.06,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-10-2024",
        day: "Saturday",
        previousDate: "07-13-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 130,
              format: "Number",
            },
            Discounts: {
              value: 44.26,
              format: "Currency",
            },
            "Gross Sales": {
              value: 5123.1,
              format: "Currency",
            },
            Returns: {
              value: 6.55,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 109,
              format: "Number",
            },
            Discounts: {
              value: 45.03,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4077.01,
              format: "Currency",
            },
            Returns: {
              value: 4,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-17-2024",
        day: "Saturday",
        previousDate: "07-20-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 106,
              format: "Number",
            },
            Discounts: {
              value: 91.12,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3562.6,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 100,
              format: "Number",
            },
            Discounts: {
              value: 189.65,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3977.17,
              format: "Currency",
            },
            Returns: {
              value: 1.8,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-24-2024",
        day: "Saturday",
        previousDate: "07-27-2024",
        tooltipData: {
          current: {
            Customers: {
              value: 102,
              format: "Number",
            },
            Discounts: {
              value: 14.1,
              format: "Currency",
            },
            "Gross Sales": {
              value: 3617.33,
              format: "Currency",
            },
            Returns: {
              value: 5,
              format: "Number",
            },
          },
          previous: {
            Customers: {
              value: 101,
              format: "Number",
            },
            Discounts: {
              value: 22.64,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4482.68,
              format: "Currency",
            },
            Returns: {
              value: 12.15,
              format: "Number",
            },
          },
        },
      },
      {
        reportType: "GroupBar",
        currentDate: "08-31-2024",
        day: "Saturday",
        previousDate: null,
        tooltipData: {
          current: {
            Customers: {
              value: 121,
              format: "Number",
            },
            Discounts: {
              value: 49.52,
              format: "Currency",
            },
            "Gross Sales": {
              value: 4211.76,
              format: "Currency",
            },
            Returns: {
              value: 0,
              format: "Number",
            },
          },
        },
      },
    ],
    a: 4900.36,
    b: 5123.1,
    c: 3562.6,
    d: 3617.33,
    e: 4211.76,
  },
]
