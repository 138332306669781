import Box from "@mui/material/Box"
import { useTheme } from "../../../contexts/theme"
import { ResponsiveScatterPlot } from "@nivo/scatterplot"

const NivoScatterPlot = ({
  ScatterPlotChartParameters,
  ScatterPlotChartData,
}) => {
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const customTheme = {
    axis: {
      ticks: {
        line: {
          transform: "translateX(-2px)",
        },
        formatXAxisLabel: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  const CustomGridBackground = ({
    xScale,
    yScale,
    innerWidth,
    innerHeight,
  }) => (
    <g>
      <rect
        x={xScale(0)}
        y={yScale(100)}
        width={innerWidth}
        height={innerHeight - yScale(100)}
        fill="rgba(7, 19, 57, 0.3)"
      />

      <line
        x1={xScale(15)}
        y1={0}
        x2={xScale(15)}
        y2={innerHeight}
        stroke="rgba(97, 128, 182, 1)"
      />
      <line
        x1={xScale(45)}
        y1={0}
        x2={xScale(45)}
        y2={innerHeight}
        stroke="rgba(97, 128, 182, 1)"
      />
      <line
        x1={xScale(75)}
        y1={0}
        x2={xScale(75)}
        y2={innerHeight}
        stroke="rgba(97, 128, 182, 1)"
      />
    </g>
  )

  const formatXAxisLabel = (value) => {
    switch (value) {
      case 0:
        return "Newest"
      case 15:
        return "New"
      case 45:
        return "Old"
      case 75:
        return "Oldest"
      default:
        return value
    }
  }

  return (
    <>
      <Box
        height="350px"
        display="flex"
        justifyContent="center"
        alignContent="center"
      >
        <ResponsiveScatterPlot
          data={ScatterPlotChartData}
          theme={customTheme}
          // margin={{
          //   top: 30,
          //   right: 30,
          //   bottom: 70,
          //   left: 10,
          // }}
          margin={ScatterPlotChartParameters.margin}
          // xScale={{
          //   type: "linear",
          //   min: 0,
          //   max: 100,
          // }}
          xScale={ScatterPlotChartParameters.xScale}
          // yScale={{
          //   type: "linear",
          //   min: 0,
          //   max: 100,
          // }}
          yScale={ScatterPlotChartParameters.yScale}
          // axisTop={null}
          axisTop={ScatterPlotChartParameters.axisTop}
          // axisRight={null}
          axisRight={ScatterPlotChartParameters.axisRight}
          axisBottom={{
            tickValues: [0, 15, 45, 75],
            tickFormat: formatXAxisLabel,
            legendPosition: "middle",
            legendOffset: 46,
            tickSize: 8,
            tickPadding: 10,
            tickRotation: 0,
            format: (value) => formatXAxisLabel(value),
            renderTick: ({ value, index, axis, x, y }) => (
              <g key={index} transform={`translate(${x},${y + 16})`}>
                <text
                  x={45}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{
                    fill: "#fff",
                    fontSize: 12,
                  }}
                >
                  {formatXAxisLabel(value)}
                </text>
              </g>
            ),
          }}
          // axisBottom={ScatterPlotChartParameters.axisBottom}
          // gridXValues={[15, 45, 75]}
          gridXValues={ScatterPlotChartParameters.gridXValues}
          // gridYValues={[50]}
          gridYValues={ScatterPlotChartParameters.gridYValues}
          // nodeSize={8}
          nodeSize={ScatterPlotChartParameters.nodeSize}
          layers={["grid", "axes", "nodes", CustomGridBackground]}
          // layers={ScatterPlotChartParameters.Layers}
          // colors={(node) =>
          //   node.serieId === "Min"
          //     ? "rgba(92, 41, 199, 1)"
          //     : "rgba(240, 134, 76, 1)"
          // }
          colors={ScatterPlotChartParameters.colors}
        />
      </Box>
    </>
  )
}

export default NivoScatterPlot
