import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Chip from "@mui/material/Chip"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import Star from "../../../../assets/svgs/Star"
import Box from "@mui/material/Box"
import CustomPaper from "../../../../components/CustomPaper"
import { useTheme } from "../../../../contexts/theme"
import { Link, useNavigate } from "react-router-dom"
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import DeleteModal from "../../../../components/DeleteModal"
import { useState } from "react"
import "./styles.scss"

const UserDetailsCard = ({ title, children }) => (
  <Stack
    display="flex"
    flexDirection="column"
    // justifyContent="space-between"
    // height="100%"
  >
    <Typography color="inherit" variant="subtitle2">
      {title}
    </Typography>
    {children}
  </Stack>
)

const UserDetails = () => {
  const { theme } = useTheme()

  const navigate = useNavigate()

  const handleEdit = () => {
    navigate(`/management/users/edit`)
  }

  const [open, setOpen] = useState(false)

  const handleDelete = (event) => {
    event.stopPropagation()
    setOpen(true)
  }

  const deleteRecord = () => {
    setOpen(false)
  }

  return (
    <>
      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onDelete={deleteRecord}
      />
      <Box p="30px 30px 15px 30px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "Management",
              // isActive: false,
              clickHandler: () => {
                navigate("/management")
              },
            },
            {
              text: "Users",
              // isActive: false,
              // clickHandler: () => {
              //   navigate("/employees/overview")
              // },
            },
            {
              text: "Dianne Russle",
              isActive: true,
            },
          ]}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        p="15px 30px 15px 30px"
      >
        <Typography component="div" variant="h6">
          User Details
        </Typography>
        <Box>
          <IconButton size="small">
            <EditIcon sx={{ color: "inherit" }} onClick={handleEdit} />
          </IconButton>
          <IconButton size="small" onClick={handleDelete}>
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </Box>
      <Stack gap="20px" px="30px" pb="20px">
        <div className="primary-grid-container">
          <CustomPaper className="user-grid-content1 grid-col-span-3">
            <Box>
              {/* Personal Information */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                borderBottom="2px solid #091743"
              >
                <Typography mb="10px" color="inherit">
                  Personal Information
                </Typography>
              </Box>

              {/* Personal Information Details */}
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap="30px"
                mt={1.5}
              >
                {Object.entries({
                  name: {
                    label: "Name",
                    value: "Dianne Russle",
                  },
                  streetAddress: {
                    label: "Street Address",
                    value: "123 Main Street",
                  },
                  state: {
                    label: "State",
                    value: "New York",
                  },
                  rank: {
                    label: "Rank",
                    value: "6",
                  },
                  phoneNumber: {
                    label: "Phone Number",
                    value: "97399321",
                  },
                  city: {
                    label: "City",
                    value: "AnyTown",
                  },
                  country: {
                    label: "Country",
                    value: "USA",
                  },
                  emailAddress: {
                    label: "Email Address",
                    value: "abc.qqqq@livelytics.ai",
                  },
                  apartmentSuiteNumber: {
                    label: "Appartment / Suite Number",
                    value: "5th Nov 2023",
                  },
                  zipCode: {
                    label: "Zip Code",
                    value: "440012",
                  },
                }).map(([key, value]) => (
                  <Stack
                    key={key}
                    rowGap="5px"
                    minWidth={{
                      sx: "180px",
                      sm: "180px",
                      md: "180px",
                      lg: "250px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      sx={{
                        opacity: "70%",
                        fontSize: "0.85rem",
                      }}
                    >
                      {value.label}
                    </Typography>
                    <Typography
                      variant="body1"
                      color={
                        value.label === "Total Purchased Amount"
                          ? "#50CD89"
                          : "inherit"
                      }
                      sx={{ fontSize: "1.1rem" }}
                    >
                      {value.value}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Box>
          </CustomPaper>

          <div className="secondary-grid-container">
            <div className="item-4">
              {[
                {
                  title: "Total Leaves",
                  value: "36",
                },
                {
                  title: "Performance",
                  value: (
                    <>
                      4.3 <Star />
                    </>
                  ),
                },
                {
                  title: "Total Leaves",
                  value: "36",
                },
                {
                  title: "Anual Income",
                  value: "$12,360",
                },
                {
                  title: "Total Leaves",
                  value: "36",
                },
                {
                  title: "Status",
                  value: (
                    <Chip
                      size="small"
                      label="Active"
                      sx={{
                        backgroundColor: "rgba(80, 205, 137, 0.3)",
                        color: "rgb(80, 205, 137)",
                        maxWidth: "fit-content",
                      }}
                    />
                  ),
                },
              ].map((item, idx) => (
                <CustomPaper
                  key={idx}
                  className={`small-grid-${idx}`}
                  watermarkBg={true}
                  customStyles={{ minHeight: "100%" }}
                >
                  <UserDetailsCard title={item.title}>
                    <Typography color="inherit" variant="h6">
                      {item.value}
                    </Typography>
                  </UserDetailsCard>
                </CustomPaper>
              ))}
            </div>
          </div>
        </div>

        <CustomPaper>
          <Box>
            {/* Job Information */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              borderBottom="2px solid #091743"
            >
              <Typography mb="10px" color="inherit">
                Job Information
              </Typography>
            </Box>

            {/* Job Information Details */}
            <Box
              display="flex"
              justifyContent="space-between"
              mt={1.5}
              flexWrap="wrap"
              gap="20px"
            >
              {/* Department */}
              <Stack rowGap="5px">
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  Department
                </Typography>
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  Kitchen
                </Typography>
              </Stack>

              {/* Group */}
              <Stack rowGap="5px">
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  Group
                </Typography>
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  Chef
                </Typography>
              </Stack>

              {/* Date of Joining */}
              <Stack rowGap="5px">
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  Date of Joining
                </Typography>
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  1 March 2024
                </Typography>
              </Stack>
            </Box>
          </Box>
        </CustomPaper>

        <CustomPaper>
          <Box>
            {/* Payroll Summary */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              borderBottom="2px solid #091743"
            >
              <Typography mb="10px" color="inherit">
                Payroll Summary
              </Typography>
            </Box>

            {/* Payroll Summary Details */}
            <Box
              display="flex"
              justifyContent="space-between"
              mt={1.5}
              flexWrap="wrap"
              gap="20px"
            >
              <Stack rowGap="5px">
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  Salary
                </Typography>
                <Typography
                  variant="body1"
                  color="rgba(80, 205, 137, 0.8)"
                  sx={{ fontSize: "1.1rem" }}
                >
                  $1,031
                </Typography>
              </Stack>
              <Stack rowGap="5px">
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  Last Processed cycle
                </Typography>
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  March - 2023 (01 March - 30 March)
                </Typography>
              </Stack>
              <Stack rowGap="5px">
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  No of Days
                </Typography>
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  31
                </Typography>
              </Stack>
              <Stack rowGap="5px">
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  Loss of pay
                </Typography>
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  0
                </Typography>
              </Stack>
              <Stack rowGap="5px">
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{
                    opacity: "70%",
                    fontSize: "0.85rem",
                  }}
                >
                  Status
                </Typography>
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: "1.1rem" }}
                >
                  <Chip
                    size="small"
                    label="Active"
                    sx={{
                      backgroundColor: "rgba(80, 205, 137, 0.3)",
                      color: "rgb(80, 205, 137)",
                    }}
                  />
                </Typography>
              </Stack>
              <Box display="flex" alignItems="center">
                <Link
                  href=""
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography color={theme.palette.accent} fontSize="0.8rem">
                    View Pay Slip →
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </CustomPaper>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomPaper>
              <Box>
                {/* Login Credential */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  borderBottom="2px solid #091743"
                >
                  <Typography mb="10px" color="inherit">
                    Login Credential
                  </Typography>
                </Box>
                {/* Login Credential Details */}

                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt={1.5}
                  flexWrap="wrap"
                  gap="20px"
                >
                  {/* User Name */}
                  {[
                    { label: "User Name", value: "bookrlynnn" },
                    { label: "Password", value: "********" },
                    { label: "Created Date", value: "25 Jun 2023" },
                  ].map(({ label, value }, index) => (
                    <Stack rowGap="5px" key={index}>
                      <Typography
                        variant="body2"
                        color="inherit"
                        sx={{
                          opacity: "70%",
                          fontSize: "0.85rem",
                        }}
                      >
                        {label}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="inherit"
                        sx={{ fontSize: "1.1rem" }}
                      >
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </CustomPaper>
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomPaper
              className="additional-user2"
              customStyles={{
                backgroundColor: "transparent",
                border:
                  theme.palette.mode === "dark"
                    ? "2px solid rgba(13, 34, 102, 1)"
                    : "none",
              }}
            >
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  borderBottom="2px solid #091743"
                >
                  <Typography mb="10px" color="inherit">
                    Roles
                  </Typography>
                </Box>
                <List dense={true}>
                  <ListItem sx={{ px: 0 }}>Vendor Details</ListItem>
                  <ListItem sx={{ px: 0 }}>Vendor Details</ListItem>
                  <ListItem sx={{ px: 0 }}>Customer Details</ListItem>
                  <ListItem sx={{ px: 0 }}>Employee Details</ListItem>
                  <ListItem sx={{ px: 0 }}>Sales Details</ListItem>
                  <ListItem sx={{ px: 0 }}>Sales Details</ListItem>
                  <ListItem sx={{ px: 0 }}>Sales Details</ListItem>
                  <ListItem sx={{ px: 0 }}>Sales Details</ListItem>
                  <ListItem sx={{ px: 0 }}>Sales Details</ListItem>
                </List>
              </Box>
            </CustomPaper>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}

export default UserDetails
