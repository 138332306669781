import { createSlice } from "@reduxjs/toolkit"

export const overviewSlice = createSlice({
  name: "overview",
  initialState: {
    dashboard: null,
    dashboard_uid: null,
    reports: [],
  },
  reducers: {
    loadData: (state, action) => {
      const { dashboard, dashboard_uid, reports } = action.payload
      state.dashboard = dashboard
      state.dashboard_uid = dashboard_uid
      state.reports = reports
    },
    setChartData: (state, action) => {
      const { reportUid, response, comparisonPeriod, viewBy } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )
      if (selectedReport) selectedReport.reportData = response
      if (selectedReport && comparisonPeriod)
        selectedReport.comparisonPeriod = comparisonPeriod
      if (selectedReport && viewBy) selectedReport.viewBy = viewBy
    },
  },
})

export const { loadData, setChartData } = overviewSlice.actions

export default overviewSlice.reducer
