import Button from "@mui/material/Button"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"
import { useTheme } from "../contexts/theme"
import { Link } from "react-router-dom"
import SaveModal from "./SaveModal"
import LocModal from "./LocModal"

const Locations = () => {
  const { theme } = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [showLocation, setShowLocation] = useState(false)
  const [open, setOpen] = useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const handleLocation = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const menuId = "location-menu"

  const handleLocationClose = () => {
    setAnchorEl(null)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const [selectedLocations, setSelectedLocations] = useState({
    all: false,
    locations: [
      { name: "California", checked: false },
      { name: "New York", checked: false },
      { name: "Washington", checked: false },
      { name: "Yellowstone Park", checked: false },
    ],
  })
  return (
    <>
      <Button
        startIcon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.6"
              d="M3.01675 7.0763C4.65842 -0.140363 15.3501 -0.132028 16.9834 7.08464C17.9417 11.318 15.3084 14.9013 13.0001 17.118C11.3251 18.7346 8.67508 18.7346 6.99175 17.118C4.69175 14.9013 2.05842 11.3096 3.01675 7.0763Z"
              stroke={
                theme.palette.mode === "dark"
                  ? "currentColor"
                  : theme.palette.accent
              }
              strokeWidth="1.4"
            />
            <path
              d="M7.70801 9.58333L8.95801 10.8333L12.2913 7.5"
              stroke={
                theme.palette.mode === "dark"
                  ? "currentColor"
                  : theme.palette.accent
              }
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        variant="contained"
        color="primary"
        sx={{
          display: "flex",
          ml: "auto",
          textTransform: "none",
          borderRadius: "8px",

          "&:hover": {
            backgroundColor: theme.palette.mode === "light" && "#E9EDFC",
          },
        }}
        endIcon={
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.64645 5.65967C3.84171 5.44678 4.15829 5.44678 4.35355 5.65967L7.64645 9.25C7.84171 9.4629 8.15829 9.4629 8.35355 9.25L11.6464 5.65968C11.8417 5.44678 12.1583 5.44678 12.3536 5.65968C12.5488 5.87257 12.5488 6.21775 12.3536 6.43065L9.06066 10.021C8.47487 10.6597 7.52513 10.6597 6.93934 10.021L3.64645 6.43065C3.45118 6.21775 3.45118 5.87257 3.64645 5.65967Z"
              fill={
                theme.palette.mode === "dark"
                  ? "currentColor"
                  : theme.palette.accent
              }
            />
          </svg>
        }
        onClick={handleLocation}
      >
        <Typography
          sx={{ fontSize: "12px", minWidth: "86px" }}
          color={
            theme.palette.mode === "dark" ? "inherit" : theme.palette.accent
          }
        >
          All Locations
        </Typography>
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleLocationClose}
        sx={{ top: "33px" }}
        PaperProps={{
          sx: {
            backgroundColor:
              theme.palette.mode === "light" ? "#FFFFFF" : "#0D2266",
          },
        }}
      >
        {/* <Link
          style={{
            color: theme.palette.mode === "dark" ? "white" : "black",
            textDecoration: "none",
            fontSize: "0.9rem",
          }}
          to={"/"}
          //   onClick={handleLogout}
        > */}
        <MenuItem>
          <ListItemIcon>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                fill={theme.palette.accent}
              />
            </svg>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="inherit">
              All Locations
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                fill={theme.palette.accent}
              />
            </svg>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="inherit">
              California
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                fill={theme.palette.accent}
              />
            </svg>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="inherit">
              New York
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                fill={theme.palette.accent}
              />
            </svg>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="inherit">
              Washington
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                fill={theme.palette.accent}
              />
            </svg>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="inherit">
              Yellowstone Park
            </Typography>
          </ListItemText>
          {/* </Link> */}
        </MenuItem>
        <MenuItem>
          <ListItemText>
            <Typography
              textAlign="center"
              variant="subtitle2"
              color={theme.palette.accent}
              onClick={handleOpen}
            >
              More
            </Typography>
            {/* <SaveModal
              open={open}
              onClose={() => setOpen(false)}
              title="Success"
              message="User has been saved successfully!!"
            /> */}

            <LocModal
              open={open}
              onClose={() => setOpen(false)}
              title="Select Locations"
              message="Please select the location/s you wish to see the data for "
            />
          </ListItemText>
        </MenuItem>
      </Menu>
      {/* {showLocation && (
        <Box
          display="flex"
          sx={{
            zIndex: 20,
            position: "absolute",
            top: {
              xs: "293px",
              sm: "251px",
              md: "251px",
              lg: "216px",
            },
            right: {
              sm: "59px",
              xl: "calc((100vw - 1536px) / 2 + 59px)",
            },
          }}
        >
          <ul>
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
          </ul>
        </Box>
      )} */}
    </>
  )
}

export default Locations
