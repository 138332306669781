// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KPICardhoverEffect {
  display: inline-block;
  transition: transform 0.5s ease;
}

.KPICardhoverEffect:hover {
  transform: translateX(6px);
}`, "",{"version":3,"sources":["webpack://./src/routes/marketingportal/Style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,+BAAA;AACF;;AAEA;EACE,0BAAA;AACF","sourcesContent":[".KPICardhoverEffect {\n  display: inline-block;\n  transition: transform 0.5s ease;\n}\n\n.KPICardhoverEffect:hover {\n  transform: translateX(6px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
