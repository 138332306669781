import { GridToolbarContainer } from "@mui/x-data-grid"
import { useTheme } from "../../contexts/theme"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Select from "@mui/material/Select"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import FormControl from "@mui/material/FormControl"
import { useEffect, useState } from "react"
import { postData } from "../../api/api"
import LoadingButton from "@mui/lab/LoadingButton"
import XLSX from "xlsx"
import Button from "@mui/material/Button"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import CustomFilter from "../CustomFilter"
import FilterListIcon from "@mui/icons-material/FilterList"
import Collapse from "@mui/material/Collapse"
import Badge from "@mui/material/Badge"
import { useSelector } from "react-redux"

const ExportMenu = ({
  theme,
  entityDb,
  entityId,
  fromDate,
  toDate,
  pageSize,
  searchText,
  reportInstanceId,
  postUrl,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [loading, setLoading] = useState(true)
  const [allRows, setAllRows] = useState([])
  useEffect(() => {
    const fetchAllRows = async () => {
      try {
        setLoading(true)
        let response

        if (postUrl == "subscription/invoices") {
          response = await postData(postUrl, {
            entityId,
          })
        } else {
          response = await postData(postUrl, {
            entityDb,
            fromDate,
            toDate,
            pageSize,
            pageNumber: 1,
            searchText,
            reportInstanceId,
          })
        }

        setAllRows(
          postUrl === "subscription/invoices"
            ? response.result
            : response[0]?.data || []
        )
      } catch (error) {
        console.log(error.message)
      } finally {
        setLoading(false) // Only set loading to false once here
      }
    }

    fetchAllRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entityDb,
    entityId,
    fromDate,
    toDate,
    pageSize,
    searchText,
    reportInstanceId,
    postUrl,
  ])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const exportData = async (type) => {
    if (type === "pdf") {
      alert("Export PDF is WIP")
      setAnchorEl(null)
      return
    }
    const worksheet = XLSX.utils.json_to_sheet(allRows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data")
    XLSX.writeFile(workbook, `data.${type}`, { bookType: type })
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title="Export data">
        <LoadingButton
          loading={loading}
          sx={{
            minWidth: "104px",
            textTransform: "none",
            borderRadius: "20px",
            border: `1px solid ${theme.palette.accent}`,
            color: theme.palette.accent,
            padding: "5px 15px",
            "&:hover": {
              border: `1px solid ${theme.palette.accent}`,
            },
            "& .MuiCircularProgress-colorInherit": {
              color: theme.palette.accent,
            },
          }}
          color="warning"
          startIcon={null}
          onClick={handleClick}
          endIcon={
            !loading && (
              <svg
                width="18"
                height="18"
                viewBox="0 0 34 34"
                fill={`${theme.palette.accent}50`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32 22V24C32 26.8003 32 28.2004 31.455 29.27C30.9757 30.2108 30.2108 30.9757 29.27 31.455C28.2004 32 26.8003 32 24 32H10C7.19974 32 5.79961 32 4.73005 31.455C3.78924 30.9757 3.02433 30.2108 2.54497 29.27C2 28.2004 2 26.8003 2 24V22M25.3333 13.6667L17 22M17 22L8.66667 13.6667M17 22V2"
                  stroke={theme.palette.accent}
                  strokeWidth="3.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )
          }
        >
          Export
        </LoadingButton>
      </Tooltip>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ "aria-labelledby": "export-button" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <MenuItem onClick={() => exportData("pdf")}>
          <ListItemIcon>
            <svg
              width="20"
              height="20"
              viewBox="0 0 36 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.12">
                <path
                  d="M3.33301 19.6667C3.33301 16.8665 3.33301 15.4664 3.87798 14.3968C4.35734 13.456 5.12225 12.6911 6.06306 12.2117C7.13261 11.6667 8.53275 11.6667 11.333 11.6667H28.6663C31.4666 11.6667 32.8667 11.6667 33.9363 12.2117C34.8771 12.6911 35.642 13.456 36.1214 14.3968C36.6663 15.4664 36.6663 16.8665 36.6663 19.6667V23.3334C36.6663 24.8834 36.6663 25.6583 36.496 26.2942C36.0336 28.0196 34.6859 29.3674 32.9604 29.8297C32.3246 30.0001 31.5496 30.0001 29.9997 30.0001V28.6667C29.9997 26.7999 29.9997 25.8665 29.6364 25.1534C29.3168 24.5262 28.8069 24.0163 28.1796 23.6967C27.4666 23.3334 26.5332 23.3334 24.6663 23.3334H15.333C13.4662 23.3334 12.5327 23.3334 11.8197 23.6967C11.1925 24.0163 10.6826 24.5262 10.363 25.1534C9.99967 25.8665 9.99967 26.7999 9.99967 28.6667V30.0001C8.44972 30.0001 7.67475 30.0001 7.03891 29.8297C5.31345 29.3674 3.96571 28.0196 3.50338 26.2942C3.33301 25.6583 3.33301 24.8834 3.33301 23.3334V19.6667Z"
                  fill={theme.palette.accent}
                />
              </g>
              <path
                d="M29.9997 11.6666V8.66659C29.9997 6.79974 29.9997 5.86632 29.6364 5.15328C29.3168 4.52608 28.8069 4.01614 28.1796 3.69656C27.4666 3.33325 26.5332 3.33325 24.6663 3.33325H15.333C13.4662 3.33325 12.5327 3.33325 11.8197 3.69656C11.1925 4.01614 10.6826 4.52608 10.363 5.15328C9.99967 5.86632 9.99967 6.79974 9.99967 8.66659V11.6666M9.99967 29.9999C8.44972 29.9999 7.67475 29.9999 7.03891 29.8295C5.31345 29.3672 3.96571 28.0195 3.50338 26.294C3.33301 25.6582 3.33301 24.8832 3.33301 23.3333V19.6666C3.33301 16.8663 3.33301 15.4662 3.87798 14.3966C4.35734 13.4558 5.12224 12.6909 6.06306 12.2116C7.13261 11.6666 8.53275 11.6666 11.333 11.6666H28.6663C31.4666 11.6666 32.8667 11.6666 33.9363 12.2116C34.8771 12.6909 35.642 13.4558 36.1214 14.3966C36.6663 15.4662 36.6663 16.8663 36.6663 19.6666V23.3333C36.6663 24.8832 36.6663 25.6582 36.496 26.294C36.0336 28.0195 34.6859 29.3672 32.9604 29.8295C32.3246 29.9999 31.5496 29.9999 29.9997 29.9999M24.9997 17.4999H29.9997M15.333 36.6666H24.6663C26.5332 36.6666 27.4666 36.6666 28.1796 36.3033C28.8069 35.9837 29.3168 35.4738 29.6364 34.8466C29.9997 34.1335 29.9997 33.2001 29.9997 31.3333V28.6666C29.9997 26.7997 29.9997 25.8663 29.6364 25.1533C29.3168 24.5261 28.8069 24.0161 28.1796 23.6966C27.4666 23.3333 26.5332 23.3333 24.6663 23.3333H15.333C13.4662 23.3333 12.5327 23.3333 11.8197 23.6966C11.1925 24.0161 10.6826 24.5261 10.363 25.1533C9.99967 25.8663 9.99967 26.7997 9.99967 28.6666V31.3333C9.99967 33.2001 9.99967 34.1335 10.363 34.8466C10.6826 35.4738 11.1925 35.9837 11.8197 36.3033C12.5327 36.6666 13.4662 36.6666 15.333 36.6666Z"
                stroke={theme.palette.accent}
                strokeWidth="3.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="inherit">
              PDF
            </Typography>
          </ListItemText>
        </MenuItem> */}
        <MenuItem onClick={() => exportData("xlsx")}>
          <ListItemIcon>
            <svg
              width="20"
              height="20"
              viewBox="-2 0 30 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3337 2.78247V9.66671C18.3337 10.6001 18.3337 11.0668 18.5153 11.4234C18.6751 11.737 18.9301 11.9919 19.2437 12.1517C19.6002 12.3334 20.0669 12.3334 21.0003 12.3334H27.8846M21.667 20.6666H8.33366M21.667 27.3333H8.33366M11.667 13.9999H8.33366M18.3337 2.33325H9.66699C6.86673 2.33325 5.4666 2.33325 4.39704 2.87822C3.45623 3.35759 2.69133 4.12249 2.21196 5.0633C1.66699 6.13286 1.66699 7.53299 1.66699 10.3333V27.6666C1.66699 30.4668 1.66699 31.867 2.21196 32.9365C2.69133 33.8773 3.45623 34.6423 4.39704 35.1216C5.4666 35.6666 6.86673 35.6666 9.66699 35.6666H20.3337C23.1339 35.6666 24.5341 35.6666 25.6036 35.1216C26.5444 34.6423 27.3093 33.8773 27.7887 32.9365C28.3337 31.867 28.3337 30.4668 28.3337 27.6666V12.3333L18.3337 2.33325Z"
                stroke={theme.palette.accent}
                strokeWidth="3.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="inherit">
              Excel
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => exportData("csv")}>
          <ListItemIcon>
            <svg
              width="20"
              height="20"
              viewBox="-2 0 30 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3337 2.78247V9.66671C18.3337 10.6001 18.3337 11.0668 18.5153 11.4234C18.6751 11.737 18.9301 11.9919 19.2437 12.1517C19.6002 12.3334 20.0669 12.3334 21.0003 12.3334H27.8846M21.667 20.6666H8.33366M21.667 27.3333H8.33366M11.667 13.9999H8.33366M18.3337 2.33325H9.66699C6.86673 2.33325 5.4666 2.33325 4.39704 2.87822C3.45623 3.35759 2.69133 4.12249 2.21196 5.0633C1.66699 6.13286 1.66699 7.53299 1.66699 10.3333V27.6666C1.66699 30.4668 1.66699 31.867 2.21196 32.9365C2.69133 33.8773 3.45623 34.6423 4.39704 35.1216C5.4666 35.6666 6.86673 35.6666 9.66699 35.6666H20.3337C23.1339 35.6666 24.5341 35.6666 25.6036 35.1216C26.5444 34.6423 27.3093 33.8773 27.7887 32.9365C28.3337 31.867 28.3337 30.4668 28.3337 27.6666V12.3333L18.3337 2.33325Z"
                stroke={theme.palette.accent}
                strokeWidth="3.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="inherit">
              CSV
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

const Toolbar = ({
  title = "Vendors",
  searchTerm,
  setSearchTerm,
  setSearchTrigger,
  showButton,
  showExportButton = true,
  showFiltersButton = false,
  showDropdown,
  dropdownOptions,
  buttonName,
  entityDb,
  entityId,
  fromDate,
  toDate,
  pageSize,
  searchText,
  reportInstanceId,
  postUrl,
  loading,
  columns,
  route,
}) => {
  const { theme } = useTheme()
  const [selectedGroup, setSelectedGroup] = useState("")
  const handleSelectChange = (event) => {
    setSelectedGroup(event.target.value)
  }

  const filtersData = useSelector((state) => state?.filters?.[route])
  const filters = showFiltersButton ? filtersData?.filters : []

  const [showFilters, setShowFilters] = useState(false)

  return (
    <>
      <GridToolbarContainer
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexWrap: "wrap",
          padding: "1.2rem 1.2rem 0rem 1.2rem",
        }}
      >
        <Typography variant="subtitle1" color="inherit">
          {title}
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          style={{
            cursor: "pointer",
            minWidth: "350px",
            marginLeft: "auto",
          }}
        >
          <input
            className="search_input"
            type="text"
            placeholder="Search here..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              padding: "10px",
              color: "inherit",
              background: "transparent",
              borderRadius: "10px",
              border: "1px solid rgba(26, 68, 203, 1)",
              outline: "none",
              height: "36px",
              width: "100%",
            }}
          />
          <Box
            display="inline-flex"
            alignContent="center"
            alignItems="center"
            sx={{
              position: "relative",
              inset: "2px 0px 0px -25px",
              cursor: "pointer",
              borderRadius: "5px",
              marginRight: "-13px",
            }}
            onClick={() => setSearchTrigger((prevState) => !prevState)}
          >
            <svg
              width="13"
              height="15"
              viewBox="0 0 13 15"
              fill={theme.palette.mode === "light" ? "blue" : "#F3884A"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.1849 7.33333H8.65823L8.47156 7.15333C9.1249 6.39333 9.51823 5.40667 9.51823 4.33333C9.51823 1.94 7.57823 0 5.1849 0C2.79156 0 0.851562 1.94 0.851562 4.33333C0.851562 6.72667 2.79156 8.66667 5.1849 8.66667C6.25823 8.66667 7.2449 8.27333 8.0049 7.62L8.1849 7.80667V8.33333L11.5182 11.66L12.5116 10.6667L9.1849 7.33333ZM5.1849 7.33333C3.5249 7.33333 2.1849 5.99333 2.1849 4.33333C2.1849 2.67333 3.5249 1.33333 5.1849 1.33333C6.8449 1.33333 8.1849 2.67333 8.1849 4.33333C8.1849 5.99333 6.8449 7.33333 5.1849 7.33333Z"
                fill={theme.palette.mode === "light" ? "blue" : "#F3884A"}
              />
            </svg>
          </Box>
        </Box>

        {showFiltersButton && (
          <Badge
            badgeContent={filters?.length || 0}
            sx={{
              ".MuiBadge-badge": {
                // background: "blue"
                border: 1,
              },
            }}
            color="primary"
          >
            <Button
              variant="outline"
              sx={{
                textTransform: "none",
                borderRadius: "20px",
                border: `1px solid ${theme.palette.accent}`,
                color: theme.palette.accent,
                "&:hover": {
                  border: `1px solid ${theme.palette.accent}`,
                },
              }}
              endIcon={<FilterListIcon color={theme.palette.accent} />}
              onClick={() => setShowFilters((prevState) => !prevState)}
            >
              Filters
            </Button>
          </Badge>
        )}

        {showDropdown && typeof showDropdown === "boolean" && (
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={selectedGroup}
              onChange={handleSelectChange}
              displayEmpty
              inputProps={{ "aria-label": "Selet Group" }}
              sx={{
                "&:hover": {
                  border: "0px !important",
                },
                ".MuiSelect-icon": {
                  color: theme.palette.accent,
                },
                fontSize: "0.9rem",
                width: "18rem",
                height: "2.3rem",
                color: "gray",
                borderRadius: "10px",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "transparent"
                    : "rgba(255, 255, 255, 1)",
                border: "1px solid rgba(26, 68, 203, 1)", // Set border color to blue regardless of theme mode
              }}
            >
              <MenuItem value="">Select Group</MenuItem>
              {dropdownOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  sx={{ fontSize: "0.9rem" }}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {showButton && typeof showButton === "function" && (
          <button
            onClick={showButton}
            style={{
              cursor: "pointer",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 121, 53, 1)"
                  : "rgba(29, 71, 206, 1)",
              color: "white",
              whiteSpace: "nowrap",
              border: "none",
              textTransform: "none",
              width: "auto",
              height: "36px",
              top: "10px",
              padding: "10px 16px 10px 16px",
              borderRadius: "24px",
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 100, 0, 1)"
                    : "rgba(29, 71, 226, 1)",
              },
            }}
          >
            <Typography sx={{ fontSize: "0.8rem" }}>{buttonName}</Typography>
          </button>
        )}
        {showExportButton && !loading && (
          <ExportMenu
            theme={theme}
            entityDb={entityDb}
            entityId={entityId}
            fromDate={fromDate}
            toDate={toDate}
            pageSize={pageSize}
            searchText={searchText}
            reportInstanceId={reportInstanceId}
            postUrl={postUrl}
          />
        )}
      </GridToolbarContainer>

      <Collapse in={showFilters}>
        <CustomFilter columns={columns} route={route} />
      </Collapse>
    </>
  )
}

export default Toolbar
