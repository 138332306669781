import React, { useState } from "react"
import { useTheme } from "../contexts/theme"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

const LocModal = ({ open, onClose, title, message }) => {
  const { theme } = useTheme()
  const [searchTerm, setSearchTerm] = useState("")
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const colors = {
    accentColor: theme.palette.mode === "dark" ? "#F3884A" : "#1D47CE",
    accentHoverColor: theme.palette.mode === "dark" ? "#EB7E41" : "#1C3DBD",
  }

  const [selectedLocations, setSelectedLocations] = useState({
    all: false,
    locations: [
      { name: "California", checked: false },
      { name: "New York", checked: false },
      { name: "Washington", checked: false },
      { name: "New Jersey", checked: false },
      { name: "New Mexico", checked: false },
      { name: "Colorado", checked: false },
      { name: "North Carolina", checked: false },
      { name: "Connecticut", checked: false },
      { name: "Louisville", checked: false },
      { name: "San Antonio", checked: false },
      { name: "Texas", checked: false },
      { name: "Ohio", checked: false },
      { name: "Arizona", checked: false },
      { name: "Las Vegas", checked: false },
      { name: "Miami", checked: false },
      { name: "Georgia", checked: false },
      { name: "Illinois", checked: false },
      { name: "Chicago", checked: false },
      { name: "Louisville", checked: false },
      { name: "Tennessee", checked: false },
      { name: "Philadelphia", checked: false },
    ],
  })

  const handleAllLocationsChange = () => {
    setSelectedLocations((prev) => ({
      all: !prev.all,
      locations: prev.locations.map((location) => ({
        ...location,
        checked: !prev.all,
      })),
    }))
  }

  const handleLocationChange = (location) => {
    setSelectedLocations((prev) => ({
      all: false,
      locations: prev.locations.map((loc) =>
        loc.name === location.name ? { ...loc, checked: !loc.checked } : loc
      ),
    }))
  }

  const svg = encodeURIComponent(`
    <svg width="13" height="12" viewBox="0 0 13 12" fill="${
      theme.palette.mode === "light" ? "blue" : "#F3884A"
    }" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.1849 7.33333H8.65823L8.47156 7.15333C9.1249 6.39333 9.51823 5.40667 9.51823 4.33333C9.51823 1.94 7.57823 0 5.1849 0C2.79156 0 0.851562 1.94 0.851562 4.33333C0.851562 6.72667 2.79156 8.66667 5.1849 8.66667C6.25823 8.66667 7.2449 8.27333 8.0049 7.62L8.1849 7.80667V8.33333L11.5182 11.66L12.5116 10.6667L9.1849 7.33333ZM5.1849 7.33333C3.5249 7.33333 2.1849 5.99333 2.1849 4.33333C2.1849 2.67333 3.5249 1.33333 5.1849 1.33333C6.8449 1.33333 8.1849 2.67333 8.1849 4.33333C8.1849 5.99333 6.8449 7.33333 5.1849 7.33333Z" fill="${
        theme.palette.mode === "light" ? "blue" : "#F3884A"
      }" />
    </svg>
  `)

  const searchIconStyle = {
    background: `url("data:image/svg+xml;utf8,${svg}") no-repeat calc(100% - 8px) 50%`,
    padding: "10px",
    color: theme.palette.mode === "light" ? "black" : "white",
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        {/* <Typography variant="h5" component="div" gutterBottom sx={{ m: 1 }}>
          Select Locations
        </Typography> */}
        <Stack
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          //   justifyContent="center"
          //   alignContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 12,
            p: 2,
            width: "50vw",
            borderRadius: "12px",
            textAlign: "center",
          }}
          gap="20px"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
            width="100%"
          >
            <Typography
              component="div"
              gutterBottom
              sx={{ m: 1, fontSize: "1rem", textAlign: "center" }}
            >
              {title}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                mb: 1,
                mt: -1,
                color: "inherit",
                opacity: "80%",
                fontSize: "0.7rem",
              }}
            >
              {message}
            </Typography>
            <div className="main_search">
              <input
                className="searching"
                type="text"
                placeholder="Search Locations..."
                value={searchTerm}
                onChange={handleSearchChange}
                style={{
                  marginLeft: "-50%",
                  background: `url("data:image/svg+xml;utf8,${svg}") no-repeat calc(100% - 8px) 50%`,
                  padding: "10px",
                  color: theme.palette.mode === "light" ? "black" : "white",
                  width: "200%",
                }}
              />
            </div>
          </Box>
          <Box>
            <Button
              style={{
                border: `1px solid ${
                  selectedLocations.all ? "coral" : "lightblue"
                }`,
                padding: "8px 20px",
                margin: "5px",
                borderRadius: "5px",
                backgroundColor: "background.paper",
                color: "inherit",
                fontSize: "0.8rem",
              }}
              onClick={handleAllLocationsChange}
            >
              {selectedLocations.all ? (
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                    fill={theme.palette.accent}
                  />
                </svg>
              ) : (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    d="M20 10C20 15.5228 15.5228 20 10 20C4.47714 20 0 15.5228 0 10C0 4.47714 4.47714 0 10 0C15.5228 0 20 4.47714 20 10ZM8.84327 15.2949L16.2626 7.87556C16.5146 7.62363 16.5146 7.21512 16.2626 6.96318L15.3503 6.0508C15.0983 5.79883 14.6898 5.79883 14.4378 6.0508L8.3871 12.1015L5.56213 9.27656C5.3102 9.0246 4.90169 9.0246 4.64972 9.27656L3.73734 10.189C3.4854 10.4409 3.4854 10.8494 3.73734 11.1013L7.93089 15.2949C8.18286 15.5468 8.59131 15.5468 8.84327 15.2949Z"
                    fill={theme.palette.accent}
                  />
                </svg>
              )}
              All Locations
            </Button>
            {selectedLocations.locations.map((location, index) => (
              <Button
                key={index}
                style={{
                  border: `1px solid ${
                    location.checked ? "coral" : "lightblue"
                  }`,
                  padding: "6px 20px",
                  margin: "5px",
                  borderRadius: "5px",
                  backgroundColor: "background.paper",
                  color: "inherit",
                }}
                onClick={() => handleLocationChange(location)}
              >
                {location.checked ? (
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                      fill={theme.palette.accent}
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47714 20 0 15.5228 0 10C0 4.47714 4.47714 0 10 0C15.5228 0 20 4.47714 20 10ZM8.84327 15.2949L16.2626 7.87556C16.5146 7.62363 16.5146 7.21512 16.2626 6.96318L15.3503 6.0508C15.0983 5.79883 14.6898 5.79883 14.4378 6.0508L8.3871 12.1015L5.56213 9.27656C5.3102 9.0246 4.90169 9.0246 4.64972 9.27656L3.73734 10.189C3.4854 10.4409 3.4854 10.8494 3.73734 11.1013L7.93089 15.2949C8.18286 15.5468 8.59131 15.5468 8.84327 15.2949Z"
                      fill={theme.palette.accent}
                    />
                  </svg>
                )}
                {/* </span> */}
                <Typography
                  sx={{ fontSize: "0.8rem", paddingLeft: "2px" }}
                  color="inherit"
                >
                  {location.name}
                </Typography>
              </Button>
            ))}
          </Box>
          <Box display="flex" width="100%" gap={1} justifyContent={"center"}>
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{
                border: `1px solid ${theme.palette.accent}`,
                textTransform: "none",
                color: theme.palette.accent,
                borderRadius: "20px",
                "&:hover": {
                  border: `1px solid ${theme.palette.accent}`,
                  color: "",
                  boxShadow: "5px",
                },
                width: "10rem",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={onClose}
              sx={{
                bgcolor: theme.palette.accent,
                textTransform: "none",
                color: "white",
                borderRadius: "20px",
                height: "36px",
                "&:hover": {
                  bgcolor: theme.palette.accent,
                  boxShadow: "5px",
                },
                width: "10rem",
              }}
            >
              Apply
            </Button>
          </Box>
        </Stack>
      </Modal>
    </>
  )
}

export default LocModal
