export const AgeAnalysisData = [
  {
    Age: "0-2",
    Normal: 0,
    Positive: 150,
    Negative: 150,
    tooltipData: {
      current: {
        Population: { value: 1200, format: "Number" },
        Percentage: { value: 8, format: "Percentage" },
      },
      previous: {
        Population: { value: 1100, format: "Number" },
        Percentage: { value: 7, format: "Percentage" },
      },
    },
  },
  {
    Age: "3-5",
    Normal: 250,
    Positive: 101,
    Negative: 50,
    tooltipData: {
      current: {
        Population: { value: 2400, format: "Number" },
        Percentage: { value: 10, format: "Percentage" },
      },
      previous: {
        Population: { value: 2200, format: "Number" },
        Percentage: { value: 9, format: "Percentage" },
      },
    },
  },
  {
    Age: "6-12",
    Normal: 900,
    Positive: 350,
    Negative: 150,
    tooltipData: {
      current: {
        Population: { value: 3600, format: "Number" },
        Percentage: { value: 15, format: "Percentage" },
      },
      previous: {
        Population: { value: 3400, format: "Number" },
        Percentage: { value: 14, format: "Percentage" },
      },
    },
  },
  {
    Age: "13-17",
    Normal: 1000,
    Positive: 450,
    Negative: 200,
    tooltipData: {
      current: {
        Population: { value: 2800, format: "Number" },
        Percentage: { value: 12, format: "Percentage" },
      },
      previous: {
        Population: { value: 2600, format: "Number" },
        Percentage: { value: 11, format: "Percentage" },
      },
    },
  },
  {
    Age: "18-24",
    Normal: 700,
    Positive: 300,
    Negative: 202,
    tooltipData: {
      current: {
        Population: { value: 3300, format: "Number" },
        Percentage: { value: 16, format: "Percentage" },
      },
      previous: {
        Population: { value: 3200, format: "Number" },
        Percentage: { value: 15, format: "Percentage" },
      },
    },
  },
  {
    Age: "25-64",
    Normal: 1700,
    Positive: 300,
    Negative: 200,
    tooltipData: {
      current: {
        Population: { value: 5000, format: "Number" },
        Percentage: { value: 25, format: "Percentage" },
      },
      previous: {
        Population: { value: 4800, format: "Number" },
        Percentage: { value: 24, format: "Percentage" },
      },
    },
  },
  {
    Age: "65+",
    Normal: 1450,
    Positive: 300,
    Negative: 150,
    tooltipData: {
      current: {
        Population: { value: 4100, format: "Number" },
        Percentage: { value: 20, format: "Percentage" },
      },
      previous: {
        Population: { value: 4000, format: "Number" },
        Percentage: { value: 19, format: "Percentage" },
      },
    },
  },
]
