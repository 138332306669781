import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

const EmployeeLeaveLegends = ({ category, count, colorItem = "red" }) => {
  return (
    <Box
      display="flex"
      marginY="25px"
      alignItems="flex-start"
      flexDirection="row"
    >
      <Box>
        <Typography variant="body1" color={colorItem}>
          {count}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {category}
        </Typography>
      </Box>
    </Box>
  )
}

export default EmployeeLeaveLegends
