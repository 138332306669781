
const SentimentNeutral = () => {
  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39 71.5C56.9491 71.5 71.5 56.9491 71.5 39C71.5 21.0507 56.9491 6.5 39 6.5C21.0507 6.5 6.5 21.0507 6.5 39C6.5 56.9491 21.0507 71.5 39 71.5Z"
        fill="#FCBF49"
      />
      <path
        opacity="0.5"
        d="M48.75 39C50.545 39 52 36.8173 52 34.125C52 31.4326 50.545 29.25 48.75 29.25C46.955 29.25 45.5 31.4326 45.5 34.125C45.5 36.8173 46.955 39 48.75 39Z"
        fill="#030816"
      />
      <path
        opacity="0.5"
        d="M29.25 39C31.0449 39 32.5 36.8173 32.5 34.125C32.5 31.4326 31.0449 29.25 29.25 29.25C27.4551 29.25 26 31.4326 26 34.125C26 36.8173 27.4551 39 29.25 39Z"
        fill="#030816"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51 53.5C51 54.8807 49.8807 56 48.5 56H29C27.6193 56 26.5 54.8807 26.5 53.5C26.5 52.1193 27.6193 51 29 51H48.5C49.8807 51 51 52.1193 51 53.5Z"
        fill="#7F632F"
      />
    </svg>
  )
}

export default SentimentNeutral
