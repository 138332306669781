// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-details-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.group-details-grid-item {
  grid-column: span 1;
}

.group-details-cards-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media screen and (max-width: 960px) {
  .group-details-cards-grid {
    grid-template-columns: 1fr;
  }
}
.group-details-cards-grid-item {
  grid-column: span 1;
}`, "",{"version":3,"sources":["webpack://./src/routes/management/groupManagement/groupDetails/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AACF;;AAEA;EACE;IACE,0BAAA;EACF;AACF;AAEA;EACE,mBAAA;AAAF","sourcesContent":[".group-details-grid {\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  gap: 20px;\n}\n\n.group-details-grid-item {\n  grid-column: span 1;\n}\n\n.group-details-cards-grid {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 20px;\n}\n\n@media screen and (max-width: 960px) {\n  .group-details-cards-grid {\n    grid-template-columns: 1fr;\n  }\n}\n\n.group-details-cards-grid-item {\n  grid-column: span 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
