import { createSlice } from "@reduxjs/toolkit"

export const vendorSlice = createSlice({
  name: "vendor",
  initialState: {
    vendor: null,
    vendor_uid: null,
    reports: [],
  },
  reducers: {
    loadData: (state, action) => {
      return action.payload
    },
    setChartData: (state, action) => {
      const { reportUid, response, comparisonPeriod, viewBy } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )
      if (selectedReport) selectedReport.reportData = response
      if (selectedReport && comparisonPeriod)
        selectedReport.comparisonPeriod = comparisonPeriod
      if (selectedReport && viewBy) selectedReport.viewBy = viewBy
    },
  },
})

export const { loadData, setChartData } = vendorSlice.actions

export default vendorSlice.reducer
