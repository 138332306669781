import { useEffect } from "react"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useTheme } from "../contexts/theme"
import dayjs from "dayjs"

const ViewBy = ({
  parameters,
  viewBy = "Day",
  setViewBy,
  dateRangeData,
  placeholder = "View By",
}) => {
  const { theme } = useTheme()
  const calculateDaysInRange = (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      console.error(
        "Invalid date range: fromDate or toDate is null or undefined"
      )
      return 0
    }

    const from = dayjs(fromDate, "YYYYMMDD")
    const to = dayjs(toDate, "YYYYMMDD")

    return to.diff(from, "day") + 1
  }

  const daysInRange = dateRangeData
    ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
    : 0

  const getDefaultOption = (days) => {
    if (days > 0 && days <= 31) return "Day"
    if (days >= 32 && days < 180) return "Week"
    if (days >= 180) return "Month"
    return "Day"
  }
  // const getDefaultOption = (days) => {
  //   if (days > 0 && days <= 31) return "Day"
  //   if (days >= 32 && days < 91) return "Week"
  //   if (days >= 91) return "Month"
  //   // if (days >= 180) return "Quarter"
  //   return "Day"
  // }

  useEffect(() => {
    setViewBy(getDefaultOption(daysInRange))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysInRange])

  const rangeOptions = [
    { min: 0, max: 7, options: ["Day"] },
    { min: 8, max: 31, options: ["Day", "Week"] },
    { min: 32, max: 364, options: ["Week", "Month"] },
    // { min: 91, max: 364, options: ["Month", "Quarter"] },
    { min: 365, max: Infinity, options: ["Month", "Quarter", "Year"] },
  ]

  const applicableOptions =
    rangeOptions.find(
      (range) => daysInRange >= range.min && daysInRange <= range.max
    )?.options || []

  // param eg. "Day", "Month" etc
  const visibleOptions = parameters.filter((param) =>
    applicableOptions.includes(param)
  )

  const handleChange = (event) => {
    setViewBy(event.target.value || getDefaultOption(daysInRange))
  }

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: "6.2rem",
      }}
    >
      <Select
        value={viewBy}
        onChange={handleChange}
        disableUnderline={true}
        defaultValue={"Day"}
        renderValue={(selected) => {
          if (!selected) {
            return placeholder
          }
          return selected
        }}
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#071339" : "#e7ecff",
          borderRadius: "10px",
          fontSize: "0.8rem",
          "& .MuiSelect-iconOpen, & .MuiSelect-icon": {
            color: theme.palette.accent,
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        }}
      >
        <MenuItem disabled value={null} sx={{ fontSize: "0.8rem" }}>
          View By
        </MenuItem>
        {visibleOptions.map((option, index) => (
          <MenuItem key={index} value={option} sx={{ fontSize: "0.8rem" }}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ViewBy
