import RouteTitle from "../../components/RouteTitle"
import Table from "../../components/datagrid/Table"
import Box from "@mui/material/Box"
import DatePicker from "../../components/DatePicker"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getFormattedValue } from "../../utils/utils"

const CustomersList = () => {
  const dateRangeData = useSelector((state) => state.calendar)
  const navigate = useNavigate()
  const handleRowClick = () => {
    // navigate("/customers/allcustomers/customer/1")
  }

  const filtersData = useSelector((state) => {
    return state.filters.customerList
  })

  return (
    <>
      <RouteTitle title={`All Customers ${filtersData?.filtersString}`}>
        <DatePicker />
      </RouteTitle>
      <Box padding="15px 30px 30px 30px">
        <Table
          title="Customers"
          onRowClick={handleRowClick}
          dateRangeData={dateRangeData}
          reportUid="d23b5808-1f06-4daf-9035-093e4682d202"
          postUrl="customer/list"
          parameters={[
            {
              parameterType: "COLUMNS",
              reportParameters: [
                {
                  parameterName: "columns",
                  parameterValue: [
                    {
                      field: "CustomerID",
                      headerName: "Customer ID",
                      flex: 1,
                      minWidth: 120,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Name",
                      headerName: "Name",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Phone",
                      headerName: "Phone",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Email",
                      headerName: "Email",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "FirstPurchaseDate",
                      headerName: "First Purchase Date",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "LastPurchaseDate",
                      headerName: "Last Purchase Date",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "TotalOrders",
                      headerName: "Total Orders",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                      renderCell: (params) => (
                        <Box
                          display="flex"
                          justifyContent="center"
                          sx={{ color: "#50DC89" }}
                        >
                          {getFormattedValue(params.value)}
                        </Box>
                      ),
                    },
                    {
                      field: "CustomerType",
                      headerName: "Customer Type",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                  ],
                },
              ],
            },
          ]}
          route="customerList"
          showExportButton={true}
          showFiltersButton={true}
        />
      </Box>
    </>
  )
}

export default CustomersList
