import React, { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { loginSchema } from "./Validation"
import { postData } from "../../api/api"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Modal from "@mui/material/Modal"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import ReCAPTCHA from "react-google-recaptcha"
import Logo from "../../assets/svgs/Logo"
import LoadingButton from "@mui/lab/LoadingButton"
import dayjs from "dayjs"
import { useTheme } from "../../contexts/theme"

const Login = () => {
  const { resetColorMode } = useTheme()
  const navigate = useNavigate()
  const recaptcha = useRef()
  const [showPassword, setShowPassword] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [modalMessage, setModalMessage] = useState({ header: "", message: "" })
  const [isLoading, setIsLoading] = useState(false)
  const handleCloseErrorModal = () => setOpenErrorModal(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      const { email, password } = values
      try {
        await loginSchema.validate(values, { abortEarly: false })
        const response = await postData("auth/login", {
          email,
          password,
        }).catch(function (error) {
          if (error.response) {
            return error.response.data
          }
        })
        setIsLoading(false)

        if (response.success) {
          resetColorMode(response.displayMode ? "light" : "dark")
          // const subscriptionStatus = response.subscriptionStatus || "inactive"
          const expiryTime = dayjs().add(10, "minute").valueOf()
          if (response.tempPassword) {
            navigate("/newpassword")
          } else {
            localStorage.setItem("token", response.token)
            localStorage.setItem("entityDb", response.entityDb)
            localStorage.setItem("fname", response.fname)
            localStorage.setItem("lname", response.lname)
            localStorage.setItem("tokenExpiry", expiryTime)
            localStorage.setItem("dataStatus", response.dataStatus)
            localStorage.setItem("entityId", response.entityId)
            localStorage.setItem(
              "subscriptionStatus",
              response.subscriptionStatus
            )
            localStorage.setItem(
              "themeMode",
              response.displayMode ? "light" : "dark"
            )
            localStorage.setItem("dateFormat", response.dateFormat)
            localStorage.setItem("timeFormat", response.timeFormat)
            localStorage.setItem("defaultPage", response.defaultPage)
            localStorage.setItem(
              "user",
              JSON.stringify({ email: email, userId: response.userId })
            )
            if (
              response.dataStatus === "Ready" ||
              response.dataStatus === "In Process"
            ) {
              let defaultPage = response.defaultPage
              if (response.subscriptionStatus !== "Active") {
                navigate("/subscription/packages")
              } else {
                if (defaultPage === "Products Dashboard")
                  navigate("/products/dashboard")
                else if (defaultPage === "Customers Dashboard")
                  navigate("/customers/dashboard")
                else if (defaultPage === "Sales Dashboard") navigate("/sales")
                else if (defaultPage === "Employees Dashboard")
                  navigate("/employees/dashboard")
                else if (defaultPage === "Customers List")
                  navigate("/customers/allcustomers")
                else if (defaultPage === "Products List")
                  navigate("/products/products")
                else if (defaultPage === "Employees List")
                  navigate("/employees/allemployees")
                else {
                  if (response.dataStatus === "Ready") {
                    navigate("/businesssynopsis")
                  } else if (response.dataStatus === "In Process") {
                    navigate("/businesssynopsis/in-process")
                  }
                }
              }
            } else if (response.dataStatus === "Not Started") {
              navigate("/welcome")
            } else {
              setOpenErrorModal(true)
              setModalMessage({
                header: "Error",
                message: "Unexpected data status.",
              })
            }
          }
        } else {
          setOpenErrorModal(true)
          setModalMessage({ header: "Failure", message: response?.msg })
        }
      } catch (error) {
        setOpenErrorModal(true)
        setModalMessage({ header: "Failure", message: error.message })
      }
    },
  })

  return (
    <>
      <Stack display="flex" gap={2} alignItems="center">
        <Typography variant="h5" color="inherit" textAlign="center">
          Welcome to
        </Typography>
        <Logo fill="black" yfill="#1D47CE" />
        <Typography variant="h4" color="inherit" textAlign="center">
          Login
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack display="flex" gap={2} alignItems="center">
            <TextField
              sx={{
                display: "block",
                width: "350px",
                "& .MuiInputLabel-root": { color: "#000000" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#000000" },
              }}
              required
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                endAdornment: <InputAdornment position="end"></InputAdornment>,
              }}
            />
            <TextField
              sx={{
                display: "block",
                width: "350px",
                "& input::-ms-reveal, & input::-ms-clear": {
                  display: "none",
                },
                "& .MuiInputLabel-root": { color: "#000000" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#000000" },
              }}
              required
              name="password"
              label="Password"
              fullWidth
              type={showPassword ? "text" : "password"}
              variant="standard"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: "black" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              type="submit"
              loading={isLoading}
              sx={{
                backgroundColor: "#2642a8",
                padding: "10px 19px",
                borderRadius: 10,
                color: "#fff",
                width: "8rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2642a8",
                },
                "&:disabled": {
                  backgroundColor: "#2642a8a3",
                },
                "& .MuiCircularProgress-colorInherit": {
                  color: "white",
                },
              }}
            >
              Login
            </LoadingButton>
          </Stack>
        </form>
        <ReCAPTCHA
          ref={recaptcha}
          size="invisible"
          sitekey={process.env.REACT_APP_SITE_KEY}
        />
      </Stack>
      <Stack display="flex" alignItems="center">
        <Typography variant="body1" color="inherit" textAlign="center">
          Or
        </Typography>
        <Button
          variant="text"
          sx={{
            textTransform: "none",
            color: "#2642a8",
            padding: "10px 19px",
            borderRadius: 10,
            "&:hover": {
              backgroundColor: "#2642a810",
            },
          }}
          onClick={() => navigate("/forgotpassword")}
        >
          Forgot password?
        </Button>
        {/* <Typography variant="body1" color="inherit" textAlign="center">
            Don't have an account?
          </Typography>
          <Button
            sx={{
              mt: "16px",
              padding: "10px 19px",
              borderRadius: 10,
              color: "#2642a8",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#2642a810",
              },
            }}
            onClick={() => navigate("/registration")}
          >
            Sign Up
          </Button> */}
      </Stack>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">{modalMessage?.header}</Typography>
          <Typography variant="body1">{modalMessage?.message}</Typography>
        </Box>
      </Modal>
    </>
  )
}

export default Login
