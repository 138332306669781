const Paypal = () => {
  return (
    <svg
      width="100"
      height="25"
      viewBox="0 0 100 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.72406 5.80164H15.5595C19.2304 5.80164 20.6082 7.65656 20.3988 10.3927C20.0483 14.8961 17.3219 17.3888 13.7095 17.3888H11.8789C11.3823 17.3888 11.0512 17.715 10.9149 18.6059L10.1311 23.7715C10.0824 24.1074 9.90225 24.3021 9.63935 24.3265H5.35503C4.95094 24.3265 4.80975 24.0198 4.91199 23.3479L7.53127 6.78022C7.63838 6.11809 8.00352 5.80164 8.72406 5.80164Z"
        fill="#00AEEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.393 5.49498C40.7007 5.49498 42.8282 6.74619 42.5361 9.86206C42.1905 13.5573 40.1992 15.6167 37.0639 15.6264H34.3326C33.9383 15.6264 33.7484 15.9478 33.6462 16.605L33.1204 19.9692C33.0425 20.4755 32.7796 20.7287 32.3949 20.7287H29.8438C29.4397 20.7287 29.2985 20.4706 29.3862 19.8913L31.4894 6.4054C31.5916 5.74327 31.8448 5.49498 32.2976 5.49498H38.393ZM34.245 12.715H36.319C37.614 12.6663 38.4758 11.7705 38.5634 10.1493C38.6169 9.15126 37.9402 8.43558 36.8643 8.44045L34.912 8.45018L34.245 12.715ZM49.4543 19.6965C49.688 19.4872 49.9217 19.3752 49.8876 19.6381L49.8049 20.2613C49.7611 20.5875 49.8925 20.7627 50.1944 20.7627H52.4534C52.8331 20.7627 53.0181 20.607 53.1155 20.0179L54.5079 11.2739C54.5761 10.8358 54.4738 10.6167 54.1379 10.6167H51.6501C51.4261 10.6167 51.319 10.7433 51.2606 11.0841L51.1681 11.6245C51.1194 11.9069 50.9928 11.9555 50.8711 11.6732C50.4475 10.6751 49.3716 10.2272 47.8623 10.2564C44.3764 10.3294 42.0249 12.9779 41.7718 16.3713C41.577 18.9955 43.4612 21.0597 45.9392 21.0597C47.7406 21.0549 48.5488 20.5193 49.4543 19.6965ZM47.5605 18.3528C46.061 18.3528 45.0142 17.1552 45.2333 15.6848C45.4524 14.2145 46.8545 13.0218 48.354 13.0218C49.8536 13.0218 50.9003 14.2194 50.6812 15.6848C50.4621 17.1552 49.06 18.3528 47.5605 18.3528ZM58.9383 10.5875H56.6549C56.1827 10.5875 55.9879 10.9331 56.1389 11.3762L58.987 19.7111L56.1827 23.6741C55.949 24.0052 56.1291 24.3071 56.4602 24.3071H59.0356C59.4203 24.3071 59.6101 24.2097 59.8 23.9322L68.5537 11.3713C68.8214 10.9867 68.6948 10.5777 68.2518 10.5777L65.8127 10.5729C65.394 10.5729 65.2284 10.7384 64.985 11.0841L61.3336 16.3811L59.7075 11.0743C59.615 10.7579 59.3764 10.5875 58.9383 10.5875Z"
        fill="#03428E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.6676 5.49498C79.9752 5.49498 82.1028 6.74619 81.8107 9.86206C81.465 13.5573 79.4738 15.6167 76.3384 15.6264H73.6072C73.2128 15.6264 73.023 15.9478 72.9207 16.605L72.3949 19.9692C72.317 20.4755 72.0541 20.7287 71.6695 20.7287H69.1232C68.7192 20.7287 68.578 20.4706 68.6656 19.8913L70.7688 6.4054C70.8711 5.74327 71.1242 5.49498 71.577 5.49498H77.6676ZM73.5244 12.715H75.5984C76.8935 12.6663 77.7552 11.7705 77.8428 10.1493C77.8964 9.15126 77.2196 8.43558 76.1437 8.44045L74.1914 8.45018L73.5244 12.715ZM88.7289 19.6965C88.9626 19.4872 89.2011 19.3752 89.1622 19.6381L89.0794 20.2613C89.0356 20.5875 89.1671 20.7627 89.4689 20.7627H91.7279C92.1077 20.7627 92.2927 20.607 92.39 20.0179L93.7824 11.2739C93.8506 10.8358 93.7484 10.6167 93.4124 10.6167H90.9246C90.7007 10.6167 90.5935 10.7433 90.5351 11.0841L90.4426 11.6245C90.3939 11.9069 90.2674 11.9555 90.1456 11.6732C89.7221 10.6751 88.6461 10.2272 87.1369 10.2564C83.651 10.3294 81.2995 12.9779 81.0463 16.3713C80.8516 18.9955 82.7357 21.0597 85.2138 21.0597C87.02 21.0549 87.8233 20.5193 88.7289 19.6965ZM86.8399 18.3528C85.3404 18.3528 84.2936 17.1552 84.5127 15.6848C84.7318 14.2145 86.134 13.0218 87.6335 13.0218C89.133 13.0218 90.1797 14.2194 89.9606 15.6848C89.7416 17.1552 88.3394 18.3528 86.8399 18.3528ZM97.2683 20.7774H94.6588C94.4494 20.7774 94.3083 20.6167 94.3375 20.4171L96.6306 5.88933C96.6598 5.68972 96.8594 5.52906 97.0639 5.52906H99.6734C99.8827 5.52906 100.024 5.68972 99.9947 5.88933L97.7016 20.4171C97.6724 20.6118 97.4777 20.7774 97.2683 20.7774Z"
        fill="#00AEEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44945 0.100586H11.2898C13.2177 0.100586 15.5011 0.163878 17.0298 1.51247C18.0522 2.41315 18.5877 3.84937 18.4611 5.38783C18.0424 10.6118 14.9168 13.5378 10.725 13.5378H7.3511C6.77661 13.5378 6.39687 13.9175 6.23621 14.9497L5.29658 20.938C5.23328 21.3275 5.06775 21.5563 4.76104 21.5855H0.54974C0.0823593 21.5855 -0.0831713 21.2301 0.0385424 20.4511L3.07165 1.23983C3.19336 0.465727 3.61206 0.100586 4.44945 0.100586Z"
        fill="#03428E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33844 14.3411L7.53127 6.78022C7.63838 6.11809 7.99862 5.80164 8.71917 5.80164H15.5546C16.6841 5.80164 17.5994 5.97691 18.3151 6.3031C17.6286 10.9526 14.6198 13.5329 10.6861 13.5329H7.31215C6.86912 13.5378 6.53806 13.7617 6.33844 14.3411Z"
        fill="#0E3178"
      />
    </svg>
  );
};

export default Paypal;
