import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Chip from "@mui/material/Chip"
import CustomPaper from "../../../../components/CustomPaper"
import { useTheme } from "../../../../contexts/theme"

const PayrollSummary = () => {
  const { theme } = useTheme()

  return (
    <CustomPaper className="Payroll-Summary">
      <Box>
        {/* Payroll Summary */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          borderBottom="2px solid #091743"
        >
          <Typography mb="10px" color="inherit">
            Payroll Summary
          </Typography>
        </Box>

        {/* Payroll Summary Details */}
        <Box
          display="flex"
          justifyContent="space-between"
          mt={1.5}
          flexWrap="wrap"
          gap="20px"
        >
          <Stack rowGap="5px">
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                opacity: "70%",
                fontSize: "0.85rem",
              }}
            >
              Salary
            </Typography>
            <Typography
              variant="body1"
              color="rgba(80, 205, 137, 0.8)"
              sx={{ fontSize: "1.1rem" }}
            >
              $1,031
            </Typography>
          </Stack>
          <Stack rowGap="5px">
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                opacity: "70%",
                fontSize: "0.85rem",
              }}
            >
              Last Processed cycle
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontSize: "1.1rem" }}
            >
              March - 2023 (01 March - 30 March)
            </Typography>
          </Stack>
          <Stack rowGap="5px">
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                opacity: "70%",
                fontSize: "0.85rem",
              }}
            >
              No of Days
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontSize: "1.1rem" }}
            >
              31
            </Typography>
          </Stack>
          <Stack rowGap="5px">
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                opacity: "70%",
                fontSize: "0.85rem",
              }}
            >
              Loss of pay
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontSize: "1.1rem" }}
            >
              0
            </Typography>
          </Stack>
          <Stack rowGap="5px">
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                opacity: "70%",
                fontSize: "0.85rem",
              }}
            >
              Status
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontSize: "1.1rem" }}
            >
              <Chip
                size="small"
                label="Active"
                sx={{
                  backgroundColor: "rgba(80, 205, 137, 0.3)",
                  color: "rgb(80, 205, 137)",
                }}
              />
            </Typography>
          </Stack>
          <Box display="flex" alignItems="center">
            <Link
              href=""
              style={{
                textDecoration: "none",
              }}
            >
              <Typography color={theme.palette.accent} fontSize="0.8rem">
                View Pay Slip →
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </CustomPaper>
  )
}

export default PayrollSummary
