import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material"
import { useTheme } from "../contexts/theme"
import CustomPaper from "./CustomPaper"
import { useNavigate } from "react-router-dom"

const SentimentSlider = styled(Slider)({
  height: 10,
  "& .MuiSlider-track": {
    border: "none",
    opacity: "0%",
  },
  "& .MuiSlider-rail": {
    background: "linear-gradient(90deg, #FF2C5C 0%, #23BF6F 100%)",
    opacity: "100%",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#23BF6F",
    border: "3px solid #FFFFFFED",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "24px",
      height: "24px",
      background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-1 0 24 24"><path fill="%23FFFFFFED" d="M9 16.2l-3.5-3.5 1.4-1.4 2.1 2.1 5.3-5.3 1.4 1.4z"/></svg>') no-repeat center`,
      backgroundSize: "24px 24px",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#FFFFFFED",
    height: 13,
    width: 5,
    borderRadius: 2,
  },
})

const CutomerSatisfaction = () => {
  const navigate = useNavigate()
  const { theme } = useTheme()

  const viewDetailsClick = () => {
    navigate("/customers/dashboard/customerSentiment")
  }
  return (
    <CustomPaper
      watermarkBg={true}
      customStyles={{
        height: "100%",
        position: "relative",
      }}
    >
      {/* Coming soon overlay */}
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: theme.palette.mode === "dark" ? "#00000085" : "#FFFFFFA0",
          position: "absolute",
          zIndex: 1,
          top: "0px",
          left: "0px",
        }}
      >
        <Typography
          variant="body1"
          color="inherit"
          sx={{
            p: 1,
            borderRadius: 1,
            // textShadow: `0 0 0.5px ${theme.palette.text.primary}`,
          }}
          fontWeight="bold"
        >
          Coming soon
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        height="100%"
      >
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
          gap="0.3rem"
          justifyContent="center"
          sx={{ position: "relative" }}
        >
          <Box display="flex" width="100%" justifyContent="space-between">
            <Stack
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Typography fontSize="1.8rem">😡</Typography>
              <Typography fontSize="0.7rem">Not Happy</Typography>
            </Stack>
            <Stack display="flex" flexDirection="column" alignItems="flex-end">
              <Typography fontSize="1.8rem">😍</Typography>
              <Typography fontSize="0.7rem">Satisfied</Typography>
            </Stack>
          </Box>
          <SentimentSlider
            disabled
            defaultValue={85}
            step={1}
            marks={[
              {
                value: 25,
              },
              {
                value: 50,
              },
              {
                value: 75,
              },
            ]}
            min={0}
            max={100}
          />
        </Stack>

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ mx: "1.2rem", my: 0 }}
        />

        <Stack gap={1}>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ minWidth: "21ch" }}
          >
            Customer Satisfaction
          </Typography>
          <Button
            endIcon={<ArrowForwardIcon />}
            onClick={viewDetailsClick}
            sx={{
              color: "white",
              background: theme.palette.accent,
              textTransform: "none",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 100, 0, 1)"
                    : "rgba(29, 71, 226, 1)",
              },
            }}
          >
            View Details
          </Button>
        </Stack>
      </Box>
    </CustomPaper>
  )
}

export default CutomerSatisfaction
