import WordCloud from "react-d3-cloud"

const ReactWordCloudChart = ({ chartInput }) => {
  return (
    <WordCloud
      data={chartInput}
      font="Comfortaa"
      width={500}
      height={275}
      fontWeight="bold"
      padding={5}
    />
  )
}

export default ReactWordCloudChart
