import Box from "@mui/material/Box"
import Star from "../../src/assets/svgs/Star"

export const funnelData = [
  {
    id: "Step 1",
    value: 100,
    tooltipData: {
      current: {
        "Step 1": {
          value: 100,
          format: "Units",
        },
      },
      previous: {
        "Step 1": {
          value: 95,
          format: "Units",
        },
      },
    },
  },
  {
    id: "Step 2",
    value: 80,
    tooltipData: {
      current: {
        "Step 2": {
          value: 80,
          format: "Units",
        },
      },
      previous: {
        "Step 2": {
          value: 85,
          format: "Units",
        },
      },
    },
  },
  {
    id: "Step 3",
    value: 60,
    tooltipData: {
      current: {
        "Step 3": {
          value: 60,
          format: "Units",
        },
      },
      previous: {
        "Step 3": {
          value: 65,
          format: "Units",
        },
      },
    },
  },
  {
    id: "Step 4",
    value: 40,
    tooltipData: {
      current: {
        "Step 4": {
          value: 40,
          format: "Units",
        },
      },
      previous: {
        "Step 4": {
          value: 35,
          format: "Units",
        },
      },
    },
  },
  {
    id: "Step 5",
    value: 20,
    tooltipData: {
      current: {
        "Step 5": {
          value: 20,
          format: "Units",
        },
      },
      previous: {
        "Step 5": {
          value: 10,
          format: "Units",
        },
      },
    },
  },
]

export const funnelParameters = {
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  spacing: 10,
  valueFormat: ">-.0s",
  colors: { scheme: "spectral" },
  borderWidth: 20,
  borderColor: { from: "color", modifiers: [["darker", 0.2]] },
  labelColor: { from: "color", modifiers: [["darker", 3]] },
  enableBeforeSeparators: true,
  beforeSeparatorLength: 100,
  beforeSeparatorOffset: 20,
  enableAfterSeparators: true,
  afterSeparatorLength: 100,
  afterSeparatorOffset: 20,
  currentPartSizeExtension: 10,
  currentBorderWidth: 40,
  motionConfig: "gentle",
}

export const RadarChartData = [
  {
    category: "Quality",
    "Team A": 75,

    tooltipData: {
      current: {
        "Team A": {
          value: 75,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 70,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Accessibility",
    "Team A": 90,

    tooltipData: {
      current: {
        "Team A": {
          value: 90,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Product Range",
    "Team A": 80,

    tooltipData: {
      current: {
        "Team A": {
          value: 80,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 78,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Healthy",
    "Team A": 85,

    tooltipData: {
      current: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 80,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Customer Satisfaction",
    "Team A": 88,

    tooltipData: {
      current: {
        "Team A": {
          value: 88,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Service",
    "Team A": 28,

    tooltipData: {
      current: {
        "Team A": {
          value: 28,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
]

export const RadarChartParameters = {
  keys: ["Team A"],
  indexBy: "category",
  maxValue: 100,
  margin: { top: 20, right: 50, bottom: 20, left: 50 },
  curve: "linearClosed",
  gridShape: "linear",
  borderWidth: 2,
  borderColor: { from: "color", modifiers: [["darker", 6]] },
  gridLevels: 2,
  gridLabelOffset: 10,
  enableDots: false,
  dotSize: 10,
  dotColor: { theme: "background" },
  dotBorderWidth: 2,
  dotBorderColor: { from: "color" },
  enableDotLabel: false,
  dotLabel: "value",
  dotLabelYOffset: -12,
  colors: "#438B53",
  fillOpacity: 0.25,
  blendMode: "multiply",
  animate: true,
  motionConfig: "wobbly",
  isInteractive: true,
  legends: [
    {
      anchor: "top-left",
      direction: "column",
      translateX: -50,
      translateY: -40,
      itemWidth: 80,
      itemHeight: 20,
      itemTextColor: "#999",
      symbolSize: 12,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ],
}

export const Bumpchartdata = [
  {
    id: "KC GONZALEZ",
    data: [
      {
        x: "Jan",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4461.25, format: "Currency" } },
          previous: { Sales: { value: 3350.77, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2875.5, format: "Currency" } },
          previous: { Sales: { value: 2650.8, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3750.9, format: "Currency" } },
          previous: { Sales: { value: 3500.55, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.33, format: "Currency" } },
          previous: { Sales: { value: 3800.22, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2500.75, format: "Currency" } },
          previous: { Sales: { value: 2300.44, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1200.45, format: "Currency" } },
          previous: { Sales: { value: 1000.33, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.9, format: "Currency" } },
          previous: { Sales: { value: 4900.77, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 2875.5, format: "Currency" } },
          previous: { Sales: { value: 2650.8, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "BREE RUBENZER",
    data: [
      {
        x: "Jan",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1200.45, format: "Currency" } },
          previous: { Sales: { value: 1000.33, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.9, format: "Currency" } },
          previous: { Sales: { value: 4900.77, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.1, format: "Currency" } },
          previous: { Sales: { value: 4200.45, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5300.75, format: "Currency" } },
          previous: { Sales: { value: 5000.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 3000.4, format: "Currency" } },
          previous: { Sales: { value: 2750.33, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.1, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "CHRISTINE SMOCZYK",
    data: [
      {
        x: "Jan",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3300.75, format: "Currency" } },
          previous: { Sales: { value: 3100.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.2, format: "Currency" } },
          previous: { Sales: { value: 4700.1, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2200.55, format: "Currency" } },
          previous: { Sales: { value: 2000.4, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4850.3, format: "Currency" } },
          previous: { Sales: { value: 4600.75, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5100.45, format: "Currency" } },
          previous: { Sales: { value: 4900.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3750.9, format: "Currency" } },
          previous: { Sales: { value: 3500.55, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.33, format: "Currency" } },
          previous: { Sales: { value: 3800.22, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2500.75, format: "Currency" } },
          previous: { Sales: { value: 2300.44, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "KIM POPP",
    data: [
      {
        x: "Jan",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.7, format: "Currency" } },
          previous: { Sales: { value: 4200.9, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.1, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.6, format: "Currency" } },
          previous: { Sales: { value: 4200.85, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3600.45, format: "Currency" } },
          previous: { Sales: { value: 3400.2, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4200.5, format: "Currency" } },
          previous: { Sales: { value: 4000.45, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3100.75, format: "Currency" } },
          previous: { Sales: { value: 2900.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "Maria Silva",
    data: [
      {
        x: "Jan",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3600.45, format: "Currency" } },
          previous: { Sales: { value: 3400.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4200.5, format: "Currency" } },
          previous: { Sales: { value: 4000.45, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3100.75, format: "Currency" } },
          previous: { Sales: { value: 2900.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 2100.9, format: "Currency" } },
          previous: { Sales: { value: 1900.4, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3300.2, format: "Currency" } },
          previous: { Sales: { value: 3100.55, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.6, format: "Currency" } },
          previous: { Sales: { value: 4200.85, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "MARIA RAMOS",
    data: [
      {
        x: "Jan",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.75, format: "Currency" } },
          previous: { Sales: { value: 3900.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.3, format: "Currency" } },
          previous: { Sales: { value: 3900.1, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 2000.9, format: "Currency" } },
          previous: { Sales: { value: 1800.5, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4400.2, format: "Currency" } },
          previous: { Sales: { value: 4200.75, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.5, format: "Currency" } },
          previous: { Sales: { value: 3500.3, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.9, format: "Currency" } },
          previous: { Sales: { value: 3300.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.75, format: "Currency" } },
          previous: { Sales: { value: 3300.55, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 3800.6, format: "Currency" } },
          previous: { Sales: { value: 3600.45, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "PAMELA M",
    data: [
      {
        x: "Jan",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.3, format: "Currency" } },
          previous: { Sales: { value: 2700.2, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.9, format: "Currency" } },
          previous: { Sales: { value: 3300.7, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.75, format: "Currency" } },
          previous: { Sales: { value: 3300.55, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 3800.6, format: "Currency" } },
          previous: { Sales: { value: 3600.45, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2700.5, format: "Currency" } },
          previous: { Sales: { value: 2500.4, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "MAKENA E",
    data: [
      {
        x: "Jan",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5000.25, format: "Currency" } },
          previous: { Sales: { value: 4800.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1500.5, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4000.8, format: "Currency" } },
          previous: { Sales: { value: 3800.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1300.3, format: "Currency" } },
          previous: { Sales: { value: 1100.2, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.6, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.1, format: "Currency" } },
          previous: { Sales: { value: 5000.9, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3500.2, format: "Currency" } },
          previous: { Sales: { value: 3300.1, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4600.8, format: "Currency" } },
          previous: { Sales: { value: 4400.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "JESSICA K",
    data: [
      {
        x: "Jan",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 800.3, format: "Currency" } },
          previous: { Sales: { value: 600.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1000.7, format: "Currency" } },
          previous: { Sales: { value: 800.6, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.1, format: "Currency" } },
          previous: { Sales: { value: 5000.9, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3500.2, format: "Currency" } },
          previous: { Sales: { value: 3300.1, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4600.8, format: "Currency" } },
          previous: { Sales: { value: 4400.6, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3400.4, format: "Currency" } },
          previous: { Sales: { value: 3200.3, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.9, format: "Currency" } },
          previous: { Sales: { value: 3500.8, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.5, format: "Currency" } },
          previous: { Sales: { value: 2600.4, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "JOHNA SH",
    data: [
      {
        x: "Jan",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3400.4, format: "Currency" } },
          previous: { Sales: { value: 3200.3, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.9, format: "Currency" } },
          previous: { Sales: { value: 3500.8, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.5, format: "Currency" } },
          previous: { Sales: { value: 2600.4, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.6, format: "Currency" } },
          previous: { Sales: { value: 2600.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1300.7, format: "Currency" } },
          previous: { Sales: { value: 1100.4, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.75, format: "Currency" } },
          previous: { Sales: { value: 2700.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "ALEXA P",
    data: [
      {
        x: "Jan",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1500.6, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.75, format: "Currency" } },
          previous: { Sales: { value: 2700.6, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 2000.8, format: "Currency" } },
          previous: { Sales: { value: 1800.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 900.7, format: "Currency" } },
          previous: { Sales: { value: 700.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "AVA ARONSON",
    data: [
      {
        x: "Jan",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2700.5, format: "Currency" } },
          previous: { Sales: { value: 2500.4, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 900.7, format: "Currency" } },
          previous: { Sales: { value: 700.6, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3100.6, format: "Currency" } },
          previous: { Sales: { value: 2900.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1500.6, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
    ],
  },
]

export const BumpChartParameters = {
  margin: {
    top: 40,
    right: 100,
    bottom: 40,
    left: 60,
  },
  lineWidth: 3,
  activeLineWidth: 6,
  inactiveLineWidth: 3,
  inactiveOpacity: 0.15,
  pointSize: 10,
  activePointSize: 16,
  inactivePointSize: 0,
  pointColor: { theme: "background" },
  pointBorderWidth: 3,
  activePointBorderWidth: 3,
  pointBorderColor: { from: "serie.color" },
  axisTop: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: -36,
    truncateTickAt: 0,
  },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: 32,
    truncateTickAt: 0,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Ranking",
    legendPosition: "middle",
    legendOffset: -40,
    truncateTickAt: 0,
  },
  axisRight: null,
}

export const WordCloudData = [
  {
    text: "Fatty",
    value: 1845,
  },
  {
    text: "Organic",
    value: 1213,
  },
  {
    text: "Greasy",
    value: 1575,
  },
  {
    text: "Overcooked",
    value: 987,
  },
  {
    text: "Gluten-free",
    value: 362,
  },
  {
    text: "Vegan",
    value: 798,
  },
  {
    text: "Expired",
    value: 914,
  },
  {
    text: "Artificial Flowers",
    value: 671,
  },
  {
    text: "Delicious",
    value: 192,
  },
  {
    text: "Processed",
    value: 497,
  },
  {
    text: "Gourment",
    value: 1569,
  },
  {
    text: "Stale",
    value: 948,
  },
  {
    text: "Fresh",
    value: 716,
  },
  {
    text: "Bland",
    value: 831,
  },
  {
    text: "Homemade",
    value: 1082,
  },
  {
    text: "Sustainable",
    value: 994,
  },
  {
    text: "Healthy",
    value: 733,
  },
]

export const LineChartData = [
  {
    id: "Forecasted",
    color: "rgba(0, 158, 247, 1)",
    data: [
      { x: "Jan-01", y: 1000 },
      { x: "Feb-01", y: 2200 },
      { x: "Mar-01", y: 4400 },
      { x: "Apr-01", y: 2600 },
      { x: "May-01", y: 3300 },
      { x: "Jun-01", y: 1100 },
      { x: "Jul-01", y: 2500 },
      { x: "Aug-01", y: 1700 },
      { x: "Sep-01", y: 2000 },
      { x: "Oct-01", y: 1300 },
      { x: "Nov-01", y: 4500 },
      { x: "Dec-01", y: 2700 },
    ],
  },
  {
    id: "Actual",
    color: "rgba(80, 205, 137, 1)",
    data: [
      { x: "Jan-01", y: 1200 },
      { x: "Feb-01", y: 5300 },
      { x: "Mar-01", y: 3500 },
      { x: "Apr-01", y: 2400 },
      { x: "May-01", y: 6200 },
      { x: "Jun-01", y: 7600 },
      { x: "Jul-01", y: 5800 },
      { x: "Aug-01", y: 2100 },
      { x: "Sep-01", y: 2400 },
      { x: "Oct-01", y: 5600 },
      { x: "Nov-01", y: 4800 },
      { x: "Dec-01", y: 3000 },
    ],
  },
]

export const LineChartParameters = {
  margin: {
    top: 30,
    right: 50,
    bottom: 30,
    left: 50,
  },
  xScale: {
    type: "point",
    format: "%m-%d",
    precision: "day",
  },
  yScale: {
    type: "linear",
    min: 0,
    max: 12000,
    stacked: false,
    reverse: false,
    spacing: 0,
  },
  layers: [
    "grid",
    "markers",
    "axes",
    "areas",
    "crosshair",
    "lines",
    "slices",
    "points",
    "legends",
    "mesh",
    "annotations",
  ],
  curve: "cardinal",
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Date",
    legendOffset: 36,
    legendPosition: "middle",
  },
  axisLeft: {
    tickValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
    legendOffset: -40,
    legendPosition: "middle",
    format: (value) => (value >= 0 ? value : ""),
  },
  enablePoints: false,
  enableGridX: false,
  enableGridY: true,
  gridYValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
  enableArea: false,
  colors: ["rgba(80, 205, 137, 1)", "rgba(0, 158, 247, 1)"],
  pointSize: 4,
  pointBorderWidth: 2,
  pointBorderColor: {
    from: "serieColor",
  },
  useMesh: true,
}

export const DashLineChartData = [
  {
    id: "Actual",
    data: [
      {
        x: 1,
        y: 3283.68,
        tooltipData: {
          current: {
            Sales: {
              value: 3283.68,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3772.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 2,
        y: 3327.99,
        tooltipData: {
          current: {
            Sales: {
              value: 3327.99,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4199.89,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 3,
        y: 4899.86,
        tooltipData: {
          current: {
            Sales: {
              value: 4899.86,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3814.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 4,
        y: 3773.12,
        tooltipData: {
          current: {
            Sales: {
              value: 3773.12,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3911.54,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 5,
        y: 3015.48,
        tooltipData: {
          current: {
            Sales: {
              value: 3015.48,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4715.62,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 6,
        y: 2325.96,
        tooltipData: {
          current: {
            Sales: {
              value: 2325.96,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 7,
        y: 3783.66,
        tooltipData: {
          current: {
            Sales: {
              value: 3783.66,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 8,
        y: 3047.8,
        tooltipData: {
          current: {
            Sales: {
              value: 3047.8,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 333.37,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 9,
        y: 3449.76,
        tooltipData: {
          current: {
            Sales: {
              value: 3449.76,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4238.91,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 10,
        y: 5128.68,
        tooltipData: {
          current: {
            Sales: {
              value: 5128.68,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3369.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 11,
        y: 3541.7,
        tooltipData: {
          current: {
            Sales: {
              value: 3541.7,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4078,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 12,
        y: 3417.72,
        tooltipData: {
          current: {
            Sales: {
              value: 3417.72,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3412.24,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 13,
        y: 3497.3,
        tooltipData: {
          current: {
            Sales: {
              value: 3497.3,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2718.32,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 14,
        y: 5293.04,
        tooltipData: {
          current: {
            Sales: {
              value: 5293.04,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3471.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 15,
        y: 3807.56,
        tooltipData: {
          current: {
            Sales: {
              value: 3807.56,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3920.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 16,
        y: 3173.44,
        tooltipData: {
          current: {
            Sales: {
              value: 3173.44,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3797.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 17,
        y: 3562.6,
        tooltipData: {
          current: {
            Sales: {
              value: 3562.6,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3941.54,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 18,
        y: 4804,
        tooltipData: {
          current: {
            Sales: {
              value: 4804,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3977.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 19,
        y: 2375.58,
        tooltipData: {
          current: {
            Sales: {
              value: 2375.58,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3767.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 20,
        y: 2670.94,
        tooltipData: {
          current: {
            Sales: {
              value: 2670.94,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2795.23,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 21,
        y: 3953.26,
        tooltipData: {
          current: {
            Sales: {
              value: 3953.26,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2854.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 22,
        y: 3804.29,
        tooltipData: {
          current: {
            Sales: {
              value: 3804.29,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3719.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 23,
        y: 4098.72,
        tooltipData: {
          current: {
            Sales: {
              value: 4098.72,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2455.66,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 24,
        y: 3621.33,
        tooltipData: {
          current: {
            Sales: {
              value: 3621.33,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3815.25,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 25,
        y: 4160.49,
        tooltipData: {
          current: {
            Sales: {
              value: 4160.49,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4496.89,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 26,
        y: 2718.7,
        tooltipData: {
          current: {
            Sales: {
              value: 2718.7,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4459.45,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 27,
        y: 2485.69,
        tooltipData: {
          current: {
            Sales: {
              value: 2485.69,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3166.1,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 28,
        y: 3350.77,
        tooltipData: {
          current: {
            Sales: {
              value: 3350.77,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2405.79,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 29,
        y: 3300.89,
        tooltipData: {
          current: {
            Sales: {
              value: 3300.89,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3600.15,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Forecast",
    data: [
      {
        x: 1,
        y: 2628.32,
        tooltipData: {
          current: {
            Sales: {
              value: 2628.32,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3731.76,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 2,
        y: 3596.17,
        tooltipData: {
          current: {
            Sales: {
              value: 3596.17,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3405.58,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 3,
        y: 3563.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3563.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3668.35,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 4,
        y: 3664.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3664.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4169.93,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 5,
        y: 3817.15,
        tooltipData: {
          current: {
            Sales: {
              value: 3817.15,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4272.24,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 6,
        y: 3820.46,
        tooltipData: {
          current: {
            Sales: {
              value: 3820.46,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2521.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 7,
        y: 2531.42,
        tooltipData: {
          current: {
            Sales: {
              value: 2531.42,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2624.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 8,
        y: 2636.97,
        tooltipData: {
          current: {
            Sales: {
              value: 2636.97,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3636.37,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 9,
        y: 3561.18,
        tooltipData: {
          current: {
            Sales: {
              value: 3561.18,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3404.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 10,
        y: 3477.93,
        tooltipData: {
          current: {
            Sales: {
              value: 3477.93,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3652.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 11,
        y: 3586.8,
        tooltipData: {
          current: {
            Sales: {
              value: 3586.8,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4200.31,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 12,
        y: 3842.28,
        tooltipData: {
          current: {
            Sales: {
              value: 3842.28,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4252.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 13,
        y: 3901.04,
        tooltipData: {
          current: {
            Sales: {
              value: 3901.04,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2500.98,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 14,
        y: 2514.56,
        tooltipData: {
          current: {
            Sales: {
              value: 2514.56,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2603.51,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 15,
        y: 2616.3,
        tooltipData: {
          current: {
            Sales: {
              value: 2616.3,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3611.84,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 16,
        y: 3537.4,
        tooltipData: {
          current: {
            Sales: {
              value: 3537.4,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3385.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 17,
        y: 3450.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3450.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3632.11,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 18,
        y: 3559.97,
        tooltipData: {
          current: {
            Sales: {
              value: 3559.97,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4182.85,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 19,
        y: 3822.79,
        tooltipData: {
          current: {
            Sales: {
              value: 3822.79,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4232.44,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 20,
        y: 3885.49,
        tooltipData: {
          current: {
            Sales: {
              value: 3885.49,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2480.64,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 21,
        y: 2492.07,
        tooltipData: {
          current: {
            Sales: {
              value: 2492.07,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2583.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 22,
        y: 2593.53,
        tooltipData: {
          current: {
            Sales: {
              value: 2593.53,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3591.3,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 23,
        y: 3514.41,
        tooltipData: {
          current: {
            Sales: {
              value: 3514.41,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3365.46,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 24,
        y: 3427.29,
        tooltipData: {
          current: {
            Sales: {
              value: 3427.29,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3611.82,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 25,
        y: 3536.76,
        tooltipData: {
          current: {
            Sales: {
              value: 3536.76,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4162.71,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 26,
        y: 3800.1,
        tooltipData: {
          current: {
            Sales: {
              value: 3800.1,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4212.14,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 27,
        y: 3863.09,
        tooltipData: {
          current: {
            Sales: {
              value: 3863.09,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2460.33,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 28,
        y: 2469.17,
        tooltipData: {
          current: {
            Sales: {
              value: 2469.17,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2562.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 29,
        y: 2570.62,
        tooltipData: {
          current: {
            Sales: {
              value: 2570.62,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2469.26,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
]

export const DashLineChartParameters = {
  margin: { top: 40, right: 20, bottom: 50, left: 60 },
  xScale: {
    type: "point",
    min: 0,
    max: "auto",
  },
  yScale: {
    type: "linear",
    min: 0,
    max: "auto",
  },
  lineWidth: 3,

  layers: [
    "grid",
    "markers",
    "axes",
    "areas",
    "crosshair",
    "lines",
    "slices",
    "points",
    "legends",
    "mesh",
    "annotations",
  ],
  curve: "cardinal",
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Date",
    legendOffset: 36,
    legendPosition: "middle",
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Value",
    legendOffset: -55,
    legendPosition: "middle",
    format: (value) => (value >= 0 ? value : ""),
  },
  enablePoints: true,
  enableGridX: false,
  enableGridY: true,
  gridYValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
  enableArea: false,
  colors: ["rgb(251, 101, 29)", "rgb(0, 155, 230)"],
  pointSize: 4,
  pointBorderWidth: 2,
  pointBorderColor: {
    from: "serieColor",
  },
  useMesh: true,
}

export const BarChartData = [
  {
    id: "New",
    "Gross Sales": 4285.47,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4285.47,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.83,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2290.04,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 82,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.93,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Repeat",
    "Gross Sales": 92528.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
]

export const BarChartParameters = {
  keys: ["value"],
  indexBy: "id",
  margin: { top: 30, right: 10, bottom: 50, left: 60 },
  padding: 0.6,
  colors: "rgb(0, 108, 255), rgb(251, 101, 29)",
  borderColor: { from: "color", modifiers: [["darker", 1.6]] },
  borderRadius: 10,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -42,

    legendPosition: "middle",
    legendOffset: 32,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: "middle",
    legendOffset: -40,
  },
  labelTextColor: { from: "color", modifiers: [["darker", 1.6]] },
  enableLabel: false,
  isInteractive: false,
  layout: "horizantal",
}

export const PieChartData = [
  {
    id: "Walk In",
    value: 9457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Dine In",
    value: 3457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "To Go",
    value: 1457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
]

export const PieChartParameters = {
  margin: {
    top: 20,
    bottom: 15,
    left: 50,
  },
  startAngle: -5,
  innerRadius: 0.6,
  padAngle: 0,
  cornerRadius: 0,
  activeInnerRadiusOffset: 26,
  activeOuterRadiusOffset: 19,
  borderWidth: 1,
  borderColor: {
    from: "color",
    modifiers: [["darker", 0.2]],
  },
  enableSliceLabels: false,
  enableSlicesLabels: false,
  enableArcLinkLabels: false,
  arcLinkLabel: "value",
  arcLinkLabelsTextOffset: 0,
  arcLinkLabelsTextColor: "#333333",
  arcLinkLabelsOffset: -24,
  arcLinkLabelsDiagonalLength: 0,
  arcLinkLabelsStraightLength: 0,
  arcLinkLabelsThickness: 0,
  arcLinkLabelsColor: {
    from: "color",
  },
  enableArcLabels: false,
  arcLabelsRadiusOffset: 0.25,
  arcLabelsSkipAngle: 14,
  arcLabelsTextColor: {
    from: "color",
    modifiers: [["darker", 2]],
  },
  animate: false,
  meta_info: {
    format: "Currency",
  },
  tooltip_fields: "'Gross Sales','Customers','Transactions','Average Ticket'",
}

export const LineBarChartData = [
  {
    day: 1,
    date: "2024-8-1",
    barValue: 3283.68,
    lineValue: 3740.12,
    tooltipData: {
      currentDate: "2024-8-1",
      previousDate: "2024-7-3",
      current: {
        "Gross Sales": {
          value: 3283.68,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 44,
          format: "Currency",
        },
        "Discount Amount": {
          value: 72.45,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3740.12,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.72,
          format: "Currency",
        },
        "Return Amount": {
          value: 3,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 2,
    date: "2024-8-2",
    barValue: 3321.99,
    lineValue: 4188.27,
    tooltipData: {
      currentDate: "2024-8-2",
      previousDate: "2024-7-4",
      current: {
        "Gross Sales": {
          value: 3321.99,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.41,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4188.27,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 31.02,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.43,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 3,
    date: "2024-8-3",
    barValue: 4900.36,
    lineValue: 3676.93,
    tooltipData: {
      currentDate: "2024-8-3",
      previousDate: "2024-7-5",
      current: {
        "Gross Sales": {
          value: 4900.36,
          format: "Currency",
        },
        Customers: {
          value: 131,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.88,
          format: "Currency",
        },
        "Return Amount": {
          value: 23.5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.28,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3676.93,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.64,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 4,
    date: "2024-8-4",
    barValue: 3773.12,
    lineValue: 3900.37,
    tooltipData: {
      currentDate: "2024-8-4",
      previousDate: "2024-7-6",
      current: {
        "Gross Sales": {
          value: 3773.12,
          format: "Currency",
        },
        Customers: {
          value: 127,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.94,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 23.14,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3900.37,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.35,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.06,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 5,
    date: "2024-8-5",
    barValue: 2973.24,
    lineValue: 4715.62,
    tooltipData: {
      currentDate: "2024-8-5",
      previousDate: "2024-7-7",
      current: {
        "Gross Sales": {
          value: 2973.24,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 74.26,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4715.62,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.2,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.03,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 6,
    date: "2024-8-6",
    barValue: 2325.96,
    lineValue: 0,
    tooltipData: {
      currentDate: "2024-8-6",
      previousDate: "2024-7-8",
      current: {
        "Gross Sales": {
          value: 2325.96,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 12.37,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 7,
    date: "2024-8-7",
    barValue: 3768.57,
    lineValue: 0,
    tooltipData: {
      currentDate: "2024-8-7",
      previousDate: "2024-7-9",
      current: {
        "Gross Sales": {
          value: 3768.57,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.67,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.05,
          format: "Currency",
        },
        "Discount Amount": {
          value: 233.82,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 8,
    date: "2024-8-8",
    barValue: 3047.64,
    lineValue: 333.37,
    tooltipData: {
      currentDate: "2024-8-8",
      previousDate: "2024-7-10",
      current: {
        "Gross Sales": {
          value: 3047.64,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.86,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 333.37,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 24,
          format: "Number",
        },
        "Average Ticket": {
          value: 13.89,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 9,
    date: "2024-8-9",
    barValue: 3449.76,
    lineValue: 4238.91,
    tooltipData: {
      currentDate: "2024-8-9",
      previousDate: "2024-7-11",
      current: {
        "Gross Sales": {
          value: 3449.76,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.37,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.39,
          format: "Currency",
        },
        "Discount Amount": {
          value: 78,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4238.91,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.19,
          format: "Currency",
        },
        "Return Amount": {
          value: 14.86,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 10,
    date: "2024-8-10",
    barValue: 5123.1,
    lineValue: 3275.42,
    tooltipData: {
      currentDate: "2024-8-10",
      previousDate: "2024-7-12",
      current: {
        "Gross Sales": {
          value: 5123.1,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 6.55,
          format: "Currency",
        },
        "Discount Amount": {
          value: 44.26,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3275.42,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 47.49,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 11,
    date: "2024-8-11",
    barValue: 3534.91,
    lineValue: 4077.01,
    tooltipData: {
      currentDate: "2024-8-11",
      previousDate: "2024-7-13",
      current: {
        "Gross Sales": {
          value: 3534.91,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.16,
          format: "Currency",
        },
        "Discount Amount": {
          value: 64.64,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4077.01,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 12,
    date: "2024-8-12",
    barValue: 3417.72,
    lineValue: 3412.24,
    tooltipData: {
      currentDate: "2024-8-12",
      previousDate: "2024-7-14",
      current: {
        "Gross Sales": {
          value: 3417.72,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.78,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3412.24,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 13,
    date: "2024-8-13",
    barValue: 3443.92,
    lineValue: 2718.32,
    tooltipData: {
      currentDate: "2024-8-13",
      previousDate: "2024-7-15",
      current: {
        "Gross Sales": {
          value: 3443.92,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 10.93,
          format: "Currency",
        },
        "Discount Amount": {
          value: 2.58,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2718.32,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 14,
    date: "2024-8-14",
    barValue: 5222.11,
    lineValue: 3471.69,
    tooltipData: {
      currentDate: "2024-8-14",
      previousDate: "2024-7-16",
      current: {
        "Gross Sales": {
          value: 5222.11,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 21.09,
          format: "Currency",
        },
        "Discount Amount": {
          value: 321.6,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3471.69,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 15,
    date: "2024-8-15",
    barValue: 3807.56,
    lineValue: 3920.59,
    tooltipData: {
      currentDate: "2024-8-15",
      previousDate: "2024-7-17",
      current: {
        "Gross Sales": {
          value: 3807.56,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.25,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3920.59,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 35.32,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 319.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 16,
    date: "2024-8-16",
    barValue: 3173.44,
    lineValue: 3796.93,
    tooltipData: {
      currentDate: "2024-8-16",
      previousDate: "2024-7-18",
      current: {
        "Gross Sales": {
          value: 3173.44,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 25.91,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3796.93,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.21,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.2,
          format: "Currency",
        },
        "Discount Amount": {
          value: 35.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 17,
    date: "2024-8-17",
    barValue: 3562.6,
    lineValue: 3922.97,
    tooltipData: {
      currentDate: "2024-8-17",
      previousDate: "2024-7-19",
      current: {
        "Gross Sales": {
          value: 3562.6,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 91.12,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3922.97,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.24,
          format: "Currency",
        },
        "Return Amount": {
          value: 0.63,
          format: "Currency",
        },
        "Discount Amount": {
          value: 96.71000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 18,
    date: "2024-8-18",
    barValue: 4795.91,
    lineValue: 3977.17,
    tooltipData: {
      currentDate: "2024-8-18",
      previousDate: "2024-7-20",
      current: {
        "Gross Sales": {
          value: 4795.91,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.56,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 103.35,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3977.17,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.03,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 189.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 19,
    date: "2024-8-19",
    barValue: 2375.58,
    lineValue: 3763.96,
    tooltipData: {
      currentDate: "2024-8-19",
      previousDate: "2024-7-21",
      current: {
        "Gross Sales": {
          value: 2375.58,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        "Average Ticket": {
          value: 22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.48,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3763.96,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.38,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.02,
          format: "Currency",
        },
        "Discount Amount": {
          value: 57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 20,
    date: "2024-8-20",
    barValue: 2664.94,
    lineValue: 2797.87,
    tooltipData: {
      currentDate: "2024-8-20",
      previousDate: "2024-7-22",
      current: {
        "Gross Sales": {
          value: 2664.94,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.66,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.75,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.95,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2797.87,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.98,
          format: "Currency",
        },
        "Return Amount": {
          value: 9.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 11.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 21,
    date: "2024-8-21",
    barValue: 3953.26,
    lineValue: 2854.63,
    tooltipData: {
      currentDate: "2024-8-21",
      previousDate: "2024-7-23",
      current: {
        "Gross Sales": {
          value: 3953.26,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.41,
          format: "Currency",
        },
        "Return Amount": {
          value: 33.1,
          format: "Currency",
        },
        "Discount Amount": {
          value: 230.44,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2854.63,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.79,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 1.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 22,
    date: "2024-8-22",
    barValue: 3804.29,
    lineValue: 3643.13,
    tooltipData: {
      currentDate: "2024-8-22",
      previousDate: "2024-7-24",
      current: {
        "Gross Sales": {
          value: 3804.29,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.82,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 86.62,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3643.13,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.99,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.69,
          format: "Currency",
        },
        "Discount Amount": {
          value: 142.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 23,
    date: "2024-8-23",
    barValue: 4092.72,
    lineValue: 2455.66,
    tooltipData: {
      currentDate: "2024-8-23",
      previousDate: "2024-7-25",
      current: {
        "Gross Sales": {
          value: 4092.72,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.75,
          format: "Currency",
        },
        "Return Amount": {
          value: 20.3,
          format: "Currency",
        },
        "Discount Amount": {
          value: 32.81,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2455.66,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.8,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 43.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 24,
    date: "2024-8-24",
    barValue: 3617.33,
    lineValue: 3815.25,
    tooltipData: {
      currentDate: "2024-8-24",
      previousDate: "2024-7-26",
      current: {
        "Gross Sales": {
          value: 3617.33,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 14.1,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3815.25,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 87.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 25,
    date: "2024-8-25",
    barValue: 4175.49,
    lineValue: 4482.68,
    tooltipData: {
      currentDate: "2024-8-25",
      previousDate: "2024-7-27",
      current: {
        "Gross Sales": {
          value: 4175.49,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 19,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.71,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4482.68,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 12.15,
          format: "Currency",
        },
        "Discount Amount": {
          value: 22.64,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 26,
    date: "2024-8-26",
    barValue: 2718.7,
    lineValue: 4457.98,
    tooltipData: {
      currentDate: "2024-8-26",
      previousDate: "2024-7-28",
      current: {
        "Gross Sales": {
          value: 2718.7,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.46,
          format: "Currency",
        },
        "Discount Amount": {
          value: 63.57000000000001,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4457.98,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.78,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.45,
          format: "Currency",
        },
        "Discount Amount": {
          value: 163.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 27,
    date: "2024-8-27",
    barValue: 2485.69,
    lineValue: 3166.1,
    tooltipData: {
      currentDate: "2024-8-27",
      previousDate: "2024-7-29",
      current: {
        "Gross Sales": {
          value: 2485.69,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.86,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.54,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3166.1,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.87,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 30.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 28,
    date: "2024-8-28",
    barValue: 3342.88,
    lineValue: 2398.79,
    tooltipData: {
      currentDate: "2024-8-28",
      previousDate: "2024-7-30",
      current: {
        "Gross Sales": {
          value: 3342.88,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        "Average Ticket": {
          value: 33.1,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 247.86,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2398.79,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        "Average Ticket": {
          value: 22.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 44.08,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.53,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 29,
    date: "2024-8-29",
    barValue: 3300.89,
    lineValue: 3568.35,
    tooltipData: {
      currentDate: "2024-8-29",
      previousDate: "2024-7-31",
      current: {
        "Gross Sales": {
          value: 3300.89,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.85,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3568.35,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.74,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 200.43,
          format: "Currency",
        },
      },
    },
  },
]

export const LineBarChartParameters = {
  keys: "Net Sales",
  indexBy: "day",
  margin: {
    top: "20",
    right: "10",
    bottom: "60",
    left: "60",
  },
  padding: 0.6,
  borderRadius: 6,
  enableLabel: false,
  axisBottom: {
    enableTicks: true,
    enableLabel: false,
    tickSize: 5,
    tickPadding: 5,
    tickValues: "every 4 day",
    legendOffset: 36,
    legendPosition: "middle",
    tickRotation: 45,
  },
  axisLeft: {
    tickSize: "5",
    tickPadding: "5",
    tickRotation: "0",
  },
  colors: "rgba(0, 155, 230, 1)",
  theme: "{customTheme}",
  labelTextColor: {
    from: "color",
    modifiers: "[[darker, 1.6]]",
  },
  layers: ["grid", "axes", "bars"],
  legends: {
    dataFrom: "keys",
    anchor: "bottom-right",
    direction: "column",
    justify: "false",
    translateX: "120",
    translateY: "0",
    itemsSpacing: "5",
    itemDirection: "left-to-right",
    itemWidth: "100",
    itemHeight: "20",
    itemOpacity: "0.85",
    symbolSize: "20",
  },
  view_by_period: ["Day", "Week", "Month", "Quarter", "Year"],
  meta_info: {
    format: "Currency",
  },
  compared_by_period: ["Previous Period", "Previous Year"],
  columns: [
    "Gross Sales",
    "New  Customers",
    "Repeat Customers",
    "Total Transactions",
    "Average Ticket Size",
    "Returns",
    "Discounts",
  ],
  focus_mode: true,
  tooltip_fields:
    "'Gross Sales','Customers','Transactions','Average Ticket','Return Amount','Discount Amount'",
  tooltip_format: "Currency,Number,Number,Currency,Currency,Currency",
  tooltip_data: [
    {
      name: "Gross Sales",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Average Ticket",
      format: "Currency",
      color: "",
    },
    {
      name: "Return Amount",
      format: "Currency",
      color: "",
    },
    {
      name: "Discount Amount",
      format: "Currency",
      color: "",
    },
  ],
  chart_colors: {
    line: {
      dark: "#fb651d",
      light: "#fb651d",
    },
    lineCircle: {
      dark: {
        fill: "#fb651d",
        stroke: "#ffffff",
      },
      light: {
        fill: "#ffffff",
        stroke: "#fb651d",
      },
    },
    bar: {
      dark: "#2647d9",
      light: "#2647d9",
    },
  },
  viewBy: "Day",
}

export const PopularProductList = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      SNo: 1,
      Name: "Sukiyaki",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 2,
      Name: "Donburi",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 3,
      Name: "Okonomiyaki",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 4,
      Name: "Temura",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 5,
      Name: "Yakitari",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 6,
      Name: "Onigiri",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 7,
      Name: "Cheesebusrger Deluxe",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 8,
      Name: "Ceasar Salad",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 9,
      Name: "Apple Pie",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 10,
      Name: "Guacamole and Chips",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
  ],
}

export const PopularProductParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            "[ { field: 'SNo', headerName: 'S.No', flex: 1, minWidth: 50, headerClassName: 'datagrid-header' },{ field: 'Name', headerName: 'Name', flex: 1, minWidth: 150, headerClassName: 'datagrid-header' },{ field: 'Item Investement', headerName: 'Item Investement', flex: 1, minWidth: 110, headerClassName: 'datagrid-header' },{ field: 'Item Sold', headerName: 'Item Sold', flex: 1, minWidth: 70, headerClassName: 'datagrid-header' },{ field: 'Profit', headerName: 'Profit', flex: 1, minWidth: 70, headerClassName: 'datagrid-header',renderCell: (params) => <div style={{ color: '#50DC89' }} },{ field: 'Profit %', headerName: 'Profit %', flex: 1, minWidth: 70, headerClassName: 'datagrid-header',renderCell: (params) => <div style={{ color: '#50DC89' }} }, ]",
        },
      ],
    },
  ],
  columns: [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 1,
      minWidth: 50,
      headerClassName: "datagrid-header",
    },
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "Item Investement",
      headerName: "Item Investement",
      flex: 1,
      minWidth: 110,
      headerClassName: "datagrid-header",
    },
    {
      field: "Item Sold",
      headerName: "Item Sold",
      flex: 1,
      minWidth: 70,
      headerClassName: "datagrid-header",
    },
    {
      field: "Profit",
      headerName: "Profit",
      flex: 1,
      minWidth: 70,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <div style={{ color: "#50DC89" }}>{params.value}</div>
      ),
    },
    {
      field: "Profit %",
      headerName: "Profit %",
      flex: 1,
      minWidth: 70,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <div style={{ color: "#50DC89" }}>{params.value}</div>
      ),
    },
  ],
}

export const TopEmployeeList = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      EmpID: 14997,
      Name: "Theressa Webb",
      Role: "Chef",
      "No. of Leaves": 1,
      Salary: "$2500",
    },
    {
      EmpID: 12118,
      Name: "Ronalds Rechards",
      Role: "Manager",
      "No. of Leaves": 1,
      Salary: "$2500",
    },
    {
      EmpID: 14461,
      Name: "Bessie Cooper",
      Role: "Ass. MAnager",
      "No. of Leaves": 6,
      Salary: "$1500",
    },
    {
      EmpID: 11407,
      Name: "Brroklys Symons",
      Role: "Cassier",
      "No. of Leaves": 2,
      Salary: "$1200",
    },
    {
      EmpID: 14907,
      Name: "Savannah Nguyan",
      Role: "Catering MAnager",
      "No. of Leaves": 11,
      Salary: "$3200",
    },
    {
      EmpID: 13897,
      Name: "Floyad Mines",
      Role: "Event Planners",
      "No. of Leaves": 4,
      Salary: "$1200",
    },
    {
      EmpID: 10997,
      Name: "Eleonar Pena",
      Role: "Maintainance Worker",
      "No. of Leaves": 7,
      Salary: "$2900",
    },
    {
      EmpID: 12097,
      Name: "Jennys wison",
      Role: "Hoistess",
      "No. of Leaves": 9,
      Salary: "$2100",
    },
    {
      EmpID: 10697,
      Name: "Kathryn Murphy",
      Role: "Server",
      "No. of Leaves": 3,
      Salary: "$4500",
    },
    {
      EmpID: 15757,
      Name: "Jakeb jones",
      Role: "Saous chef",
      "No. of Leaves": 5,
      Salary: "$1500",
    },
  ],
}

export const TopEmployeeListParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            "[ { field: 'EmpID', headerName: 'Emp ID', flex: 1, minWidth: 100, headerClassName: 'datagrid-header' } ,{ field: 'Name', headerName: 'Name', flex: 1, minWidth: 200, headerClassName: 'datagrid-header' }, { field: 'Role', headerName: 'Role', flex: 1, minWidth: 100, headerClassName: 'datagrid-header' }, { field: 'No. of Leaves', headerName: 'No. of Leaves', flex: 1, minWidth: 50, headerClassName: 'datagrid-header' },{ field: 'Salary', headerName: 'Salary', flex: 1, minWidth: 100, headerClassName: 'datagrid-header' },]",
        },
      ],
    },
  ],
  columns: [
    {
      field: "EmpID",
      headerName: "Emp ID",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Role",
      headerName: "Role",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "No. of Leaves",
      headerName: "No. of Leaves",
      flex: 1,
      minWidth: 50,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "Salary",
      headerName: "Salary",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: "#50DC89",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ],
}

export const SentimentReviewsList = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      Sentiment: "Negative",
      Category: "Food Quality",
      Comment: "The main course was overcooked and tasteless.",
    },
    {
      Sentiment: "Positive",
      Category: " Ambiance",
      Comment: "The restaurant had a cozy and inviting atmosphere.",
    },
    {
      Sentiment: "Negative",
      Category: "Ambiance",
      Comment: "Unpleasant odor in the dining area.",
    },
    {
      Sentiment: "Positive",
      Category: "Food Quality",
      Comment: "The food was absolutely delicious! ",
    },
    {
      Sentiment: "Positive",
      Category: "Service",
      Comment: "The staff was attentive and friendly.",
    },
    {
      Sentiment: "Negative",
      Category: "Value for Money",
      Comment: "Prices too high for the quality of the food.",
    },
    {
      Sentiment: "Negative",
      Category: "Communication",
      Comment: "Confusing menu descriptions; misleading.",
    },
  ],
}

export const SentimentReviewsParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            "[{ field: 'Sentiment', headerName: 'Sentiment', flex: 1, minWidth: 100, headerClassName: 'datagrid-header' } ,{ field: 'Category', headerName: 'Category', flex: 1, minWidth: 200, headerClassName: 'datagrid-header' }, { field: 'Comment', headerName: 'Comment', flex: 1, minWidth: 100, headerClassName: 'datagrid-header'},]",
        },
      ],
    },
  ],
  columns: [
    {
      field: "Sentiment",
      headerName: "Sentiment",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
      renderCell: (params) => {
        let color = params.value === "Positive" ? "green" : "red"
        return <span style={{ color: color }}>{params.value}</span>
      },
    },
    {
      field: "Category",
      headerName: "Category",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Comment",
      headerName: "Comment",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
  ],
}

export const ListData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      test: true,
      ProductName: "Chicken Whole",
      "Total Sales Quantity": 1217.17,
    },
    { ProductName: "Chicken Leg Qrt Cut Up", "Total Sales Quantity": 665.66 },
    { ProductName: "Chicken BLS Thigh", "Total Sales Quantity": 612.81 },
    { ProductName: "Cilantro Bunch", "Total Sales Quantity": 565 },
    { ProductName: "Goat Cut Up", "Total Sales Quantity": 337.69 },
    { ProductName: "Curry Leaves (Pack)", "Total Sales Quantity": 312 },
    { ProductName: "Betel Leaf / Paan", "Total Sales Quantity": 303 },
    { ProductName: "Mint Leaves - (Bunch)", "Total Sales Quantity": 300 },
    { ProductName: "Chicken Breast Boti", "Total Sales Quantity": 245.22 },
    { ProductName: "Onion (Red) (lb)", "Total Sales Quantity": 219.32 },
  ],
}

export const ListParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            "[ { field: 'ProductName', headerName: 'Product Name', flex: 1, minWidth: 200, headerClassName: 'datagrid-header' }, { field: 'Total Sales Quantity', headerName: 'Total Sales Quantity', flex: 1, minWidth: 150, headerClassName: 'datagrid-header' } ]",
        },
      ],
    },
  ],
  columns: [
    {
      field: "ProductName",
      headerName: "Product Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Total Sales Quantity",
      headerName: "Total Sales Quantity",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
  ],
}

export const DraftsData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      SNo: 1,
      campaign: "Beat the heat with our summer specials",
      recipientsType: "Email",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 2,
      campaign: "SocialMediaAds",
      recipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 3,
      campaign: "EventRegistration",
      recipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 4,
      campaign: "InfluencerCollab",
      recipientsType: "Email",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 5,
      campaign: "ContentMarketing",
      recipientsType: "SMS",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 6,
      campaign: "ReferralProgram",
      recipientsType: "Email",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 7,
      campaign: "FreeTrialOffer",
      recipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 8,
      campaign: "FreeTrialOffer",
      recipientsType: "SMS",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 9,
      campaign: "FreeTrialOffer",
      recipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 10,
      campaign: "FreeTrialOffer",
      recipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 11,
      campaign: "FreeTrialOffer",
      recipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 12,
      campaign: "FreeTrialOffer",
      recipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
  ],
}
export const TargetedCustomersData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      SNo: 1,
      Customers: "Dianne Russell",
      EmailAddress: "diannerussell@example.com",
      PhoneNumber: "(307) 555-0133",
      CustomerRating: "4.3",
      AmtSpent: "$3,054.43",
    },
    {
      id: 2,
      SNo: 2,
      Customers: "Jerome Bell",
      EmailAddress: "jeromebell@example.com",
      PhoneNumber: "(205) 555-0100",
      CustomerRating: "4.3",
      AmtSpent: "$2,700.86",
    },
    {
      id: 3,
      SNo: 3,
      Customers: "Kristin Watson",
      EmailAddress: "kristinwatson@example.com",
      PhoneNumber: "(302) 555-0107",
      CustomerRating: "4.3",
      AmtSpent: "$2,543.64",
    },
    {
      id: 4,
      SNo: 4,
      Customers: "Ralph Edwards",
      EmailAddress: "ralphedwards@example.com",
      PhoneNumber: "(201) 555-0124",
      CustomerRating: "4.3",
      AmtSpent: "$2,285.54",
    },
    {
      id: 5,
      SNo: 5,
      Customers: "Wade Warren",
      EmailAddress: "wadewarren@example.com",
      PhoneNumber: "(480) 555-0103",
      CustomerRating: "4.3",
      AmtSpent: "$2,135.75",
    },
    {
      id: 6,
      SNo: 6,
      Customers: "Marketing",
      EmailAddress: "marketing@example.com",
      PhoneNumber: "(252) 555-0126",
      CustomerRating: "4.3",
      AmtSpent: "$1,956.76",
    },
    {
      id: 7,
      SNo: 7,
      Customers: "Robert Fox",
      EmailAddress: "robertfox@example.com",
      PhoneNumber: "(808) 555-0111",
      CustomerRating: "4.3",
      AmtSpent: "$1,906.76",
    },
    {
      id: 8,
      SNo: 8,
      Customers: "Jenny Wilson",
      EmailAddress: "jennywilson@example.com",
      PhoneNumber: "(505) 555-0125",
      CustomerRating: "4.3",
      AmtSpent: "$1,754.86",
    },
    {
      id: 9,
      SNo: 9,
      Customers: "Cody Fisher",
      EmailAddress: "codfisher@example.com",
      PhoneNumber: "(239) 555-0108",
      CustomerRating: "4.3",
      AmtSpent: "$1,643.87",
    },
    {
      id: 10,
      SNo: 10,
      Customers: "Eleanor Pena",
      EmailAddress: "eleanorpena@example.com",
      PhoneNumber: "(217) 555-0113",
      CustomerRating: "4.3",
      AmtSpent: "$1,765.87",
    },
    {
      id: 11,
      SNo: 11,
      Customers: "Kathryn Murohy",
      EmailAddress: "kathrynmurohy@example.com",
      PhoneNumber: "(312) 555-0114",
      CustomerRating: "4.3",
      AmtSpent: "$2,265.87",
    },
    {
      id: 12,
      SNo: 12,
      Customers: "Guy Hawkins",
      EmailAddress: "guyhawkins@example.com",
      PhoneNumber: "(414) 555-0115",
      CustomerRating: "4.3",
      AmtSpent: "$2,765.87",
    },
  ],
}

export const TargetedCustomersParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"S.No","headerName":"S.No","flex":0.5,"minWidth":50,"headerClassName":"datagrid-header"},{"field":"Customers","headerName":"Customers","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Email Address","headerName":"Email Address","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Phone Number","headerName":"Phone Number","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Customer Rating","headerName":"Customer Rating","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Amt Spent","headerName":"Amt Spent","flex":1,"minWidth":150,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 0.5,
      minWidth: 50,
      headerClassName: "datagrid-header",
    },
    {
      field: "Customers",
      headerName: "Customers",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "EmailAddress",
      headerName: "Email Address",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "CustomerRating",
      headerName: "Customer Rating",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <Box display="flex" justifyContent="left">
          <Box display={"felx"} justifyContent={"center"}>
            {params.value} {""}
            <Star />
          </Box>
        </Box>
      ),
    },
    {
      field: "AmtSpent",
      headerName: "Amt Spent",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <Box display="flex" justifyContent="left" sx={{ color: "#50DC89" }}>
          {params.value}
        </Box>
      ),
    },
  ],
}
export const DraftsParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"S.No","headerName":"S.No","flex":0.5,"minWidth":50,"headerClassName":"datagrid-header"},{"field":"Campaign","headerName":"Campaign","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Recipients Type","headerName":"Recipients Type","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"QR/Coupon Type","headerName":"QR/Coupon Type","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"QR/Coupon Code","headerName":"QR/Coupon Code","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Saved Date","headerName":"Saved Date","flex":1,"minWidth":150,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 0.5,
      minWidth: 50,
      headerClassName: "datagrid-header",
    },
    {
      field: "campaign",
      headerName: "Campaign",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "recipientsType",
      headerName: "Recipients Type",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "QRCouponType",
      headerName: "QR/Coupon Type",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "QRCouponCode",
      headerName: "QR/Coupon Code",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "savedDate",
      headerName: "Saved Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
  ],
}

export const PastCampaignData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      campaign: "HolidayPromo",
      RecipientsType: "Email",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 2,
      campaign: "SocialMediaAds",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 3,
      campaign: "EventRegistration",
      RecipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 4,
      campaign: "PromoDiscount",
      RecipientsType: "Email",
      QRCouponType: "Discount",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 5,
      campaign: "FlyerCampaign",
      RecipientsType: "Email",
      QRCouponType: "Flyer",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 6,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 7,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 8,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 9,
      campaign: "FreeTrialOffer",
      RecipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 10,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 11,
      campaign: "FreeTrialOffer",
      RecipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 12,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
  ],
}

export const PastCampaignParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"id","headerName":"S.No","flex":0.5, "minWidth":50,"headerClassName":"datagrid-header"},{"field":"campaign","headerName":"campaign","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"RecipientsType","headerName":"Recipients Type","flex":1,"minWidth":100,"headerClassName":"datagrid-header"},{"field":"QRCouponType","headerName":"QR/Coupon Type","flex":1,"minWidth":100,"headerClassName":"datagrid-header"},{"field":"QRCouponCode","headerName":"QR/Coupon Code","flex":1,"minWidth":100,"headerClassName":"datagrid-header"},{"field":"attendedMembers","headerName":"Attended Members","flex":1,"minWidth":50,"headerClassName":"datagrid-header"},{"field":"RespondStatus","headerName":"Respond Status","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"createdDate","headerName":"created Date","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"AmountSpent","headerName":"Amount Spent","flex":1,"minWidth":150,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],

  columns: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "campaign",
      headerName: "campaign",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "RecipientsType",
      headerName: "Recipients Type",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "QRCouponType",
      headerName: "QR/Coupon Type",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "QRCouponCode",
      headerName: "QR/Coupon Code",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "attendedMembers",
      headerName: "Attended Members",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "RespondStatus",
      headerName: "Respond Status",
      // flex: 1,
      minWidth: 175,
      headerClassName: "datagrid-header",
      renderCell: (params) => {
        const styles = {
          display: "flex",
          padding: "8px",
          marginTop: "20px",
          lineHeight: "1",
          justifyContent: "center",
          alignItems: "center",
          gap: "1px",
          borderRadius: "10px",
          border:
            params.value === "Responded"
              ? "1px solid rgba(80, 205, 137, 0.50)"
              : "1px solid rgba(238, 0, 12, 0.40)",
          background:
            params.value === "Responded"
              ? "rgba(80, 205, 137, 0.30)"
              : "rgba(238, 0, 12, 0.20)",
          color: params.value === "Responded" ? "#50cd89" : "#ee000c",
        }
        return <div style={styles}>{params.value}</div>
      },
    },
    {
      field: "createdDate",
      headerName: "created Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "AmountSpent",
      headerName: "Amount Spent",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
      renderCell: (params) => {
        const styles = {
          color: "#50cd89",
        }
        return <div style={styles}>{params.value}</div>
      },
    },
  ],
}

export const HeatmapChartData = [
  {
    id: "Sunday",
    data: [
      {
        x: "9AM",
        y: 25,
        previousY: 19,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 446.66,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.87,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.5,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 382.81,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.15,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.76,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 48,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 949.7,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.97,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1168.63,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.4,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 71,
        previousY: 71,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1963.86,
              format: "Currency",
            },
            Customers: {
              value: 62,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.66,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.55,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2040.91,
              format: "Currency",
            },
            Customers: {
              value: 57,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.75,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 91,
        previousY: 66,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2617.65,
              format: "Currency",
            },
            Customers: {
              value: 64,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.77,
              format: "Currency",
            },
            "Return Amount": {
              value: 2,
              format: "Currency",
            },
            "Discount Amount": {
              value: 27.45,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1637.54,
              format: "Currency",
            },
            Customers: {
              value: 52,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.81,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 62,
        previousY: 65,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1773.69,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.52,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1570.15,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.16,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.67,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 52,
        previousY: 51,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1386.95,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 9,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2274.08,
              format: "Currency",
            },
            Customers: {
              value: 36,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 44.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 156.48,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 44,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1188.48,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.01,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.12,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1416.17,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 36.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 37,
        previousY: 41,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 710.38,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 14.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1026.23,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.03,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 63,
        previousY: 53,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1216.48,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.31,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 25,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1288.87,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 13.53,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 46,
        previousY: 67,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1168.28,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.4,
              format: "Currency",
            },
            "Return Amount": {
              value: 19,
              format: "Currency",
            },
            "Discount Amount": {
              value: 21.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1404.09,
              format: "Currency",
            },
            Customers: {
              value: 52,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.97,
              format: "Currency",
            },
            "Discount Amount": {
              value: 26.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 59,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1278.77,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 39.35,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1119.02,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 34.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 61,
        previousY: 49,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1457.24,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.89,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.16,
              format: "Currency",
            },
            "Discount Amount": {
              value: 31.94,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1021.3,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.84,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.45,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 7,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 121.29,
              format: "Currency",
            },
            Customers: {
              value: 7,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.33,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.72,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Monday",
    data: [
      {
        x: "9AM",
        y: 5,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 101.02,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.78,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 18,
        previousY: 15,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 413.76,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.99,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.83,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 376.06,
              format: "Currency",
            },
            Customers: {
              value: 13,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 28,
        previousY: 26,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 597.71,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.35,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 973.4,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.44,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 16,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 656.77,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.24,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.77,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 484.7,
              format: "Currency",
            },
            Customers: {
              value: 16,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.29,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 28,
        previousY: 29,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 752.51,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.08,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 674.67,
              format: "Currency",
            },
            Customers: {
              value: 20,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.77,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 40,
        previousY: 24,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1176.92,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.42,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.34,
              format: "Currency",
            },
            "Discount Amount": {
              value: 18.11,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 701.74,
              format: "Currency",
            },
            Customers: {
              value: 18,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.24,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 28,
        previousY: 32,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1130.77,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 40.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 520.98,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 16.28,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.1,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 33,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1043.14,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 60.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 389.77,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.73,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 40,
        previousY: 37,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1079.03,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.7,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1186.84,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.08,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.68,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 61,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1188.11,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.56,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1222.7,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.58,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 61,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1853.49,
              format: "Currency",
            },
            Customers: {
              value: 48,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.37,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 847.77,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.72,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.2,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 55,
        previousY: 39,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1125.64,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.47,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.87,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1219.48,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.27,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.78,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 9,
        previousY: 4,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 366.37,
              format: "Currency",
            },
            Customers: {
              value: 8,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 40.71,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 84.18,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Tuesday",
    data: [
      {
        x: "9AM",
        y: 0,
        previousY: 0,
        tooltipData: {
          current: {},
          previous: {},
        },
      },
      {
        x: "10AM",
        y: 9,
        previousY: 7,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 157.81,
              format: "Currency",
            },
            Customers: {
              value: 7,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.53,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 85.25,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 12.18,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 32,
        previousY: 19,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 946.71,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.58,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.84,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 619.41,
              format: "Currency",
            },
            Customers: {
              value: 16,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.6,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 34,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 947.41,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.75,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.16,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 890.4,
              format: "Currency",
            },
            Customers: {
              value: 25,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.19,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.53,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 32,
        previousY: 40,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 970.13,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.63,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1069.31,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.73,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.08,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 33,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 980.4,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.71,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.54,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.26,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 388.62,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.8,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 27,
        previousY: 25,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 736.13,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.13,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 672.07,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 26,
        previousY: 40,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 617.17,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.9,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1099.29,
              format: "Currency",
            },
            Customers: {
              value: 25,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 40,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 982.33,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.19,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 827.98,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 32.58,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 62,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1778.5,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.69,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.19,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1304.23,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.64,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.95,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 63,
        previousY: 34,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1695.61,
              format: "Currency",
            },
            Customers: {
              value: 51,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.91,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 14.78,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 702.87,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 11.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 41,
        previousY: 45,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 875.63,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.36,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.33,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1058.21,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.52,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 5,
        previousY: 1,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 232.68,
              format: "Currency",
            },
            Customers: {
              value: 5,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.54,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 7.47,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 7.47,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Wednesday",
    data: [
      {
        x: "9AM",
        y: 2,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 12.52,
              format: "Currency",
            },
            Customers: {
              value: 2,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 6.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 15.1,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 17,
        previousY: 16,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 274.77,
              format: "Currency",
            },
            Customers: {
              value: 14,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 16.16,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 443.19,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.75,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 32,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 763.56,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.86,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.24,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1733.73,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 45.62,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 166.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 39,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2335.66,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 64.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.1,
              format: "Currency",
            },
            "Discount Amount": {
              value: 222.1,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 913.12,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.41,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 47,
        previousY: 47,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 3941.84,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 83.87,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 480.4,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1037.14,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 52,
        previousY: 33,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1049.7,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.19,
              format: "Currency",
            },
            "Return Amount": {
              value: 16.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.75,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 561.52,
              format: "Currency",
            },
            Customers: {
              value: 22,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.02,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.92,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 31,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 555.38,
              format: "Currency",
            },
            Customers: {
              value: 22,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.44,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1071.58,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 21.95,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 41,
        previousY: 36,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2096.79,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 51.14,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 225.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 3175.25,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 88.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 392.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 48,
        previousY: 58,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1178.81,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.33,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1502.4,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.9,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.06,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 67,
        previousY: 79,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1473.83,
              format: "Currency",
            },
            Customers: {
              value: 53,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1891.12,
              format: "Currency",
            },
            Customers: {
              value: 56,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.94,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 54,
        previousY: 63,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1387.99,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 10.25,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1612.32,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.7,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 56,
        previousY: 65,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1123.73,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 18,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.73,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1234.01,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 11,
        previousY: 2,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 92.24,
              format: "Currency",
            },
            Customers: {
              value: 8,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 8.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.91,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 30.18,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.09,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Thursday",
    data: [
      {
        x: "9AM",
        y: 1,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 43.98,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 43.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 35,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 725.21,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.72,
              format: "Currency",
            },
            "Return Amount": {
              value: 5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.96,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 527.27,
              format: "Currency",
            },
            Customers: {
              value: 17,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.29,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.15,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 44,
        previousY: 35,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1011.15,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.14,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 787.88,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.51,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.55,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 41,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1455.48,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.67,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1243.98,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.09,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.42,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 42,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1766.87,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 42.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 26.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2063.6,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.9,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 138.99,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 49,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1377.13,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.1,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.38,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1328.67,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.75,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 39,
        previousY: 41,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1544.7,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 39.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.4,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.79,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2611.77,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 63.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 305.65,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 51,
        previousY: 45,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1708.63,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 60.4,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 946.2,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.03,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 70,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1428.37,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.41,
              format: "Currency",
            },
            "Return Amount": {
              value: 44,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.75,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1346.7,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.49,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.55,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.35,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 81,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2109.23,
              format: "Currency",
            },
            Customers: {
              value: 62,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 50.08,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1251.26,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.1,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 88,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1947.88,
              format: "Currency",
            },
            Customers: {
              value: 64,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.13,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 33.99,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1672.93,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.42,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.43,
              format: "Currency",
            },
            "Discount Amount": {
              value: 55.14,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 105,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2095.95,
              format: "Currency",
            },
            Customers: {
              value: 75,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.44,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 879.22,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.99,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.42,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.99,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 3,
        previousY: 2,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 29.48,
              format: "Currency",
            },
            Customers: {
              value: 3,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 9.83,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 20.29,
              format: "Currency",
            },
            Customers: {
              value: 2,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 10.14,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.65,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Friday",
    data: [
      {
        x: "9AM",
        y: 0,
        previousY: 0,
        tooltipData: {
          current: {},
          previous: {},
        },
      },
      {
        x: "10AM",
        y: 33,
        previousY: 22,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 597.97,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.12,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.1,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 310.38,
              format: "Currency",
            },
            Customers: {
              value: 21,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 14.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 35,
        previousY: 50,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 896.96,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.63,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.31,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1376.01,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.52,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 35.93,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 46,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 921.77,
              format: "Currency",
            },
            Customers: {
              value: 36,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.32,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1421.68,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.31,
              format: "Currency",
            },
            "Return Amount": {
              value: 18.9,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 48,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1417.32,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.53,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.03,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1163.21,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 24.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 43,
        previousY: 56,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1032.4,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.01,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.34,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1853.27,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.09,
              format: "Currency",
            },
            "Return Amount": {
              value: 29.49,
              format: "Currency",
            },
            "Discount Amount": {
              value: 33.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 37,
        previousY: 50,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1168.92,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.16,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1449.25,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0.63,
              format: "Currency",
            },
            "Discount Amount": {
              value: 76.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 54,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1472.99,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.28,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 24.27,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1709.78,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 38.38,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 58,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1246.04,
              format: "Currency",
            },
            Customers: {
              value: 39,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.39,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.55,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1802.9,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.63,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.84,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 61,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1600.25,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.23,
              format: "Currency",
            },
            "Return Amount": {
              value: 24.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 34.18,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1285.98,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 55,
        previousY: 68,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1725.81,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.14,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1064.07,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.5,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 63,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1839.42,
              format: "Currency",
            },
            Customers: {
              value: 46,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 52.83,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1249.3,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 6,
        previousY: 1,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 118.06,
              format: "Currency",
            },
            Customers: {
              value: 6,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.68,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.9,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 4.74,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 4.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Saturday",
    data: [
      {
        x: "9AM",
        y: 22,
        previousY: 15,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 555.8,
              format: "Currency",
            },
            Customers: {
              value: 18,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 702.21,
              format: "Currency",
            },
            Customers: {
              value: 13,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.81,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.86,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 36,
        previousY: 27,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 947.98,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.33,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.75,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 970.1,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.93,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.58,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 56,
        previousY: 54,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1574.19,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.68,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1818.14,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.03,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 62,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1919.24,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.55,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.67,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1260.28,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 5.95,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.16,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 39,
        previousY: 67,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1084.25,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.8,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.72,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2383.16,
              format: "Currency",
            },
            Customers: {
              value: 55,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.57,
              format: "Currency",
            },
            "Return Amount": {
              value: 4,
              format: "Currency",
            },
            "Discount Amount": {
              value: 162.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 40,
        previousY: 59,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1594.25,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 39.86,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.25,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.52,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1851.9,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.06,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.78,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 35,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1027.46,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.36,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1625.48,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.8,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.3,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 55,
        previousY: 51,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1740.51,
              format: "Currency",
            },
            Customers: {
              value: 48,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.24,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1251.98,
              format: "Currency",
            },
            Customers: {
              value: 39,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.55,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 28.62,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 57,
        previousY: 42,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1624.56,
              format: "Currency",
            },
            Customers: {
              value: 46,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.56,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 580.64,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 13.82,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 68,
        previousY: 49,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1786.36,
              format: "Currency",
            },
            Customers: {
              value: 49,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.27,
              format: "Currency",
            },
            "Return Amount": {
              value: 15,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1443.66,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.46,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.7,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 68,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1831.97,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.94,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.68,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1498.97,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.53,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 62,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1105.69,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.83,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.27,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1050.71,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.43,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 11,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 411.13,
              format: "Currency",
            },
            Customers: {
              value: 10,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.32,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
]

export const HeatmapChartParameters = {
  meta_info: {
    format: "Currency",
  },
  axisRight: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Day of Week",
    legendPosition: "middle",
    legendOffset: 80,
    truncateTickAt: 0,
  },
  axisTop: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Time of Day",
    legendOffset: -40,
    legendPosition: "middle",
    truncateTickAt: 10,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Day of Week",
    legendPosition: "middle",
    legendOffset: -80,
    truncateTickAt: 0,
  },
  theme: "customTheme",
  colors: {
    type: "diverging",
    scheme: "blues",
    divergeAt: 0.5,
  },
  emptyColor: "#555555",
  legends: [
    {
      anchor: "bottom",
      translateX: 0,
      translateY: 30,
      length: 400,
      thickness: 8,
      direction: "row",
      tickPosition: "after",
      tickSize: 3,
      tickSpacing: 4,
      tickOverlap: false,
      tickFormat: ">-.2s",
      title: "Average Sale($)",
      titleAlign: "start",
      titleOffset: 4,
    },
  ],
  margin: {
    top: 60,
    right: 90,
    bottom: 60,
    left: 90,
  },
  tooltip_fields:
    "'Gross Sales','Customers','Transactions','Average Ticket','Return Amount','Discount Amount'",
    performerFlag: {
      "icon": "false",
      "legend": "true"
  }
}

export const ProgressBarData = [
  {
    title: "GB Foods",
    defaultValue: 220730,
    tooltipData: {
      current: {
        Name: {
          value: "GB Foods",
          format: false,
        },
        TotalOrders: {
          value: 4162,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220730,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "GB Foods",
          format: false,
        },
        TotalOrders: {
          value: 4162,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220730,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rasoi Restaurant",
    defaultValue: 74005,
    tooltipData: {
      current: {
        Name: {
          value: "Rasoi Restaurant",
          format: false,
        },
        TotalOrders: {
          value: 1169,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 74005,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rasoi Restaurant",
          format: false,
        },
        TotalOrders: {
          value: 1169,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 74005,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Oms Oms",
    defaultValue: 16666,
    tooltipData: {
      current: {
        Name: {
          value: "Oms Oms",
          format: false,
        },
        TotalOrders: {
          value: 235,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 16666,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Oms Oms",
          format: false,
        },
        TotalOrders: {
          value: 235,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 16666,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rekha goyal",
    defaultValue: 10133,
    tooltipData: {
      current: {
        Name: {
          value: "rekha goyal",
          format: false,
        },
        TotalOrders: {
          value: 504,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 10133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rekha goyal",
          format: false,
        },
        TotalOrders: {
          value: 504,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 10133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pranav v",
    defaultValue: 7922,
    tooltipData: {
      current: {
        Name: {
          value: "pranav v",
          format: false,
        },
        TotalOrders: {
          value: 394,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7922,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pranav v",
          format: false,
        },
        TotalOrders: {
          value: 394,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7922,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anusha Vyawahare",
    defaultValue: 7903,
    tooltipData: {
      current: {
        Name: {
          value: "Anusha Vyawahare",
          format: false,
        },
        TotalOrders: {
          value: 308,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7903,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anusha Vyawahare",
          format: false,
        },
        TotalOrders: {
          value: 308,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7903,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Akram Shakir",
    defaultValue: 7092,
    tooltipData: {
      current: {
        Name: {
          value: "Akram Shakir",
          format: false,
        },
        TotalOrders: {
          value: 95,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7092,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Akram Shakir",
          format: false,
        },
        TotalOrders: {
          value: 95,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7092,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Annie Syed",
    defaultValue: 7015,
    tooltipData: {
      current: {
        Name: {
          value: "Annie Syed",
          format: false,
        },
        TotalOrders: {
          value: 147,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7015,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Annie Syed",
          format: false,
        },
        TotalOrders: {
          value: 147,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7015,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "syeda naqvi",
    defaultValue: 6769,
    tooltipData: {
      current: {
        Name: {
          value: "syeda naqvi",
          format: false,
        },
        TotalOrders: {
          value: 172,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6769,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "syeda naqvi",
          format: false,
        },
        TotalOrders: {
          value: 172,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6769,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rizwaan Khaja",
    defaultValue: 6725,
    tooltipData: {
      current: {
        Name: {
          value: "Rizwaan Khaja",
          format: false,
        },
        TotalOrders: {
          value: 115,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6725,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rizwaan Khaja",
          format: false,
        },
        TotalOrders: {
          value: 115,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6725,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Firdouse Qasin",
    defaultValue: 6203,
    tooltipData: {
      current: {
        Name: {
          value: "Firdouse Qasin",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6203,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Firdouse Qasin",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6203,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Ahmed",
    defaultValue: 5749,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5749,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5749,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abbas Naqvi",
    defaultValue: 5655,
    tooltipData: {
      current: {
        Name: {
          value: "Abbas Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 109,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5655,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abbas Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 109,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5655,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tuba athar",
    defaultValue: 5621,
    tooltipData: {
      current: {
        Name: {
          value: "tuba athar",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5621,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tuba athar",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5621,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ased Kanwal",
    defaultValue: 5325,
    tooltipData: {
      current: {
        Name: {
          value: "Ased Kanwal",
          format: false,
        },
        TotalOrders: {
          value: 77,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5325,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ased Kanwal",
          format: false,
        },
        TotalOrders: {
          value: 77,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5325,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rabab Rizvi",
    defaultValue: 5291,
    tooltipData: {
      current: {
        Name: {
          value: "Rabab Rizvi",
          format: false,
        },
        TotalOrders: {
          value: 78,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5291,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rabab Rizvi",
          format: false,
        },
        TotalOrders: {
          value: 78,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5291,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zaki Mohammed",
    defaultValue: 5153,
    tooltipData: {
      current: {
        Name: {
          value: "Zaki Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5153,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zaki Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5153,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sadaf Zeeshan",
    defaultValue: 5085,
    tooltipData: {
      current: {
        Name: {
          value: "Sadaf Zeeshan",
          format: false,
        },
        TotalOrders: {
          value: 74,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5085,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sadaf Zeeshan",
          format: false,
        },
        TotalOrders: {
          value: 74,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5085,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mashaal Dagha",
    defaultValue: 4829,
    tooltipData: {
      current: {
        Name: {
          value: "Mashaal Dagha",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4829,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mashaal Dagha",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4829,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naba khan",
    defaultValue: 4772,
    tooltipData: {
      current: {
        Name: {
          value: "naba khan",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4772,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naba khan",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4772,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arsala Imam",
    defaultValue: 4725,
    tooltipData: {
      current: {
        Name: {
          value: "Arsala Imam",
          format: false,
        },
        TotalOrders: {
          value: 162,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4725,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arsala Imam",
          format: false,
        },
        TotalOrders: {
          value: 162,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4725,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Surya Anakala",
    defaultValue: 4715,
    tooltipData: {
      current: {
        Name: {
          value: "Surya Anakala",
          format: false,
        },
        TotalOrders: {
          value: 140,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4715,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Surya Anakala",
          format: false,
        },
        TotalOrders: {
          value: 140,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4715,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mehreen khan",
    defaultValue: 4499,
    tooltipData: {
      current: {
        Name: {
          value: "Mehreen khan",
          format: false,
        },
        TotalOrders: {
          value: 74,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4499,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mehreen khan",
          format: false,
        },
        TotalOrders: {
          value: 74,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4499,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Naima Khan",
    defaultValue: 4399,
    tooltipData: {
      current: {
        Name: {
          value: "Naima Khan",
          format: false,
        },
        TotalOrders: {
          value: 200,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4399,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Naima Khan",
          format: false,
        },
        TotalOrders: {
          value: 200,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4399,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunira Ahmed",
    defaultValue: 4331,
    tooltipData: {
      current: {
        Name: {
          value: "Sunira Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 116,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4331,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunira Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 116,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4331,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "asma naeem",
    defaultValue: 4274,
    tooltipData: {
      current: {
        Name: {
          value: "asma naeem",
          format: false,
        },
        TotalOrders: {
          value: 158,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4274,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "asma naeem",
          format: false,
        },
        TotalOrders: {
          value: 158,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4274,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Irfan Ali",
    defaultValue: 4071,
    tooltipData: {
      current: {
        Name: {
          value: "Irfan Ali",
          format: false,
        },
        TotalOrders: {
          value: 243,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4071,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Irfan Ali",
          format: false,
        },
        TotalOrders: {
          value: 243,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4071,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alishba Khan",
    defaultValue: 4024,
    tooltipData: {
      current: {
        Name: {
          value: "Alishba Khan",
          format: false,
        },
        TotalOrders: {
          value: 83,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4024,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alishba Khan",
          format: false,
        },
        TotalOrders: {
          value: 83,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4024,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Asad Javed",
    defaultValue: 4013,
    tooltipData: {
      current: {
        Name: {
          value: "Asad Javed",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4013,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Asad Javed",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4013,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "serwat rana",
    defaultValue: 3981,
    tooltipData: {
      current: {
        Name: {
          value: "serwat rana",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3981,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "serwat rana",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3981,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Alam",
    defaultValue: 3929,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Alam",
          format: false,
        },
        TotalOrders: {
          value: 165,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3929,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Alam",
          format: false,
        },
        TotalOrders: {
          value: 165,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3929,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohammed ali",
    defaultValue: 3731,
    tooltipData: {
      current: {
        Name: {
          value: "mohammed ali",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3731,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohammed ali",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3731,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kanwal irfan",
    defaultValue: 3525,
    tooltipData: {
      current: {
        Name: {
          value: "kanwal irfan",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3525,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kanwal irfan",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3525,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rasool ali",
    defaultValue: 3479,
    tooltipData: {
      current: {
        Name: {
          value: "rasool ali",
          format: false,
        },
        TotalOrders: {
          value: 147,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3479,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rasool ali",
          format: false,
        },
        TotalOrders: {
          value: 147,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3479,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Uzma Khichi",
    defaultValue: 3426,
    tooltipData: {
      current: {
        Name: {
          value: "Uzma Khichi",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3426,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Uzma Khichi",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3426,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "atif siddiqui",
    defaultValue: 3413,
    tooltipData: {
      current: {
        Name: {
          value: "atif siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3413,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "atif siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3413,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Iffat Hasan",
    defaultValue: 3395,
    tooltipData: {
      current: {
        Name: {
          value: "Iffat Hasan",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3395,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Iffat Hasan",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3395,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashfaq Ahmed",
    defaultValue: 3372,
    tooltipData: {
      current: {
        Name: {
          value: "Ashfaq Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3372,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashfaq Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3372,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jai kannah",
    defaultValue: 3368,
    tooltipData: {
      current: {
        Name: {
          value: "jai kannah",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3368,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jai kannah",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3368,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abdul Moid",
    defaultValue: 3291,
    tooltipData: {
      current: {
        Name: {
          value: "Abdul Moid",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3291,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abdul Moid",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3291,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naheed jehan",
    defaultValue: 3268,
    tooltipData: {
      current: {
        Name: {
          value: "naheed jehan",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3268,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naheed jehan",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3268,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammed Shaikh",
    defaultValue: 3264,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammed Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3264,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammed Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3264,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sayosree Bhattacharjee",
    defaultValue: 3224,
    tooltipData: {
      current: {
        Name: {
          value: "Sayosree Bhattacharjee",
          format: false,
        },
        TotalOrders: {
          value: 72,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3224,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sayosree Bhattacharjee",
          format: false,
        },
        TotalOrders: {
          value: 72,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3224,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aysha bawani",
    defaultValue: 3154,
    tooltipData: {
      current: {
        Name: {
          value: "aysha bawani",
          format: false,
        },
        TotalOrders: {
          value: 156,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aysha bawani",
          format: false,
        },
        TotalOrders: {
          value: 156,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "adeela khaliq",
    defaultValue: 3152,
    tooltipData: {
      current: {
        Name: {
          value: "adeela khaliq",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3152,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "adeela khaliq",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3152,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Moms Kitchen",
    defaultValue: 3147,
    tooltipData: {
      current: {
        Name: {
          value: "Moms Kitchen",
          format: false,
        },
        TotalOrders: {
          value: 107,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3147,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Moms Kitchen",
          format: false,
        },
        TotalOrders: {
          value: 107,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3147,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Malik Wasim",
    defaultValue: 3141,
    tooltipData: {
      current: {
        Name: {
          value: "Malik Wasim",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3141,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Malik Wasim",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3141,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Asif Memon",
    defaultValue: 3115,
    tooltipData: {
      current: {
        Name: {
          value: "Asif Memon",
          format: false,
        },
        TotalOrders: {
          value: 106,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Asif Memon",
          format: false,
        },
        TotalOrders: {
          value: 106,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nazia naqvi",
    defaultValue: 3094,
    tooltipData: {
      current: {
        Name: {
          value: "nazia naqvi",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3094,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nazia naqvi",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3094,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Matiha Ali",
    defaultValue: 3092,
    tooltipData: {
      current: {
        Name: {
          value: "Matiha Ali",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3092,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Matiha Ali",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3092,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohammed arshad",
    defaultValue: 3062,
    tooltipData: {
      current: {
        Name: {
          value: "mohammed arshad",
          format: false,
        },
        TotalOrders: {
          value: 132,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3062,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohammed arshad",
          format: false,
        },
        TotalOrders: {
          value: 132,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3062,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Naila Saeed",
    defaultValue: 3017,
    tooltipData: {
      current: {
        Name: {
          value: "Naila Saeed",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3017,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Naila Saeed",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 3017,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anjum Malik",
    defaultValue: 2921,
    tooltipData: {
      current: {
        Name: {
          value: "Anjum Malik",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2921,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anjum Malik",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2921,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tabassum I",
    defaultValue: 2913,
    tooltipData: {
      current: {
        Name: {
          value: "tabassum I",
          format: false,
        },
        TotalOrders: {
          value: 104,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2913,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tabassum I",
          format: false,
        },
        TotalOrders: {
          value: 104,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2913,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sudhanshu Jha",
    defaultValue: 2879,
    tooltipData: {
      current: {
        Name: {
          value: "Sudhanshu Jha",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2879,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sudhanshu Jha",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2879,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Firdaus Doctor",
    defaultValue: 2878,
    tooltipData: {
      current: {
        Name: {
          value: "Firdaus Doctor",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2878,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Firdaus Doctor",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2878,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "radhika mergey",
    defaultValue: 2871,
    tooltipData: {
      current: {
        Name: {
          value: "radhika mergey",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2871,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "radhika mergey",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2871,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rabeea Zaki",
    defaultValue: 2851,
    tooltipData: {
      current: {
        Name: {
          value: "Rabeea Zaki",
          format: false,
        },
        TotalOrders: {
          value: 81,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2851,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rabeea Zaki",
          format: false,
        },
        TotalOrders: {
          value: 81,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2851,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed ahmed",
    defaultValue: 2847,
    tooltipData: {
      current: {
        Name: {
          value: "Syed ahmed",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2847,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed ahmed",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2847,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anita Naqvi",
    defaultValue: 2833,
    tooltipData: {
      current: {
        Name: {
          value: "Anita Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 92,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2833,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anita Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 92,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2833,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sabrina Moheet",
    defaultValue: 2766,
    tooltipData: {
      current: {
        Name: {
          value: "Sabrina Moheet",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2766,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sabrina Moheet",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2766,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Habeeb",
    defaultValue: 2764,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Habeeb",
          format: false,
        },
        TotalOrders: {
          value: 63,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2764,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Habeeb",
          format: false,
        },
        TotalOrders: {
          value: 63,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2764,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ibrahim khadar",
    defaultValue: 2759,
    tooltipData: {
      current: {
        Name: {
          value: "ibrahim khadar",
          format: false,
        },
        TotalOrders: {
          value: 97,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2759,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ibrahim khadar",
          format: false,
        },
        TotalOrders: {
          value: 97,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2759,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suresh Reddy",
    defaultValue: 2728,
    tooltipData: {
      current: {
        Name: {
          value: "Suresh Reddy",
          format: false,
        },
        TotalOrders: {
          value: 95,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2728,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suresh Reddy",
          format: false,
        },
        TotalOrders: {
          value: 95,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2728,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amitabh Patnam",
    defaultValue: 2700,
    tooltipData: {
      current: {
        Name: {
          value: "Amitabh Patnam",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2700,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amitabh Patnam",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2700,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Habiba Asif",
    defaultValue: 2691,
    tooltipData: {
      current: {
        Name: {
          value: "Habiba Asif",
          format: false,
        },
        TotalOrders: {
          value: 71,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2691,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Habiba Asif",
          format: false,
        },
        TotalOrders: {
          value: 71,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2691,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahrukh Baig",
    defaultValue: 2673,
    tooltipData: {
      current: {
        Name: {
          value: "Mahrukh Baig",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2673,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahrukh Baig",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2673,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lubna Kausei",
    defaultValue: 2672,
    tooltipData: {
      current: {
        Name: {
          value: "Lubna Kausei",
          format: false,
        },
        TotalOrders: {
          value: 70,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2672,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lubna Kausei",
          format: false,
        },
        TotalOrders: {
          value: 70,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2672,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "subbarayudu nidumukkala",
    defaultValue: 2637,
    tooltipData: {
      current: {
        Name: {
          value: "subbarayudu nidumukkala",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2637,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "subbarayudu nidumukkala",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2637,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ashmita singh",
    defaultValue: 2634,
    tooltipData: {
      current: {
        Name: {
          value: "ashmita singh",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2634,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ashmita singh",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2634,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rajesh reddy",
    defaultValue: 2630,
    tooltipData: {
      current: {
        Name: {
          value: "rajesh reddy",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2630,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rajesh reddy",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2630,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sadia Ali",
    defaultValue: 2625,
    tooltipData: {
      current: {
        Name: {
          value: "Sadia Ali",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2625,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sadia Ali",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2625,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Salman Mahmood",
    defaultValue: 2601,
    tooltipData: {
      current: {
        Name: {
          value: "Salman Mahmood",
          format: false,
        },
        TotalOrders: {
          value: 70,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2601,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Salman Mahmood",
          format: false,
        },
        TotalOrders: {
          value: 70,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2601,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kaushik subramanian",
    defaultValue: 2601,
    tooltipData: {
      current: {
        Name: {
          value: "kaushik subramanian",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2601,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kaushik subramanian",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2601,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Mirza",
    defaultValue: 2596,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Mirza",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2596,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Mirza",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2596,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syeda Tirmzi",
    defaultValue: 2590,
    tooltipData: {
      current: {
        Name: {
          value: "Syeda Tirmzi",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2590,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syeda Tirmzi",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2590,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Srivastava",
    defaultValue: 2587,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Srivastava",
          format: false,
        },
        TotalOrders: {
          value: 78,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2587,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Srivastava",
          format: false,
        },
        TotalOrders: {
          value: 78,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2587,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "syed quabri",
    defaultValue: 2572,
    tooltipData: {
      current: {
        Name: {
          value: "syed quabri",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2572,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "syed quabri",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2572,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "farhan naqvi",
    defaultValue: 2560,
    tooltipData: {
      current: {
        Name: {
          value: "farhan naqvi",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2560,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "farhan naqvi",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2560,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aysha saiyed",
    defaultValue: 2556,
    tooltipData: {
      current: {
        Name: {
          value: "aysha saiyed",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2556,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aysha saiyed",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2556,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aisha Furqan",
    defaultValue: 2552,
    tooltipData: {
      current: {
        Name: {
          value: "Aisha Furqan",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2552,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aisha Furqan",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2552,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nausheen shahab",
    defaultValue: 2542,
    tooltipData: {
      current: {
        Name: {
          value: "nausheen shahab",
          format: false,
        },
        TotalOrders: {
          value: 53,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2542,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nausheen shahab",
          format: false,
        },
        TotalOrders: {
          value: 53,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2542,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "parshant gupta",
    defaultValue: 2540,
    tooltipData: {
      current: {
        Name: {
          value: "parshant gupta",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2540,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "parshant gupta",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2540,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neha Manne",
    defaultValue: 2538,
    tooltipData: {
      current: {
        Name: {
          value: "Neha Manne",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2538,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neha Manne",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2538,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lakshmi Prabhu",
    defaultValue: 2520,
    tooltipData: {
      current: {
        Name: {
          value: "Lakshmi Prabhu",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2520,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lakshmi Prabhu",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2520,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abdul Rasheed",
    defaultValue: 2512,
    tooltipData: {
      current: {
        Name: {
          value: "Abdul Rasheed",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2512,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abdul Rasheed",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2512,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amir Khan",
    defaultValue: 2508,
    tooltipData: {
      current: {
        Name: {
          value: "Amir Khan",
          format: false,
        },
        TotalOrders: {
          value: 66,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2508,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amir Khan",
          format: false,
        },
        TotalOrders: {
          value: 66,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2508,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nargis Jaliawala",
    defaultValue: 2494,
    tooltipData: {
      current: {
        Name: {
          value: "Nargis Jaliawala",
          format: false,
        },
        TotalOrders: {
          value: 100,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2494,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nargis Jaliawala",
          format: false,
        },
        TotalOrders: {
          value: 100,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2494,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shivani Aggarwal",
    defaultValue: 2487,
    tooltipData: {
      current: {
        Name: {
          value: "Shivani Aggarwal",
          format: false,
        },
        TotalOrders: {
          value: 70,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2487,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shivani Aggarwal",
          format: false,
        },
        TotalOrders: {
          value: 70,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2487,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ekta malhotra",
    defaultValue: 2471,
    tooltipData: {
      current: {
        Name: {
          value: "ekta malhotra",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2471,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ekta malhotra",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2471,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rupsikha Borah deori",
    defaultValue: 2411,
    tooltipData: {
      current: {
        Name: {
          value: "Rupsikha Borah deori",
          format: false,
        },
        TotalOrders: {
          value: 86,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2411,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rupsikha Borah deori",
          format: false,
        },
        TotalOrders: {
          value: 86,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2411,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "yasha mathad",
    defaultValue: 2399,
    tooltipData: {
      current: {
        Name: {
          value: "yasha mathad",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2399,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "yasha mathad",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2399,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sabrina sayeed",
    defaultValue: 2387,
    tooltipData: {
      current: {
        Name: {
          value: "sabrina sayeed",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2387,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sabrina sayeed",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2387,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kashif Aleem",
    defaultValue: 2386,
    tooltipData: {
      current: {
        Name: {
          value: "Kashif Aleem",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2386,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kashif Aleem",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2386,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "wajiha waheed",
    defaultValue: 2385,
    tooltipData: {
      current: {
        Name: {
          value: "wajiha waheed",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2385,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "wajiha waheed",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2385,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fouzia Zaidi",
    defaultValue: 2375,
    tooltipData: {
      current: {
        Name: {
          value: "Fouzia Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 94,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2375,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fouzia Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 94,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2375,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syeda Tirmzi",
    defaultValue: 2365,
    tooltipData: {
      current: {
        Name: {
          value: "Syeda Tirmzi",
          format: false,
        },
        TotalOrders: {
          value: 71,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2365,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syeda Tirmzi",
          format: false,
        },
        TotalOrders: {
          value: 71,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2365,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zafar Zaidi",
    defaultValue: 2363,
    tooltipData: {
      current: {
        Name: {
          value: "Zafar Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 89,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2363,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zafar Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 89,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2363,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "noshir khandwawala",
    defaultValue: 2353,
    tooltipData: {
      current: {
        Name: {
          value: "noshir khandwawala",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2353,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "noshir khandwawala",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2353,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sadia Anwar",
    defaultValue: 2351,
    tooltipData: {
      current: {
        Name: {
          value: "Sadia Anwar",
          format: false,
        },
        TotalOrders: {
          value: 99,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2351,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sadia Anwar",
          format: false,
        },
        TotalOrders: {
          value: 99,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2351,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sam Merchant",
    defaultValue: 2349,
    tooltipData: {
      current: {
        Name: {
          value: "Sam Merchant",
          format: false,
        },
        TotalOrders: {
          value: 126,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2349,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sam Merchant",
          format: false,
        },
        TotalOrders: {
          value: 126,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2349,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aneeta Mustafa",
    defaultValue: 2328,
    tooltipData: {
      current: {
        Name: {
          value: "Aneeta Mustafa",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2328,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aneeta Mustafa",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2328,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hira Khan",
    defaultValue: 2322,
    tooltipData: {
      current: {
        Name: {
          value: "Hira Khan",
          format: false,
        },
        TotalOrders: {
          value: 74,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hira Khan",
          format: false,
        },
        TotalOrders: {
          value: 74,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ansar Siddiqui",
    defaultValue: 2320,
    tooltipData: {
      current: {
        Name: {
          value: "Ansar Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 78,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2320,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ansar Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 78,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2320,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi Varre",
    defaultValue: 2319,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi Varre",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2319,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi Varre",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2319,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swetha Elati",
    defaultValue: 2304,
    tooltipData: {
      current: {
        Name: {
          value: "Swetha Elati",
          format: false,
        },
        TotalOrders: {
          value: 77,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2304,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swetha Elati",
          format: false,
        },
        TotalOrders: {
          value: 77,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2304,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kamran Ahmad",
    defaultValue: 2302,
    tooltipData: {
      current: {
        Name: {
          value: "Kamran Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 80,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2302,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kamran Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 80,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2302,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aqsa fahad",
    defaultValue: 2293,
    tooltipData: {
      current: {
        Name: {
          value: "aqsa fahad",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2293,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aqsa fahad",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2293,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vipula waghmare",
    defaultValue: 2282,
    tooltipData: {
      current: {
        Name: {
          value: "vipula waghmare",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2282,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vipula waghmare",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2282,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Elijah Beery",
    defaultValue: 2253,
    tooltipData: {
      current: {
        Name: {
          value: "Elijah Beery",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Elijah Beery",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shabekun Nahar",
    defaultValue: 2222,
    tooltipData: {
      current: {
        Name: {
          value: "Shabekun Nahar",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shabekun Nahar",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sairaah haider",
    defaultValue: 2212,
    tooltipData: {
      current: {
        Name: {
          value: "sairaah haider",
          format: false,
        },
        TotalOrders: {
          value: 73,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2212,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sairaah haider",
          format: false,
        },
        TotalOrders: {
          value: 73,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2212,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sanjay parihar",
    defaultValue: 2192,
    tooltipData: {
      current: {
        Name: {
          value: "sanjay parihar",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2192,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sanjay parihar",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2192,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hira siddiqui",
    defaultValue: 2191,
    tooltipData: {
      current: {
        Name: {
          value: "hira siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 68,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2191,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hira siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 68,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2191,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "atiya khan",
    defaultValue: 2176,
    tooltipData: {
      current: {
        Name: {
          value: "atiya khan",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "atiya khan",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shabbar mir",
    defaultValue: 2172,
    tooltipData: {
      current: {
        Name: {
          value: "shabbar mir",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2172,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shabbar mir",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2172,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ram puram",
    defaultValue: 2166,
    tooltipData: {
      current: {
        Name: {
          value: "ram puram",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ram puram",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkat Nayudu",
    defaultValue: 2156,
    tooltipData: {
      current: {
        Name: {
          value: "Venkat Nayudu",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2156,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkat Nayudu",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2156,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Jafri",
    defaultValue: 2144,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Jafri",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2144,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Jafri",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2144,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jatin Sablok",
    defaultValue: 2117,
    tooltipData: {
      current: {
        Name: {
          value: "jatin Sablok",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jatin Sablok",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Burhan Alam",
    defaultValue: 2078,
    tooltipData: {
      current: {
        Name: {
          value: "Burhan Alam",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2078,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Burhan Alam",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2078,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nida Khan",
    defaultValue: 2076,
    tooltipData: {
      current: {
        Name: {
          value: "nida Khan",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2076,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nida Khan",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2076,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pradhan Raj",
    defaultValue: 2073,
    tooltipData: {
      current: {
        Name: {
          value: "Pradhan Raj",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2073,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pradhan Raj",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2073,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aleema farooqui",
    defaultValue: 2061,
    tooltipData: {
      current: {
        Name: {
          value: "aleema farooqui",
          format: false,
        },
        TotalOrders: {
          value: 66,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2061,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aleema farooqui",
          format: false,
        },
        TotalOrders: {
          value: 66,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2061,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Noman Khan",
    defaultValue: 2059,
    tooltipData: {
      current: {
        Name: {
          value: "Noman Khan",
          format: false,
        },
        TotalOrders: {
          value: 87,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2059,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Noman Khan",
          format: false,
        },
        TotalOrders: {
          value: 87,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2059,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deep Dwivedi",
    defaultValue: 2056,
    tooltipData: {
      current: {
        Name: {
          value: "Deep Dwivedi",
          format: false,
        },
        TotalOrders: {
          value: 150,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2056,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deep Dwivedi",
          format: false,
        },
        TotalOrders: {
          value: 150,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2056,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Qaisar Jawad",
    defaultValue: 2055,
    tooltipData: {
      current: {
        Name: {
          value: "Qaisar Jawad",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2055,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Qaisar Jawad",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2055,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Ali",
    defaultValue: 2045,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Ali",
          format: false,
        },
        TotalOrders: {
          value: 74,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2045,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Ali",
          format: false,
        },
        TotalOrders: {
          value: 74,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2045,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rukia Yahya",
    defaultValue: 2033,
    tooltipData: {
      current: {
        Name: {
          value: "Rukia Yahya",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2033,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rukia Yahya",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2033,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mehwish Ahmed",
    defaultValue: 2022,
    tooltipData: {
      current: {
        Name: {
          value: "Mehwish Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 80,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2022,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mehwish Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 80,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2022,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohsen Ahmad",
    defaultValue: 2016,
    tooltipData: {
      current: {
        Name: {
          value: "Mohsen Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2016,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohsen Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2016,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raja Jagannathan",
    defaultValue: 2013,
    tooltipData: {
      current: {
        Name: {
          value: "Raja Jagannathan",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2013,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raja Jagannathan",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2013,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shane Christian",
    defaultValue: 2011,
    tooltipData: {
      current: {
        Name: {
          value: "Shane Christian",
          format: false,
        },
        TotalOrders: {
          value: 80,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2011,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shane Christian",
          format: false,
        },
        TotalOrders: {
          value: 80,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2011,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sana Salman",
    defaultValue: 2011,
    tooltipData: {
      current: {
        Name: {
          value: "Sana Salman",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2011,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sana Salman",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 2011,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yogita Goomber",
    defaultValue: 1993,
    tooltipData: {
      current: {
        Name: {
          value: "Yogita Goomber",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1993,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yogita Goomber",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1993,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "PUNITHA BALAJI",
    defaultValue: 1992,
    tooltipData: {
      current: {
        Name: {
          value: "PUNITHA BALAJI",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1992,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "PUNITHA BALAJI",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1992,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhavi Venkatesh",
    defaultValue: 1986,
    tooltipData: {
      current: {
        Name: {
          value: "Madhavi Venkatesh",
          format: false,
        },
        TotalOrders: {
          value: 81,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1986,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhavi Venkatesh",
          format: false,
        },
        TotalOrders: {
          value: 81,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1986,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Farzana Fumakeya",
    defaultValue: 1978,
    tooltipData: {
      current: {
        Name: {
          value: "Farzana Fumakeya",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1978,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Farzana Fumakeya",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1978,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Joseph Almeida",
    defaultValue: 1974,
    tooltipData: {
      current: {
        Name: {
          value: "Joseph Almeida",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1974,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Joseph Almeida",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1974,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramu Potharam",
    defaultValue: 1967,
    tooltipData: {
      current: {
        Name: {
          value: "Ramu Potharam",
          format: false,
        },
        TotalOrders: {
          value: 84,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1967,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramu Potharam",
          format: false,
        },
        TotalOrders: {
          value: 84,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1967,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kashif rizvi",
    defaultValue: 1963,
    tooltipData: {
      current: {
        Name: {
          value: "kashif rizvi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1963,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kashif rizvi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1963,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zohaib Jiwani",
    defaultValue: 1959,
    tooltipData: {
      current: {
        Name: {
          value: "Zohaib Jiwani",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1959,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zohaib Jiwani",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1959,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tehmina saqib",
    defaultValue: 1957,
    tooltipData: {
      current: {
        Name: {
          value: "tehmina saqib",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1957,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tehmina saqib",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1957,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muneeza Adil",
    defaultValue: 1951,
    tooltipData: {
      current: {
        Name: {
          value: "Muneeza Adil",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1951,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muneeza Adil",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1951,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shahab Ahmed",
    defaultValue: 1949,
    tooltipData: {
      current: {
        Name: {
          value: "Shahab Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1949,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shahab Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1949,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saad syed",
    defaultValue: 1948,
    tooltipData: {
      current: {
        Name: {
          value: "saad syed",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1948,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saad syed",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1948,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suneel Reddy",
    defaultValue: 1934,
    tooltipData: {
      current: {
        Name: {
          value: "Suneel Reddy",
          format: false,
        },
        TotalOrders: {
          value: 72,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1934,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suneel Reddy",
          format: false,
        },
        TotalOrders: {
          value: 72,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1934,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tarika malla",
    defaultValue: 1931,
    tooltipData: {
      current: {
        Name: {
          value: "tarika malla",
          format: false,
        },
        TotalOrders: {
          value: 66,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1931,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tarika malla",
          format: false,
        },
        TotalOrders: {
          value: 66,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1931,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aasma Sami",
    defaultValue: 1931,
    tooltipData: {
      current: {
        Name: {
          value: "Aasma Sami",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1931,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aasma Sami",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1931,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vasudha Gosula",
    defaultValue: 1928,
    tooltipData: {
      current: {
        Name: {
          value: "Vasudha Gosula",
          format: false,
        },
        TotalOrders: {
          value: 91,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1928,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vasudha Gosula",
          format: false,
        },
        TotalOrders: {
          value: 91,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1928,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Taheera ali",
    defaultValue: 1917,
    tooltipData: {
      current: {
        Name: {
          value: "Taheera ali",
          format: false,
        },
        TotalOrders: {
          value: 72,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1917,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Taheera ali",
          format: false,
        },
        TotalOrders: {
          value: 72,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1917,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amara Shibli",
    defaultValue: 1916,
    tooltipData: {
      current: {
        Name: {
          value: "Amara Shibli",
          format: false,
        },
        TotalOrders: {
          value: 72,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1916,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amara Shibli",
          format: false,
        },
        TotalOrders: {
          value: 72,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1916,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saqib karim",
    defaultValue: 1906,
    tooltipData: {
      current: {
        Name: {
          value: "saqib karim",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1906,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saqib karim",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1906,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saba islam",
    defaultValue: 1905,
    tooltipData: {
      current: {
        Name: {
          value: "saba islam",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1905,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saba islam",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1905,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chetan Srivastava",
    defaultValue: 1890,
    tooltipData: {
      current: {
        Name: {
          value: "Chetan Srivastava",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1890,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chetan Srivastava",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1890,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nausheen Choudhry",
    defaultValue: 1869,
    tooltipData: {
      current: {
        Name: {
          value: "Nausheen Choudhry",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1869,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nausheen Choudhry",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1869,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "syed waqif",
    defaultValue: 1865,
    tooltipData: {
      current: {
        Name: {
          value: "syed waqif",
          format: false,
        },
        TotalOrders: {
          value: 73,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1865,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "syed waqif",
          format: false,
        },
        TotalOrders: {
          value: 73,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1865,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Afroze Qasim",
    defaultValue: 1854,
    tooltipData: {
      current: {
        Name: {
          value: "Afroze Qasim",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1854,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Afroze Qasim",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1854,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mahammad saheb",
    defaultValue: 1847,
    tooltipData: {
      current: {
        Name: {
          value: "mahammad saheb",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1847,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mahammad saheb",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1847,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Umber Farooqi",
    defaultValue: 1845,
    tooltipData: {
      current: {
        Name: {
          value: "Umber Farooqi",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1845,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Umber Farooqi",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1845,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chennai Super Kitchen",
    defaultValue: 1843,
    tooltipData: {
      current: {
        Name: {
          value: "Chennai Super Kitchen",
          format: false,
        },
        TotalOrders: {
          value: 63,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1843,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chennai Super Kitchen",
          format: false,
        },
        TotalOrders: {
          value: 63,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1843,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shubham Chnadra",
    defaultValue: 1834,
    tooltipData: {
      current: {
        Name: {
          value: "Shubham Chnadra",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1834,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shubham Chnadra",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1834,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shabana Nesar",
    defaultValue: 1825,
    tooltipData: {
      current: {
        Name: {
          value: "Shabana Nesar",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1825,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shabana Nesar",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1825,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chandana Prasad",
    defaultValue: 1822,
    tooltipData: {
      current: {
        Name: {
          value: "Chandana Prasad",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1822,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chandana Prasad",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1822,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ganesh Amle",
    defaultValue: 1820,
    tooltipData: {
      current: {
        Name: {
          value: "Ganesh Amle",
          format: false,
        },
        TotalOrders: {
          value: 77,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1820,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ganesh Amle",
          format: false,
        },
        TotalOrders: {
          value: 77,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1820,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kiran Singh",
    defaultValue: 1818,
    tooltipData: {
      current: {
        Name: {
          value: "Kiran Singh",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1818,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kiran Singh",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1818,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yasir abbas",
    defaultValue: 1811,
    tooltipData: {
      current: {
        Name: {
          value: "Yasir abbas",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1811,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yasir abbas",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1811,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shehnaz Arif",
    defaultValue: 1811,
    tooltipData: {
      current: {
        Name: {
          value: "Shehnaz Arif",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1811,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shehnaz Arif",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1811,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashish Adike",
    defaultValue: 1805,
    tooltipData: {
      current: {
        Name: {
          value: "Ashish Adike",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1805,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashish Adike",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1805,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ameer Mohammed",
    defaultValue: 1797,
    tooltipData: {
      current: {
        Name: {
          value: "Ameer Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1797,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ameer Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1797,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manish kaistha",
    defaultValue: 1795,
    tooltipData: {
      current: {
        Name: {
          value: "manish kaistha",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1795,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manish kaistha",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1795,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bushra Naqvi",
    defaultValue: 1794,
    tooltipData: {
      current: {
        Name: {
          value: "Bushra Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 71,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1794,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bushra Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 71,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1794,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Midhat Faisal",
    defaultValue: 1794,
    tooltipData: {
      current: {
        Name: {
          value: "Midhat Faisal",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1794,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Midhat Faisal",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1794,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gaytri Sundar",
    defaultValue: 1793,
    tooltipData: {
      current: {
        Name: {
          value: "Gaytri Sundar",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1793,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gaytri Sundar",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1793,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhanu Sunkara",
    defaultValue: 1790,
    tooltipData: {
      current: {
        Name: {
          value: "Bhanu Sunkara",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1790,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhanu Sunkara",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1790,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Subhani Shaik",
    defaultValue: 1783,
    tooltipData: {
      current: {
        Name: {
          value: "Subhani Shaik",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1783,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Subhani Shaik",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1783,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "uzair haroon",
    defaultValue: 1770,
    tooltipData: {
      current: {
        Name: {
          value: "uzair haroon",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1770,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "uzair haroon",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1770,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priya Joshi",
    defaultValue: 1764,
    tooltipData: {
      current: {
        Name: {
          value: "Priya Joshi",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1764,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priya Joshi",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1764,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vivek bhatia",
    defaultValue: 1764,
    tooltipData: {
      current: {
        Name: {
          value: "vivek bhatia",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1764,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vivek bhatia",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1764,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mustafa sajanlal",
    defaultValue: 1753,
    tooltipData: {
      current: {
        Name: {
          value: "mustafa sajanlal",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1753,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mustafa sajanlal",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1753,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amir Banu",
    defaultValue: 1745,
    tooltipData: {
      current: {
        Name: {
          value: "Amir Banu",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1745,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amir Banu",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1745,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sufian Avib",
    defaultValue: 1743,
    tooltipData: {
      current: {
        Name: {
          value: "Sufian Avib",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1743,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sufian Avib",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1743,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SHASHI BADGUJAR",
    defaultValue: 1738,
    tooltipData: {
      current: {
        Name: {
          value: "SHASHI BADGUJAR",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1738,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SHASHI BADGUJAR",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1738,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manal Jamshid",
    defaultValue: 1733,
    tooltipData: {
      current: {
        Name: {
          value: "Manal Jamshid",
          format: false,
        },
        TotalOrders: {
          value: 83,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1733,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manal Jamshid",
          format: false,
        },
        TotalOrders: {
          value: 83,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1733,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "harpreet singh",
    defaultValue: 1730,
    tooltipData: {
      current: {
        Name: {
          value: "harpreet singh",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1730,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "harpreet singh",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1730,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashish Malhotra",
    defaultValue: 1728,
    tooltipData: {
      current: {
        Name: {
          value: "Ashish Malhotra",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1728,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashish Malhotra",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1728,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Haniya Raja",
    defaultValue: 1718,
    tooltipData: {
      current: {
        Name: {
          value: "Haniya Raja",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1718,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Haniya Raja",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1718,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neha Agarwal",
    defaultValue: 1713,
    tooltipData: {
      current: {
        Name: {
          value: "Neha Agarwal",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1713,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neha Agarwal",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1713,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sayoni rakshit",
    defaultValue: 1707,
    tooltipData: {
      current: {
        Name: {
          value: "sayoni rakshit",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1707,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sayoni rakshit",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1707,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pritesh Bhoumick",
    defaultValue: 1703,
    tooltipData: {
      current: {
        Name: {
          value: "Pritesh Bhoumick",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1703,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pritesh Bhoumick",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1703,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Huzaifa burhani",
    defaultValue: 1702,
    tooltipData: {
      current: {
        Name: {
          value: "Huzaifa burhani",
          format: false,
        },
        TotalOrders: {
          value: 69,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1702,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Huzaifa burhani",
          format: false,
        },
        TotalOrders: {
          value: 69,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1702,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amit Raina",
    defaultValue: 1702,
    tooltipData: {
      current: {
        Name: {
          value: "Amit Raina",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1702,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amit Raina",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1702,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Durrat R",
    defaultValue: 1695,
    tooltipData: {
      current: {
        Name: {
          value: "Durrat R",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1695,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Durrat R",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1695,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "KAUSHIK CHAKRABARTY",
    defaultValue: 1688,
    tooltipData: {
      current: {
        Name: {
          value: "KAUSHIK CHAKRABARTY",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1688,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "KAUSHIK CHAKRABARTY",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1688,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vijay ahire",
    defaultValue: 1679,
    tooltipData: {
      current: {
        Name: {
          value: "vijay ahire",
          format: false,
        },
        TotalOrders: {
          value: 66,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1679,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vijay ahire",
          format: false,
        },
        TotalOrders: {
          value: 66,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1679,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Archana Maheshwari",
    defaultValue: 1674,
    tooltipData: {
      current: {
        Name: {
          value: "Archana Maheshwari",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1674,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Archana Maheshwari",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1674,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ABNAR GEORGE",
    defaultValue: 1670,
    tooltipData: {
      current: {
        Name: {
          value: "ABNAR GEORGE",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1670,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ABNAR GEORGE",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1670,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nusrath Fatima",
    defaultValue: 1665,
    tooltipData: {
      current: {
        Name: {
          value: "Nusrath Fatima",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1665,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nusrath Fatima",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1665,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sridhar mantri",
    defaultValue: 1663,
    tooltipData: {
      current: {
        Name: {
          value: "sridhar mantri",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1663,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sridhar mantri",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1663,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Superna Arya",
    defaultValue: 1662,
    tooltipData: {
      current: {
        Name: {
          value: "Superna Arya",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1662,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Superna Arya",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1662,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swati Singh",
    defaultValue: 1662,
    tooltipData: {
      current: {
        Name: {
          value: "Swati Singh",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1662,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swati Singh",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1662,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sowmya Gurram",
    defaultValue: 1661,
    tooltipData: {
      current: {
        Name: {
          value: "Sowmya Gurram",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1661,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sowmya Gurram",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1661,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "abdulla panjwani",
    defaultValue: 1656,
    tooltipData: {
      current: {
        Name: {
          value: "abdulla panjwani",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1656,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "abdulla panjwani",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1656,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Annie Shaikh",
    defaultValue: 1652,
    tooltipData: {
      current: {
        Name: {
          value: "Annie Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1652,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Annie Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1652,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rekha Sid",
    defaultValue: 1649,
    tooltipData: {
      current: {
        Name: {
          value: "Rekha Sid",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1649,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rekha Sid",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1649,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "imran khan",
    defaultValue: 1626,
    tooltipData: {
      current: {
        Name: {
          value: "imran khan",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1626,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "imran khan",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1626,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manisha vijapure",
    defaultValue: 1625,
    tooltipData: {
      current: {
        Name: {
          value: "manisha vijapure",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1625,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manisha vijapure",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1625,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shano taymour",
    defaultValue: 1624,
    tooltipData: {
      current: {
        Name: {
          value: "shano taymour",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1624,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shano taymour",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1624,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aparna Shetty",
    defaultValue: 1623,
    tooltipData: {
      current: {
        Name: {
          value: "Aparna Shetty",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1623,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aparna Shetty",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1623,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lakshmi Reka",
    defaultValue: 1618,
    tooltipData: {
      current: {
        Name: {
          value: "Lakshmi Reka",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1618,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lakshmi Reka",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1618,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shilpa Khullar",
    defaultValue: 1618,
    tooltipData: {
      current: {
        Name: {
          value: "Shilpa Khullar",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1618,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shilpa Khullar",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1618,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amit Joshi",
    defaultValue: 1616,
    tooltipData: {
      current: {
        Name: {
          value: "Amit Joshi",
          format: false,
        },
        TotalOrders: {
          value: 115,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1616,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amit Joshi",
          format: false,
        },
        TotalOrders: {
          value: 115,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1616,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kajal shah",
    defaultValue: 1613,
    tooltipData: {
      current: {
        Name: {
          value: "kajal shah",
          format: false,
        },
        TotalOrders: {
          value: 73,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1613,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kajal shah",
          format: false,
        },
        TotalOrders: {
          value: 73,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1613,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hoor Nabi",
    defaultValue: 1608,
    tooltipData: {
      current: {
        Name: {
          value: "Hoor Nabi",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1608,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hoor Nabi",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1608,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saira khan",
    defaultValue: 1605,
    tooltipData: {
      current: {
        Name: {
          value: "saira khan",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1605,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saira khan",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1605,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Adi Singla",
    defaultValue: 1603,
    tooltipData: {
      current: {
        Name: {
          value: "Adi Singla",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1603,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Adi Singla",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1603,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "karthikey devarasu",
    defaultValue: 1598,
    tooltipData: {
      current: {
        Name: {
          value: "karthikey devarasu",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1598,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "karthikey devarasu",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1598,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sonali chakraborty",
    defaultValue: 1593,
    tooltipData: {
      current: {
        Name: {
          value: "sonali chakraborty",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1593,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sonali chakraborty",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1593,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rafia fasih",
    defaultValue: 1591,
    tooltipData: {
      current: {
        Name: {
          value: "rafia fasih",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1591,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rafia fasih",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1591,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Namita Gunjal",
    defaultValue: 1586,
    tooltipData: {
      current: {
        Name: {
          value: "Namita Gunjal",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1586,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Namita Gunjal",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1586,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fayad elreda",
    defaultValue: 1583,
    tooltipData: {
      current: {
        Name: {
          value: "fayad elreda",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1583,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fayad elreda",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1583,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sudha Reddy",
    defaultValue: 1582,
    tooltipData: {
      current: {
        Name: {
          value: "Sudha Reddy",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1582,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sudha Reddy",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1582,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Mian",
    defaultValue: 1578,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Mian",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1578,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Mian",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1578,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "narayanarao varadi",
    defaultValue: 1578,
    tooltipData: {
      current: {
        Name: {
          value: "narayanarao varadi",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1578,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "narayanarao varadi",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1578,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhakti Naik",
    defaultValue: 1575,
    tooltipData: {
      current: {
        Name: {
          value: "Bhakti Naik",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1575,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhakti Naik",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1575,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Asha Vineesh",
    defaultValue: 1568,
    tooltipData: {
      current: {
        Name: {
          value: "Asha Vineesh",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1568,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Asha Vineesh",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1568,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Afrin ali",
    defaultValue: 1555,
    tooltipData: {
      current: {
        Name: {
          value: "Afrin ali",
          format: false,
        },
        TotalOrders: {
          value: 69,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1555,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Afrin ali",
          format: false,
        },
        TotalOrders: {
          value: 69,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1555,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Noreen K",
    defaultValue: 1553,
    tooltipData: {
      current: {
        Name: {
          value: "Noreen K",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1553,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Noreen K",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1553,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Namak Mirch Kitchen",
    defaultValue: 1551,
    tooltipData: {
      current: {
        Name: {
          value: "Namak Mirch Kitchen",
          format: false,
        },
        TotalOrders: {
          value: 139,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1551,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Namak Mirch Kitchen",
          format: false,
        },
        TotalOrders: {
          value: 139,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1551,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rakesh parim",
    defaultValue: 1550,
    tooltipData: {
      current: {
        Name: {
          value: "rakesh parim",
          format: false,
        },
        TotalOrders: {
          value: 63,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1550,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rakesh parim",
          format: false,
        },
        TotalOrders: {
          value: 63,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1550,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ruchika Khanna",
    defaultValue: 1545,
    tooltipData: {
      current: {
        Name: {
          value: "Ruchika Khanna",
          format: false,
        },
        TotalOrders: {
          value: 58,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1545,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ruchika Khanna",
          format: false,
        },
        TotalOrders: {
          value: 58,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1545,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sana naqvi",
    defaultValue: 1524,
    tooltipData: {
      current: {
        Name: {
          value: "sana naqvi",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1524,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sana naqvi",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1524,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prajkta mulay",
    defaultValue: 1511,
    tooltipData: {
      current: {
        Name: {
          value: "prajkta mulay",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1511,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prajkta mulay",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1511,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Usama Raza",
    defaultValue: 1510,
    tooltipData: {
      current: {
        Name: {
          value: "Usama Raza",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1510,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Usama Raza",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1510,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sravanth Vangari",
    defaultValue: 1509,
    tooltipData: {
      current: {
        Name: {
          value: "Sravanth Vangari",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1509,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sravanth Vangari",
          format: false,
        },
        TotalOrders: {
          value: 65,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1509,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ankit Gupta",
    defaultValue: 1508,
    tooltipData: {
      current: {
        Name: {
          value: "Ankit Gupta",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1508,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ankit Gupta",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1508,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Iti Shhablani",
    defaultValue: 1507,
    tooltipData: {
      current: {
        Name: {
          value: "Iti Shhablani",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1507,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Iti Shhablani",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1507,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fizza Mansoor",
    defaultValue: 1507,
    tooltipData: {
      current: {
        Name: {
          value: "Fizza Mansoor",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1507,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fizza Mansoor",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1507,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samantha Dcruz",
    defaultValue: 1504,
    tooltipData: {
      current: {
        Name: {
          value: "Samantha Dcruz",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1504,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samantha Dcruz",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1504,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fatima Khan",
    defaultValue: 1501,
    tooltipData: {
      current: {
        Name: {
          value: "Fatima Khan",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1501,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fatima Khan",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1501,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tulika Mukherjee",
    defaultValue: 1494,
    tooltipData: {
      current: {
        Name: {
          value: "Tulika Mukherjee",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1494,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tulika Mukherjee",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1494,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sudheer M",
    defaultValue: 1487,
    tooltipData: {
      current: {
        Name: {
          value: "Sudheer M",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1487,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sudheer M",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1487,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "marzooq raza",
    defaultValue: 1484,
    tooltipData: {
      current: {
        Name: {
          value: "marzooq raza",
          format: false,
        },
        TotalOrders: {
          value: 117,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1484,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "marzooq raza",
          format: false,
        },
        TotalOrders: {
          value: 117,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1484,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Renu Kumar",
    defaultValue: 1478,
    tooltipData: {
      current: {
        Name: {
          value: "Renu Kumar",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1478,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Renu Kumar",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1478,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "PRADEEP EAWAR",
    defaultValue: 1475,
    tooltipData: {
      current: {
        Name: {
          value: "PRADEEP EAWAR",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1475,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "PRADEEP EAWAR",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1475,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fizzah Bajwa",
    defaultValue: 1470,
    tooltipData: {
      current: {
        Name: {
          value: "Fizzah Bajwa",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1470,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fizzah Bajwa",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1470,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venky Venu",
    defaultValue: 1463,
    tooltipData: {
      current: {
        Name: {
          value: "Venky Venu",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1463,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venky Venu",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1463,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hanif naskani",
    defaultValue: 1461,
    tooltipData: {
      current: {
        Name: {
          value: "hanif naskani",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1461,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hanif naskani",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1461,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "angela bazzi",
    defaultValue: 1458,
    tooltipData: {
      current: {
        Name: {
          value: "angela bazzi",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1458,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "angela bazzi",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1458,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rajesh saha",
    defaultValue: 1456,
    tooltipData: {
      current: {
        Name: {
          value: "rajesh saha",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1456,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rajesh saha",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1456,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "azghar pasha",
    defaultValue: 1452,
    tooltipData: {
      current: {
        Name: {
          value: "azghar pasha",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1452,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "azghar pasha",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1452,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "megha srivastava",
    defaultValue: 1448,
    tooltipData: {
      current: {
        Name: {
          value: "megha srivastava",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1448,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "megha srivastava",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1448,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "asma zaidi",
    defaultValue: 1448,
    tooltipData: {
      current: {
        Name: {
          value: "asma zaidi",
          format: false,
        },
        TotalOrders: {
          value: 53,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1448,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "asma zaidi",
          format: false,
        },
        TotalOrders: {
          value: 53,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1448,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammad Haque",
    defaultValue: 1448,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammad Haque",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1448,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammad Haque",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1448,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bismah irfan",
    defaultValue: 1444,
    tooltipData: {
      current: {
        Name: {
          value: "bismah irfan",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1444,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bismah irfan",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1444,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajeev Lochan",
    defaultValue: 1437,
    tooltipData: {
      current: {
        Name: {
          value: "Rajeev Lochan",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1437,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajeev Lochan",
          format: false,
        },
        TotalOrders: {
          value: 67,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1437,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amit Gupta",
    defaultValue: 1431,
    tooltipData: {
      current: {
        Name: {
          value: "Amit Gupta",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1431,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amit Gupta",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1431,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saima Siddiqui",
    defaultValue: 1424,
    tooltipData: {
      current: {
        Name: {
          value: "Saima Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1424,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saima Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1424,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kinza Rana",
    defaultValue: 1414,
    tooltipData: {
      current: {
        Name: {
          value: "Kinza Rana",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1414,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kinza Rana",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1414,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramya ayyanki",
    defaultValue: 1414,
    tooltipData: {
      current: {
        Name: {
          value: "ramya ayyanki",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1414,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramya ayyanki",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1414,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samir Ashfaq",
    defaultValue: 1412,
    tooltipData: {
      current: {
        Name: {
          value: "Samir Ashfaq",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1412,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samir Ashfaq",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1412,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Varun Ram",
    defaultValue: 1408,
    tooltipData: {
      current: {
        Name: {
          value: "Varun Ram",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1408,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Varun Ram",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1408,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prachi Kondaskar",
    defaultValue: 1403,
    tooltipData: {
      current: {
        Name: {
          value: "Prachi Kondaskar",
          format: false,
        },
        TotalOrders: {
          value: 63,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1403,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prachi Kondaskar",
          format: false,
        },
        TotalOrders: {
          value: 63,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1403,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "THOMAS MATHU",
    defaultValue: 1399,
    tooltipData: {
      current: {
        Name: {
          value: "THOMAS MATHU",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1399,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "THOMAS MATHU",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1399,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Selva Baskar",
    defaultValue: 1395,
    tooltipData: {
      current: {
        Name: {
          value: "Selva Baskar",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1395,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Selva Baskar",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1395,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anup Pande",
    defaultValue: 1394,
    tooltipData: {
      current: {
        Name: {
          value: "Anup Pande",
          format: false,
        },
        TotalOrders: {
          value: 81,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1394,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anup Pande",
          format: false,
        },
        TotalOrders: {
          value: 81,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1394,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepa Ushadevi",
    defaultValue: 1393,
    tooltipData: {
      current: {
        Name: {
          value: "Deepa Ushadevi",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1393,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepa Ushadevi",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1393,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Snitha Pakki",
    defaultValue: 1392,
    tooltipData: {
      current: {
        Name: {
          value: "Snitha Pakki",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1392,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Snitha Pakki",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1392,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amina hayat",
    defaultValue: 1380,
    tooltipData: {
      current: {
        Name: {
          value: "amina hayat",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1380,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amina hayat",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1380,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muz Shafi",
    defaultValue: 1378,
    tooltipData: {
      current: {
        Name: {
          value: "Muz Shafi",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1378,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muz Shafi",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1378,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shashi Kapoor",
    defaultValue: 1377,
    tooltipData: {
      current: {
        Name: {
          value: "Shashi Kapoor",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1377,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shashi Kapoor",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1377,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dev A",
    defaultValue: 1374,
    tooltipData: {
      current: {
        Name: {
          value: "Dev A",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1374,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dev A",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1374,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fahad Qureshi",
    defaultValue: 1367,
    tooltipData: {
      current: {
        Name: {
          value: "Fahad Qureshi",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1367,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fahad Qureshi",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1367,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nancy Sam",
    defaultValue: 1358,
    tooltipData: {
      current: {
        Name: {
          value: "Nancy Sam",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1358,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nancy Sam",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1358,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "huma rais",
    defaultValue: 1351,
    tooltipData: {
      current: {
        Name: {
          value: "huma rais",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1351,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "huma rais",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1351,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "syed shah",
    defaultValue: 1349,
    tooltipData: {
      current: {
        Name: {
          value: "syed shah",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1349,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "syed shah",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1349,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sathish rajgopalal",
    defaultValue: 1348,
    tooltipData: {
      current: {
        Name: {
          value: "sathish rajgopalal",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1348,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sathish rajgopalal",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1348,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saima shoaib",
    defaultValue: 1348,
    tooltipData: {
      current: {
        Name: {
          value: "saima shoaib",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1348,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saima shoaib",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1348,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ayaz Bhuiyan",
    defaultValue: 1347,
    tooltipData: {
      current: {
        Name: {
          value: "Ayaz Bhuiyan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1347,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ayaz Bhuiyan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1347,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sarah K",
    defaultValue: 1346,
    tooltipData: {
      current: {
        Name: {
          value: "Sarah K",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1346,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sarah K",
          format: false,
        },
        TotalOrders: {
          value: 64,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1346,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zainab Dhoon",
    defaultValue: 1346,
    tooltipData: {
      current: {
        Name: {
          value: "Zainab Dhoon",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1346,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zainab Dhoon",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1346,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rama Darga",
    defaultValue: 1345,
    tooltipData: {
      current: {
        Name: {
          value: "Rama Darga",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1345,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rama Darga",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1345,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amit kulshrestha",
    defaultValue: 1344,
    tooltipData: {
      current: {
        Name: {
          value: "amit kulshrestha",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1344,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amit kulshrestha",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1344,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhagwat Haribhau",
    defaultValue: 1342,
    tooltipData: {
      current: {
        Name: {
          value: "Bhagwat Haribhau",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1342,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhagwat Haribhau",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1342,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Imran Rashid",
    defaultValue: 1339,
    tooltipData: {
      current: {
        Name: {
          value: "Imran Rashid",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1339,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Imran Rashid",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1339,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srilatha Dhonthinani",
    defaultValue: 1337,
    tooltipData: {
      current: {
        Name: {
          value: "Srilatha Dhonthinani",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1337,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srilatha Dhonthinani",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1337,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "humeer shaik",
    defaultValue: 1332,
    tooltipData: {
      current: {
        Name: {
          value: "humeer shaik",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1332,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "humeer shaik",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1332,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "farah farahshah",
    defaultValue: 1323,
    tooltipData: {
      current: {
        Name: {
          value: "farah farahshah",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1323,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "farah farahshah",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1323,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Monu Koshy",
    defaultValue: 1322,
    tooltipData: {
      current: {
        Name: {
          value: "Monu Koshy",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Monu Koshy",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ahmed Faraz",
    defaultValue: 1322,
    tooltipData: {
      current: {
        Name: {
          value: "Ahmed Faraz",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ahmed Faraz",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vineet rai",
    defaultValue: 1314,
    tooltipData: {
      current: {
        Name: {
          value: "Vineet rai",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1314,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vineet rai",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1314,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suparna Sanyal",
    defaultValue: 1313,
    tooltipData: {
      current: {
        Name: {
          value: "Suparna Sanyal",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1313,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suparna Sanyal",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1313,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "senthil kathirvel",
    defaultValue: 1311,
    tooltipData: {
      current: {
        Name: {
          value: "senthil kathirvel",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1311,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "senthil kathirvel",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1311,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jaspreet Singh",
    defaultValue: 1306,
    tooltipData: {
      current: {
        Name: {
          value: "Jaspreet Singh",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1306,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jaspreet Singh",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1306,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "archana parashar",
    defaultValue: 1302,
    tooltipData: {
      current: {
        Name: {
          value: "archana parashar",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1302,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "archana parashar",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1302,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saraiah Thotla",
    defaultValue: 1298,
    tooltipData: {
      current: {
        Name: {
          value: "Saraiah Thotla",
          format: false,
        },
        TotalOrders: {
          value: 82,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1298,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saraiah Thotla",
          format: false,
        },
        TotalOrders: {
          value: 82,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1298,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Varsha N",
    defaultValue: 1297,
    tooltipData: {
      current: {
        Name: {
          value: "Varsha N",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1297,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Varsha N",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1297,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramnath sekar",
    defaultValue: 1296,
    tooltipData: {
      current: {
        Name: {
          value: "ramnath sekar",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1296,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramnath sekar",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1296,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "noman siddique",
    defaultValue: 1295,
    tooltipData: {
      current: {
        Name: {
          value: "noman siddique",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1295,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "noman siddique",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1295,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kumar M",
    defaultValue: 1294,
    tooltipData: {
      current: {
        Name: {
          value: "Kumar M",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1294,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kumar M",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1294,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shipra Jain",
    defaultValue: 1291,
    tooltipData: {
      current: {
        Name: {
          value: "Shipra Jain",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1291,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shipra Jain",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1291,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kimmi sahay",
    defaultValue: 1284,
    tooltipData: {
      current: {
        Name: {
          value: "kimmi sahay",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1284,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kimmi sahay",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1284,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "uma giduthuri",
    defaultValue: 1284,
    tooltipData: {
      current: {
        Name: {
          value: "uma giduthuri",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1284,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "uma giduthuri",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1284,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dimple Sethi",
    defaultValue: 1283,
    tooltipData: {
      current: {
        Name: {
          value: "Dimple Sethi",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1283,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dimple Sethi",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1283,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Utsav Upadhyaya",
    defaultValue: 1276,
    tooltipData: {
      current: {
        Name: {
          value: "Utsav Upadhyaya",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1276,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Utsav Upadhyaya",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1276,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NIDA SYED",
    defaultValue: 1276,
    tooltipData: {
      current: {
        Name: {
          value: "NIDA SYED",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1276,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NIDA SYED",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1276,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ammad Chaudhry",
    defaultValue: 1269,
    tooltipData: {
      current: {
        Name: {
          value: "Ammad Chaudhry",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1269,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ammad Chaudhry",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1269,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neha Puri",
    defaultValue: 1267,
    tooltipData: {
      current: {
        Name: {
          value: "Neha Puri",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1267,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neha Puri",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1267,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tina Dias",
    defaultValue: 1267,
    tooltipData: {
      current: {
        Name: {
          value: "Tina Dias",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1267,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tina Dias",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1267,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ritu chowdhry",
    defaultValue: 1265,
    tooltipData: {
      current: {
        Name: {
          value: "ritu chowdhry",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1265,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ritu chowdhry",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1265,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dev dixit",
    defaultValue: 1265,
    tooltipData: {
      current: {
        Name: {
          value: "dev dixit",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1265,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dev dixit",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1265,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shweta parmar",
    defaultValue: 1254,
    tooltipData: {
      current: {
        Name: {
          value: "shweta parmar",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1254,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shweta parmar",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1254,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Avinash Ranganath",
    defaultValue: 1254,
    tooltipData: {
      current: {
        Name: {
          value: "Avinash Ranganath",
          format: false,
        },
        TotalOrders: {
          value: 69,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1254,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Avinash Ranganath",
          format: false,
        },
        TotalOrders: {
          value: 69,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1254,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ozen Ahmed",
    defaultValue: 1253,
    tooltipData: {
      current: {
        Name: {
          value: "Ozen Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ozen Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Naveed Yahya",
    defaultValue: 1253,
    tooltipData: {
      current: {
        Name: {
          value: "Naveed Yahya",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Naveed Yahya",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yousf a",
    defaultValue: 1248,
    tooltipData: {
      current: {
        Name: {
          value: "Yousf a",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1248,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yousf a",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1248,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sanjeev Daruka",
    defaultValue: 1244,
    tooltipData: {
      current: {
        Name: {
          value: "Sanjeev Daruka",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1244,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sanjeev Daruka",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1244,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Partha Sharma",
    defaultValue: 1243,
    tooltipData: {
      current: {
        Name: {
          value: "Partha Sharma",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1243,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Partha Sharma",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1243,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "danny danny",
    defaultValue: 1243,
    tooltipData: {
      current: {
        Name: {
          value: "danny danny",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1243,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "danny danny",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1243,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "zeeshan manzoor",
    defaultValue: 1241,
    tooltipData: {
      current: {
        Name: {
          value: "zeeshan manzoor",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1241,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "zeeshan manzoor",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1241,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Faraz Waheed",
    defaultValue: 1235,
    tooltipData: {
      current: {
        Name: {
          value: "Faraz Waheed",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1235,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Faraz Waheed",
          format: false,
        },
        TotalOrders: {
          value: 75,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1235,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manoj george",
    defaultValue: 1235,
    tooltipData: {
      current: {
        Name: {
          value: "manoj george",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1235,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manoj george",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1235,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swapan Sahoo",
    defaultValue: 1232,
    tooltipData: {
      current: {
        Name: {
          value: "Swapan Sahoo",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1232,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swapan Sahoo",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1232,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lakshmi Boopathy",
    defaultValue: 1231,
    tooltipData: {
      current: {
        Name: {
          value: "Lakshmi Boopathy",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1231,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lakshmi Boopathy",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1231,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suman Jha",
    defaultValue: 1231,
    tooltipData: {
      current: {
        Name: {
          value: "Suman Jha",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1231,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suman Jha",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1231,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mamatha nidjelli",
    defaultValue: 1226,
    tooltipData: {
      current: {
        Name: {
          value: "mamatha nidjelli",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1226,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mamatha nidjelli",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1226,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Bukhari",
    defaultValue: 1223,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Bukhari",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1223,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Bukhari",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1223,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Indhu Arul",
    defaultValue: 1222,
    tooltipData: {
      current: {
        Name: {
          value: "Indhu Arul",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Indhu Arul",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aundre moodre",
    defaultValue: 1219,
    tooltipData: {
      current: {
        Name: {
          value: "aundre moodre",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1219,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aundre moodre",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1219,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jig P",
    defaultValue: 1217,
    tooltipData: {
      current: {
        Name: {
          value: "Jig P",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1217,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jig P",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1217,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "zahid paul",
    defaultValue: 1216,
    tooltipData: {
      current: {
        Name: {
          value: "zahid paul",
          format: false,
        },
        TotalOrders: {
          value: 68,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1216,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "zahid paul",
          format: false,
        },
        TotalOrders: {
          value: 68,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1216,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vikas Maurya",
    defaultValue: 1211,
    tooltipData: {
      current: {
        Name: {
          value: "Vikas Maurya",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1211,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vikas Maurya",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1211,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Baskaran Subban",
    defaultValue: 1211,
    tooltipData: {
      current: {
        Name: {
          value: "Baskaran Subban",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1211,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Baskaran Subban",
          format: false,
        },
        TotalOrders: {
          value: 56,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1211,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ajit Kumar",
    defaultValue: 1209,
    tooltipData: {
      current: {
        Name: {
          value: "Ajit Kumar",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1209,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ajit Kumar",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1209,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "balaji namasivayam",
    defaultValue: 1209,
    tooltipData: {
      current: {
        Name: {
          value: "balaji namasivayam",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1209,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "balaji namasivayam",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1209,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kishor Rawal",
    defaultValue: 1207,
    tooltipData: {
      current: {
        Name: {
          value: "Kishor Rawal",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1207,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kishor Rawal",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1207,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Monica Kini",
    defaultValue: 1205,
    tooltipData: {
      current: {
        Name: {
          value: "Monica Kini",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1205,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Monica Kini",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1205,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neetu agarwal",
    defaultValue: 1200,
    tooltipData: {
      current: {
        Name: {
          value: "neetu agarwal",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1200,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neetu agarwal",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1200,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "waseen ahmed",
    defaultValue: 1198,
    tooltipData: {
      current: {
        Name: {
          value: "waseen ahmed",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1198,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "waseen ahmed",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1198,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rohit sharma",
    defaultValue: 1196,
    tooltipData: {
      current: {
        Name: {
          value: "rohit sharma",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1196,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rohit sharma",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1196,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "samiya hanif",
    defaultValue: 1196,
    tooltipData: {
      current: {
        Name: {
          value: "samiya hanif",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1196,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "samiya hanif",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1196,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "yoga ponanna",
    defaultValue: 1195,
    tooltipData: {
      current: {
        Name: {
          value: "yoga ponanna",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1195,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "yoga ponanna",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1195,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rama Pyti",
    defaultValue: 1190,
    tooltipData: {
      current: {
        Name: {
          value: "Rama Pyti",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1190,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rama Pyti",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1190,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "renu pir",
    defaultValue: 1189,
    tooltipData: {
      current: {
        Name: {
          value: "renu pir",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1189,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "renu pir",
          format: false,
        },
        TotalOrders: {
          value: 59,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1189,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nasir Ali",
    defaultValue: 1180,
    tooltipData: {
      current: {
        Name: {
          value: "Nasir Ali",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1180,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nasir Ali",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1180,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Puja Mishra",
    defaultValue: 1179,
    tooltipData: {
      current: {
        Name: {
          value: "Puja Mishra",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1179,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Puja Mishra",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1179,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sujay Kahali",
    defaultValue: 1177,
    tooltipData: {
      current: {
        Name: {
          value: "Sujay Kahali",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1177,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sujay Kahali",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1177,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajesh Thiyagarajan",
    defaultValue: 1177,
    tooltipData: {
      current: {
        Name: {
          value: "Rajesh Thiyagarajan",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1177,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajesh Thiyagarajan",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1177,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jyoti dandu",
    defaultValue: 1176,
    tooltipData: {
      current: {
        Name: {
          value: "jyoti dandu",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jyoti dandu",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amber umar",
    defaultValue: 1171,
    tooltipData: {
      current: {
        Name: {
          value: "amber umar",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1171,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amber umar",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1171,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bindu L",
    defaultValue: 1170,
    tooltipData: {
      current: {
        Name: {
          value: "Bindu L",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bindu L",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "satheesh vellingiri",
    defaultValue: 1170,
    tooltipData: {
      current: {
        Name: {
          value: "satheesh vellingiri",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "satheesh vellingiri",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chandu Thangada",
    defaultValue: 1169,
    tooltipData: {
      current: {
        Name: {
          value: "Chandu Thangada",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chandu Thangada",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sabeen Abbas",
    defaultValue: 1169,
    tooltipData: {
      current: {
        Name: {
          value: "Sabeen Abbas",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sabeen Abbas",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Devanshik Singh",
    defaultValue: 1167,
    tooltipData: {
      current: {
        Name: {
          value: "Devanshik Singh",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Devanshik Singh",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nasir Amjad",
    defaultValue: 1165,
    tooltipData: {
      current: {
        Name: {
          value: "Nasir Amjad",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1165,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nasir Amjad",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1165,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amit Rai",
    defaultValue: 1165,
    tooltipData: {
      current: {
        Name: {
          value: "Amit Rai",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1165,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amit Rai",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1165,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "husna ahmed",
    defaultValue: 1163,
    tooltipData: {
      current: {
        Name: {
          value: "husna ahmed",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1163,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "husna ahmed",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1163,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aisha mansoor",
    defaultValue: 1158,
    tooltipData: {
      current: {
        Name: {
          value: "aisha mansoor",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1158,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aisha mansoor",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1158,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SAMINA SALIM",
    defaultValue: 1157,
    tooltipData: {
      current: {
        Name: {
          value: "SAMINA SALIM",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SAMINA SALIM",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srikanth G",
    defaultValue: 1154,
    tooltipData: {
      current: {
        Name: {
          value: "Srikanth G",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srikanth G",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "liza amin",
    defaultValue: 1152,
    tooltipData: {
      current: {
        Name: {
          value: "liza amin",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1152,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "liza amin",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1152,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anam Farooqui",
    defaultValue: 1150,
    tooltipData: {
      current: {
        Name: {
          value: "Anam Farooqui",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1150,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anam Farooqui",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1150,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SUSAN FERNANDES",
    defaultValue: 1146,
    tooltipData: {
      current: {
        Name: {
          value: "SUSAN FERNANDES",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1146,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SUSAN FERNANDES",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1146,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saira kidwai",
    defaultValue: 1133,
    tooltipData: {
      current: {
        Name: {
          value: "saira kidwai",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saira kidwai",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunil Boga",
    defaultValue: 1132,
    tooltipData: {
      current: {
        Name: {
          value: "Sunil Boga",
          format: false,
        },
        TotalOrders: {
          value: 71,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1132,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunil Boga",
          format: false,
        },
        TotalOrders: {
          value: 71,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1132,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashwin Karanam",
    defaultValue: 1125,
    tooltipData: {
      current: {
        Name: {
          value: "Ashwin Karanam",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1125,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashwin Karanam",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1125,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srikar Mallampati",
    defaultValue: 1122,
    tooltipData: {
      current: {
        Name: {
          value: "Srikar Mallampati",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1122,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srikar Mallampati",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1122,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sidra farooqi",
    defaultValue: 1120,
    tooltipData: {
      current: {
        Name: {
          value: "sidra farooqi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1120,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sidra farooqi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1120,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "imad khan",
    defaultValue: 1119,
    tooltipData: {
      current: {
        Name: {
          value: "imad khan",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1119,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "imad khan",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1119,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kaneez jamal",
    defaultValue: 1116,
    tooltipData: {
      current: {
        Name: {
          value: "kaneez jamal",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1116,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kaneez jamal",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1116,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sanjeev Julka",
    defaultValue: 1115,
    tooltipData: {
      current: {
        Name: {
          value: "Sanjeev Julka",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sanjeev Julka",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shahul Sah",
    defaultValue: 1113,
    tooltipData: {
      current: {
        Name: {
          value: "Shahul Sah",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shahul Sah",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "smriti bose",
    defaultValue: 1110,
    tooltipData: {
      current: {
        Name: {
          value: "smriti bose",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "smriti bose",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Soniya Goyal",
    defaultValue: 1110,
    tooltipData: {
      current: {
        Name: {
          value: "Soniya Goyal",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Soniya Goyal",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rajendra kotturi",
    defaultValue: 1108,
    tooltipData: {
      current: {
        Name: {
          value: "rajendra kotturi",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rajendra kotturi",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Niranjan Veeranki",
    defaultValue: 1108,
    tooltipData: {
      current: {
        Name: {
          value: "Niranjan Veeranki",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Niranjan Veeranki",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Leela Prabhu",
    defaultValue: 1105,
    tooltipData: {
      current: {
        Name: {
          value: "Leela Prabhu",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1105,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Leela Prabhu",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1105,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ahsan Aziz",
    defaultValue: 1096,
    tooltipData: {
      current: {
        Name: {
          value: "ahsan Aziz",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1096,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ahsan Aziz",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1096,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Harsh Chaudhry",
    defaultValue: 1096,
    tooltipData: {
      current: {
        Name: {
          value: "Harsh Chaudhry",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1096,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Harsh Chaudhry",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1096,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bala s",
    defaultValue: 1094,
    tooltipData: {
      current: {
        Name: {
          value: "bala s",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1094,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bala s",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1094,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bilal Syed",
    defaultValue: 1092,
    tooltipData: {
      current: {
        Name: {
          value: "Bilal Syed",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1092,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bilal Syed",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1092,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nighat nasir",
    defaultValue: 1092,
    tooltipData: {
      current: {
        Name: {
          value: "nighat nasir",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1092,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nighat nasir",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1092,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "khaled chowdhury",
    defaultValue: 1088,
    tooltipData: {
      current: {
        Name: {
          value: "khaled chowdhury",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1088,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "khaled chowdhury",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1088,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Husnain",
    defaultValue: 1088,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Husnain",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1088,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Husnain",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1088,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kim Dsouza",
    defaultValue: 1085,
    tooltipData: {
      current: {
        Name: {
          value: "Kim Dsouza",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1085,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kim Dsouza",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1085,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhvi Kumari",
    defaultValue: 1082,
    tooltipData: {
      current: {
        Name: {
          value: "Madhvi Kumari",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1082,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhvi Kumari",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1082,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramesh oad",
    defaultValue: 1080,
    tooltipData: {
      current: {
        Name: {
          value: "ramesh oad",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1080,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramesh oad",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1080,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "yesvanti r",
    defaultValue: 1077,
    tooltipData: {
      current: {
        Name: {
          value: "yesvanti r",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1077,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "yesvanti r",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1077,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hina hassan",
    defaultValue: 1075,
    tooltipData: {
      current: {
        Name: {
          value: "hina hassan",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1075,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hina hassan",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1075,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumit Pal",
    defaultValue: 1073,
    tooltipData: {
      current: {
        Name: {
          value: "Sumit Pal",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1073,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumit Pal",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1073,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gopa Kumar",
    defaultValue: 1073,
    tooltipData: {
      current: {
        Name: {
          value: "Gopa Kumar",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1073,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gopa Kumar",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1073,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fraz fraz",
    defaultValue: 1072,
    tooltipData: {
      current: {
        Name: {
          value: "fraz fraz",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1072,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fraz fraz",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1072,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mUHAMMAD Shaikh",
    defaultValue: 1069,
    tooltipData: {
      current: {
        Name: {
          value: "mUHAMMAD Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1069,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mUHAMMAD Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1069,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sahir abbasi",
    defaultValue: 1067,
    tooltipData: {
      current: {
        Name: {
          value: "sahir abbasi",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1067,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sahir abbasi",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1067,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zara Khan",
    defaultValue: 1066,
    tooltipData: {
      current: {
        Name: {
          value: "Zara Khan",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1066,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zara Khan",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1066,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shubha prasanna",
    defaultValue: 1060,
    tooltipData: {
      current: {
        Name: {
          value: "shubha prasanna",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1060,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shubha prasanna",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1060,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sarwat Rehan",
    defaultValue: 1060,
    tooltipData: {
      current: {
        Name: {
          value: "Sarwat Rehan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1060,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sarwat Rehan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1060,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srikanth Tangella",
    defaultValue: 1060,
    tooltipData: {
      current: {
        Name: {
          value: "Srikanth Tangella",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1060,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srikanth Tangella",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1060,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "venket kumar",
    defaultValue: 1057,
    tooltipData: {
      current: {
        Name: {
          value: "venket kumar",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1057,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "venket kumar",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1057,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jacqueline d",
    defaultValue: 1057,
    tooltipData: {
      current: {
        Name: {
          value: "Jacqueline d",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1057,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jacqueline d",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1057,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ymane elghirai",
    defaultValue: 1055,
    tooltipData: {
      current: {
        Name: {
          value: "ymane elghirai",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1055,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ymane elghirai",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1055,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "VIneeta Juthani",
    defaultValue: 1054,
    tooltipData: {
      current: {
        Name: {
          value: "VIneeta Juthani",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1054,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "VIneeta Juthani",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1054,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sheetal koparde",
    defaultValue: 1050,
    tooltipData: {
      current: {
        Name: {
          value: "sheetal koparde",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1050,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sheetal koparde",
          format: false,
        },
        TotalOrders: {
          value: 50,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1050,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Maria Siddiqwui",
    defaultValue: 1049,
    tooltipData: {
      current: {
        Name: {
          value: "Maria Siddiqwui",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1049,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Maria Siddiqwui",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1049,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prashant k",
    defaultValue: 1044,
    tooltipData: {
      current: {
        Name: {
          value: "Prashant k",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1044,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prashant k",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1044,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aparna thatigutla",
    defaultValue: 1044,
    tooltipData: {
      current: {
        Name: {
          value: "aparna thatigutla",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1044,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aparna thatigutla",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1044,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Waqas Fazal",
    defaultValue: 1041,
    tooltipData: {
      current: {
        Name: {
          value: "Waqas Fazal",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1041,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Waqas Fazal",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1041,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dharmeen Gujrat",
    defaultValue: 1040,
    tooltipData: {
      current: {
        Name: {
          value: "Dharmeen Gujrat",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1040,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dharmeen Gujrat",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1040,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dias Krish",
    defaultValue: 1032,
    tooltipData: {
      current: {
        Name: {
          value: "Dias Krish",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1032,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dias Krish",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1032,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prasad Vulisi",
    defaultValue: 1032,
    tooltipData: {
      current: {
        Name: {
          value: "Prasad Vulisi",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1032,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prasad Vulisi",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1032,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anil Kumar Boddu",
    defaultValue: 1032,
    tooltipData: {
      current: {
        Name: {
          value: "Anil Kumar Boddu",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1032,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anil Kumar Boddu",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1032,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "moin khan",
    defaultValue: 1030,
    tooltipData: {
      current: {
        Name: {
          value: "moin khan",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1030,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "moin khan",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1030,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Viju Yuva",
    defaultValue: 1027,
    tooltipData: {
      current: {
        Name: {
          value: "Viju Yuva",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1027,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Viju Yuva",
          format: false,
        },
        TotalOrders: {
          value: 62,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1027,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramesh papadas",
    defaultValue: 1023,
    tooltipData: {
      current: {
        Name: {
          value: "ramesh papadas",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1023,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramesh papadas",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1023,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinitha Niruban",
    defaultValue: 1022,
    tooltipData: {
      current: {
        Name: {
          value: "Vinitha Niruban",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1022,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinitha Niruban",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1022,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suganya ilavarasan",
    defaultValue: 1022,
    tooltipData: {
      current: {
        Name: {
          value: "Suganya ilavarasan",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1022,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suganya ilavarasan",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1022,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neil ragbirsingh",
    defaultValue: 1018,
    tooltipData: {
      current: {
        Name: {
          value: "neil ragbirsingh",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1018,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neil ragbirsingh",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1018,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yasser Syed",
    defaultValue: 1017,
    tooltipData: {
      current: {
        Name: {
          value: "Yasser Syed",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1017,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yasser Syed",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1017,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sashi kona",
    defaultValue: 1017,
    tooltipData: {
      current: {
        Name: {
          value: "sashi kona",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1017,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sashi kona",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1017,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aamer Athar",
    defaultValue: 1013,
    tooltipData: {
      current: {
        Name: {
          value: "Aamer Athar",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1013,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aamer Athar",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1013,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amar singh",
    defaultValue: 1008,
    tooltipData: {
      current: {
        Name: {
          value: "amar singh",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1008,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amar singh",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1008,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "MD Hassan",
    defaultValue: 1007,
    tooltipData: {
      current: {
        Name: {
          value: "MD Hassan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1007,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "MD Hassan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1007,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "elly saion",
    defaultValue: 1006,
    tooltipData: {
      current: {
        Name: {
          value: "elly saion",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1006,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "elly saion",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1006,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ali dalal",
    defaultValue: 1006,
    tooltipData: {
      current: {
        Name: {
          value: "ali dalal",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1006,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ali dalal",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1006,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sekhar T",
    defaultValue: 1006,
    tooltipData: {
      current: {
        Name: {
          value: "Sekhar T",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1006,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sekhar T",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1006,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zainab Nabeel",
    defaultValue: 1005,
    tooltipData: {
      current: {
        Name: {
          value: "Zainab Nabeel",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1005,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zainab Nabeel",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1005,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pooja kamath",
    defaultValue: 1000,
    tooltipData: {
      current: {
        Name: {
          value: "pooja kamath",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1000,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pooja kamath",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 1000,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajni Nehra",
    defaultValue: 996,
    tooltipData: {
      current: {
        Name: {
          value: "Rajni Nehra",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 996,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajni Nehra",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 996,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi G",
    defaultValue: 995,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi G",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 995,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi G",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 995,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "syed hussian",
    defaultValue: 995,
    tooltipData: {
      current: {
        Name: {
          value: "syed hussian",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 995,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "syed hussian",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 995,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saravanan ravi",
    defaultValue: 994,
    tooltipData: {
      current: {
        Name: {
          value: "saravanan ravi",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 994,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saravanan ravi",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 994,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "intesar ahmed",
    defaultValue: 993,
    tooltipData: {
      current: {
        Name: {
          value: "intesar ahmed",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 993,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "intesar ahmed",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 993,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gina Mamora",
    defaultValue: 986,
    tooltipData: {
      current: {
        Name: {
          value: "Gina Mamora",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 986,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gina Mamora",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 986,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hasan Beyabani",
    defaultValue: 986,
    tooltipData: {
      current: {
        Name: {
          value: "Hasan Beyabani",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 986,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hasan Beyabani",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 986,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alka Agrawal",
    defaultValue: 985,
    tooltipData: {
      current: {
        Name: {
          value: "Alka Agrawal",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 985,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alka Agrawal",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 985,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ronald Lopes",
    defaultValue: 984,
    tooltipData: {
      current: {
        Name: {
          value: "Ronald Lopes",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 984,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ronald Lopes",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 984,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Maqsood Majid",
    defaultValue: 984,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Maqsood Majid",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 984,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Maqsood Majid",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 984,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ameer Syed",
    defaultValue: 981,
    tooltipData: {
      current: {
        Name: {
          value: "Ameer Syed",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 981,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ameer Syed",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 981,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arif Khan",
    defaultValue: 981,
    tooltipData: {
      current: {
        Name: {
          value: "Arif Khan",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 981,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arif Khan",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 981,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Seada Mohamed",
    defaultValue: 977,
    tooltipData: {
      current: {
        Name: {
          value: "Seada Mohamed",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 977,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Seada Mohamed",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 977,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nawshad Ahmad",
    defaultValue: 976,
    tooltipData: {
      current: {
        Name: {
          value: "Nawshad Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 976,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nawshad Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 976,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kuresh Huseni",
    defaultValue: 975,
    tooltipData: {
      current: {
        Name: {
          value: "Kuresh Huseni",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 975,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kuresh Huseni",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 975,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sharmila Bose",
    defaultValue: 975,
    tooltipData: {
      current: {
        Name: {
          value: "Sharmila Bose",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 975,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sharmila Bose",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 975,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srnivas Dan",
    defaultValue: 975,
    tooltipData: {
      current: {
        Name: {
          value: "Srnivas Dan",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 975,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srnivas Dan",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 975,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Namita maharana",
    defaultValue: 974,
    tooltipData: {
      current: {
        Name: {
          value: "Namita maharana",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 974,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Namita maharana",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 974,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sreeni Ediga",
    defaultValue: 973,
    tooltipData: {
      current: {
        Name: {
          value: "Sreeni Ediga",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 973,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sreeni Ediga",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 973,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mehak Arif",
    defaultValue: 973,
    tooltipData: {
      current: {
        Name: {
          value: "Mehak Arif",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 973,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mehak Arif",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 973,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raati Bhattachari",
    defaultValue: 973,
    tooltipData: {
      current: {
        Name: {
          value: "Raati Bhattachari",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 973,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raati Bhattachari",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 973,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manjeera Puttha",
    defaultValue: 972,
    tooltipData: {
      current: {
        Name: {
          value: "Manjeera Puttha",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 972,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manjeera Puttha",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 972,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raza hussain",
    defaultValue: 966,
    tooltipData: {
      current: {
        Name: {
          value: "raza hussain",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 966,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raza hussain",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 966,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Iqra Younus",
    defaultValue: 964,
    tooltipData: {
      current: {
        Name: {
          value: "Iqra Younus",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 964,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Iqra Younus",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 964,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "uzma syed",
    defaultValue: 961,
    tooltipData: {
      current: {
        Name: {
          value: "uzma syed",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 961,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "uzma syed",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 961,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kaushaik chowdhury",
    defaultValue: 958,
    tooltipData: {
      current: {
        Name: {
          value: "kaushaik chowdhury",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 958,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kaushaik chowdhury",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 958,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Job Abraham",
    defaultValue: 958,
    tooltipData: {
      current: {
        Name: {
          value: "Job Abraham",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 958,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Job Abraham",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 958,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suman Kalyan Hajra",
    defaultValue: 957,
    tooltipData: {
      current: {
        Name: {
          value: "Suman Kalyan Hajra",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 957,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suman Kalyan Hajra",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 957,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yusra Naiyyer",
    defaultValue: 953,
    tooltipData: {
      current: {
        Name: {
          value: "Yusra Naiyyer",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 953,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yusra Naiyyer",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 953,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gini Chakcko",
    defaultValue: 950,
    tooltipData: {
      current: {
        Name: {
          value: "Gini Chakcko",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 950,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gini Chakcko",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 950,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kalyan pydipati",
    defaultValue: 948,
    tooltipData: {
      current: {
        Name: {
          value: "kalyan pydipati",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 948,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kalyan pydipati",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 948,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sahar mansoor",
    defaultValue: 944,
    tooltipData: {
      current: {
        Name: {
          value: "sahar mansoor",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 944,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sahar mansoor",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 944,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gulzar charaniya",
    defaultValue: 944,
    tooltipData: {
      current: {
        Name: {
          value: "Gulzar charaniya",
          format: false,
        },
        TotalOrders: {
          value: 78,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 944,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gulzar charaniya",
          format: false,
        },
        TotalOrders: {
          value: 78,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 944,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raghu palla",
    defaultValue: 943,
    tooltipData: {
      current: {
        Name: {
          value: "raghu palla",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 943,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raghu palla",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 943,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Johnsy Paul",
    defaultValue: 943,
    tooltipData: {
      current: {
        Name: {
          value: "Johnsy Paul",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 943,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Johnsy Paul",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 943,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mani sava",
    defaultValue: 943,
    tooltipData: {
      current: {
        Name: {
          value: "mani sava",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 943,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mani sava",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 943,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mayank Modi",
    defaultValue: 942,
    tooltipData: {
      current: {
        Name: {
          value: "Mayank Modi",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 942,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mayank Modi",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 942,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Akash Roy",
    defaultValue: 937,
    tooltipData: {
      current: {
        Name: {
          value: "Akash Roy",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 937,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Akash Roy",
          format: false,
        },
        TotalOrders: {
          value: 51,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 937,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Karthik Ram",
    defaultValue: 937,
    tooltipData: {
      current: {
        Name: {
          value: "Karthik Ram",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 937,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Karthik Ram",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 937,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shiva sadai",
    defaultValue: 937,
    tooltipData: {
      current: {
        Name: {
          value: "shiva sadai",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 937,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shiva sadai",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 937,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "satheesh nelakurthi",
    defaultValue: 933,
    tooltipData: {
      current: {
        Name: {
          value: "satheesh nelakurthi",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 933,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "satheesh nelakurthi",
          format: false,
        },
        TotalOrders: {
          value: 54,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 933,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "GAURAV PARMAR",
    defaultValue: 933,
    tooltipData: {
      current: {
        Name: {
          value: "GAURAV PARMAR",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 933,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "GAURAV PARMAR",
          format: false,
        },
        TotalOrders: {
          value: 57,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 933,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sindura Reddy",
    defaultValue: 932,
    tooltipData: {
      current: {
        Name: {
          value: "Sindura Reddy",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 932,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sindura Reddy",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 932,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkata siddineni",
    defaultValue: 928,
    tooltipData: {
      current: {
        Name: {
          value: "Venkata siddineni",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 928,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkata siddineni",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 928,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pruthvi raj",
    defaultValue: 925,
    tooltipData: {
      current: {
        Name: {
          value: "pruthvi raj",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 925,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pruthvi raj",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 925,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bipin Kadel",
    defaultValue: 923,
    tooltipData: {
      current: {
        Name: {
          value: "Bipin Kadel",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 923,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bipin Kadel",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 923,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mayank Saxena",
    defaultValue: 922,
    tooltipData: {
      current: {
        Name: {
          value: "Mayank Saxena",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 922,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mayank Saxena",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 922,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anji Javvji",
    defaultValue: 922,
    tooltipData: {
      current: {
        Name: {
          value: "Anji Javvji",
          format: false,
        },
        TotalOrders: {
          value: 58,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 922,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anji Javvji",
          format: false,
        },
        TotalOrders: {
          value: 58,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 922,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Reddy",
    defaultValue: 922,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Reddy",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 922,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Reddy",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 922,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Qaiser Ali",
    defaultValue: 921,
    tooltipData: {
      current: {
        Name: {
          value: "Qaiser Ali",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 921,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Qaiser Ali",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 921,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zubaria Shahab",
    defaultValue: 921,
    tooltipData: {
      current: {
        Name: {
          value: "Zubaria Shahab",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 921,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zubaria Shahab",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 921,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sana ali",
    defaultValue: 919,
    tooltipData: {
      current: {
        Name: {
          value: "sana ali",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 919,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sana ali",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 919,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shankar Pala",
    defaultValue: 917,
    tooltipData: {
      current: {
        Name: {
          value: "Shankar Pala",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 917,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shankar Pala",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 917,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Hamza",
    defaultValue: 916,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Hamza",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 916,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Hamza",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 916,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajesh Nara",
    defaultValue: 913,
    tooltipData: {
      current: {
        Name: {
          value: "Rajesh Nara",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 913,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajesh Nara",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 913,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nazeer Kalakada",
    defaultValue: 912,
    tooltipData: {
      current: {
        Name: {
          value: "Nazeer Kalakada",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 912,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nazeer Kalakada",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 912,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ajay Vishwanath",
    defaultValue: 912,
    tooltipData: {
      current: {
        Name: {
          value: "Ajay Vishwanath",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 912,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ajay Vishwanath",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 912,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rehana Rana",
    defaultValue: 912,
    tooltipData: {
      current: {
        Name: {
          value: "Rehana Rana",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 912,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rehana Rana",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 912,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tania parvesh",
    defaultValue: 911,
    tooltipData: {
      current: {
        Name: {
          value: "tania parvesh",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 911,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tania parvesh",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 911,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "siva kumar",
    defaultValue: 909,
    tooltipData: {
      current: {
        Name: {
          value: "siva kumar",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 909,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "siva kumar",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 909,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srikanth Surapaneni",
    defaultValue: 906,
    tooltipData: {
      current: {
        Name: {
          value: "Srikanth Surapaneni",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 906,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srikanth Surapaneni",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 906,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arshia Waheed",
    defaultValue: 903,
    tooltipData: {
      current: {
        Name: {
          value: "Arshia Waheed",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 903,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arshia Waheed",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 903,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Batool Hussain",
    defaultValue: 902,
    tooltipData: {
      current: {
        Name: {
          value: "Batool Hussain",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 902,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Batool Hussain",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 902,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "AVI NANDALA",
    defaultValue: 901,
    tooltipData: {
      current: {
        Name: {
          value: "AVI NANDALA",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 901,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "AVI NANDALA",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 901,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhawana Bajaj",
    defaultValue: 900,
    tooltipData: {
      current: {
        Name: {
          value: "Bhawana Bajaj",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 900,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhawana Bajaj",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 900,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sara fazal",
    defaultValue: 900,
    tooltipData: {
      current: {
        Name: {
          value: "sara fazal",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 900,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sara fazal",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 900,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venky N",
    defaultValue: 899,
    tooltipData: {
      current: {
        Name: {
          value: "Venky N",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 899,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venky N",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 899,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Laxmi kol",
    defaultValue: 899,
    tooltipData: {
      current: {
        Name: {
          value: "Laxmi kol",
          format: false,
        },
        TotalOrders: {
          value: 53,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 899,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Laxmi kol",
          format: false,
        },
        TotalOrders: {
          value: 53,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 899,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajesh y",
    defaultValue: 895,
    tooltipData: {
      current: {
        Name: {
          value: "Rajesh y",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 895,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajesh y",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 895,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sameen Raza",
    defaultValue: 894,
    tooltipData: {
      current: {
        Name: {
          value: "Sameen Raza",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 894,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sameen Raza",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 894,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ketan Rane",
    defaultValue: 892,
    tooltipData: {
      current: {
        Name: {
          value: "Ketan Rane",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 892,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ketan Rane",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 892,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dhanajaya Jena",
    defaultValue: 891,
    tooltipData: {
      current: {
        Name: {
          value: "Dhanajaya Jena",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 891,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dhanajaya Jena",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 891,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Salman Khan",
    defaultValue: 890,
    tooltipData: {
      current: {
        Name: {
          value: "Salman Khan",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 890,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Salman Khan",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 890,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Shoaib",
    defaultValue: 887,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Shoaib",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 887,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Shoaib",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 887,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "s ali",
    defaultValue: 885,
    tooltipData: {
      current: {
        Name: {
          value: "s ali",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 885,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "s ali",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 885,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Taseen Mufti",
    defaultValue: 883,
    tooltipData: {
      current: {
        Name: {
          value: "Taseen Mufti",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 883,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Taseen Mufti",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 883,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohan Sundaram",
    defaultValue: 883,
    tooltipData: {
      current: {
        Name: {
          value: "Mohan Sundaram",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 883,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohan Sundaram",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 883,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mani Kr",
    defaultValue: 882,
    tooltipData: {
      current: {
        Name: {
          value: "Mani Kr",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 882,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mani Kr",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 882,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhavi joshi",
    defaultValue: 878,
    tooltipData: {
      current: {
        Name: {
          value: "Madhavi joshi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 878,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhavi joshi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 878,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ajay Kumar",
    defaultValue: 874,
    tooltipData: {
      current: {
        Name: {
          value: "Ajay Kumar",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 874,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ajay Kumar",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 874,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Feryal Ahmad",
    defaultValue: 869,
    tooltipData: {
      current: {
        Name: {
          value: "Feryal Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 869,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Feryal Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 869,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hira Hussain",
    defaultValue: 868,
    tooltipData: {
      current: {
        Name: {
          value: "Hira Hussain",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 868,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hira Hussain",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 868,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vijay J",
    defaultValue: 866,
    tooltipData: {
      current: {
        Name: {
          value: "vijay J",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 866,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vijay J",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 866,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Roshan adhikari",
    defaultValue: 863,
    tooltipData: {
      current: {
        Name: {
          value: "Roshan adhikari",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 863,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Roshan adhikari",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 863,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saroj Rout",
    defaultValue: 862,
    tooltipData: {
      current: {
        Name: {
          value: "Saroj Rout",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 862,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saroj Rout",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 862,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "PavanKumar Logam",
    defaultValue: 862,
    tooltipData: {
      current: {
        Name: {
          value: "PavanKumar Logam",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 862,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "PavanKumar Logam",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 862,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Taha Rahman",
    defaultValue: 857,
    tooltipData: {
      current: {
        Name: {
          value: "Taha Rahman",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 857,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Taha Rahman",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 857,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Daniel Sam",
    defaultValue: 855,
    tooltipData: {
      current: {
        Name: {
          value: "Daniel Sam",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 855,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Daniel Sam",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 855,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anushil rai",
    defaultValue: 854,
    tooltipData: {
      current: {
        Name: {
          value: "anushil rai",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 854,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anushil rai",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 854,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepika Singh",
    defaultValue: 854,
    tooltipData: {
      current: {
        Name: {
          value: "Deepika Singh",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 854,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepika Singh",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 854,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "FAREEHA IMAM",
    defaultValue: 852,
    tooltipData: {
      current: {
        Name: {
          value: "FAREEHA IMAM",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 852,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "FAREEHA IMAM",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 852,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepak Pendyala",
    defaultValue: 851,
    tooltipData: {
      current: {
        Name: {
          value: "Deepak Pendyala",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 851,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepak Pendyala",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 851,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahathi Vithala",
    defaultValue: 849,
    tooltipData: {
      current: {
        Name: {
          value: "Mahathi Vithala",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 849,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahathi Vithala",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 849,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunil Dabas",
    defaultValue: 849,
    tooltipData: {
      current: {
        Name: {
          value: "Sunil Dabas",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 849,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunil Dabas",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 849,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rakesh raj",
    defaultValue: 849,
    tooltipData: {
      current: {
        Name: {
          value: "rakesh raj",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 849,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rakesh raj",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 849,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Terence Pabbathi",
    defaultValue: 843,
    tooltipData: {
      current: {
        Name: {
          value: "Terence Pabbathi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 843,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Terence Pabbathi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 843,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SenthilVelan Sadasivam",
    defaultValue: 842,
    tooltipData: {
      current: {
        Name: {
          value: "SenthilVelan Sadasivam",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 842,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SenthilVelan Sadasivam",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 842,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Salman a",
    defaultValue: 841,
    tooltipData: {
      current: {
        Name: {
          value: "Salman a",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 841,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Salman a",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 841,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammad Sarfaraz",
    defaultValue: 840,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammad Sarfaraz",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 840,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammad Sarfaraz",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 840,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ayesha Kashif",
    defaultValue: 836,
    tooltipData: {
      current: {
        Name: {
          value: "Ayesha Kashif",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 836,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ayesha Kashif",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 836,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sithy Iqbal",
    defaultValue: 835,
    tooltipData: {
      current: {
        Name: {
          value: "Sithy Iqbal",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 835,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sithy Iqbal",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 835,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sri velicheti",
    defaultValue: 834,
    tooltipData: {
      current: {
        Name: {
          value: "sri velicheti",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 834,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sri velicheti",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 834,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Annapurana Jampani",
    defaultValue: 833,
    tooltipData: {
      current: {
        Name: {
          value: "Annapurana Jampani",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 833,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Annapurana Jampani",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 833,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ARVIND nair",
    defaultValue: 832,
    tooltipData: {
      current: {
        Name: {
          value: "ARVIND nair",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 832,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ARVIND nair",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 832,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Hasan",
    defaultValue: 832,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Hasan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 832,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Hasan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 832,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Smita Dwivedi",
    defaultValue: 831,
    tooltipData: {
      current: {
        Name: {
          value: "Smita Dwivedi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 831,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Smita Dwivedi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 831,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suprya Godbole",
    defaultValue: 830,
    tooltipData: {
      current: {
        Name: {
          value: "Suprya Godbole",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 830,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suprya Godbole",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 830,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arshia Khan",
    defaultValue: 828,
    tooltipData: {
      current: {
        Name: {
          value: "Arshia Khan",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 828,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arshia Khan",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 828,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mona fernandes",
    defaultValue: 827,
    tooltipData: {
      current: {
        Name: {
          value: "mona fernandes",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 827,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mona fernandes",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 827,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepika Khatrii",
    defaultValue: 826,
    tooltipData: {
      current: {
        Name: {
          value: "Deepika Khatrii",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 826,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepika Khatrii",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 826,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohamed Shoukri",
    defaultValue: 826,
    tooltipData: {
      current: {
        Name: {
          value: "Mohamed Shoukri",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 826,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohamed Shoukri",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 826,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gaurav Pandey",
    defaultValue: 825,
    tooltipData: {
      current: {
        Name: {
          value: "Gaurav Pandey",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 825,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gaurav Pandey",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 825,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tushar Mohanty",
    defaultValue: 824,
    tooltipData: {
      current: {
        Name: {
          value: "Tushar Mohanty",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 824,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tushar Mohanty",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 824,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kathik Ammachathran",
    defaultValue: 821,
    tooltipData: {
      current: {
        Name: {
          value: "Kathik Ammachathran",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 821,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kathik Ammachathran",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 821,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NICK ABUTO",
    defaultValue: 820,
    tooltipData: {
      current: {
        Name: {
          value: "NICK ABUTO",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 820,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NICK ABUTO",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 820,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramesh putta",
    defaultValue: 819,
    tooltipData: {
      current: {
        Name: {
          value: "ramesh putta",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 819,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramesh putta",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 819,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "caroline james",
    defaultValue: 818,
    tooltipData: {
      current: {
        Name: {
          value: "caroline james",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 818,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "caroline james",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 818,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neelam rashid",
    defaultValue: 817,
    tooltipData: {
      current: {
        Name: {
          value: "neelam rashid",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 817,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neelam rashid",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 817,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Soumya Ghosh",
    defaultValue: 816,
    tooltipData: {
      current: {
        Name: {
          value: "Soumya Ghosh",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 816,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Soumya Ghosh",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 816,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hameed Farooq",
    defaultValue: 813,
    tooltipData: {
      current: {
        Name: {
          value: "Hameed Farooq",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 813,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hameed Farooq",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 813,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shakir shaikh",
    defaultValue: 812,
    tooltipData: {
      current: {
        Name: {
          value: "shakir shaikh",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 812,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shakir shaikh",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 812,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hina Anwar",
    defaultValue: 811,
    tooltipData: {
      current: {
        Name: {
          value: "Hina Anwar",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 811,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hina Anwar",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 811,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prakash jayaramaraju",
    defaultValue: 811,
    tooltipData: {
      current: {
        Name: {
          value: "prakash jayaramaraju",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 811,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prakash jayaramaraju",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 811,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sathiya Selvaraj",
    defaultValue: 811,
    tooltipData: {
      current: {
        Name: {
          value: "Sathiya Selvaraj",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 811,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sathiya Selvaraj",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 811,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Binitha Abhilash",
    defaultValue: 810,
    tooltipData: {
      current: {
        Name: {
          value: "Binitha Abhilash",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 810,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Binitha Abhilash",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 810,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Roopa Gandhi",
    defaultValue: 809,
    tooltipData: {
      current: {
        Name: {
          value: "Roopa Gandhi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 809,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Roopa Gandhi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 809,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "chitra memon",
    defaultValue: 809,
    tooltipData: {
      current: {
        Name: {
          value: "chitra memon",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 809,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "chitra memon",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 809,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kalpana S",
    defaultValue: 808,
    tooltipData: {
      current: {
        Name: {
          value: "Kalpana S",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 808,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kalpana S",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 808,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "siva alaparthi",
    defaultValue: 808,
    tooltipData: {
      current: {
        Name: {
          value: "siva alaparthi",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 808,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "siva alaparthi",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 808,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nicki taef",
    defaultValue: 804,
    tooltipData: {
      current: {
        Name: {
          value: "nicki taef",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 804,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nicki taef",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 804,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nadeem Alvi",
    defaultValue: 804,
    tooltipData: {
      current: {
        Name: {
          value: "Nadeem Alvi",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 804,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nadeem Alvi",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 804,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shivam nayak",
    defaultValue: 803,
    tooltipData: {
      current: {
        Name: {
          value: "shivam nayak",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 803,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shivam nayak",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 803,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bibin Krishna",
    defaultValue: 802,
    tooltipData: {
      current: {
        Name: {
          value: "Bibin Krishna",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 802,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bibin Krishna",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 802,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fareed Ali",
    defaultValue: 801,
    tooltipData: {
      current: {
        Name: {
          value: "Fareed Ali",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 801,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fareed Ali",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 801,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramya ganne",
    defaultValue: 800,
    tooltipData: {
      current: {
        Name: {
          value: "ramya ganne",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 800,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramya ganne",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 800,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arunima Aikot",
    defaultValue: 799,
    tooltipData: {
      current: {
        Name: {
          value: "Arunima Aikot",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 799,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arunima Aikot",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 799,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manish tiwari",
    defaultValue: 798,
    tooltipData: {
      current: {
        Name: {
          value: "manish tiwari",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 798,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manish tiwari",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 798,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Moe Zahria",
    defaultValue: 796,
    tooltipData: {
      current: {
        Name: {
          value: "Moe Zahria",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 796,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Moe Zahria",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 796,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vidya segar",
    defaultValue: 796,
    tooltipData: {
      current: {
        Name: {
          value: "vidya segar",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 796,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vidya segar",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 796,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abdul Rehman",
    defaultValue: 794,
    tooltipData: {
      current: {
        Name: {
          value: "Abdul Rehman",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 794,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abdul Rehman",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 794,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vidya G",
    defaultValue: 794,
    tooltipData: {
      current: {
        Name: {
          value: "Vidya G",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 794,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vidya G",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 794,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dilip Raj",
    defaultValue: 794,
    tooltipData: {
      current: {
        Name: {
          value: "Dilip Raj",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 794,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dilip Raj",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 794,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mita Hussain",
    defaultValue: 792,
    tooltipData: {
      current: {
        Name: {
          value: "Mita Hussain",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 792,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mita Hussain",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 792,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rupa Shah",
    defaultValue: 792,
    tooltipData: {
      current: {
        Name: {
          value: "Rupa Shah",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 792,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rupa Shah",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 792,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saima Siddiqui",
    defaultValue: 792,
    tooltipData: {
      current: {
        Name: {
          value: "Saima Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 792,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saima Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 792,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raveendra budida",
    defaultValue: 791,
    tooltipData: {
      current: {
        Name: {
          value: "raveendra budida",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 791,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raveendra budida",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 791,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nimish Surve",
    defaultValue: 791,
    tooltipData: {
      current: {
        Name: {
          value: "Nimish Surve",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 791,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nimish Surve",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 791,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fahim Ahsan",
    defaultValue: 790,
    tooltipData: {
      current: {
        Name: {
          value: "Fahim Ahsan",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 790,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fahim Ahsan",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 790,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Salima Fumakeya",
    defaultValue: 790,
    tooltipData: {
      current: {
        Name: {
          value: "Salima Fumakeya",
          format: false,
        },
        TotalOrders: {
          value: 82,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 790,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Salima Fumakeya",
          format: false,
        },
        TotalOrders: {
          value: 82,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 790,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ruby Mathew",
    defaultValue: 789,
    tooltipData: {
      current: {
        Name: {
          value: "Ruby Mathew",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 789,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ruby Mathew",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 789,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Divya Reka",
    defaultValue: 788,
    tooltipData: {
      current: {
        Name: {
          value: "Divya Reka",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 788,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Divya Reka",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 788,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tirmula Rao Cheedella",
    defaultValue: 786,
    tooltipData: {
      current: {
        Name: {
          value: "Tirmula Rao Cheedella",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 786,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tirmula Rao Cheedella",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 786,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashok Para",
    defaultValue: 785,
    tooltipData: {
      current: {
        Name: {
          value: "Ashok Para",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 785,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashok Para",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 785,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nishal G",
    defaultValue: 784,
    tooltipData: {
      current: {
        Name: {
          value: "Nishal G",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 784,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nishal G",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 784,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sreenivas kondakindi",
    defaultValue: 784,
    tooltipData: {
      current: {
        Name: {
          value: "sreenivas kondakindi",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 784,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sreenivas kondakindi",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 784,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pinto Balaraman",
    defaultValue: 782,
    tooltipData: {
      current: {
        Name: {
          value: "Pinto Balaraman",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 782,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pinto Balaraman",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 782,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SANTOSH IYER",
    defaultValue: 779,
    tooltipData: {
      current: {
        Name: {
          value: "SANTOSH IYER",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 779,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SANTOSH IYER",
          format: false,
        },
        TotalOrders: {
          value: 55,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 779,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Navid Taef",
    defaultValue: 776,
    tooltipData: {
      current: {
        Name: {
          value: "Navid Taef",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Navid Taef",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajesh Kolla",
    defaultValue: 776,
    tooltipData: {
      current: {
        Name: {
          value: "Rajesh Kolla",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajesh Kolla",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ahmed sultan",
    defaultValue: 776,
    tooltipData: {
      current: {
        Name: {
          value: "ahmed sultan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ahmed sultan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srini M",
    defaultValue: 776,
    tooltipData: {
      current: {
        Name: {
          value: "Srini M",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srini M",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sidra Hanif",
    defaultValue: 776,
    tooltipData: {
      current: {
        Name: {
          value: "Sidra Hanif",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sidra Hanif",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepti Kharb",
    defaultValue: 776,
    tooltipData: {
      current: {
        Name: {
          value: "Deepti Kharb",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepti Kharb",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 776,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Reena verma",
    defaultValue: 774,
    tooltipData: {
      current: {
        Name: {
          value: "Reena verma",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 774,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Reena verma",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 774,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Afsal Abdul",
    defaultValue: 773,
    tooltipData: {
      current: {
        Name: {
          value: "Afsal Abdul",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 773,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Afsal Abdul",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 773,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saurabh Tonapi",
    defaultValue: 772,
    tooltipData: {
      current: {
        Name: {
          value: "Saurabh Tonapi",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 772,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saurabh Tonapi",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 772,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anubhav Manak",
    defaultValue: 770,
    tooltipData: {
      current: {
        Name: {
          value: "Anubhav Manak",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 770,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anubhav Manak",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 770,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Noreen Saleem",
    defaultValue: 769,
    tooltipData: {
      current: {
        Name: {
          value: "Noreen Saleem",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 769,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Noreen Saleem",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 769,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tariq khan",
    defaultValue: 769,
    tooltipData: {
      current: {
        Name: {
          value: "tariq khan",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 769,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tariq khan",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 769,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gagan Salwan",
    defaultValue: 768,
    tooltipData: {
      current: {
        Name: {
          value: "Gagan Salwan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 768,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gagan Salwan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 768,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ChandraSekhar CHappparapu",
    defaultValue: 768,
    tooltipData: {
      current: {
        Name: {
          value: "ChandraSekhar CHappparapu",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 768,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ChandraSekhar CHappparapu",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 768,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pranay gadipalli",
    defaultValue: 760,
    tooltipData: {
      current: {
        Name: {
          value: "pranay gadipalli",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 760,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pranay gadipalli",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 760,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anjum Rashed",
    defaultValue: 756,
    tooltipData: {
      current: {
        Name: {
          value: "Anjum Rashed",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 756,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anjum Rashed",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 756,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samia Khan",
    defaultValue: 755,
    tooltipData: {
      current: {
        Name: {
          value: "Samia Khan",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 755,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samia Khan",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 755,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vimal Lakhera",
    defaultValue: 755,
    tooltipData: {
      current: {
        Name: {
          value: "Vimal Lakhera",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 755,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vimal Lakhera",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 755,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saloni mehta",
    defaultValue: 751,
    tooltipData: {
      current: {
        Name: {
          value: "saloni mehta",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 751,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saloni mehta",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 751,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "afshan ahsan",
    defaultValue: 746,
    tooltipData: {
      current: {
        Name: {
          value: "afshan ahsan",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 746,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "afshan ahsan",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 746,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nilesh Apte",
    defaultValue: 744,
    tooltipData: {
      current: {
        Name: {
          value: "Nilesh Apte",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 744,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nilesh Apte",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 744,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prateek Raman",
    defaultValue: 744,
    tooltipData: {
      current: {
        Name: {
          value: "Prateek Raman",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 744,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prateek Raman",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 744,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Keerthana Thummala",
    defaultValue: 743,
    tooltipData: {
      current: {
        Name: {
          value: "Keerthana Thummala",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 743,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Keerthana Thummala",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 743,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "priya ganesh",
    defaultValue: 743,
    tooltipData: {
      current: {
        Name: {
          value: "priya ganesh",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 743,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "priya ganesh",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 743,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raza r",
    defaultValue: 743,
    tooltipData: {
      current: {
        Name: {
          value: "raza r",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 743,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raza r",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 743,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sohini chaudary",
    defaultValue: 742,
    tooltipData: {
      current: {
        Name: {
          value: "sohini chaudary",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 742,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sohini chaudary",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 742,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "wasif rauf",
    defaultValue: 742,
    tooltipData: {
      current: {
        Name: {
          value: "wasif rauf",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 742,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "wasif rauf",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 742,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mayuresh Patil",
    defaultValue: 741,
    tooltipData: {
      current: {
        Name: {
          value: "Mayuresh Patil",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 741,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mayuresh Patil",
          format: false,
        },
        TotalOrders: {
          value: 48,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 741,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sushovan Ghimire",
    defaultValue: 740,
    tooltipData: {
      current: {
        Name: {
          value: "Sushovan Ghimire",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 740,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sushovan Ghimire",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 740,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nida Siddiqui",
    defaultValue: 740,
    tooltipData: {
      current: {
        Name: {
          value: "Nida Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 740,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nida Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 740,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Khalid Akhtar",
    defaultValue: 740,
    tooltipData: {
      current: {
        Name: {
          value: "Khalid Akhtar",
          format: false,
        },
        TotalOrders: {
          value: 58,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 740,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Khalid Akhtar",
          format: false,
        },
        TotalOrders: {
          value: 58,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 740,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ganga dhar yadagiri",
    defaultValue: 736,
    tooltipData: {
      current: {
        Name: {
          value: "ganga dhar yadagiri",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 736,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ganga dhar yadagiri",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 736,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vipra Ram",
    defaultValue: 735,
    tooltipData: {
      current: {
        Name: {
          value: "Vipra Ram",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 735,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vipra Ram",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 735,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aditya Agnihotri",
    defaultValue: 734,
    tooltipData: {
      current: {
        Name: {
          value: "Aditya Agnihotri",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 734,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aditya Agnihotri",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 734,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chanchal Chandak",
    defaultValue: 733,
    tooltipData: {
      current: {
        Name: {
          value: "Chanchal Chandak",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 733,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chanchal Chandak",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 733,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "VIDYA SUDHAKAR",
    defaultValue: 727,
    tooltipData: {
      current: {
        Name: {
          value: "VIDYA SUDHAKAR",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 727,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "VIDYA SUDHAKAR",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 727,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aleema Farooqui",
    defaultValue: 725,
    tooltipData: {
      current: {
        Name: {
          value: "Aleema Farooqui",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 725,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aleema Farooqui",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 725,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ayesha anssari",
    defaultValue: 724,
    tooltipData: {
      current: {
        Name: {
          value: "ayesha anssari",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 724,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ayesha anssari",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 724,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kc Banda",
    defaultValue: 723,
    tooltipData: {
      current: {
        Name: {
          value: "Kc Banda",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 723,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kc Banda",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 723,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mahesh mohan",
    defaultValue: 723,
    tooltipData: {
      current: {
        Name: {
          value: "mahesh mohan",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 723,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mahesh mohan",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 723,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vaneet goyal",
    defaultValue: 723,
    tooltipData: {
      current: {
        Name: {
          value: "vaneet goyal",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 723,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vaneet goyal",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 723,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "samina husain",
    defaultValue: 722,
    tooltipData: {
      current: {
        Name: {
          value: "samina husain",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 722,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "samina husain",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 722,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sylvia anya",
    defaultValue: 722,
    tooltipData: {
      current: {
        Name: {
          value: "sylvia anya",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 722,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sylvia anya",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 722,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "devjit biswas",
    defaultValue: 721,
    tooltipData: {
      current: {
        Name: {
          value: "devjit biswas",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 721,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "devjit biswas",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 721,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ruby khan",
    defaultValue: 719,
    tooltipData: {
      current: {
        Name: {
          value: "ruby khan",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 719,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ruby khan",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 719,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shruti Dobey",
    defaultValue: 719,
    tooltipData: {
      current: {
        Name: {
          value: "Shruti Dobey",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 719,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shruti Dobey",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 719,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jeba Vijay",
    defaultValue: 719,
    tooltipData: {
      current: {
        Name: {
          value: "Jeba Vijay",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 719,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jeba Vijay",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 719,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhu Rao",
    defaultValue: 718,
    tooltipData: {
      current: {
        Name: {
          value: "Madhu Rao",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 718,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhu Rao",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 718,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Basil Tp",
    defaultValue: 717,
    tooltipData: {
      current: {
        Name: {
          value: "Basil Tp",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 717,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Basil Tp",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 717,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Veera Kolan",
    defaultValue: 717,
    tooltipData: {
      current: {
        Name: {
          value: "Veera Kolan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 717,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Veera Kolan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 717,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "asit goradin",
    defaultValue: 716,
    tooltipData: {
      current: {
        Name: {
          value: "asit goradin",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 716,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "asit goradin",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 716,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manjusri Basu",
    defaultValue: 716,
    tooltipData: {
      current: {
        Name: {
          value: "Manjusri Basu",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 716,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manjusri Basu",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 716,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SAAD KHALID",
    defaultValue: 715,
    tooltipData: {
      current: {
        Name: {
          value: "SAAD KHALID",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 715,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SAAD KHALID",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 715,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tushar Kewate",
    defaultValue: 715,
    tooltipData: {
      current: {
        Name: {
          value: "Tushar Kewate",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 715,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tushar Kewate",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 715,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manan Mehta",
    defaultValue: 713,
    tooltipData: {
      current: {
        Name: {
          value: "Manan Mehta",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 713,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manan Mehta",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 713,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Beena Patel",
    defaultValue: 712,
    tooltipData: {
      current: {
        Name: {
          value: "Beena Patel",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 712,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Beena Patel",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 712,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Thirupati Reddy",
    defaultValue: 710,
    tooltipData: {
      current: {
        Name: {
          value: "Thirupati Reddy",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 710,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Thirupati Reddy",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 710,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rupesh Shyam",
    defaultValue: 709,
    tooltipData: {
      current: {
        Name: {
          value: "Rupesh Shyam",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 709,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rupesh Shyam",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 709,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Naidu Gajula",
    defaultValue: 708,
    tooltipData: {
      current: {
        Name: {
          value: "Naidu Gajula",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 708,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Naidu Gajula",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 708,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sudha Pujuri",
    defaultValue: 708,
    tooltipData: {
      current: {
        Name: {
          value: "Sudha Pujuri",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 708,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sudha Pujuri",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 708,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "gautam dhodhi",
    defaultValue: 706,
    tooltipData: {
      current: {
        Name: {
          value: "gautam dhodhi",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 706,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "gautam dhodhi",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 706,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prakash Periyasamy",
    defaultValue: 705,
    tooltipData: {
      current: {
        Name: {
          value: "Prakash Periyasamy",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 705,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prakash Periyasamy",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 705,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Talha R",
    defaultValue: 702,
    tooltipData: {
      current: {
        Name: {
          value: "Talha R",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 702,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Talha R",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 702,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shravani lingampally",
    defaultValue: 699,
    tooltipData: {
      current: {
        Name: {
          value: "shravani lingampally",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 699,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shravani lingampally",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 699,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sreeni s",
    defaultValue: 698,
    tooltipData: {
      current: {
        Name: {
          value: "sreeni s",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 698,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sreeni s",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 698,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Adnan Ahmed",
    defaultValue: 695,
    tooltipData: {
      current: {
        Name: {
          value: "Adnan Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 695,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Adnan Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 695,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Geeta Rajendra",
    defaultValue: 695,
    tooltipData: {
      current: {
        Name: {
          value: "Geeta Rajendra",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 695,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Geeta Rajendra",
          format: false,
        },
        TotalOrders: {
          value: 41,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 695,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sushma Hamal",
    defaultValue: 695,
    tooltipData: {
      current: {
        Name: {
          value: "Sushma Hamal",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 695,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sushma Hamal",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 695,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Niharika Thusu",
    defaultValue: 694,
    tooltipData: {
      current: {
        Name: {
          value: "Niharika Thusu",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 694,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Niharika Thusu",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 694,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi Kurva",
    defaultValue: 694,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi Kurva",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 694,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi Kurva",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 694,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prav Sidhu",
    defaultValue: 694,
    tooltipData: {
      current: {
        Name: {
          value: "Prav Sidhu",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 694,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prav Sidhu",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 694,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sriram chodisetty",
    defaultValue: 692,
    tooltipData: {
      current: {
        Name: {
          value: "sriram chodisetty",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 692,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sriram chodisetty",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 692,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahrookh Mamidanna",
    defaultValue: 691,
    tooltipData: {
      current: {
        Name: {
          value: "Mahrookh Mamidanna",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 691,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahrookh Mamidanna",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 691,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "HItesh Hasija",
    defaultValue: 690,
    tooltipData: {
      current: {
        Name: {
          value: "HItesh Hasija",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 690,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "HItesh Hasija",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 690,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rohini Mukkawar",
    defaultValue: 690,
    tooltipData: {
      current: {
        Name: {
          value: "Rohini Mukkawar",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 690,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rohini Mukkawar",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 690,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Devdas Panjala",
    defaultValue: 688,
    tooltipData: {
      current: {
        Name: {
          value: "Devdas Panjala",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 688,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Devdas Panjala",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 688,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Narayanan",
    defaultValue: 687,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Narayanan",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 687,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Narayanan",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 687,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jay parashar",
    defaultValue: 685,
    tooltipData: {
      current: {
        Name: {
          value: "jay parashar",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 685,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jay parashar",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 685,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sheetal Gandewar",
    defaultValue: 684,
    tooltipData: {
      current: {
        Name: {
          value: "Sheetal Gandewar",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 684,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sheetal Gandewar",
          format: false,
        },
        TotalOrders: {
          value: 61,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 684,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mamta basu",
    defaultValue: 683,
    tooltipData: {
      current: {
        Name: {
          value: "mamta basu",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 683,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mamta basu",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 683,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shamnkha roy",
    defaultValue: 682,
    tooltipData: {
      current: {
        Name: {
          value: "shamnkha roy",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 682,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shamnkha roy",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 682,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ira Giri",
    defaultValue: 681,
    tooltipData: {
      current: {
        Name: {
          value: "Ira Giri",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 681,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ira Giri",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 681,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abdullah A",
    defaultValue: 681,
    tooltipData: {
      current: {
        Name: {
          value: "Abdullah A",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 681,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abdullah A",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 681,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "srini akkala",
    defaultValue: 681,
    tooltipData: {
      current: {
        Name: {
          value: "srini akkala",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 681,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "srini akkala",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 681,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lakshmi divi",
    defaultValue: 681,
    tooltipData: {
      current: {
        Name: {
          value: "lakshmi divi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 681,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lakshmi divi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 681,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sunitha maddala",
    defaultValue: 680,
    tooltipData: {
      current: {
        Name: {
          value: "sunitha maddala",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 680,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sunitha maddala",
          format: false,
        },
        TotalOrders: {
          value: 52,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 680,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abdu Rao",
    defaultValue: 680,
    tooltipData: {
      current: {
        Name: {
          value: "Abdu Rao",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 680,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abdu Rao",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 680,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jivika Govil",
    defaultValue: 680,
    tooltipData: {
      current: {
        Name: {
          value: "Jivika Govil",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 680,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jivika Govil",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 680,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dinesh Vaz",
    defaultValue: 680,
    tooltipData: {
      current: {
        Name: {
          value: "Dinesh Vaz",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 680,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dinesh Vaz",
          format: false,
        },
        TotalOrders: {
          value: 33,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 680,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sharath Putta",
    defaultValue: 678,
    tooltipData: {
      current: {
        Name: {
          value: "Sharath Putta",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 678,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sharath Putta",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 678,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sarah fahad",
    defaultValue: 677,
    tooltipData: {
      current: {
        Name: {
          value: "Sarah fahad",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 677,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sarah fahad",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 677,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Roli Sangal",
    defaultValue: 677,
    tooltipData: {
      current: {
        Name: {
          value: "Roli Sangal",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 677,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Roli Sangal",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 677,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mamatha N",
    defaultValue: 675,
    tooltipData: {
      current: {
        Name: {
          value: "Mamatha N",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 675,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mamatha N",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 675,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "abhishek das",
    defaultValue: 675,
    tooltipData: {
      current: {
        Name: {
          value: "abhishek das",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 675,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "abhishek das",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 675,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Petula Kelson",
    defaultValue: 675,
    tooltipData: {
      current: {
        Name: {
          value: "Petula Kelson",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 675,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Petula Kelson",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 675,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prachi Rakhe",
    defaultValue: 674,
    tooltipData: {
      current: {
        Name: {
          value: "Prachi Rakhe",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 674,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prachi Rakhe",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 674,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Preethi M",
    defaultValue: 673,
    tooltipData: {
      current: {
        Name: {
          value: "Preethi M",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 673,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Preethi M",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 673,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "adnan zaidi",
    defaultValue: 672,
    tooltipData: {
      current: {
        Name: {
          value: "adnan zaidi",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 672,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "adnan zaidi",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 672,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhu Putturi",
    defaultValue: 672,
    tooltipData: {
      current: {
        Name: {
          value: "Madhu Putturi",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 672,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhu Putturi",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 672,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jyotsna Inapuri",
    defaultValue: 672,
    tooltipData: {
      current: {
        Name: {
          value: "Jyotsna Inapuri",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 672,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jyotsna Inapuri",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 672,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rahul Grover",
    defaultValue: 672,
    tooltipData: {
      current: {
        Name: {
          value: "Rahul Grover",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 672,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rahul Grover",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 672,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sid sharma",
    defaultValue: 670,
    tooltipData: {
      current: {
        Name: {
          value: "sid sharma",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 670,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sid sharma",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 670,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi Goli",
    defaultValue: 669,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi Goli",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 669,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi Goli",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 669,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "JK Rao",
    defaultValue: 669,
    tooltipData: {
      current: {
        Name: {
          value: "JK Rao",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 669,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "JK Rao",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 669,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Khalid Razavi",
    defaultValue: 667,
    tooltipData: {
      current: {
        Name: {
          value: "Khalid Razavi",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 667,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Khalid Razavi",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 667,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zeeshan Ahmed",
    defaultValue: 667,
    tooltipData: {
      current: {
        Name: {
          value: "Zeeshan Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 667,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zeeshan Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 667,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "gaglul islam",
    defaultValue: 666,
    tooltipData: {
      current: {
        Name: {
          value: "gaglul islam",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 666,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "gaglul islam",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 666,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rawaa alob",
    defaultValue: 662,
    tooltipData: {
      current: {
        Name: {
          value: "rawaa alob",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 662,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rawaa alob",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 662,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Farhat Naqvi",
    defaultValue: 662,
    tooltipData: {
      current: {
        Name: {
          value: "Farhat Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 662,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Farhat Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 662,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Waris Pasha",
    defaultValue: 662,
    tooltipData: {
      current: {
        Name: {
          value: "Waris Pasha",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 662,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Waris Pasha",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 662,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chandni Menon",
    defaultValue: 661,
    tooltipData: {
      current: {
        Name: {
          value: "Chandni Menon",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 661,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chandni Menon",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 661,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alina Mansoor",
    defaultValue: 661,
    tooltipData: {
      current: {
        Name: {
          value: "Alina Mansoor",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 661,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alina Mansoor",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 661,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sayontan Sinha",
    defaultValue: 661,
    tooltipData: {
      current: {
        Name: {
          value: "Sayontan Sinha",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 661,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sayontan Sinha",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 661,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravali Rao",
    defaultValue: 656,
    tooltipData: {
      current: {
        Name: {
          value: "Ravali Rao",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 656,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravali Rao",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 656,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "santhosh buddha",
    defaultValue: 655,
    tooltipData: {
      current: {
        Name: {
          value: "santhosh buddha",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 655,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "santhosh buddha",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 655,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sehrish khan",
    defaultValue: 655,
    tooltipData: {
      current: {
        Name: {
          value: "sehrish khan",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 655,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sehrish khan",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 655,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sabin sano",
    defaultValue: 655,
    tooltipData: {
      current: {
        Name: {
          value: "sabin sano",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 655,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sabin sano",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 655,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Daniel Rahim",
    defaultValue: 654,
    tooltipData: {
      current: {
        Name: {
          value: "Daniel Rahim",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 654,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Daniel Rahim",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 654,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "gOPI Muluka",
    defaultValue: 654,
    tooltipData: {
      current: {
        Name: {
          value: "gOPI Muluka",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 654,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "gOPI Muluka",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 654,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Omer Mohammed",
    defaultValue: 653,
    tooltipData: {
      current: {
        Name: {
          value: "Omer Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 653,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Omer Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 653,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi Kularnav",
    defaultValue: 652,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi Kularnav",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 652,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi Kularnav",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 652,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkat A",
    defaultValue: 651,
    tooltipData: {
      current: {
        Name: {
          value: "Venkat A",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 651,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkat A",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 651,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "gunjan chhibber",
    defaultValue: 650,
    tooltipData: {
      current: {
        Name: {
          value: "gunjan chhibber",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 650,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "gunjan chhibber",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 650,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abrar Arif",
    defaultValue: 650,
    tooltipData: {
      current: {
        Name: {
          value: "Abrar Arif",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 650,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abrar Arif",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 650,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amina Athar",
    defaultValue: 649,
    tooltipData: {
      current: {
        Name: {
          value: "Amina Athar",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 649,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amina Athar",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 649,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nida Wani",
    defaultValue: 646,
    tooltipData: {
      current: {
        Name: {
          value: "Nida Wani",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 646,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nida Wani",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 646,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lakshman G",
    defaultValue: 646,
    tooltipData: {
      current: {
        Name: {
          value: "Lakshman G",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 646,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lakshman G",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 646,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bala g",
    defaultValue: 646,
    tooltipData: {
      current: {
        Name: {
          value: "bala g",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 646,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bala g",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 646,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nida khan",
    defaultValue: 645,
    tooltipData: {
      current: {
        Name: {
          value: "nida khan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 645,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nida khan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 645,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sana Jia",
    defaultValue: 644,
    tooltipData: {
      current: {
        Name: {
          value: "Sana Jia",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 644,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sana Jia",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 644,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Ali",
    defaultValue: 643,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Ali",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 643,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Ali",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 643,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sreedhar muthuramalingam",
    defaultValue: 641,
    tooltipData: {
      current: {
        Name: {
          value: "sreedhar muthuramalingam",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 641,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sreedhar muthuramalingam",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 641,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ASHER KHAN",
    defaultValue: 639,
    tooltipData: {
      current: {
        Name: {
          value: "ASHER KHAN",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 639,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ASHER KHAN",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 639,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arundhati Pal",
    defaultValue: 639,
    tooltipData: {
      current: {
        Name: {
          value: "Arundhati Pal",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 639,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arundhati Pal",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 639,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ashish loney",
    defaultValue: 638,
    tooltipData: {
      current: {
        Name: {
          value: "ashish loney",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 638,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ashish loney",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 638,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hana Ayedh",
    defaultValue: 637,
    tooltipData: {
      current: {
        Name: {
          value: "Hana Ayedh",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 637,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hana Ayedh",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 637,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunil Borkar",
    defaultValue: 637,
    tooltipData: {
      current: {
        Name: {
          value: "Sunil Borkar",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 637,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunil Borkar",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 637,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amit suman",
    defaultValue: 636,
    tooltipData: {
      current: {
        Name: {
          value: "amit suman",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 636,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amit suman",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 636,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arjun murugan",
    defaultValue: 635,
    tooltipData: {
      current: {
        Name: {
          value: "arjun murugan",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 635,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arjun murugan",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 635,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "katherine alapatt",
    defaultValue: 635,
    tooltipData: {
      current: {
        Name: {
          value: "katherine alapatt",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 635,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "katherine alapatt",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 635,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sanjoy karmakar",
    defaultValue: 635,
    tooltipData: {
      current: {
        Name: {
          value: "sanjoy karmakar",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 635,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sanjoy karmakar",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 635,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yasser B",
    defaultValue: 634,
    tooltipData: {
      current: {
        Name: {
          value: "Yasser B",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 634,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yasser B",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 634,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "indra dayaram",
    defaultValue: 634,
    tooltipData: {
      current: {
        Name: {
          value: "indra dayaram",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 634,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "indra dayaram",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 634,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dasaradha M",
    defaultValue: 633,
    tooltipData: {
      current: {
        Name: {
          value: "dasaradha M",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 633,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dasaradha M",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 633,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohan Mani",
    defaultValue: 633,
    tooltipData: {
      current: {
        Name: {
          value: "Mohan Mani",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 633,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohan Mani",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 633,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nurulhuda shegrud",
    defaultValue: 632,
    tooltipData: {
      current: {
        Name: {
          value: "nurulhuda shegrud",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 632,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nurulhuda shegrud",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 632,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi Thupakula",
    defaultValue: 632,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi Thupakula",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 632,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi Thupakula",
          format: false,
        },
        TotalOrders: {
          value: 44,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 632,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Noman Siddiqui",
    defaultValue: 632,
    tooltipData: {
      current: {
        Name: {
          value: "Noman Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 632,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Noman Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 632,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vivek D",
    defaultValue: 631,
    tooltipData: {
      current: {
        Name: {
          value: "Vivek D",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 631,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vivek D",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 631,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Karie Maharaj",
    defaultValue: 629,
    tooltipData: {
      current: {
        Name: {
          value: "Karie Maharaj",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 629,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Karie Maharaj",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 629,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Monalisa Bera",
    defaultValue: 629,
    tooltipData: {
      current: {
        Name: {
          value: "Monalisa Bera",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 629,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Monalisa Bera",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 629,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shruti yasharth",
    defaultValue: 629,
    tooltipData: {
      current: {
        Name: {
          value: "shruti yasharth",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 629,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shruti yasharth",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 629,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Baimakari Hydara",
    defaultValue: 628,
    tooltipData: {
      current: {
        Name: {
          value: "Baimakari Hydara",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 628,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Baimakari Hydara",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 628,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sarita M",
    defaultValue: 627,
    tooltipData: {
      current: {
        Name: {
          value: "Sarita M",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 627,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sarita M",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 627,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shajahan noorudheen",
    defaultValue: 627,
    tooltipData: {
      current: {
        Name: {
          value: "Shajahan noorudheen",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 627,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shajahan noorudheen",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 627,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Majeed Mohammad",
    defaultValue: 626,
    tooltipData: {
      current: {
        Name: {
          value: "Majeed Mohammad",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 626,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Majeed Mohammad",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 626,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anupama Tiwari",
    defaultValue: 626,
    tooltipData: {
      current: {
        Name: {
          value: "Anupama Tiwari",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 626,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anupama Tiwari",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 626,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zainish Tariq",
    defaultValue: 625,
    tooltipData: {
      current: {
        Name: {
          value: "Zainish Tariq",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 625,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zainish Tariq",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 625,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fahat anwar",
    defaultValue: 624,
    tooltipData: {
      current: {
        Name: {
          value: "fahat anwar",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 624,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fahat anwar",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 624,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "umar khan",
    defaultValue: 624,
    tooltipData: {
      current: {
        Name: {
          value: "umar khan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 624,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "umar khan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 624,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prasad Pakala",
    defaultValue: 622,
    tooltipData: {
      current: {
        Name: {
          value: "Prasad Pakala",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 622,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prasad Pakala",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 622,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priya Venkateshan",
    defaultValue: 622,
    tooltipData: {
      current: {
        Name: {
          value: "Priya Venkateshan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 622,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priya Venkateshan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 622,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "MILAD SINGH",
    defaultValue: 621,
    tooltipData: {
      current: {
        Name: {
          value: "MILAD SINGH",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 621,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "MILAD SINGH",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 621,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Naima Razaq",
    defaultValue: 620,
    tooltipData: {
      current: {
        Name: {
          value: "Naima Razaq",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 620,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Naima Razaq",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 620,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neeta Mojidra",
    defaultValue: 620,
    tooltipData: {
      current: {
        Name: {
          value: "Neeta Mojidra",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 620,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neeta Mojidra",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 620,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nasir Chapparband",
    defaultValue: 619,
    tooltipData: {
      current: {
        Name: {
          value: "Nasir Chapparband",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 619,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nasir Chapparband",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 619,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "chakir c",
    defaultValue: 618,
    tooltipData: {
      current: {
        Name: {
          value: "chakir c",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 618,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "chakir c",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 618,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Divya B",
    defaultValue: 613,
    tooltipData: {
      current: {
        Name: {
          value: "Divya B",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 613,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Divya B",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 613,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kumar Gellu",
    defaultValue: 612,
    tooltipData: {
      current: {
        Name: {
          value: "Kumar Gellu",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 612,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kumar Gellu",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 612,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sushil Gujarati",
    defaultValue: 611,
    tooltipData: {
      current: {
        Name: {
          value: "Sushil Gujarati",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 611,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sushil Gujarati",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 611,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Challa",
    defaultValue: 610,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Challa",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 610,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Challa",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 610,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zoher Neemuchwala",
    defaultValue: 610,
    tooltipData: {
      current: {
        Name: {
          value: "Zoher Neemuchwala",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 610,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zoher Neemuchwala",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 610,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anbili Linish",
    defaultValue: 610,
    tooltipData: {
      current: {
        Name: {
          value: "Anbili Linish",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 610,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anbili Linish",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 610,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tina zaman",
    defaultValue: 609,
    tooltipData: {
      current: {
        Name: {
          value: "tina zaman",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 609,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tina zaman",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 609,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "PRIYA M",
    defaultValue: 609,
    tooltipData: {
      current: {
        Name: {
          value: "PRIYA M",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 609,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "PRIYA M",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 609,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nayab sayed",
    defaultValue: 608,
    tooltipData: {
      current: {
        Name: {
          value: "nayab sayed",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 608,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nayab sayed",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 608,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kavita mahabaleshwarka",
    defaultValue: 608,
    tooltipData: {
      current: {
        Name: {
          value: "kavita mahabaleshwarka",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 608,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kavita mahabaleshwarka",
          format: false,
        },
        TotalOrders: {
          value: 40,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 608,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohan Ram",
    defaultValue: 608,
    tooltipData: {
      current: {
        Name: {
          value: "Mohan Ram",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 608,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohan Ram",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 608,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rupsa Roy",
    defaultValue: 607,
    tooltipData: {
      current: {
        Name: {
          value: "Rupsa Roy",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 607,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rupsa Roy",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 607,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tasnim ziuttin",
    defaultValue: 607,
    tooltipData: {
      current: {
        Name: {
          value: "tasnim ziuttin",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 607,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tasnim ziuttin",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 607,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pal 214975266594",
    defaultValue: 603,
    tooltipData: {
      current: {
        Name: {
          value: "Pal 214975266594",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 603,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pal 214975266594",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 603,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NAVEENRAJ MADHESWARAN",
    defaultValue: 600,
    tooltipData: {
      current: {
        Name: {
          value: "NAVEENRAJ MADHESWARAN",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 600,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NAVEENRAJ MADHESWARAN",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 600,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aneela Ali",
    defaultValue: 599,
    tooltipData: {
      current: {
        Name: {
          value: "Aneela Ali",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 599,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aneela Ali",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 599,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Thirumal Alli",
    defaultValue: 599,
    tooltipData: {
      current: {
        Name: {
          value: "Thirumal Alli",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 599,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Thirumal Alli",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 599,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Goutham Chilula",
    defaultValue: 598,
    tooltipData: {
      current: {
        Name: {
          value: "Goutham Chilula",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 598,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Goutham Chilula",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 598,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sri Kodiganti",
    defaultValue: 598,
    tooltipData: {
      current: {
        Name: {
          value: "Sri Kodiganti",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 598,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sri Kodiganti",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 598,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amanda baca",
    defaultValue: 597,
    tooltipData: {
      current: {
        Name: {
          value: "amanda baca",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 597,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amanda baca",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 597,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Birjees Naqvi",
    defaultValue: 597,
    tooltipData: {
      current: {
        Name: {
          value: "Birjees Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 597,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Birjees Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 597,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aasta Sharma",
    defaultValue: 597,
    tooltipData: {
      current: {
        Name: {
          value: "Aasta Sharma",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 597,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aasta Sharma",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 597,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prasanthi Madineni",
    defaultValue: 597,
    tooltipData: {
      current: {
        Name: {
          value: "Prasanthi Madineni",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 597,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prasanthi Madineni",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 597,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Erode",
    defaultValue: 596,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Erode",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 596,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Erode",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 596,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sudipta datta",
    defaultValue: 594,
    tooltipData: {
      current: {
        Name: {
          value: "sudipta datta",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 594,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sudipta datta",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 594,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nitin khare",
    defaultValue: 594,
    tooltipData: {
      current: {
        Name: {
          value: "nitin khare",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 594,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nitin khare",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 594,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sanya Syed",
    defaultValue: 593,
    tooltipData: {
      current: {
        Name: {
          value: "Sanya Syed",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 593,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sanya Syed",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 593,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mir shrestha",
    defaultValue: 593,
    tooltipData: {
      current: {
        Name: {
          value: "mir shrestha",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 593,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mir shrestha",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 593,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramya gunda",
    defaultValue: 593,
    tooltipData: {
      current: {
        Name: {
          value: "ramya gunda",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 593,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramya gunda",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 593,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rathika Veeraian",
    defaultValue: 591,
    tooltipData: {
      current: {
        Name: {
          value: "Rathika Veeraian",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 591,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rathika Veeraian",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 591,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rumesa Majid",
    defaultValue: 591,
    tooltipData: {
      current: {
        Name: {
          value: "Rumesa Majid",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 591,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rumesa Majid",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 591,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Asif Aziz",
    defaultValue: 591,
    tooltipData: {
      current: {
        Name: {
          value: "Asif Aziz",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 591,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Asif Aziz",
          format: false,
        },
        TotalOrders: {
          value: 35,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 591,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ishika Agarwal",
    defaultValue: 588,
    tooltipData: {
      current: {
        Name: {
          value: "Ishika Agarwal",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 588,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ishika Agarwal",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 588,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay N",
    defaultValue: 587,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay N",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 587,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay N",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 587,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Liji Mathew",
    defaultValue: 587,
    tooltipData: {
      current: {
        Name: {
          value: "Liji Mathew",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 587,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Liji Mathew",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 587,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Atri Sen",
    defaultValue: 586,
    tooltipData: {
      current: {
        Name: {
          value: "Atri Sen",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 586,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Atri Sen",
          format: false,
        },
        TotalOrders: {
          value: 39,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 586,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mehnaz arsha",
    defaultValue: 584,
    tooltipData: {
      current: {
        Name: {
          value: "mehnaz arsha",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 584,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mehnaz arsha",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 584,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ganesan Packirisamy",
    defaultValue: 584,
    tooltipData: {
      current: {
        Name: {
          value: "Ganesan Packirisamy",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 584,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ganesan Packirisamy",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 584,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rahul Mulay",
    defaultValue: 583,
    tooltipData: {
      current: {
        Name: {
          value: "Rahul Mulay",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 583,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rahul Mulay",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 583,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jean jOHN",
    defaultValue: 582,
    tooltipData: {
      current: {
        Name: {
          value: "Jean jOHN",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 582,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jean jOHN",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 582,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jarafreen Barucha",
    defaultValue: 582,
    tooltipData: {
      current: {
        Name: {
          value: "Jarafreen Barucha",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 582,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jarafreen Barucha",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 582,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Akhlaque Ahmed",
    defaultValue: 582,
    tooltipData: {
      current: {
        Name: {
          value: "Akhlaque Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 582,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Akhlaque Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 582,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NARAAYAN REDDY",
    defaultValue: 580,
    tooltipData: {
      current: {
        Name: {
          value: "NARAAYAN REDDY",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 580,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NARAAYAN REDDY",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 580,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hanin Alhaj",
    defaultValue: 578,
    tooltipData: {
      current: {
        Name: {
          value: "Hanin Alhaj",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 578,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hanin Alhaj",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 578,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "farah ali",
    defaultValue: 578,
    tooltipData: {
      current: {
        Name: {
          value: "farah ali",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 578,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "farah ali",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 578,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Maryam Malik",
    defaultValue: 576,
    tooltipData: {
      current: {
        Name: {
          value: "Maryam Malik",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 576,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Maryam Malik",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 576,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "varun kumar",
    defaultValue: 575,
    tooltipData: {
      current: {
        Name: {
          value: "varun kumar",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 575,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "varun kumar",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 575,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajesh Sheth",
    defaultValue: 575,
    tooltipData: {
      current: {
        Name: {
          value: "Rajesh Sheth",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 575,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajesh Sheth",
          format: false,
        },
        TotalOrders: {
          value: 60,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 575,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "swati sharma",
    defaultValue: 575,
    tooltipData: {
      current: {
        Name: {
          value: "swati sharma",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 575,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "swati sharma",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 575,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mehnaz khoja",
    defaultValue: 574,
    tooltipData: {
      current: {
        Name: {
          value: "mehnaz khoja",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 574,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mehnaz khoja",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 574,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramesh Bala",
    defaultValue: 573,
    tooltipData: {
      current: {
        Name: {
          value: "Ramesh Bala",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 573,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramesh Bala",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 573,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nagoor Taneru",
    defaultValue: 573,
    tooltipData: {
      current: {
        Name: {
          value: "Nagoor Taneru",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 573,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nagoor Taneru",
          format: false,
        },
        TotalOrders: {
          value: 36,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 573,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dinesh Dixit",
    defaultValue: 571,
    tooltipData: {
      current: {
        Name: {
          value: "Dinesh Dixit",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 571,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dinesh Dixit",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 571,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jyoti Talwar",
    defaultValue: 570,
    tooltipData: {
      current: {
        Name: {
          value: "Jyoti Talwar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 570,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jyoti Talwar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 570,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinai Gali",
    defaultValue: 567,
    tooltipData: {
      current: {
        Name: {
          value: "Vinai Gali",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 567,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinai Gali",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 567,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arjun reddy",
    defaultValue: 564,
    tooltipData: {
      current: {
        Name: {
          value: "arjun reddy",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 564,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arjun reddy",
          format: false,
        },
        TotalOrders: {
          value: 45,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 564,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vinod nair",
    defaultValue: 564,
    tooltipData: {
      current: {
        Name: {
          value: "vinod nair",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 564,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vinod nair",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 564,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Parachi Deshpande",
    defaultValue: 562,
    tooltipData: {
      current: {
        Name: {
          value: "Parachi Deshpande",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 562,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Parachi Deshpande",
          format: false,
        },
        TotalOrders: {
          value: 37,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 562,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sunny jivanee",
    defaultValue: 561,
    tooltipData: {
      current: {
        Name: {
          value: "sunny jivanee",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 561,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sunny jivanee",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 561,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arun arya",
    defaultValue: 559,
    tooltipData: {
      current: {
        Name: {
          value: "arun arya",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 559,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arun arya",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 559,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bindu conikee",
    defaultValue: 558,
    tooltipData: {
      current: {
        Name: {
          value: "bindu conikee",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 558,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bindu conikee",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 558,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nagesh pawar",
    defaultValue: 557,
    tooltipData: {
      current: {
        Name: {
          value: "nagesh pawar",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 557,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nagesh pawar",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 557,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jaspreet Kaur",
    defaultValue: 557,
    tooltipData: {
      current: {
        Name: {
          value: "Jaspreet Kaur",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 557,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jaspreet Kaur",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 557,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suresh Madaan",
    defaultValue: 556,
    tooltipData: {
      current: {
        Name: {
          value: "Suresh Madaan",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 556,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suresh Madaan",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 556,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priyanka Sarkar",
    defaultValue: 555,
    tooltipData: {
      current: {
        Name: {
          value: "Priyanka Sarkar",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 555,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priyanka Sarkar",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 555,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jawad Khalid",
    defaultValue: 554,
    tooltipData: {
      current: {
        Name: {
          value: "Jawad Khalid",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 554,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jawad Khalid",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 554,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "K P",
    defaultValue: 552,
    tooltipData: {
      current: {
        Name: {
          value: "K P",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 552,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "K P",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 552,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "samra rana",
    defaultValue: 551,
    tooltipData: {
      current: {
        Name: {
          value: "samra rana",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 551,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "samra rana",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 551,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahesh M",
    defaultValue: 549,
    tooltipData: {
      current: {
        Name: {
          value: "Mahesh M",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 549,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahesh M",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 549,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anushree Bawane",
    defaultValue: 547,
    tooltipData: {
      current: {
        Name: {
          value: "Anushree Bawane",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 547,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anushree Bawane",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 547,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rubina bhamani",
    defaultValue: 547,
    tooltipData: {
      current: {
        Name: {
          value: "rubina bhamani",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 547,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rubina bhamani",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 547,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deblina Bose",
    defaultValue: 546,
    tooltipData: {
      current: {
        Name: {
          value: "Deblina Bose",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 546,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deblina Bose",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 546,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Qazi Haider",
    defaultValue: 546,
    tooltipData: {
      current: {
        Name: {
          value: "Qazi Haider",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 546,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Qazi Haider",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 546,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tuba Maab",
    defaultValue: 545,
    tooltipData: {
      current: {
        Name: {
          value: "Tuba Maab",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 545,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tuba Maab",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 545,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumeet Kohli",
    defaultValue: 545,
    tooltipData: {
      current: {
        Name: {
          value: "Sumeet Kohli",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 545,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumeet Kohli",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 545,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jerone Paulraj",
    defaultValue: 544,
    tooltipData: {
      current: {
        Name: {
          value: "Jerone Paulraj",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 544,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jerone Paulraj",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 544,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Umair Haroon",
    defaultValue: 544,
    tooltipData: {
      current: {
        Name: {
          value: "Umair Haroon",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 544,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Umair Haroon",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 544,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Firasath Mohammed",
    defaultValue: 544,
    tooltipData: {
      current: {
        Name: {
          value: "Firasath Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 544,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Firasath Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 544,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "DEEPA RAVI",
    defaultValue: 541,
    tooltipData: {
      current: {
        Name: {
          value: "DEEPA RAVI",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 541,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "DEEPA RAVI",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 541,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shiney giri",
    defaultValue: 539,
    tooltipData: {
      current: {
        Name: {
          value: "shiney giri",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 539,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shiney giri",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 539,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Revathy Bagawandoss",
    defaultValue: 539,
    tooltipData: {
      current: {
        Name: {
          value: "Revathy Bagawandoss",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 539,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Revathy Bagawandoss",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 539,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajesh Miriam",
    defaultValue: 539,
    tooltipData: {
      current: {
        Name: {
          value: "Rajesh Miriam",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 539,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajesh Miriam",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 539,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rishu jain",
    defaultValue: 538,
    tooltipData: {
      current: {
        Name: {
          value: "rishu jain",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 538,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rishu jain",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 538,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jyothi Madapu",
    defaultValue: 537,
    tooltipData: {
      current: {
        Name: {
          value: "Jyothi Madapu",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 537,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jyothi Madapu",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 537,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dalia Afifi",
    defaultValue: 537,
    tooltipData: {
      current: {
        Name: {
          value: "Dalia Afifi",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 537,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dalia Afifi",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 537,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anita Pendem",
    defaultValue: 536,
    tooltipData: {
      current: {
        Name: {
          value: "Anita Pendem",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 536,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anita Pendem",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 536,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rohida jamal",
    defaultValue: 536,
    tooltipData: {
      current: {
        Name: {
          value: "rohida jamal",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 536,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rohida jamal",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 536,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Kotaru",
    defaultValue: 535,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Kotaru",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 535,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Kotaru",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 535,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chakrapani Madhavan",
    defaultValue: 535,
    tooltipData: {
      current: {
        Name: {
          value: "Chakrapani Madhavan",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 535,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chakrapani Madhavan",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 535,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pratiba Bhandari",
    defaultValue: 535,
    tooltipData: {
      current: {
        Name: {
          value: "Pratiba Bhandari",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 535,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pratiba Bhandari",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 535,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nehal bhavsar",
    defaultValue: 535,
    tooltipData: {
      current: {
        Name: {
          value: "nehal bhavsar",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 535,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nehal bhavsar",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 535,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammad Sheikh",
    defaultValue: 534,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammad Sheikh",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 534,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammad Sheikh",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 534,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "zohia maknojia",
    defaultValue: 534,
    tooltipData: {
      current: {
        Name: {
          value: "zohia maknojia",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 534,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "zohia maknojia",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 534,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samira Raza",
    defaultValue: 532,
    tooltipData: {
      current: {
        Name: {
          value: "Samira Raza",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 532,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samira Raza",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 532,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Subika Sayed",
    defaultValue: 532,
    tooltipData: {
      current: {
        Name: {
          value: "Subika Sayed",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 532,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Subika Sayed",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 532,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "venkat ravipati",
    defaultValue: 532,
    tooltipData: {
      current: {
        Name: {
          value: "venkat ravipati",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 532,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "venkat ravipati",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 532,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lokesh Thummala",
    defaultValue: 530,
    tooltipData: {
      current: {
        Name: {
          value: "Lokesh Thummala",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 530,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lokesh Thummala",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 530,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "satya g",
    defaultValue: 529,
    tooltipData: {
      current: {
        Name: {
          value: "satya g",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 529,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "satya g",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 529,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "veena anumula",
    defaultValue: 528,
    tooltipData: {
      current: {
        Name: {
          value: "veena anumula",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 528,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "veena anumula",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 528,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amol b",
    defaultValue: 528,
    tooltipData: {
      current: {
        Name: {
          value: "Amol b",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 528,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amol b",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 528,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madiha Naryyer",
    defaultValue: 527,
    tooltipData: {
      current: {
        Name: {
          value: "Madiha Naryyer",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 527,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madiha Naryyer",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 527,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tiru B",
    defaultValue: 527,
    tooltipData: {
      current: {
        Name: {
          value: "Tiru B",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 527,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tiru B",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 527,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Azam Siddiq",
    defaultValue: 526,
    tooltipData: {
      current: {
        Name: {
          value: "Azam Siddiq",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 526,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Azam Siddiq",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 526,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tajinder dhillon",
    defaultValue: 526,
    tooltipData: {
      current: {
        Name: {
          value: "tajinder dhillon",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 526,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tajinder dhillon",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 526,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abhishek Lalwani",
    defaultValue: 525,
    tooltipData: {
      current: {
        Name: {
          value: "Abhishek Lalwani",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 525,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abhishek Lalwani",
          format: false,
        },
        TotalOrders: {
          value: 43,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 525,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kamran Ahmed",
    defaultValue: 524,
    tooltipData: {
      current: {
        Name: {
          value: "Kamran Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 524,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kamran Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 524,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sandeep Pratap",
    defaultValue: 524,
    tooltipData: {
      current: {
        Name: {
          value: "Sandeep Pratap",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 524,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sandeep Pratap",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 524,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swapna Thumma",
    defaultValue: 524,
    tooltipData: {
      current: {
        Name: {
          value: "Swapna Thumma",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 524,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swapna Thumma",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 524,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rehana ali",
    defaultValue: 524,
    tooltipData: {
      current: {
        Name: {
          value: "rehana ali",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 524,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rehana ali",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 524,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aineni Karnakar",
    defaultValue: 523,
    tooltipData: {
      current: {
        Name: {
          value: "Aineni Karnakar",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 523,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aineni Karnakar",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 523,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sridhar Goona",
    defaultValue: 523,
    tooltipData: {
      current: {
        Name: {
          value: "Sridhar Goona",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 523,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sridhar Goona",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 523,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bindu Bandaru",
    defaultValue: 521,
    tooltipData: {
      current: {
        Name: {
          value: "Bindu Bandaru",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 521,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bindu Bandaru",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 521,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sonam chopra",
    defaultValue: 520,
    tooltipData: {
      current: {
        Name: {
          value: "sonam chopra",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 520,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sonam chopra",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 520,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rahul Malik",
    defaultValue: 520,
    tooltipData: {
      current: {
        Name: {
          value: "Rahul Malik",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 520,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rahul Malik",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 520,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nida ali",
    defaultValue: 520,
    tooltipData: {
      current: {
        Name: {
          value: "nida ali",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 520,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nida ali",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 520,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sneha joshi",
    defaultValue: 518,
    tooltipData: {
      current: {
        Name: {
          value: "sneha joshi",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 518,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sneha joshi",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 518,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vineet joshi",
    defaultValue: 517,
    tooltipData: {
      current: {
        Name: {
          value: "vineet joshi",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 517,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vineet joshi",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 517,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rida Hasan",
    defaultValue: 517,
    tooltipData: {
      current: {
        Name: {
          value: "Rida Hasan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 517,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rida Hasan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 517,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Annie Saleem",
    defaultValue: 516,
    tooltipData: {
      current: {
        Name: {
          value: "Annie Saleem",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 516,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Annie Saleem",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 516,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anil Muvvala",
    defaultValue: 516,
    tooltipData: {
      current: {
        Name: {
          value: "Anil Muvvala",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 516,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anil Muvvala",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 516,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shafaq khan",
    defaultValue: 516,
    tooltipData: {
      current: {
        Name: {
          value: "shafaq khan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 516,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shafaq khan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 516,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "carlos pleitez",
    defaultValue: 516,
    tooltipData: {
      current: {
        Name: {
          value: "carlos pleitez",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 516,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "carlos pleitez",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 516,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Niranjan Aryal",
    defaultValue: 515,
    tooltipData: {
      current: {
        Name: {
          value: "Niranjan Aryal",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 515,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Niranjan Aryal",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 515,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swati Malla",
    defaultValue: 515,
    tooltipData: {
      current: {
        Name: {
          value: "Swati Malla",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 515,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swati Malla",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 515,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kranthi Gunda",
    defaultValue: 514,
    tooltipData: {
      current: {
        Name: {
          value: "Kranthi Gunda",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 514,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kranthi Gunda",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 514,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sanjeev pilla",
    defaultValue: 514,
    tooltipData: {
      current: {
        Name: {
          value: "sanjeev pilla",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 514,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sanjeev pilla",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 514,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arshad Ali",
    defaultValue: 512,
    tooltipData: {
      current: {
        Name: {
          value: "Arshad Ali",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 512,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arshad Ali",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 512,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sabih Siddiqui",
    defaultValue: 512,
    tooltipData: {
      current: {
        Name: {
          value: "Sabih Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 512,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sabih Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 512,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "smita saroj",
    defaultValue: 511,
    tooltipData: {
      current: {
        Name: {
          value: "smita saroj",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 511,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "smita saroj",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 511,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepa Ahuja",
    defaultValue: 510,
    tooltipData: {
      current: {
        Name: {
          value: "Deepa Ahuja",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 510,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepa Ahuja",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 510,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "geetha gade",
    defaultValue: 510,
    tooltipData: {
      current: {
        Name: {
          value: "geetha gade",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 510,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "geetha gade",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 510,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "AHSAN PERVAIZ",
    defaultValue: 510,
    tooltipData: {
      current: {
        Name: {
          value: "AHSAN PERVAIZ",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 510,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "AHSAN PERVAIZ",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 510,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zeb Malik",
    defaultValue: 509,
    tooltipData: {
      current: {
        Name: {
          value: "Zeb Malik",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 509,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zeb Malik",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 509,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sahastej perkit",
    defaultValue: 509,
    tooltipData: {
      current: {
        Name: {
          value: "Sahastej perkit",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 509,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sahastej perkit",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 509,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Erica Brito",
    defaultValue: 508,
    tooltipData: {
      current: {
        Name: {
          value: "Erica Brito",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 508,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Erica Brito",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 508,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "noveen osama",
    defaultValue: 508,
    tooltipData: {
      current: {
        Name: {
          value: "noveen osama",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 508,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "noveen osama",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 508,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shahezad shaikh",
    defaultValue: 508,
    tooltipData: {
      current: {
        Name: {
          value: "shahezad shaikh",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 508,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shahezad shaikh",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 508,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arun Bijjali",
    defaultValue: 507,
    tooltipData: {
      current: {
        Name: {
          value: "Arun Bijjali",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 507,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arun Bijjali",
          format: false,
        },
        TotalOrders: {
          value: 42,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 507,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sriranjan Reddy",
    defaultValue: 507,
    tooltipData: {
      current: {
        Name: {
          value: "Sriranjan Reddy",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 507,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sriranjan Reddy",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 507,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nadia Noorzad",
    defaultValue: 507,
    tooltipData: {
      current: {
        Name: {
          value: "Nadia Noorzad",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 507,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nadia Noorzad",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 507,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nimalika Wellawa",
    defaultValue: 506,
    tooltipData: {
      current: {
        Name: {
          value: "Nimalika Wellawa",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 506,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nimalika Wellawa",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 506,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sawan shah",
    defaultValue: 505,
    tooltipData: {
      current: {
        Name: {
          value: "sawan shah",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 505,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sawan shah",
          format: false,
        },
        TotalOrders: {
          value: 34,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 505,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rashna vajifdai",
    defaultValue: 505,
    tooltipData: {
      current: {
        Name: {
          value: "Rashna vajifdai",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 505,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rashna vajifdai",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 505,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jayshree Ruje",
    defaultValue: 505,
    tooltipData: {
      current: {
        Name: {
          value: "Jayshree Ruje",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 505,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jayshree Ruje",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 505,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "karthik R",
    defaultValue: 503,
    tooltipData: {
      current: {
        Name: {
          value: "karthik R",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 503,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "karthik R",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 503,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raja Chakravarthy",
    defaultValue: 503,
    tooltipData: {
      current: {
        Name: {
          value: "Raja Chakravarthy",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 503,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raja Chakravarthy",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 503,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "chaitali basu",
    defaultValue: 502,
    tooltipData: {
      current: {
        Name: {
          value: "chaitali basu",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 502,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "chaitali basu",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 502,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vamsi Atluri",
    defaultValue: 502,
    tooltipData: {
      current: {
        Name: {
          value: "Vamsi Atluri",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 502,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vamsi Atluri",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 502,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumanth Tella",
    defaultValue: 500,
    tooltipData: {
      current: {
        Name: {
          value: "Sumanth Tella",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 500,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumanth Tella",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 500,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nagarajan sekar",
    defaultValue: 500,
    tooltipData: {
      current: {
        Name: {
          value: "nagarajan sekar",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 500,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nagarajan sekar",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 500,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shah Mohamad",
    defaultValue: 499,
    tooltipData: {
      current: {
        Name: {
          value: "Shah Mohamad",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 499,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shah Mohamad",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 499,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "poornima mathi",
    defaultValue: 499,
    tooltipData: {
      current: {
        Name: {
          value: "poornima mathi",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 499,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "poornima mathi",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 499,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Resmi Mathew",
    defaultValue: 499,
    tooltipData: {
      current: {
        Name: {
          value: "Resmi Mathew",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 499,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Resmi Mathew",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 499,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "cindy singh",
    defaultValue: 498,
    tooltipData: {
      current: {
        Name: {
          value: "cindy singh",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 498,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "cindy singh",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 498,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shanker ayyar",
    defaultValue: 498,
    tooltipData: {
      current: {
        Name: {
          value: "shanker ayyar",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 498,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shanker ayyar",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 498,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raj bhaumik",
    defaultValue: 497,
    tooltipData: {
      current: {
        Name: {
          value: "raj bhaumik",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 497,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raj bhaumik",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 497,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Khalid Alabdulla",
    defaultValue: 497,
    tooltipData: {
      current: {
        Name: {
          value: "Khalid Alabdulla",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 497,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Khalid Alabdulla",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 497,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dev alamengada",
    defaultValue: 497,
    tooltipData: {
      current: {
        Name: {
          value: "dev alamengada",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 497,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dev alamengada",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 497,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Najji Fatima",
    defaultValue: 496,
    tooltipData: {
      current: {
        Name: {
          value: "Najji Fatima",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 496,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Najji Fatima",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 496,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sadia emran",
    defaultValue: 494,
    tooltipData: {
      current: {
        Name: {
          value: "sadia emran",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 494,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sadia emran",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 494,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "meena arun",
    defaultValue: 494,
    tooltipData: {
      current: {
        Name: {
          value: "meena arun",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 494,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "meena arun",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 494,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syeda Fatima",
    defaultValue: 493,
    tooltipData: {
      current: {
        Name: {
          value: "Syeda Fatima",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 493,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syeda Fatima",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 493,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Senthil Sainath",
    defaultValue: 493,
    tooltipData: {
      current: {
        Name: {
          value: "Senthil Sainath",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 493,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Senthil Sainath",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 493,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "KSHITIJA PANSARE",
    defaultValue: 492,
    tooltipData: {
      current: {
        Name: {
          value: "KSHITIJA PANSARE",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 492,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "KSHITIJA PANSARE",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 492,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arun K",
    defaultValue: 492,
    tooltipData: {
      current: {
        Name: {
          value: "Arun K",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 492,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arun K",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 492,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fatima Mussa",
    defaultValue: 488,
    tooltipData: {
      current: {
        Name: {
          value: "Fatima Mussa",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 488,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fatima Mussa",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 488,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sneha Juttu",
    defaultValue: 486,
    tooltipData: {
      current: {
        Name: {
          value: "Sneha Juttu",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 486,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sneha Juttu",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 486,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aman Deep",
    defaultValue: 486,
    tooltipData: {
      current: {
        Name: {
          value: "Aman Deep",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 486,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aman Deep",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 486,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sakina Saleem",
    defaultValue: 485,
    tooltipData: {
      current: {
        Name: {
          value: "Sakina Saleem",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 485,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sakina Saleem",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 485,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gayatri pampangowgari",
    defaultValue: 485,
    tooltipData: {
      current: {
        Name: {
          value: "Gayatri pampangowgari",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 485,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gayatri pampangowgari",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 485,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "STEPHANIE BENRAHMOUNE",
    defaultValue: 484,
    tooltipData: {
      current: {
        Name: {
          value: "STEPHANIE BENRAHMOUNE",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 484,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "STEPHANIE BENRAHMOUNE",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 484,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Avinash Korwarkar",
    defaultValue: 483,
    tooltipData: {
      current: {
        Name: {
          value: "Avinash Korwarkar",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 483,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Avinash Korwarkar",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 483,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raghavendra Dasari",
    defaultValue: 483,
    tooltipData: {
      current: {
        Name: {
          value: "Raghavendra Dasari",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 483,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raghavendra Dasari",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 483,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhagyesh Amin",
    defaultValue: 482,
    tooltipData: {
      current: {
        Name: {
          value: "Bhagyesh Amin",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 482,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhagyesh Amin",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 482,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mitul Kundalia",
    defaultValue: 482,
    tooltipData: {
      current: {
        Name: {
          value: "Mitul Kundalia",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 482,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mitul Kundalia",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 482,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jyoti bandikalla",
    defaultValue: 482,
    tooltipData: {
      current: {
        Name: {
          value: "jyoti bandikalla",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 482,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jyoti bandikalla",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 482,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sharath M",
    defaultValue: 481,
    tooltipData: {
      current: {
        Name: {
          value: "Sharath M",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 481,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sharath M",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 481,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deenu kanjickal",
    defaultValue: 480,
    tooltipData: {
      current: {
        Name: {
          value: "Deenu kanjickal",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 480,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deenu kanjickal",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 480,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shirin Khan",
    defaultValue: 479,
    tooltipData: {
      current: {
        Name: {
          value: "Shirin Khan",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 479,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shirin Khan",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 479,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Uzma Shafi",
    defaultValue: 479,
    tooltipData: {
      current: {
        Name: {
          value: "Uzma Shafi",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 479,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Uzma Shafi",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 479,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "yamini hari",
    defaultValue: 479,
    tooltipData: {
      current: {
        Name: {
          value: "yamini hari",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 479,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "yamini hari",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 479,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Abbas",
    defaultValue: 479,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Abbas",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 479,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Abbas",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 479,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Devika Puri",
    defaultValue: 478,
    tooltipData: {
      current: {
        Name: {
          value: "Devika Puri",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 478,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Devika Puri",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 478,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dinesh chanchlani",
    defaultValue: 478,
    tooltipData: {
      current: {
        Name: {
          value: "dinesh chanchlani",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 478,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dinesh chanchlani",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 478,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kalaiselvi Pandian",
    defaultValue: 477,
    tooltipData: {
      current: {
        Name: {
          value: "Kalaiselvi Pandian",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 477,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kalaiselvi Pandian",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 477,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amina Syeda",
    defaultValue: 477,
    tooltipData: {
      current: {
        Name: {
          value: "Amina Syeda",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 477,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amina Syeda",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 477,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramesh Tentu",
    defaultValue: 475,
    tooltipData: {
      current: {
        Name: {
          value: "Ramesh Tentu",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 475,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramesh Tentu",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 475,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "srinivas kurupathi",
    defaultValue: 474,
    tooltipData: {
      current: {
        Name: {
          value: "srinivas kurupathi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 474,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "srinivas kurupathi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 474,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashar Siddique",
    defaultValue: 474,
    tooltipData: {
      current: {
        Name: {
          value: "Ashar Siddique",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 474,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashar Siddique",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 474,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arun Jo",
    defaultValue: 474,
    tooltipData: {
      current: {
        Name: {
          value: "Arun Jo",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 474,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arun Jo",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 474,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Idimadakala",
    defaultValue: 473,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Idimadakala",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 473,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Idimadakala",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 473,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aric Chisha",
    defaultValue: 469,
    tooltipData: {
      current: {
        Name: {
          value: "Aric Chisha",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aric Chisha",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkat Ravi",
    defaultValue: 469,
    tooltipData: {
      current: {
        Name: {
          value: "Venkat Ravi",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkat Ravi",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramesh sakarepalli",
    defaultValue: 469,
    tooltipData: {
      current: {
        Name: {
          value: "Ramesh sakarepalli",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramesh sakarepalli",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rafiq Islam",
    defaultValue: 469,
    tooltipData: {
      current: {
        Name: {
          value: "Rafiq Islam",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rafiq Islam",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hiran Mehta",
    defaultValue: 469,
    tooltipData: {
      current: {
        Name: {
          value: "Hiran Mehta",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hiran Mehta",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tabinda Mumtaz",
    defaultValue: 469,
    tooltipData: {
      current: {
        Name: {
          value: "Tabinda Mumtaz",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tabinda Mumtaz",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 469,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "cherian dainel",
    defaultValue: 468,
    tooltipData: {
      current: {
        Name: {
          value: "cherian dainel",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 468,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "cherian dainel",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 468,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mustapha Elniloudi",
    defaultValue: 468,
    tooltipData: {
      current: {
        Name: {
          value: "Mustapha Elniloudi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 468,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mustapha Elniloudi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 468,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "VISWANATH AVASARALA",
    defaultValue: 466,
    tooltipData: {
      current: {
        Name: {
          value: "VISWANATH AVASARALA",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 466,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "VISWANATH AVASARALA",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 466,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pallav Srivastava",
    defaultValue: 465,
    tooltipData: {
      current: {
        Name: {
          value: "Pallav Srivastava",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 465,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pallav Srivastava",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 465,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "onaiza siddique",
    defaultValue: 465,
    tooltipData: {
      current: {
        Name: {
          value: "onaiza siddique",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 465,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "onaiza siddique",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 465,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Preet K",
    defaultValue: 463,
    tooltipData: {
      current: {
        Name: {
          value: "Preet K",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 463,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Preet K",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 463,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Helen Mack",
    defaultValue: 462,
    tooltipData: {
      current: {
        Name: {
          value: "Helen Mack",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 462,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Helen Mack",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 462,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naveeta sehgal",
    defaultValue: 462,
    tooltipData: {
      current: {
        Name: {
          value: "naveeta sehgal",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 462,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naveeta sehgal",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 462,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sana mughal",
    defaultValue: 461,
    tooltipData: {
      current: {
        Name: {
          value: "sana mughal",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 461,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sana mughal",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 461,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gouri Erode",
    defaultValue: 460,
    tooltipData: {
      current: {
        Name: {
          value: "Gouri Erode",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 460,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gouri Erode",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 460,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Ahmed",
    defaultValue: 460,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 460,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 460,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tia sahoo",
    defaultValue: 459,
    tooltipData: {
      current: {
        Name: {
          value: "tia sahoo",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 459,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tia sahoo",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 459,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "david Arulthomas",
    defaultValue: 459,
    tooltipData: {
      current: {
        Name: {
          value: "david Arulthomas",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 459,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "david Arulthomas",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 459,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neetika Bhalla",
    defaultValue: 458,
    tooltipData: {
      current: {
        Name: {
          value: "Neetika Bhalla",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 458,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neetika Bhalla",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 458,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Santosh K",
    defaultValue: 457,
    tooltipData: {
      current: {
        Name: {
          value: "Santosh K",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 457,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Santosh K",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 457,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Kumar",
    defaultValue: 457,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Kumar",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 457,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Kumar",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 457,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arun chander",
    defaultValue: 456,
    tooltipData: {
      current: {
        Name: {
          value: "arun chander",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 456,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arun chander",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 456,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "siyom narra",
    defaultValue: 456,
    tooltipData: {
      current: {
        Name: {
          value: "siyom narra",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 456,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "siyom narra",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 456,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "janith banderi",
    defaultValue: 456,
    tooltipData: {
      current: {
        Name: {
          value: "janith banderi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 456,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "janith banderi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 456,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neha Khan",
    defaultValue: 455,
    tooltipData: {
      current: {
        Name: {
          value: "Neha Khan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 455,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neha Khan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 455,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yash maddini",
    defaultValue: 455,
    tooltipData: {
      current: {
        Name: {
          value: "Yash maddini",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 455,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yash maddini",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 455,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lamak Baliuala",
    defaultValue: 455,
    tooltipData: {
      current: {
        Name: {
          value: "Lamak Baliuala",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 455,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lamak Baliuala",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 455,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Babar Pervez",
    defaultValue: 454,
    tooltipData: {
      current: {
        Name: {
          value: "Babar Pervez",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 454,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Babar Pervez",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 454,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Omar Malik",
    defaultValue: 454,
    tooltipData: {
      current: {
        Name: {
          value: "Omar Malik",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 454,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Omar Malik",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 454,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Harbans Mann",
    defaultValue: 454,
    tooltipData: {
      current: {
        Name: {
          value: "Harbans Mann",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 454,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Harbans Mann",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 454,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abhinag Naidu",
    defaultValue: 454,
    tooltipData: {
      current: {
        Name: {
          value: "Abhinag Naidu",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 454,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abhinag Naidu",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 454,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prita Shaktivel",
    defaultValue: 453,
    tooltipData: {
      current: {
        Name: {
          value: "Prita Shaktivel",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 453,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prita Shaktivel",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 453,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naga kaley",
    defaultValue: 453,
    tooltipData: {
      current: {
        Name: {
          value: "naga kaley",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 453,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naga kaley",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 453,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "upendar Reddy",
    defaultValue: 453,
    tooltipData: {
      current: {
        Name: {
          value: "upendar Reddy",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 453,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "upendar Reddy",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 453,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gayatri Rajkumar",
    defaultValue: 452,
    tooltipData: {
      current: {
        Name: {
          value: "Gayatri Rajkumar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 452,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gayatri Rajkumar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 452,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pramod Gujjar",
    defaultValue: 450,
    tooltipData: {
      current: {
        Name: {
          value: "Pramod Gujjar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 450,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pramod Gujjar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 450,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shahid Kizilbash",
    defaultValue: 449,
    tooltipData: {
      current: {
        Name: {
          value: "Shahid Kizilbash",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 449,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shahid Kizilbash",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 449,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dhanesh Sirigiri",
    defaultValue: 448,
    tooltipData: {
      current: {
        Name: {
          value: "Dhanesh Sirigiri",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 448,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dhanesh Sirigiri",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 448,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vimla Oad",
    defaultValue: 447,
    tooltipData: {
      current: {
        Name: {
          value: "Vimla Oad",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 447,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vimla Oad",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 447,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yognand Ramamurthy",
    defaultValue: 447,
    tooltipData: {
      current: {
        Name: {
          value: "Yognand Ramamurthy",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 447,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yognand Ramamurthy",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 447,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saima Hemani",
    defaultValue: 447,
    tooltipData: {
      current: {
        Name: {
          value: "Saima Hemani",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 447,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saima Hemani",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 447,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "midhun Bandreddi",
    defaultValue: 446,
    tooltipData: {
      current: {
        Name: {
          value: "midhun Bandreddi",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 446,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "midhun Bandreddi",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 446,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Najaraj",
    defaultValue: 446,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Najaraj",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 446,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Najaraj",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 446,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rana mehmood",
    defaultValue: 445,
    tooltipData: {
      current: {
        Name: {
          value: "rana mehmood",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 445,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rana mehmood",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 445,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "CHANDRA VALMIKI",
    defaultValue: 445,
    tooltipData: {
      current: {
        Name: {
          value: "CHANDRA VALMIKI",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 445,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "CHANDRA VALMIKI",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 445,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Rentala",
    defaultValue: 445,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Rentala",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 445,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Rentala",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 445,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepak Israel",
    defaultValue: 445,
    tooltipData: {
      current: {
        Name: {
          value: "Deepak Israel",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 445,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepak Israel",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 445,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shehreen Rahim",
    defaultValue: 444,
    tooltipData: {
      current: {
        Name: {
          value: "Shehreen Rahim",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 444,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shehreen Rahim",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 444,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shaheen Velani",
    defaultValue: 443,
    tooltipData: {
      current: {
        Name: {
          value: "Shaheen Velani",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 443,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shaheen Velani",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 443,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mounika Arabandi",
    defaultValue: 442,
    tooltipData: {
      current: {
        Name: {
          value: "Mounika Arabandi",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 442,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mounika Arabandi",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 442,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumit Dhingra",
    defaultValue: 441,
    tooltipData: {
      current: {
        Name: {
          value: "Sumit Dhingra",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 441,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumit Dhingra",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 441,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arti Narayan",
    defaultValue: 441,
    tooltipData: {
      current: {
        Name: {
          value: "Arti Narayan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 441,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arti Narayan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 441,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vikky suji",
    defaultValue: 440,
    tooltipData: {
      current: {
        Name: {
          value: "Vikky suji",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 440,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vikky suji",
          format: false,
        },
        TotalOrders: {
          value: 32,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 440,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sandhya pathak",
    defaultValue: 440,
    tooltipData: {
      current: {
        Name: {
          value: "sandhya pathak",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 440,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sandhya pathak",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 440,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aman Singh",
    defaultValue: 440,
    tooltipData: {
      current: {
        Name: {
          value: "Aman Singh",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 440,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aman Singh",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 440,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bawani Karunanithi",
    defaultValue: 440,
    tooltipData: {
      current: {
        Name: {
          value: "Bawani Karunanithi",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 440,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bawani Karunanithi",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 440,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neeraja zavvala",
    defaultValue: 439,
    tooltipData: {
      current: {
        Name: {
          value: "neeraja zavvala",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 439,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neeraja zavvala",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 439,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rahul Chaudhari",
    defaultValue: 439,
    tooltipData: {
      current: {
        Name: {
          value: "Rahul Chaudhari",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 439,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rahul Chaudhari",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 439,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sarkar s",
    defaultValue: 439,
    tooltipData: {
      current: {
        Name: {
          value: "Sarkar s",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 439,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sarkar s",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 439,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sam Vasu",
    defaultValue: 439,
    tooltipData: {
      current: {
        Name: {
          value: "Sam Vasu",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 439,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sam Vasu",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 439,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kunal Khatri",
    defaultValue: 437,
    tooltipData: {
      current: {
        Name: {
          value: "Kunal Khatri",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 437,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kunal Khatri",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 437,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fahad Khaliq",
    defaultValue: 434,
    tooltipData: {
      current: {
        Name: {
          value: "Fahad Khaliq",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 434,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fahad Khaliq",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 434,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ameena bhaidani",
    defaultValue: 434,
    tooltipData: {
      current: {
        Name: {
          value: "Ameena bhaidani",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 434,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ameena bhaidani",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 434,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Soumya Bhat",
    defaultValue: 433,
    tooltipData: {
      current: {
        Name: {
          value: "Soumya Bhat",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 433,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Soumya Bhat",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 433,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Santosh Veettil",
    defaultValue: 433,
    tooltipData: {
      current: {
        Name: {
          value: "Santosh Veettil",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 433,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Santosh Veettil",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 433,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunil Pallemoni",
    defaultValue: 432,
    tooltipData: {
      current: {
        Name: {
          value: "Sunil Pallemoni",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 432,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunil Pallemoni",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 432,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nooreshaa biabani",
    defaultValue: 432,
    tooltipData: {
      current: {
        Name: {
          value: "nooreshaa biabani",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 432,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nooreshaa biabani",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 432,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "m bose",
    defaultValue: 432,
    tooltipData: {
      current: {
        Name: {
          value: "m bose",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 432,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "m bose",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 432,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Atif Zaidi",
    defaultValue: 430,
    tooltipData: {
      current: {
        Name: {
          value: "Atif Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 430,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Atif Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 430,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SHARAD ANAND",
    defaultValue: 429,
    tooltipData: {
      current: {
        Name: {
          value: "SHARAD ANAND",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 429,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SHARAD ANAND",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 429,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sabri CV",
    defaultValue: 428,
    tooltipData: {
      current: {
        Name: {
          value: "Sabri CV",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 428,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sabri CV",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 428,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nadia Muzammil",
    defaultValue: 428,
    tooltipData: {
      current: {
        Name: {
          value: "Nadia Muzammil",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 428,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nadia Muzammil",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 428,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aparna Arora",
    defaultValue: 427,
    tooltipData: {
      current: {
        Name: {
          value: "Aparna Arora",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 427,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aparna Arora",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 427,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kalpna rout",
    defaultValue: 427,
    tooltipData: {
      current: {
        Name: {
          value: "kalpna rout",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 427,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kalpna rout",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 427,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Padmaja Karney",
    defaultValue: 427,
    tooltipData: {
      current: {
        Name: {
          value: "Padmaja Karney",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 427,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Padmaja Karney",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 427,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hemant Rao",
    defaultValue: 427,
    tooltipData: {
      current: {
        Name: {
          value: "Hemant Rao",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 427,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hemant Rao",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 427,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sarah Siddiqui",
    defaultValue: 426,
    tooltipData: {
      current: {
        Name: {
          value: "Sarah Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 426,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sarah Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 426,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinay Padala",
    defaultValue: 425,
    tooltipData: {
      current: {
        Name: {
          value: "Vinay Padala",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 425,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinay Padala",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 425,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mona Aggarwal",
    defaultValue: 425,
    tooltipData: {
      current: {
        Name: {
          value: "Mona Aggarwal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 425,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mona Aggarwal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 425,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gurpreet Bhusrr",
    defaultValue: 424,
    tooltipData: {
      current: {
        Name: {
          value: "Gurpreet Bhusrr",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 424,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gurpreet Bhusrr",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 424,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hina sarfraz",
    defaultValue: 424,
    tooltipData: {
      current: {
        Name: {
          value: "hina sarfraz",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 424,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hina sarfraz",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 424,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rubina Sarah",
    defaultValue: 424,
    tooltipData: {
      current: {
        Name: {
          value: "Rubina Sarah",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 424,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rubina Sarah",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 424,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sheetal Laluani",
    defaultValue: 424,
    tooltipData: {
      current: {
        Name: {
          value: "Sheetal Laluani",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 424,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sheetal Laluani",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 424,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rushi Sayed",
    defaultValue: 423,
    tooltipData: {
      current: {
        Name: {
          value: "Rushi Sayed",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 423,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rushi Sayed",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 423,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sijo jose",
    defaultValue: 421,
    tooltipData: {
      current: {
        Name: {
          value: "sijo jose",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sijo jose",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nasir Wasim",
    defaultValue: 421,
    tooltipData: {
      current: {
        Name: {
          value: "Nasir Wasim",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nasir Wasim",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ritu Turi",
    defaultValue: 421,
    tooltipData: {
      current: {
        Name: {
          value: "Ritu Turi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ritu Turi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kavya Gad",
    defaultValue: 421,
    tooltipData: {
      current: {
        Name: {
          value: "Kavya Gad",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kavya Gad",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "usha devadas",
    defaultValue: 421,
    tooltipData: {
      current: {
        Name: {
          value: "usha devadas",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "usha devadas",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 421,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Irani Hazra",
    defaultValue: 420,
    tooltipData: {
      current: {
        Name: {
          value: "Irani Hazra",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 420,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Irani Hazra",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 420,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ali wasif",
    defaultValue: 419,
    tooltipData: {
      current: {
        Name: {
          value: "ali wasif",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 419,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ali wasif",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 419,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pavan Gangavaram",
    defaultValue: 419,
    tooltipData: {
      current: {
        Name: {
          value: "Pavan Gangavaram",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 419,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pavan Gangavaram",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 419,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "zahir noorani",
    defaultValue: 418,
    tooltipData: {
      current: {
        Name: {
          value: "zahir noorani",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 418,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "zahir noorani",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 418,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srinivas Pattela",
    defaultValue: 418,
    tooltipData: {
      current: {
        Name: {
          value: "Srinivas Pattela",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 418,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srinivas Pattela",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 418,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nikita Narang",
    defaultValue: 417,
    tooltipData: {
      current: {
        Name: {
          value: "Nikita Narang",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 417,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nikita Narang",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 417,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anupam Jaiswal",
    defaultValue: 416,
    tooltipData: {
      current: {
        Name: {
          value: "Anupam Jaiswal",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 416,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anupam Jaiswal",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 416,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hayder cheema",
    defaultValue: 415,
    tooltipData: {
      current: {
        Name: {
          value: "hayder cheema",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 415,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hayder cheema",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 415,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abhishek Verma",
    defaultValue: 414,
    tooltipData: {
      current: {
        Name: {
          value: "Abhishek Verma",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 414,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abhishek Verma",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 414,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SHAMAILA IMAM",
    defaultValue: 414,
    tooltipData: {
      current: {
        Name: {
          value: "SHAMAILA IMAM",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 414,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SHAMAILA IMAM",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 414,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shali Shetty",
    defaultValue: 412,
    tooltipData: {
      current: {
        Name: {
          value: "Shali Shetty",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 412,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shali Shetty",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 412,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhu KR",
    defaultValue: 410,
    tooltipData: {
      current: {
        Name: {
          value: "Madhu KR",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 410,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhu KR",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 410,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nav Tala",
    defaultValue: 409,
    tooltipData: {
      current: {
        Name: {
          value: "Nav Tala",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 409,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nav Tala",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 409,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prakash Lingidi",
    defaultValue: 409,
    tooltipData: {
      current: {
        Name: {
          value: "Prakash Lingidi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 409,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prakash Lingidi",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 409,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Uma Bugatha",
    defaultValue: 409,
    tooltipData: {
      current: {
        Name: {
          value: "Uma Bugatha",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 409,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Uma Bugatha",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 409,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "satish jasti",
    defaultValue: 408,
    tooltipData: {
      current: {
        Name: {
          value: "satish jasti",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 408,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "satish jasti",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 408,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pooja shrestha",
    defaultValue: 407,
    tooltipData: {
      current: {
        Name: {
          value: "pooja shrestha",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 407,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pooja shrestha",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 407,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shams imam",
    defaultValue: 407,
    tooltipData: {
      current: {
        Name: {
          value: "shams imam",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 407,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shams imam",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 407,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Maqdum Mohammad",
    defaultValue: 407,
    tooltipData: {
      current: {
        Name: {
          value: "Maqdum Mohammad",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 407,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Maqdum Mohammad",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 407,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dharma gattupalli",
    defaultValue: 407,
    tooltipData: {
      current: {
        Name: {
          value: "dharma gattupalli",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 407,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dharma gattupalli",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 407,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ann f",
    defaultValue: 406,
    tooltipData: {
      current: {
        Name: {
          value: "ann f",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 406,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ann f",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 406,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohan Pati",
    defaultValue: 405,
    tooltipData: {
      current: {
        Name: {
          value: "Mohan Pati",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 405,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohan Pati",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 405,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bharat K",
    defaultValue: 405,
    tooltipData: {
      current: {
        Name: {
          value: "Bharat K",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 405,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bharat K",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 405,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nafisa Momin",
    defaultValue: 403,
    tooltipData: {
      current: {
        Name: {
          value: "Nafisa Momin",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 403,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nafisa Momin",
          format: false,
        },
        TotalOrders: {
          value: 31,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 403,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohammad jalal",
    defaultValue: 403,
    tooltipData: {
      current: {
        Name: {
          value: "mohammad jalal",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 403,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohammad jalal",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 403,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "giri Shan",
    defaultValue: 402,
    tooltipData: {
      current: {
        Name: {
          value: "giri Shan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 402,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "giri Shan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 402,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samea Azer",
    defaultValue: 401,
    tooltipData: {
      current: {
        Name: {
          value: "Samea Azer",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 401,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samea Azer",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 401,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shermila g",
    defaultValue: 401,
    tooltipData: {
      current: {
        Name: {
          value: "Shermila g",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 401,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shermila g",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 401,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashok Mandula",
    defaultValue: 400,
    tooltipData: {
      current: {
        Name: {
          value: "Ashok Mandula",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 400,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashok Mandula",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 400,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sonali kale",
    defaultValue: 400,
    tooltipData: {
      current: {
        Name: {
          value: "sonali kale",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 400,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sonali kale",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 400,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arif shaikh",
    defaultValue: 399,
    tooltipData: {
      current: {
        Name: {
          value: "arif shaikh",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 399,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arif shaikh",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 399,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amit Gupta",
    defaultValue: 398,
    tooltipData: {
      current: {
        Name: {
          value: "Amit Gupta",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 398,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amit Gupta",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 398,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anisa desai",
    defaultValue: 398,
    tooltipData: {
      current: {
        Name: {
          value: "anisa desai",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 398,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anisa desai",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 398,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Avneet Chopra",
    defaultValue: 398,
    tooltipData: {
      current: {
        Name: {
          value: "Avneet Chopra",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 398,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Avneet Chopra",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 398,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinod Gujja",
    defaultValue: 398,
    tooltipData: {
      current: {
        Name: {
          value: "Vinod Gujja",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 398,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinod Gujja",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 398,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "forouzan zantefili",
    defaultValue: 396,
    tooltipData: {
      current: {
        Name: {
          value: "forouzan zantefili",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 396,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "forouzan zantefili",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 396,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lakshmi pasunuri",
    defaultValue: 396,
    tooltipData: {
      current: {
        Name: {
          value: "lakshmi pasunuri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 396,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lakshmi pasunuri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 396,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rohini Achanta",
    defaultValue: 396,
    tooltipData: {
      current: {
        Name: {
          value: "Rohini Achanta",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 396,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rohini Achanta",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 396,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "praveen aaviti",
    defaultValue: 396,
    tooltipData: {
      current: {
        Name: {
          value: "praveen aaviti",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 396,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "praveen aaviti",
          format: false,
        },
        TotalOrders: {
          value: 30,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 396,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fiaza ismail",
    defaultValue: 395,
    tooltipData: {
      current: {
        Name: {
          value: "fiaza ismail",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fiaza ismail",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nandita raja",
    defaultValue: 395,
    tooltipData: {
      current: {
        Name: {
          value: "nandita raja",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nandita raja",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gaythri Arasaraju",
    defaultValue: 395,
    tooltipData: {
      current: {
        Name: {
          value: "Gaythri Arasaraju",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gaythri Arasaraju",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Preeti Lotey",
    defaultValue: 395,
    tooltipData: {
      current: {
        Name: {
          value: "Preeti Lotey",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Preeti Lotey",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SATISH KUMAR",
    defaultValue: 395,
    tooltipData: {
      current: {
        Name: {
          value: "SATISH KUMAR",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SATISH KUMAR",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 395,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Raja",
    defaultValue: 394,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Raja",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 394,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Raja",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 394,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sailaja Patibandla",
    defaultValue: 394,
    tooltipData: {
      current: {
        Name: {
          value: "Sailaja Patibandla",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 394,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sailaja Patibandla",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 394,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Soma Gadde",
    defaultValue: 394,
    tooltipData: {
      current: {
        Name: {
          value: "Soma Gadde",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 394,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Soma Gadde",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 394,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ram Kaimal",
    defaultValue: 393,
    tooltipData: {
      current: {
        Name: {
          value: "Ram Kaimal",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 393,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ram Kaimal",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 393,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dris hachem",
    defaultValue: 393,
    tooltipData: {
      current: {
        Name: {
          value: "dris hachem",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 393,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dris hachem",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 393,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramana Nandikolla",
    defaultValue: 393,
    tooltipData: {
      current: {
        Name: {
          value: "Ramana Nandikolla",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 393,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramana Nandikolla",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 393,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "venkat bonan",
    defaultValue: 392,
    tooltipData: {
      current: {
        Name: {
          value: "venkat bonan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 392,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "venkat bonan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 392,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumathi Shankar",
    defaultValue: 391,
    tooltipData: {
      current: {
        Name: {
          value: "Sumathi Shankar",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 391,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumathi Shankar",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 391,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jyosna Vekhande",
    defaultValue: 391,
    tooltipData: {
      current: {
        Name: {
          value: "Jyosna Vekhande",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 391,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jyosna Vekhande",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 391,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Satyam Kala",
    defaultValue: 389,
    tooltipData: {
      current: {
        Name: {
          value: "Satyam Kala",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 389,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Satyam Kala",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 389,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saba shaih",
    defaultValue: 389,
    tooltipData: {
      current: {
        Name: {
          value: "saba shaih",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 389,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saba shaih",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 389,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Afnan Khokhar",
    defaultValue: 389,
    tooltipData: {
      current: {
        Name: {
          value: "Afnan Khokhar",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 389,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Afnan Khokhar",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 389,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Karthi D",
    defaultValue: 388,
    tooltipData: {
      current: {
        Name: {
          value: "Karthi D",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 388,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Karthi D",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 388,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srikanth Addepalli",
    defaultValue: 388,
    tooltipData: {
      current: {
        Name: {
          value: "Srikanth Addepalli",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 388,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srikanth Addepalli",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 388,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manish Varshney",
    defaultValue: 387,
    tooltipData: {
      current: {
        Name: {
          value: "Manish Varshney",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manish Varshney",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhavi Athelli",
    defaultValue: 387,
    tooltipData: {
      current: {
        Name: {
          value: "Madhavi Athelli",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhavi Athelli",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nehal Bhasvar",
    defaultValue: 387,
    tooltipData: {
      current: {
        Name: {
          value: "Nehal Bhasvar",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nehal Bhasvar",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pradeep Potti",
    defaultValue: 387,
    tooltipData: {
      current: {
        Name: {
          value: "Pradeep Potti",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pradeep Potti",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rakesh kumar",
    defaultValue: 387,
    tooltipData: {
      current: {
        Name: {
          value: "rakesh kumar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rakesh kumar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 387,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yesha Rangunwala",
    defaultValue: 386,
    tooltipData: {
      current: {
        Name: {
          value: "Yesha Rangunwala",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 386,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yesha Rangunwala",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 386,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sankeerth Kommula",
    defaultValue: 385,
    tooltipData: {
      current: {
        Name: {
          value: "Sankeerth Kommula",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 385,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sankeerth Kommula",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 385,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yogesh Padman",
    defaultValue: 385,
    tooltipData: {
      current: {
        Name: {
          value: "Yogesh Padman",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 385,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yogesh Padman",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 385,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Satish Adapa",
    defaultValue: 385,
    tooltipData: {
      current: {
        Name: {
          value: "Satish Adapa",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 385,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Satish Adapa",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 385,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ruchika Sindhu",
    defaultValue: 384,
    tooltipData: {
      current: {
        Name: {
          value: "Ruchika Sindhu",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 384,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ruchika Sindhu",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 384,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sharon Macwan",
    defaultValue: 384,
    tooltipData: {
      current: {
        Name: {
          value: "Sharon Macwan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 384,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sharon Macwan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 384,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "piyush nigam",
    defaultValue: 384,
    tooltipData: {
      current: {
        Name: {
          value: "piyush nigam",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 384,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "piyush nigam",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 384,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ahmed z",
    defaultValue: 384,
    tooltipData: {
      current: {
        Name: {
          value: "ahmed z",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 384,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ahmed z",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 384,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rakesh Sinha",
    defaultValue: 383,
    tooltipData: {
      current: {
        Name: {
          value: "Rakesh Sinha",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 383,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rakesh Sinha",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 383,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rikhi Sahay",
    defaultValue: 383,
    tooltipData: {
      current: {
        Name: {
          value: "Rikhi Sahay",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 383,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rikhi Sahay",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 383,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ruba Shaikh",
    defaultValue: 383,
    tooltipData: {
      current: {
        Name: {
          value: "Ruba Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 383,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ruba Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 383,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raksha Sing",
    defaultValue: 382,
    tooltipData: {
      current: {
        Name: {
          value: "Raksha Sing",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 382,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raksha Sing",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 382,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahesh Reddy",
    defaultValue: 382,
    tooltipData: {
      current: {
        Name: {
          value: "Mahesh Reddy",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 382,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahesh Reddy",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 382,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sonia rajani",
    defaultValue: 380,
    tooltipData: {
      current: {
        Name: {
          value: "sonia rajani",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 380,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sonia rajani",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 380,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "radhika gopalakrishna",
    defaultValue: 380,
    tooltipData: {
      current: {
        Name: {
          value: "radhika gopalakrishna",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 380,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "radhika gopalakrishna",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 380,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "umesh hiremath",
    defaultValue: 380,
    tooltipData: {
      current: {
        Name: {
          value: "umesh hiremath",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 380,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "umesh hiremath",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 380,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shahid Habib",
    defaultValue: 380,
    tooltipData: {
      current: {
        Name: {
          value: "Shahid Habib",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 380,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shahid Habib",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 380,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pavan Madugula",
    defaultValue: 379,
    tooltipData: {
      current: {
        Name: {
          value: "Pavan Madugula",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 379,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pavan Madugula",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 379,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Talukder Reza",
    defaultValue: 379,
    tooltipData: {
      current: {
        Name: {
          value: "Talukder Reza",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 379,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Talukder Reza",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 379,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sophia Guarnera",
    defaultValue: 379,
    tooltipData: {
      current: {
        Name: {
          value: "Sophia Guarnera",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 379,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sophia Guarnera",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 379,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Alam",
    defaultValue: 378,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Alam",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 378,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Alam",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 378,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nisha Khan",
    defaultValue: 377,
    tooltipData: {
      current: {
        Name: {
          value: "Nisha Khan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nisha Khan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sunny tadikonda",
    defaultValue: 377,
    tooltipData: {
      current: {
        Name: {
          value: "sunny tadikonda",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sunny tadikonda",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Diana Nair",
    defaultValue: 377,
    tooltipData: {
      current: {
        Name: {
          value: "Diana Nair",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Diana Nair",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anwar Shifaw",
    defaultValue: 377,
    tooltipData: {
      current: {
        Name: {
          value: "Anwar Shifaw",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anwar Shifaw",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rupali W",
    defaultValue: 377,
    tooltipData: {
      current: {
        Name: {
          value: "Rupali W",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rupali W",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Flora B",
    defaultValue: 377,
    tooltipData: {
      current: {
        Name: {
          value: "Flora B",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Flora B",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 377,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumanta sanyal",
    defaultValue: 375,
    tooltipData: {
      current: {
        Name: {
          value: "Sumanta sanyal",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 375,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumanta sanyal",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 375,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Faraz Mohammed",
    defaultValue: 375,
    tooltipData: {
      current: {
        Name: {
          value: "Faraz Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 375,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Faraz Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 375,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sucheta Rai",
    defaultValue: 375,
    tooltipData: {
      current: {
        Name: {
          value: "Sucheta Rai",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 375,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sucheta Rai",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 375,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Uzma Scott",
    defaultValue: 375,
    tooltipData: {
      current: {
        Name: {
          value: "Uzma Scott",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 375,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Uzma Scott",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 375,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ruby singh",
    defaultValue: 374,
    tooltipData: {
      current: {
        Name: {
          value: "ruby singh",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 374,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ruby singh",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 374,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ujwala Patil",
    defaultValue: 374,
    tooltipData: {
      current: {
        Name: {
          value: "Ujwala Patil",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 374,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ujwala Patil",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 374,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ashwiinii n",
    defaultValue: 373,
    tooltipData: {
      current: {
        Name: {
          value: "ashwiinii n",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 373,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ashwiinii n",
          format: false,
        },
        TotalOrders: {
          value: 29,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 373,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venu Kesann",
    defaultValue: 372,
    tooltipData: {
      current: {
        Name: {
          value: "Venu Kesann",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 372,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venu Kesann",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 372,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "khyati chakraborty",
    defaultValue: 372,
    tooltipData: {
      current: {
        Name: {
          value: "khyati chakraborty",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 372,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "khyati chakraborty",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 372,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "habiba sharmeen",
    defaultValue: 372,
    tooltipData: {
      current: {
        Name: {
          value: "habiba sharmeen",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 372,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "habiba sharmeen",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 372,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mina khan",
    defaultValue: 372,
    tooltipData: {
      current: {
        Name: {
          value: "mina khan",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 372,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mina khan",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 372,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manoj KC",
    defaultValue: 371,
    tooltipData: {
      current: {
        Name: {
          value: "manoj KC",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 371,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manoj KC",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 371,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Maryam Javed",
    defaultValue: 370,
    tooltipData: {
      current: {
        Name: {
          value: "Maryam Javed",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 370,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Maryam Javed",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 370,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sharon Ghorpani",
    defaultValue: 369,
    tooltipData: {
      current: {
        Name: {
          value: "Sharon Ghorpani",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 369,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sharon Ghorpani",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 369,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "loubna almatarr",
    defaultValue: 369,
    tooltipData: {
      current: {
        Name: {
          value: "loubna almatarr",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 369,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "loubna almatarr",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 369,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Javed Akhtar",
    defaultValue: 369,
    tooltipData: {
      current: {
        Name: {
          value: "Javed Akhtar",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 369,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Javed Akhtar",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 369,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "muhaammad tahir",
    defaultValue: 369,
    tooltipData: {
      current: {
        Name: {
          value: "muhaammad tahir",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 369,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "muhaammad tahir",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 369,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prasathi M",
    defaultValue: 368,
    tooltipData: {
      current: {
        Name: {
          value: "prasathi M",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 368,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prasathi M",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 368,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Usmaan Khan",
    defaultValue: 367,
    tooltipData: {
      current: {
        Name: {
          value: "Usmaan Khan",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 367,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Usmaan Khan",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 367,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "akrit kaur",
    defaultValue: 367,
    tooltipData: {
      current: {
        Name: {
          value: "akrit kaur",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 367,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "akrit kaur",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 367,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anum zohair",
    defaultValue: 366,
    tooltipData: {
      current: {
        Name: {
          value: "anum zohair",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 366,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anum zohair",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 366,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "radhika mu",
    defaultValue: 364,
    tooltipData: {
      current: {
        Name: {
          value: "radhika mu",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 364,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "radhika mu",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 364,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "deepika kaur",
    defaultValue: 364,
    tooltipData: {
      current: {
        Name: {
          value: "deepika kaur",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 364,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "deepika kaur",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 364,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ghouse Syed",
    defaultValue: 364,
    tooltipData: {
      current: {
        Name: {
          value: "Ghouse Syed",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 364,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ghouse Syed",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 364,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "john shaikh",
    defaultValue: 363,
    tooltipData: {
      current: {
        Name: {
          value: "john shaikh",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 363,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "john shaikh",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 363,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rukaiya Ranguana",
    defaultValue: 363,
    tooltipData: {
      current: {
        Name: {
          value: "Rukaiya Ranguana",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 363,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rukaiya Ranguana",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 363,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tashna samuels",
    defaultValue: 362,
    tooltipData: {
      current: {
        Name: {
          value: "tashna samuels",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tashna samuels",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jerin bejads",
    defaultValue: 362,
    tooltipData: {
      current: {
        Name: {
          value: "jerin bejads",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jerin bejads",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nilanjan Das",
    defaultValue: 362,
    tooltipData: {
      current: {
        Name: {
          value: "Nilanjan Das",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nilanjan Das",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "urmil parmar",
    defaultValue: 362,
    tooltipData: {
      current: {
        Name: {
          value: "urmil parmar",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "urmil parmar",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "umesh tasker",
    defaultValue: 362,
    tooltipData: {
      current: {
        Name: {
          value: "umesh tasker",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "umesh tasker",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 362,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shehryar Iqbal",
    defaultValue: 361,
    tooltipData: {
      current: {
        Name: {
          value: "Shehryar Iqbal",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 361,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shehryar Iqbal",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 361,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Karthik Mahadevan",
    defaultValue: 361,
    tooltipData: {
      current: {
        Name: {
          value: "Karthik Mahadevan",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 361,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Karthik Mahadevan",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 361,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nasim Hasan",
    defaultValue: 361,
    tooltipData: {
      current: {
        Name: {
          value: "Nasim Hasan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 361,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nasim Hasan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 361,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "holly talwar",
    defaultValue: 360,
    tooltipData: {
      current: {
        Name: {
          value: "holly talwar",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 360,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "holly talwar",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 360,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chhavi Verma",
    defaultValue: 360,
    tooltipData: {
      current: {
        Name: {
          value: "Chhavi Verma",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 360,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chhavi Verma",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 360,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ali waqar",
    defaultValue: 358,
    tooltipData: {
      current: {
        Name: {
          value: "ali waqar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 358,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ali waqar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 358,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dilpreet Paul",
    defaultValue: 357,
    tooltipData: {
      current: {
        Name: {
          value: "Dilpreet Paul",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 357,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dilpreet Paul",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 357,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jhanvi reddy",
    defaultValue: 357,
    tooltipData: {
      current: {
        Name: {
          value: "jhanvi reddy",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 357,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jhanvi reddy",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 357,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anitha chamarthi",
    defaultValue: 357,
    tooltipData: {
      current: {
        Name: {
          value: "anitha chamarthi",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 357,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anitha chamarthi",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 357,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bipin patel",
    defaultValue: 357,
    tooltipData: {
      current: {
        Name: {
          value: "bipin patel",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 357,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bipin patel",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 357,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Anis",
    defaultValue: 356,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Anis",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 356,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Anis",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 356,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Param Kandre",
    defaultValue: 356,
    tooltipData: {
      current: {
        Name: {
          value: "Param Kandre",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 356,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Param Kandre",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 356,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Eswar Badam",
    defaultValue: 356,
    tooltipData: {
      current: {
        Name: {
          value: "Eswar Badam",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 356,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Eswar Badam",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 356,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pavan pusuluri",
    defaultValue: 355,
    tooltipData: {
      current: {
        Name: {
          value: "pavan pusuluri",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 355,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pavan pusuluri",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 355,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepali Loney",
    defaultValue: 355,
    tooltipData: {
      current: {
        Name: {
          value: "Deepali Loney",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 355,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepali Loney",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 355,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Faheem Ismat",
    defaultValue: 354,
    tooltipData: {
      current: {
        Name: {
          value: "Faheem Ismat",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Faheem Ismat",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samiha Saif",
    defaultValue: 354,
    tooltipData: {
      current: {
        Name: {
          value: "Samiha Saif",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samiha Saif",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "savita s",
    defaultValue: 354,
    tooltipData: {
      current: {
        Name: {
          value: "savita s",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "savita s",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kat Mutale",
    defaultValue: 354,
    tooltipData: {
      current: {
        Name: {
          value: "Kat Mutale",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kat Mutale",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramesh Bala",
    defaultValue: 354,
    tooltipData: {
      current: {
        Name: {
          value: "Ramesh Bala",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramesh Bala",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tiresh Reddy",
    defaultValue: 354,
    tooltipData: {
      current: {
        Name: {
          value: "Tiresh Reddy",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tiresh Reddy",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 354,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Meher Meel",
    defaultValue: 353,
    tooltipData: {
      current: {
        Name: {
          value: "Meher Meel",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 353,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Meher Meel",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 353,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pramod pawar",
    defaultValue: 353,
    tooltipData: {
      current: {
        Name: {
          value: "pramod pawar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 353,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pramod pawar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 353,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neha shimpi",
    defaultValue: 352,
    tooltipData: {
      current: {
        Name: {
          value: "neha shimpi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 352,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neha shimpi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 352,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "george abraham",
    defaultValue: 351,
    tooltipData: {
      current: {
        Name: {
          value: "george abraham",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 351,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "george abraham",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 351,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Essam Naz",
    defaultValue: 351,
    tooltipData: {
      current: {
        Name: {
          value: "Essam Naz",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 351,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Essam Naz",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 351,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vijay Budarapu",
    defaultValue: 351,
    tooltipData: {
      current: {
        Name: {
          value: "vijay Budarapu",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 351,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vijay Budarapu",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 351,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fozia Kamran",
    defaultValue: 350,
    tooltipData: {
      current: {
        Name: {
          value: "Fozia Kamran",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 350,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fozia Kamran",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 350,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abhishek Pandey",
    defaultValue: 350,
    tooltipData: {
      current: {
        Name: {
          value: "Abhishek Pandey",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 350,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abhishek Pandey",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 350,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumit Tyagi",
    defaultValue: 350,
    tooltipData: {
      current: {
        Name: {
          value: "Sumit Tyagi",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 350,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumit Tyagi",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 350,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammed Shakeel",
    defaultValue: 350,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammed Shakeel",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 350,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammed Shakeel",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 350,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Smita Patel",
    defaultValue: 349,
    tooltipData: {
      current: {
        Name: {
          value: "Smita Patel",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 349,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Smita Patel",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 349,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rehana Ali",
    defaultValue: 349,
    tooltipData: {
      current: {
        Name: {
          value: "Rehana Ali",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 349,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rehana Ali",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 349,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suresh babu",
    defaultValue: 347,
    tooltipData: {
      current: {
        Name: {
          value: "Suresh babu",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 347,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suresh babu",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 347,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lisa Patel",
    defaultValue: 347,
    tooltipData: {
      current: {
        Name: {
          value: "Lisa Patel",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 347,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lisa Patel",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 347,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rita jain",
    defaultValue: 347,
    tooltipData: {
      current: {
        Name: {
          value: "rita jain",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 347,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rita jain",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 347,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "baljeet kumar",
    defaultValue: 346,
    tooltipData: {
      current: {
        Name: {
          value: "baljeet kumar",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 346,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "baljeet kumar",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 346,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Iram Salim",
    defaultValue: 345,
    tooltipData: {
      current: {
        Name: {
          value: "Iram Salim",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 345,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Iram Salim",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 345,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nina Eduljee",
    defaultValue: 345,
    tooltipData: {
      current: {
        Name: {
          value: "Nina Eduljee",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 345,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nina Eduljee",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 345,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Marzan Haque",
    defaultValue: 345,
    tooltipData: {
      current: {
        Name: {
          value: "Marzan Haque",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 345,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Marzan Haque",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 345,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jay Modi",
    defaultValue: 345,
    tooltipData: {
      current: {
        Name: {
          value: "Jay Modi",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 345,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jay Modi",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 345,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suraj Belani",
    defaultValue: 344,
    tooltipData: {
      current: {
        Name: {
          value: "Suraj Belani",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 344,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suraj Belani",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 344,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hemangi Shah",
    defaultValue: 344,
    tooltipData: {
      current: {
        Name: {
          value: "Hemangi Shah",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 344,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hemangi Shah",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 344,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohammad ali",
    defaultValue: 343,
    tooltipData: {
      current: {
        Name: {
          value: "mohammad ali",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 343,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohammad ali",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 343,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nishesh parikh",
    defaultValue: 342,
    tooltipData: {
      current: {
        Name: {
          value: "nishesh parikh",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 342,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nishesh parikh",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 342,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Joseph Prashant",
    defaultValue: 342,
    tooltipData: {
      current: {
        Name: {
          value: "Joseph Prashant",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 342,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Joseph Prashant",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 342,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hina Aamir",
    defaultValue: 342,
    tooltipData: {
      current: {
        Name: {
          value: "Hina Aamir",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 342,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hina Aamir",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 342,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jaya Yenduri",
    defaultValue: 342,
    tooltipData: {
      current: {
        Name: {
          value: "Jaya Yenduri",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 342,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jaya Yenduri",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 342,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bala ji",
    defaultValue: 341,
    tooltipData: {
      current: {
        Name: {
          value: "bala ji",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 341,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bala ji",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 341,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dEEPIKA JANGA",
    defaultValue: 340,
    tooltipData: {
      current: {
        Name: {
          value: "dEEPIKA JANGA",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 340,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dEEPIKA JANGA",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 340,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mrinal Kaustue",
    defaultValue: 340,
    tooltipData: {
      current: {
        Name: {
          value: "Mrinal Kaustue",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 340,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mrinal Kaustue",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 340,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Humera Shoaib",
    defaultValue: 339,
    tooltipData: {
      current: {
        Name: {
          value: "Humera Shoaib",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 339,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Humera Shoaib",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 339,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sriram Shankar",
    defaultValue: 339,
    tooltipData: {
      current: {
        Name: {
          value: "Sriram Shankar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 339,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sriram Shankar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 339,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Awais Umer",
    defaultValue: 338,
    tooltipData: {
      current: {
        Name: {
          value: "Awais Umer",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 338,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Awais Umer",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 338,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammad Azim",
    defaultValue: 338,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammad Azim",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 338,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammad Azim",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 338,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "KISHORE nayudu",
    defaultValue: 338,
    tooltipData: {
      current: {
        Name: {
          value: "KISHORE nayudu",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 338,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "KISHORE nayudu",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 338,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vamsi Popuri",
    defaultValue: 337,
    tooltipData: {
      current: {
        Name: {
          value: "Vamsi Popuri",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 337,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vamsi Popuri",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 337,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Thushan Navaratne",
    defaultValue: 337,
    tooltipData: {
      current: {
        Name: {
          value: "Thushan Navaratne",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 337,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Thushan Navaratne",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 337,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohammed ahmed",
    defaultValue: 336,
    tooltipData: {
      current: {
        Name: {
          value: "mohammed ahmed",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 336,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohammed ahmed",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 336,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinod Atm",
    defaultValue: 336,
    tooltipData: {
      current: {
        Name: {
          value: "Vinod Atm",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 336,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinod Atm",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 336,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suresh Sappa",
    defaultValue: 336,
    tooltipData: {
      current: {
        Name: {
          value: "Suresh Sappa",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 336,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suresh Sappa",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 336,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Minhaj",
    defaultValue: 336,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Minhaj",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 336,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Minhaj",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 336,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bharath Puvvada",
    defaultValue: 334,
    tooltipData: {
      current: {
        Name: {
          value: "Bharath Puvvada",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 334,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bharath Puvvada",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 334,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rukmini ajukumar",
    defaultValue: 333,
    tooltipData: {
      current: {
        Name: {
          value: "rukmini ajukumar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 333,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rukmini ajukumar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 333,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Punita Amin",
    defaultValue: 333,
    tooltipData: {
      current: {
        Name: {
          value: "Punita Amin",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 333,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Punita Amin",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 333,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Omer Malik",
    defaultValue: 332,
    tooltipData: {
      current: {
        Name: {
          value: "Omer Malik",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 332,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Omer Malik",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 332,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mala viswanathan",
    defaultValue: 332,
    tooltipData: {
      current: {
        Name: {
          value: "mala viswanathan",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 332,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mala viswanathan",
          format: false,
        },
        TotalOrders: {
          value: 27,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 332,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anika Sayeed",
    defaultValue: 332,
    tooltipData: {
      current: {
        Name: {
          value: "Anika Sayeed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 332,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anika Sayeed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 332,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mark samuels",
    defaultValue: 332,
    tooltipData: {
      current: {
        Name: {
          value: "mark samuels",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 332,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mark samuels",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 332,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sheheli jahan",
    defaultValue: 331,
    tooltipData: {
      current: {
        Name: {
          value: "sheheli jahan",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 331,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sheheli jahan",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 331,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sadiq S",
    defaultValue: 330,
    tooltipData: {
      current: {
        Name: {
          value: "Sadiq S",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 330,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sadiq S",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 330,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suresh Prashar",
    defaultValue: 330,
    tooltipData: {
      current: {
        Name: {
          value: "Suresh Prashar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 330,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suresh Prashar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 330,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bharat rajappa",
    defaultValue: 329,
    tooltipData: {
      current: {
        Name: {
          value: "bharat rajappa",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bharat rajappa",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Farheen Vaidya",
    defaultValue: 329,
    tooltipData: {
      current: {
        Name: {
          value: "Farheen Vaidya",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Farheen Vaidya",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aj Ansari",
    defaultValue: 329,
    tooltipData: {
      current: {
        Name: {
          value: "Aj Ansari",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aj Ansari",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lisa patel",
    defaultValue: 329,
    tooltipData: {
      current: {
        Name: {
          value: "lisa patel",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lisa patel",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prem sholapurapu",
    defaultValue: 329,
    tooltipData: {
      current: {
        Name: {
          value: "prem sholapurapu",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prem sholapurapu",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nadia Khan",
    defaultValue: 329,
    tooltipData: {
      current: {
        Name: {
          value: "Nadia Khan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nadia Khan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 329,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ahmed shehata",
    defaultValue: 328,
    tooltipData: {
      current: {
        Name: {
          value: "ahmed shehata",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 328,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ahmed shehata",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 328,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "depti prakash",
    defaultValue: 327,
    tooltipData: {
      current: {
        Name: {
          value: "depti prakash",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 327,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "depti prakash",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 327,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Salman Shaukat",
    defaultValue: 326,
    tooltipData: {
      current: {
        Name: {
          value: "Salman Shaukat",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 326,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Salman Shaukat",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 326,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "navneet D",
    defaultValue: 326,
    tooltipData: {
      current: {
        Name: {
          value: "navneet D",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 326,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "navneet D",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 326,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ram Bandaru",
    defaultValue: 326,
    tooltipData: {
      current: {
        Name: {
          value: "Ram Bandaru",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 326,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ram Bandaru",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 326,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sonal Jain",
    defaultValue: 326,
    tooltipData: {
      current: {
        Name: {
          value: "Sonal Jain",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 326,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sonal Jain",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 326,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prakash Kasappa",
    defaultValue: 325,
    tooltipData: {
      current: {
        Name: {
          value: "Prakash Kasappa",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 325,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prakash Kasappa",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 325,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mamta B",
    defaultValue: 325,
    tooltipData: {
      current: {
        Name: {
          value: "Mamta B",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 325,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mamta B",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 325,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saraniya kumar",
    defaultValue: 325,
    tooltipData: {
      current: {
        Name: {
          value: "saraniya kumar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 325,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saraniya kumar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 325,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Naveed Ahmad",
    defaultValue: 324,
    tooltipData: {
      current: {
        Name: {
          value: "Naveed Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 324,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Naveed Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 324,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sameera P",
    defaultValue: 324,
    tooltipData: {
      current: {
        Name: {
          value: "sameera P",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 324,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sameera P",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 324,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nabeel shafi",
    defaultValue: 324,
    tooltipData: {
      current: {
        Name: {
          value: "nabeel shafi",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 324,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nabeel shafi",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 324,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sailaja Cherukuri",
    defaultValue: 323,
    tooltipData: {
      current: {
        Name: {
          value: "Sailaja Cherukuri",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 323,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sailaja Cherukuri",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 323,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "yasmeen mak",
    defaultValue: 323,
    tooltipData: {
      current: {
        Name: {
          value: "yasmeen mak",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 323,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "yasmeen mak",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 323,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neelima Pasupuleti",
    defaultValue: 323,
    tooltipData: {
      current: {
        Name: {
          value: "Neelima Pasupuleti",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 323,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neelima Pasupuleti",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 323,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Felix Ranjeeh",
    defaultValue: 323,
    tooltipData: {
      current: {
        Name: {
          value: "Felix Ranjeeh",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 323,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Felix Ranjeeh",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 323,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Asma Syed",
    defaultValue: 322,
    tooltipData: {
      current: {
        Name: {
          value: "Asma Syed",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Asma Syed",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aravind Diddi",
    defaultValue: 322,
    tooltipData: {
      current: {
        Name: {
          value: "Aravind Diddi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aravind Diddi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amit vaddi",
    defaultValue: 322,
    tooltipData: {
      current: {
        Name: {
          value: "amit vaddi",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amit vaddi",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lama alsharif",
    defaultValue: 322,
    tooltipData: {
      current: {
        Name: {
          value: "lama alsharif",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lama alsharif",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Farisa Khan",
    defaultValue: 322,
    tooltipData: {
      current: {
        Name: {
          value: "Farisa Khan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Farisa Khan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jagan rayavarapu",
    defaultValue: 322,
    tooltipData: {
      current: {
        Name: {
          value: "jagan rayavarapu",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jagan rayavarapu",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 322,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aaban Moiz",
    defaultValue: 320,
    tooltipData: {
      current: {
        Name: {
          value: "Aaban Moiz",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 320,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aaban Moiz",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 320,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "chandra govi",
    defaultValue: 320,
    tooltipData: {
      current: {
        Name: {
          value: "chandra govi",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 320,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "chandra govi",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 320,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "zainab umar",
    defaultValue: 319,
    tooltipData: {
      current: {
        Name: {
          value: "zainab umar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 319,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "zainab umar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 319,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arjun Subedi",
    defaultValue: 319,
    tooltipData: {
      current: {
        Name: {
          value: "Arjun Subedi",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 319,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arjun Subedi",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 319,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkata Appala",
    defaultValue: 317,
    tooltipData: {
      current: {
        Name: {
          value: "Venkata Appala",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 317,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkata Appala",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 317,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "gayathri nair",
    defaultValue: 317,
    tooltipData: {
      current: {
        Name: {
          value: "gayathri nair",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 317,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "gayathri nair",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 317,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srinivasan Arumugam",
    defaultValue: 317,
    tooltipData: {
      current: {
        Name: {
          value: "Srinivasan Arumugam",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 317,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srinivasan Arumugam",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 317,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkat Thirupathy",
    defaultValue: 315,
    tooltipData: {
      current: {
        Name: {
          value: "Venkat Thirupathy",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 315,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkat Thirupathy",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 315,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bandaru reddy",
    defaultValue: 314,
    tooltipData: {
      current: {
        Name: {
          value: "bandaru reddy",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 314,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bandaru reddy",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 314,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammad Butt",
    defaultValue: 314,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammad Butt",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 314,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammad Butt",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 314,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hamza khan",
    defaultValue: 314,
    tooltipData: {
      current: {
        Name: {
          value: "hamza khan",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 314,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hamza khan",
          format: false,
        },
        TotalOrders: {
          value: 47,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 314,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manoj Kumar",
    defaultValue: 314,
    tooltipData: {
      current: {
        Name: {
          value: "Manoj Kumar",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 314,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manoj Kumar",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 314,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amrin Harun",
    defaultValue: 313,
    tooltipData: {
      current: {
        Name: {
          value: "Amrin Harun",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 313,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amrin Harun",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 313,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amelia Boland",
    defaultValue: 312,
    tooltipData: {
      current: {
        Name: {
          value: "Amelia Boland",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 312,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amelia Boland",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 312,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vandana gorur",
    defaultValue: 312,
    tooltipData: {
      current: {
        Name: {
          value: "vandana gorur",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 312,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vandana gorur",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 312,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "gajanan gaikwad",
    defaultValue: 312,
    tooltipData: {
      current: {
        Name: {
          value: "gajanan gaikwad",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 312,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "gajanan gaikwad",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 312,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajini Arashanapalli",
    defaultValue: 311,
    tooltipData: {
      current: {
        Name: {
          value: "Rajini Arashanapalli",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 311,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajini Arashanapalli",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 311,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "malvika patel",
    defaultValue: 311,
    tooltipData: {
      current: {
        Name: {
          value: "malvika patel",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 311,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "malvika patel",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 311,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prasad p",
    defaultValue: 311,
    tooltipData: {
      current: {
        Name: {
          value: "Prasad p",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 311,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prasad p",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 311,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swarooea Pulivarthi",
    defaultValue: 310,
    tooltipData: {
      current: {
        Name: {
          value: "Swarooea Pulivarthi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swarooea Pulivarthi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "meghana durjam",
    defaultValue: 310,
    tooltipData: {
      current: {
        Name: {
          value: "meghana durjam",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "meghana durjam",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bobby Thomas",
    defaultValue: 310,
    tooltipData: {
      current: {
        Name: {
          value: "Bobby Thomas",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bobby Thomas",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jitesh Srivastava",
    defaultValue: 310,
    tooltipData: {
      current: {
        Name: {
          value: "Jitesh Srivastava",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jitesh Srivastava",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ASHWAG YAGOUB",
    defaultValue: 310,
    tooltipData: {
      current: {
        Name: {
          value: "ASHWAG YAGOUB",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ASHWAG YAGOUB",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 310,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "MONJUR RAHMAN",
    defaultValue: 309,
    tooltipData: {
      current: {
        Name: {
          value: "MONJUR RAHMAN",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 309,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "MONJUR RAHMAN",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 309,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "srinath jagarlamudi",
    defaultValue: 309,
    tooltipData: {
      current: {
        Name: {
          value: "srinath jagarlamudi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 309,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "srinath jagarlamudi",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 309,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saima Hafeez",
    defaultValue: 309,
    tooltipData: {
      current: {
        Name: {
          value: "Saima Hafeez",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 309,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saima Hafeez",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 309,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunil Shalia",
    defaultValue: 308,
    tooltipData: {
      current: {
        Name: {
          value: "Sunil Shalia",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 308,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunil Shalia",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 308,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "imran siddiqui",
    defaultValue: 308,
    tooltipData: {
      current: {
        Name: {
          value: "imran siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 308,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "imran siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 308,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rashad Mirzayed",
    defaultValue: 308,
    tooltipData: {
      current: {
        Name: {
          value: "Rashad Mirzayed",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 308,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rashad Mirzayed",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 308,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Osama",
    defaultValue: 307,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Osama",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 307,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Osama",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 307,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sandeep kaur",
    defaultValue: 307,
    tooltipData: {
      current: {
        Name: {
          value: "sandeep kaur",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 307,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sandeep kaur",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 307,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raghu Konduru",
    defaultValue: 305,
    tooltipData: {
      current: {
        Name: {
          value: "Raghu Konduru",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 305,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raghu Konduru",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 305,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahesh Subramanya",
    defaultValue: 305,
    tooltipData: {
      current: {
        Name: {
          value: "Mahesh Subramanya",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 305,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahesh Subramanya",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 305,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mehnaz Zaidi",
    defaultValue: 305,
    tooltipData: {
      current: {
        Name: {
          value: "Mehnaz Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 305,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mehnaz Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 305,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "iRIN shimu",
    defaultValue: 304,
    tooltipData: {
      current: {
        Name: {
          value: "iRIN shimu",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 304,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "iRIN shimu",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 304,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunil Singareddy",
    defaultValue: 304,
    tooltipData: {
      current: {
        Name: {
          value: "Sunil Singareddy",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 304,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunil Singareddy",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 304,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "reji sontineni",
    defaultValue: 302,
    tooltipData: {
      current: {
        Name: {
          value: "reji sontineni",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "reji sontineni",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "deepika karanguli",
    defaultValue: 302,
    tooltipData: {
      current: {
        Name: {
          value: "deepika karanguli",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "deepika karanguli",
          format: false,
        },
        TotalOrders: {
          value: 23,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "MEHENDI DAS",
    defaultValue: 302,
    tooltipData: {
      current: {
        Name: {
          value: "MEHENDI DAS",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "MEHENDI DAS",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rahul B",
    defaultValue: 302,
    tooltipData: {
      current: {
        Name: {
          value: "Rahul B",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rahul B",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "stacy samion",
    defaultValue: 302,
    tooltipData: {
      current: {
        Name: {
          value: "stacy samion",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "stacy samion",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 302,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Annie Khalid",
    defaultValue: 301,
    tooltipData: {
      current: {
        Name: {
          value: "Annie Khalid",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 301,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Annie Khalid",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 301,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fida hasan",
    defaultValue: 300,
    tooltipData: {
      current: {
        Name: {
          value: "fida hasan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fida hasan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naresh akulaa",
    defaultValue: 300,
    tooltipData: {
      current: {
        Name: {
          value: "naresh akulaa",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naresh akulaa",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aditya Demer",
    defaultValue: 300,
    tooltipData: {
      current: {
        Name: {
          value: "Aditya Demer",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aditya Demer",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "eswar chinna",
    defaultValue: 300,
    tooltipData: {
      current: {
        Name: {
          value: "eswar chinna",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "eswar chinna",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Jayachandran",
    defaultValue: 300,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Jayachandran",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Jayachandran",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mehandi Das",
    defaultValue: 300,
    tooltipData: {
      current: {
        Name: {
          value: "Mehandi Das",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mehandi Das",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 300,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Wardah Saeed",
    defaultValue: 299,
    tooltipData: {
      current: {
        Name: {
          value: "Wardah Saeed",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 299,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Wardah Saeed",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 299,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nasir Ali",
    defaultValue: 299,
    tooltipData: {
      current: {
        Name: {
          value: "Nasir Ali",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 299,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nasir Ali",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 299,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manisha Katar",
    defaultValue: 299,
    tooltipData: {
      current: {
        Name: {
          value: "Manisha Katar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 299,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manisha Katar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 299,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naga nimmagadda",
    defaultValue: 298,
    tooltipData: {
      current: {
        Name: {
          value: "naga nimmagadda",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 298,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naga nimmagadda",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 298,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Serlena Wardhan",
    defaultValue: 298,
    tooltipData: {
      current: {
        Name: {
          value: "Serlena Wardhan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 298,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Serlena Wardhan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 298,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pranav Donepudi",
    defaultValue: 298,
    tooltipData: {
      current: {
        Name: {
          value: "Pranav Donepudi",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 298,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pranav Donepudi",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 298,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kirat Kumar",
    defaultValue: 298,
    tooltipData: {
      current: {
        Name: {
          value: "Kirat Kumar",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 298,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kirat Kumar",
          format: false,
        },
        TotalOrders: {
          value: 26,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 298,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkat Kundeti",
    defaultValue: 297,
    tooltipData: {
      current: {
        Name: {
          value: "Venkat Kundeti",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 297,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkat Kundeti",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 297,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mathew jose",
    defaultValue: 297,
    tooltipData: {
      current: {
        Name: {
          value: "Mathew jose",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 297,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mathew jose",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 297,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "surya sagi",
    defaultValue: 296,
    tooltipData: {
      current: {
        Name: {
          value: "surya sagi",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "surya sagi",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Salman Malick",
    defaultValue: 296,
    tooltipData: {
      current: {
        Name: {
          value: "Salman Malick",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Salman Malick",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pooja Trehan",
    defaultValue: 296,
    tooltipData: {
      current: {
        Name: {
          value: "Pooja Trehan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pooja Trehan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rita Shertty",
    defaultValue: 296,
    tooltipData: {
      current: {
        Name: {
          value: "Rita Shertty",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rita Shertty",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chandra Vytha",
    defaultValue: 296,
    tooltipData: {
      current: {
        Name: {
          value: "Chandra Vytha",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chandra Vytha",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 296,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gaurav Sehgal",
    defaultValue: 295,
    tooltipData: {
      current: {
        Name: {
          value: "Gaurav Sehgal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 295,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gaurav Sehgal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 295,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pradeep modukuri",
    defaultValue: 295,
    tooltipData: {
      current: {
        Name: {
          value: "pradeep modukuri",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 295,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pradeep modukuri",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 295,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Faisal Qadh",
    defaultValue: 295,
    tooltipData: {
      current: {
        Name: {
          value: "Faisal Qadh",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 295,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Faisal Qadh",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 295,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ram wagle",
    defaultValue: 294,
    tooltipData: {
      current: {
        Name: {
          value: "ram wagle",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 294,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ram wagle",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 294,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "azmat aziz",
    defaultValue: 294,
    tooltipData: {
      current: {
        Name: {
          value: "azmat aziz",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 294,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "azmat aziz",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 294,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Siva Budati",
    defaultValue: 294,
    tooltipData: {
      current: {
        Name: {
          value: "Siva Budati",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 294,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Siva Budati",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 294,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mini Thejo",
    defaultValue: 293,
    tooltipData: {
      current: {
        Name: {
          value: "Mini Thejo",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 293,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mini Thejo",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 293,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "BInu Chitrakar",
    defaultValue: 293,
    tooltipData: {
      current: {
        Name: {
          value: "BInu Chitrakar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 293,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "BInu Chitrakar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 293,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "moiz ahmed",
    defaultValue: 293,
    tooltipData: {
      current: {
        Name: {
          value: "moiz ahmed",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 293,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "moiz ahmed",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 293,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prameela d",
    defaultValue: 293,
    tooltipData: {
      current: {
        Name: {
          value: "Prameela d",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 293,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prameela d",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 293,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Burra",
    defaultValue: 290,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Burra",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Burra",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vaishali Pawar",
    defaultValue: 290,
    tooltipData: {
      current: {
        Name: {
          value: "Vaishali Pawar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vaishali Pawar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jay kumar",
    defaultValue: 290,
    tooltipData: {
      current: {
        Name: {
          value: "jay kumar",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jay kumar",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kirti J",
    defaultValue: 290,
    tooltipData: {
      current: {
        Name: {
          value: "Kirti J",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kirti J",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Porna Vemulapalli",
    defaultValue: 290,
    tooltipData: {
      current: {
        Name: {
          value: "Porna Vemulapalli",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Porna Vemulapalli",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 290,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Brinda Mehta",
    defaultValue: 289,
    tooltipData: {
      current: {
        Name: {
          value: "Brinda Mehta",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Brinda Mehta",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashish Tasare",
    defaultValue: 289,
    tooltipData: {
      current: {
        Name: {
          value: "Ashish Tasare",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashish Tasare",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shakeel Bhai",
    defaultValue: 289,
    tooltipData: {
      current: {
        Name: {
          value: "Shakeel Bhai",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shakeel Bhai",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raheel hafeez",
    defaultValue: 289,
    tooltipData: {
      current: {
        Name: {
          value: "raheel hafeez",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raheel hafeez",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "marjan alavi",
    defaultValue: 289,
    tooltipData: {
      current: {
        Name: {
          value: "marjan alavi",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "marjan alavi",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhuri Pangulura",
    defaultValue: 289,
    tooltipData: {
      current: {
        Name: {
          value: "Madhuri Pangulura",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhuri Pangulura",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 289,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "reema ghosh",
    defaultValue: 288,
    tooltipData: {
      current: {
        Name: {
          value: "reema ghosh",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "reema ghosh",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NAILA OWAIS",
    defaultValue: 288,
    tooltipData: {
      current: {
        Name: {
          value: "NAILA OWAIS",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NAILA OWAIS",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amal kamil",
    defaultValue: 288,
    tooltipData: {
      current: {
        Name: {
          value: "amal kamil",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amal kamil",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ajay y",
    defaultValue: 288,
    tooltipData: {
      current: {
        Name: {
          value: "ajay y",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ajay y",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raheel Arshad",
    defaultValue: 288,
    tooltipData: {
      current: {
        Name: {
          value: "Raheel Arshad",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raheel Arshad",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 288,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sanober Ahmed",
    defaultValue: 287,
    tooltipData: {
      current: {
        Name: {
          value: "Sanober Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 287,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sanober Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 287,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jalal Ahmed",
    defaultValue: 286,
    tooltipData: {
      current: {
        Name: {
          value: "Jalal Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 286,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jalal Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 286,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ajay Surapaneni",
    defaultValue: 285,
    tooltipData: {
      current: {
        Name: {
          value: "Ajay Surapaneni",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 285,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ajay Surapaneni",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 285,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sandhya G",
    defaultValue: 285,
    tooltipData: {
      current: {
        Name: {
          value: "Sandhya G",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 285,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sandhya G",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 285,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhu Ravila",
    defaultValue: 285,
    tooltipData: {
      current: {
        Name: {
          value: "Madhu Ravila",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 285,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhu Ravila",
          format: false,
        },
        TotalOrders: {
          value: 24,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 285,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dipti mistry",
    defaultValue: 285,
    tooltipData: {
      current: {
        Name: {
          value: "dipti mistry",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 285,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dipti mistry",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 285,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mithun Pusukuri",
    defaultValue: 284,
    tooltipData: {
      current: {
        Name: {
          value: "Mithun Pusukuri",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 284,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mithun Pusukuri",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 284,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muneeza Last name",
    defaultValue: 284,
    tooltipData: {
      current: {
        Name: {
          value: "Muneeza Last name",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 284,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muneeza Last name",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 284,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SIMA ARORA",
    defaultValue: 284,
    tooltipData: {
      current: {
        Name: {
          value: "SIMA ARORA",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 284,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SIMA ARORA",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 284,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swarup Bade",
    defaultValue: 284,
    tooltipData: {
      current: {
        Name: {
          value: "Swarup Bade",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 284,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swarup Bade",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 284,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Krishna Prasad",
    defaultValue: 283,
    tooltipData: {
      current: {
        Name: {
          value: "Krishna Prasad",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 283,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Krishna Prasad",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 283,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Steve S",
    defaultValue: 282,
    tooltipData: {
      current: {
        Name: {
          value: "Steve S",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 282,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Steve S",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 282,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sameer Vilas",
    defaultValue: 281,
    tooltipData: {
      current: {
        Name: {
          value: "Sameer Vilas",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sameer Vilas",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Namrata Walia",
    defaultValue: 281,
    tooltipData: {
      current: {
        Name: {
          value: "Namrata Walia",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Namrata Walia",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sabika jafri",
    defaultValue: 281,
    tooltipData: {
      current: {
        Name: {
          value: "sabika jafri",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sabika jafri",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sangeeta arun",
    defaultValue: 281,
    tooltipData: {
      current: {
        Name: {
          value: "sangeeta arun",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sangeeta arun",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gunjan Seth",
    defaultValue: 281,
    tooltipData: {
      current: {
        Name: {
          value: "Gunjan Seth",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gunjan Seth",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Navneet Dasson",
    defaultValue: 281,
    tooltipData: {
      current: {
        Name: {
          value: "Navneet Dasson",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Navneet Dasson",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Thilak Nagaraja",
    defaultValue: 281,
    tooltipData: {
      current: {
        Name: {
          value: "Thilak Nagaraja",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Thilak Nagaraja",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 281,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yasiir Kirmani",
    defaultValue: 280,
    tooltipData: {
      current: {
        Name: {
          value: "Yasiir Kirmani",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 280,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yasiir Kirmani",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 280,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Md uddin",
    defaultValue: 280,
    tooltipData: {
      current: {
        Name: {
          value: "Md uddin",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 280,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Md uddin",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 280,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tauqir Nizami",
    defaultValue: 280,
    tooltipData: {
      current: {
        Name: {
          value: "Tauqir Nizami",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 280,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tauqir Nizami",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 280,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sridhar swargam",
    defaultValue: 280,
    tooltipData: {
      current: {
        Name: {
          value: "sridhar swargam",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 280,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sridhar swargam",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 280,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saritha S",
    defaultValue: 279,
    tooltipData: {
      current: {
        Name: {
          value: "Saritha S",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 279,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saritha S",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 279,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepti Lokanath",
    defaultValue: 279,
    tooltipData: {
      current: {
        Name: {
          value: "Deepti Lokanath",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 279,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepti Lokanath",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 279,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SULAKSHANA LAHIRI",
    defaultValue: 279,
    tooltipData: {
      current: {
        Name: {
          value: "SULAKSHANA LAHIRI",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 279,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SULAKSHANA LAHIRI",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 279,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ummehain yamani",
    defaultValue: 279,
    tooltipData: {
      current: {
        Name: {
          value: "ummehain yamani",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 279,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ummehain yamani",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 279,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syeda Batool",
    defaultValue: 278,
    tooltipData: {
      current: {
        Name: {
          value: "Syeda Batool",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 278,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syeda Batool",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 278,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nasreen shah",
    defaultValue: 278,
    tooltipData: {
      current: {
        Name: {
          value: "nasreen shah",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 278,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nasreen shah",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 278,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priti Belorkar",
    defaultValue: 278,
    tooltipData: {
      current: {
        Name: {
          value: "Priti Belorkar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 278,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priti Belorkar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 278,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramakishore gandi",
    defaultValue: 277,
    tooltipData: {
      current: {
        Name: {
          value: "ramakishore gandi",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 277,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramakishore gandi",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 277,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sabina Shahid",
    defaultValue: 277,
    tooltipData: {
      current: {
        Name: {
          value: "Sabina Shahid",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 277,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sabina Shahid",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 277,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "debasis pradhan",
    defaultValue: 277,
    tooltipData: {
      current: {
        Name: {
          value: "debasis pradhan",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 277,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "debasis pradhan",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 277,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Paras Gupta",
    defaultValue: 276,
    tooltipData: {
      current: {
        Name: {
          value: "Paras Gupta",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 276,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Paras Gupta",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 276,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jhinli Bora",
    defaultValue: 275,
    tooltipData: {
      current: {
        Name: {
          value: "Jhinli Bora",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 275,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jhinli Bora",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 275,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "avon farnandes",
    defaultValue: 275,
    tooltipData: {
      current: {
        Name: {
          value: "avon farnandes",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 275,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "avon farnandes",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 275,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "salma iqbal",
    defaultValue: 275,
    tooltipData: {
      current: {
        Name: {
          value: "salma iqbal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 275,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "salma iqbal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 275,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "meriem madi",
    defaultValue: 274,
    tooltipData: {
      current: {
        Name: {
          value: "meriem madi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 274,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "meriem madi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 274,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "samon tahir",
    defaultValue: 274,
    tooltipData: {
      current: {
        Name: {
          value: "samon tahir",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 274,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "samon tahir",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 274,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fahad bilal",
    defaultValue: 274,
    tooltipData: {
      current: {
        Name: {
          value: "fahad bilal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 274,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fahad bilal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 274,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ganesh arunachalam",
    defaultValue: 273,
    tooltipData: {
      current: {
        Name: {
          value: "ganesh arunachalam",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 273,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ganesh arunachalam",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 273,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vamshee Milkuri",
    defaultValue: 273,
    tooltipData: {
      current: {
        Name: {
          value: "Vamshee Milkuri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 273,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vamshee Milkuri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 273,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "priti jha",
    defaultValue: 273,
    tooltipData: {
      current: {
        Name: {
          value: "priti jha",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 273,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "priti jha",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 273,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rahitha Ram",
    defaultValue: 273,
    tooltipData: {
      current: {
        Name: {
          value: "Rahitha Ram",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 273,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rahitha Ram",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 273,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arun R",
    defaultValue: 272,
    tooltipData: {
      current: {
        Name: {
          value: "Arun R",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 272,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arun R",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 272,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sid mukerji",
    defaultValue: 272,
    tooltipData: {
      current: {
        Name: {
          value: "sid mukerji",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 272,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sid mukerji",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 272,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Misbah Imran",
    defaultValue: 271,
    tooltipData: {
      current: {
        Name: {
          value: "Misbah Imran",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 271,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Misbah Imran",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 271,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Mansoor",
    defaultValue: 271,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Mansoor",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 271,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Mansoor",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 271,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nandita wikhar",
    defaultValue: 271,
    tooltipData: {
      current: {
        Name: {
          value: "Nandita wikhar",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 271,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nandita wikhar",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 271,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "RK Mallya",
    defaultValue: 270,
    tooltipData: {
      current: {
        Name: {
          value: "RK Mallya",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 270,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "RK Mallya",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 270,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahendar Redy",
    defaultValue: 270,
    tooltipData: {
      current: {
        Name: {
          value: "Mahendar Redy",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 270,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahendar Redy",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 270,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Puja Singh",
    defaultValue: 270,
    tooltipData: {
      current: {
        Name: {
          value: "Puja Singh",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 270,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Puja Singh",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 270,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suma Patibanda",
    defaultValue: 269,
    tooltipData: {
      current: {
        Name: {
          value: "Suma Patibanda",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suma Patibanda",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manohar Bathina",
    defaultValue: 269,
    tooltipData: {
      current: {
        Name: {
          value: "Manohar Bathina",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manohar Bathina",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samarjit Chakraborty",
    defaultValue: 269,
    tooltipData: {
      current: {
        Name: {
          value: "Samarjit Chakraborty",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samarjit Chakraborty",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amulya Uppalapati",
    defaultValue: 269,
    tooltipData: {
      current: {
        Name: {
          value: "Amulya Uppalapati",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amulya Uppalapati",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suresh t",
    defaultValue: 269,
    tooltipData: {
      current: {
        Name: {
          value: "Suresh t",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suresh t",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 269,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jawad Rehman",
    defaultValue: 268,
    tooltipData: {
      current: {
        Name: {
          value: "Jawad Rehman",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 268,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jawad Rehman",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 268,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shibani gupta",
    defaultValue: 268,
    tooltipData: {
      current: {
        Name: {
          value: "shibani gupta",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 268,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shibani gupta",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 268,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vani shirahatti",
    defaultValue: 267,
    tooltipData: {
      current: {
        Name: {
          value: "vani shirahatti",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 267,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vani shirahatti",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 267,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raji Prasad",
    defaultValue: 267,
    tooltipData: {
      current: {
        Name: {
          value: "Raji Prasad",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 267,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raji Prasad",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 267,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "preeti virani",
    defaultValue: 267,
    tooltipData: {
      current: {
        Name: {
          value: "preeti virani",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 267,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "preeti virani",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 267,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinod Rajan",
    defaultValue: 266,
    tooltipData: {
      current: {
        Name: {
          value: "Vinod Rajan",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 266,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinod Rajan",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 266,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kanthi Kaza",
    defaultValue: 266,
    tooltipData: {
      current: {
        Name: {
          value: "Kanthi Kaza",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 266,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kanthi Kaza",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 266,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sakeena Amjad",
    defaultValue: 265,
    tooltipData: {
      current: {
        Name: {
          value: "Sakeena Amjad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 265,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sakeena Amjad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 265,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raj giddi",
    defaultValue: 264,
    tooltipData: {
      current: {
        Name: {
          value: "raj giddi",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 264,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raj giddi",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 264,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arshad Riaz",
    defaultValue: 264,
    tooltipData: {
      current: {
        Name: {
          value: "Arshad Riaz",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 264,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arshad Riaz",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 264,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suman Sahu",
    defaultValue: 263,
    tooltipData: {
      current: {
        Name: {
          value: "Suman Sahu",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 263,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suman Sahu",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 263,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prashanth Reddy",
    defaultValue: 263,
    tooltipData: {
      current: {
        Name: {
          value: "Prashanth Reddy",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 263,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prashanth Reddy",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 263,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prabhakar sinha",
    defaultValue: 263,
    tooltipData: {
      current: {
        Name: {
          value: "prabhakar sinha",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 263,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prabhakar sinha",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 263,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naheed ahmed",
    defaultValue: 263,
    tooltipData: {
      current: {
        Name: {
          value: "naheed ahmed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 263,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naheed ahmed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 263,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ravi mukkera",
    defaultValue: 262,
    tooltipData: {
      current: {
        Name: {
          value: "ravi mukkera",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 262,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ravi mukkera",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 262,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mandar shah",
    defaultValue: 262,
    tooltipData: {
      current: {
        Name: {
          value: "Mandar shah",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 262,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mandar shah",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 262,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "surendra kotta",
    defaultValue: 262,
    tooltipData: {
      current: {
        Name: {
          value: "surendra kotta",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 262,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "surendra kotta",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 262,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhu Pemmaraju",
    defaultValue: 261,
    tooltipData: {
      current: {
        Name: {
          value: "Madhu Pemmaraju",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 261,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhu Pemmaraju",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 261,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Krishna R",
    defaultValue: 261,
    tooltipData: {
      current: {
        Name: {
          value: "Krishna R",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 261,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Krishna R",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 261,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mukundan Krishnan",
    defaultValue: 260,
    tooltipData: {
      current: {
        Name: {
          value: "Mukundan Krishnan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 260,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mukundan Krishnan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 260,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raveesh V",
    defaultValue: 260,
    tooltipData: {
      current: {
        Name: {
          value: "Raveesh V",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 260,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raveesh V",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 260,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manju Rama",
    defaultValue: 260,
    tooltipData: {
      current: {
        Name: {
          value: "Manju Rama",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 260,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manju Rama",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 260,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sunny abbasi",
    defaultValue: 260,
    tooltipData: {
      current: {
        Name: {
          value: "sunny abbasi",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 260,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sunny abbasi",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 260,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Praveena Tikkireddi",
    defaultValue: 259,
    tooltipData: {
      current: {
        Name: {
          value: "Praveena Tikkireddi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 259,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Praveena Tikkireddi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 259,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "CHANDRESEKAR BALA",
    defaultValue: 259,
    tooltipData: {
      current: {
        Name: {
          value: "CHANDRESEKAR BALA",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 259,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "CHANDRESEKAR BALA",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 259,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "syed Mubashir",
    defaultValue: 258,
    tooltipData: {
      current: {
        Name: {
          value: "syed Mubashir",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 258,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "syed Mubashir",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 258,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nithya Logan",
    defaultValue: 258,
    tooltipData: {
      current: {
        Name: {
          value: "Nithya Logan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 258,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nithya Logan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 258,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Junaid James",
    defaultValue: 258,
    tooltipData: {
      current: {
        Name: {
          value: "Junaid James",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 258,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Junaid James",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 258,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Krithika Krishnan",
    defaultValue: 257,
    tooltipData: {
      current: {
        Name: {
          value: "Krithika Krishnan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Krithika Krishnan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "khitam mizyad",
    defaultValue: 257,
    tooltipData: {
      current: {
        Name: {
          value: "khitam mizyad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "khitam mizyad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Sabha",
    defaultValue: 257,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Sabha",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Sabha",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bharat raghavan",
    defaultValue: 257,
    tooltipData: {
      current: {
        Name: {
          value: "bharat raghavan",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bharat raghavan",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nadeem Mujeeb",
    defaultValue: 257,
    tooltipData: {
      current: {
        Name: {
          value: "Nadeem Mujeeb",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nadeem Mujeeb",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 257,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammed Qumruzzaman",
    defaultValue: 256,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammed Qumruzzaman",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammed Qumruzzaman",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hema Narayanan",
    defaultValue: 256,
    tooltipData: {
      current: {
        Name: {
          value: "Hema Narayanan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hema Narayanan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Subash A",
    defaultValue: 256,
    tooltipData: {
      current: {
        Name: {
          value: "Subash A",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Subash A",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anju Nair",
    defaultValue: 256,
    tooltipData: {
      current: {
        Name: {
          value: "Anju Nair",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anju Nair",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jasmeet Othee",
    defaultValue: 256,
    tooltipData: {
      current: {
        Name: {
          value: "Jasmeet Othee",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jasmeet Othee",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Seshu Kanuri",
    defaultValue: 256,
    tooltipData: {
      current: {
        Name: {
          value: "Seshu Kanuri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Seshu Kanuri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 256,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Divya Kuchi",
    defaultValue: 255,
    tooltipData: {
      current: {
        Name: {
          value: "Divya Kuchi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 255,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Divya Kuchi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 255,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhargavi Yadla",
    defaultValue: 255,
    tooltipData: {
      current: {
        Name: {
          value: "Bhargavi Yadla",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 255,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhargavi Yadla",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 255,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Seemi Amir",
    defaultValue: 255,
    tooltipData: {
      current: {
        Name: {
          value: "Seemi Amir",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 255,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Seemi Amir",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 255,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ammna Mirza",
    defaultValue: 254,
    tooltipData: {
      current: {
        Name: {
          value: "Ammna Mirza",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 254,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ammna Mirza",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 254,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sheela naga",
    defaultValue: 254,
    tooltipData: {
      current: {
        Name: {
          value: "sheela naga",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 254,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sheela naga",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 254,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Satish Kuruvada",
    defaultValue: 253,
    tooltipData: {
      current: {
        Name: {
          value: "Satish Kuruvada",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Satish Kuruvada",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bilal kazmi",
    defaultValue: 253,
    tooltipData: {
      current: {
        Name: {
          value: "bilal kazmi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bilal kazmi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "luciana rizinde",
    defaultValue: 253,
    tooltipData: {
      current: {
        Name: {
          value: "luciana rizinde",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "luciana rizinde",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sujaa Deepak",
    defaultValue: 253,
    tooltipData: {
      current: {
        Name: {
          value: "Sujaa Deepak",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sujaa Deepak",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saima Mansuri",
    defaultValue: 253,
    tooltipData: {
      current: {
        Name: {
          value: "Saima Mansuri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saima Mansuri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amar Pali",
    defaultValue: 253,
    tooltipData: {
      current: {
        Name: {
          value: "Amar Pali",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amar Pali",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 253,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sara naqvi",
    defaultValue: 252,
    tooltipData: {
      current: {
        Name: {
          value: "sara naqvi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 252,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sara naqvi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 252,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sandhya pai",
    defaultValue: 252,
    tooltipData: {
      current: {
        Name: {
          value: "sandhya pai",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 252,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sandhya pai",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 252,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jasleen lyall",
    defaultValue: 252,
    tooltipData: {
      current: {
        Name: {
          value: "Jasleen lyall",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 252,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jasleen lyall",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 252,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Wazir Sheikh",
    defaultValue: 251,
    tooltipData: {
      current: {
        Name: {
          value: "Wazir Sheikh",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 251,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Wazir Sheikh",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 251,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "p singh",
    defaultValue: 250,
    tooltipData: {
      current: {
        Name: {
          value: "p singh",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 250,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "p singh",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 250,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raza Mohammed",
    defaultValue: 250,
    tooltipData: {
      current: {
        Name: {
          value: "Raza Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 250,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raza Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 250,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "keerthi kotla",
    defaultValue: 250,
    tooltipData: {
      current: {
        Name: {
          value: "keerthi kotla",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 250,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "keerthi kotla",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 250,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prannay Parihar",
    defaultValue: 249,
    tooltipData: {
      current: {
        Name: {
          value: "Prannay Parihar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 249,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prannay Parihar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 249,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "MOhammad Rahman",
    defaultValue: 249,
    tooltipData: {
      current: {
        Name: {
          value: "MOhammad Rahman",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 249,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "MOhammad Rahman",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 249,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "talha zaheer",
    defaultValue: 249,
    tooltipData: {
      current: {
        Name: {
          value: "talha zaheer",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 249,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "talha zaheer",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 249,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkatesh Akkipedi",
    defaultValue: 248,
    tooltipData: {
      current: {
        Name: {
          value: "Venkatesh Akkipedi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkatesh Akkipedi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dalip Kumar",
    defaultValue: 248,
    tooltipData: {
      current: {
        Name: {
          value: "Dalip Kumar",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dalip Kumar",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Megha shah",
    defaultValue: 248,
    tooltipData: {
      current: {
        Name: {
          value: "Megha shah",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Megha shah",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prabesh Paudel",
    defaultValue: 248,
    tooltipData: {
      current: {
        Name: {
          value: "Prabesh Paudel",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prabesh Paudel",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alaa T",
    defaultValue: 248,
    tooltipData: {
      current: {
        Name: {
          value: "Alaa T",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alaa T",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 248,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sohail Iqbal",
    defaultValue: 247,
    tooltipData: {
      current: {
        Name: {
          value: "Sohail Iqbal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 247,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sohail Iqbal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 247,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chandana Geeda",
    defaultValue: 247,
    tooltipData: {
      current: {
        Name: {
          value: "Chandana Geeda",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 247,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chandana Geeda",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 247,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sameer Puri",
    defaultValue: 247,
    tooltipData: {
      current: {
        Name: {
          value: "Sameer Puri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 247,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sameer Puri",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 247,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "waseem shaikh",
    defaultValue: 247,
    tooltipData: {
      current: {
        Name: {
          value: "waseem shaikh",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 247,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "waseem shaikh",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 247,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shashank Panchangam",
    defaultValue: 246,
    tooltipData: {
      current: {
        Name: {
          value: "Shashank Panchangam",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 246,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shashank Panchangam",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 246,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajiv Arya",
    defaultValue: 246,
    tooltipData: {
      current: {
        Name: {
          value: "Rajiv Arya",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 246,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajiv Arya",
          format: false,
        },
        TotalOrders: {
          value: 19,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 246,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amit Rathore",
    defaultValue: 246,
    tooltipData: {
      current: {
        Name: {
          value: "Amit Rathore",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 246,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amit Rathore",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 246,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hassan bajwa",
    defaultValue: 245,
    tooltipData: {
      current: {
        Name: {
          value: "hassan bajwa",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 245,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hassan bajwa",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 245,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arpitha Alluri",
    defaultValue: 245,
    tooltipData: {
      current: {
        Name: {
          value: "Arpitha Alluri",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 245,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arpitha Alluri",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 245,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinay K",
    defaultValue: 244,
    tooltipData: {
      current: {
        Name: {
          value: "Vinay K",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinay K",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Uma Arvinth",
    defaultValue: 244,
    tooltipData: {
      current: {
        Name: {
          value: "Uma Arvinth",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Uma Arvinth",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prachi pagnis",
    defaultValue: 244,
    tooltipData: {
      current: {
        Name: {
          value: "prachi pagnis",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prachi pagnis",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Siva Kumar",
    defaultValue: 244,
    tooltipData: {
      current: {
        Name: {
          value: "Siva Kumar",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Siva Kumar",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sujata gunta",
    defaultValue: 244,
    tooltipData: {
      current: {
        Name: {
          value: "sujata gunta",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sujata gunta",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 244,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "varun navad",
    defaultValue: 243,
    tooltipData: {
      current: {
        Name: {
          value: "varun navad",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "varun navad",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Farah Hassan",
    defaultValue: 243,
    tooltipData: {
      current: {
        Name: {
          value: "Farah Hassan",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Farah Hassan",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aisha Haroon",
    defaultValue: 243,
    tooltipData: {
      current: {
        Name: {
          value: "Aisha Haroon",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aisha Haroon",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Karthik Bommana",
    defaultValue: 243,
    tooltipData: {
      current: {
        Name: {
          value: "Karthik Bommana",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Karthik Bommana",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Hariyana",
    defaultValue: 243,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Hariyana",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Hariyana",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 243,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashish Nischal",
    defaultValue: 242,
    tooltipData: {
      current: {
        Name: {
          value: "Ashish Nischal",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 242,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashish Nischal",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 242,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zehra Meerza",
    defaultValue: 242,
    tooltipData: {
      current: {
        Name: {
          value: "Zehra Meerza",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 242,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zehra Meerza",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 242,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vani Kurupati",
    defaultValue: 241,
    tooltipData: {
      current: {
        Name: {
          value: "Vani Kurupati",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 241,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vani Kurupati",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 241,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ebun Roberts",
    defaultValue: 241,
    tooltipData: {
      current: {
        Name: {
          value: "Ebun Roberts",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 241,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ebun Roberts",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 241,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Meenakshi Gangwar",
    defaultValue: 241,
    tooltipData: {
      current: {
        Name: {
          value: "Meenakshi Gangwar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 241,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Meenakshi Gangwar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 241,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ravi singh",
    defaultValue: 240,
    tooltipData: {
      current: {
        Name: {
          value: "ravi singh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ravi singh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swathi Reddy",
    defaultValue: 240,
    tooltipData: {
      current: {
        Name: {
          value: "Swathi Reddy",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swathi Reddy",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sharmila Kugasia",
    defaultValue: 240,
    tooltipData: {
      current: {
        Name: {
          value: "Sharmila Kugasia",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sharmila Kugasia",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Karim Rahim",
    defaultValue: 240,
    tooltipData: {
      current: {
        Name: {
          value: "Karim Rahim",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Karim Rahim",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Varun Tandon",
    defaultValue: 240,
    tooltipData: {
      current: {
        Name: {
          value: "Varun Tandon",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Varun Tandon",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pramod narahari",
    defaultValue: 239,
    tooltipData: {
      current: {
        Name: {
          value: "pramod narahari",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 239,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pramod narahari",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 239,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chay B",
    defaultValue: 238,
    tooltipData: {
      current: {
        Name: {
          value: "Chay B",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 238,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chay B",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 238,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sikazwe Kapemba",
    defaultValue: 238,
    tooltipData: {
      current: {
        Name: {
          value: "Sikazwe Kapemba",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 238,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sikazwe Kapemba",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 238,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Toms John",
    defaultValue: 238,
    tooltipData: {
      current: {
        Name: {
          value: "Toms John",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 238,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Toms John",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 238,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jagan Pantina",
    defaultValue: 238,
    tooltipData: {
      current: {
        Name: {
          value: "Jagan Pantina",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 238,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jagan Pantina",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 238,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhu Naga",
    defaultValue: 237,
    tooltipData: {
      current: {
        Name: {
          value: "Madhu Naga",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhu Naga",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Giridhar Gutha",
    defaultValue: 237,
    tooltipData: {
      current: {
        Name: {
          value: "Giridhar Gutha",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Giridhar Gutha",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neelima Joshi",
    defaultValue: 237,
    tooltipData: {
      current: {
        Name: {
          value: "neelima Joshi",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neelima Joshi",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arjun medisetti",
    defaultValue: 237,
    tooltipData: {
      current: {
        Name: {
          value: "arjun medisetti",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arjun medisetti",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raja Stalin",
    defaultValue: 237,
    tooltipData: {
      current: {
        Name: {
          value: "Raja Stalin",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raja Stalin",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aliya zaidi",
    defaultValue: 237,
    tooltipData: {
      current: {
        Name: {
          value: "aliya zaidi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aliya zaidi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SHriram Srivasta",
    defaultValue: 237,
    tooltipData: {
      current: {
        Name: {
          value: "SHriram Srivasta",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SHriram Srivasta",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Niru Singhal",
    defaultValue: 237,
    tooltipData: {
      current: {
        Name: {
          value: "Niru Singhal",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Niru Singhal",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 237,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amulya bollineni",
    defaultValue: 236,
    tooltipData: {
      current: {
        Name: {
          value: "amulya bollineni",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 236,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amulya bollineni",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 236,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vaidehi Borgaonkar",
    defaultValue: 236,
    tooltipData: {
      current: {
        Name: {
          value: "Vaidehi Borgaonkar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 236,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vaidehi Borgaonkar",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 236,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shiva Gangaraju",
    defaultValue: 236,
    tooltipData: {
      current: {
        Name: {
          value: "Shiva Gangaraju",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 236,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shiva Gangaraju",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 236,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prince Thomas",
    defaultValue: 236,
    tooltipData: {
      current: {
        Name: {
          value: "Prince Thomas",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 236,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prince Thomas",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 236,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Gunta",
    defaultValue: 235,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Gunta",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 235,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Gunta",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 235,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vaibhav Khanna",
    defaultValue: 235,
    tooltipData: {
      current: {
        Name: {
          value: "Vaibhav Khanna",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 235,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vaibhav Khanna",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 235,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priya Ramesh",
    defaultValue: 235,
    tooltipData: {
      current: {
        Name: {
          value: "Priya Ramesh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 235,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priya Ramesh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 235,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dileep penmetsa",
    defaultValue: 235,
    tooltipData: {
      current: {
        Name: {
          value: "dileep penmetsa",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 235,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dileep penmetsa",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 235,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "JELLY RHEINHARDT",
    defaultValue: 234,
    tooltipData: {
      current: {
        Name: {
          value: "JELLY RHEINHARDT",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 234,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "JELLY RHEINHARDT",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 234,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pammi vira",
    defaultValue: 234,
    tooltipData: {
      current: {
        Name: {
          value: "pammi vira",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 234,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pammi vira",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 234,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lakhshmi chirumamilla",
    defaultValue: 233,
    tooltipData: {
      current: {
        Name: {
          value: "lakhshmi chirumamilla",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 233,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lakhshmi chirumamilla",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 233,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ganesh lathi",
    defaultValue: 233,
    tooltipData: {
      current: {
        Name: {
          value: "ganesh lathi",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 233,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ganesh lathi",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 233,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rafi Shaikh",
    defaultValue: 232,
    tooltipData: {
      current: {
        Name: {
          value: "Rafi Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rafi Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sowmya mohan",
    defaultValue: 232,
    tooltipData: {
      current: {
        Name: {
          value: "sowmya mohan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sowmya mohan",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Malavika Nagraj",
    defaultValue: 232,
    tooltipData: {
      current: {
        Name: {
          value: "Malavika Nagraj",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Malavika Nagraj",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Riffat Zeeshan",
    defaultValue: 232,
    tooltipData: {
      current: {
        Name: {
          value: "Riffat Zeeshan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Riffat Zeeshan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "priyanka srivastava",
    defaultValue: 232,
    tooltipData: {
      current: {
        Name: {
          value: "priyanka srivastava",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "priyanka srivastava",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bela shenoy",
    defaultValue: 232,
    tooltipData: {
      current: {
        Name: {
          value: "bela shenoy",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bela shenoy",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 232,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "amir khan",
    defaultValue: 231,
    tooltipData: {
      current: {
        Name: {
          value: "amir khan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 231,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "amir khan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 231,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saru Kaur",
    defaultValue: 230,
    tooltipData: {
      current: {
        Name: {
          value: "Saru Kaur",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 230,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saru Kaur",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 230,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sam singh",
    defaultValue: 230,
    tooltipData: {
      current: {
        Name: {
          value: "sam singh",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 230,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sam singh",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 230,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rucha Nirantar",
    defaultValue: 230,
    tooltipData: {
      current: {
        Name: {
          value: "Rucha Nirantar",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 230,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rucha Nirantar",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 230,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madan Biswal",
    defaultValue: 229,
    tooltipData: {
      current: {
        Name: {
          value: "Madan Biswal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 229,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madan Biswal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 229,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kranthi Stephen",
    defaultValue: 229,
    tooltipData: {
      current: {
        Name: {
          value: "Kranthi Stephen",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 229,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kranthi Stephen",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 229,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samuel Rajamanicum",
    defaultValue: 228,
    tooltipData: {
      current: {
        Name: {
          value: "Samuel Rajamanicum",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 228,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samuel Rajamanicum",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 228,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhav Sadhu",
    defaultValue: 228,
    tooltipData: {
      current: {
        Name: {
          value: "Madhav Sadhu",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 228,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhav Sadhu",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 228,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sakeena Ahmed",
    defaultValue: 228,
    tooltipData: {
      current: {
        Name: {
          value: "Sakeena Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 228,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sakeena Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 228,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sanjeev Kumar",
    defaultValue: 227,
    tooltipData: {
      current: {
        Name: {
          value: "Sanjeev Kumar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 227,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sanjeev Kumar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 227,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NAMRITA CHUBAL",
    defaultValue: 226,
    tooltipData: {
      current: {
        Name: {
          value: "NAMRITA CHUBAL",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 226,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NAMRITA CHUBAL",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 226,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Usha Aryal",
    defaultValue: 226,
    tooltipData: {
      current: {
        Name: {
          value: "Usha Aryal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 226,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Usha Aryal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 226,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hema Vuppala",
    defaultValue: 226,
    tooltipData: {
      current: {
        Name: {
          value: "Hema Vuppala",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 226,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hema Vuppala",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 226,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zainab Iqbal",
    defaultValue: 226,
    tooltipData: {
      current: {
        Name: {
          value: "Zainab Iqbal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 226,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zainab Iqbal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 226,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swami Adda",
    defaultValue: 225,
    tooltipData: {
      current: {
        Name: {
          value: "Swami Adda",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 225,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swami Adda",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 225,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hayder Khalissi",
    defaultValue: 225,
    tooltipData: {
      current: {
        Name: {
          value: "Hayder Khalissi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 225,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hayder Khalissi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 225,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Makhlook Singh",
    defaultValue: 224,
    tooltipData: {
      current: {
        Name: {
          value: "Makhlook Singh",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 224,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Makhlook Singh",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 224,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Monika Agnihotri",
    defaultValue: 224,
    tooltipData: {
      current: {
        Name: {
          value: "Monika Agnihotri",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 224,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Monika Agnihotri",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 224,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ankesh mehta",
    defaultValue: 224,
    tooltipData: {
      current: {
        Name: {
          value: "ankesh mehta",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 224,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ankesh mehta",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 224,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anidita chakraborty",
    defaultValue: 223,
    tooltipData: {
      current: {
        Name: {
          value: "anidita chakraborty",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 223,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anidita chakraborty",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 223,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ram Manoharan",
    defaultValue: 223,
    tooltipData: {
      current: {
        Name: {
          value: "Ram Manoharan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 223,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ram Manoharan",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 223,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aruna tadanki",
    defaultValue: 223,
    tooltipData: {
      current: {
        Name: {
          value: "aruna tadanki",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 223,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aruna tadanki",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 223,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sampath Mondeddula",
    defaultValue: 223,
    tooltipData: {
      current: {
        Name: {
          value: "Sampath Mondeddula",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 223,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sampath Mondeddula",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 223,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nayem Jhangir",
    defaultValue: 222,
    tooltipData: {
      current: {
        Name: {
          value: "Nayem Jhangir",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nayem Jhangir",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sirisha Earni",
    defaultValue: 222,
    tooltipData: {
      current: {
        Name: {
          value: "Sirisha Earni",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sirisha Earni",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saritha Machireddy",
    defaultValue: 222,
    tooltipData: {
      current: {
        Name: {
          value: "Saritha Machireddy",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saritha Machireddy",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tooba Jawwad",
    defaultValue: 222,
    tooltipData: {
      current: {
        Name: {
          value: "Tooba Jawwad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tooba Jawwad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Renu Singh",
    defaultValue: 222,
    tooltipData: {
      current: {
        Name: {
          value: "Renu Singh",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Renu Singh",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lakshmi Vellanki",
    defaultValue: 222,
    tooltipData: {
      current: {
        Name: {
          value: "Lakshmi Vellanki",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lakshmi Vellanki",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pardeep katta",
    defaultValue: 222,
    tooltipData: {
      current: {
        Name: {
          value: "pardeep katta",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pardeep katta",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 222,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ann Wawery",
    defaultValue: 221,
    tooltipData: {
      current: {
        Name: {
          value: "Ann Wawery",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 221,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ann Wawery",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 221,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sakina bushra",
    defaultValue: 221,
    tooltipData: {
      current: {
        Name: {
          value: "sakina bushra",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 221,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sakina bushra",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 221,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sylvester Dean",
    defaultValue: 221,
    tooltipData: {
      current: {
        Name: {
          value: "Sylvester Dean",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 221,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sylvester Dean",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 221,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Harpal Singh",
    defaultValue: 221,
    tooltipData: {
      current: {
        Name: {
          value: "Harpal Singh",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 221,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Harpal Singh",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 221,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shan Khan",
    defaultValue: 220,
    tooltipData: {
      current: {
        Name: {
          value: "Shan Khan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shan Khan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ayesha Ali",
    defaultValue: 220,
    tooltipData: {
      current: {
        Name: {
          value: "Ayesha Ali",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ayesha Ali",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yaamini Gutta",
    defaultValue: 220,
    tooltipData: {
      current: {
        Name: {
          value: "Yaamini Gutta",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yaamini Gutta",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lata ravi",
    defaultValue: 219,
    tooltipData: {
      current: {
        Name: {
          value: "lata ravi",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 219,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lata ravi",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 219,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "GIRISH dalbhanjan",
    defaultValue: 219,
    tooltipData: {
      current: {
        Name: {
          value: "GIRISH dalbhanjan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 219,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "GIRISH dalbhanjan",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 219,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "honey Jain",
    defaultValue: 218,
    tooltipData: {
      current: {
        Name: {
          value: "honey Jain",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "honey Jain",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samira Raza",
    defaultValue: 218,
    tooltipData: {
      current: {
        Name: {
          value: "Samira Raza",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samira Raza",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bahman Ahmadli",
    defaultValue: 218,
    tooltipData: {
      current: {
        Name: {
          value: "Bahman Ahmadli",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bahman Ahmadli",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rania idris",
    defaultValue: 218,
    tooltipData: {
      current: {
        Name: {
          value: "rania idris",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rania idris",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nida khan",
    defaultValue: 218,
    tooltipData: {
      current: {
        Name: {
          value: "nida khan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nida khan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naina dabas",
    defaultValue: 218,
    tooltipData: {
      current: {
        Name: {
          value: "naina dabas",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naina dabas",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 218,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pradeep rachakatla",
    defaultValue: 217,
    tooltipData: {
      current: {
        Name: {
          value: "pradeep rachakatla",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 217,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pradeep rachakatla",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 217,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pinku Murarka",
    defaultValue: 217,
    tooltipData: {
      current: {
        Name: {
          value: "Pinku Murarka",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 217,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pinku Murarka",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 217,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi Buddi",
    defaultValue: 217,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi Buddi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 217,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi Buddi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 217,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hina Kazmi",
    defaultValue: 217,
    tooltipData: {
      current: {
        Name: {
          value: "Hina Kazmi",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 217,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hina Kazmi",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 217,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Cecilia Joseph",
    defaultValue: 216,
    tooltipData: {
      current: {
        Name: {
          value: "Cecilia Joseph",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 216,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Cecilia Joseph",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 216,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "omar hayat",
    defaultValue: 216,
    tooltipData: {
      current: {
        Name: {
          value: "omar hayat",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 216,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "omar hayat",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 216,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sujan Ramamurthy",
    defaultValue: 216,
    tooltipData: {
      current: {
        Name: {
          value: "Sujan Ramamurthy",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 216,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sujan Ramamurthy",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 216,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "TEEJVEER SINGH",
    defaultValue: 215,
    tooltipData: {
      current: {
        Name: {
          value: "TEEJVEER SINGH",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 215,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "TEEJVEER SINGH",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 215,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dinesh dillibabu",
    defaultValue: 215,
    tooltipData: {
      current: {
        Name: {
          value: "dinesh dillibabu",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 215,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dinesh dillibabu",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 215,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Meriem Naamane",
    defaultValue: 214,
    tooltipData: {
      current: {
        Name: {
          value: "Meriem Naamane",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 214,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Meriem Naamane",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 214,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Varun Rajan",
    defaultValue: 214,
    tooltipData: {
      current: {
        Name: {
          value: "Varun Rajan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 214,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Varun Rajan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 214,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aashir Nadeem",
    defaultValue: 214,
    tooltipData: {
      current: {
        Name: {
          value: "Aashir Nadeem",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 214,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aashir Nadeem",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 214,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vamshi Dasyam",
    defaultValue: 214,
    tooltipData: {
      current: {
        Name: {
          value: "Vamshi Dasyam",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 214,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vamshi Dasyam",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 214,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rishi Shah",
    defaultValue: 213,
    tooltipData: {
      current: {
        Name: {
          value: "Rishi Shah",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rishi Shah",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sanjay Pasrija",
    defaultValue: 213,
    tooltipData: {
      current: {
        Name: {
          value: "Sanjay Pasrija",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sanjay Pasrija",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neelam Kumari",
    defaultValue: 213,
    tooltipData: {
      current: {
        Name: {
          value: "Neelam Kumari",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neelam Kumari",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vidhya Sridharan",
    defaultValue: 213,
    tooltipData: {
      current: {
        Name: {
          value: "Vidhya Sridharan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vidhya Sridharan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Farooq",
    defaultValue: 213,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Farooq",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Farooq",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "PRERIT SHAH",
    defaultValue: 213,
    tooltipData: {
      current: {
        Name: {
          value: "PRERIT SHAH",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "PRERIT SHAH",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "subbarao valisetty",
    defaultValue: 213,
    tooltipData: {
      current: {
        Name: {
          value: "subbarao valisetty",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "subbarao valisetty",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 213,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samer Saad",
    defaultValue: 212,
    tooltipData: {
      current: {
        Name: {
          value: "Samer Saad",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 212,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samer Saad",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 212,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gowthami Chikkala",
    defaultValue: 212,
    tooltipData: {
      current: {
        Name: {
          value: "Gowthami Chikkala",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 212,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gowthami Chikkala",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 212,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Biplab Mondal",
    defaultValue: 211,
    tooltipData: {
      current: {
        Name: {
          value: "Biplab Mondal",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 211,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Biplab Mondal",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 211,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aroos jebbbar",
    defaultValue: 211,
    tooltipData: {
      current: {
        Name: {
          value: "aroos jebbbar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 211,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aroos jebbbar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 211,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lana Vustilna",
    defaultValue: 211,
    tooltipData: {
      current: {
        Name: {
          value: "Lana Vustilna",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 211,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lana Vustilna",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 211,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkat Nr",
    defaultValue: 211,
    tooltipData: {
      current: {
        Name: {
          value: "Venkat Nr",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 211,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkat Nr",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 211,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tazria mahmood",
    defaultValue: 210,
    tooltipData: {
      current: {
        Name: {
          value: "tazria mahmood",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tazria mahmood",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajendra D",
    defaultValue: 210,
    tooltipData: {
      current: {
        Name: {
          value: "Rajendra D",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajendra D",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SANKARAN MURUGESAN",
    defaultValue: 210,
    tooltipData: {
      current: {
        Name: {
          value: "SANKARAN MURUGESAN",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SANKARAN MURUGESAN",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shahana Poly",
    defaultValue: 210,
    tooltipData: {
      current: {
        Name: {
          value: "Shahana Poly",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shahana Poly",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "srinivas akiri",
    defaultValue: 210,
    tooltipData: {
      current: {
        Name: {
          value: "srinivas akiri",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "srinivas akiri",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 210,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ahmed Hussain",
    defaultValue: 209,
    tooltipData: {
      current: {
        Name: {
          value: "Ahmed Hussain",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 209,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ahmed Hussain",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 209,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Genga Rajaram",
    defaultValue: 209,
    tooltipData: {
      current: {
        Name: {
          value: "Genga Rajaram",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 209,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Genga Rajaram",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 209,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zudith Crooks",
    defaultValue: 209,
    tooltipData: {
      current: {
        Name: {
          value: "Zudith Crooks",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 209,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zudith Crooks",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 209,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "srieeni s",
    defaultValue: 209,
    tooltipData: {
      current: {
        Name: {
          value: "srieeni s",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 209,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "srieeni s",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 209,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raja Raman",
    defaultValue: 208,
    tooltipData: {
      current: {
        Name: {
          value: "Raja Raman",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 208,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raja Raman",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 208,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Avilo Pereira",
    defaultValue: 208,
    tooltipData: {
      current: {
        Name: {
          value: "Avilo Pereira",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 208,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Avilo Pereira",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 208,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Himanshu K",
    defaultValue: 207,
    tooltipData: {
      current: {
        Name: {
          value: "Himanshu K",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 207,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Himanshu K",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 207,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Zaidi",
    defaultValue: 207,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 207,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Zaidi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 207,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Niharika Nandhida",
    defaultValue: 207,
    tooltipData: {
      current: {
        Name: {
          value: "Niharika Nandhida",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 207,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Niharika Nandhida",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 207,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SHUJA SIEAJ",
    defaultValue: 207,
    tooltipData: {
      current: {
        Name: {
          value: "SHUJA SIEAJ",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 207,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SHUJA SIEAJ",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 207,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vandana Khurana",
    defaultValue: 206,
    tooltipData: {
      current: {
        Name: {
          value: "Vandana Khurana",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 206,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vandana Khurana",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 206,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vinod bojja",
    defaultValue: 206,
    tooltipData: {
      current: {
        Name: {
          value: "vinod bojja",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 206,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vinod bojja",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 206,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Divya Anakala",
    defaultValue: 206,
    tooltipData: {
      current: {
        Name: {
          value: "Divya Anakala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 206,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Divya Anakala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 206,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nasu Ali",
    defaultValue: 206,
    tooltipData: {
      current: {
        Name: {
          value: "Nasu Ali",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 206,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nasu Ali",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 206,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Parimal Patel",
    defaultValue: 205,
    tooltipData: {
      current: {
        Name: {
          value: "Parimal Patel",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 205,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Parimal Patel",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 205,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Taran Singh",
    defaultValue: 205,
    tooltipData: {
      current: {
        Name: {
          value: "Taran Singh",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 205,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Taran Singh",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 205,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tanya Siddiqi",
    defaultValue: 205,
    tooltipData: {
      current: {
        Name: {
          value: "Tanya Siddiqi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 205,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tanya Siddiqi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 205,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "KAILASH MAKHIJA",
    defaultValue: 204,
    tooltipData: {
      current: {
        Name: {
          value: "KAILASH MAKHIJA",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 204,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "KAILASH MAKHIJA",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 204,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Veena Raman",
    defaultValue: 203,
    tooltipData: {
      current: {
        Name: {
          value: "Veena Raman",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 203,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Veena Raman",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 203,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rand Alsheikh",
    defaultValue: 202,
    tooltipData: {
      current: {
        Name: {
          value: "Rand Alsheikh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 202,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rand Alsheikh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 202,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syeda Rizvi",
    defaultValue: 202,
    tooltipData: {
      current: {
        Name: {
          value: "Syeda Rizvi",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 202,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syeda Rizvi",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 202,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zarin Ali",
    defaultValue: 202,
    tooltipData: {
      current: {
        Name: {
          value: "Zarin Ali",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 202,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zarin Ali",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 202,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ambika anantha",
    defaultValue: 202,
    tooltipData: {
      current: {
        Name: {
          value: "ambika anantha",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 202,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ambika anantha",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 202,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kalyan S",
    defaultValue: 201,
    tooltipData: {
      current: {
        Name: {
          value: "Kalyan S",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kalyan S",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "savina alkhalgi",
    defaultValue: 201,
    tooltipData: {
      current: {
        Name: {
          value: "savina alkhalgi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "savina alkhalgi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rupam Bora",
    defaultValue: 201,
    tooltipData: {
      current: {
        Name: {
          value: "Rupam Bora",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rupam Bora",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sheila a",
    defaultValue: 201,
    tooltipData: {
      current: {
        Name: {
          value: "sheila a",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sheila a",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "preeti jha",
    defaultValue: 201,
    tooltipData: {
      current: {
        Name: {
          value: "preeti jha",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "preeti jha",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aqeel Ahmed",
    defaultValue: 201,
    tooltipData: {
      current: {
        Name: {
          value: "Aqeel Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aqeel Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kalyani nampell",
    defaultValue: 201,
    tooltipData: {
      current: {
        Name: {
          value: "kalyani nampell",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kalyani nampell",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 201,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anitha Natarajan",
    defaultValue: 200,
    tooltipData: {
      current: {
        Name: {
          value: "Anitha Natarajan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anitha Natarajan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amita Borse",
    defaultValue: 200,
    tooltipData: {
      current: {
        Name: {
          value: "Amita Borse",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amita Borse",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bala Y",
    defaultValue: 200,
    tooltipData: {
      current: {
        Name: {
          value: "Bala Y",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bala Y",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Imran tahir",
    defaultValue: 200,
    tooltipData: {
      current: {
        Name: {
          value: "Imran tahir",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Imran tahir",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Khattak",
    defaultValue: 200,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Khattak",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Khattak",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 200,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sri Nama",
    defaultValue: 199,
    tooltipData: {
      current: {
        Name: {
          value: "Sri Nama",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 199,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sri Nama",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 199,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "swati rana",
    defaultValue: 199,
    tooltipData: {
      current: {
        Name: {
          value: "swati rana",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 199,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "swati rana",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 199,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "latha chiruvolu",
    defaultValue: 199,
    tooltipData: {
      current: {
        Name: {
          value: "latha chiruvolu",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 199,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "latha chiruvolu",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 199,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ashok mandula",
    defaultValue: 198,
    tooltipData: {
      current: {
        Name: {
          value: "ashok mandula",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 198,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ashok mandula",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 198,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Preet singh",
    defaultValue: 198,
    tooltipData: {
      current: {
        Name: {
          value: "Preet singh",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 198,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Preet singh",
          format: false,
        },
        TotalOrders: {
          value: 25,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 198,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumit Bansal",
    defaultValue: 198,
    tooltipData: {
      current: {
        Name: {
          value: "Sumit Bansal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 198,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumit Bansal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 198,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arvind Nair",
    defaultValue: 198,
    tooltipData: {
      current: {
        Name: {
          value: "Arvind Nair",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 198,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arvind Nair",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 198,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shameem Mohmand",
    defaultValue: 197,
    tooltipData: {
      current: {
        Name: {
          value: "Shameem Mohmand",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 197,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shameem Mohmand",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 197,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ranjan Dash",
    defaultValue: 197,
    tooltipData: {
      current: {
        Name: {
          value: "Ranjan Dash",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 197,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ranjan Dash",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 197,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swapnil Surse",
    defaultValue: 196,
    tooltipData: {
      current: {
        Name: {
          value: "Swapnil Surse",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 196,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swapnil Surse",
          format: false,
        },
        TotalOrders: {
          value: 20,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 196,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Senthil arumu",
    defaultValue: 196,
    tooltipData: {
      current: {
        Name: {
          value: "Senthil arumu",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 196,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Senthil arumu",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 196,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mehtab Amin",
    defaultValue: 196,
    tooltipData: {
      current: {
        Name: {
          value: "Mehtab Amin",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 196,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mehtab Amin",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 196,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manasi Sadhale",
    defaultValue: 195,
    tooltipData: {
      current: {
        Name: {
          value: "Manasi Sadhale",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manasi Sadhale",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aparna Perala",
    defaultValue: 195,
    tooltipData: {
      current: {
        Name: {
          value: "Aparna Perala",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aparna Perala",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkat Kundeti",
    defaultValue: 195,
    tooltipData: {
      current: {
        Name: {
          value: "Venkat Kundeti",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkat Kundeti",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sumati Sankpal",
    defaultValue: 195,
    tooltipData: {
      current: {
        Name: {
          value: "Sumati Sankpal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sumati Sankpal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tarannum Siddiqui",
    defaultValue: 195,
    tooltipData: {
      current: {
        Name: {
          value: "Tarannum Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tarannum Siddiqui",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anitha Bejawada",
    defaultValue: 195,
    tooltipData: {
      current: {
        Name: {
          value: "Anitha Bejawada",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anitha Bejawada",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saurav Garg",
    defaultValue: 195,
    tooltipData: {
      current: {
        Name: {
          value: "Saurav Garg",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saurav Garg",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 195,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jocelyn lee",
    defaultValue: 194,
    tooltipData: {
      current: {
        Name: {
          value: "jocelyn lee",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 194,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jocelyn lee",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 194,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hammad rasul",
    defaultValue: 194,
    tooltipData: {
      current: {
        Name: {
          value: "hammad rasul",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 194,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hammad rasul",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 194,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Veera Raghvan",
    defaultValue: 193,
    tooltipData: {
      current: {
        Name: {
          value: "Veera Raghvan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 193,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Veera Raghvan",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 193,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Surendar Sokkanathan",
    defaultValue: 193,
    tooltipData: {
      current: {
        Name: {
          value: "Surendar Sokkanathan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 193,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Surendar Sokkanathan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 193,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nick Abdul",
    defaultValue: 192,
    tooltipData: {
      current: {
        Name: {
          value: "Nick Abdul",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 192,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nick Abdul",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 192,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vishal M",
    defaultValue: 192,
    tooltipData: {
      current: {
        Name: {
          value: "Vishal M",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 192,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vishal M",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 192,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "zehra ali",
    defaultValue: 192,
    tooltipData: {
      current: {
        Name: {
          value: "zehra ali",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 192,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "zehra ali",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 192,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pramod Sambidi",
    defaultValue: 192,
    tooltipData: {
      current: {
        Name: {
          value: "Pramod Sambidi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 192,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pramod Sambidi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 192,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aseem Garg",
    defaultValue: 191,
    tooltipData: {
      current: {
        Name: {
          value: "Aseem Garg",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 191,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aseem Garg",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 191,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shilpa Srikumar",
    defaultValue: 191,
    tooltipData: {
      current: {
        Name: {
          value: "Shilpa Srikumar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 191,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shilpa Srikumar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 191,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anil rajagiri",
    defaultValue: 190,
    tooltipData: {
      current: {
        Name: {
          value: "anil rajagiri",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 190,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anil rajagiri",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 190,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "avinash uttha",
    defaultValue: 190,
    tooltipData: {
      current: {
        Name: {
          value: "avinash uttha",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 190,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "avinash uttha",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 190,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Afia Qureshi",
    defaultValue: 190,
    tooltipData: {
      current: {
        Name: {
          value: "Afia Qureshi",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 190,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Afia Qureshi",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 190,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Radha Sundaram",
    defaultValue: 189,
    tooltipData: {
      current: {
        Name: {
          value: "Radha Sundaram",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 189,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Radha Sundaram",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 189,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "beverly montinez",
    defaultValue: 189,
    tooltipData: {
      current: {
        Name: {
          value: "beverly montinez",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 189,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "beverly montinez",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 189,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Omnia Ahmed",
    defaultValue: 189,
    tooltipData: {
      current: {
        Name: {
          value: "Omnia Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 189,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Omnia Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 189,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mario Chay",
    defaultValue: 189,
    tooltipData: {
      current: {
        Name: {
          value: "Mario Chay",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 189,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mario Chay",
          format: false,
        },
        TotalOrders: {
          value: 22,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 189,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prabhakar Thota",
    defaultValue: 188,
    tooltipData: {
      current: {
        Name: {
          value: "Prabhakar Thota",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 188,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prabhakar Thota",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 188,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "HESHAM ELSWEISY",
    defaultValue: 188,
    tooltipData: {
      current: {
        Name: {
          value: "HESHAM ELSWEISY",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 188,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "HESHAM ELSWEISY",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 188,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramesh kummari",
    defaultValue: 188,
    tooltipData: {
      current: {
        Name: {
          value: "ramesh kummari",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 188,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramesh kummari",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 188,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gaja Rajesh",
    defaultValue: 187,
    tooltipData: {
      current: {
        Name: {
          value: "Gaja Rajesh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gaja Rajesh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Puneet Singh",
    defaultValue: 187,
    tooltipData: {
      current: {
        Name: {
          value: "Puneet Singh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Puneet Singh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amit Taneja",
    defaultValue: 187,
    tooltipData: {
      current: {
        Name: {
          value: "Amit Taneja",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amit Taneja",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zarmina Adil",
    defaultValue: 187,
    tooltipData: {
      current: {
        Name: {
          value: "Zarmina Adil",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zarmina Adil",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ranjith Kasi",
    defaultValue: 187,
    tooltipData: {
      current: {
        Name: {
          value: "Ranjith Kasi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ranjith Kasi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 187,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mariyam el",
    defaultValue: 186,
    tooltipData: {
      current: {
        Name: {
          value: "Mariyam el",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mariyam el",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ganesh meka",
    defaultValue: 186,
    tooltipData: {
      current: {
        Name: {
          value: "ganesh meka",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ganesh meka",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hanuman Gade",
    defaultValue: 186,
    tooltipData: {
      current: {
        Name: {
          value: "Hanuman Gade",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hanuman Gade",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Michael Lall",
    defaultValue: 186,
    tooltipData: {
      current: {
        Name: {
          value: "Michael Lall",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Michael Lall",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sAURABH Mathure",
    defaultValue: 186,
    tooltipData: {
      current: {
        Name: {
          value: "sAURABH Mathure",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sAURABH Mathure",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Avesh Dixit",
    defaultValue: 186,
    tooltipData: {
      current: {
        Name: {
          value: "Avesh Dixit",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Avesh Dixit",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 186,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saania Quaziobash",
    defaultValue: 185,
    tooltipData: {
      current: {
        Name: {
          value: "Saania Quaziobash",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saania Quaziobash",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bhaskar madugula",
    defaultValue: 185,
    tooltipData: {
      current: {
        Name: {
          value: "bhaskar madugula",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bhaskar madugula",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Adnan",
    defaultValue: 185,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Adnan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Adnan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "puneet arneja",
    defaultValue: 185,
    tooltipData: {
      current: {
        Name: {
          value: "puneet arneja",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "puneet arneja",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Maan Moualla",
    defaultValue: 185,
    tooltipData: {
      current: {
        Name: {
          value: "Maan Moualla",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Maan Moualla",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 185,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Veena Pramod",
    defaultValue: 184,
    tooltipData: {
      current: {
        Name: {
          value: "Veena Pramod",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 184,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Veena Pramod",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 184,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sarita Rajwada",
    defaultValue: 184,
    tooltipData: {
      current: {
        Name: {
          value: "Sarita Rajwada",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 184,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sarita Rajwada",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 184,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Malarvizhi Gnanavel",
    defaultValue: 184,
    tooltipData: {
      current: {
        Name: {
          value: "Malarvizhi Gnanavel",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 184,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Malarvizhi Gnanavel",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 184,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Husnain Tanoli",
    defaultValue: 184,
    tooltipData: {
      current: {
        Name: {
          value: "Husnain Tanoli",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 184,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Husnain Tanoli",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 184,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ajay m",
    defaultValue: 183,
    tooltipData: {
      current: {
        Name: {
          value: "Ajay m",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 183,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ajay m",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 183,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sirisha akkem",
    defaultValue: 183,
    tooltipData: {
      current: {
        Name: {
          value: "sirisha akkem",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 183,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sirisha akkem",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 183,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anuji james",
    defaultValue: 182,
    tooltipData: {
      current: {
        Name: {
          value: "anuji james",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 182,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anuji james",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 182,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rahila irfan",
    defaultValue: 182,
    tooltipData: {
      current: {
        Name: {
          value: "rahila irfan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 182,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rahila irfan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 182,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sridhar Aleta",
    defaultValue: 182,
    tooltipData: {
      current: {
        Name: {
          value: "Sridhar Aleta",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 182,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sridhar Aleta",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 182,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "praveen burra",
    defaultValue: 182,
    tooltipData: {
      current: {
        Name: {
          value: "praveen burra",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 182,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "praveen burra",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 182,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Akhter ul Mustafa",
    defaultValue: 181,
    tooltipData: {
      current: {
        Name: {
          value: "Akhter ul Mustafa",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 181,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Akhter ul Mustafa",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 181,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "visakh Kumar",
    defaultValue: 180,
    tooltipData: {
      current: {
        Name: {
          value: "visakh Kumar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "visakh Kumar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunil singh",
    defaultValue: 180,
    tooltipData: {
      current: {
        Name: {
          value: "Sunil singh",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunil singh",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Savan Shah",
    defaultValue: 180,
    tooltipData: {
      current: {
        Name: {
          value: "Savan Shah",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Savan Shah",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arulmary Rathinam",
    defaultValue: 180,
    tooltipData: {
      current: {
        Name: {
          value: "Arulmary Rathinam",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arulmary Rathinam",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Taha Abdullah",
    defaultValue: 180,
    tooltipData: {
      current: {
        Name: {
          value: "Taha Abdullah",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Taha Abdullah",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 180,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "yousuf mohammad",
    defaultValue: 179,
    tooltipData: {
      current: {
        Name: {
          value: "yousuf mohammad",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 179,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "yousuf mohammad",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 179,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prabhaker Thota",
    defaultValue: 179,
    tooltipData: {
      current: {
        Name: {
          value: "Prabhaker Thota",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 179,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prabhaker Thota",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 179,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nasir g",
    defaultValue: 179,
    tooltipData: {
      current: {
        Name: {
          value: "Nasir g",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 179,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nasir g",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 179,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhan RT",
    defaultValue: 178,
    tooltipData: {
      current: {
        Name: {
          value: "Madhan RT",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhan RT",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohan gouri",
    defaultValue: 178,
    tooltipData: {
      current: {
        Name: {
          value: "mohan gouri",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohan gouri",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Stella Mitchell",
    defaultValue: 178,
    tooltipData: {
      current: {
        Name: {
          value: "Stella Mitchell",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Stella Mitchell",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alina Ikram",
    defaultValue: 178,
    tooltipData: {
      current: {
        Name: {
          value: "Alina Ikram",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alina Ikram",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vipul Gupta",
    defaultValue: 178,
    tooltipData: {
      current: {
        Name: {
          value: "Vipul Gupta",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vipul Gupta",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhu Murugan",
    defaultValue: 178,
    tooltipData: {
      current: {
        Name: {
          value: "Madhu Murugan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhu Murugan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "avinash anumolu",
    defaultValue: 178,
    tooltipData: {
      current: {
        Name: {
          value: "avinash anumolu",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "avinash anumolu",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 178,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Karthik G",
    defaultValue: 177,
    tooltipData: {
      current: {
        Name: {
          value: "Karthik G",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Karthik G",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aman jaliaqqala",
    defaultValue: 177,
    tooltipData: {
      current: {
        Name: {
          value: "aman jaliaqqala",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aman jaliaqqala",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Michelle Khan",
    defaultValue: 177,
    tooltipData: {
      current: {
        Name: {
          value: "Michelle Khan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Michelle Khan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Smitha Alli",
    defaultValue: 177,
    tooltipData: {
      current: {
        Name: {
          value: "Smitha Alli",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Smitha Alli",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sree Pathak",
    defaultValue: 177,
    tooltipData: {
      current: {
        Name: {
          value: "Sree Pathak",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sree Pathak",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 177,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sri gunu",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "sri gunu",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sri gunu",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramya Madalapu",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "Ramya Madalapu",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramya Madalapu",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "DIA ADLAKHA",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "DIA ADLAKHA",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "DIA ADLAKHA",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "valerie farnandeg",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "valerie farnandeg",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "valerie farnandeg",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohammed rajas",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "mohammed rajas",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohammed rajas",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neha jain",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "neha jain",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neha jain",
          format: false,
        },
        TotalOrders: {
          value: 18,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "melody wilson",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "melody wilson",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "melody wilson",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajeshwari Munirajan",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "Rajeshwari Munirajan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajeshwari Munirajan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rakesh Jangada",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "Rakesh Jangada",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rakesh Jangada",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sabbir Ashraf",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "Sabbir Ashraf",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sabbir Ashraf",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "asif ali",
    defaultValue: 176,
    tooltipData: {
      current: {
        Name: {
          value: "asif ali",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "asif ali",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 176,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rupali shishulkar",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "rupali shishulkar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rupali shishulkar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prabu bontlin",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "prabu bontlin",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prabu bontlin",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Adnan Memon",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "Adnan Memon",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Adnan Memon",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sudev Vasudevan",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "Sudev Vasudevan",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sudev Vasudevan",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priti bhattad",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "Priti bhattad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priti bhattad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "satish kasani",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "satish kasani",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "satish kasani",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lalita Nilakantan",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "Lalita Nilakantan",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lalita Nilakantan",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Janki Patel",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "Janki Patel",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Janki Patel",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Santosh Borse",
    defaultValue: 175,
    tooltipData: {
      current: {
        Name: {
          value: "Santosh Borse",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Santosh Borse",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 175,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Diwakar Aggarwal",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "Diwakar Aggarwal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Diwakar Aggarwal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kIRAN vARANASI",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "kIRAN vARANASI",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kIRAN vARANASI",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ignatius Fernandez",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "Ignatius Fernandez",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ignatius Fernandez",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Atharva Barve",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "Atharva Barve",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Atharva Barve",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ATIQA RAJ",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "ATIQA RAJ",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ATIQA RAJ",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rashmi Sharma",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "Rashmi Sharma",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rashmi Sharma",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Keerthi Stephen",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "Keerthi Stephen",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Keerthi Stephen",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shrikanth Bhavsar",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "Shrikanth Bhavsar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shrikanth Bhavsar",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "maged eltom",
    defaultValue: 174,
    tooltipData: {
      current: {
        Name: {
          value: "maged eltom",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "maged eltom",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 174,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "madhi sekar",
    defaultValue: 173,
    tooltipData: {
      current: {
        Name: {
          value: "madhi sekar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 173,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "madhi sekar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 173,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yashika Gulati",
    defaultValue: 173,
    tooltipData: {
      current: {
        Name: {
          value: "Yashika Gulati",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 173,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yashika Gulati",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 173,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pankaj Nerkr",
    defaultValue: 173,
    tooltipData: {
      current: {
        Name: {
          value: "Pankaj Nerkr",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 173,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pankaj Nerkr",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 173,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prakash Nellor",
    defaultValue: 173,
    tooltipData: {
      current: {
        Name: {
          value: "Prakash Nellor",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 173,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prakash Nellor",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 173,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "roshni patel",
    defaultValue: 172,
    tooltipData: {
      current: {
        Name: {
          value: "roshni patel",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 172,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "roshni patel",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 172,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anil Rajan",
    defaultValue: 172,
    tooltipData: {
      current: {
        Name: {
          value: "Anil Rajan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 172,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anil Rajan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 172,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fatima Jaffer",
    defaultValue: 172,
    tooltipData: {
      current: {
        Name: {
          value: "Fatima Jaffer",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 172,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fatima Jaffer",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 172,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tirumaleswara Rampura",
    defaultValue: 172,
    tooltipData: {
      current: {
        Name: {
          value: "Tirumaleswara Rampura",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 172,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tirumaleswara Rampura",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 172,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yaqub Burkhalter",
    defaultValue: 171,
    tooltipData: {
      current: {
        Name: {
          value: "Yaqub Burkhalter",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 171,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yaqub Burkhalter",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 171,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sanduni razasingh",
    defaultValue: 171,
    tooltipData: {
      current: {
        Name: {
          value: "sanduni razasingh",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 171,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sanduni razasingh",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 171,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "reena pandh",
    defaultValue: 171,
    tooltipData: {
      current: {
        Name: {
          value: "reena pandh",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 171,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "reena pandh",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 171,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mehr Sheik",
    defaultValue: 171,
    tooltipData: {
      current: {
        Name: {
          value: "Mehr Sheik",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 171,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mehr Sheik",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 171,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prerit Bhatia",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "Prerit Bhatia",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prerit Bhatia",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Winith Misquitta",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "Winith Misquitta",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Winith Misquitta",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Masthan Andela",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "Masthan Andela",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Masthan Andela",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sailaja Kur",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "Sailaja Kur",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sailaja Kur",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tarek Gaber",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "Tarek Gaber",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tarek Gaber",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sanket kulkarni",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "sanket kulkarni",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sanket kulkarni",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rubiat Ferdous",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "Rubiat Ferdous",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rubiat Ferdous",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manoj kumar",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "manoj kumar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manoj kumar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sachin Jaiswal",
    defaultValue: 170,
    tooltipData: {
      current: {
        Name: {
          value: "Sachin Jaiswal",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sachin Jaiswal",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 170,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mayowa babalola",
    defaultValue: 169,
    tooltipData: {
      current: {
        Name: {
          value: "mayowa babalola",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mayowa babalola",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amna Maisam",
    defaultValue: 169,
    tooltipData: {
      current: {
        Name: {
          value: "Amna Maisam",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amna Maisam",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Podchanpally",
    defaultValue: 169,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Podchanpally",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Podchanpally",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "suresh gunturi",
    defaultValue: 169,
    tooltipData: {
      current: {
        Name: {
          value: "suresh gunturi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "suresh gunturi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shalbha Gupta",
    defaultValue: 169,
    tooltipData: {
      current: {
        Name: {
          value: "Shalbha Gupta",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shalbha Gupta",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manju Darsi",
    defaultValue: 169,
    tooltipData: {
      current: {
        Name: {
          value: "Manju Darsi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manju Darsi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shahida Badr",
    defaultValue: 169,
    tooltipData: {
      current: {
        Name: {
          value: "Shahida Badr",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shahida Badr",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arvind sambaraj",
    defaultValue: 169,
    tooltipData: {
      current: {
        Name: {
          value: "arvind sambaraj",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arvind sambaraj",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 169,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Troy Gethrie",
    defaultValue: 168,
    tooltipData: {
      current: {
        Name: {
          value: "Troy Gethrie",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Troy Gethrie",
          format: false,
        },
        TotalOrders: {
          value: 28,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ravi t",
    defaultValue: 168,
    tooltipData: {
      current: {
        Name: {
          value: "ravi t",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ravi t",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Murali Akku",
    defaultValue: 168,
    tooltipData: {
      current: {
        Name: {
          value: "Murali Akku",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Murali Akku",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ajay Kothi",
    defaultValue: 168,
    tooltipData: {
      current: {
        Name: {
          value: "Ajay Kothi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ajay Kothi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sachin jain",
    defaultValue: 168,
    tooltipData: {
      current: {
        Name: {
          value: "sachin jain",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sachin jain",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aparna K",
    defaultValue: 168,
    tooltipData: {
      current: {
        Name: {
          value: "Aparna K",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aparna K",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 168,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tan Santa",
    defaultValue: 167,
    tooltipData: {
      current: {
        Name: {
          value: "Tan Santa",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tan Santa",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Preeth Khanna",
    defaultValue: 167,
    tooltipData: {
      current: {
        Name: {
          value: "Preeth Khanna",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Preeth Khanna",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prabhat Behera",
    defaultValue: 167,
    tooltipData: {
      current: {
        Name: {
          value: "Prabhat Behera",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prabhat Behera",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ratan meshram",
    defaultValue: 167,
    tooltipData: {
      current: {
        Name: {
          value: "ratan meshram",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ratan meshram",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shiva Hamsagadda",
    defaultValue: 167,
    tooltipData: {
      current: {
        Name: {
          value: "Shiva Hamsagadda",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shiva Hamsagadda",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nivantha Rowel",
    defaultValue: 167,
    tooltipData: {
      current: {
        Name: {
          value: "Nivantha Rowel",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nivantha Rowel",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vaishali Bhuvela",
    defaultValue: 167,
    tooltipData: {
      current: {
        Name: {
          value: "Vaishali Bhuvela",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vaishali Bhuvela",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kanthi Bansal",
    defaultValue: 167,
    tooltipData: {
      current: {
        Name: {
          value: "Kanthi Bansal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kanthi Bansal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 167,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "MARISELA COLLINS",
    defaultValue: 166,
    tooltipData: {
      current: {
        Name: {
          value: "MARISELA COLLINS",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "MARISELA COLLINS",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vinod kavil",
    defaultValue: 166,
    tooltipData: {
      current: {
        Name: {
          value: "vinod kavil",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vinod kavil",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Asha Jayaraj",
    defaultValue: 166,
    tooltipData: {
      current: {
        Name: {
          value: "Asha Jayaraj",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Asha Jayaraj",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shan perias",
    defaultValue: 166,
    tooltipData: {
      current: {
        Name: {
          value: "shan perias",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shan perias",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anuraga Raghuraj",
    defaultValue: 166,
    tooltipData: {
      current: {
        Name: {
          value: "Anuraga Raghuraj",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anuraga Raghuraj",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohammad fatany",
    defaultValue: 166,
    tooltipData: {
      current: {
        Name: {
          value: "Mohammad fatany",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohammad fatany",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SHREEYA SHRESTHA",
    defaultValue: 166,
    tooltipData: {
      current: {
        Name: {
          value: "SHREEYA SHRESTHA",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SHREEYA SHRESTHA",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alex Pradeep",
    defaultValue: 165,
    tooltipData: {
      current: {
        Name: {
          value: "Alex Pradeep",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 165,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alex Pradeep",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 165,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dipali Hofe",
    defaultValue: 165,
    tooltipData: {
      current: {
        Name: {
          value: "Dipali Hofe",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 165,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dipali Hofe",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 165,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohanad Salhi",
    defaultValue: 165,
    tooltipData: {
      current: {
        Name: {
          value: "Mohanad Salhi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 165,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohanad Salhi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 165,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Bala",
    defaultValue: 165,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Bala",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 165,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Bala",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 165,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kalpana Rangaswamy",
    defaultValue: 164,
    tooltipData: {
      current: {
        Name: {
          value: "Kalpana Rangaswamy",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kalpana Rangaswamy",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dev Dixit",
    defaultValue: 164,
    tooltipData: {
      current: {
        Name: {
          value: "Dev Dixit",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dev Dixit",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shiv Kumar",
    defaultValue: 164,
    tooltipData: {
      current: {
        Name: {
          value: "Shiv Kumar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shiv Kumar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saravana Selvaraj",
    defaultValue: 164,
    tooltipData: {
      current: {
        Name: {
          value: "Saravana Selvaraj",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saravana Selvaraj",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Praneeth G",
    defaultValue: 164,
    tooltipData: {
      current: {
        Name: {
          value: "Praneeth G",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Praneeth G",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohamed Zaki",
    defaultValue: 164,
    tooltipData: {
      current: {
        Name: {
          value: "Mohamed Zaki",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohamed Zaki",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 164,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "parveen khan",
    defaultValue: 163,
    tooltipData: {
      current: {
        Name: {
          value: "parveen khan",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "parveen khan",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Siddhartha Jariwal",
    defaultValue: 163,
    tooltipData: {
      current: {
        Name: {
          value: "Siddhartha Jariwal",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Siddhartha Jariwal",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "swathi naidu",
    defaultValue: 163,
    tooltipData: {
      current: {
        Name: {
          value: "swathi naidu",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "swathi naidu",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anu Chari",
    defaultValue: 163,
    tooltipData: {
      current: {
        Name: {
          value: "Anu Chari",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anu Chari",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suresh Pandita",
    defaultValue: 163,
    tooltipData: {
      current: {
        Name: {
          value: "Suresh Pandita",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suresh Pandita",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nai Hamida",
    defaultValue: 163,
    tooltipData: {
      current: {
        Name: {
          value: "Nai Hamida",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nai Hamida",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 163,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kala sal",
    defaultValue: 162,
    tooltipData: {
      current: {
        Name: {
          value: "kala sal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kala sal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sharmeen hamit",
    defaultValue: 162,
    tooltipData: {
      current: {
        Name: {
          value: "sharmeen hamit",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sharmeen hamit",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Indu Manish",
    defaultValue: 162,
    tooltipData: {
      current: {
        Name: {
          value: "Indu Manish",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Indu Manish",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sai ganesh",
    defaultValue: 162,
    tooltipData: {
      current: {
        Name: {
          value: "sai ganesh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sai ganesh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shanmu Pattem",
    defaultValue: 162,
    tooltipData: {
      current: {
        Name: {
          value: "Shanmu Pattem",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shanmu Pattem",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dinesh Mohan",
    defaultValue: 162,
    tooltipData: {
      current: {
        Name: {
          value: "Dinesh Mohan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dinesh Mohan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 162,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manigandan Narasimhan",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "Manigandan Narasimhan",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manigandan Narasimhan",
          format: false,
        },
        TotalOrders: {
          value: 21,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dharmik Shah",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "Dharmik Shah",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dharmik Shah",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Preetham Sam",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "Preetham Sam",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Preetham Sam",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pawan Singh",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "Pawan Singh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pawan Singh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bukola azeez",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "bukola azeez",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bukola azeez",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arti div",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "arti div",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arti div",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gurdeep Othee",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "Gurdeep Othee",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gurdeep Othee",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anum Sattar",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "Anum Sattar",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anum Sattar",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fakeha ghazal",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "fakeha ghazal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fakeha ghazal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tina Jose",
    defaultValue: 161,
    tooltipData: {
      current: {
        Name: {
          value: "Tina Jose",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tina Jose",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 161,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Biren patkunaraj",
    defaultValue: 160,
    tooltipData: {
      current: {
        Name: {
          value: "Biren patkunaraj",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 160,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Biren patkunaraj",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 160,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "meenakshi venkat",
    defaultValue: 160,
    tooltipData: {
      current: {
        Name: {
          value: "meenakshi venkat",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 160,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "meenakshi venkat",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 160,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Stacey Nicholls",
    defaultValue: 160,
    tooltipData: {
      current: {
        Name: {
          value: "Stacey Nicholls",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 160,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Stacey Nicholls",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 160,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Talal Zeitouni",
    defaultValue: 159,
    tooltipData: {
      current: {
        Name: {
          value: "Talal Zeitouni",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 159,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Talal Zeitouni",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 159,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "VENU KUKKADAPU",
    defaultValue: 159,
    tooltipData: {
      current: {
        Name: {
          value: "VENU KUKKADAPU",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 159,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "VENU KUKKADAPU",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 159,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ranjita Sk",
    defaultValue: 158,
    tooltipData: {
      current: {
        Name: {
          value: "Ranjita Sk",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ranjita Sk",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mathew Dsouza",
    defaultValue: 158,
    tooltipData: {
      current: {
        Name: {
          value: "Mathew Dsouza",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mathew Dsouza",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nisha Cicil mendez",
    defaultValue: 158,
    tooltipData: {
      current: {
        Name: {
          value: "Nisha Cicil mendez",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nisha Cicil mendez",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ARPANA Mahesh",
    defaultValue: 158,
    tooltipData: {
      current: {
        Name: {
          value: "ARPANA Mahesh",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ARPANA Mahesh",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rahul ch",
    defaultValue: 158,
    tooltipData: {
      current: {
        Name: {
          value: "rahul ch",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rahul ch",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 158,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ranjit Sail",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "Ranjit Sail",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ranjit Sail",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ahmad Nadeem",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "Ahmad Nadeem",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ahmad Nadeem",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Koil Sargunam",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "Koil Sargunam",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Koil Sargunam",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rupa kota",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "rupa kota",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rupa kota",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepak Raj",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "Deepak Raj",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepak Raj",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "farah shazad",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "farah shazad",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "farah shazad",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sankar Gopal",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "Sankar Gopal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sankar Gopal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mathew Cherian",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "Mathew Cherian",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mathew Cherian",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi V",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi V",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi V",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raj bakshi",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "raj bakshi",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raj bakshi",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pavani Myla",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "Pavani Myla",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pavani Myla",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shadab zaidi",
    defaultValue: 157,
    tooltipData: {
      current: {
        Name: {
          value: "shadab zaidi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shadab zaidi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 157,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shoukat Ali",
    defaultValue: 156,
    tooltipData: {
      current: {
        Name: {
          value: "Shoukat Ali",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shoukat Ali",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kantilal lad",
    defaultValue: 156,
    tooltipData: {
      current: {
        Name: {
          value: "kantilal lad",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kantilal lad",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tanya patel",
    defaultValue: 156,
    tooltipData: {
      current: {
        Name: {
          value: "tanya patel",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tanya patel",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Phani Kumar",
    defaultValue: 156,
    tooltipData: {
      current: {
        Name: {
          value: "Phani Kumar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Phani Kumar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rupesh Shyam",
    defaultValue: 156,
    tooltipData: {
      current: {
        Name: {
          value: "Rupesh Shyam",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rupesh Shyam",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lakshmi chirumalla",
    defaultValue: 156,
    tooltipData: {
      current: {
        Name: {
          value: "lakshmi chirumalla",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lakshmi chirumalla",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 156,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prathima bandi",
    defaultValue: 155,
    tooltipData: {
      current: {
        Name: {
          value: "prathima bandi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prathima bandi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "priyanka kumari",
    defaultValue: 155,
    tooltipData: {
      current: {
        Name: {
          value: "priyanka kumari",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "priyanka kumari",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bashavisha Mehta",
    defaultValue: 155,
    tooltipData: {
      current: {
        Name: {
          value: "Bashavisha Mehta",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bashavisha Mehta",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Farooq Kirmani",
    defaultValue: 155,
    tooltipData: {
      current: {
        Name: {
          value: "Farooq Kirmani",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Farooq Kirmani",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pooja Jha",
    defaultValue: 155,
    tooltipData: {
      current: {
        Name: {
          value: "Pooja Jha",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pooja Jha",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yogendra Pandey",
    defaultValue: 155,
    tooltipData: {
      current: {
        Name: {
          value: "Yogendra Pandey",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yogendra Pandey",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 155,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "zahoor illahi",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "zahoor illahi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "zahoor illahi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hima Raju",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "Hima Raju",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hima Raju",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Probina Karmakar",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "Probina Karmakar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Probina Karmakar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Waseem Ahmad",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "Waseem Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Waseem Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swetha Anand",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "Swetha Anand",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swetha Anand",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shakeeb Mashoud",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "Shakeeb Mashoud",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shakeeb Mashoud",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Snehal Roge",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "Snehal Roge",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Snehal Roge",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anil Mathew",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "Anil Mathew",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anil Mathew",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sridhar Venuganti",
    defaultValue: 154,
    tooltipData: {
      current: {
        Name: {
          value: "Sridhar Venuganti",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sridhar Venuganti",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 154,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nandkumar Buxani",
    defaultValue: 153,
    tooltipData: {
      current: {
        Name: {
          value: "Nandkumar Buxani",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 153,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nandkumar Buxani",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 153,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ASMA REHAN",
    defaultValue: 153,
    tooltipData: {
      current: {
        Name: {
          value: "ASMA REHAN",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 153,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ASMA REHAN",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 153,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rakesh Kriplani",
    defaultValue: 153,
    tooltipData: {
      current: {
        Name: {
          value: "Rakesh Kriplani",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 153,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rakesh Kriplani",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 153,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hamza ahmad",
    defaultValue: 153,
    tooltipData: {
      current: {
        Name: {
          value: "hamza ahmad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 153,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hamza ahmad",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 153,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rakesh Raman",
    defaultValue: 152,
    tooltipData: {
      current: {
        Name: {
          value: "Rakesh Raman",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rakesh Raman",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SUNDAR ARUN",
    defaultValue: 152,
    tooltipData: {
      current: {
        Name: {
          value: "SUNDAR ARUN",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SUNDAR ARUN",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sriram Koripella",
    defaultValue: 152,
    tooltipData: {
      current: {
        Name: {
          value: "Sriram Koripella",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sriram Koripella",
          format: false,
        },
        TotalOrders: {
          value: 17,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deena shakya",
    defaultValue: 152,
    tooltipData: {
      current: {
        Name: {
          value: "Deena shakya",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deena shakya",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sabah hasan",
    defaultValue: 152,
    tooltipData: {
      current: {
        Name: {
          value: "sabah hasan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sabah hasan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sumaira amer",
    defaultValue: 152,
    tooltipData: {
      current: {
        Name: {
          value: "sumaira amer",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sumaira amer",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 152,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gaurav Vatra",
    defaultValue: 151,
    tooltipData: {
      current: {
        Name: {
          value: "Gaurav Vatra",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gaurav Vatra",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sree Gandikota",
    defaultValue: 151,
    tooltipData: {
      current: {
        Name: {
          value: "Sree Gandikota",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sree Gandikota",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "maria c",
    defaultValue: 151,
    tooltipData: {
      current: {
        Name: {
          value: "maria c",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "maria c",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajesh Doddi",
    defaultValue: 151,
    tooltipData: {
      current: {
        Name: {
          value: "Rajesh Doddi",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajesh Doddi",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ammar mandviwala",
    defaultValue: 151,
    tooltipData: {
      current: {
        Name: {
          value: "ammar mandviwala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ammar mandviwala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zeenat durrani",
    defaultValue: 151,
    tooltipData: {
      current: {
        Name: {
          value: "Zeenat durrani",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zeenat durrani",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 151,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arya Jha",
    defaultValue: 150,
    tooltipData: {
      current: {
        Name: {
          value: "Arya Jha",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arya Jha",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sriram A",
    defaultValue: 150,
    tooltipData: {
      current: {
        Name: {
          value: "Sriram A",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sriram A",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sawsan Hasan",
    defaultValue: 150,
    tooltipData: {
      current: {
        Name: {
          value: "Sawsan Hasan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sawsan Hasan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srikanth Chaparala",
    defaultValue: 150,
    tooltipData: {
      current: {
        Name: {
          value: "Srikanth Chaparala",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srikanth Chaparala",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gulekha Hussain",
    defaultValue: 150,
    tooltipData: {
      current: {
        Name: {
          value: "Gulekha Hussain",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gulekha Hussain",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 150,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bala Rengaswamy",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "Bala Rengaswamy",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bala Rengaswamy",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rizwana Shakir",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "Rizwana Shakir",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rizwana Shakir",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "suneela kommana",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "suneela kommana",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "suneela kommana",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mustafa khan",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "mustafa khan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mustafa khan",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Najme z",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "Najme z",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Najme z",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohinder bahara",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "mohinder bahara",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohinder bahara",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Roberto Banda",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "Roberto Banda",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Roberto Banda",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ram Thakur",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "Ram Thakur",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ram Thakur",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Eka Murni",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "Eka Murni",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Eka Murni",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Noor Ahmad",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "Noor Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Noor Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ram Murti",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "Ram Murti",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ram Murti",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vatsala rana",
    defaultValue: 149,
    tooltipData: {
      current: {
        Name: {
          value: "vatsala rana",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vatsala rana",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 149,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahindra Devarapalli",
    defaultValue: 148,
    tooltipData: {
      current: {
        Name: {
          value: "Mahindra Devarapalli",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahindra Devarapalli",
          format: false,
        },
        TotalOrders: {
          value: 14,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abdulah Adil",
    defaultValue: 148,
    tooltipData: {
      current: {
        Name: {
          value: "Abdulah Adil",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abdulah Adil",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Alam",
    defaultValue: 148,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Alam",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Alam",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bala krishna",
    defaultValue: 148,
    tooltipData: {
      current: {
        Name: {
          value: "bala krishna",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bala krishna",
          format: false,
        },
        TotalOrders: {
          value: 13,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "priti karsan",
    defaultValue: 148,
    tooltipData: {
      current: {
        Name: {
          value: "priti karsan",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "priti karsan",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Darren Bender",
    defaultValue: 148,
    tooltipData: {
      current: {
        Name: {
          value: "Darren Bender",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Darren Bender",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 148,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shailaja Manda",
    defaultValue: 147,
    tooltipData: {
      current: {
        Name: {
          value: "Shailaja Manda",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shailaja Manda",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sai K",
    defaultValue: 147,
    tooltipData: {
      current: {
        Name: {
          value: "Sai K",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sai K",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "animesh prasad",
    defaultValue: 147,
    tooltipData: {
      current: {
        Name: {
          value: "animesh prasad",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "animesh prasad",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abida Zafar",
    defaultValue: 147,
    tooltipData: {
      current: {
        Name: {
          value: "Abida Zafar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abida Zafar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "priyanka pendyala",
    defaultValue: 147,
    tooltipData: {
      current: {
        Name: {
          value: "priyanka pendyala",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "priyanka pendyala",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunita Kalse",
    defaultValue: 147,
    tooltipData: {
      current: {
        Name: {
          value: "Sunita Kalse",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunita Kalse",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 147,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kumar Natarajan",
    defaultValue: 146,
    tooltipData: {
      current: {
        Name: {
          value: "Kumar Natarajan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kumar Natarajan",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neha mathur",
    defaultValue: 146,
    tooltipData: {
      current: {
        Name: {
          value: "neha mathur",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neha mathur",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alisha Totai",
    defaultValue: 146,
    tooltipData: {
      current: {
        Name: {
          value: "Alisha Totai",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alisha Totai",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nithin M",
    defaultValue: 146,
    tooltipData: {
      current: {
        Name: {
          value: "Nithin M",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nithin M",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "gurudath kamath",
    defaultValue: 146,
    tooltipData: {
      current: {
        Name: {
          value: "gurudath kamath",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "gurudath kamath",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 146,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "aishwaryaa sundar",
    defaultValue: 145,
    tooltipData: {
      current: {
        Name: {
          value: "aishwaryaa sundar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "aishwaryaa sundar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sara shafi",
    defaultValue: 145,
    tooltipData: {
      current: {
        Name: {
          value: "sara shafi",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sara shafi",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saritha kun",
    defaultValue: 145,
    tooltipData: {
      current: {
        Name: {
          value: "saritha kun",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saritha kun",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manjit Banwait",
    defaultValue: 145,
    tooltipData: {
      current: {
        Name: {
          value: "Manjit Banwait",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manjit Banwait",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fatima Naeem",
    defaultValue: 145,
    tooltipData: {
      current: {
        Name: {
          value: "Fatima Naeem",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fatima Naeem",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 145,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "muhammad malik",
    defaultValue: 144,
    tooltipData: {
      current: {
        Name: {
          value: "muhammad malik",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 144,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "muhammad malik",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 144,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Umair Umar",
    defaultValue: 144,
    tooltipData: {
      current: {
        Name: {
          value: "Umair Umar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 144,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Umair Umar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 144,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yusuf Abbasi",
    defaultValue: 144,
    tooltipData: {
      current: {
        Name: {
          value: "Yusuf Abbasi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 144,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yusuf Abbasi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 144,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kalyani B",
    defaultValue: 144,
    tooltipData: {
      current: {
        Name: {
          value: "Kalyani B",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 144,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kalyani B",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 144,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ekneet Saini",
    defaultValue: 143,
    tooltipData: {
      current: {
        Name: {
          value: "Ekneet Saini",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ekneet Saini",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ebony kuju",
    defaultValue: 143,
    tooltipData: {
      current: {
        Name: {
          value: "ebony kuju",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ebony kuju",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manisha Singh",
    defaultValue: 143,
    tooltipData: {
      current: {
        Name: {
          value: "Manisha Singh",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manisha Singh",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Haren Thatola",
    defaultValue: 143,
    tooltipData: {
      current: {
        Name: {
          value: "Haren Thatola",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Haren Thatola",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aisha Sabir",
    defaultValue: 143,
    tooltipData: {
      current: {
        Name: {
          value: "Aisha Sabir",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aisha Sabir",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 143,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rajni T",
    defaultValue: 142,
    tooltipData: {
      current: {
        Name: {
          value: "Rajni T",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rajni T",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Geroge Issac",
    defaultValue: 142,
    tooltipData: {
      current: {
        Name: {
          value: "Geroge Issac",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Geroge Issac",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fikri Shihab",
    defaultValue: 142,
    tooltipData: {
      current: {
        Name: {
          value: "Fikri Shihab",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fikri Shihab",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dinesh perumadla",
    defaultValue: 142,
    tooltipData: {
      current: {
        Name: {
          value: "dinesh perumadla",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dinesh perumadla",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neeta Lathi",
    defaultValue: 142,
    tooltipData: {
      current: {
        Name: {
          value: "Neeta Lathi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neeta Lathi",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 142,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swapna Mulay",
    defaultValue: 141,
    tooltipData: {
      current: {
        Name: {
          value: "Swapna Mulay",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swapna Mulay",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "gagan kehar",
    defaultValue: 141,
    tooltipData: {
      current: {
        Name: {
          value: "gagan kehar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "gagan kehar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bibin Manayil",
    defaultValue: 141,
    tooltipData: {
      current: {
        Name: {
          value: "Bibin Manayil",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bibin Manayil",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "misha d",
    defaultValue: 141,
    tooltipData: {
      current: {
        Name: {
          value: "misha d",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "misha d",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "noman ahmed",
    defaultValue: 141,
    tooltipData: {
      current: {
        Name: {
          value: "noman ahmed",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "noman ahmed",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 141,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "GEETAnjali gade",
    defaultValue: 140,
    tooltipData: {
      current: {
        Name: {
          value: "GEETAnjali gade",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "GEETAnjali gade",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jasmine anklesaria",
    defaultValue: 140,
    tooltipData: {
      current: {
        Name: {
          value: "jasmine anklesaria",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jasmine anklesaria",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sowmya Nadiger",
    defaultValue: 140,
    tooltipData: {
      current: {
        Name: {
          value: "Sowmya Nadiger",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sowmya Nadiger",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Prasad",
    defaultValue: 140,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Prasad",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Prasad",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hasan Idrees",
    defaultValue: 140,
    tooltipData: {
      current: {
        Name: {
          value: "Hasan Idrees",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hasan Idrees",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 140,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Leena Mittal",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "Leena Mittal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Leena Mittal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "uma abburi",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "uma abburi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "uma abburi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sangeetha Baliah",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "Sangeetha Baliah",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sangeetha Baliah",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vaibhavi Kotnis",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "Vaibhavi Kotnis",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vaibhavi Kotnis",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Veera Alavala",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "Veera Alavala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Veera Alavala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mahesh Shetty",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "Mahesh Shetty",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mahesh Shetty",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jahnavi Bandla",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "Jahnavi Bandla",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jahnavi Bandla",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amrin Shaikh",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "Amrin Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amrin Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fatima Ballguen",
    defaultValue: 139,
    tooltipData: {
      current: {
        Name: {
          value: "Fatima Ballguen",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fatima Ballguen",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NayanKumar Amin",
    defaultValue: 138,
    tooltipData: {
      current: {
        Name: {
          value: "NayanKumar Amin",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NayanKumar Amin",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sri Panduranga",
    defaultValue: 138,
    tooltipData: {
      current: {
        Name: {
          value: "Sri Panduranga",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sri Panduranga",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ian Suares",
    defaultValue: 138,
    tooltipData: {
      current: {
        Name: {
          value: "Ian Suares",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ian Suares",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anita K",
    defaultValue: 138,
    tooltipData: {
      current: {
        Name: {
          value: "Anita K",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anita K",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Navneet Sekhon",
    defaultValue: 138,
    tooltipData: {
      current: {
        Name: {
          value: "Navneet Sekhon",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Navneet Sekhon",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ali kanchwala",
    defaultValue: 138,
    tooltipData: {
      current: {
        Name: {
          value: "ali kanchwala",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ali kanchwala",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 138,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Subash chandra",
    defaultValue: 137,
    tooltipData: {
      current: {
        Name: {
          value: "Subash chandra",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Subash chandra",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramakrishna raju",
    defaultValue: 137,
    tooltipData: {
      current: {
        Name: {
          value: "ramakrishna raju",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramakrishna raju",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pete Yazdi",
    defaultValue: 137,
    tooltipData: {
      current: {
        Name: {
          value: "Pete Yazdi",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pete Yazdi",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "khadija Mkondera",
    defaultValue: 137,
    tooltipData: {
      current: {
        Name: {
          value: "khadija Mkondera",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "khadija Mkondera",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nagalakshmi d",
    defaultValue: 137,
    tooltipData: {
      current: {
        Name: {
          value: "Nagalakshmi d",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nagalakshmi d",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kiramayi Chavvakula",
    defaultValue: 137,
    tooltipData: {
      current: {
        Name: {
          value: "Kiramayi Chavvakula",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kiramayi Chavvakula",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sumit kumar",
    defaultValue: 137,
    tooltipData: {
      current: {
        Name: {
          value: "sumit kumar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sumit kumar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kusum Kishore",
    defaultValue: 137,
    tooltipData: {
      current: {
        Name: {
          value: "Kusum Kishore",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kusum Kishore",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 137,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shafaq ahmed",
    defaultValue: 136,
    tooltipData: {
      current: {
        Name: {
          value: "shafaq ahmed",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shafaq ahmed",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mohan Raj",
    defaultValue: 136,
    tooltipData: {
      current: {
        Name: {
          value: "Mohan Raj",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mohan Raj",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Dinakar T",
    defaultValue: 136,
    tooltipData: {
      current: {
        Name: {
          value: "Dinakar T",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Dinakar T",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rishi Moghe",
    defaultValue: 136,
    tooltipData: {
      current: {
        Name: {
          value: "Rishi Moghe",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rishi Moghe",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sherine Abrahim",
    defaultValue: 136,
    tooltipData: {
      current: {
        Name: {
          value: "Sherine Abrahim",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sherine Abrahim",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priyanka Sharma",
    defaultValue: 136,
    tooltipData: {
      current: {
        Name: {
          value: "Priyanka Sharma",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priyanka Sharma",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nawabs Katy",
    defaultValue: 136,
    tooltipData: {
      current: {
        Name: {
          value: "Nawabs Katy",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nawabs Katy",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "abdul saleem",
    defaultValue: 136,
    tooltipData: {
      current: {
        Name: {
          value: "abdul saleem",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "abdul saleem",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 136,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jafar ahmed",
    defaultValue: 135,
    tooltipData: {
      current: {
        Name: {
          value: "jafar ahmed",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jafar ahmed",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "alyaa alzamani",
    defaultValue: 135,
    tooltipData: {
      current: {
        Name: {
          value: "alyaa alzamani",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "alyaa alzamani",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Krishna Gangisetty",
    defaultValue: 135,
    tooltipData: {
      current: {
        Name: {
          value: "Krishna Gangisetty",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Krishna Gangisetty",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Naim akmal",
    defaultValue: 135,
    tooltipData: {
      current: {
        Name: {
          value: "Naim akmal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Naim akmal",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chandra Lanka",
    defaultValue: 135,
    tooltipData: {
      current: {
        Name: {
          value: "Chandra Lanka",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chandra Lanka",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 135,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mahendi das",
    defaultValue: 134,
    tooltipData: {
      current: {
        Name: {
          value: "mahendi das",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mahendi das",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shilpa Ramachandra",
    defaultValue: 134,
    tooltipData: {
      current: {
        Name: {
          value: "Shilpa Ramachandra",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shilpa Ramachandra",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "VIKRAM P",
    defaultValue: 134,
    tooltipData: {
      current: {
        Name: {
          value: "VIKRAM P",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "VIKRAM P",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "asha lalani",
    defaultValue: 134,
    tooltipData: {
      current: {
        Name: {
          value: "asha lalani",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "asha lalani",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rachana Reddy",
    defaultValue: 134,
    tooltipData: {
      current: {
        Name: {
          value: "Rachana Reddy",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rachana Reddy",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anju mittal",
    defaultValue: 134,
    tooltipData: {
      current: {
        Name: {
          value: "anju mittal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anju mittal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sreeni Papana",
    defaultValue: 134,
    tooltipData: {
      current: {
        Name: {
          value: "Sreeni Papana",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sreeni Papana",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepankr Vaidy",
    defaultValue: 134,
    tooltipData: {
      current: {
        Name: {
          value: "Deepankr Vaidy",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepankr Vaidy",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 134,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gagan Bhardwaj",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "Gagan Bhardwaj",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gagan Bhardwaj",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arvind Nimma",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "Arvind Nimma",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arvind Nimma",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mariana S",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "Mariana S",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mariana S",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "walaa yousif",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "walaa yousif",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "walaa yousif",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srinu m",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "Srinu m",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srinu m",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pooja SRIVASTVA",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "pooja SRIVASTVA",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pooja SRIVASTVA",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shilpa Gujarathi",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "Shilpa Gujarathi",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shilpa Gujarathi",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Rizvi",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Rizvi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Rizvi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "wazid Faridi",
    defaultValue: 133,
    tooltipData: {
      current: {
        Name: {
          value: "wazid Faridi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "wazid Faridi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Iqbal Raza",
    defaultValue: 132,
    tooltipData: {
      current: {
        Name: {
          value: "Iqbal Raza",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Iqbal Raza",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kumaresh Tahkku",
    defaultValue: 132,
    tooltipData: {
      current: {
        Name: {
          value: "Kumaresh Tahkku",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kumaresh Tahkku",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "paraul garg",
    defaultValue: 132,
    tooltipData: {
      current: {
        Name: {
          value: "paraul garg",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "paraul garg",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Clarek Woodman",
    defaultValue: 132,
    tooltipData: {
      current: {
        Name: {
          value: "Clarek Woodman",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Clarek Woodman",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Feroza Ali",
    defaultValue: 132,
    tooltipData: {
      current: {
        Name: {
          value: "Feroza Ali",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Feroza Ali",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 132,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mridul Kalita",
    defaultValue: 131,
    tooltipData: {
      current: {
        Name: {
          value: "Mridul Kalita",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mridul Kalita",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "john kumar",
    defaultValue: 131,
    tooltipData: {
      current: {
        Name: {
          value: "john kumar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "john kumar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sirisha Bukka",
    defaultValue: 131,
    tooltipData: {
      current: {
        Name: {
          value: "Sirisha Bukka",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sirisha Bukka",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jemshed Mohammed",
    defaultValue: 131,
    tooltipData: {
      current: {
        Name: {
          value: "Jemshed Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jemshed Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suganya Mani",
    defaultValue: 131,
    tooltipData: {
      current: {
        Name: {
          value: "Suganya Mani",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suganya Mani",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 131,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "inam Shahid",
    defaultValue: 130,
    tooltipData: {
      current: {
        Name: {
          value: "inam Shahid",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "inam Shahid",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shubham shukla",
    defaultValue: 130,
    tooltipData: {
      current: {
        Name: {
          value: "shubham shukla",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shubham shukla",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nick Vads",
    defaultValue: 130,
    tooltipData: {
      current: {
        Name: {
          value: "Nick Vads",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nick Vads",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mallory P",
    defaultValue: 130,
    tooltipData: {
      current: {
        Name: {
          value: "Mallory P",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mallory P",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Asma Syeda",
    defaultValue: 130,
    tooltipData: {
      current: {
        Name: {
          value: "Asma Syeda",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Asma Syeda",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kj kj",
    defaultValue: 130,
    tooltipData: {
      current: {
        Name: {
          value: "kj kj",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kj kj",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aparna Shetty",
    defaultValue: 130,
    tooltipData: {
      current: {
        Name: {
          value: "Aparna Shetty",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aparna Shetty",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 130,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "John Abdul",
    defaultValue: 129,
    tooltipData: {
      current: {
        Name: {
          value: "John Abdul",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "John Abdul",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NEHA KAUR",
    defaultValue: 129,
    tooltipData: {
      current: {
        Name: {
          value: "NEHA KAUR",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NEHA KAUR",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "veena  venkatesh",
    defaultValue: 129,
    tooltipData: {
      current: {
        Name: {
          value: "veena  venkatesh",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "veena  venkatesh",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hengameh Oji",
    defaultValue: 129,
    tooltipData: {
      current: {
        Name: {
          value: "Hengameh Oji",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hengameh Oji",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "surekha gadi",
    defaultValue: 129,
    tooltipData: {
      current: {
        Name: {
          value: "surekha gadi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "surekha gadi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Inas Abutabra",
    defaultValue: 129,
    tooltipData: {
      current: {
        Name: {
          value: "Inas Abutabra",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Inas Abutabra",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kani manikandasamy",
    defaultValue: 129,
    tooltipData: {
      current: {
        Name: {
          value: "kani manikandasamy",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kani manikandasamy",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 129,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Albert Boland",
    defaultValue: 128,
    tooltipData: {
      current: {
        Name: {
          value: "Albert Boland",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 128,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Albert Boland",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 128,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rishi c",
    defaultValue: 128,
    tooltipData: {
      current: {
        Name: {
          value: "rishi c",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 128,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rishi c",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 128,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shilpa Mody",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "Shilpa Mody",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shilpa Mody",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shazia Arif",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "Shazia Arif",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shazia Arif",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "waseem zadi",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "waseem zadi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "waseem zadi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "chetan jindal",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "chetan jindal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "chetan jindal",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Indhira Regina",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "Indhira Regina",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Indhira Regina",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nuwanthi Maldini",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "Nuwanthi Maldini",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nuwanthi Maldini",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Vegunta",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Vegunta",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Vegunta",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Snehal Boke",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "Snehal Boke",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Snehal Boke",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pramodh MV",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "Pramodh MV",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pramodh MV",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jyoti pahwa",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "jyoti pahwa",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jyoti pahwa",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hema T",
    defaultValue: 127,
    tooltipData: {
      current: {
        Name: {
          value: "Hema T",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hema T",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 127,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suraj Dhakal",
    defaultValue: 126,
    tooltipData: {
      current: {
        Name: {
          value: "Suraj Dhakal",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suraj Dhakal",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "percy cleetes",
    defaultValue: 126,
    tooltipData: {
      current: {
        Name: {
          value: "percy cleetes",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "percy cleetes",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "parveen poola",
    defaultValue: 126,
    tooltipData: {
      current: {
        Name: {
          value: "parveen poola",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "parveen poola",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shaikh Rahman",
    defaultValue: 126,
    tooltipData: {
      current: {
        Name: {
          value: "Shaikh Rahman",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shaikh Rahman",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manisha joshi",
    defaultValue: 126,
    tooltipData: {
      current: {
        Name: {
          value: "manisha joshi",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manisha joshi",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Natasha Garg",
    defaultValue: 126,
    tooltipData: {
      current: {
        Name: {
          value: "Natasha Garg",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Natasha Garg",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 126,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vaishali Baviskar",
    defaultValue: 125,
    tooltipData: {
      current: {
        Name: {
          value: "Vaishali Baviskar",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 125,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vaishali Baviskar",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 125,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nazy mahmoudi",
    defaultValue: 125,
    tooltipData: {
      current: {
        Name: {
          value: "nazy mahmoudi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 125,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nazy mahmoudi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 125,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alex Bissland",
    defaultValue: 125,
    tooltipData: {
      current: {
        Name: {
          value: "Alex Bissland",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 125,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alex Bissland",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 125,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vaidehi Modekrutti",
    defaultValue: 125,
    tooltipData: {
      current: {
        Name: {
          value: "Vaidehi Modekrutti",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 125,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vaidehi Modekrutti",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 125,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Navi S",
    defaultValue: 124,
    tooltipData: {
      current: {
        Name: {
          value: "Navi S",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 124,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Navi S",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 124,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "day antony",
    defaultValue: 124,
    tooltipData: {
      current: {
        Name: {
          value: "day antony",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 124,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "day antony",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 124,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "barbara ad",
    defaultValue: 123,
    tooltipData: {
      current: {
        Name: {
          value: "barbara ad",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "barbara ad",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jacqueline nagoo",
    defaultValue: 123,
    tooltipData: {
      current: {
        Name: {
          value: "jacqueline nagoo",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jacqueline nagoo",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Demi Fasade",
    defaultValue: 123,
    tooltipData: {
      current: {
        Name: {
          value: "Demi Fasade",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Demi Fasade",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tony sesayyan",
    defaultValue: 123,
    tooltipData: {
      current: {
        Name: {
          value: "tony sesayyan",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tony sesayyan",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sayyeda razi",
    defaultValue: 123,
    tooltipData: {
      current: {
        Name: {
          value: "sayyeda razi",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sayyeda razi",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 123,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "elwis iyerson",
    defaultValue: 122,
    tooltipData: {
      current: {
        Name: {
          value: "elwis iyerson",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "elwis iyerson",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Satya Kuntum",
    defaultValue: 122,
    tooltipData: {
      current: {
        Name: {
          value: "Satya Kuntum",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Satya Kuntum",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jay Mishra",
    defaultValue: 122,
    tooltipData: {
      current: {
        Name: {
          value: "Jay Mishra",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jay Mishra",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sandeep Menon",
    defaultValue: 122,
    tooltipData: {
      current: {
        Name: {
          value: "Sandeep Menon",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sandeep Menon",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "harjeet rai",
    defaultValue: 122,
    tooltipData: {
      current: {
        Name: {
          value: "harjeet rai",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "harjeet rai",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samera Godil",
    defaultValue: 122,
    tooltipData: {
      current: {
        Name: {
          value: "Samera Godil",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samera Godil",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 122,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vikram ghikyaala",
    defaultValue: 121,
    tooltipData: {
      current: {
        Name: {
          value: "vikram ghikyaala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vikram ghikyaala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venu Reddy",
    defaultValue: 121,
    tooltipData: {
      current: {
        Name: {
          value: "Venu Reddy",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venu Reddy",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priya Mishra",
    defaultValue: 121,
    tooltipData: {
      current: {
        Name: {
          value: "Priya Mishra",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priya Mishra",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sharan Dsouza",
    defaultValue: 121,
    tooltipData: {
      current: {
        Name: {
          value: "Sharan Dsouza",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sharan Dsouza",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vijay Ravalli",
    defaultValue: 121,
    tooltipData: {
      current: {
        Name: {
          value: "Vijay Ravalli",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vijay Ravalli",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhargava Devineni",
    defaultValue: 121,
    tooltipData: {
      current: {
        Name: {
          value: "Bhargava Devineni",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhargava Devineni",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 121,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Krishna Trisal",
    defaultValue: 120,
    tooltipData: {
      current: {
        Name: {
          value: "Krishna Trisal",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Krishna Trisal",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ajay seelamneni",
    defaultValue: 120,
    tooltipData: {
      current: {
        Name: {
          value: "ajay seelamneni",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ajay seelamneni",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Danish Hussain",
    defaultValue: 120,
    tooltipData: {
      current: {
        Name: {
          value: "Danish Hussain",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Danish Hussain",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sana Iqban",
    defaultValue: 120,
    tooltipData: {
      current: {
        Name: {
          value: "Sana Iqban",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sana Iqban",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amod Manjreker",
    defaultValue: 120,
    tooltipData: {
      current: {
        Name: {
          value: "Amod Manjreker",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amod Manjreker",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kakoli goldar",
    defaultValue: 120,
    tooltipData: {
      current: {
        Name: {
          value: "kakoli goldar",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kakoli goldar",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 120,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Akhi Raj",
    defaultValue: 119,
    tooltipData: {
      current: {
        Name: {
          value: "Akhi Raj",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Akhi Raj",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aisha Aman",
    defaultValue: 119,
    tooltipData: {
      current: {
        Name: {
          value: "Aisha Aman",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aisha Aman",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kavita Karri",
    defaultValue: 119,
    tooltipData: {
      current: {
        Name: {
          value: "Kavita Karri",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kavita Karri",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramana vithala",
    defaultValue: 119,
    tooltipData: {
      current: {
        Name: {
          value: "ramana vithala",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramana vithala",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "venkata m",
    defaultValue: 119,
    tooltipData: {
      current: {
        Name: {
          value: "venkata m",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "venkata m",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 119,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashish Madan",
    defaultValue: 118,
    tooltipData: {
      current: {
        Name: {
          value: "Ashish Madan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashish Madan",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Nazeer",
    defaultValue: 118,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Nazeer",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Nazeer",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "yasdani shaik",
    defaultValue: 118,
    tooltipData: {
      current: {
        Name: {
          value: "yasdani shaik",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "yasdani shaik",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "niti raj",
    defaultValue: 118,
    tooltipData: {
      current: {
        Name: {
          value: "niti raj",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "niti raj",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shailaja reddy",
    defaultValue: 118,
    tooltipData: {
      current: {
        Name: {
          value: "shailaja reddy",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shailaja reddy",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Radhika Reddy",
    defaultValue: 118,
    tooltipData: {
      current: {
        Name: {
          value: "Radhika Reddy",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Radhika Reddy",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ZEESHAN ALI",
    defaultValue: 118,
    tooltipData: {
      current: {
        Name: {
          value: "ZEESHAN ALI",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ZEESHAN ALI",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lily aboo",
    defaultValue: 118,
    tooltipData: {
      current: {
        Name: {
          value: "lily aboo",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lily aboo",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 118,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lak hanu",
    defaultValue: 117,
    tooltipData: {
      current: {
        Name: {
          value: "lak hanu",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lak hanu",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Laxmi Bhat",
    defaultValue: 117,
    tooltipData: {
      current: {
        Name: {
          value: "Laxmi Bhat",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Laxmi Bhat",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kashan Jamal",
    defaultValue: 117,
    tooltipData: {
      current: {
        Name: {
          value: "Kashan Jamal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kashan Jamal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Puja W",
    defaultValue: 117,
    tooltipData: {
      current: {
        Name: {
          value: "Puja W",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Puja W",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suchatra Menon",
    defaultValue: 117,
    tooltipData: {
      current: {
        Name: {
          value: "Suchatra Menon",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suchatra Menon",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Majeed Sultan",
    defaultValue: 117,
    tooltipData: {
      current: {
        Name: {
          value: "Majeed Sultan",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Majeed Sultan",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ganesh Neupane",
    defaultValue: 117,
    tooltipData: {
      current: {
        Name: {
          value: "Ganesh Neupane",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ganesh Neupane",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Uday D",
    defaultValue: 117,
    tooltipData: {
      current: {
        Name: {
          value: "Uday D",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Uday D",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neha sohani",
    defaultValue: 116,
    tooltipData: {
      current: {
        Name: {
          value: "Neha sohani",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neha sohani",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sarah M",
    defaultValue: 116,
    tooltipData: {
      current: {
        Name: {
          value: "Sarah M",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sarah M",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ankit Chadha",
    defaultValue: 116,
    tooltipData: {
      current: {
        Name: {
          value: "Ankit Chadha",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ankit Chadha",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tanya Noorani",
    defaultValue: 116,
    tooltipData: {
      current: {
        Name: {
          value: "Tanya Noorani",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tanya Noorani",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sudha Vangaveti",
    defaultValue: 116,
    tooltipData: {
      current: {
        Name: {
          value: "Sudha Vangaveti",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sudha Vangaveti",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sai v",
    defaultValue: 116,
    tooltipData: {
      current: {
        Name: {
          value: "Sai v",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sai v",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shahzad khan",
    defaultValue: 116,
    tooltipData: {
      current: {
        Name: {
          value: "shahzad khan",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shahzad khan",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 116,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shilpa nistane",
    defaultValue: 115,
    tooltipData: {
      current: {
        Name: {
          value: "shilpa nistane",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shilpa nistane",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prasanna Rakhe",
    defaultValue: 115,
    tooltipData: {
      current: {
        Name: {
          value: "Prasanna Rakhe",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prasanna Rakhe",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kingshuk ch",
    defaultValue: 115,
    tooltipData: {
      current: {
        Name: {
          value: "kingshuk ch",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kingshuk ch",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sandhya Kttegummula",
    defaultValue: 115,
    tooltipData: {
      current: {
        Name: {
          value: "Sandhya Kttegummula",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sandhya Kttegummula",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ram mopuri",
    defaultValue: 115,
    tooltipData: {
      current: {
        Name: {
          value: "ram mopuri",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ram mopuri",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abul Mohammed",
    defaultValue: 115,
    tooltipData: {
      current: {
        Name: {
          value: "Abul Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abul Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rebhy Abodev",
    defaultValue: 115,
    tooltipData: {
      current: {
        Name: {
          value: "Rebhy Abodev",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rebhy Abodev",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 115,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SRINIVAS PITLA",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "SRINIVAS PITLA",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SRINIVAS PITLA",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinh Nguyen",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "Vinh Nguyen",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinh Nguyen",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raghuram Narasingu",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "Raghuram Narasingu",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raghuram Narasingu",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kothri Kothari",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "Kothri Kothari",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kothri Kothari",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lobna Nassar",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "Lobna Nassar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lobna Nassar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "DANESH PALANISAMY",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "DANESH PALANISAMY",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "DANESH PALANISAMY",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "arambula genoveva",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "arambula genoveva",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "arambula genoveva",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anujay Kapoor",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "Anujay Kapoor",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anujay Kapoor",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yashwant Bandreddi",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "Yashwant Bandreddi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yashwant Bandreddi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sandeep anandesh",
    defaultValue: 114,
    tooltipData: {
      current: {
        Name: {
          value: "sandeep anandesh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sandeep anandesh",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 114,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shamim Ahmad",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "Shamim Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shamim Ahmad",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "swapna patnala",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "swapna patnala",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "swapna patnala",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "maha matshar",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "maha matshar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "maha matshar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Omer Ahmed",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "Omer Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Omer Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shana Parikh",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "Shana Parikh",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shana Parikh",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sunita gupta",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "sunita gupta",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sunita gupta",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manisha rathot",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "manisha rathot",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manisha rathot",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jayapal Ruddy",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "Jayapal Ruddy",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jayapal Ruddy",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suresh Patnam",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "Suresh Patnam",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suresh Patnam",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "parimll maity",
    defaultValue: 113,
    tooltipData: {
      current: {
        Name: {
          value: "parimll maity",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "parimll maity",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 113,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sarita elati",
    defaultValue: 112,
    tooltipData: {
      current: {
        Name: {
          value: "sarita elati",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sarita elati",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Swati Joshi",
    defaultValue: 112,
    tooltipData: {
      current: {
        Name: {
          value: "Swati Joshi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Swati Joshi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Girish Pawar",
    defaultValue: 112,
    tooltipData: {
      current: {
        Name: {
          value: "Girish Pawar",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Girish Pawar",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Donald Cooper",
    defaultValue: 112,
    tooltipData: {
      current: {
        Name: {
          value: "Donald Cooper",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Donald Cooper",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "niruban govindasamy",
    defaultValue: 112,
    tooltipData: {
      current: {
        Name: {
          value: "niruban govindasamy",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "niruban govindasamy",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Snehal halade",
    defaultValue: 112,
    tooltipData: {
      current: {
        Name: {
          value: "Snehal halade",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Snehal halade",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 112,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rizwana mohand",
    defaultValue: 111,
    tooltipData: {
      current: {
        Name: {
          value: "rizwana mohand",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rizwana mohand",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhumi Desai",
    defaultValue: 111,
    tooltipData: {
      current: {
        Name: {
          value: "Bhumi Desai",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhumi Desai",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arun Daram",
    defaultValue: 111,
    tooltipData: {
      current: {
        Name: {
          value: "Arun Daram",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arun Daram",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ayesha Doman",
    defaultValue: 111,
    tooltipData: {
      current: {
        Name: {
          value: "Ayesha Doman",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ayesha Doman",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "thomas dcosta",
    defaultValue: 111,
    tooltipData: {
      current: {
        Name: {
          value: "thomas dcosta",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "thomas dcosta",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "michelle khan",
    defaultValue: 111,
    tooltipData: {
      current: {
        Name: {
          value: "michelle khan",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "michelle khan",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Beniel Geevarghese",
    defaultValue: 111,
    tooltipData: {
      current: {
        Name: {
          value: "Beniel Geevarghese",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Beniel Geevarghese",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sowmya Reddy",
    defaultValue: 111,
    tooltipData: {
      current: {
        Name: {
          value: "Sowmya Reddy",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sowmya Reddy",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 111,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shahid Shamsu",
    defaultValue: 110,
    tooltipData: {
      current: {
        Name: {
          value: "Shahid Shamsu",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shahid Shamsu",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Muhammad Khan",
    defaultValue: 110,
    tooltipData: {
      current: {
        Name: {
          value: "Muhammad Khan",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Muhammad Khan",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saba Faisal",
    defaultValue: 110,
    tooltipData: {
      current: {
        Name: {
          value: "Saba Faisal",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saba Faisal",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "roli sangal",
    defaultValue: 110,
    tooltipData: {
      current: {
        Name: {
          value: "roli sangal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "roli sangal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rajesh yadav",
    defaultValue: 110,
    tooltipData: {
      current: {
        Name: {
          value: "rajesh yadav",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rajesh yadav",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fatima pirzata",
    defaultValue: 110,
    tooltipData: {
      current: {
        Name: {
          value: "fatima pirzata",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fatima pirzata",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "REETA SHENOY",
    defaultValue: 110,
    tooltipData: {
      current: {
        Name: {
          value: "REETA SHENOY",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "REETA SHENOY",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "muhammad umar",
    defaultValue: 110,
    tooltipData: {
      current: {
        Name: {
          value: "muhammad umar",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "muhammad umar",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 110,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Antony A",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "Antony A",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Antony A",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abdul Basit",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "Abdul Basit",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abdul Basit",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Devina Kohli",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "Devina Kohli",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Devina Kohli",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pranav upadhyaya",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "pranav upadhyaya",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pranav upadhyaya",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Avinash Peterraj",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "Avinash Peterraj",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Avinash Peterraj",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Madhuri Chivukula",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "Madhuri Chivukula",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Madhuri Chivukula",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "snehal attarde",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "snehal attarde",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "snehal attarde",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Imran Sakina",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "Imran Sakina",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Imran Sakina",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nandan Khurd",
    defaultValue: 109,
    tooltipData: {
      current: {
        Name: {
          value: "Nandan Khurd",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nandan Khurd",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 109,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "swati sri",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "swati sri",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "swati sri",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohsin Zaffar",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "mohsin Zaffar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohsin Zaffar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohammad Ali",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "mohammad Ali",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohammad Ali",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ghuapriya Sivakumar",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "Ghuapriya Sivakumar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ghuapriya Sivakumar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manisha Umbarje",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "Manisha Umbarje",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manisha Umbarje",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Noel Darma",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "Noel Darma",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Noel Darma",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "suman patibanda",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "suman patibanda",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "suman patibanda",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anas Haimoud",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "Anas Haimoud",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anas Haimoud",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "uzma aleem",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "uzma aleem",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "uzma aleem",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Varun Verma",
    defaultValue: 108,
    tooltipData: {
      current: {
        Name: {
          value: "Varun Verma",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Varun Verma",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 108,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Roopa Kamath",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Roopa Kamath",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Roopa Kamath",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "fauzia marchaant",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "fauzia marchaant",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "fauzia marchaant",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raam Thangaraj",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Raam Thangaraj",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raam Thangaraj",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkat P",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Venkat P",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkat P",
          format: false,
        },
        TotalOrders: {
          value: 12,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Noor Ibraheem",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Noor Ibraheem",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Noor Ibraheem",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pravina Patel",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Pravina Patel",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pravina Patel",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ayein pyone",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "ayein pyone",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ayein pyone",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "wanda baghel",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "wanda baghel",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "wanda baghel",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srinivas Rao",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Srinivas Rao",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srinivas Rao",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nikhil P",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Nikhil P",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nikhil P",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tharindu Hirantha",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Tharindu Hirantha",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tharindu Hirantha",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Seema Ahmed",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Seema Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Seema Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tania shaikh",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "tania shaikh",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tania shaikh",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "avi pal",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "avi pal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "avi pal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priya Kothari",
    defaultValue: 107,
    tooltipData: {
      current: {
        Name: {
          value: "Priya Kothari",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priya Kothari",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 107,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ravi H",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "Ravi H",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ravi H",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "krishnan narayanan",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "krishnan narayanan",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "krishnan narayanan",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Maigaly cruz",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "Maigaly cruz",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Maigaly cruz",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gayathri kalimuthu",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "Gayathri kalimuthu",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gayathri kalimuthu",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepika Tukkai",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "Deepika Tukkai",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepika Tukkai",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anjali Kumar",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "Anjali Kumar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anjali Kumar",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Musab Amin",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "Musab Amin",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Musab Amin",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rakesh Kotta",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "Rakesh Kotta",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rakesh Kotta",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "richa rathore",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "richa rathore",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "richa rathore",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "reddy rajitha",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "reddy rajitha",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "reddy rajitha",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Akash Shakunala",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "Akash Shakunala",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Akash Shakunala",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ravi patel",
    defaultValue: 106,
    tooltipData: {
      current: {
        Name: {
          value: "ravi patel",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ravi patel",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 106,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "PullaReddy J",
    defaultValue: 105,
    tooltipData: {
      current: {
        Name: {
          value: "PullaReddy J",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "PullaReddy J",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pankaj Mani",
    defaultValue: 105,
    tooltipData: {
      current: {
        Name: {
          value: "Pankaj Mani",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pankaj Mani",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Stuti Pal",
    defaultValue: 105,
    tooltipData: {
      current: {
        Name: {
          value: "Stuti Pal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Stuti Pal",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "lenarad trice",
    defaultValue: 105,
    tooltipData: {
      current: {
        Name: {
          value: "lenarad trice",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "lenarad trice",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jyoti Wagle",
    defaultValue: 105,
    tooltipData: {
      current: {
        Name: {
          value: "Jyoti Wagle",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jyoti Wagle",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Balaji Gopal",
    defaultValue: 105,
    tooltipData: {
      current: {
        Name: {
          value: "Balaji Gopal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Balaji Gopal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Asher Imam",
    defaultValue: 105,
    tooltipData: {
      current: {
        Name: {
          value: "Asher Imam",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Asher Imam",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 105,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chandra K",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "Chandra K",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chandra K",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Thanigaivel P",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "Thanigaivel P",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Thanigaivel P",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Denson Davis",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "Denson Davis",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Denson Davis",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Siba Srinavasan",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "Siba Srinavasan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Siba Srinavasan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shruti khare",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "shruti khare",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shruti khare",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raja palani",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "raja palani",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raja palani",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Leena Nanaware",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "Leena Nanaware",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Leena Nanaware",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "basil abraham",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "basil abraham",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "basil abraham",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "RUSHIKESH GUNDEWAR",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "RUSHIKESH GUNDEWAR",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "RUSHIKESH GUNDEWAR",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tehseen khan",
    defaultValue: 104,
    tooltipData: {
      current: {
        Name: {
          value: "tehseen khan",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tehseen khan",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 104,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "marry hasnani",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "marry hasnani",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "marry hasnani",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jiny Joseph",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "jiny Joseph",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jiny Joseph",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ravi nimmagadda",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "ravi nimmagadda",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ravi nimmagadda",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amit Diwakar",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "Amit Diwakar",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amit Diwakar",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manzoor Shaikh",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "Manzoor Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manzoor Shaikh",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shakeeb Mohiuddin",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "Shakeeb Mohiuddin",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shakeeb Mohiuddin",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Krishna Narayana",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "Krishna Narayana",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Krishna Narayana",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mouni Bavana",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "Mouni Bavana",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mouni Bavana",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jyothi kanderao",
    defaultValue: 103,
    tooltipData: {
      current: {
        Name: {
          value: "Jyothi kanderao",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jyothi kanderao",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 103,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amanda Avila",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "Amanda Avila",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amanda Avila",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hira rizvi",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "hira rizvi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hira rizvi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arif Rahmam",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "Arif Rahmam",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arif Rahmam",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vijay raju",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "vijay raju",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vijay raju",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mona bhai",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "mona bhai",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mona bhai",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saman asghar",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "saman asghar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saman asghar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Afshan Waqas",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "Afshan Waqas",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Afshan Waqas",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramesh Subbiah",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "Ramesh Subbiah",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramesh Subbiah",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sandeep Rout",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "Sandeep Rout",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sandeep Rout",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Javed Salim",
    defaultValue: 102,
    tooltipData: {
      current: {
        Name: {
          value: "Javed Salim",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Javed Salim",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 102,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "geetha sri",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "geetha sri",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "geetha sri",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sara Noahn",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "sara Noahn",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sara Noahn",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neelam jain",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "neelam jain",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neelam jain",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "palvi kulkarni",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "palvi kulkarni",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "palvi kulkarni",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raj bhat",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "raj bhat",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raj bhat",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Brad Chadda",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "Brad Chadda",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Brad Chadda",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "yoga mehra",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "yoga mehra",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "yoga mehra",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saad Shams",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "Saad Shams",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saad Shams",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mohammed ansari",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "mohammed ansari",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mohammed ansari",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "manmohan bucchammolla",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "manmohan bucchammolla",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "manmohan bucchammolla",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rishabh Shrivastava",
    defaultValue: 101,
    tooltipData: {
      current: {
        Name: {
          value: "Rishabh Shrivastava",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rishabh Shrivastava",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 101,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "PANKAJ DUREJA",
    defaultValue: 100,
    tooltipData: {
      current: {
        Name: {
          value: "PANKAJ DUREJA",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "PANKAJ DUREJA",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bhumi Pandya",
    defaultValue: 100,
    tooltipData: {
      current: {
        Name: {
          value: "Bhumi Pandya",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bhumi Pandya",
          format: false,
        },
        TotalOrders: {
          value: 16,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zoha Gulamali",
    defaultValue: 100,
    tooltipData: {
      current: {
        Name: {
          value: "Zoha Gulamali",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zoha Gulamali",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nauman Qadeer",
    defaultValue: 100,
    tooltipData: {
      current: {
        Name: {
          value: "Nauman Qadeer",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nauman Qadeer",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raji Abbas",
    defaultValue: 100,
    tooltipData: {
      current: {
        Name: {
          value: "Raji Abbas",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raji Abbas",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "narasaiah upadhyayula",
    defaultValue: 100,
    tooltipData: {
      current: {
        Name: {
          value: "narasaiah upadhyayula",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "narasaiah upadhyayula",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 100,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shyam Kishor",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "Shyam Kishor",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shyam Kishor",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "khan asmat",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "khan asmat",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "khan asmat",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rezaur Akand",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "Rezaur Akand",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rezaur Akand",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prachi Khanna",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "Prachi Khanna",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prachi Khanna",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Munirah Reukauf",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "Munirah Reukauf",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Munirah Reukauf",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rohit Bhatia",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "Rohit Bhatia",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rohit Bhatia",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sathiya Sakthi",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "Sathiya Sakthi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sathiya Sakthi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "varun kunal",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "varun kunal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "varun kunal",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "surianis shukri",
    defaultValue: 99,
    tooltipData: {
      current: {
        Name: {
          value: "surianis shukri",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "surianis shukri",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 99,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ann kay",
    defaultValue: 98,
    tooltipData: {
      current: {
        Name: {
          value: "ann kay",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 98,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ann kay",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 98,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kami odeneye",
    defaultValue: 98,
    tooltipData: {
      current: {
        Name: {
          value: "kami odeneye",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 98,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kami odeneye",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 98,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Krishna Ponnuru",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "Krishna Ponnuru",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Krishna Ponnuru",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prakash Ramachandran",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "Prakash Ramachandran",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prakash Ramachandran",
          format: false,
        },
        TotalOrders: {
          value: 15,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sravan Kumar",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "Sravan Kumar",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sravan Kumar",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Minahaz S",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "Minahaz S",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Minahaz S",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anil varghese",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "anil varghese",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anil varghese",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Suvidha Narang",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "Suvidha Narang",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Suvidha Narang",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rana Silat",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "Rana Silat",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rana Silat",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aditi Srivastav",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "Aditi Srivastav",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aditi Srivastav",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sayli Pillai",
    defaultValue: 97,
    tooltipData: {
      current: {
        Name: {
          value: "Sayli Pillai",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sayli Pillai",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 97,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raji Pothuru",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "Raji Pothuru",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raji Pothuru",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "one er",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "one er",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "one er",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kulsoom Farooqui",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "Kulsoom Farooqui",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kulsoom Farooqui",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Moiz Syed",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "Moiz Syed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Moiz Syed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raj kumar",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "raj kumar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raj kumar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Farshid Mahdavi",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "Farshid Mahdavi",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Farshid Mahdavi",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ramakrishna karnekota",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "ramakrishna karnekota",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ramakrishna karnekota",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anjali Varma",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "Anjali Varma",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anjali Varma",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Najvat s",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "Najvat s",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Najvat s",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "upendra dubey",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "upendra dubey",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "upendra dubey",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arti Jhaveri",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "Arti Jhaveri",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arti Jhaveri",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Toral Kapadia",
    defaultValue: 96,
    tooltipData: {
      current: {
        Name: {
          value: "Toral Kapadia",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Toral Kapadia",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 96,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ranga Javvadi",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Ranga Javvadi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ranga Javvadi",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shiva thigulla",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "shiva thigulla",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shiva thigulla",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vrata Bidi",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Vrata Bidi",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vrata Bidi",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Seenu Murthy",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Seenu Murthy",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Seenu Murthy",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zaina Alawsy",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Zaina Alawsy",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zaina Alawsy",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Gopal Mehta",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Gopal Mehta",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Gopal Mehta",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rahul Patil",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Rahul Patil",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rahul Patil",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vinay Dodla",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Vinay Dodla",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vinay Dodla",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saha Biswas",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Saha Biswas",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saha Biswas",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aslam Mohammad",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "Aslam Mohammad",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aslam Mohammad",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ASHMA KHADKA",
    defaultValue: 95,
    tooltipData: {
      current: {
        Name: {
          value: "ASHMA KHADKA",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ASHMA KHADKA",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 95,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SmITA LIMAYE",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "SmITA LIMAYE",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SmITA LIMAYE",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nisha nalawade",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "nisha nalawade",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nisha nalawade",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Preeya Mistry",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Preeya Mistry",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Preeya Mistry",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Imran Haq",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Imran Haq",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Imran Haq",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nilanjan Roy",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Nilanjan Roy",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nilanjan Roy",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "KEDAR DESHPANDE",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "KEDAR DESHPANDE",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "KEDAR DESHPANDE",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ramesh Kumar",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Ramesh Kumar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ramesh Kumar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ritesh Kumar",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Ritesh Kumar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ritesh Kumar",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zubair shaik",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Zubair shaik",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zubair shaik",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shashi pasunuru",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "shashi pasunuru",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shashi pasunuru",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Srinivasan Raja k",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Srinivasan Raja k",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Srinivasan Raja k",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sumaira baig",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "sumaira baig",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sumaira baig",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nidal Zeidat",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Nidal Zeidat",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nidal Zeidat",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Kamran Arif",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Kamran Arif",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Kamran Arif",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ashish jain",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "ashish jain",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ashish jain",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "NIL SARKAR",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "NIL SARKAR",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "NIL SARKAR",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lezanne pugh",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "Lezanne pugh",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lezanne pugh",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jaison t",
    defaultValue: 94,
    tooltipData: {
      current: {
        Name: {
          value: "jaison t",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jaison t",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 94,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arpana Sarkar",
    defaultValue: 93,
    tooltipData: {
      current: {
        Name: {
          value: "Arpana Sarkar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arpana Sarkar",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nikki Reddy",
    defaultValue: 93,
    tooltipData: {
      current: {
        Name: {
          value: "Nikki Reddy",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nikki Reddy",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Huma Khandwala",
    defaultValue: 93,
    tooltipData: {
      current: {
        Name: {
          value: "Huma Khandwala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Huma Khandwala",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Deepthi Seban",
    defaultValue: 93,
    tooltipData: {
      current: {
        Name: {
          value: "Deepthi Seban",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Deepthi Seban",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Reddy Saggam",
    defaultValue: 93,
    tooltipData: {
      current: {
        Name: {
          value: "Reddy Saggam",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Reddy Saggam",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yelda Mohmand",
    defaultValue: 93,
    tooltipData: {
      current: {
        Name: {
          value: "Yelda Mohmand",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yelda Mohmand",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ansar Imam",
    defaultValue: 93,
    tooltipData: {
      current: {
        Name: {
          value: "Ansar Imam",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ansar Imam",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shima Khosravi",
    defaultValue: 93,
    tooltipData: {
      current: {
        Name: {
          value: "Shima Khosravi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shima Khosravi",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 93,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "dev sharma",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "dev sharma",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "dev sharma",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Haider",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Haider",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Haider",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hess nur",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "hess nur",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hess nur",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Preeti Dixit",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "Preeti Dixit",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Preeti Dixit",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "GARRETT WALSH",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "GARRETT WALSH",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "GARRETT WALSH",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Venkatesh y",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "Venkatesh y",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Venkatesh y",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "indira p",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "indira p",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "indira p",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tonmoy Roy",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "Tonmoy Roy",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tonmoy Roy",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arshia Sayed",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "Arshia Sayed",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arshia Sayed",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ragini Singh",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "Ragini Singh",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ragini Singh",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sudhakar Korrapati",
    defaultValue: 92,
    tooltipData: {
      current: {
        Name: {
          value: "Sudhakar Korrapati",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sudhakar Korrapati",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 92,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "anupama kanduri",
    defaultValue: 91,
    tooltipData: {
      current: {
        Name: {
          value: "anupama kanduri",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "anupama kanduri",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Priyanshi Rastogi",
    defaultValue: 91,
    tooltipData: {
      current: {
        Name: {
          value: "Priyanshi Rastogi",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Priyanshi Rastogi",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anum Moeen",
    defaultValue: 91,
    tooltipData: {
      current: {
        Name: {
          value: "Anum Moeen",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anum Moeen",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ajay m",
    defaultValue: 91,
    tooltipData: {
      current: {
        Name: {
          value: "ajay m",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ajay m",
          format: false,
        },
        TotalOrders: {
          value: 9,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ajeeth Karedath",
    defaultValue: 91,
    tooltipData: {
      current: {
        Name: {
          value: "Ajeeth Karedath",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ajeeth Karedath",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Yadav",
    defaultValue: 91,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Yadav",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Yadav",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Yogesh Naik",
    defaultValue: 91,
    tooltipData: {
      current: {
        Name: {
          value: "Yogesh Naik",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Yogesh Naik",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sarih ahmed",
    defaultValue: 91,
    tooltipData: {
      current: {
        Name: {
          value: "sarih ahmed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sarih ahmed",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 91,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "subbarao reddimosu",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "subbarao reddimosu",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "subbarao reddimosu",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "indira a",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "indira a",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "indira a",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "nishant chawla",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "nishant chawla",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "nishant chawla",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Navroze Hilloo",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "Navroze Hilloo",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Navroze Hilloo",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Vishal Rajan",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "Vishal Rajan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Vishal Rajan",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "PonnuSwamy Ambazhagam",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "PonnuSwamy Ambazhagam",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "PonnuSwamy Ambazhagam",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ali Quazi",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "Ali Quazi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ali Quazi",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rama M",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "Rama M",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rama M",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sadia babar",
    defaultValue: 90,
    tooltipData: {
      current: {
        Name: {
          value: "sadia babar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sadia babar",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 90,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "raj patar",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "raj patar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "raj patar",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Tabot Bate",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "Tabot Bate",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Tabot Bate",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ravi dokku",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "ravi dokku",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ravi dokku",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prasad Yarlagadda",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "Prasad Yarlagadda",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prasad Yarlagadda",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "srinivas thota",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "srinivas thota",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "srinivas thota",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sonie gill",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "sonie gill",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sonie gill",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Osman Jillani",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "Osman Jillani",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Osman Jillani",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "neela nigudkar",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "neela nigudkar",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "neela nigudkar",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Krishna Peri",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "Krishna Peri",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Krishna Peri",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "INder Atual",
    defaultValue: 89,
    tooltipData: {
      current: {
        Name: {
          value: "INder Atual",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "INder Atual",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 89,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ashish khanna",
    defaultValue: 88,
    tooltipData: {
      current: {
        Name: {
          value: "Ashish khanna",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ashish khanna",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Souravbh Mayya",
    defaultValue: 88,
    tooltipData: {
      current: {
        Name: {
          value: "Souravbh Mayya",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Souravbh Mayya",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "prasad murari",
    defaultValue: 88,
    tooltipData: {
      current: {
        Name: {
          value: "prasad murari",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "prasad murari",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nabihan Chuwdhury",
    defaultValue: 88,
    tooltipData: {
      current: {
        Name: {
          value: "Nabihan Chuwdhury",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nabihan Chuwdhury",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Aayush Bajracharya",
    defaultValue: 88,
    tooltipData: {
      current: {
        Name: {
          value: "Aayush Bajracharya",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Aayush Bajracharya",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prasad Patibal",
    defaultValue: 88,
    tooltipData: {
      current: {
        Name: {
          value: "Prasad Patibal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prasad Patibal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 88,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zafar Muhammad",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "Zafar Muhammad",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zafar Muhammad",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "matthew ninan",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "matthew ninan",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "matthew ninan",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "jyoti aryal",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "jyoti aryal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "jyoti aryal",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "OMAR MIRZA",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "OMAR MIRZA",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "OMAR MIRZA",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Jay Samuel",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "Jay Samuel",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Jay Samuel",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Divya Nama",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "Divya Nama",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Divya Nama",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "saher iman",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "saher iman",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "saher iman",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anand Mavuram",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "Anand Mavuram",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anand Mavuram",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mwaffaq hassan",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "mwaffaq hassan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mwaffaq hassan",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "RK M",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "RK M",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "RK M",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "srinivasan gna",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "srinivasan gna",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "srinivasan gna",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vidya rathish",
    defaultValue: 87,
    tooltipData: {
      current: {
        Name: {
          value: "vidya rathish",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vidya rathish",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 87,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rishi Karekar",
    defaultValue: 86,
    tooltipData: {
      current: {
        Name: {
          value: "Rishi Karekar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rishi Karekar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Radhika Varry",
    defaultValue: 86,
    tooltipData: {
      current: {
        Name: {
          value: "Radhika Varry",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Radhika Varry",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shibani tiku",
    defaultValue: 86,
    tooltipData: {
      current: {
        Name: {
          value: "shibani tiku",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shibani tiku",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "areej zahid",
    defaultValue: 86,
    tooltipData: {
      current: {
        Name: {
          value: "areej zahid",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "areej zahid",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Khshif ijaz",
    defaultValue: 86,
    tooltipData: {
      current: {
        Name: {
          value: "Khshif ijaz",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Khshif ijaz",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 86,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sazid Syed",
    defaultValue: 85,
    tooltipData: {
      current: {
        Name: {
          value: "Sazid Syed",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sazid Syed",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Engy Kholeif",
    defaultValue: 85,
    tooltipData: {
      current: {
        Name: {
          value: "Engy Kholeif",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Engy Kholeif",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "mozammel hoque",
    defaultValue: 85,
    tooltipData: {
      current: {
        Name: {
          value: "mozammel hoque",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "mozammel hoque",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sudha yam",
    defaultValue: 85,
    tooltipData: {
      current: {
        Name: {
          value: "sudha yam",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sudha yam",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "soumya banerjee",
    defaultValue: 85,
    tooltipData: {
      current: {
        Name: {
          value: "soumya banerjee",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "soumya banerjee",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Neha Maggo",
    defaultValue: 85,
    tooltipData: {
      current: {
        Name: {
          value: "Neha Maggo",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Neha Maggo",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 85,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "narayan mohta",
    defaultValue: 84,
    tooltipData: {
      current: {
        Name: {
          value: "narayan mohta",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "narayan mohta",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Bunni Oluwadare",
    defaultValue: 84,
    tooltipData: {
      current: {
        Name: {
          value: "Bunni Oluwadare",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Bunni Oluwadare",
          format: false,
        },
        TotalOrders: {
          value: 10,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Chandrasekhar Bhimireddy",
    defaultValue: 84,
    tooltipData: {
      current: {
        Name: {
          value: "Chandrasekhar Bhimireddy",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Chandrasekhar Bhimireddy",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Nargis Husain",
    defaultValue: 84,
    tooltipData: {
      current: {
        Name: {
          value: "Nargis Husain",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Nargis Husain",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "SAIF MOHAMMED",
    defaultValue: 84,
    tooltipData: {
      current: {
        Name: {
          value: "SAIF MOHAMMED",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "SAIF MOHAMMED",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Samuel Sumbo",
    defaultValue: 84,
    tooltipData: {
      current: {
        Name: {
          value: "Samuel Sumbo",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Samuel Sumbo",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mazhar Basha",
    defaultValue: 84,
    tooltipData: {
      current: {
        Name: {
          value: "Mazhar Basha",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mazhar Basha",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Prasum Chaudhury",
    defaultValue: 84,
    tooltipData: {
      current: {
        Name: {
          value: "Prasum Chaudhury",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Prasum Chaudhury",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 84,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vibha shukla",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "vibha shukla",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vibha shukla",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "bhagyasri kalluri",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "bhagyasri kalluri",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "bhagyasri kalluri",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rocio Sanghavi",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "Rocio Sanghavi",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rocio Sanghavi",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Hamza Junge",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "Hamza Junge",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Hamza Junge",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "maskey sandeep",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "maskey sandeep",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "maskey sandeep",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ala Odeh",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "Ala Odeh",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ala Odeh",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "samah ismail",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "samah ismail",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "samah ismail",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Toyein Daniel",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "Toyein Daniel",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Toyein Daniel",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Waseem Bashir",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "Waseem Bashir",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Waseem Bashir",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "brenton barreto",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "brenton barreto",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "brenton barreto",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fatimzahra Benmensour",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "Fatimzahra Benmensour",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fatimzahra Benmensour",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Fatima Umar",
    defaultValue: 83,
    tooltipData: {
      current: {
        Name: {
          value: "Fatima Umar",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Fatima Umar",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 83,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Pooja K",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "Pooja K",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Pooja K",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Urvi Carraway",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "Urvi Carraway",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Urvi Carraway",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Haleema Zahid",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "Haleema Zahid",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Haleema Zahid",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Lakshmi Priya",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "Lakshmi Priya",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Lakshmi Priya",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ray Kumar",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "Ray Kumar",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ray Kumar",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "shirley biglarians",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "shirley biglarians",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "shirley biglarians",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sweta Plaha",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "sweta Plaha",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sweta Plaha",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vinay pillai",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "vinay pillai",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vinay pillai",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Manish Vishwanath",
    defaultValue: 82,
    tooltipData: {
      current: {
        Name: {
          value: "Manish Vishwanath",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Manish Vishwanath",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mrudula P",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "Mrudula P",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mrudula P",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Joseph Martin",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "Joseph Martin",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Joseph Martin",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sundar Arun",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "Sundar Arun",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sundar Arun",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "hema desai",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "hema desai",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "hema desai",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "waleed javed",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "waleed javed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "waleed javed",
          format: false,
        },
        TotalOrders: {
          value: 7,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Raj Dahiya",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "Raj Dahiya",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Raj Dahiya",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "vijay lakshmi",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "vijay lakshmi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "vijay lakshmi",
          format: false,
        },
        TotalOrders: {
          value: 11,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ahmed Mohammed",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "Ahmed Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ahmed Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 1,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sudarshan G",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "Sudarshan G",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sudarshan G",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "kishore perisetla",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "kishore perisetla",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "kishore perisetla",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Urmila Rahman",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "Urmila Rahman",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Urmila Rahman",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shipra Chandra",
    defaultValue: 81,
    tooltipData: {
      current: {
        Name: {
          value: "Shipra Chandra",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shipra Chandra",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 81,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "caroliana cortez",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "caroliana cortez",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "caroliana cortez",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "abhilash krishna",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "abhilash krishna",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "abhilash krishna",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Joe Albert",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "Joe Albert",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Joe Albert",
          format: false,
        },
        TotalOrders: {
          value: 6,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Amar Javidi",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "Amar Javidi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Amar Javidi",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "ZAKARIA ORAIKAT",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "ZAKARIA ORAIKAT",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "ZAKARIA ORAIKAT",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "QUYEN PHU",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "QUYEN PHU",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "QUYEN PHU",
          format: false,
        },
        TotalOrders: {
          value: 8,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Shahul shaik",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "Shahul shaik",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Shahul shaik",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ankit Jain",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "Ankit Jain",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ankit Jain",
          format: false,
        },
        TotalOrders: {
          value: 2,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Saeed Zafar",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "Saeed Zafar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Saeed Zafar",
          format: false,
        },
        TotalOrders: {
          value: 5,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "sanjay menezes",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "sanjay menezes",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "sanjay menezes",
          format: false,
        },
        TotalOrders: {
          value: 3,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "useless Parmar",
    defaultValue: 80,
    tooltipData: {
      current: {
        Name: {
          value: "useless Parmar",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "useless Parmar",
          format: false,
        },
        TotalOrders: {
          value: 4,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 80,
          format: "Currency",
        },
      },
    },
  },
]

export const ProgressBarDataProducts = [
  {
    title: "COFFEE",
    defaultValue: 2000,
    tooltipData: {
      current: {
        Name: { value: "COFFEE", format: false },
        "Total Orders": { value: 20, format: "Number" },
      },
    },
  },
  {
    title: "SODA",
    defaultValue: 1714,
    tooltipData: {
      current: {
        Name: { value: "SODA", format: false },
        "Total Orders": { value: 17, format: "Number" },
      },
    },
  },
  {
    title: "2 EGG COMBO",
    defaultValue: 1470,
    tooltipData: {
      current: {
        Name: { value: "2 EGG COMBO", format: false },
        "Total Orders": { value: 14, format: "Number" },
      },
    },
  },
  {
    title: "FRESH SQUEEZED OJ 12 OZ",
    defaultValue: 1260,
    tooltipData: {
      current: {
        Name: { value: "FRESH SQUEEZED OJ 12 OZ", format: false },
        "Total Orders": { value: 12, format: "Number" },
      },
    },
  },
  {
    title: "2*2 PANCAKES",
    defaultValue: 1080,
    tooltipData: {
      current: {
        Name: { value: "2*2 PANCAKES", format: false },
        "Total Orders": { value: 10, format: "Number" },
      },
    },
  },
  {
    title: "SIDE BACON",
    defaultValue: 926,
    tooltipData: {
      current: {
        Name: { value: "SIDE BACON", format: false },
        "Total Orders": { value: 9, format: "Number" },
      },
    },
  },
  {
    title: "BOWL OF SOUP",
    defaultValue: 794,
    tooltipData: {
      current: {
        Name: { value: "BOWL OF SOUP", format: false },
        "Total Orders": { value: 7, format: "Number" },
      },
    },
  },
  {
    title: "MEAT LOVERS SKILLET",
    defaultValue: 680,
    tooltipData: {
      current: {
        Name: { value: "MEAT LOVERS SKILLET", format: false },
        "Total Orders": { value: 6, format: "Number" },
      },
    },
  },
  {
    title: "TOAST",
    defaultValue: 583,
    tooltipData: {
      current: {
        Name: { value: "TOAST", format: false },
        "Total Orders": { value: 5, format: "Number" },
      },
    },
  },
  {
    title: "SIDE HASHBROWNS",
    defaultValue: 400,
    tooltipData: {
      current: {
        Name: { value: "SIDE HASHBROWNS", format: false },
        "Total Orders": { value: 4, format: "Number" },
      },
    },
  },
]

export const ProgressBarParameters = {
  margin: { top: -5, right: 0, bottom: 5, left: 0 },
  height: "8",
  width: "0",
  border: "4px solid #ffffffd1",
}

export const ScatterPlotChartData = [
  {
    id: "Min",
    data: [
      { x: 99, y: 66 },
      { x: 51, y: 39 },
      { x: 29, y: 88 },
      { x: 20, y: 37 },
      { x: 69, y: 7 },
      { x: 80, y: 60 },
      { x: 97, y: 65 },
      { x: 56, y: 38 },
      { x: 26, y: 89 },
      { x: 25, y: 38 },
      { x: 63, y: 75 },
      { x: 92, y: 92 },
      { x: 92, y: 96 },
      { x: 54, y: 89 },
      { x: 21, y: 78 },
      { x: 28, y: 37 },
      { x: 63, y: 9 },
      { x: 87, y: 62 },
      { x: 92, y: 67 },
      { x: 55, y: 33 },
      { x: 27, y: 86 },
      { x: 28, y: 37 },
      { x: 69, y: 77 },
      { x: 91, y: 68 },
    ],
  },
  {
    id: "Max",
    data: [
      { x: 61, y: 94 },
      { x: 12, y: 10 },
      { x: 33, y: 68 },
      { x: 11, y: 85 },
      { x: 38, y: 42 },
      { x: 21, y: 78 },
      { x: 28, y: 37 },
      { x: 63, y: 9 },
      { x: 87, y: 62 },
      { x: 92, y: 67 },
      { x: 55, y: 33 },
      { x: 27, y: 86 },
      { x: 28, y: 37 },
    ],
  },
]

export const ScatterPlotChartParameters = {
  margin: { top: 30, right: 30, bottom: 70, left: 10 },
  xScale: {
    type: "linear",
    min: 0,
    max: 100,
  },
  yScale: {
    type: "linear",
    min: 0,
    max: 100,
  },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickValues: [0, 15, 45, 75],
    tickFormat: "formatXAxisLabel",
    legendPosition: "middle",
    legendOffset: 46,
    tickSize: 8,
    tickPadding: 10,
    tickRotation: 0,
  },
  gridXValues: [15, 45, 75],
  gridYValues: [50],
  nodeSize: 8,
  layers: ["grid", "axes", "nodes", "CustomGridBackground"],
  colors: (node) =>
    node.serieId === "Min" ? "rgba(92, 41, 199, 1)" : "rgba(240, 134, 76, 1)",
}
export const StreamChartData = [
  {
    "Theressa Webb": 176,
    "Ronalds Rechards": 132,
    "Bessie Cooper": 109,
    "Brroklys Symons": 119,
    "Savannah Nguyan": 157,
    "Floyad Mines": 80,
  },
  {
    "Theressa Webb": 155,
    "Ronalds Rechards": 56,
    "Bessie Cooper": 91,
    "Brroklys Symons": 45,
    "Savannah Nguyan": 59,
    "Floyad Mines": 147,
  },
  {
    "Theressa Webb": 116,
    "Ronalds Rechards": 114,
    "Bessie Cooper": 176,
    "Brroklys Symons": 12,
    "Savannah Nguyan": 53,
    "Floyad Mines": 127,
  },
  {
    "Theressa Webb": 135,
    "Ronalds Rechards": 196,
    "Bessie Cooper": 131,
    "Brroklys Symons": 125,
    "Savannah Nguyan": 107,
    "Floyad Mines": 57,
  },
  {
    "Theressa Webb": 66,
    "Ronalds Rechards": 62,
    "Bessie Cooper": 153,
    "Brroklys Symons": 135,
    "Savannah Nguyan": 158,
    "Floyad Mines": 140,
  },
  {
    "Theressa Webb": 60,
    "Ronalds Rechards": 113,
    "Bessie Cooper": 140,
    "Brroklys Symons": 88,
    "Savannah Nguyan": 167,
    "Floyad Mines": 111,
  },
  {
    "Theressa Webb": 0,
    "Ronalds Rechards": 107,
    "Bessie Cooper": 142,
    "Brroklys Symons": 78,
    "Savannah Nguyan": 162,
    "Floyad Mines": 199,
  },
  {
    "Theressa Webb": 161,
    "Ronalds Rechards": 157,
    "Bessie Cooper": 101,
    "Brroklys Symons": 124,
    "Savannah Nguyan": 118,
    "Floyad Mines": 158,
  },
  {
    "Theressa Webb": 128,
    "Ronalds Rechards": 147,
    "Bessie Cooper": 60,
    "Brroklys Symons": 165,
    "Savannah Nguyan": 71,
    "Floyad Mines": 89,
  },
]

export const StreamChartParameters = {
  keys: [
    "Theressa Webb",
    "Ronalds Rechards",
    "Bessie Cooper",
    "Brroklys Symons",
    "Savannah Nguyan",
    "Floyad Mines",
  ],
  margin: {
    top: 50,
    right: 30,
    bottom: 50,
    left: 30,
  },
  axisTop: "",
  axisRight: "",
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: 36,
    truncateTickAt: 0,
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: -40,
    truncateTickAt: 0,
    tickTextColor: "#ffffff",
  },
  enableGridX: "true",
  enableGridY: "false",
  offsetType: "silhouette",
  colors: {
    scheme: "set3",
  },
  fillOpacity: [0.85],
  borderColor: {
    theme: "background",
  },
  defs: [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "#2c998f",
      size: 4,
      padding: 2,
      stagger: true,
    },
    {
      id: "squares",
      type: "patternSquares",
      background: "inherit",
      color: "#e4c912",
      size: 6,
      padding: 2,
      stagger: true,
    },
  ],
  fill: [
    {
      match: {
        id: "Eggs",
      },
      id: "dots",
    },
    {
      match: {
        id: "Carrots",
      },
      id: "squares",
    },
  ],
  dotSize: [8],
  dotColor: {
    from: "color",
  },
  dotBorderWidth: [2],
  dotBorderColor: {
    from: "color",
    modifiers: [["darker", 0.7]],
  },
  legends: [
    {
      anchor: "bottom",
      direction: "row",
      translateY: 50,
      itemWidth: 80,
      itemHeight: 20,
      itemTextColor: "#999999",
      symbolSize: 12,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#fff",
          },
        },
      ],
    },
  ],
}
export const HorizontalBarChartData = [
  {
    idMonth: "08-01-2024",
    orderCount: -122,
    tooltipData: {
      current: {
        "Order Count": {
          value: 122,
          format: "Number",
        },
        "Gross Sales": {
          value: 3283.68,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 44,
          format: "Currency",
        },
        "Discount Amount": {
          value: 72.45,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 140,
          format: "Number",
        },
        "Gross Sales": {
          value: 3740.12,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.72,
          format: "Currency",
        },
        "Return Amount": {
          value: 3,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-02-2024",
    orderCount: -119,
    tooltipData: {
      current: {
        "Order Count": {
          value: 119,
          format: "Number",
        },
        "Gross Sales": {
          value: 3321.99,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.41,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 4188.27,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 31.02,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.43,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-03-2024",
    orderCount: -164,
    tooltipData: {
      current: {
        "Order Count": {
          value: 164,
          format: "Number",
        },
        "Gross Sales": {
          value: 4900.36,
          format: "Currency",
        },
        Customers: {
          value: 131,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.88,
          format: "Currency",
        },
        "Return Amount": {
          value: 23.5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.28,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 138,
          format: "Number",
        },
        "Gross Sales": {
          value: 3676.93,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.64,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-04-2024",
    orderCount: -172,
    tooltipData: {
      current: {
        "Order Count": {
          value: 172,
          format: "Number",
        },
        "Gross Sales": {
          value: 3773.12,
          format: "Currency",
        },
        Customers: {
          value: 127,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.94,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 23.14,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 148,
          format: "Number",
        },
        "Gross Sales": {
          value: 3900.37,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.35,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.06,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-05-2024",
    orderCount: -113,
    tooltipData: {
      current: {
        "Order Count": {
          value: 113,
          format: "Number",
        },
        "Gross Sales": {
          value: 2973.24,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 74.26,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 180,
          format: "Number",
        },
        "Gross Sales": {
          value: 4715.62,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.2,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.03,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-06-2024",
    orderCount: -98,
    tooltipData: {
      current: {
        "Order Count": {
          value: 98,
          format: "Number",
        },
        "Gross Sales": {
          value: 2325.96,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 12.37,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 0,
          format: "Number",
        },
        "Gross Sales": {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          value: null,
          format: "Currency",
        },
        "Return Amount": {
          value: null,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-07-2024",
    orderCount: -127,
    tooltipData: {
      current: {
        "Order Count": {
          value: 127,
          format: "Number",
        },
        "Gross Sales": {
          value: 3768.57,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.67,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.05,
          format: "Currency",
        },
        "Discount Amount": {
          value: 233.82,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 0,
          format: "Number",
        },
        "Gross Sales": {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          value: null,
          format: "Currency",
        },
        "Return Amount": {
          value: null,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-08-2024",
    orderCount: -120,
    tooltipData: {
      current: {
        "Order Count": {
          value: 120,
          format: "Number",
        },
        "Gross Sales": {
          value: 3047.64,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.86,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 24,
          format: "Number",
        },
        "Gross Sales": {
          value: 333.37,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 24,
          format: "Number",
        },
        "Average Ticket": {
          value: 13.89,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-09-2024",
    orderCount: -136,
    tooltipData: {
      current: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 3449.76,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.37,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.39,
          format: "Currency",
        },
        "Discount Amount": {
          value: 78,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 4238.91,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.19,
          format: "Currency",
        },
        "Return Amount": {
          value: 14.86,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-10-2024",
    orderCount: -167,
    tooltipData: {
      current: {
        "Order Count": {
          value: 167,
          format: "Number",
        },
        "Gross Sales": {
          value: 5123.1,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 6.55,
          format: "Currency",
        },
        "Discount Amount": {
          value: 44.26,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3275.42,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 47.49,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-11-2024",
    orderCount: -161,
    tooltipData: {
      current: {
        "Order Count": {
          value: 161,
          format: "Number",
        },
        "Gross Sales": {
          value: 3534.91,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.16,
          format: "Currency",
        },
        "Discount Amount": {
          value: 64.64,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 144,
          format: "Number",
        },
        "Gross Sales": {
          value: 4077.01,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-12-2024",
    orderCount: -118,
    tooltipData: {
      current: {
        "Order Count": {
          value: 118,
          format: "Number",
        },
        "Gross Sales": {
          value: 3417.72,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.78,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 157,
          format: "Number",
        },
        "Gross Sales": {
          value: 3412.24,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-13-2024",
    orderCount: -115,
    tooltipData: {
      current: {
        "Order Count": {
          value: 115,
          format: "Number",
        },
        "Gross Sales": {
          value: 3443.92,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 10.93,
          format: "Currency",
        },
        "Discount Amount": {
          value: 2.58,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 2718.32,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-14-2024",
    orderCount: -136,
    tooltipData: {
      current: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 5222.11,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 21.09,
          format: "Currency",
        },
        "Discount Amount": {
          value: 321.6,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 118,
          format: "Number",
        },
        "Gross Sales": {
          value: 3471.69,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.04,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-15-2024",
    orderCount: -145,
    tooltipData: {
      current: {
        "Order Count": {
          value: 145,
          format: "Number",
        },
        "Gross Sales": {
          value: 3807.56,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.25,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 3920.59,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 35.32,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 319.33,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-16-2024",
    orderCount: -131,
    tooltipData: {
      current: {
        "Order Count": {
          value: 131,
          format: "Number",
        },
        "Gross Sales": {
          value: 3173.44,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 25.91,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3796.93,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.21,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.2,
          format: "Currency",
        },
        "Discount Amount": {
          value: 35.08,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-17-2024",
    orderCount: -135,
    tooltipData: {
      current: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3562.6,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 91.12,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 144,
          format: "Number",
        },
        "Gross Sales": {
          value: 3922.97,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.24,
          format: "Currency",
        },
        "Return Amount": {
          value: 0.63,
          format: "Currency",
        },
        "Discount Amount": {
          value: 96.71,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-18-2024",
    orderCount: -174,
    tooltipData: {
      current: {
        "Order Count": {
          value: 174,
          format: "Number",
        },
        "Gross Sales": {
          value: 4795.91,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.56,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 103.35,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 137,
          format: "Number",
        },
        "Gross Sales": {
          value: 3977.17,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.03,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 189.65,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-19-2024",
    orderCount: -108,
    tooltipData: {
      current: {
        "Order Count": {
          value: 108,
          format: "Number",
        },
        "Gross Sales": {
          value: 2375.58,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        "Average Ticket": {
          value: 22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.48,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 161,
          format: "Number",
        },
        "Gross Sales": {
          value: 3763.96,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.38,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.02,
          format: "Currency",
        },
        "Discount Amount": {
          value: 57,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-20-2024",
    orderCount: -93,
    tooltipData: {
      current: {
        "Order Count": {
          value: 93,
          format: "Number",
        },
        "Gross Sales": {
          value: 2664.94,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.66,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.75,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.95,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 112,
          format: "Number",
        },
        "Gross Sales": {
          value: 2797.87,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.98,
          format: "Currency",
        },
        "Return Amount": {
          value: 9.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 11.3,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-21-2024",
    orderCount: -130,
    tooltipData: {
      current: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3953.26,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.41,
          format: "Currency",
        },
        "Return Amount": {
          value: 33.1,
          format: "Currency",
        },
        "Discount Amount": {
          value: 230.44,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 120,
          format: "Number",
        },
        "Gross Sales": {
          value: 2854.63,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.79,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 1.79,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-22-2024",
    orderCount: -132,
    tooltipData: {
      current: {
        "Order Count": {
          value: 132,
          format: "Number",
        },
        "Gross Sales": {
          value: 3804.29,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.82,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 86.62,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3643.13,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.99,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.69,
          format: "Currency",
        },
        "Discount Amount": {
          value: 142.18,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-23-2024",
    orderCount: -153,
    tooltipData: {
      current: {
        "Order Count": {
          value: 153,
          format: "Number",
        },
        "Gross Sales": {
          value: 4092.72,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.75,
          format: "Currency",
        },
        "Return Amount": {
          value: 20.3,
          format: "Currency",
        },
        "Discount Amount": {
          value: 32.81,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 99,
          format: "Number",
        },
        "Gross Sales": {
          value: 2455.66,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.8,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 43.11,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-24-2024",
    orderCount: -145,
    tooltipData: {
      current: {
        "Order Count": {
          value: 145,
          format: "Number",
        },
        "Gross Sales": {
          value: 3617.33,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 14.1,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 143,
          format: "Number",
        },
        "Gross Sales": {
          value: 3815.25,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 87.17,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-25-2024",
    orderCount: -159,
    tooltipData: {
      current: {
        "Order Count": {
          value: 159,
          format: "Number",
        },
        "Gross Sales": {
          value: 4175.49,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 19,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.71,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 152,
          format: "Number",
        },
        "Gross Sales": {
          value: 4482.68,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 12.15,
          format: "Currency",
        },
        "Discount Amount": {
          value: 22.64,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-26-2024",
    orderCount: -103,
    tooltipData: {
      current: {
        "Order Count": {
          value: 103,
          format: "Number",
        },
        "Gross Sales": {
          value: 2718.7,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.46,
          format: "Currency",
        },
        "Discount Amount": {
          value: 63.57,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 4457.98,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.78,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.45,
          format: "Currency",
        },
        "Discount Amount": {
          value: 163.11,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-27-2024",
    orderCount: -100,
    tooltipData: {
      current: {
        "Order Count": {
          value: 100,
          format: "Number",
        },
        "Gross Sales": {
          value: 2485.69,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.86,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.54,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.18,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 106,
          format: "Number",
        },
        "Gross Sales": {
          value: 3166.1,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.87,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 30.95,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-28-2024",
    orderCount: -101,
    tooltipData: {
      current: {
        "Order Count": {
          value: 101,
          format: "Number",
        },
        "Gross Sales": {
          value: 3342.88,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        "Average Ticket": {
          value: 33.1,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 247.86,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 107,
          format: "Number",
        },
        "Gross Sales": {
          value: 2398.79,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        "Average Ticket": {
          value: 22.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 44.08,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.53,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-29-2024",
    orderCount: -130,
    tooltipData: {
      current: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3300.89,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.85,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 109,
          format: "Number",
        },
        "Gross Sales": {
          value: 3568.35,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.74,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 200.43,
          format: "Currency",
        },
      },
    },
  },
]

export const HorizontalBarChartsecondData = [
  {
    idMonth: "2024-8-1",
    grossSale: 3283.68,
    tooltipData: {
      current: {
        "Order Count": {
          value: 122,
          format: "Number",
        },
        "Gross Sales": {
          value: 3283.68,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 44,
          format: "Currency",
        },
        "Discount Amount": {
          value: 72.45,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 140,
          format: "Number",
        },
        "Gross Sales": {
          value: 3740.12,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.72,
          format: "Currency",
        },
        "Return Amount": {
          value: 3,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-2",
    grossSale: 3321.99,
    tooltipData: {
      current: {
        "Order Count": {
          value: 119,
          format: "Number",
        },
        "Gross Sales": {
          value: 3321.99,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.41,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 4188.27,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 31.02,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.43,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-3",
    grossSale: 4900.36,
    tooltipData: {
      current: {
        "Order Count": {
          value: 164,
          format: "Number",
        },
        "Gross Sales": {
          value: 4900.36,
          format: "Currency",
        },
        Customers: {
          value: 131,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.88,
          format: "Currency",
        },
        "Return Amount": {
          value: 23.5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.28,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 138,
          format: "Number",
        },
        "Gross Sales": {
          value: 3676.93,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.64,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-4",
    grossSale: 3773.12,
    tooltipData: {
      current: {
        "Order Count": {
          value: 172,
          format: "Number",
        },
        "Gross Sales": {
          value: 3773.12,
          format: "Currency",
        },
        Customers: {
          value: 127,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.94,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 23.14,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 148,
          format: "Number",
        },
        "Gross Sales": {
          value: 3900.37,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.35,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.06,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-5",
    grossSale: 2973.24,
    tooltipData: {
      current: {
        "Order Count": {
          value: 113,
          format: "Number",
        },
        "Gross Sales": {
          value: 2973.24,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 74.26,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 180,
          format: "Number",
        },
        "Gross Sales": {
          value: 4715.62,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.2,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.03,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-6",
    grossSale: 2325.96,
    tooltipData: {
      current: {
        "Order Count": {
          value: 98,
          format: "Number",
        },
        "Gross Sales": {
          value: 2325.96,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 12.37,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 0,
          format: "Number",
        },
        "Gross Sales": {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          value: null,
          format: "Currency",
        },
        "Return Amount": {
          value: null,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-7",
    grossSale: 3768.57,
    tooltipData: {
      current: {
        "Order Count": {
          value: 127,
          format: "Number",
        },
        "Gross Sales": {
          value: 3768.57,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.67,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.05,
          format: "Currency",
        },
        "Discount Amount": {
          value: 233.82,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 0,
          format: "Number",
        },
        "Gross Sales": {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          value: null,
          format: "Currency",
        },
        "Return Amount": {
          value: null,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-8",
    grossSale: 3047.64,
    tooltipData: {
      current: {
        "Order Count": {
          value: 120,
          format: "Number",
        },
        "Gross Sales": {
          value: 3047.64,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.86,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 24,
          format: "Number",
        },
        "Gross Sales": {
          value: 333.37,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 24,
          format: "Number",
        },
        "Average Ticket": {
          value: 13.89,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-9",
    grossSale: 3449.76,
    tooltipData: {
      current: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 3449.76,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.37,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.39,
          format: "Currency",
        },
        "Discount Amount": {
          value: 78,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 4238.91,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.19,
          format: "Currency",
        },
        "Return Amount": {
          value: 14.86,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-10",
    grossSale: 5123.1,
    tooltipData: {
      current: {
        "Order Count": {
          value: 167,
          format: "Number",
        },
        "Gross Sales": {
          value: 5123.1,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 6.55,
          format: "Currency",
        },
        "Discount Amount": {
          value: 44.26,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3275.42,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 47.49,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-11",
    grossSale: 3534.91,
    tooltipData: {
      current: {
        "Order Count": {
          value: 161,
          format: "Number",
        },
        "Gross Sales": {
          value: 3534.91,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.16,
          format: "Currency",
        },
        "Discount Amount": {
          value: 64.64,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 144,
          format: "Number",
        },
        "Gross Sales": {
          value: 4077.01,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-12",
    grossSale: 3417.72,
    tooltipData: {
      current: {
        "Order Count": {
          value: 118,
          format: "Number",
        },
        "Gross Sales": {
          value: 3417.72,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.78,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 157,
          format: "Number",
        },
        "Gross Sales": {
          value: 3412.24,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-13",
    grossSale: 3443.92,
    tooltipData: {
      current: {
        "Order Count": {
          value: 115,
          format: "Number",
        },
        "Gross Sales": {
          value: 3443.92,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 10.93,
          format: "Currency",
        },
        "Discount Amount": {
          value: 2.58,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 2718.32,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-14",
    grossSale: 5222.11,
    tooltipData: {
      current: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 5222.11,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 21.09,
          format: "Currency",
        },
        "Discount Amount": {
          value: 321.6,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 118,
          format: "Number",
        },
        "Gross Sales": {
          value: 3471.69,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.04,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-15",
    grossSale: 3807.56,
    tooltipData: {
      current: {
        "Order Count": {
          value: 145,
          format: "Number",
        },
        "Gross Sales": {
          value: 3807.56,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.25,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 3920.59,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 35.32,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 319.33,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-16",
    grossSale: 3173.44,
    tooltipData: {
      current: {
        "Order Count": {
          value: 131,
          format: "Number",
        },
        "Gross Sales": {
          value: 3173.44,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 25.91,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3796.93,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.21,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.2,
          format: "Currency",
        },
        "Discount Amount": {
          value: 35.08,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-17",
    grossSale: 3562.6,
    tooltipData: {
      current: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3562.6,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 91.12,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 144,
          format: "Number",
        },
        "Gross Sales": {
          value: 3922.97,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.24,
          format: "Currency",
        },
        "Return Amount": {
          value: 0.63,
          format: "Currency",
        },
        "Discount Amount": {
          value: 96.71,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-18",
    grossSale: 4795.91,
    tooltipData: {
      current: {
        "Order Count": {
          value: 174,
          format: "Number",
        },
        "Gross Sales": {
          value: 4795.91,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.56,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 103.35,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 137,
          format: "Number",
        },
        "Gross Sales": {
          value: 3977.17,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.03,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 189.65,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-19",
    grossSale: 2375.58,
    tooltipData: {
      current: {
        "Order Count": {
          value: 108,
          format: "Number",
        },
        "Gross Sales": {
          value: 2375.58,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        "Average Ticket": {
          value: 22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.48,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 161,
          format: "Number",
        },
        "Gross Sales": {
          value: 3763.96,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.38,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.02,
          format: "Currency",
        },
        "Discount Amount": {
          value: 57,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-20",
    grossSale: 2664.94,
    tooltipData: {
      current: {
        "Order Count": {
          value: 93,
          format: "Number",
        },
        "Gross Sales": {
          value: 2664.94,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.66,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.75,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.95,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 112,
          format: "Number",
        },
        "Gross Sales": {
          value: 2797.87,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.98,
          format: "Currency",
        },
        "Return Amount": {
          value: 9.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 11.3,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-21",
    grossSale: 3953.26,
    tooltipData: {
      current: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3953.26,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.41,
          format: "Currency",
        },
        "Return Amount": {
          value: 33.1,
          format: "Currency",
        },
        "Discount Amount": {
          value: 230.44,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 120,
          format: "Number",
        },
        "Gross Sales": {
          value: 2854.63,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.79,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 1.79,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-22",
    grossSale: 3804.29,
    tooltipData: {
      current: {
        "Order Count": {
          value: 132,
          format: "Number",
        },
        "Gross Sales": {
          value: 3804.29,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.82,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 86.62,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3643.13,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.99,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.69,
          format: "Currency",
        },
        "Discount Amount": {
          value: 142.18,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-23",
    grossSale: 4092.72,
    tooltipData: {
      current: {
        "Order Count": {
          value: 153,
          format: "Number",
        },
        "Gross Sales": {
          value: 4092.72,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.75,
          format: "Currency",
        },
        "Return Amount": {
          value: 20.3,
          format: "Currency",
        },
        "Discount Amount": {
          value: 32.81,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 99,
          format: "Number",
        },
        "Gross Sales": {
          value: 2455.66,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.8,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 43.11,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-24",
    grossSale: 3617.33,
    tooltipData: {
      current: {
        "Order Count": {
          value: 145,
          format: "Number",
        },
        "Gross Sales": {
          value: 3617.33,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 14.1,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 143,
          format: "Number",
        },
        "Gross Sales": {
          value: 3815.25,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 87.17,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-25",
    grossSale: 4175.49,
    tooltipData: {
      current: {
        "Order Count": {
          value: 159,
          format: "Number",
        },
        "Gross Sales": {
          value: 4175.49,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 19,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.71,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 152,
          format: "Number",
        },
        "Gross Sales": {
          value: 4482.68,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 12.15,
          format: "Currency",
        },
        "Discount Amount": {
          value: 22.64,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-26",
    grossSale: 2718.7,
    tooltipData: {
      current: {
        "Order Count": {
          value: 103,
          format: "Number",
        },
        "Gross Sales": {
          value: 2718.7,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.46,
          format: "Currency",
        },
        "Discount Amount": {
          value: 63.57,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 4457.98,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.78,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.45,
          format: "Currency",
        },
        "Discount Amount": {
          value: 163.11,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-27",
    grossSale: 2485.69,
    tooltipData: {
      current: {
        "Order Count": {
          value: 100,
          format: "Number",
        },
        "Gross Sales": {
          value: 2485.69,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.86,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.54,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.18,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 106,
          format: "Number",
        },
        "Gross Sales": {
          value: 3166.1,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.87,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 30.95,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-28",
    grossSale: 3342.88,
    tooltipData: {
      current: {
        "Order Count": {
          value: 101,
          format: "Number",
        },
        "Gross Sales": {
          value: 3342.88,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        "Average Ticket": {
          value: 33.1,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 247.86,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 107,
          format: "Number",
        },
        "Gross Sales": {
          value: 2398.79,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        "Average Ticket": {
          value: 22.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 44.08,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.53,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-29",
    grossSale: 3300.89,
    tooltipData: {
      current: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3300.89,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.85,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 109,
          format: "Number",
        },
        "Gross Sales": {
          value: 3568.35,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.74,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 200.43,
          format: "Currency",
        },
      },
    },
  },
]

export const HorizontalBarChartParameters = {
  margin: {
    top: 10,
    right: 10,
    bottom: 30,
    left: 40,
  },
  padding: 0.2,
  borderRadius: 3,
  layout: "horizontal",
  keys: ["revenue", "orders"],
  indexBy: "id",
  colors: {
    scheme: "nivo",
  },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    labelTextColor: "red",
    tickRotation: -42,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
  },
  enableLabel: false,
  animate: {
    motionStiffness: 80,
    motionDamping: 15,
  },
}

export const sunburstData = {
  name: "nivo",
  color: "hsl(215, 70%, 50%)",
  children: [
    {
      name: "viz",
      color: "hsl(86, 70%, 50%)",
      children: [
        {
          name: "stack",
          color: "hsl(209, 70%, 50%)",
          children: [
            {
              name: "cchart",
              color: "hsl(78, 70%, 50%)",
              loc: 8562,
            },
            {
              name: "xAxis",
              color: "hsl(280, 70%, 50%)",
              loc: 176586,
            },
            {
              name: "yAxis",
              color: "hsl(213, 70%, 50%)",
              loc: 39214,
            },
            {
              name: "layers",
              color: "hsl(279, 70%, 50%)",
              loc: 43788,
            },
          ],
        },
        {
          name: "ppie",
          color: "hsl(20, 70%, 50%)",
          children: [
            {
              name: "chart",
              color: "hsl(242, 70%, 50%)",
              children: [
                {
                  name: "pie",
                  color: "hsl(202, 70%, 50%)",
                  children: [
                    {
                      name: "outline",
                      color: "hsl(80, 70%, 50%)",
                      loc: 156210,
                    },
                    {
                      name: "slices",
                      color: "hsl(80, 70%, 50%)",
                      loc: 192060,
                    },
                    {
                      name: "bbox",
                      color: "hsl(281, 70%, 50%)",
                      loc: 98825,
                    },
                  ],
                },
                {
                  name: "donut",
                  color: "hsl(94, 70%, 50%)",
                  loc: 5421,
                },
                {
                  name: "gauge",
                  color: "hsl(317, 70%, 50%)",
                  loc: 165074,
                },
              ],
            },
            {
              name: "legends",
              color: "hsl(334, 70%, 50%)",
              loc: 19528,
            },
          ],
        },
      ],
    },
    {
      name: "colors",
      color: "hsl(31, 70%, 50%)",
      children: [
        {
          name: "rgb",
          color: "hsl(266, 70%, 50%)",
          loc: 3817,
        },
        {
          name: "hsl",
          color: "hsl(254, 70%, 50%)",
          loc: 129091,
        },
      ],
    },
    {
      name: "utils",
      color: "hsl(298, 70%, 50%)",
      children: [
        {
          name: "randomize",
          color: "hsl(226, 70%, 50%)",
          loc: 1402,
        },
        {
          name: "resetClock",
          color: "hsl(23, 70%, 50%)",
          loc: 167611,
        },
        {
          name: "noop",
          color: "hsl(224, 70%, 50%)",
          loc: 178268,
        },
        {
          name: "tick",
          color: "hsl(303, 70%, 50%)",
          loc: 178983,
        },
        {
          name: "forceGC",
          color: "hsl(23, 70%, 50%)",
          loc: 169552,
        },
        {
          name: "stackTrace",
          color: "hsl(321, 70%, 50%)",
          loc: 181511,
        },
        {
          name: "dbg",
          color: "hsl(177, 70%, 50%)",
          loc: 92905,
        },
      ],
    },
    {
      name: "generators",
      color: "hsl(234, 70%, 50%)",
      children: [
        {
          name: "address",
          color: "hsl(288, 70%, 50%)",
          loc: 64130,
        },
        {
          name: "city",
          color: "hsl(221, 70%, 50%)",
          loc: 91558,
        },
        {
          name: "animal",
          color: "hsl(5, 70%, 50%)",
          loc: 17932,
        },
        {
          name: "movie",
          color: "hsl(343, 70%, 50%)",
          loc: 38926,
        },
        {
          name: "user",
          color: "hsl(316, 70%, 50%)",
          loc: 138682,
        },
      ],
    },
    {
      name: "set",
      color: "hsl(193, 70%, 50%)",
      children: [
        {
          name: "clone",
          color: "hsl(306, 70%, 50%)",
          loc: 8378,
        },
        {
          name: "intersect",
          color: "hsl(343, 70%, 50%)",
          loc: 11604,
        },
        {
          name: "merge",
          color: "hsl(343, 70%, 50%)",
          loc: 183297,
        },
        {
          name: "reverse",
          color: "hsl(240, 70%, 50%)",
          loc: 103688,
        },
        {
          name: "toArray",
          color: "hsl(330, 70%, 50%)",
          loc: 31694,
        },
        {
          name: "toObject",
          color: "hsl(186, 70%, 50%)",
          loc: 14587,
        },
        {
          name: "fromCSV",
          color: "hsl(346, 70%, 50%)",
          loc: 177751,
        },
        {
          name: "slice",
          color: "hsl(154, 70%, 50%)",
          loc: 34799,
        },
        {
          name: "append",
          color: "hsl(201, 70%, 50%)",
          loc: 130616,
        },
        {
          name: "prepend",
          color: "hsl(271, 70%, 50%)",
          loc: 127947,
        },
        {
          name: "shuffle",
          color: "hsl(139, 70%, 50%)",
          loc: 118678,
        },
        {
          name: "pick",
          color: "hsl(116, 70%, 50%)",
          loc: 27926,
        },
        {
          name: "plouc",
          color: "hsl(280, 70%, 50%)",
          loc: 56071,
        },
      ],
    },
    {
      name: "text",
      color: "hsl(32, 70%, 50%)",
      children: [
        {
          name: "trim",
          color: "hsl(300, 70%, 50%)",
          loc: 127513,
        },
        {
          name: "slugify",
          color: "hsl(257, 70%, 50%)",
          loc: 191574,
        },
        {
          name: "snakeCase",
          color: "hsl(193, 70%, 50%)",
          loc: 66189,
        },
        {
          name: "camelCase",
          color: "hsl(285, 70%, 50%)",
          loc: 73309,
        },
        {
          name: "repeat",
          color: "hsl(111, 70%, 50%)",
          loc: 170123,
        },
        {
          name: "padLeft",
          color: "hsl(321, 70%, 50%)",
          loc: 197209,
        },
        {
          name: "padRight",
          color: "hsl(265, 70%, 50%)",
          loc: 125236,
        },
        {
          name: "sanitize",
          color: "hsl(276, 70%, 50%)",
          loc: 174268,
        },
        {
          name: "ploucify",
          color: "hsl(223, 70%, 50%)",
          loc: 194304,
        },
      ],
    },
    {
      name: "misc",
      color: "hsl(126, 70%, 50%)",
      children: [
        {
          name: "greetings",
          color: "hsl(300, 70%, 50%)",
          loc: 79690,
        },
        {
          name: "other",
          color: "hsl(150, 70%, 50%)",
          loc: 16906,
        },
        {
          name: "path",
          color: "hsl(147, 70%, 50%)",
          loc: 56412,
        },
      ],
    },
  ],
}

export const sunburstParameters = {
  margin: { top: 10, right: 10, bottom: 10, left: 10 },
  id: "name",
  value: "loc",
  cornerRadius: 2,
  borderWidth: 3,
  borderColor: {
    theme: "background",
  },
  colors: { scheme: "blues" },
  childColor: {
    from: "color",
    modifiers: [["brighter", 0.1]],
  },
  enableArcLabels: true,
  arcLabelsSkipAngle: 14,
  arcLabelsTextColor: {
    from: "color",
    modifiers: [["darker", 2]],
  },
}
