import { TableTooltip, TooltipWrapper } from "@nivo/tooltip"
import { getFormattedValue } from "../../../utils/utils"

const CustomPieTooltip = ({ id, value, datum, formatParams }) => {
  const stringStyle = {
    color: "#A9A9A9",
    fontSize: "0.8rem",
    fontWeight: 200,
  }
  const tooltipStyle = {
    fontSize: "0.8rem",
    fontWeight: 200,
  }

  const rows = [
    ["", <span style={tooltipStyle}>Value</span>],
    ...Object.keys(datum.data).map((key) => [
      <span style={stringStyle}>{key}:</span>,
      <span style={tooltipStyle}>
        {key === "value"
          ? getFormattedValue(datum.data[key], formatParams)
          : datum.data[key]}
      </span>,
    ]),
  ].filter(Boolean)

  return (
    <TooltipWrapper anchor="top" position={[0, 0]}>
      <div
        style={{
          borderRadius: "3px",
          minWidth: "230px",
          color: "#fff",
          background: "rgba(0, 0, 0, 0.99)",
        }}
      >
        <TableTooltip rows={rows} />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "-9px",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid #071339",
        }}
      />
    </TooltipWrapper>
  )
}

export default CustomPieTooltip
