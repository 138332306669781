export const TimeLineParameters = {
  margin: { top: 30, right: 10, bottom: 40, left: 44 },
  padding: 0.85,
  borderRadius: 0,
  enableLabel: false,
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    truncateTickAt: 0,
  },
  keys: ["Normal", "Positive", "Negative"],
  indexBy: "month",

  axisBottom: {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legendOffset: 32,
    truncateTickAt: 0,
  },
  labelTextColor: {
    from: "color",
    modifiers: [["darker", 1.6]],
  },
  colors: ["#50CD89", "#FEBE10", "#EE000C"],
  valueScale: { type: "linear" },
  indexScale: { type: "band", round: true },
}
