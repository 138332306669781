import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { formatDate } from "../../utils/dateUtils"

const initialState = {
  fromDate: formatDate(
    dayjs().subtract(1, "month").startOf("month"),
    "YYYYMMDD"
  ),
  toDate: formatDate(dayjs().subtract(1, "month").endOf("month"), "YYYYMMDD"),
  label: {
    shortcutLabel: "Last Month",
    formattedRange: `${formatDate(
      dayjs().subtract(1, "month").startOf("month"),
      "MMM D"
    )} - ${formatDate(dayjs().subtract(1, "month").endOf("month"), "MMM D")}`,
  },
}

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setDateRangeLabel: (state, action) => {
      state.fromDate = formatDate(action.payload.fromDate, "YYYYMMDD")
      // intentional null to force user to pick end date
      state.toDate =
        action.payload.toDate === null
          ? null
          : formatDate(action.payload.toDate, "YYYYMMDD")
      state.label = action.payload.label
    },
  },
})

export const { setDateRangeLabel } = calendarSlice.actions

export default calendarSlice.reducer
