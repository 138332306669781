const OnTheFlyLogo = ({ width = "77", height = "20" }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 392 120"
      enable-background="new 0 0 392 120"
      xml="preserve"
    >
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M1.000000,86.000000 
	C1.000000,57.696346 1.000000,29.392691 1.000000,1.000000 
	C111.687546,1.000000 222.375107,1.000000 333.530670,1.393332 
	C333.741974,2.875391 333.796814,4.205516 333.173401,5.010065 
	C330.778534,8.100656 327.448486,10.684262 325.836334,14.102653 
	C324.214020,17.542591 321.786316,18.445272 318.457031,18.965441 
	C317.762756,18.441740 317.324738,18.056408 316.664612,17.477036 
	C315.607025,17.581030 314.771515,17.879065 313.836426,18.341711 
	C313.736847,18.506325 313.374481,18.635592 313.153503,18.275558 
	C311.875183,17.684435 310.731995,17.070997 309.774719,17.285872 
	C304.547729,18.459183 303.697876,23.231741 307.321960,30.494564 
	C307.101074,31.229639 306.935822,31.638191 306.591339,32.176441 
	C306.201233,32.533287 306.088715,32.801708 306.102112,33.108852 
	C306.129669,33.106304 306.150848,33.157963 305.758850,33.114944 
	C303.889984,34.188747 302.028687,36.540611 300.999176,36.220169 
	C294.731537,34.269325 296.298126,39.259243 296.145874,41.789452 
	C295.946899,45.095062 294.767609,46.876720 291.567749,47.684502 
	C291.140350,46.117035 290.780670,44.797939 290.420990,43.478848 
	C288.949463,47.221039 287.043488,49.836857 282.766083,50.019096 
	C279.502533,50.158146 276.254486,50.661736 272.733337,51.003700 
	C272.290009,51.015961 272.112885,51.027679 271.593750,50.944614 
	C269.868378,50.675167 268.485077,50.500500 266.884644,49.999851 
	C261.996429,49.224915 257.303345,48.923676 252.668289,48.232876 
	C251.355499,48.037224 249.989197,46.907944 249.030167,45.857441 
	C245.108566,41.561775 247.210693,36.714314 248.445206,32.003899 
	C254.038116,32.400040 259.192261,32.797672 265.109161,33.254154 
	C264.083130,30.033298 263.400696,27.891098 262.762299,25.887136 
	C260.686310,25.887136 258.894806,25.887136 257.072388,25.505754 
	C257.039337,22.350060 259.043243,18.739386 255.113327,17.472359 
	C253.914673,17.085907 251.813980,19.548067 250.096466,20.659355 
	C249.868607,20.806786 249.463058,20.679613 249.139801,20.679613 
	C247.600052,18.939438 245.805603,17.483339 243.827332,17.163275 
	C241.515442,16.789236 239.006561,17.762611 236.648102,17.519926 
	C231.551819,16.995512 226.817444,16.170822 222.322128,20.351652 
	C220.991333,21.589340 216.797134,19.787910 213.925964,19.313250 
	C212.453033,19.069740 210.965591,18.300213 209.552979,18.443178 
	C207.172989,18.684050 204.848267,19.466080 202.498672,20.013536 
	C198.861786,20.860941 194.260727,20.331841 193.797180,26.133854 
	C193.356613,26.522316 193.119461,26.760998 192.590576,27.046513 
	C192.026581,27.520008 191.754318,27.946674 191.482071,28.373341 
	C192.014038,28.566448 192.545990,28.759554 193.062500,28.959713 
	C193.047043,28.966768 193.068863,28.928720 193.047424,29.357313 
	C192.188034,34.834351 194.824295,36.254585 199.325699,36.032818 
	C202.730072,35.865105 206.149323,35.999603 208.471573,35.999603 
	C209.383469,40.115818 210.076782,43.245426 210.704514,46.700939 
	C210.482346,47.673740 210.325760,48.320641 209.854904,49.195309 
	C209.400543,50.696087 209.260452,51.969097 209.115570,53.442562 
	C209.110794,53.643017 208.932068,54.002010 208.684143,54.113144 
	C207.960968,54.485073 207.485733,54.745869 206.671402,54.859016 
	C199.955688,52.831230 193.615479,50.810074 187.182343,49.148716 
	C184.980621,48.580128 182.355774,48.575478 181.655716,51.945435 
	C179.025696,47.608116 176.022018,48.062717 171.919327,52.015331 
	C172.818359,47.856522 173.390381,45.210419 173.935654,42.688103 
	C169.833679,42.057137 166.408981,41.530350 162.844360,40.709831 
	C162.129410,39.283283 161.554367,38.150471 161.020325,36.668175 
	C161.038208,34.877068 161.015121,33.435440 161.127853,31.686497 
	C161.168030,29.928921 161.072388,28.478659 160.964264,26.726528 
	C160.633759,25.950045 160.315704,25.475430 159.851624,24.663126 
	C159.705582,22.427853 159.705582,20.530268 159.705582,18.564377 
	C157.457489,18.034174 155.401031,17.371723 153.295410,17.119263 
	C152.036911,16.968370 150.708008,17.556690 149.399368,17.645920 
	C143.296921,18.062021 142.582504,19.585182 145.898376,25.269300 
	C145.521729,26.017334 145.253418,26.507500 144.621780,26.934006 
	C142.476517,26.318520 139.484528,24.878630 139.123947,25.369953 
	C137.633011,27.401409 136.938736,30.017525 135.933548,32.463211 
	C129.525345,31.508720 128.546722,32.538231 130.209610,38.540829 
	C130.443069,39.383545 130.083649,40.372864 130.182236,41.274242 
	C130.391708,43.189274 130.722061,45.091084 131.003601,47.249062 
	C131.003906,47.666962 131.003479,47.834114 130.722778,48.136536 
	C130.598587,49.180290 130.754654,50.088772 130.877533,51.286537 
	C130.759247,51.750309 130.674164,51.924793 130.438782,52.257507 
	C130.128113,52.675121 130.060349,52.956841 130.098389,53.563934 
	C130.005844,54.255848 129.900131,54.644718 129.558685,55.273003 
	C129.246735,56.403625 129.170486,57.294834 129.101654,58.571365 
	C128.991196,59.975639 128.873352,60.994595 128.500702,62.176407 
	C127.834290,63.220806 127.422653,64.102356 126.822021,65.255356 
	C126.436172,67.627411 125.562706,69.960068 126.186584,71.779190 
	C127.398880,75.314018 126.191490,80.151909 131.114944,82.258209 
	C131.488632,83.009819 131.754257,83.502724 131.623795,84.009224 
	C128.079971,84.564262 128.553497,87.916374 129.716522,88.166023 
	C132.648575,88.795387 136.088562,92.291084 139.324310,87.963203 
	C140.127151,87.551689 140.608139,87.167648 141.406830,86.813095 
	C142.141678,86.925117 142.558792,87.007660 142.981079,87.322525 
	C142.986221,87.554848 142.998169,88.019447 142.997772,88.397629 
	C141.664902,90.183884 140.332458,91.591942 138.610123,93.006577 
	C133.180573,94.887802 128.342667,96.305397 127.329453,88.462128 
	C127.302605,88.254326 126.461823,88.151680 125.996254,87.633629 
	C125.203461,85.885429 124.403023,84.510254 123.631119,83.119247 
	C123.172951,82.293594 122.202385,80.900383 122.411072,80.685997 
	C125.000206,78.026199 122.540588,75.789200 121.983131,73.411613 
	C121.761375,72.465805 121.074074,71.175598 121.450905,70.612785 
	C125.450310,64.639557 120.073013,65.783455 117.129150,65.286118 
	C117.043716,62.377739 117.369591,59.327435 113.076569,59.620190 
	C112.859367,58.218361 112.744263,57.190983 112.952911,55.951683 
	C113.768158,48.834061 111.082840,41.999477 112.766975,35.025066 
	C112.862061,34.631290 110.755150,33.033131 110.202362,33.263809 
	C106.372681,34.861954 104.334747,33.455853 102.828995,29.903448 
	C102.322029,28.707401 100.834755,27.776148 99.596970,27.073788 
	C97.408325,25.831886 95.067520,24.858164 92.571442,23.667774 
	C93.427811,23.272673 94.491875,22.781744 95.850922,22.154724 
	C93.791183,20.397703 92.179115,17.944063 90.119911,17.481663 
	C85.241348,16.386173 80.150566,16.235777 74.204605,15.603234 
	C77.683830,11.549151 83.262672,9.084351 78.689095,2.827530 
	C77.404671,7.113566 75.863350,10.454687 70.548958,9.080551 
	C65.307632,8.492133 60.358746,6.190134 55.583885,10.289936 
	C55.137703,11.191952 54.955368,11.855273 54.512592,12.835189 
	C54.315937,14.736291 54.379723,16.320799 53.993050,18.022373 
	C52.959682,18.348736 52.376762,18.558031 51.651943,18.682955 
	C51.272549,18.642622 51.100582,18.773245 50.610542,18.998276 
	C49.763451,19.204765 49.299953,19.403431 48.504501,19.571318 
	C45.788059,20.385921 43.403572,21.231306 40.935730,21.685963 
	C38.412300,20.786478 35.972214,20.277721 32.604980,19.575653 
	C33.339149,22.817616 33.730309,24.544916 33.925262,26.514282 
	C32.441528,28.066238 31.154003,29.376125 29.625118,30.818403 
	C29.261448,31.087667 29.139137,31.224543 28.715826,31.436485 
	C27.219692,32.906914 26.024561,34.302273 24.524986,35.845215 
	C22.134615,38.991917 20.048687,41.991043 17.605461,45.063446 
	C16.858593,46.451271 16.469021,47.765816 16.075821,49.036171 
	C16.072197,48.991985 16.104929,49.075901 15.724809,49.109253 
	C14.570575,50.495693 13.280541,51.789120 13.115932,53.212685 
	C12.548162,58.122822 12.373132,63.078377 12.032581,68.373123 
	C11.958114,69.437492 11.899589,70.145363 11.528090,70.919075 
	C10.147173,71.667450 9.079229,72.349998 7.642675,73.095482 
	C6.898197,74.303368 5.965014,75.603889 6.234367,76.568703 
	C8.448712,84.500389 12.554846,90.566566 21.870243,91.256508 
	C21.966562,91.460739 22.050346,91.904503 22.027794,92.181244 
	C22.002754,92.642387 22.000271,92.826790 21.997940,93.345764 
	C21.996056,94.126434 21.994013,94.572525 21.620338,95.016953 
	C19.836367,95.351318 18.424026,95.687340 16.954393,95.671928 
	C14.931376,94.880325 12.965650,94.440163 10.615372,93.994080 
	C8.487220,93.658768 6.743619,93.329384 5.000010,93.000000 
	C5.000000,93.000000 4.999962,93.000000 4.994125,92.618477 
	C3.658858,90.157967 2.329429,88.078987 1.000000,86.000000 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M10.999924,94.000000 
	C12.965650,94.440163 14.931376,94.880325 16.954880,95.909126 
	C17.012661,96.497757 17.010944,96.972702 17.076244,97.345154 
	C18.761053,98.145073 20.380566,98.572533 22.338200,99.001801 
	C23.450882,99.335732 24.225441,99.667862 25.046360,100.371132 
	C27.728178,101.828072 30.363632,102.913879 32.999352,104.333023 
	C32.999744,105.110802 32.999874,105.555252 32.744690,106.193275 
	C32.659637,107.591240 32.829895,108.795616 33.010170,110.383156 
	C34.346745,111.510872 35.673294,112.255440 37.063824,113.354614 
	C38.751919,113.806152 40.376034,113.903076 42.440002,114.000000 
	C55.586571,114.000000 68.293289,114.000000 81.145432,114.225861 
	C81.860573,114.301147 82.430290,114.150574 83.416031,114.000000 
	C86.221382,113.666664 88.610687,113.333328 91.175293,113.324081 
	C95.859741,115.960960 96.966843,115.520050 98.000000,110.624863 
	C97.999687,109.499413 97.999382,108.749092 97.961700,107.636887 
	C96.693832,103.999039 94.714478,100.636230 99.302414,98.130096 
	C100.736549,98.173462 101.868271,98.086731 103.395653,97.999947 
	C105.194206,97.333160 106.597099,96.666435 108.374924,95.999664 
	C109.499695,95.999542 110.249542,95.999466 111.332832,95.999588 
	C112.110847,95.999863 112.555428,95.999931 113.156136,96.313492 
	C114.835968,97.258308 116.714546,98.737755 117.786415,98.289246 
	C118.923454,97.813469 119.294456,95.506981 120.375000,94.000000 
	C121.500000,94.000000 122.250000,94.000000 123.290268,94.222275 
	C124.619698,98.216873 127.032516,99.805649 130.902710,98.994064 
	C133.940918,98.356949 136.967987,97.666664 140.387177,97.000000 
	C142.182297,96.667900 143.590240,96.335793 145.240906,96.099350 
	C145.931046,96.463013 146.378479,96.731026 146.680145,97.302292 
	C146.387924,97.775703 146.241440,97.945854 146.093491,98.131577 
	C146.092010,98.147163 146.060318,98.163292 145.825027,98.209305 
	C145.298080,98.425789 145.104553,98.673103 145.006348,99.397522 
	C145.002151,100.865189 145.000778,101.932594 144.630493,103.074417 
	C144.171158,105.200607 143.441559,108.766281 144.100037,109.044281 
	C147.084610,110.304359 148.203674,116.139191 153.421783,113.000000 
	C156.562576,112.999802 159.281601,112.999596 162.333740,112.999420 
	C163.111038,112.999329 163.555222,112.999207 164.293091,113.295563 
	C170.724518,113.394699 176.862259,113.197350 183.388977,113.045082 
	C189.423447,115.195320 195.133896,116.070816 201.385101,114.044945 
	C208.078888,115.930267 208.974716,115.229042 209.240616,108.848488 
	C209.286346,107.751137 210.280609,105.901688 211.080795,105.757881 
	C215.730164,104.922356 215.592026,101.343689 216.374557,97.996246 
	C217.498108,97.992561 218.247116,97.990181 219.229065,98.267693 
	C221.648331,98.746101 223.872543,99.298256 226.013016,99.068962 
	C230.365936,98.602654 234.673660,97.714378 239.381744,97.166397 
	C244.508987,97.555206 249.254486,97.777603 254.285721,98.119614 
	C255.714294,98.159492 256.857147,98.079750 258.398438,97.992355 
	C262.951019,97.342140 267.135376,96.002762 271.254028,96.180977 
	C286.787811,96.853073 302.297302,98.074478 317.825897,98.901489 
	C319.179657,98.973587 320.606903,97.666473 322.412781,97.001015 
	C324.582611,97.338890 326.328247,97.859375 328.099365,97.969368 
	C330.723419,98.132347 333.365570,98.004211 336.399994,98.000000 
	C337.866669,98.000000 338.933319,98.000000 340.260834,98.281128 
	C342.682068,98.049683 344.842499,97.537102 347.391174,97.013260 
	C348.851593,96.004959 349.923676,95.007919 351.333374,93.969772 
	C352.120239,93.970604 352.569489,94.012535 353.234070,94.312225 
	C354.966278,95.046661 356.483124,95.523331 358.065033,96.369865 
	C362.133667,97.826317 366.111267,99.696968 370.146454,99.830696 
	C377.746796,100.082596 385.380280,99.333168 393.000000,99.000000 
	C393.000000,106.326073 393.000000,113.652138 393.000000,120.955261 
	C262.333313,120.955261 131.666656,120.955261 1.000000,120.955261 
	C1.000000,111.919449 1.000000,102.928383 1.399993,93.468658 
	C2.866644,93.000000 3.933303,93.000000 4.999962,93.000000 
	C4.999962,93.000000 5.000000,93.000000 5.004988,93.375229 
	C6.139246,95.124115 7.268517,96.497765 8.397787,97.871414 
	C9.265165,96.580940 10.132545,95.290474 10.999924,94.000000 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M393.000000,98.531342 
	C385.380280,99.333168 377.746796,100.082596 370.146454,99.830696 
	C366.111267,99.696968 362.133667,97.826317 358.411865,96.366318 
	C359.472321,95.628654 360.251038,95.264397 361.442932,94.878601 
	C363.230835,94.967743 364.605560,95.078407 366.072754,95.534439 
	C368.108093,95.928780 370.050995,95.977753 372.408875,96.041138 
	C374.204041,95.990662 375.584198,95.925774 377.227112,96.176575 
	C381.233429,95.984665 384.976929,95.477058 388.720459,94.969460 
	C387.234558,92.430244 385.761932,89.882980 384.236237,87.367912 
	C384.120819,87.177643 383.627716,87.216484 383.252350,87.136497 
	C381.840302,88.072792 380.397491,89.029480 378.928162,89.665985 
	C378.270813,88.902000 377.640045,88.458191 376.693665,87.980362 
	C375.575623,87.723564 374.773163,87.500793 373.591583,87.155746 
	C372.158112,86.988152 371.103760,86.942833 369.617432,86.878716 
	C364.775421,86.297234 360.365356,85.734535 355.884094,84.879120 
	C355.761475,84.392242 355.710144,84.198074 355.805389,83.675369 
	C356.842499,81.983215 358.035187,80.728447 358.572144,79.237503 
	C361.119385,72.164810 365.703247,66.754150 371.642517,62.314568 
	C372.652527,61.559608 373.292267,60.309383 374.439880,59.188274 
	C377.163666,57.384045 379.550720,55.679504 382.176514,53.845490 
	C382.262909,53.156563 382.110504,52.597111 381.963623,52.029396 
	C381.969147,52.021133 381.949158,52.037083 381.866394,51.767174 
	C381.520020,50.999065 381.256348,50.500870 381.268433,49.764606 
	C381.397766,48.274906 381.096802,47.018616 381.134338,45.772522 
	C381.241791,42.207588 379.740479,40.601585 376.071136,41.004898 
	C372.380188,41.410595 370.255737,42.958302 370.657837,46.964218 
	C368.748138,45.963013 367.173309,45.014534 365.419678,43.927444 
	C365.240875,43.788830 365.081268,43.365490 365.426086,43.242493 
	C365.846436,42.415920 365.921997,41.712341 365.998230,40.591389 
	C366.325867,33.250465 361.905731,36.795074 358.842712,36.693794 
	C357.456940,35.924957 356.228485,35.462479 354.979309,34.702164 
	C354.639069,33.936119 354.319519,33.467915 354.000000,32.589897 
	C353.000427,27.119175 352.000824,22.058262 351.376312,16.920761 
	C352.166779,14.897314 352.582184,12.950451 352.998291,10.564901 
	C353.626068,6.549575 352.561798,3.528730 350.000000,1.000000 
	C364.288727,1.000000 378.577423,1.000000 393.000000,1.000000 
	C393.000000,33.354115 393.000000,65.708397 393.000000,98.531342 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M318.713226,19.103811 
	C321.786316,18.445272 324.214020,17.542591 325.836334,14.102653 
	C327.448486,10.684262 330.778534,8.100656 333.173401,5.010065 
	C333.796814,4.205516 333.741974,2.875391 333.999329,1.393332 
	C335.714355,1.000000 337.428711,1.000000 339.571533,1.250282 
	C339.999603,1.667418 339.999176,1.834273 339.701935,2.001734 
	C338.900055,2.096802 338.395050,2.191263 337.890015,2.285724 
	C337.968292,2.523796 338.046570,2.761867 338.124817,2.999938 
	C338.749481,2.666937 339.374146,2.333935 340.374084,2.000396 
	C341.499542,1.999901 342.249725,1.999945 343.036133,2.313037 
	C343.714935,3.417850 344.357452,4.209616 344.693665,5.135297 
	C343.221436,6.581350 342.055481,7.893489 340.598755,9.034042 
	C338.588898,8.593740 336.869781,8.325023 334.800720,8.080159 
	C331.907135,10.063841 329.507690,12.048727 333.181702,15.440210 
	C332.715546,16.854881 332.303101,17.927641 331.599548,19.039124 
	C331.254578,19.739519 331.200653,20.401192 331.126160,21.409761 
	C330.964081,22.195526 330.822632,22.634392 330.514130,23.213785 
	C330.157166,23.594643 330.061707,23.868006 330.041382,24.544544 
	C329.268738,27.293007 328.515503,29.671326 327.574524,32.206360 
	C327.176941,32.634418 327.079712,32.941456 327.121887,33.571056 
	C327.160919,34.241543 327.173187,34.625160 327.032837,35.321873 
	C326.819061,36.093178 326.757843,36.551388 326.637573,37.126556 
	C326.578522,37.243523 326.731232,37.456501 326.471375,37.705597 
	C325.835999,39.297039 325.460419,40.639385 325.005737,42.187004 
	C324.926666,42.392273 324.903412,42.831627 324.676025,42.990574 
	C324.233917,43.450653 324.019196,43.751785 323.585449,44.257591 
	C323.280823,45.048130 323.195160,45.633995 323.086914,46.547371 
	C322.749420,47.919422 322.434509,48.963966 321.765930,50.038200 
	C319.608154,51.378010 317.804077,52.688122 315.762695,54.081993 
	C315.364258,54.212402 315.203125,54.259048 314.816833,54.107330 
	C314.400055,53.851841 314.208344,53.794720 313.868866,53.640572 
	C313.721069,53.543549 313.368958,53.576008 313.331299,53.583023 
	C313.293640,53.590038 313.223114,53.619843 313.087158,53.407654 
	C312.636078,53.065990 312.320892,52.936520 311.970276,52.512527 
	C311.566711,51.788750 311.198608,51.359486 310.845490,50.556023 
	C311.625641,48.121498 312.390808,46.061172 313.542908,43.984299 
	C314.584839,41.950451 315.239838,39.933151 315.879150,37.907642 
	C315.863464,37.899437 315.852448,37.865749 316.270111,37.899513 
	C320.863007,37.009949 318.333527,33.800144 318.839722,31.325483 
	C319.146698,30.316420 319.452881,29.607191 320.158325,28.922901 
	C321.383667,27.666397 322.209717,26.384954 323.078186,24.752554 
	C322.475983,23.627794 321.831390,22.853992 320.821838,22.053730 
	C319.969910,22.016897 319.483032,22.006525 319.076172,21.629799 
	C319.008575,20.543568 318.860901,19.823689 318.713226,19.103811 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M345.000000,5.001374 
	C344.357452,4.209616 343.714935,3.417850 343.036255,2.063041 
	C343.000061,1.499997 343.000000,1.000000 343.000000,1.000000 
	C345.041656,1.000000 347.083344,1.000000 349.562500,1.000000 
	C352.561798,3.528730 353.626068,6.549575 352.665588,10.564245 
	C351.888519,11.002801 351.444855,11.003327 350.844055,10.697037 
	C349.457520,9.926916 348.228149,9.463613 346.995972,8.651525 
	C346.328796,7.202284 345.664398,6.101825 345.000000,5.001374 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M4.994125,92.618477 
	C3.933303,93.000000 2.866644,93.000000 1.399993,93.000000 
	C1.000000,90.958336 1.000000,88.916664 1.000000,86.437500 
	C2.329429,88.078987 3.658858,90.157967 4.994125,92.618477 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M342.625000,1.000000 
	C343.000000,1.000000 343.000061,1.499997 342.999969,1.749993 
	C342.249725,1.999945 341.499542,1.999901 340.374695,1.999929 
	C340.000000,2.000000 339.998779,2.001127 339.998779,2.001127 
	C339.999176,1.834273 339.999603,1.667418 340.000000,1.250282 
	C340.750000,1.000000 341.500000,1.000000 342.625000,1.000000 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M272.999542,51.003159 
	C276.254486,50.661736 279.502533,50.158146 282.766083,50.019096 
	C287.043488,49.836857 288.949463,47.221039 290.420990,43.478848 
	C290.780670,44.797939 291.140350,46.117035 291.567749,47.684502 
	C294.767609,46.876720 295.946899,45.095062 296.145874,41.789452 
	C296.298126,39.259243 294.731537,34.269325 300.999176,36.220169 
	C302.028687,36.540611 303.889984,34.188747 305.705505,33.475586 
	C303.256256,43.625858 297.378510,52.443523 297.163391,63.332329 
	C296.653503,64.770325 296.268860,65.877289 295.632202,67.231079 
	C294.760162,70.565147 294.140137,73.652397 293.520142,76.739647 
	C293.897522,76.814621 294.274902,76.889595 294.652283,76.964569 
	C292.763977,78.140404 290.875702,79.316231 288.529297,80.567490 
	C284.015930,80.647491 279.960632,80.652069 275.604156,80.445152 
	C272.882721,80.424194 270.462494,80.614716 267.605591,80.872162 
	C264.461151,81.315758 261.753387,81.692436 258.770447,82.047318 
	C258.315582,82.064697 258.135803,82.103867 257.681396,82.038017 
	C257.237061,81.834976 257.067322,81.736969 256.625244,81.431870 
	C254.582504,81.415215 252.812119,81.605637 250.600128,81.865463 
	C247.085266,82.246887 244.011963,82.558914 240.475632,82.820709 
	C232.427277,82.884148 224.841980,82.997826 216.881622,83.064911 
	C215.668045,82.361412 214.829483,81.704491 213.985474,81.016815 
	C213.980011,80.986069 214.040451,80.970306 214.338135,80.818153 
	C214.655991,79.419228 214.676193,78.172447 214.702881,76.507675 
	C214.871307,74.706779 215.033218,73.323883 215.414185,71.727356 
	C215.702271,70.911293 215.771301,70.308853 215.834869,69.356750 
	C215.949020,68.319534 216.068634,67.631981 216.402588,66.747223 
	C216.700867,65.983070 216.784790,65.416122 216.883041,64.521912 
	C216.999802,63.770912 217.102219,63.347164 217.402298,62.755474 
	C217.669937,62.251850 217.739914,61.916168 217.760590,61.310375 
	C217.778091,60.682259 217.844879,60.324249 218.279541,59.915066 
	C219.374527,56.499264 220.101624,53.134636 220.856003,49.399158 
	C222.886703,43.741890 224.890152,38.455467 226.918152,32.867641 
	C226.938675,32.364677 226.934647,32.163116 226.960266,31.958904 
	C226.989899,31.956257 226.933945,31.933771 227.370041,31.953743 
	C229.547012,31.979948 231.287888,31.986179 233.018021,32.362720 
	C232.960373,33.471741 232.913467,34.210457 232.646606,35.158859 
	C232.302612,35.953545 232.178558,36.538536 232.029938,37.360077 
	C232.005356,37.596630 231.795685,38.023575 231.600189,38.170391 
	C231.177475,38.577831 231.072418,38.880863 231.038788,39.567875 
	C230.266953,41.601917 229.545853,43.294380 228.475098,45.073845 
	C227.774384,46.495239 227.423309,47.829639 227.051041,49.388885 
	C227.029831,49.613731 226.846680,50.026619 226.621124,50.216171 
	C226.280106,50.973003 226.164627,51.540279 226.043823,52.345383 
	C226.038498,52.583202 225.861725,53.024899 225.631760,53.221611 
	C225.294724,54.003101 225.187653,54.587879 225.070740,55.386230 
	C225.060898,55.599808 224.889725,55.991657 224.516373,56.086990 
	C224.081131,59.123249 224.019241,62.064175 224.223190,65.183426 
	C224.994690,66.267830 225.500336,67.173897 226.091370,68.232178 
	C226.449707,68.308022 226.722656,68.231644 227.322891,68.089424 
	C228.094528,67.997231 228.538849,67.970886 228.993866,68.336227 
	C230.312286,69.763374 231.682236,71.732185 232.915497,71.650002 
	C235.967514,71.446617 238.774155,70.145889 238.185028,65.808563 
	C240.180069,64.298149 241.941376,63.028107 243.795959,62.140678 
	C245.813080,67.423859 249.920898,65.972572 253.668747,66.134109 
	C256.135925,66.240448 258.560150,67.343521 261.186646,68.254616 
	C261.956390,69.416862 262.365295,70.572800 263.182739,71.159012 
	C263.918243,71.686462 265.101929,71.588913 266.431915,71.795059 
	C267.465363,71.845909 268.154846,71.868744 269.197357,71.929352 
	C270.363678,71.644249 271.176941,71.321373 272.292908,70.830002 
	C275.855530,68.532501 279.115479,66.403481 282.700806,64.185364 
	C284.525330,63.120892 286.108185,62.250168 287.497894,61.138035 
	C288.671112,60.199112 289.620239,58.980160 290.668549,57.885155 
	C289.151978,57.282242 287.635437,56.679329 286.072083,55.694279 
	C283.581116,51.958397 281.851166,55.099903 279.545654,55.928585 
	C278.431152,55.009548 277.644531,54.150497 276.881073,53.316776 
	C275.471680,54.591667 274.185547,55.755066 272.967224,56.690960 
	C273.035065,56.463451 273.174438,56.009563 273.313507,55.924301 
	C273.689453,55.541817 273.650574,55.273018 273.297180,54.689114 
	C273.167633,53.895443 273.076935,53.445309 273.015991,52.748680 
	C273.045715,52.502186 272.972687,52.011028 272.984711,51.759201 
	C272.997681,51.339306 272.998596,51.171230 272.999542,51.003159 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M146.825897,96.999039 
	C146.378479,96.731026 145.931046,96.463013 144.992966,95.819427 
	C143.001541,95.295898 141.500763,95.147949 139.754730,94.832794 
	C139.339630,94.110397 139.169815,93.555199 139.000000,93.000000 
	C140.332458,91.591942 141.664902,90.183884 143.293427,88.373093 
	C144.061142,87.660805 144.532791,87.351234 145.279755,86.973305 
	C145.931915,86.898285 146.308746,86.891617 146.963959,86.844604 
	C147.498322,86.544998 147.754303,86.285736 148.216248,85.868515 
	C148.865112,85.444862 149.308014,85.179161 150.021729,84.852234 
	C150.517227,84.510582 150.741943,84.230164 150.982330,83.975250 
	C150.998001,84.000748 150.950089,83.964676 151.184296,83.872269 
	C151.602646,83.507416 151.786804,83.234962 151.971558,82.980919 
	C151.972168,82.999329 151.953644,82.961662 152.311691,82.923126 
	C153.113647,81.879013 153.557541,80.873428 154.231293,79.680794 
	C155.008911,78.659882 155.556671,77.826012 156.316833,76.857178 
	C156.665558,76.421333 156.801865,76.120445 156.971802,75.600121 
	C157.005402,75.380676 157.190521,74.977089 157.387909,74.829254 
	C157.814667,74.418732 157.924698,74.113022 157.941437,73.455215 
	C158.008743,72.943520 158.050003,72.740898 158.331482,72.636780 
	C158.715240,72.832100 158.858734,72.928902 159.220581,73.284027 
	C160.538986,73.696678 161.639053,73.851013 162.745880,74.339127 
	C163.989914,78.159340 165.289200,75.725990 166.515472,74.685730 
	C168.319031,73.155754 170.076324,71.571228 172.007416,70.289185 
	C173.102615,71.004967 174.043732,71.441498 175.020203,72.195404 
	C175.630524,73.014908 176.205521,73.517044 177.087708,74.246223 
	C178.535324,74.583351 179.979782,75.198257 180.767914,74.723526 
	C186.980453,70.981316 193.084106,67.055733 199.118195,63.031963 
	C199.309814,62.904194 198.389114,61.108402 197.987640,59.741718 
	C197.992645,58.929127 197.996933,58.465023 198.135193,57.945198 
	C198.179138,57.925919 198.089111,57.962360 197.610138,58.004688 
	C196.443314,58.025490 195.665436,58.040405 194.566193,58.020172 
	C191.976730,58.722958 189.695053,59.422092 187.444031,60.208935 
	C184.794342,61.135136 182.171234,62.137363 179.232224,63.218601 
	C180.176666,54.398857 180.113098,54.303673 174.645248,55.890324 
	C173.857605,56.222958 173.370407,56.537617 172.588303,56.883034 
	C171.815536,57.223827 171.337662,57.533863 170.525986,57.904976 
	C166.982864,61.215908 164.707214,57.559826 162.266937,56.589317 
	C163.329376,54.711578 164.910202,53.068695 164.830505,51.510750 
	C164.650208,47.986095 163.650665,44.503349 162.984299,41.003563 
	C166.408981,41.530350 169.833679,42.057137 173.935654,42.688103 
	C173.390381,45.210419 172.818359,47.856522 171.919327,52.015331 
	C176.022018,48.062717 179.025696,47.608116 181.661102,52.288216 
	C182.335449,53.296909 182.660126,53.908253 182.984818,54.519592 
	C183.266403,54.222687 183.547989,53.925785 183.829575,53.628883 
	C183.219711,53.085922 182.609863,52.542961 182.000000,52.000000 
	C182.355774,48.575478 184.980621,48.580128 187.182343,49.148716 
	C193.615479,50.810074 199.955688,52.831230 206.661926,55.244904 
	C207.039108,57.869892 207.086685,59.961349 207.150482,62.383362 
	C207.082077,63.145618 206.997452,63.577324 206.626358,64.166321 
	C206.258682,65.561020 206.177475,66.798431 206.095490,68.365601 
	C206.019653,69.126419 205.944626,69.557495 205.551758,70.049217 
	C203.857315,72.900551 202.762054,75.627640 206.879959,77.574806 
	C207.284302,79.973434 207.642212,81.984161 207.739517,84.079605 
	C207.116760,84.161827 206.754608,84.159332 205.981277,84.103706 
	C199.384918,85.012100 193.199768,85.973625 186.692749,87.014633 
	C185.929626,87.106110 185.488358,87.118111 184.866058,86.863846 
	C183.127792,87.043633 181.570572,87.489670 179.732391,88.019836 
	C179.062912,88.107712 178.674362,88.111458 178.054016,88.115707 
	C177.504303,88.177856 177.252304,88.341095 176.651932,88.726349 
	C173.963318,88.957016 171.688965,89.067253 169.082138,89.132690 
	C168.175690,89.342613 167.601700,89.597328 166.692230,89.944870 
	C165.663254,90.066589 164.969788,90.095474 163.962616,90.089676 
	C163.109985,90.307632 162.571060,90.560280 161.707870,90.909454 
	C160.935486,91.040428 160.487366,91.074867 159.835999,90.841171 
	C157.752808,91.350563 155.872910,92.128105 153.816650,92.996216 
	C153.640320,93.086792 153.243881,93.091576 153.001221,93.119400 
	C152.433212,93.254517 152.192856,93.460770 151.851044,93.887527 
	C151.664612,94.009064 151.227005,94.090317 150.981384,94.125511 
	C150.410141,94.283791 150.173065,94.502693 149.761230,95.002213 
	C148.607285,95.791016 147.716599,96.395027 146.825897,96.999039 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M112.629158,56.163605 
	C112.744263,57.190983 112.859367,58.218361 113.025055,60.035965 
	C113.714233,64.303505 113.477150,68.303085 115.174461,71.148964 
	C118.663818,76.999512 118.416512,83.309845 112.407654,86.148125 
	C107.583313,88.426895 104.115929,91.726166 100.522079,95.608505 
	C99.788025,95.981453 99.318306,96.264290 98.455627,96.568298 
	C92.595779,96.516266 92.511086,102.720100 88.382507,104.660843 
	C87.362640,104.748703 86.679741,104.776428 85.997993,104.443512 
	C85.997986,103.120140 85.996819,102.157410 86.281807,100.979279 
	C87.255165,100.056442 88.367348,99.447151 88.557228,98.624870 
	C89.458878,94.720169 92.218987,93.891350 95.623589,92.381638 
	C101.093033,89.956329 105.840042,85.773537 110.613312,81.982117 
	C112.109795,80.793457 112.708855,78.474968 113.718689,76.673630 
	C111.823700,76.058632 109.941261,74.958824 108.031647,74.909431 
	C97.712708,74.642548 87.387032,74.635895 77.131851,74.248177 
	C78.138092,73.010040 79.076157,72.063240 80.479591,71.066650 
	C90.158905,71.101807 99.372864,71.186768 108.792480,71.632996 
	C110.105553,72.213203 111.212967,72.432152 112.320381,72.651100 
	C112.356491,72.231407 112.392601,71.811714 112.428711,71.392021 
	C111.198555,71.261566 109.968407,71.131111 108.852997,70.620827 
	C110.453598,56.671848 106.059952,44.930244 96.352135,35.788097 
	C91.134933,30.874891 84.234711,27.748837 78.296936,23.588564 
	C80.376511,23.091917 82.173851,22.762028 81.345642,20.105446 
	C97.275078,27.595928 107.921700,39.334774 112.629158,56.163605 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M112.952911,55.951683 
	C107.921700,39.334774 97.275078,27.595928 80.949005,20.058586 
	C77.819962,19.348158 75.445107,18.657269 73.022934,17.557724 
	C72.305832,14.474281 71.636055,11.799496 70.966278,9.124710 
	C75.863350,10.454687 77.404671,7.113566 78.689095,2.827530 
	C83.262672,9.084351 77.683830,11.549151 74.204605,15.603234 
	C80.150566,16.235777 85.241348,16.386173 90.119911,17.481663 
	C92.179115,17.944063 93.791183,20.397703 95.850922,22.154724 
	C94.491875,22.781744 93.427811,23.272673 92.571442,23.667774 
	C95.067520,24.858164 97.408325,25.831886 99.596970,27.073788 
	C100.834755,27.776148 102.322029,28.707401 102.828995,29.903448 
	C104.334747,33.455853 106.372681,34.861954 110.202362,33.263809 
	C110.755150,33.033131 112.862061,34.631290 112.766975,35.025066 
	C111.082840,41.999477 113.768158,48.834061 112.952911,55.951683 
z"
      />
      <path
        fill="#D55E95"
        opacity="1.000000"
        stroke="none"
        d="
M130.589066,52.099281 
	C130.674164,51.924793 130.759247,51.750309 130.997253,51.159042 
	C131.150162,50.742264 131.222137,50.400196 131.464569,50.138546 
	C132.804443,47.251087 133.901886,44.625275 135.211029,41.811440 
	C135.625015,41.105019 135.827301,40.586632 136.230560,39.966347 
	C136.660019,39.634220 136.752625,39.358364 136.836273,38.842278 
	C136.963196,38.647667 137.068832,38.195141 137.299377,38.062630 
	C137.691513,37.622841 137.853134,37.315559 138.007080,37.004143 
	C137.999390,37.000000 138.009460,37.014816 138.220703,36.923935 
	C138.695877,36.627354 138.817169,36.357533 138.996033,35.806305 
	C139.470200,35.076233 139.744141,34.563454 140.241211,33.942974 
	C140.756332,33.632908 140.916077,33.354637 140.973083,33.001331 
	C141.002563,33.002197 140.987061,32.945286 141.267761,32.811852 
	C142.105759,31.817192 142.663071,30.955975 143.450256,29.962141 
	C144.135895,29.291828 144.591629,28.754129 145.363464,28.122112 
	C146.830872,27.038216 147.982178,26.048634 149.406433,25.019209 
	C150.144516,24.714655 150.609650,24.449945 151.437790,24.156321 
	C152.510101,23.970121 153.219376,23.812838 154.234756,23.767616 
	C155.313568,24.271530 156.086304,24.663385 156.897339,25.421448 
	C156.961182,26.522337 156.986694,27.257019 156.935913,28.295288 
	C156.254944,29.351185 155.650253,30.103495 154.897034,30.658897 
	C154.138596,30.640833 153.528687,30.819674 152.598419,31.047045 
	C151.835312,31.754473 151.392563,32.413368 150.638397,32.945915 
	C149.354080,32.937176 147.926056,32.679996 147.475189,33.227501 
	C137.577133,45.247169 131.490738,58.620029 133.153305,75.046234 
	C132.668091,76.402885 132.277969,77.373405 131.668625,78.246506 
	C131.449402,78.149086 130.986130,78.024330 130.993912,78.012505 
	C131.001694,78.000679 130.976532,78.012375 130.926727,77.664810 
	C130.278000,75.822815 129.679062,74.328392 129.092651,72.473854 
	C128.997894,71.402985 128.890610,70.692238 128.822510,69.658310 
	C128.754593,68.919266 128.647507,68.503410 128.582184,67.724854 
	C128.558533,66.882477 128.493134,66.402794 128.576355,65.553238 
	C128.735153,64.126770 128.745316,63.070160 128.755493,62.013554 
	C128.873352,60.994595 128.991196,59.975639 129.315567,58.309326 
	C129.612854,56.785847 129.703644,55.909721 129.794418,55.033592 
	C129.900131,54.644718 130.005844,54.255848 130.273682,53.415390 
	C130.642776,52.703175 130.693863,52.414997 130.589066,52.099281 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M194.000580,25.984074 
	C194.260727,20.331841 198.861786,20.860941 202.498672,20.013536 
	C204.848267,19.466080 207.172989,18.684050 209.552979,18.443178 
	C210.965591,18.300213 212.453033,19.069740 213.925964,19.313250 
	C216.797134,19.787910 220.991333,21.589340 222.322128,20.351652 
	C226.817444,16.170822 231.551819,16.995512 236.648102,17.519926 
	C239.006561,17.762611 241.515442,16.789236 243.827332,17.163275 
	C245.805603,17.483339 247.600052,18.939438 249.139801,20.679613 
	C249.463058,20.679613 249.868607,20.806786 250.096466,20.659355 
	C251.813980,19.548067 253.914673,17.085907 255.113327,17.472359 
	C259.043243,18.739386 257.039337,22.350060 257.042877,25.538555 
	C257.044281,25.952736 256.983643,26.016790 256.744080,26.002911 
	C256.504547,25.989033 256.024689,25.999830 255.807556,25.759132 
	C254.548416,25.299372 253.506424,25.080311 252.210083,24.624554 
	C250.407135,23.487665 249.022049,21.181955 246.736969,23.648794 
	C245.951019,23.317139 245.490036,23.155190 245.016815,22.992546 
	C245.004578,22.991850 245.018494,22.971056 245.059479,22.647160 
	C245.086914,21.889236 245.073349,21.455214 245.028046,21.023670 
	C244.996307,21.026150 245.046310,21.065557 245.231506,20.967546 
	C245.647171,20.746418 245.877640,20.623301 246.108124,20.500189 
	C245.757812,20.308382 245.407486,20.116573 245.024017,19.944786 
	C244.990860,19.964809 245.066208,19.949970 244.967529,19.634750 
	C244.246323,19.212999 243.623764,19.106466 242.724548,19.023766 
	C242.303665,19.163692 242.159454,19.279785 241.775421,19.544930 
	C240.779953,20.816595 240.024292,21.939213 238.817307,23.062334 
	C236.579514,23.186773 234.793045,23.310709 232.569946,23.354691 
	C229.100082,23.518675 226.066864,23.762609 223.020782,23.997391 
	C223.007935,23.988239 222.991241,24.014996 222.570496,23.960129 
	C220.467377,24.028051 218.785004,24.150843 216.710052,24.206959 
	C215.541412,24.273314 214.765366,24.406343 213.577301,24.430569 
	C206.777054,24.875868 200.388809,25.429970 194.000580,25.984074 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M100.786407,95.518387 
	C104.115929,91.726166 107.583313,88.426895 112.407654,86.148125 
	C118.416512,83.309845 118.663818,76.999512 115.174461,71.148964 
	C113.477150,68.303085 113.714233,64.303505 113.127151,60.410416 
	C117.369591,59.327435 117.043716,62.377739 117.129150,65.286118 
	C120.073013,65.783455 125.450310,64.639557 121.450905,70.612785 
	C121.074074,71.175598 121.761375,72.465805 121.983131,73.411613 
	C122.540588,75.789200 125.000206,78.026199 122.411072,80.685997 
	C122.202385,80.900383 123.172951,82.293594 123.631119,83.119247 
	C124.403023,84.510254 125.203461,85.885429 125.961700,87.973862 
	C124.954338,89.453842 123.977165,90.227226 122.678253,90.830414 
	C120.456146,88.249977 118.049492,86.444405 115.393623,89.012367 
	C113.472763,90.869644 112.432411,93.637581 110.999390,95.999390 
	C110.249542,95.999466 109.499695,95.999542 108.065094,95.865906 
	C105.920128,96.154587 104.459915,96.576988 102.645920,96.956818 
	C101.790230,96.448959 101.288315,95.983673 100.786407,95.518387 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M248.006439,32.005390 
	C247.210693,36.714314 245.108566,41.561775 249.030167,45.857441 
	C249.989197,46.907944 251.355499,48.037224 252.668289,48.232876 
	C257.303345,48.923676 261.996429,49.224915 266.735291,50.264809 
	C265.927124,51.372269 265.051178,51.888786 263.999451,52.095772 
	C260.912323,50.883446 260.015747,52.399899 259.999908,54.990749 
	C260.001343,55.009407 259.964783,55.001316 259.766510,55.076698 
	C259.316284,55.302116 259.133270,55.512180 258.703796,56.005100 
	C256.543823,56.627022 254.699158,57.026119 253.846603,57.210579 
	C251.517044,54.326553 249.763275,52.155350 248.005768,49.992073 
	C248.002045,50.000004 248.014969,49.990299 247.860809,49.725914 
	C247.139465,49.301739 246.572266,49.141945 245.754883,48.920998 
	C245.336258,48.893188 245.167831,48.926529 244.627075,48.926056 
	C242.196350,49.685196 240.137924,50.478153 237.839294,51.062126 
	C240.925385,44.380093 244.251678,37.907043 247.684937,31.576958 
	C247.791916,31.719919 248.006439,32.005390 248.006439,32.005390 
z"
      />
      <path
        fill="#F1E5F4"
        opacity="1.000000"
        stroke="none"
        d="
M155.045563,30.855806 
	C155.650253,30.103495 156.254944,29.351185 157.283676,28.300585 
	C158.797394,27.677662 159.887070,27.353029 160.976746,27.028397 
	C161.072388,28.478659 161.168030,29.928921 160.783142,31.744053 
	C157.749573,34.128239 159.033737,35.618542 160.979340,37.017654 
	C161.554367,38.150471 162.129410,39.283283 162.844360,40.709827 
	C163.650665,44.503349 164.650208,47.986095 164.830505,51.510750 
	C164.910202,53.068695 163.329376,54.711578 161.946808,56.776539 
	C159.883118,61.422756 158.374969,65.608337 156.626099,70.036667 
	C155.273834,72.186829 154.162338,74.094254 152.888367,75.736145 
	C152.416122,75.192711 152.106339,74.914810 151.802734,74.296371 
	C152.299850,72.694977 152.790771,71.434105 153.532867,69.922874 
	C155.389542,64.678375 157.850906,59.776073 158.398926,54.668480 
	C159.211121,47.098446 162.173218,38.756927 154.757080,32.061386 
	C154.937103,31.396732 154.991333,31.126270 155.045563,30.855806 
M161.288925,50.548752 
	C161.401581,49.967663 161.514236,49.386574 161.626877,48.805485 
	C161.216370,48.801010 160.805878,48.796535 160.395370,48.792061 
	C160.492203,49.375546 160.589035,49.959030 161.288925,50.548752 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M297.288666,63.001308 
	C297.378510,52.443523 303.256256,43.625858 306.097504,33.518604 
	C306.150848,33.157963 306.129669,33.106304 306.321411,33.016777 
	C306.755096,32.679375 306.840912,32.385876 306.770569,32.046745 
	C306.935822,31.638191 307.101074,31.229639 307.542175,30.236172 
	C309.670166,25.979368 311.522339,22.307480 313.374481,18.635592 
	C313.374481,18.635592 313.736847,18.506325 314.211060,18.296879 
	C315.419067,17.948648 316.152863,17.809864 316.886688,17.671078 
	C317.324738,18.056408 317.762756,18.441740 318.457031,18.965441 
	C318.860901,19.823689 319.008575,20.543568 318.752930,21.720959 
	C317.235809,23.796505 316.122009,25.414539 314.748230,27.260880 
	C312.380035,32.093948 310.271881,36.698715 308.119507,40.918468 
	C307.857513,39.514885 307.639648,38.496311 307.421783,37.477737 
	C305.501556,39.861340 304.459869,42.323414 303.749908,44.877712 
	C302.076965,50.896465 300.562225,56.959198 298.701050,63.002975 
	C298.041962,63.001644 297.665314,63.001476 297.288666,63.001308 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M140.018082,34.050674 
	C139.744141,34.563454 139.470200,35.076233 138.809952,35.921276 
	C138.197815,36.459522 138.059753,36.713284 138.009460,37.014816 
	C138.009460,37.014816 137.999390,37.000000 137.769104,37.128014 
	C137.382156,37.569065 137.225494,37.882103 137.068832,38.195137 
	C137.068832,38.195141 136.963196,38.647667 136.663025,38.967937 
	C136.156860,39.507641 136.045776,39.767654 136.029602,40.068245 
	C135.827301,40.586632 135.625015,41.105019 134.934418,41.935009 
	C133.630280,42.831631 132.814468,43.416653 131.945007,43.864006 
	C131.806961,43.676746 131.722565,43.627151 131.638153,43.577557 
	C131.759338,43.717937 131.880539,43.858318 131.978363,44.318027 
	C131.637634,45.424343 131.320251,46.211330 131.002869,46.998314 
	C130.722061,45.091084 130.391708,43.189274 130.182236,41.274242 
	C130.083649,40.372864 130.443069,39.383545 130.209610,38.540829 
	C128.546722,32.538231 129.525345,31.508720 135.933548,32.463211 
	C136.938736,30.017525 137.633011,27.401409 139.123947,25.369953 
	C139.484528,24.878630 142.476517,26.318520 144.631454,27.239016 
	C145.018753,27.810600 145.033066,28.013515 145.047379,28.216431 
	C144.591629,28.754129 144.135895,29.291828 143.087006,29.970253 
	C141.991592,31.055746 141.489319,32.000515 140.987061,32.945286 
	C140.987061,32.945286 141.002563,33.002197 140.754425,33.111382 
	C140.233826,33.432713 140.071091,33.709415 140.018082,34.050674 
z"
      />
      <path
        fill="#66C1BF"
        opacity="1.000000"
        stroke="none"
        d="
M17.962757,44.990166 
	C20.048687,41.991043 22.134615,38.991917 24.769938,36.179749 
	C26.072565,36.676697 26.825796,36.986691 27.385677,37.593422 
	C23.827946,45.258720 20.463562,52.627285 16.707520,60.079376 
	C15.496427,63.504696 14.676991,66.846489 13.609684,69.993668 
	C13.193167,69.197075 13.024525,68.595078 12.905808,67.556778 
	C14.005466,61.105606 15.055198,55.090755 16.104929,49.075901 
	C16.104929,49.075901 16.072197,48.991985 16.352455,48.852791 
	C17.076063,47.472454 17.519411,46.231308 17.962757,44.990166 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M210.770096,46.375038 
	C210.076782,43.245426 209.383469,40.115818 208.471573,35.999603 
	C206.149323,35.999603 202.730072,35.865105 199.325699,36.032818 
	C194.824295,36.254585 192.188034,34.834351 193.262024,29.443615 
	C193.774872,29.259884 193.984131,29.482075 194.180984,30.022596 
	C194.811432,30.328613 195.386703,30.379929 196.282837,30.376575 
	C197.036163,30.328299 197.468628,30.334692 197.883942,30.643587 
	C197.744507,35.745220 200.602097,33.956032 203.321228,33.225643 
	C204.144882,33.222095 204.615555,33.246353 205.170807,33.381367 
	C205.255402,33.492126 205.637497,33.524334 205.637497,33.524334 
	C205.637497,33.524334 205.903152,33.221176 206.340408,33.158585 
	C209.859863,32.748665 212.942047,32.401333 216.044800,32.420807 
	C215.648453,34.181843 215.231552,35.576073 214.724304,37.161293 
	C214.633957,37.352283 214.668457,37.773430 214.681030,37.811726 
	C214.693619,37.850021 214.771362,37.828659 214.487091,38.004967 
	C213.809723,39.452469 213.416641,40.723660 212.825165,42.279861 
	C212.311569,43.662243 211.996368,44.759613 211.514252,45.915478 
	C211.167435,46.125694 210.975021,46.259384 210.770096,46.375038 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M156.859024,25.055241 
	C156.086304,24.663385 155.313568,24.271530 154.066803,23.524258 
	C152.753433,23.507641 151.914093,23.846441 151.074768,24.185238 
	C150.609650,24.449945 150.144516,24.714655 149.015259,24.998383 
	C147.569641,25.015411 146.788193,25.013422 146.006729,25.011433 
	C142.582504,19.585182 143.296921,18.062021 149.399368,17.645920 
	C150.708008,17.556690 152.036911,16.968370 153.295410,17.119263 
	C155.401031,17.371723 157.457489,18.034174 159.705582,18.564377 
	C159.705582,20.530268 159.705582,22.427853 159.459488,24.663244 
	C158.428604,25.019114 157.643814,25.037176 156.859024,25.055241 
z"
      />
      <path
        fill="#D36194"
        opacity="1.000000"
        stroke="none"
        d="
M210.704514,46.700939 
	C210.975021,46.259384 211.167435,46.125694 211.790253,46.038666 
	C212.492447,46.402370 212.751709,46.701374 213.032043,47.317822 
	C213.615494,48.730934 214.177902,49.826603 214.740601,51.310295 
	C213.476883,56.797295 212.212830,61.896275 210.705948,66.799545 
	C210.100342,65.936768 209.737564,65.269707 209.305954,64.476044 
	C208.173508,66.804955 207.155258,68.899025 206.066193,70.743019 
	C205.953445,70.324806 205.911514,70.156685 205.869583,69.988564 
	C205.944626,69.557495 206.019653,69.126419 206.344482,68.113571 
	C206.700455,66.357536 206.806641,65.183281 206.912827,64.009033 
	C206.997452,63.577324 207.082077,63.145618 207.372772,62.088486 
	C208.029922,58.976044 208.481003,56.489025 208.932068,54.002010 
	C208.932068,54.002010 209.110794,53.643017 209.358276,53.155437 
	C209.793564,51.434422 209.981369,50.200985 210.169174,48.967545 
	C210.325760,48.320641 210.482346,47.673740 210.704514,46.700939 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M123.000000,91.000610 
	C123.977165,90.227226 124.954338,89.453842 125.966057,88.340225 
	C126.461823,88.151680 127.302605,88.254326 127.329453,88.462128 
	C128.342667,96.305397 133.180573,94.887802 138.610123,93.006577 
	C139.169815,93.555199 139.339630,94.110397 139.754730,95.166130 
	C140.000000,96.111107 140.000000,96.555557 140.000000,97.000000 
	C136.967987,97.666664 133.940918,98.356949 130.902710,98.994064 
	C127.032516,99.805649 124.619698,98.216873 123.290268,93.847343 
	C123.000000,92.500298 123.000000,91.750450 123.000000,91.000610 
z"
      />
      <path
        fill="#77C5C3"
        opacity="1.000000"
        stroke="none"
        d="
M70.548958,9.080550 
	C71.636055,11.799496 72.305832,14.474281 72.671234,17.600044 
	C69.980209,18.045477 67.593567,18.039932 65.155426,17.685165 
	C65.476158,15.765383 65.848389,14.194822 66.220627,12.624264 
	C64.629463,12.408462 63.039284,12.184972 61.446552,11.981488 
	C60.961784,11.919556 60.469975,11.912773 59.929344,11.547815 
	C58.534115,10.827014 57.190926,10.439128 55.847736,10.051243 
	C60.358746,6.190134 65.307632,8.492133 70.548958,9.080550 
z"
      />
      <path
        fill="#61BBBC"
        opacity="1.000000"
        stroke="none"
        d="
M22.050346,91.904503 
	C22.050346,91.904503 21.966562,91.460739 21.786602,90.912910 
	C20.359690,89.568497 19.205748,88.517052 17.848808,88.021851 
	C13.298199,86.361115 10.002574,82.062187 11.320196,78.855774 
	C13.787998,80.547775 15.917633,82.143616 18.365147,83.898125 
	C22.073298,86.026131 25.463570,87.995461 29.089661,89.904984 
	C29.882631,89.839066 30.439779,89.832954 30.990356,90.118332 
	C30.990524,90.603027 30.997261,90.796234 30.968958,91.328613 
	C30.222683,92.482674 29.511450,93.297562 28.403004,94.104187 
	C26.676403,94.052559 25.347013,94.009193 24.007622,93.982254 
	C23.997622,93.998688 24.030905,93.980103 23.937531,93.746040 
	C23.568464,93.333214 23.292770,93.154457 23.007977,92.986511 
	C22.998873,92.997322 23.019394,92.982895 22.950680,92.732780 
	C22.604759,92.289948 22.327553,92.097221 22.050346,91.904503 
z"
      />
      <path
        fill="#66C4C4"
        opacity="1.000000"
        stroke="none"
        d="
M41.019081,22.076691 
	C43.403572,21.231306 45.788059,20.385921 48.397728,19.773848 
	C49.068012,21.003344 49.513119,21.999529 49.735741,23.265718 
	C43.341698,27.007681 37.170143,30.479637 30.854389,33.635124 
	C30.428955,32.441109 30.147717,31.563562 29.866478,30.686014 
	C31.154003,29.376125 32.441528,28.066238 34.257195,26.417664 
	C36.863247,24.744881 38.941162,23.410784 41.019081,22.076691 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M197.901108,30.341084 
	C197.468628,30.334692 197.036163,30.328299 195.998962,30.210217 
	C194.971451,29.988319 194.548630,29.878109 194.125824,29.767897 
	C193.984131,29.482075 193.774872,29.259884 193.283463,29.015022 
	C193.068863,28.928720 193.047043,28.966768 193.052032,28.632515 
	C192.998795,27.865404 192.940552,27.432545 192.882309,26.999683 
	C193.119461,26.760998 193.356613,26.522316 193.797180,26.133854 
	C200.388809,25.429970 206.777054,24.875868 213.594055,24.764141 
	C214.383270,26.273209 214.743713,27.339897 214.754913,28.612946 
	C208.904144,29.326567 203.402634,29.833824 197.901108,30.341084 
M205.950439,26.830475 
	C207.373383,27.222795 208.796326,27.615116 210.219269,28.007435 
	C210.284790,27.464308 210.350311,26.921181 210.415848,26.378054 
	C209.097458,26.378054 207.779068,26.378054 205.950439,26.830475 
z"
      />
      <path
        fill="#60C5BD"
        opacity="1.000000"
        stroke="none"
        d="
M49.958221,22.995712 
	C49.513119,21.999529 49.068012,21.003344 48.729679,19.804628 
	C49.299953,19.403431 49.763451,19.204765 50.752083,18.989838 
	C51.461567,18.935223 51.633778,18.866472 51.793846,18.767324 
	C52.376762,18.558031 52.959682,18.348736 54.188560,17.711853 
	C54.814030,15.695710 54.793533,14.107152 54.773033,12.518595 
	C54.955368,11.855273 55.137703,11.191952 55.583885,10.289936 
	C57.190926,10.439128 58.534115,10.827014 59.620300,11.719821 
	C59.215366,13.258278 58.755329,14.367839 58.984699,15.309467 
	C59.457317,17.249689 60.298073,19.100237 60.722404,21.034966 
	C60.288757,21.143322 60.120049,21.204399 59.572311,21.204117 
	C57.003555,21.715069 54.813828,22.287380 52.290497,22.838612 
	C51.290668,22.876926 50.624447,22.936321 49.958221,22.995712 
z"
      />
      <path
        fill="#D6FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M18.047268,83.739449 
	C15.917633,82.143616 13.787998,80.547775 11.310474,78.503479 
	C9.978820,76.380859 8.995053,74.706696 8.011286,73.032539 
	C9.079229,72.349998 10.147173,71.667450 11.605534,70.992722 
	C11.995952,71.000542 12.144432,71.148796 12.068295,71.535461 
	C13.379707,72.888039 14.668854,74.441422 16.175915,74.693871 
	C19.044888,75.174431 22.038353,74.911797 24.984207,75.307014 
	C24.988335,76.121910 24.988377,76.587547 24.551329,77.089539 
	C21.571295,77.125893 19.028351,77.125893 15.752263,77.125893 
	C16.886435,78.999313 17.461651,79.949448 18.030327,81.255356 
	C18.031612,82.320564 18.039440,83.030006 18.047268,83.739449 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M257.073792,25.919937 
	C258.894806,25.887136 260.686310,25.887136 262.762299,25.887136 
	C263.400696,27.891098 264.083130,30.033298 265.109161,33.254154 
	C259.192261,32.797672 254.038116,32.400040 248.445206,32.003899 
	C248.006439,32.005390 247.791916,31.719919 248.040573,31.458416 
	C250.197021,30.723783 252.104813,30.250654 254.402618,29.833586 
	C255.522964,28.598696 256.253296,27.307743 256.983643,26.016790 
	C256.983643,26.016790 257.044281,25.952736 257.073792,25.919937 
z"
      />
      <path
        fill="#E1FAF9"
        opacity="1.000000"
        stroke="none"
        d="
M7.642675,73.095482 
	C8.995053,74.706696 9.978820,76.380859 10.972307,78.407310 
	C10.002574,82.062187 13.298199,86.361115 17.848808,88.021851 
	C19.205748,88.517052 20.359690,89.568497 21.690283,90.708679 
	C12.554846,90.566566 8.448712,84.500389 6.234367,76.568703 
	C5.965014,75.603889 6.898197,74.303368 7.642675,73.095482 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M313.153503,18.275558 
	C311.522339,22.307480 309.670166,25.979368 307.597839,29.909649 
	C303.697876,23.231741 304.547729,18.459183 309.774719,17.285872 
	C310.731995,17.070997 311.875183,17.684435 313.153503,18.275558 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M139.002441,87.990685 
	C136.088562,92.291084 132.648575,88.795387 129.716522,88.166023 
	C128.553497,87.916374 128.079971,84.564262 131.938202,84.142731 
	C134.185898,84.121071 135.723099,83.979500 137.547821,83.865753 
	C138.207077,84.004990 138.578812,84.116425 138.954575,84.420448 
	C138.958603,84.613037 138.989777,85.000061 139.019226,85.208206 
	C139.048676,85.416359 139.077423,85.835823 139.052078,86.194870 
	C139.018631,87.032845 139.010544,87.511765 139.002441,87.990685 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M25.000000,100.000000 
	C24.225441,99.667862 23.450882,99.335732 22.337788,98.664284 
	C21.995125,97.874611 21.990997,97.424255 21.986671,96.647888 
	C21.988304,95.887459 21.990137,95.453033 21.991970,95.018616 
	C21.994013,94.572525 21.996056,94.126434 22.253456,93.341843 
	C22.679008,92.996529 22.849201,92.989716 23.019394,92.982895 
	C23.019394,92.982895 22.998873,92.997322 23.104153,93.218338 
	C23.483257,93.619598 23.757080,93.799850 24.030903,93.980103 
	C24.030905,93.980103 23.997622,93.998688 24.149986,94.300217 
	C25.896786,95.388237 27.491222,96.174721 29.409019,96.967453 
	C30.160452,96.929314 30.588522,96.884926 31.339191,96.963318 
	C32.176693,98.032494 32.691597,98.978882 32.952217,100.035988 
	C32.471069,100.430984 32.244202,100.715263 31.613457,100.999649 
	C29.139719,100.666504 27.069859,100.333252 25.000000,100.000000 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M128.427734,65.923111 
	C128.493134,66.402794 128.558533,66.882477 128.437988,67.899490 
	C128.429123,68.951714 128.606216,69.466599 128.783325,69.981483 
	C128.890610,70.692238 128.997894,71.402985 129.057556,72.882332 
	C129.665466,75.104736 130.320999,76.558556 130.976532,78.012375 
	C130.976532,78.012375 131.001694,78.000679 131.022552,78.327347 
	C131.437653,79.105476 131.831863,79.556938 132.393143,80.153717 
	C132.560211,80.299034 132.819946,80.657715 132.464462,80.804382 
	C131.741608,81.300529 131.374252,81.650017 131.006897,81.999496 
	C126.191490,80.151909 127.398880,75.314018 126.186584,71.779190 
	C125.562706,69.960068 126.436172,67.627411 127.022644,65.334351 
	C127.750748,65.402298 128.089233,65.662704 128.427734,65.923111 
z"
      />
      <path
        fill="#89C1C3"
        opacity="1.000000"
        stroke="none"
        d="
M139.077423,85.835823 
	C139.077423,85.835823 139.048676,85.416359 139.423431,85.215034 
	C142.922913,83.495979 146.047638,81.978256 149.527954,80.394135 
	C150.599594,80.220665 151.315674,80.113594 152.011444,80.008224 
	C151.991165,80.009933 151.991272,79.969223 151.962677,80.342735 
	C151.940613,81.464722 151.947128,82.213188 151.953644,82.961662 
	C151.953644,82.961662 151.972168,82.999329 151.736084,83.071274 
	C151.316696,83.417046 151.133392,83.690857 150.950089,83.964676 
	C150.950089,83.964676 150.998001,84.000748 150.728546,84.061111 
	C150.223038,84.385475 149.986984,84.649467 149.750916,84.913460 
	C149.308014,85.179161 148.865112,85.444862 147.951385,85.932388 
	C147.215576,86.397804 146.950592,86.641380 146.685593,86.884956 
	C146.308746,86.891617 145.931915,86.898285 144.941528,86.938293 
	C143.877304,87.011162 143.426605,87.050682 142.975922,87.090202 
	C142.558792,87.007660 142.141678,86.925117 141.214706,86.600876 
	C140.162384,86.184723 139.619904,86.010269 139.077423,85.835823 
z"
      />
      <path
        fill="#CE6693"
        opacity="1.000000"
        stroke="none"
        d="
M264.175201,52.405293 
	C265.051178,51.888786 265.927124,51.372269 266.952393,50.590790 
	C268.485077,50.500500 269.868378,50.675167 271.623566,51.171440 
	C271.995422,51.493042 272.067749,51.946789 271.721252,51.963860 
	C269.093140,53.799896 266.811584,55.618858 264.103058,57.778191 
	C263.340393,56.830795 262.545807,55.843750 261.933228,54.629517 
	C262.801849,53.736649 263.488525,53.070965 264.175201,52.405293 
z"
      />
      <path
        fill="#F2FBFC"
        opacity="1.000000"
        stroke="none"
        d="
M40.935730,21.685963 
	C38.941162,23.410784 36.863247,24.744881 34.453400,26.175594 
	C33.730309,24.544916 33.339149,22.817616 32.604980,19.575653 
	C35.972214,20.277721 38.412300,20.786478 40.935730,21.685963 
z"
      />
      <path
        fill="#E1FAF9"
        opacity="1.000000"
        stroke="none"
        d="
M15.724809,49.109253 
	C15.055198,55.090755 14.005466,61.105606 12.704105,67.562744 
	C12.452477,68.005020 12.048524,68.016632 12.048524,68.016632 
	C12.373132,63.078377 12.548162,58.122822 13.115932,53.212685 
	C13.280541,51.789120 14.570575,50.495693 15.724809,49.109253 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M10.615372,93.994080 
	C10.132545,95.290474 9.265165,96.580940 8.397787,97.871414 
	C7.268517,96.497765 6.139246,95.124115 5.004997,93.375229 
	C6.743619,93.329384 8.487220,93.658768 10.615372,93.994080 
z"
      />
      <path
        fill="#66C4C4"
        opacity="1.000000"
        stroke="none"
        d="
M27.579027,37.296688 
	C26.825796,36.986691 26.072565,36.676697 25.074381,36.032169 
	C26.024561,34.302273 27.219692,32.906914 28.710804,31.820198 
	C29.088196,33.158638 29.169605,34.188431 29.203508,35.197350 
	C29.156002,35.176476 29.153906,35.072712 28.879051,35.196152 
	C28.405460,35.865410 28.206724,36.411224 27.915205,37.060207 
	C27.822420,37.163376 27.579027,37.296688 27.579027,37.296688 
z"
      />
      <path
        fill="#FCE6F5"
        opacity="1.000000"
        stroke="none"
        d="
M131.114944,82.258209 
	C131.374252,81.650017 131.741608,81.300529 132.711945,80.944565 
	C134.630051,81.904709 135.945175,82.871323 137.260315,83.837936 
	C135.723099,83.979500 134.185898,84.121071 132.334290,84.129135 
	C131.754257,83.502724 131.488632,83.009819 131.114944,82.258209 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M208.684143,54.113144 
	C208.481003,56.489025 208.029922,58.976044 207.356567,61.757935 
	C207.086685,59.961349 207.039108,57.869892 207.001007,55.392548 
	C207.485733,54.745869 207.960968,54.485073 208.684143,54.113144 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M131.998657,44.001678 
	C132.814468,43.416653 133.630280,42.831631 134.722717,42.123039 
	C133.901886,44.625275 132.804443,47.251087 131.392944,49.740501 
	C131.053604,49.069824 131.028336,48.535545 131.003052,48.001266 
	C131.003479,47.834114 131.003906,47.666962 131.003601,47.249062 
	C131.320251,46.211330 131.637634,45.424343 131.977509,44.318680 
	C132.000000,44.000000 131.998657,44.001678 131.998657,44.001678 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M161.020325,36.668175 
	C159.033737,35.618542 157.749573,34.128239 160.647308,32.051369 
	C161.015121,33.435440 161.038208,34.877068 161.020325,36.668175 
z"
      />
      <path
        fill="#F9F3FB"
        opacity="1.000000"
        stroke="none"
        d="
M156.897339,25.421448 
	C157.643814,25.037176 158.428604,25.019114 159.605530,25.000933 
	C160.315704,25.475430 160.633759,25.950045 160.964264,26.726528 
	C159.887070,27.353029 158.797394,27.677662 157.359955,27.996998 
	C156.986694,27.257019 156.961182,26.522337 156.897339,25.421448 
z"
      />
      <path
        fill="#E1FAF9"
        opacity="1.000000"
        stroke="none"
        d="
M21.620338,95.016953 
	C21.990137,95.453033 21.988304,95.887459 21.572296,96.652328 
	C19.775730,96.979424 18.393337,96.976067 17.010944,96.972702 
	C17.010944,96.972702 17.012661,96.497757 17.012173,96.260559 
	C18.424026,95.687340 19.836367,95.351318 21.620338,95.016953 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M145.898376,25.269300 
	C146.788193,25.013422 147.569641,25.015411 148.742294,25.038227 
	C147.982178,26.048634 146.830872,27.038216 145.363464,28.122112 
	C145.033066,28.013515 145.018753,27.810600 144.994781,27.302677 
	C145.253418,26.507500 145.521729,26.017334 145.898376,25.269300 
z"
      />
      <path
        fill="#E1FAF9"
        opacity="1.000000"
        stroke="none"
        d="
M17.605461,45.063446 
	C17.519411,46.231308 17.076063,47.472454 16.356081,48.896980 
	C16.469021,47.765816 16.858593,46.451271 17.605461,45.063446 
z"
      />
      <path
        fill="#FCE6F5"
        opacity="1.000000"
        stroke="none"
        d="
M128.576355,65.553238 
	C128.089233,65.662704 127.750748,65.402298 127.211639,65.062897 
	C127.422653,64.102356 127.834290,63.220806 128.500702,62.176407 
	C128.745316,63.070160 128.735153,64.126770 128.576355,65.553238 
z"
      />
      <path
        fill="#66C1BF"
        opacity="1.000000"
        stroke="none"
        d="
M29.251015,35.218224 
	C29.169605,34.188431 29.088196,33.158638 29.011806,31.745132 
	C29.139137,31.224543 29.261448,31.087667 29.625118,30.818403 
	C30.147717,31.563562 30.428955,32.441109 30.710140,33.877438 
	C30.223730,34.696888 29.737371,34.957554 29.251015,35.218224 
z"
      />
      <path
        fill="#77C5C3"
        opacity="1.000000"
        stroke="none"
        d="
M54.512596,12.835190 
	C54.793533,14.107152 54.814030,15.695710 54.639015,17.594788 
	C54.379723,16.320799 54.315937,14.736291 54.512596,12.835190 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M130.722778,48.136536 
	C131.028336,48.535545 131.053604,49.069824 131.150513,50.002151 
	C131.222137,50.400196 131.150162,50.742264 131.030457,50.869759 
	C130.754654,50.088772 130.598587,49.180290 130.722778,48.136536 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M192.590576,27.046513 
	C192.940552,27.432545 192.998795,27.865404 193.067505,28.625462 
	C192.545990,28.759554 192.014038,28.566448 191.482071,28.373341 
	C191.754318,27.946674 192.026581,27.520008 192.590576,27.046513 
z"
      />
      <path
        fill="#D6FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M12.032581,68.373123 
	C12.048524,68.016632 12.452477,68.005020 12.654181,67.999054 
	C13.024525,68.595078 13.193167,69.197075 13.473586,70.212677 
	C13.105052,70.800461 12.624742,70.974625 12.144432,71.148796 
	C12.144432,71.148796 11.995952,71.000542 11.918507,70.926895 
	C11.899589,70.145363 11.958114,69.437492 12.032581,68.373123 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M139.052078,86.194870 
	C139.619904,86.010269 140.162384,86.184723 140.896988,86.571396 
	C140.608139,87.167648 140.127151,87.551689 139.324310,87.963203 
	C139.010544,87.511765 139.018631,87.032845 139.052078,86.194870 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M209.854904,49.195309 
	C209.981369,50.200985 209.793564,51.434422 209.363068,52.954983 
	C209.260452,51.969097 209.400543,50.696087 209.854904,49.195309 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M316.664612,17.477039 
	C316.152863,17.809864 315.419067,17.948648 314.310608,18.132265 
	C314.771515,17.879065 315.607025,17.581030 316.664612,17.477039 
z"
      />
      <path
        fill="#FCE6F5"
        opacity="1.000000"
        stroke="none"
        d="
M129.558685,55.273003 
	C129.703644,55.909721 129.612854,56.785847 129.308167,57.924011 
	C129.170486,57.294834 129.246735,56.403625 129.558685,55.273003 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M142.981079,87.322525 
	C143.426605,87.050682 143.877304,87.011162 144.666229,87.006653 
	C144.532791,87.351234 144.061142,87.660805 143.293823,87.994911 
	C142.998169,88.019447 142.986221,87.554848 142.981079,87.322525 
z"
      />
      <path
        fill="#77C5C3"
        opacity="1.000000"
        stroke="none"
        d="
M51.651943,18.682955 
	C51.633778,18.866472 51.461567,18.935223 51.135674,18.982014 
	C51.100582,18.773245 51.272549,18.642622 51.651943,18.682955 
z"
      />
      <path
        fill="#E1FAF9"
        opacity="1.000000"
        stroke="none"
        d="
M22.950680,92.732780 
	C22.849201,92.989716 22.679008,92.996529 22.253302,93.007263 
	C22.000271,92.826790 22.002754,92.642387 22.027794,92.181244 
	C22.327553,92.097221 22.604759,92.289948 22.950680,92.732780 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M130.438782,52.257507 
	C130.693863,52.414997 130.642776,52.703175 130.260529,53.112350 
	C130.060349,52.956841 130.128113,52.675121 130.438782,52.257507 
z"
      />
      <path
        fill="#E7E5F0"
        opacity="1.000000"
        stroke="none"
        d="
M272.067749,51.946789 
	C272.067749,51.946789 271.995422,51.493042 271.965607,51.266220 
	C272.112885,51.027679 272.290009,51.015961 272.733337,51.003700 
	C272.998596,51.171230 272.997681,51.339306 272.748047,51.742470 
	C272.499390,51.977562 272.027008,51.928383 272.026520,51.951981 
	C272.026031,51.975582 272.067749,51.946789 272.067749,51.946789 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M306.591339,32.176437 
	C306.840912,32.385876 306.755096,32.679375 306.293823,33.019325 
	C306.088715,32.801708 306.201233,32.533287 306.591339,32.176437 
z"
      />
      <path
        fill="#DB5996"
        opacity="1.000000"
        stroke="none"
        d="
M153.243881,93.091576 
	C153.243881,93.091576 153.640320,93.086792 154.177460,92.990112 
	C156.489502,92.298721 158.264374,91.704018 160.039261,91.109314 
	C160.487366,91.074867 160.935486,91.040428 162.013107,90.944855 
	C163.187180,90.630608 163.731750,90.377480 164.276321,90.124359 
	C164.969788,90.095474 165.663254,90.066589 167.014343,89.985092 
	C168.252853,89.680817 168.833740,89.429153 169.414612,89.177490 
	C171.688965,89.067253 173.963318,88.957016 176.884491,88.743134 
	C177.858261,88.573402 178.109756,88.398643 178.285828,88.115204 
	C178.674362,88.111458 179.062912,88.107712 180.110626,88.068909 
	C182.195557,87.732605 183.621323,87.431358 185.047073,87.130104 
	C185.488358,87.118111 185.929626,87.106110 187.125366,87.057648 
	C194.050690,86.066391 200.221588,85.111610 206.392471,84.156830 
	C206.754608,84.159332 207.116760,84.161827 208.177063,84.120071 
	C211.669037,83.754379 214.462860,83.432938 217.256668,83.111496 
	C224.841980,82.997826 232.427277,82.884148 240.745087,83.134125 
	C246.970383,83.046204 252.463181,82.594620 257.955994,82.143044 
	C258.135803,82.103867 258.315582,82.064697 259.030151,82.319382 
	C262.709991,82.408989 265.855011,82.204750 268.999359,82.399513 
	C269.000031,83.862129 269.001373,84.925728 268.539856,85.991196 
	C258.517517,85.993866 248.941284,85.659584 239.405457,86.135216 
	C234.551208,86.377335 229.769608,88.075745 224.668549,89.262047 
	C223.551865,89.506844 222.721786,89.605453 221.580536,89.477219 
	C216.297562,89.745422 211.306198,90.100784 206.362213,90.794098 
	C203.868622,91.143776 201.460907,92.105888 198.657715,92.899216 
	C197.559067,93.053932 196.817047,93.100494 195.860779,92.859123 
	C194.437424,93.060387 193.228317,93.549591 191.673828,94.225510 
	C178.353943,96.716110 165.385422,99.055038 152.396118,101.272125 
	C151.030045,101.505295 149.541428,101.020508 148.065979,100.888885 
	C148.022293,100.908951 148.116684,100.927261 148.028534,100.673233 
	C147.637177,100.245338 147.333939,100.071472 146.911514,99.583092 
	C146.559860,98.884392 146.327423,98.500191 146.094971,98.115997 
	C146.241440,97.945854 146.387924,97.775703 146.680145,97.302299 
	C147.716599,96.395027 148.607285,95.791016 150.012711,94.984421 
	C150.760635,94.551338 150.993820,94.320831 151.227005,94.090317 
	C151.227005,94.090317 151.664612,94.009064 152.095642,93.874382 
	C152.852142,93.619339 153.091217,93.403297 153.243881,93.091576 
M175.092087,94.956345 
	C175.648392,94.463104 176.204697,93.969864 176.761002,93.476631 
	C176.020538,92.991478 175.277222,92.089096 174.540131,92.094170 
	C171.518906,92.114967 168.499603,92.412346 165.479584,92.607925 
	C165.436172,92.992737 165.392746,93.377548 165.349335,93.762360 
	C168.316681,94.174004 171.284042,94.585648 175.092087,94.956345 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M36.999847,113.000000 
	C35.673294,112.255440 34.346745,111.510872 33.374664,110.375984 
	C34.426041,109.351295 35.122944,108.716934 35.819847,108.082573 
	C34.879898,107.388283 33.939949,106.693985 33.000000,105.999695 
	C32.999874,105.555252 32.999744,105.110802 33.002079,104.074966 
	C33.006950,103.311523 33.009361,103.139481 33.386116,102.976585 
	C34.509987,102.990051 35.259502,102.994370 36.212440,103.028717 
	C36.415855,103.058754 36.823715,103.111412 37.017143,103.396835 
	C38.479599,104.178955 39.748623,104.675644 41.177299,105.450722 
	C44.137978,109.855202 46.419502,105.810936 49.365929,105.176811 
	C52.196552,105.150131 54.583553,105.193428 56.978161,105.624130 
	C57.161049,108.173218 56.511642,111.248085 60.063881,110.582520 
	C61.000946,110.406944 61.438263,107.466141 62.054504,105.776672 
	C62.152493,105.508034 61.982445,105.141617 62.139343,104.616196 
	C63.227268,104.636162 64.112167,104.860359 65.198914,105.409859 
	C68.989388,107.565857 72.522568,108.068253 76.355103,105.208145 
	C78.860641,105.177948 80.930145,105.235764 83.246582,105.517097 
	C83.538147,106.808037 83.897423,107.989815 83.559975,108.918388 
	C82.916664,110.688583 81.875809,112.314301 81.000000,114.000000 
	C68.293289,114.000000 55.586571,114.000000 42.397003,113.639755 
	C40.415276,110.738609 38.857620,109.231064 36.999847,113.000000 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M216.000000,97.997559 
	C215.592026,101.343689 215.730164,104.922356 211.080795,105.757881 
	C210.280609,105.901688 209.286346,107.751137 209.240616,108.848488 
	C208.974716,115.229042 208.078888,115.930267 201.215454,113.713921 
	C198.649399,112.558319 196.677994,111.644287 194.616943,111.031998 
	C191.846634,110.209007 189.007111,109.618958 186.197296,108.928909 
	C186.340469,108.396721 186.483643,107.864525 186.626816,107.332336 
	C188.194275,107.111603 189.761719,106.890877 191.360992,106.665665 
	C190.865875,104.970085 190.432938,103.487457 190.341431,102.000076 
	C191.786194,101.383034 192.889496,100.770744 194.312897,100.081207 
	C195.070435,99.972420 195.507904,99.940865 196.203888,100.209976 
	C200.249100,100.278168 204.041916,100.106667 207.814240,99.730064 
	C208.248001,99.686760 208.567596,98.499794 209.227539,97.611328 
	C210.996902,97.202126 212.478851,97.024681 214.119003,97.117111 
	C214.851456,97.590523 215.425735,97.794037 216.000000,97.997559 
z"
      />
      <path
        fill="#FEFFFE"
        opacity="1.000000"
        stroke="none"
        d="
M322.000000,97.000000 
	C320.606903,97.666473 319.179657,98.973587 317.825897,98.901489 
	C302.297302,98.074478 286.787811,96.853073 271.254028,96.180977 
	C267.135376,96.002762 262.951019,97.342140 258.430481,97.646790 
	C258.376068,96.205002 258.688019,95.101128 259.461060,93.996262 
	C264.290253,93.387451 268.658417,92.779648 273.341431,92.096893 
	C274.086426,91.985168 274.516479,91.948395 275.296814,91.967743 
	C276.114746,91.970497 276.582397,91.917130 277.410522,91.876297 
	C278.491852,91.875343 279.212799,91.861839 280.224060,91.891739 
	C280.703033,91.882812 280.891632,91.830490 281.499268,91.786514 
	C289.612030,91.698402 297.305756,91.601936 305.024231,91.874191 
	C306.022766,92.829361 306.928375,93.700790 307.982239,93.953072 
	C312.631500,95.066086 317.323486,96.000748 322.000000,97.000000 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M322.412781,97.001015 
	C317.323486,96.000748 312.631500,95.066086 307.982239,93.953072 
	C306.928375,93.700790 306.022766,92.829361 305.471741,91.787567 
	C310.907623,91.491943 315.920715,91.651680 321.206787,91.887482 
	C321.667023,91.938499 321.854279,91.913445 322.485352,91.906754 
	C325.790680,92.218719 328.652161,92.709061 331.513702,92.709419 
	C332.664642,92.709564 333.815735,91.634842 335.342346,91.003433 
	C337.476501,91.630539 339.235046,92.303543 341.229889,93.255127 
	C342.915070,93.684258 344.363953,93.834801 345.807861,94.323273 
	C345.877533,95.105125 345.952240,95.549042 346.126007,96.217644 
	C346.424194,96.716698 346.683472,96.910767 347.002899,97.024521 
	C344.842499,97.537102 342.682068,98.049683 340.443207,98.013947 
	C340.572266,96.715134 340.779816,95.964638 340.987335,95.214134 
	C339.245972,94.941040 337.504608,94.667953 335.763245,94.394867 
	C335.842163,95.596581 335.921082,96.798286 336.000000,98.000000 
	C333.365570,98.004211 330.723419,98.132347 328.099365,97.969368 
	C326.328247,97.859375 324.582611,97.338890 322.412781,97.001015 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M183.000000,113.000000 
	C176.862259,113.197350 170.724518,113.394699 164.298904,112.938545 
	C165.673828,110.190941 167.336609,108.096848 169.437561,105.981750 
	C170.453461,105.853920 171.031189,105.747101 171.733398,105.517113 
	C171.857880,105.393944 172.183777,105.262169 172.183777,105.262169 
	C172.183777,105.262169 172.533676,105.295418 172.734818,105.636566 
	C174.290649,105.319603 175.645325,104.661484 177.387238,104.000191 
	C179.516312,103.670090 181.258163,103.343155 182.999893,103.423584 
	C184.066559,108.354073 180.012314,108.578163 177.491180,109.745705 
	C179.539200,110.955559 181.269592,111.977783 183.000000,113.000000 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M183.388977,113.045082 
	C181.269592,111.977783 179.539200,110.955559 177.491180,109.745705 
	C180.012314,108.578163 184.066559,108.354073 183.340057,103.413658 
	C184.459641,102.400574 185.238953,101.804779 186.277802,101.186302 
	C186.707718,101.197205 186.878113,101.230797 187.280121,101.476059 
	C188.341156,101.793419 189.170578,101.899124 190.000000,102.004829 
	C190.432938,103.487457 190.865875,104.970085 191.360992,106.665665 
	C189.761719,106.890877 188.194275,107.111603 186.626816,107.332336 
	C186.483643,107.864525 186.340469,108.396721 186.197296,108.928909 
	C189.007111,109.618958 191.846634,110.209007 194.616943,111.031998 
	C196.677994,111.644287 198.649399,112.558319 200.830368,113.668976 
	C195.133896,116.070816 189.423447,115.195320 183.388977,113.045082 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M168.999390,106.002747 
	C167.336609,108.096848 165.673828,110.190941 164.005219,112.642059 
	C163.555222,112.999207 163.111038,112.999329 162.172333,112.689095 
	C159.118576,111.252457 156.559357,110.126167 153.961685,108.635025 
	C153.615082,106.848564 153.306931,105.426949 153.326797,104.007065 
	C154.310089,103.991394 154.965347,103.973999 155.763977,104.340393 
	C158.475952,105.094498 161.044556,105.464813 163.944214,105.875771 
	C164.843307,105.660332 165.411346,105.404259 166.346313,105.072937 
	C167.459579,104.938736 168.205933,104.879791 168.966995,105.116196 
	C168.987595,105.608620 168.993484,105.805687 168.999390,106.002747 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M152.998779,104.005333 
	C153.306931,105.426949 153.615082,106.848564 153.689423,108.866699 
	C153.303741,110.642151 153.151871,111.821075 153.000000,113.000000 
	C148.203674,116.139191 147.084610,110.304359 144.100037,109.044281 
	C143.441559,108.766281 144.171158,105.200607 144.631302,103.458572 
	C145.428009,105.105141 145.854996,106.441986 146.281967,107.778839 
	C146.698868,107.710243 147.115784,107.641655 147.532684,107.573067 
	C147.690903,106.375427 147.849106,105.177788 148.338440,104.013412 
	C150.112640,104.032890 151.555710,104.019112 152.998779,104.005333 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M239.000000,97.000000 
	C234.673660,97.714378 230.365936,98.602654 226.013016,99.068962 
	C223.872543,99.298256 221.648331,98.746101 219.333374,98.019295 
	C219.478622,97.027115 219.752518,96.563217 220.392975,96.021210 
	C221.756546,95.923973 222.753571,95.904854 224.101410,95.929504 
	C225.291138,95.713242 226.130051,95.453209 227.355942,95.115623 
	C228.791412,94.951180 229.839920,94.864288 231.091324,95.073654 
	C232.537857,94.975258 233.781464,94.580612 235.409775,94.108765 
	C236.833542,93.915718 237.872604,93.799873 239.016449,93.962090 
	C239.414139,94.495247 239.707062,94.750343 239.892609,95.263626 
	C239.523499,96.014542 239.261749,96.507278 239.000000,97.000000 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M122.678253,90.830414 
	C123.000000,91.750450 123.000000,92.500298 123.000000,93.625069 
	C122.250000,94.000000 121.500000,94.000000 119.980286,94.000015 
	C117.140373,94.666687 115.070190,95.333344 113.000000,96.000000 
	C112.555428,95.999931 112.110847,95.999863 111.332832,95.999588 
	C112.432411,93.637581 113.472763,90.869644 115.393623,89.012367 
	C118.049492,86.444405 120.456146,88.249977 122.678253,90.830414 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M88.719475,104.600716 
	C92.511086,102.720100 92.595779,96.516266 98.502831,96.932030 
	C98.961998,97.516388 98.981003,97.758194 99.000000,97.999992 
	C94.714478,100.636230 96.693832,103.999039 97.603531,107.673691 
	C92.993973,110.188553 94.621971,105.647186 93.313110,104.424362 
	C91.835289,104.635071 90.430687,104.835342 88.945381,104.929749 
	C88.864670,104.823883 88.719475,104.600716 88.719475,104.600716 
z"
      />
      <path
        fill="#83B9BB"
        opacity="1.000000"
        stroke="none"
        d="
M346.026978,95.992950 
	C345.952240,95.549042 345.877533,95.105125 346.114136,94.156334 
	C348.568604,90.415207 350.711761,87.178947 353.206848,83.960426 
	C354.258820,83.986740 354.958801,83.995323 355.658813,84.003914 
	C355.710144,84.198074 355.761475,84.392242 355.719604,85.125137 
	C355.083466,87.067879 354.540497,88.471886 353.680878,89.957893 
	C353.579102,90.783386 353.794006,91.526886 354.008545,92.525337 
	C354.008026,93.119446 354.007843,93.458611 353.760864,93.863708 
	C353.348969,93.971245 353.183868,94.012856 353.018738,94.054466 
	C352.569489,94.012535 352.120239,93.970604 350.979279,93.973106 
	C348.867371,94.676010 347.447174,95.334480 346.026978,95.992950 
z"
      />
      <path
        fill="#FEFFFE"
        opacity="1.000000"
        stroke="none"
        d="
M239.381744,97.166405 
	C239.261749,96.507278 239.523499,96.014542 240.268066,95.098114 
	C244.168121,94.453781 247.585327,94.233162 251.053894,94.350128 
	C252.070175,95.791809 253.035095,96.895905 254.000000,98.000000 
	C249.254486,97.777603 244.508987,97.555206 239.381744,97.166405 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M81.145432,114.225861 
	C81.875809,112.314301 82.916664,110.688583 83.559975,108.918388 
	C83.897423,107.989815 83.538147,106.808037 83.616638,105.427010 
	C84.492126,105.010323 85.244484,104.907234 85.996841,104.804153 
	C86.679741,104.776428 87.362640,104.748703 88.382507,104.660843 
	C88.719475,104.600716 88.864670,104.823883 88.940437,105.290741 
	C89.677574,107.504967 90.338943,109.252327 91.000153,110.999847 
	C91.000000,111.000000 90.999695,111.000305 90.560501,111.000153 
	C87.749687,112.005104 83.508057,108.571228 83.000000,114.000000 
	C82.430290,114.150574 81.860573,114.301147 81.145432,114.225861 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M91.000305,110.999695 
	C90.338943,109.252327 89.677574,107.504967 89.021149,105.396606 
	C90.430687,104.835342 91.835289,104.635071 93.313110,104.424362 
	C94.621971,105.647186 92.993973,110.188553 97.640900,108.035576 
	C97.999382,108.749092 97.999687,109.499413 97.562500,110.624863 
	C95.083443,110.999901 93.041878,110.999802 91.000305,110.999695 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M254.285721,98.119614 
	C253.035095,96.895905 252.070175,95.791809 251.057693,94.102768 
	C251.010132,93.517822 251.016724,93.023392 251.442596,92.963837 
	C253.583771,92.951195 255.299088,92.998123 257.183868,93.282242 
	C257.902222,93.678711 258.451111,93.837982 259.000000,93.997253 
	C258.688019,95.101128 258.376068,96.205002 258.032043,97.654434 
	C256.857147,98.079750 255.714294,98.159492 254.285721,98.119614 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M113.156136,96.313492 
	C115.070190,95.333344 117.140373,94.666687 119.605286,94.000015 
	C119.294456,95.506981 118.923454,97.813469 117.786415,98.289246 
	C116.714546,98.737755 114.835968,97.258308 113.156136,96.313492 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M153.421783,113.000000 
	C153.151871,111.821075 153.303741,110.642151 153.727875,109.231552 
	C156.559357,110.126167 159.118576,111.252457 161.839203,112.689072 
	C159.281601,112.999596 156.562576,112.999802 153.421783,113.000000 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M91.000153,110.999847 
	C93.041878,110.999802 95.083443,110.999901 97.562508,111.000000 
	C96.966843,115.520050 95.859741,115.960960 91.175293,112.990799 
	C90.999893,111.889061 90.999794,111.444679 90.999695,111.000305 
	C90.999695,111.000305 91.000000,111.000000 91.000153,110.999847 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M148.007324,103.980148 
	C147.849106,105.177788 147.690903,106.375427 147.532684,107.573067 
	C147.115784,107.641655 146.698868,107.710243 146.281967,107.778839 
	C145.854996,106.441986 145.428009,105.105141 145.000214,103.384148 
	C145.000778,101.932594 145.002151,100.865189 145.218018,99.307541 
	C145.705963,98.660919 145.915253,98.442924 146.060318,98.163292 
	C146.060318,98.163292 146.092010,98.147163 146.093491,98.131577 
	C146.327423,98.500191 146.559860,98.884392 147.011292,99.829315 
	C147.525757,100.569115 147.821213,100.748192 148.116684,100.927261 
	C148.116684,100.927261 148.022293,100.908951 148.029480,101.278168 
	C148.026886,102.424980 148.017105,103.202560 148.007324,103.980148 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M90.560501,111.000153 
	C90.999794,111.444679 90.999893,111.889061 91.000000,112.666718 
	C88.610687,113.333328 86.221382,113.666664 83.416031,114.000000 
	C83.508057,108.571228 87.749687,112.005104 90.560501,111.000153 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M336.399994,98.000000 
	C335.921082,96.798286 335.842163,95.596581 335.763245,94.394867 
	C337.504608,94.667953 339.245972,94.941040 340.987335,95.214134 
	C340.779816,95.964638 340.572266,96.715134 340.182373,97.732819 
	C338.933319,98.000000 337.866669,98.000000 336.399994,98.000000 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M37.063824,113.354614 
	C38.857620,109.231064 40.415276,110.738609 41.957153,113.639755 
	C40.376034,113.903076 38.751919,113.806152 37.063824,113.354614 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M33.011768,102.967445 
	C33.009361,103.139481 33.006950,103.311523 33.001816,103.741623 
	C30.363632,102.913879 27.728178,101.828072 25.046360,100.371132 
	C27.069859,100.333252 29.139719,100.666504 31.717743,101.255669 
	C32.487858,101.996872 32.749813,102.482155 33.011768,102.967445 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M32.744690,106.193275 
	C33.939949,106.693985 34.879898,107.388283 35.819847,108.082573 
	C35.122944,108.716934 34.426041,109.351295 33.364643,109.992828 
	C32.829895,108.795616 32.659637,107.591240 32.744690,106.193275 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M353.234070,94.312225 
	C353.183868,94.012856 353.348969,93.971245 354.167480,93.943611 
	C356.890472,94.271767 358.960114,94.585953 361.029724,94.900131 
	C360.251038,95.264397 359.472321,95.628654 358.346802,95.996460 
	C356.483124,95.523331 354.966278,95.046661 353.234070,94.312225 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M102.999695,96.999390 
	C104.459915,96.576988 105.920128,96.154587 107.690170,95.865952 
	C106.597099,96.666435 105.194206,97.333160 103.395622,97.749794 
	C102.999855,97.332924 102.999779,97.166161 102.999695,96.999390 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M220.026428,96.099319 
	C219.752518,96.563217 219.478622,97.027115 219.100418,97.739403 
	C218.247116,97.990181 217.498108,97.992561 216.374557,97.996246 
	C215.425735,97.794037 214.851456,97.590523 214.412445,96.873932 
	C216.373947,96.273689 218.200195,96.186508 220.026428,96.099319 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M140.387177,97.000000 
	C140.000000,96.555557 140.000000,96.111107 140.000000,95.333328 
	C141.500763,95.147949 143.001541,95.295898 144.750244,95.723770 
	C143.590240,96.335793 142.182297,96.667900 140.387177,97.000000 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M17.076244,97.345154 
	C18.393337,96.976067 19.775730,96.979424 21.572498,96.978340 
	C21.990997,97.424255 21.995125,97.874611 21.999664,98.662483 
	C20.380566,98.572533 18.761053,98.145073 17.076244,97.345154 
z"
      />
      <path
        fill="#EEE7F6"
        opacity="1.000000"
        stroke="none"
        d="
M346.126038,96.217636 
	C347.447174,95.334480 348.867371,94.676010 350.641663,94.014206 
	C349.923676,95.007919 348.851593,96.004959 347.391174,97.013260 
	C346.683472,96.910767 346.424194,96.716698 346.126038,96.217636 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M102.645920,96.956818 
	C102.999779,97.166161 102.999855,97.332924 102.999969,97.749847 
	C101.868271,98.086731 100.736549,98.173462 99.302414,98.130096 
	C98.981003,97.758194 98.961998,97.516388 98.895782,96.910858 
	C99.318306,96.264290 99.788025,95.981453 100.522079,95.608505 
	C101.288315,95.983673 101.790230,96.448959 102.645920,96.956818 
z"
      />
      <path
        fill="#EFEEF9"
        opacity="1.000000"
        stroke="none"
        d="
M145.825027,98.209305 
	C145.915253,98.442924 145.705963,98.660919 145.220810,98.907272 
	C145.104553,98.673103 145.298080,98.425789 145.825027,98.209305 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M365.081268,43.365486 
	C365.081268,43.365490 365.240875,43.788830 365.442383,44.248547 
	C365.735229,45.128262 365.826538,45.548260 365.847290,46.206818 
	C365.776733,46.445377 365.722351,46.939957 365.501038,47.210369 
	C364.604858,49.784718 363.930054,52.088657 363.255249,54.392593 
	C363.557800,54.504921 363.860352,54.617245 364.162903,54.729572 
	C365.747986,53.196827 367.333069,51.664085 369.135681,50.401993 
	C371.895691,50.134434 374.438110,49.596226 376.992065,49.401848 
	C375.380737,51.169979 373.757904,52.594280 371.884338,54.120762 
	C371.430878,54.513012 371.228180,54.803082 371.052063,55.068260 
	C371.078674,55.043365 371.096832,55.113930 370.759186,55.077766 
	C369.970978,55.033966 369.520416,55.026325 368.868164,54.781982 
	C365.710724,55.669640 362.754944,56.794003 358.991028,58.225784 
	C358.256378,59.154976 356.726440,61.090069 355.174591,62.658554 
	C355.431946,61.108761 355.711273,59.925571 356.179199,58.489197 
	C356.899445,57.057697 357.431030,55.879375 358.085785,54.427994 
	C353.645813,56.604721 349.884583,58.448692 345.790649,60.238663 
	C344.643127,60.519405 343.828308,60.854149 342.751190,61.060486 
	C341.988708,60.727345 341.488556,60.522606 340.680908,60.166420 
	C339.263031,59.295742 338.152679,58.576519 337.029236,57.628174 
	C337.016113,57.399055 337.000549,56.940327 337.178406,56.903114 
	C337.356232,56.865906 337.718048,56.899426 337.929688,56.868675 
	C338.240784,56.738949 338.340240,56.639969 338.439697,56.540977 
	C338.249725,56.354244 338.059753,56.167515 337.820038,55.601639 
	C338.733765,53.467678 339.697235,51.712872 340.721008,49.665039 
	C340.833038,49.179375 340.884735,48.986748 341.313599,48.695713 
	C347.118683,48.590664 342.142212,53.328476 345.872620,55.266251 
	C349.773132,52.497311 354.377441,49.228718 358.992615,46.319939 
	C358.371948,48.037746 357.740417,49.395744 357.139862,50.687126 
	C358.508392,51.262051 359.951691,51.868385 361.630341,52.573593 
	C362.256073,49.606236 365.658905,45.840378 359.110901,45.662956 
	C361.201538,44.684551 363.141418,44.025017 365.081268,43.365486 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M340.936462,48.794121 
	C340.884735,48.986748 340.833038,49.179375 340.385498,49.662292 
	C339.466522,49.374363 338.701263,48.881241 338.462860,48.202946 
	C337.512543,45.499176 336.726257,42.737751 335.917694,39.646866 
	C336.067993,38.839966 336.179871,38.383865 336.460266,37.794876 
	C336.823669,37.429459 336.911682,37.161713 336.926819,36.635658 
	C336.960876,36.412560 337.148041,36.001820 337.479401,35.937984 
	C340.206360,34.582680 342.601959,33.291206 345.216309,32.268883 
	C348.793976,32.695789 350.930023,37.604706 355.000000,35.000000 
	C356.228485,35.462479 357.456940,35.924957 358.691772,37.001724 
	C355.789917,39.081306 352.881775,40.546604 349.731262,42.011238 
	C349.488953,42.010571 349.003662,42.007999 348.938202,41.652420 
	C346.284973,38.953888 345.092621,39.757477 344.634155,43.056145 
	C343.164215,44.993771 342.050323,46.893944 340.936462,48.794121 
z"
      />
      <path
        fill="#EEE7F6"
        opacity="1.000000"
        stroke="none"
        d="
M372.135071,54.018570 
	C373.757904,52.594280 375.380737,51.169979 377.165710,49.152634 
	C378.549469,49.040611 379.771088,49.521641 380.992706,50.002670 
	C381.256348,50.500870 381.520020,50.999065 381.505768,51.723717 
	C378.773956,53.533054 376.320038,55.115936 373.572388,56.873055 
	C363.379333,63.664917 357.496368,73.332420 352.854889,83.942696 
	C350.711761,87.178947 348.568604,90.415207 346.119171,93.818405 
	C344.363953,93.834801 342.915070,93.684258 341.593903,93.202728 
	C343.732056,90.910439 345.742493,88.949135 347.974976,86.718399 
	C349.450073,84.240189 350.703094,82.031425 352.224060,79.659126 
	C353.003693,78.339165 353.515381,77.182747 354.195557,75.893631 
	C354.576965,75.535095 354.654266,75.269753 354.777161,74.667427 
	C355.599091,72.915115 356.239899,71.460289 356.997559,69.771027 
	C357.175903,69.194534 357.237335,68.852478 357.580139,68.342133 
	C358.605011,67.143265 359.348511,66.112679 360.057739,65.042770 
	C360.023438,65.003456 360.083832,65.080963 360.397858,64.982567 
	C362.413483,63.226242 364.115082,61.568314 365.867249,59.914612 
	C365.917816,59.918835 365.915802,59.817322 366.216919,59.709343 
	C368.044312,58.105553 369.570557,56.609741 371.096832,55.113930 
	C371.096832,55.113930 371.078674,55.043365 371.312866,54.985600 
	C371.743042,54.624744 371.939056,54.321655 372.135071,54.018570 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M346.998779,9.000303 
	C348.228149,9.463613 349.457520,9.926916 350.844055,11.124928 
	C351.001221,13.572207 351.001221,15.284780 351.001221,16.997349 
	C352.000824,22.058262 353.000427,27.119175 353.607727,32.577934 
	C350.616638,30.557678 348.210938,27.692427 343.894989,28.780479 
	C343.586060,28.288370 343.324524,28.101423 343.000610,28.000420 
	C343.000000,28.000000 342.998779,27.999191 342.994995,27.659218 
	C342.395721,26.544682 341.800171,25.770117 341.150757,24.701935 
	C341.357758,23.923405 341.618622,23.438492 342.237671,22.918228 
	C343.967010,20.726913 345.458984,18.631887 346.604218,16.361923 
	C346.824982,15.924360 345.687256,14.801380 345.126099,13.747831 
	C345.060883,13.333920 345.045685,13.166759 345.332642,12.838307 
	C346.089447,11.451444 346.544098,10.225870 346.998779,9.000303 
z"
      />
      <path
        fill="#80BBBE"
        opacity="1.000000"
        stroke="none"
        d="
M353.206848,83.960426 
	C357.496368,73.332420 363.379333,63.664917 373.617249,57.305019 
	C374.004944,58.137821 374.054016,58.712894 374.103088,59.287968 
	C373.292267,60.309383 372.652527,61.559608 371.642517,62.314568 
	C365.703247,66.754150 361.119385,72.164810 358.572144,79.237503 
	C358.035187,80.728447 356.842499,81.983215 355.805389,83.675369 
	C354.958801,83.995323 354.258820,83.986740 353.206848,83.960426 
z"
      />
      <path
        fill="#F4EAF5"
        opacity="1.000000"
        stroke="none"
        d="
M341.313599,48.695717 
	C342.050323,46.893944 343.164215,44.993771 344.634155,43.301208 
	C344.990265,43.508820 344.993896,43.999390 345.030701,44.355614 
	C348.536072,49.934948 351.888977,50.315178 355.983459,45.874119 
	C357.089661,44.674255 358.004639,43.298073 359.366608,41.847717 
	C361.816376,41.464664 363.906982,41.236717 365.997559,41.008766 
	C365.921997,41.712341 365.846436,42.415920 365.426086,43.242493 
	C363.141418,44.025017 361.201538,44.684551 359.131287,45.672451 
	C359.000916,46.000816 358.981781,45.960125 358.981781,45.960129 
	C354.377441,49.228718 349.773132,52.497311 345.872620,55.266251 
	C342.142212,53.328476 347.118683,48.590664 341.313599,48.695717 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M381.268433,49.764610 
	C379.771088,49.521641 378.549469,49.040611 377.154205,48.808800 
	C374.438110,49.596226 371.895691,50.134434 369.307800,50.124313 
	C369.839142,48.722973 370.415924,47.869961 370.992676,47.016945 
	C370.255737,42.958302 372.380188,41.410595 376.071136,41.004898 
	C379.740479,40.601585 381.241791,42.207588 381.134338,45.772522 
	C381.096802,47.018616 381.397766,48.274906 381.268433,49.764610 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M378.954712,89.986160 
	C380.397491,89.029480 381.840302,88.072792 383.252350,87.136497 
	C383.627716,87.216484 384.120819,87.177643 384.236237,87.367912 
	C385.761932,89.882980 387.234558,92.430244 388.720459,94.969460 
	C384.976929,95.477058 381.233429,95.984665 377.347107,95.892517 
	C377.506165,94.540085 377.808044,93.787392 378.525665,93.035095 
	C381.385254,93.035477 383.829132,93.035477 386.272980,93.035477 
	C386.234589,92.602478 386.196198,92.169487 386.157806,91.736488 
	C383.097168,92.154732 380.036499,92.572968 376.730713,93.006912 
	C376.485596,93.022621 375.999634,92.950989 375.996033,92.584686 
	C376.002106,91.485672 376.011780,90.752953 376.388672,90.018509 
	C377.488831,90.006577 378.221771,89.996376 378.954712,89.986160 
z"
      />
      <path
        fill="#FBFEFF"
        opacity="1.000000"
        stroke="none"
        d="
M365.998230,40.591389 
	C363.906982,41.236717 361.816376,41.464664 358.989502,41.852268 
	C355.396057,43.491432 352.538849,44.970936 349.291412,46.652523 
	C348.676086,45.941093 347.837433,44.971432 347.001160,43.999142 
	C347.003540,43.996510 347.011444,43.989132 347.362000,43.983772 
	C348.466278,43.322906 349.219940,42.667408 349.973602,42.011906 
	C352.881775,40.546604 355.789917,39.081306 358.849060,37.308083 
	C361.905731,36.795074 366.325867,33.250465 365.998230,40.591389 
z"
      />
      <path
        fill="#D15A91"
        opacity="1.000000"
        stroke="none"
        d="
M353.997559,89.875900 
	C354.540497,88.471886 355.083466,87.067879 355.790863,85.417847 
	C360.365356,85.734535 364.775421,86.297234 369.444397,87.141541 
	C368.815002,87.900307 367.926636,88.377464 366.800049,88.960007 
	C366.396576,89.118980 366.231384,89.172569 365.693542,89.259689 
	C361.546448,89.487442 357.772003,89.681671 353.997559,89.875900 
z"
      />
      <path
        fill="#7EB9B7"
        opacity="1.000000"
        stroke="none"
        d="
M378.109924,93.034706 
	C377.808044,93.787392 377.506165,94.540085 377.084320,95.576828 
	C375.584198,95.925774 374.204041,95.990662 372.156860,95.747284 
	C369.653320,95.355698 367.816803,95.272385 365.980255,95.189072 
	C364.605560,95.078407 363.230835,94.967743 361.442932,94.878609 
	C358.960114,94.585953 356.890472,94.271767 354.414246,93.877670 
	C354.007843,93.458611 354.008026,93.119446 354.428406,92.464584 
	C361.801605,94.983818 369.043701,90.194336 375.999634,92.950989 
	C375.999634,92.950989 376.485596,93.022621 377.014343,93.016769 
	C377.732025,93.018845 377.920990,93.026779 378.109924,93.034706 
z"
      />
      <path
        fill="#FBFEFF"
        opacity="1.000000"
        stroke="none"
        d="
M344.004120,28.999275 
	C348.210938,27.692427 350.616638,30.557678 353.607666,32.987747 
	C354.319519,33.467915 354.639069,33.936119 354.979309,34.702164 
	C350.930023,37.604706 348.793976,32.695789 345.192657,31.949799 
	C344.634888,30.574133 344.319519,29.786705 344.004120,28.999275 
z"
      />
      <path
        fill="#F4EAF5"
        opacity="1.000000"
        stroke="none"
        d="
M370.657837,46.964218 
	C370.415924,47.869961 369.839142,48.722973 369.090271,49.853664 
	C367.333069,51.664085 365.747986,53.196827 364.162903,54.729572 
	C363.860352,54.617245 363.557800,54.504921 363.255249,54.392593 
	C363.930054,52.088657 364.604858,49.784718 365.708679,47.162376 
	C366.241089,46.750523 366.441650,46.585266 366.433502,46.574253 
	C366.276245,46.361450 366.094513,46.166714 365.917847,45.968254 
	C365.826538,45.548260 365.735229,45.128262 365.621185,44.387161 
	C367.173309,45.014534 368.748138,45.963013 370.657837,46.964218 
z"
      />
      <path
        fill="#78C3B8"
        opacity="1.000000"
        stroke="none"
        d="
M374.439850,59.188274 
	C374.054016,58.712894 374.004944,58.137821 373.911011,57.130783 
	C376.320038,55.115936 378.773956,53.533054 381.588501,51.993629 
	C381.949158,52.037083 381.969147,52.021133 381.951050,52.351940 
	C381.934570,53.113487 381.936157,53.544231 381.937744,53.974968 
	C379.550720,55.679504 377.163666,57.384045 374.439850,59.188274 
z"
      />
      <path
        fill="#F3D7F0"
        opacity="1.000000"
        stroke="none"
        d="
M366.066223,89.226166 
	C366.231384,89.172569 366.396576,89.118980 367.231567,88.992523 
	C369.930634,88.924988 371.959930,88.930321 374.335754,88.982941 
	C375.457916,88.691612 376.233582,88.352989 377.009247,88.014374 
	C377.640045,88.458191 378.270813,88.902000 378.928162,89.665993 
	C378.221771,89.996376 377.488831,90.006577 375.942169,90.009949 
	C372.107697,89.744133 369.086975,89.485153 366.066223,89.226166 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M366.072754,95.534439 
	C367.816803,95.272385 369.653320,95.355698 371.741882,95.732864 
	C370.050995,95.977753 368.108093,95.928780 366.072754,95.534439 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M351.376282,16.920765 
	C351.001221,15.284780 351.001221,13.572207 351.001221,11.431745 
	C351.444855,11.003327 351.888519,11.002801 352.664856,11.002932 
	C352.582184,12.950451 352.166779,14.897314 351.376282,16.920765 
z"
      />
      <path
        fill="#D06E9B"
        opacity="1.000000"
        stroke="none"
        d="
M373.989258,88.935654 
	C371.959930,88.930321 369.930634,88.924988 367.469788,88.887146 
	C367.926636,88.377464 368.815002,87.900307 369.876404,87.160324 
	C371.103760,86.942833 372.158112,86.988152 373.598297,87.431328 
	C373.985840,88.198006 373.987549,88.566826 373.989258,88.935654 
z"
      />
      <path
        fill="#D07AA7"
        opacity="1.000000"
        stroke="none"
        d="
M374.335754,88.982941 
	C373.987549,88.566826 373.985840,88.198006 373.977417,87.553604 
	C374.773163,87.500793 375.575623,87.723564 376.693665,87.980347 
	C376.233582,88.352989 375.457916,88.691612 374.335754,88.982941 
z"
      />
      <path
        fill="#EEE7F6"
        opacity="1.000000"
        stroke="none"
        d="
M382.176514,53.845493 
	C381.936157,53.544231 381.934570,53.113487 381.945557,52.360203 
	C382.110504,52.597111 382.262909,53.156563 382.176514,53.845493 
z"
      />
      <path
        fill="#DB5391"
        opacity="1.000000"
        stroke="none"
        d="
M326.731232,37.456505 
	C326.731232,37.456501 326.578522,37.243523 326.905304,37.029503 
	C327.216522,36.213245 327.200958,35.611012 327.185425,35.008774 
	C327.173187,34.625160 327.160919,34.241543 327.328613,33.494415 
	C327.508514,33.130901 327.916290,32.964016 328.248413,32.813560 
	C329.421692,29.807833 330.262817,26.952560 331.393494,23.888971 
	C333.607910,18.882338 335.532776,14.084018 337.457642,9.285699 
	C337.820648,9.347802 338.183685,9.409905 338.546722,9.472009 
	C338.712524,10.648279 338.878326,11.824549 338.768646,13.230505 
	C338.035004,15.308041 337.576874,17.155891 336.834900,19.206509 
	C336.454681,20.078091 336.358276,20.746904 336.222778,21.686516 
	C336.050568,22.296242 335.917389,22.635176 335.446777,23.068800 
	C334.134033,26.544291 333.158752,29.925089 332.171570,33.597786 
	C332.061462,34.265491 331.963226,34.641304 331.651154,35.188606 
	C331.369141,35.712334 331.300934,36.064564 331.220032,36.794983 
	C329.463135,42.453434 327.718994,47.733692 325.599426,53.010635 
	C324.471924,53.002789 323.719849,52.998253 323.023163,52.608730 
	C323.686859,49.093037 324.295135,45.962334 324.903412,42.831627 
	C324.903412,42.831627 324.926666,42.392273 325.276978,41.963669 
	C325.995300,40.175545 326.363281,38.816025 326.731232,37.456505 
z"
      />
      <path
        fill="#DC5293"
        opacity="1.000000"
        stroke="none"
        d="
M298.983459,63.004128 
	C300.562225,56.959198 302.076965,50.896465 303.749908,44.877712 
	C304.459869,42.323414 305.501556,39.861340 307.421783,37.477737 
	C307.639648,38.496311 307.857513,39.514885 308.085419,41.105515 
	C308.095490,41.677567 307.924805,42.019176 307.679077,42.212742 
	C307.319611,43.010368 307.205811,43.614429 307.088013,44.425453 
	C307.084015,44.632423 306.917328,45.011406 306.674316,45.209793 
	C306.322021,46.013988 306.212708,46.619793 306.102844,47.429939 
	C306.102234,47.634277 305.948578,48.012974 305.705933,48.193817 
	C305.328003,48.950611 305.192657,49.526566 305.040466,50.339287 
	C305.023560,50.576054 304.852539,51.018917 304.711487,51.092537 
	C304.332306,51.451660 304.370453,51.711334 304.856567,52.259567 
	C305.706848,52.695293 306.385406,52.816624 307.040710,53.017792 
	C307.017426,53.097633 306.979126,53.259487 307.033325,53.525848 
	C307.725586,53.610107 308.363678,53.428005 309.261597,53.145309 
	C309.696411,52.982418 309.871399,52.920116 310.290558,52.931259 
	C310.696411,53.057178 310.858093,53.109653 311.232483,53.384388 
	C312.037842,53.611042 312.630463,53.615440 313.223114,53.619843 
	C313.223114,53.619843 313.293640,53.590038 313.274292,53.805519 
	C313.508820,53.926533 313.762756,53.832066 314.016663,53.737598 
	C314.208344,53.794720 314.400055,53.851841 314.877502,54.226856 
	C315.261322,54.705448 315.402344,54.805691 315.809296,55.175125 
	C315.955994,56.024181 315.879669,56.543587 315.457520,57.208626 
	C312.788086,57.592171 310.464539,57.830082 307.834778,57.937469 
	C305.359161,58.885799 303.189697,59.964653 300.791290,61.185219 
	C300.036041,61.885994 299.509735,62.445057 298.983459,63.004128 
z"
      />
      <path
        fill="#7DBCC0"
        opacity="1.000000"
        stroke="none"
        d="
M310.046387,52.857819 
	C309.871399,52.920116 309.696411,52.982418 308.927765,53.064323 
	C307.882446,53.142452 307.430786,53.200974 306.979126,53.259491 
	C306.979126,53.259487 307.017426,53.097633 307.106873,52.661144 
	C309.194214,46.860229 311.083557,41.450912 313.269501,36.164230 
	C313.789276,34.907146 315.301453,34.060402 316.466675,33.014702 
	C316.576233,33.004490 316.794952,32.980259 316.780151,33.339645 
	C316.461029,35.087936 316.156738,36.476841 315.852448,37.865746 
	C315.852448,37.865749 315.863464,37.899437 315.603577,38.103111 
	C314.614410,40.204807 313.885162,42.102825 313.155945,44.000847 
	C312.390808,46.061172 311.625641,48.121498 310.644135,50.738880 
	C310.300629,51.816563 310.173492,52.337189 310.046387,52.857819 
z"
      />
      <path
        fill="#D06E9B"
        opacity="1.000000"
        stroke="none"
        d="
M339.044128,13.000813 
	C338.878326,11.824549 338.712524,10.648279 338.546722,9.472009 
	C338.183685,9.409905 337.820648,9.347802 337.457642,9.285699 
	C335.532776,14.084018 333.607910,18.882338 331.322266,23.824524 
	C330.961487,23.968390 330.818634,23.839888 330.795654,23.646187 
	C330.772705,23.452484 330.681183,23.073259 330.681183,23.073257 
	C330.822632,22.634392 330.964081,22.195526 331.342407,21.216614 
	C331.683044,20.117842 331.786835,19.559120 331.890625,19.000401 
	C332.303101,17.927641 332.715546,16.854881 333.420410,15.170031 
	C334.192078,12.390728 334.671387,10.223516 335.150696,8.056305 
	C336.869781,8.325023 338.588898,8.593740 340.596375,9.235072 
	C340.884735,9.607688 340.933685,10.007408 340.779785,10.273119 
	C340.098633,11.359488 339.571381,12.180146 339.044128,13.000813 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M315.803345,57.062996 
	C315.879669,56.543587 315.955994,56.024181 316.017975,55.210220 
	C316.111481,54.841717 316.321991,54.734997 316.310547,54.699120 
	C316.233307,54.457100 316.109039,54.230099 316.000000,53.998234 
	C317.804077,52.688122 319.608154,51.378010 321.759766,50.413204 
	C322.098328,51.506336 322.089294,52.254158 321.990662,53.400826 
	C321.679871,55.607464 321.458740,57.415264 321.150391,58.870872 
	C319.309875,58.033451 317.556610,57.548222 315.803345,57.062996 
z"
      />
      <path
        fill="#82BFBB"
        opacity="1.000000"
        stroke="none"
        d="
M316.794952,32.980259 
	C316.794952,32.980259 316.576233,33.004490 316.316223,32.645885 
	C315.976349,31.499659 315.896454,30.712036 316.005524,29.631985 
	C317.858582,26.919762 319.522705,24.499968 321.186829,22.080181 
	C321.831390,22.853992 322.475983,23.627794 322.773865,24.857256 
	C321.537781,26.507927 320.648407,27.702938 319.759033,28.897955 
	C319.452881,29.607191 319.146698,30.316420 318.591583,31.523899 
	C317.826752,32.341522 317.310852,32.660892 316.794952,32.980259 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M340.933685,10.007406 
	C340.933685,10.007408 340.884735,9.607688 340.887146,9.406658 
	C342.055481,7.893489 343.221436,6.581350 344.693665,5.135288 
	C345.664398,6.101825 346.328796,7.202284 346.995972,8.651520 
	C346.544098,10.225870 346.089447,11.451444 345.140076,12.661183 
	C344.428406,12.372608 344.211487,12.099866 343.930542,11.496798 
	C342.888916,10.780114 341.911316,10.393759 340.933685,10.007406 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M334.800720,8.080158 
	C334.671387,10.223516 334.192078,12.390728 333.474121,14.828119 
	C329.507690,12.048727 331.907135,10.063841 334.800720,8.080158 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M320.821808,22.053722 
	C319.522705,24.499968 317.858582,26.919762 315.777649,29.419651 
	C315.243317,28.677359 315.125763,27.854971 315.008240,27.032578 
	C316.122009,25.414539 317.235809,23.796505 318.672852,22.087311 
	C319.483032,22.006525 319.969910,22.016897 320.821808,22.053722 
z"
      />
      <path
        fill="#D06E9B"
        opacity="1.000000"
        stroke="none"
        d="
M322.080261,53.001984 
	C322.089294,52.254158 322.098328,51.506336 322.113464,50.383507 
	C322.434509,48.963966 322.749420,47.919422 323.293457,46.331390 
	C323.616516,45.209568 323.710480,44.631241 323.804443,44.052917 
	C324.019196,43.751785 324.233917,43.450653 324.676025,42.990574 
	C324.295135,45.962334 323.686859,49.093037 322.801483,52.610962 
	C322.524384,52.998180 322.080261,53.001984 322.080261,53.001984 
z"
      />
      <path
        fill="#D06E9B"
        opacity="1.000000"
        stroke="none"
        d="
M331.032715,24.032837 
	C330.262817,26.952560 329.421692,29.807833 328.223145,32.582760 
	C327.865723,32.502415 327.762268,32.049644 327.762268,32.049644 
	C328.515503,29.671326 329.268738,27.293007 330.232788,24.465218 
	C330.443604,24.015747 330.818634,23.839888 330.818634,23.839888 
	C330.818634,23.839888 330.961487,23.968390 331.032715,24.032837 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M316.780151,33.339645 
	C317.310852,32.660892 317.826752,32.341522 318.590820,31.823734 
	C318.333527,33.800144 320.863007,37.009949 316.270111,37.899509 
	C316.156738,36.476841 316.461029,35.087936 316.780151,33.339645 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M313.542908,43.984299 
	C313.885162,42.102825 314.614410,40.204807 315.619263,38.111320 
	C315.239838,39.933151 314.584839,41.950451 313.542908,43.984299 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M320.158325,28.922894 
	C320.648407,27.702938 321.537781,26.507927 322.731476,25.208214 
	C322.209717,26.384954 321.383667,27.666397 320.158325,28.922894 
z"
      />
      <path
        fill="#D06E9B"
        opacity="1.000000"
        stroke="none"
        d="
M326.471375,37.705597 
	C326.363281,38.816025 325.995300,40.175545 325.356079,41.758400 
	C325.460419,40.639385 325.835999,39.297039 326.471375,37.705597 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M310.290558,52.931259 
	C310.173492,52.337189 310.300629,51.816563 310.629120,51.113079 
	C311.198608,51.359486 311.566711,51.788750 311.723328,52.600002 
	C311.347778,53.042042 311.183777,53.102085 311.019745,53.162132 
	C310.858093,53.109653 310.696411,53.057178 310.290558,52.931259 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M331.599548,19.039124 
	C331.786835,19.559120 331.683044,20.117842 331.362976,20.869713 
	C331.200653,20.401192 331.254578,19.739519 331.599548,19.039124 
z"
      />
      <path
        fill="#F2FBFC"
        opacity="1.000000"
        stroke="none"
        d="
M339.999390,2.000467 
	C339.374146,2.333935 338.749481,2.666937 338.124817,2.999938 
	C338.046570,2.761867 337.968292,2.523796 337.890015,2.285724 
	C338.395050,2.191263 338.900055,2.096802 339.701935,2.001734 
	C339.998779,2.001127 340.000000,2.000000 339.999390,2.000467 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M315.762695,54.081993 
	C316.109039,54.230099 316.233307,54.457100 316.310547,54.699120 
	C316.321991,54.734997 316.111481,54.841717 315.794983,54.880569 
	C315.402344,54.805691 315.261322,54.705448 315.102600,54.425224 
	C315.203125,54.259048 315.364258,54.212402 315.762695,54.081993 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M311.232483,53.384388 
	C311.183777,53.102085 311.347778,53.042042 311.758759,52.894524 
	C312.320892,52.936520 312.636078,53.065990 313.087158,53.407654 
	C312.630463,53.615440 312.037842,53.611042 311.232483,53.384388 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M323.585449,44.257591 
	C323.710480,44.631241 323.616516,45.209568 323.316040,46.003876 
	C323.195160,45.633995 323.280823,45.048130 323.585449,44.257591 
z"
      />
      <path
        fill="#D06E9B"
        opacity="1.000000"
        stroke="none"
        d="
M327.032837,35.321873 
	C327.200958,35.611012 327.216522,36.213245 326.964355,36.912537 
	C326.757843,36.551388 326.819061,36.093178 327.032837,35.321873 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M330.795654,23.646187 
	C330.818634,23.839888 330.443604,24.015747 330.252197,24.095074 
	C330.061707,23.868006 330.157166,23.594643 330.514130,23.213787 
	C330.681183,23.073259 330.772705,23.452484 330.795654,23.646187 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M313.868866,53.640572 
	C313.762756,53.832066 313.508820,53.926533 313.311951,53.798504 
	C313.368958,53.576008 313.721069,53.543549 313.868866,53.640572 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M327.574524,32.206360 
	C327.762268,32.049644 327.865723,32.502415 327.890991,32.733215 
	C327.916290,32.964016 327.508514,33.130901 327.301788,33.207542 
	C327.079712,32.941456 327.176941,32.634418 327.574524,32.206360 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M261.751251,54.856697 
	C262.545807,55.843750 263.340393,56.830795 264.103058,57.778191 
	C266.811584,55.618858 269.093140,53.799896 271.721252,51.963860 
	C272.067749,51.946789 272.026031,51.975582 272.117706,52.184929 
	C272.396301,52.687695 272.655273,52.887993 272.986237,52.995171 
	C273.076935,53.445309 273.167633,53.895443 273.190887,54.922363 
	C273.140411,55.669289 273.157440,55.839424 273.174438,56.009563 
	C273.174438,56.009563 273.035065,56.463451 272.988678,56.715309 
	C272.942291,56.967171 272.971436,56.910851 272.727173,57.013336 
	C272.281281,57.396149 272.079651,57.676476 271.596375,58.149487 
	C271.834839,58.860973 272.619415,59.920795 272.834503,59.815647 
	C275.231598,58.643764 277.540680,57.291893 279.873505,55.988564 
	C281.851166,55.099903 283.581116,51.958397 285.877319,55.950161 
	C284.800293,57.437111 283.871277,58.286037 282.588928,59.093647 
	C280.592285,60.088173 278.967529,61.155079 277.302094,62.154068 
	C272.258423,65.179428 267.535645,69.118294 261.003632,68.000275 
	C258.560150,67.343521 256.135925,66.240448 253.668747,66.134109 
	C249.920898,65.972572 245.813080,67.423859 243.861603,61.789261 
	C243.772079,60.086163 243.710190,59.117088 243.799927,57.860607 
	C244.023956,56.462299 244.096375,55.351398 244.156219,53.927940 
	C245.229355,53.362984 246.315063,53.110588 247.779114,52.770245 
	C247.825027,52.214962 247.917267,51.099552 248.009491,49.984146 
	C249.763275,52.155350 251.517044,54.326553 253.846603,57.210579 
	C254.699158,57.026119 256.543823,56.627022 258.926727,55.976524 
	C259.737274,55.556854 259.903870,55.315582 259.964783,55.001316 
	C259.964783,55.001316 260.001343,55.009407 260.292297,54.971657 
	C260.972565,54.908165 261.361908,54.882427 261.751251,54.856697 
z"
      />
      <path
        fill="#DA5A95"
        opacity="1.000000"
        stroke="none"
        d="
M248.005768,49.992073 
	C247.917267,51.099552 247.825027,52.214962 247.779114,52.770245 
	C246.315063,53.110588 245.229355,53.362984 244.118591,53.898369 
	C244.093521,54.181366 244.103226,54.088352 243.899963,54.128990 
	C243.427277,54.273312 243.232010,54.456123 242.856018,54.959251 
	C238.061844,59.448471 233.522507,63.696503 228.983185,67.944534 
	C228.538849,67.970886 228.094528,67.997231 227.078796,68.027260 
	C226.340271,68.047279 226.173126,68.063622 226.005981,68.079964 
	C225.500336,67.173897 224.994690,66.267830 224.224609,64.756676 
	C224.270050,61.431610 224.579880,58.711636 224.889725,55.991657 
	C224.889725,55.991657 225.060898,55.599808 225.299316,55.185677 
	C225.645737,54.189331 225.753723,53.607113 225.861725,53.024899 
	C225.861725,53.024899 226.038498,52.583202 226.274399,52.157089 
	C226.622437,51.162857 226.734558,50.594738 226.846680,50.026619 
	C226.846680,50.026619 227.029831,49.613731 227.247040,49.337715 
	C227.464249,49.061695 227.858063,48.965969 228.138458,48.814056 
	C228.570511,48.066315 228.722153,47.470490 228.835724,46.860100 
	C228.797653,46.845535 228.760651,46.772881 229.115356,46.699471 
	C230.334000,44.096954 231.197968,41.567844 232.295837,38.841019 
	C232.649429,38.057835 232.769089,37.472374 232.911591,36.903446 
	C232.934418,36.919983 232.886856,36.935127 233.243805,36.907837 
	C234.632019,34.194077 235.663300,31.507612 236.707855,28.546406 
	C236.466965,27.828836 236.212799,27.386011 235.759598,26.693256 
	C234.709244,25.440434 233.857910,24.437542 233.006592,23.434647 
	C234.793045,23.310709 236.579514,23.186773 239.153656,23.040932 
	C243.334991,23.358110 242.673798,21.376249 242.015228,19.395880 
	C242.159454,19.279785 242.303665,19.163692 242.898712,19.271900 
	C243.921783,19.647459 244.494003,19.798714 245.066208,19.949970 
	C245.066208,19.949970 244.990860,19.964809 245.010559,20.229996 
	C245.035614,20.685308 245.040970,20.875433 245.046310,21.065557 
	C245.046310,21.065557 244.996307,21.026150 244.791504,21.155087 
	C244.730637,21.846367 244.874557,22.408712 245.018494,22.971058 
	C245.018494,22.971056 245.004578,22.991850 245.103119,23.245258 
	C245.133804,24.331244 245.065948,25.163820 244.767761,26.135155 
	C244.353989,26.763584 244.170532,27.253252 243.668945,27.806980 
	C242.927826,27.960703 242.504868,28.050365 241.890732,27.957546 
	C241.495621,28.571369 241.291687,29.367674 241.040359,30.404987 
	C240.992966,30.645994 240.753906,31.075157 240.577225,31.203171 
	C240.196518,31.557392 240.084946,31.822544 239.994019,32.474651 
	C236.856796,40.072056 233.722168,47.294037 230.824753,54.609955 
	C230.507782,55.410267 231.555374,56.750992 232.057343,58.034847 
	C232.330582,58.044060 232.516525,57.859493 232.656143,57.613773 
	C232.609833,57.552620 232.588943,57.400642 232.933350,57.532410 
	C237.184952,54.762745 241.092178,51.861309 244.999390,48.959873 
	C245.167831,48.926529 245.336258,48.893188 245.919998,49.170826 
	C246.895203,49.651302 247.455078,49.820801 248.014969,49.990299 
	C248.014969,49.990299 248.002045,50.000004 248.005768,49.992073 
z"
      />
      <path
        fill="#7EBEBD"
        opacity="1.000000"
        stroke="none"
        d="
M216.188248,66.944420 
	C216.068634,67.631981 215.949020,68.319534 215.623459,69.583122 
	C215.343369,70.753090 215.269257,71.347038 215.195129,71.940979 
	C215.033218,73.323883 214.871307,74.706779 214.518524,76.796890 
	C214.231918,78.659492 214.136185,79.814903 214.040451,80.970306 
	C214.040451,80.970306 213.980011,80.986069 213.642761,81.046623 
	C212.586258,80.491974 211.867004,79.876770 211.392380,78.934868 
	C213.665970,61.738754 218.382996,45.616585 225.374603,30.177303 
	C226.055511,30.841301 226.494736,31.387533 226.933945,31.933769 
	C226.933945,31.933771 226.989899,31.956257 226.758087,32.049225 
	C226.456985,32.273083 226.295425,32.462910 226.334732,32.524429 
	C226.486206,32.761551 226.701874,32.957649 226.893600,33.169044 
	C224.890152,38.455467 222.886703,43.741890 220.616364,49.686348 
	C219.536850,53.551674 218.724258,56.758957 217.911652,59.966240 
	C217.844879,60.324249 217.778091,60.682259 217.569366,61.488281 
	C217.353149,62.265335 217.278900,62.594376 217.204636,62.923416 
	C217.102219,63.347164 216.999802,63.770912 216.669098,64.720963 
	C216.356628,65.812988 216.272446,66.378708 216.188248,66.944420 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M288.987396,80.492065 
	C290.875702,79.316231 292.763977,78.140404 294.652283,76.964569 
	C294.274902,76.889595 293.897522,76.814621 293.520142,76.739647 
	C294.140137,73.652397 294.760162,70.565147 295.659424,67.669983 
	C296.113861,69.982811 295.997742,72.180397 296.532074,74.206421 
	C297.348114,77.300659 299.259766,78.191826 302.287659,75.814430 
	C306.673767,71.954735 310.510529,68.095322 309.944916,61.836113 
	C310.969238,62.583290 311.580872,63.324001 312.081451,64.421951 
	C311.213959,71.417480 306.107361,75.178230 301.677490,79.768272 
	C297.155151,80.070602 293.071289,80.281334 288.987396,80.492065 
z"
      />
      <path
        fill="#F0E3F6"
        opacity="1.000000"
        stroke="none"
        d="
M228.993866,68.336227 
	C233.522507,63.696503 238.061844,59.448471 243.061508,54.930286 
	C243.815613,54.571388 244.009399,54.380795 244.103226,54.088352 
	C244.103226,54.088352 244.093521,54.181366 244.131165,54.210934 
	C244.096375,55.351398 244.023956,56.462299 243.494949,57.900837 
	C239.212875,61.386932 235.387405,64.545387 231.561951,67.703850 
	C231.961548,68.259987 232.361160,68.816132 232.760773,69.372276 
	C234.490952,68.264496 236.221146,67.156715 237.951324,66.048935 
	C238.774155,70.145889 235.967514,71.446617 232.915497,71.650002 
	C231.682236,71.732185 230.312286,69.763374 228.993866,68.336227 
z"
      />
      <path
        fill="#D06294"
        opacity="1.000000"
        stroke="none"
        d="
M301.973022,75.932014 
	C299.259766,78.191826 297.348114,77.300659 296.532074,74.206421 
	C295.997742,72.180397 296.113861,69.982811 295.911438,67.423164 
	C296.268860,65.877289 296.653503,64.770325 297.163391,63.332329 
	C297.665314,63.001476 298.041962,63.001644 298.701019,63.002968 
	C299.509735,62.445057 300.036041,61.885994 300.786499,61.569405 
	C301.057129,63.433613 301.795044,65.593735 301.026459,66.580765 
	C298.198975,70.211884 299.686066,73.025986 301.973022,75.932014 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M288.529297,80.567490 
	C293.071289,80.281334 297.155151,80.070602 301.856140,79.949371 
	C302.676727,80.304214 302.880219,80.569557 302.799927,81.161179 
	C292.676605,81.658096 282.837067,81.828743 272.748169,81.998688 
	C272.498779,81.997978 272.000000,82.000000 271.625000,81.999832 
	C270.500000,81.999947 269.750000,82.000229 269.000000,82.000504 
	C265.855011,82.204750 262.709991,82.408989 259.305298,82.341179 
	C261.753387,81.692436 264.461151,81.315758 268.039856,80.938240 
	C271.242279,80.843811 273.573792,80.750229 275.905334,80.656647 
	C279.960632,80.652069 284.015930,80.647491 288.529297,80.567490 
z"
      />
      <path
        fill="#89BEC3"
        opacity="1.000000"
        stroke="none"
        d="
M268.844330,71.891579 
	C268.154846,71.868744 267.465363,71.845909 266.271484,71.538284 
	C266.104614,70.740265 266.322388,70.011246 266.798157,69.747200 
	C271.508026,67.133408 276.260620,64.596634 281.038544,62.383842 
	C281.509125,63.244579 281.942291,63.759521 282.375458,64.274460 
	C279.115479,66.403481 275.855530,68.532501 271.953857,70.821053 
	C270.489563,71.284248 269.666962,71.587914 268.844330,71.891579 
z"
      />
      <path
        fill="#EFEEF9"
        opacity="1.000000"
        stroke="none"
        d="
M281.001129,62.038052 
	C276.260620,64.596634 271.508026,67.133408 266.798157,69.747200 
	C266.322388,70.011246 266.104614,70.740265 265.927551,71.510269 
	C265.101929,71.588913 263.918243,71.686462 263.182739,71.159012 
	C262.365295,70.572800 261.956390,69.416862 261.186646,68.254608 
	C267.535645,69.118294 272.258423,65.179428 277.302094,62.154068 
	C278.967529,61.155079 280.592285,60.088173 282.586060,59.409027 
	C282.291382,60.523170 281.646240,61.280613 281.001129,62.038052 
z"
      />
      <path
        fill="#82BFBB"
        opacity="1.000000"
        stroke="none"
        d="
M281.038544,62.383850 
	C281.646240,61.280613 282.291382,60.523170 282.939392,59.450348 
	C283.871277,58.286037 284.800293,57.437111 285.924103,56.332302 
	C287.635437,56.679329 289.151978,57.282242 290.668549,57.885155 
	C289.620239,58.980160 288.671112,60.199112 287.497894,61.138035 
	C286.108185,62.250168 284.525330,63.120892 282.700806,64.185364 
	C281.942291,63.759521 281.509125,63.244579 281.038544,62.383850 
z"
      />
      <path
        fill="#7BBBBA"
        opacity="1.000000"
        stroke="none"
        d="
M238.185028,65.808563 
	C236.221146,67.156715 234.490952,68.264496 232.760773,69.372276 
	C232.361160,68.816132 231.961548,68.259987 231.561951,67.703850 
	C235.387405,64.545387 239.212875,61.386932 243.343323,58.188248 
	C243.710190,59.117088 243.772079,60.086163 243.768341,61.406647 
	C241.941376,63.028107 240.180069,64.298149 238.185028,65.808563 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M227.370041,31.953739 
	C226.494736,31.387533 226.055511,30.841301 225.000397,30.156460 
	C223.886307,30.011600 223.388092,30.005350 222.767685,29.787514 
	C222.420944,29.017942 222.196396,28.459953 222.418732,27.925999 
	C224.636353,27.784163 226.407120,27.618288 228.386475,27.502041 
	C228.893967,27.495548 229.137177,27.347660 229.597717,27.175108 
	C230.239731,27.192616 230.608704,27.143023 231.314636,27.147238 
	C232.098068,27.280891 232.544540,27.360737 232.999329,27.760887 
	C233.014694,29.384933 233.021729,30.688671 233.028763,31.992411 
	C231.287888,31.986179 229.547012,31.979948 227.370041,31.953739 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M211.147766,79.261566 
	C211.867004,79.876770 212.586258,80.491974 213.648224,81.077377 
	C214.829483,81.704491 215.668045,82.361412 216.881622,83.064911 
	C214.462860,83.432938 211.669037,83.754379 208.437683,84.035355 
	C207.642212,81.984161 207.284302,79.973434 206.913849,77.534927 
	C206.901291,77.107155 206.968842,77.029678 207.237885,77.082169 
	C207.671570,77.213875 207.836212,77.293083 208.067200,77.649765 
	C208.596741,78.088356 209.059937,78.249458 209.523148,78.410568 
	C209.581024,77.852760 209.638901,77.294960 209.644440,76.667542 
	C209.592117,76.597923 209.568542,76.425346 209.879883,76.701950 
	C210.510071,77.739563 210.828918,78.500565 211.147766,79.261566 
z"
      />
      <path
        fill="#D35A91"
        opacity="1.000000"
        stroke="none"
        d="
M257.681396,82.038017 
	C252.463181,82.594620 246.970383,83.046204 241.208130,83.184364 
	C244.011963,82.558914 247.085266,82.246887 251.016449,81.926407 
	C253.548767,81.824951 255.223175,81.731956 256.897583,81.638954 
	C257.067322,81.736969 257.237061,81.834976 257.681396,82.038017 
z"
      />
      <path
        fill="#E7E5F0"
        opacity="1.000000"
        stroke="none"
        d="
M279.545654,55.928581 
	C277.540680,57.291893 275.231598,58.643764 272.834503,59.815647 
	C272.619415,59.920795 271.834839,58.860973 271.843872,58.052731 
	C272.572449,57.479141 272.771942,57.194992 272.971436,56.910847 
	C272.971436,56.910851 272.942291,56.967171 272.920837,56.942818 
	C274.185547,55.755066 275.471680,54.591667 276.881073,53.316776 
	C277.644531,54.150497 278.431152,55.009548 279.545654,55.928581 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M218.279541,59.915066 
	C218.724258,56.758957 219.536850,53.551674 220.589081,50.057198 
	C220.101624,53.134636 219.374527,56.499264 218.279541,59.915066 
z"
      />
      <path
        fill="#F0E6F7"
        opacity="1.000000"
        stroke="none"
        d="
M233.018021,32.362720 
	C233.021729,30.688671 233.014694,29.384933 233.361206,27.651056 
	C234.462692,27.128342 235.210663,27.035765 235.958618,26.943186 
	C236.212799,27.386011 236.466965,27.828836 236.369003,28.632465 
	C235.025146,31.002775 234.033432,33.012283 232.997406,35.004868 
	C232.953110,34.987946 232.866562,34.949169 232.866562,34.949169 
	C232.913467,34.210457 232.960373,33.471741 233.018021,32.362720 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M232.061935,39.038734 
	C231.197968,41.567844 230.334000,44.096954 229.131226,46.401836 
	C228.803177,45.780685 228.813950,45.383766 228.824738,44.986847 
	C229.545853,43.294380 230.266953,41.601917 231.255234,39.506573 
	C231.522415,39.103691 231.946976,38.954540 231.975006,38.976562 
	C232.003036,38.998581 232.061935,39.038734 232.061935,39.038734 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M224.516373,56.086990 
	C224.579880,58.711636 224.270050,61.431610 223.958771,64.578339 
	C224.019241,62.064175 224.081131,59.123249 224.516373,56.086990 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M275.604156,80.445160 
	C273.573792,80.750229 271.242279,80.843811 268.476501,80.871323 
	C270.462494,80.614716 272.882721,80.424194 275.604156,80.445160 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M214.338135,80.818153 
	C214.136185,79.814903 214.231918,78.659492 214.512024,77.214882 
	C214.676193,78.172447 214.655991,79.419228 214.338135,80.818153 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M256.625244,81.431870 
	C255.223175,81.731956 253.548767,81.824951 251.458038,81.857010 
	C252.812119,81.605637 254.582504,81.415215 256.625244,81.431870 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M228.475098,45.073845 
	C228.813950,45.383766 228.803177,45.780685 228.776520,46.475243 
	C228.760651,46.772881 228.797653,46.845535 228.584122,47.034592 
	C228.199738,47.804424 228.028900,48.385197 227.858063,48.965969 
	C227.858063,48.965969 227.464249,49.061695 227.268250,49.112869 
	C227.423309,47.829639 227.774384,46.495239 228.475098,45.073845 
z"
      />
      <path
        fill="#EFEEF9"
        opacity="1.000000"
        stroke="none"
        d="
M269.197357,71.929352 
	C269.666962,71.587914 270.489563,71.284248 271.651215,70.989540 
	C271.176941,71.321373 270.363678,71.644249 269.197357,71.929352 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M232.888763,36.886909 
	C232.769089,37.472374 232.649429,38.057835 232.295837,38.841019 
	C232.061935,39.038734 232.003036,38.998581 231.950073,38.741802 
	C231.897125,38.485023 231.795685,38.023575 231.795685,38.023575 
	C231.795685,38.023575 232.005356,37.596630 232.237534,37.297615 
	C232.469711,36.998600 232.888763,36.886909 232.888763,36.886909 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M225.631760,53.221611 
	C225.753723,53.607113 225.645737,54.189331 225.309174,54.972103 
	C225.187653,54.587879 225.294724,54.003101 225.631760,53.221611 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M215.414200,71.727356 
	C215.269257,71.347038 215.343369,70.753090 215.628906,69.932785 
	C215.771301,70.308853 215.702271,70.911293 215.414200,71.727356 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M216.402588,66.747223 
	C216.272446,66.378708 216.356628,65.812988 216.654755,65.048225 
	C216.784790,65.416122 216.700867,65.983070 216.402588,66.747223 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M226.621124,50.216171 
	C226.734558,50.594738 226.622437,51.162857 226.279724,51.919266 
	C226.164627,51.540279 226.280106,50.973003 226.621124,50.216171 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M232.911591,36.903446 
	C232.888763,36.886909 232.469711,36.998600 232.262115,37.061066 
	C232.178558,36.538536 232.302612,35.953545 232.646606,35.158859 
	C232.866562,34.949169 232.953110,34.987946 232.978912,35.324684 
	C232.965439,36.085987 232.926147,36.510559 232.886856,36.935127 
	C232.886856,36.935127 232.934418,36.919983 232.911591,36.903446 
z"
      />
      <path
        fill="#E7E5F0"
        opacity="1.000000"
        stroke="none"
        d="
M273.313507,55.924301 
	C273.157440,55.839424 273.140411,55.669289 273.229675,55.265900 
	C273.650574,55.273018 273.689453,55.541817 273.313507,55.924301 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M231.600189,38.170391 
	C231.795685,38.023575 231.897125,38.485023 231.922058,38.719780 
	C231.946976,38.954540 231.522415,39.103691 231.305969,39.164993 
	C231.072418,38.880863 231.177475,38.577831 231.600189,38.170391 
z"
      />
      <path
        fill="#F0E3F6"
        opacity="1.000000"
        stroke="none"
        d="
M226.091370,68.232178 
	C226.173126,68.063622 226.340271,68.047279 226.751495,68.093102 
	C226.722656,68.231644 226.449707,68.308022 226.091370,68.232178 
z"
      />
      <path
        fill="#CE6693"
        opacity="1.000000"
        stroke="none"
        d="
M273.015991,52.748680 
	C272.655273,52.887993 272.396301,52.687695 272.118195,52.161331 
	C272.027008,51.928383 272.499390,51.977562 272.736023,51.994293 
	C272.972687,52.011028 273.045715,52.502186 273.015991,52.748680 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M217.402298,62.755474 
	C217.278900,62.594376 217.353149,62.265335 217.618652,61.758389 
	C217.739914,61.916168 217.669937,62.251850 217.402298,62.755474 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M226.918152,32.867641 
	C226.701874,32.957649 226.486206,32.761551 226.334732,32.524429 
	C226.295425,32.462910 226.456985,32.273083 226.728439,32.051872 
	C226.934647,32.163116 226.938675,32.364677 226.918152,32.867641 
z"
      />
      <path
        fill="#D15A91"
        opacity="1.000000"
        stroke="none"
        d="
M174.984863,71.878021 
	C174.043732,71.441498 173.102615,71.004967 172.014221,69.961037 
	C171.533325,68.537498 171.199692,67.721352 170.962769,66.591675 
	C171.334457,65.227875 171.609451,64.177628 172.251205,63.053860 
	C176.755402,60.858517 177.323273,59.426792 174.985626,56.716148 
	C174.968811,56.247150 174.957245,56.059750 174.945679,55.872349 
	C180.113098,54.303673 180.176666,54.398857 179.232224,63.218601 
	C182.171234,62.137363 184.794342,61.135136 187.444031,60.208935 
	C189.695053,59.422092 191.976730,58.722958 194.497375,58.352642 
	C188.161545,63.106182 181.573196,67.492096 174.984863,71.878021 
z"
      />
      <path
        fill="#D05E95"
        opacity="1.000000"
        stroke="none"
        d="
M156.866837,69.793915 
	C158.374969,65.608337 159.883118,61.422756 161.711380,57.049953 
	C164.707214,57.559826 166.982864,61.215908 170.583405,57.968216 
	C170.974625,57.970383 171.090820,58.095634 171.012024,58.406845 
	C170.638138,60.240013 170.343018,61.761971 169.852478,63.537060 
	C166.105453,66.868690 162.553848,69.947197 159.002243,73.025703 
	C158.858734,72.928902 158.715240,72.832100 158.320557,72.326981 
	C157.668549,71.210411 157.267685,70.502167 156.866837,69.793915 
z"
      />
      <path
        fill="#83C0BF"
        opacity="1.000000"
        stroke="none"
        d="
M197.986938,60.090214 
	C198.389114,61.108402 199.309814,62.904194 199.118195,63.031963 
	C193.084106,67.055733 186.980453,70.981316 180.767914,74.723526 
	C179.979782,75.198257 178.535324,74.583351 177.310654,73.965775 
	C184.146561,69.002258 191.066757,64.546234 197.986938,60.090214 
z"
      />
      <path
        fill="#F0E3F6"
        opacity="1.000000"
        stroke="none"
        d="
M197.987640,59.741722 
	C191.066757,64.546234 184.146561,69.002258 177.003448,73.738724 
	C176.205521,73.517044 175.630524,73.014908 175.020203,72.195396 
	C181.573196,67.492096 188.161545,63.106182 194.818726,58.387794 
	C195.665436,58.040405 196.443314,58.025490 197.610596,58.005287 
	C198.000000,58.000000 198.001221,58.000923 198.001221,58.000923 
	C197.996933,58.465023 197.992645,58.929127 197.987640,59.741722 
z"
      />
      <path
        fill="#83B9BB"
        opacity="1.000000"
        stroke="none"
        d="
M170.866074,66.905212 
	C171.199692,67.721352 171.533325,68.537498 171.860138,69.681793 
	C170.076324,71.571228 168.319031,73.155754 166.515472,74.685730 
	C165.289200,75.725990 163.989914,78.159340 163.005646,74.106491 
	C165.794464,71.328445 168.330261,69.116821 170.866074,66.905212 
z"
      />
      <path
        fill="#F1DDF4"
        opacity="1.000000"
        stroke="none"
        d="
M170.962769,66.591667 
	C168.330261,69.116821 165.794464,71.328445 162.998901,73.772705 
	C161.639053,73.851013 160.538986,73.696678 159.220581,73.284027 
	C162.553848,69.947197 166.105453,66.868690 170.157608,63.505421 
	C171.066925,63.189560 171.475677,63.158466 171.884445,63.127373 
	C171.609451,64.177628 171.334457,65.227875 170.962769,66.591667 
z"
      />
      <path
        fill="#83B4B9"
        opacity="1.000000"
        stroke="none"
        d="
M156.626099,70.036667 
	C157.267685,70.502167 157.668549,71.210411 158.080322,72.228470 
	C158.050003,72.740898 158.008743,72.943520 157.743561,73.606773 
	C157.279510,74.323502 157.169815,74.626732 157.190521,74.977089 
	C157.190521,74.977089 157.005402,75.380676 156.755112,75.733345 
	C156.371368,76.388062 156.237900,76.690102 156.104431,76.992142 
	C155.556671,77.826012 155.008911,78.659882 153.894653,79.635864 
	C152.882523,79.841728 152.436905,79.905472 151.991272,79.969223 
	C151.991272,79.969223 151.991165,80.009933 152.044403,79.651581 
	C152.415359,78.196037 152.733093,77.098854 153.050812,76.001671 
	C154.162338,74.094254 155.273834,72.186829 156.626099,70.036667 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M205.981277,84.103706 
	C200.221588,85.111610 194.050690,86.066391 187.447205,86.978165 
	C193.199768,85.973625 199.384918,85.012100 205.981277,84.103706 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M205.551758,70.049217 
	C205.911514,70.156685 205.953445,70.324806 206.008606,71.133743 
	C206.337494,73.526253 206.653168,75.277969 206.968842,77.029678 
	C206.968842,77.029678 206.901291,77.107155 206.867401,77.147034 
	C202.762054,75.627640 203.857315,72.900551 205.551758,70.049217 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M159.835999,90.841171 
	C158.264374,91.704018 156.489502,92.298721 154.353806,92.899536 
	C155.872910,92.128105 157.752808,91.350563 159.835999,90.841171 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M206.626358,64.166321 
	C206.806641,65.183281 206.700455,66.357536 206.345276,67.783813 
	C206.177475,66.798431 206.258682,65.561020 206.626358,64.166321 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M184.866058,86.863846 
	C183.621323,87.431358 182.195557,87.732605 180.391571,87.984787 
	C181.570572,87.489670 183.127792,87.043633 184.866058,86.863846 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M151.962677,80.342735 
	C152.436905,79.905472 152.882523,79.841728 153.664795,79.822906 
	C153.557541,80.873428 153.113647,81.879013 152.311691,82.923126 
	C151.947128,82.213188 151.940613,81.464722 151.962677,80.342735 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M182.005371,52.342785 
	C182.609863,52.542961 183.219711,53.085922 183.829575,53.628883 
	C183.547989,53.925785 183.266403,54.222687 182.984818,54.519592 
	C182.660126,53.908253 182.335449,53.296909 182.005371,52.342785 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M163.962616,90.089668 
	C163.731750,90.377480 163.187180,90.630608 162.337387,90.848328 
	C162.571060,90.560280 163.109985,90.307632 163.962616,90.089668 
z"
      />
      <path
        fill="#D05E95"
        opacity="1.000000"
        stroke="none"
        d="
M170.917206,57.907143 
	C171.337662,57.533863 171.815536,57.223827 172.633911,56.942909 
	C172.974396,56.972031 173.062393,57.092144 172.881439,57.316460 
	C172.163925,57.725731 171.627365,57.910683 171.090820,58.095634 
	C171.090820,58.095634 170.974625,57.970383 170.917206,57.907143 
z"
      />
      <path
        fill="#D05E95"
        opacity="1.000000"
        stroke="none"
        d="
M174.645248,55.890324 
	C174.957245,56.059750 174.968811,56.247150 174.663956,56.727112 
	C173.919144,57.043831 173.490768,57.067989 173.062393,57.092144 
	C173.062393,57.092144 172.974396,56.972031 172.928802,56.912155 
	C173.370407,56.537617 173.857605,56.222958 174.645248,55.890324 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M169.082138,89.132690 
	C168.833740,89.429153 168.252853,89.680817 167.349854,89.892258 
	C167.601700,89.597328 168.175690,89.342613 169.082138,89.132690 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M150.981384,94.125519 
	C150.993820,94.320831 150.760635,94.551338 150.275970,94.799637 
	C150.173065,94.502693 150.410141,94.283791 150.981384,94.125519 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M156.316833,76.857178 
	C156.237900,76.690102 156.371368,76.388062 156.721497,75.952789 
	C156.801865,76.120445 156.665558,76.421333 156.316833,76.857178 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M157.387909,74.829254 
	C157.169815,74.626732 157.279510,74.323502 157.717484,73.915848 
	C157.924698,74.113022 157.814667,74.418732 157.387909,74.829254 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M178.054016,88.115707 
	C178.109756,88.398643 177.858261,88.573402 177.298752,88.622711 
	C177.252304,88.341095 177.504303,88.177856 178.054016,88.115707 
z"
      />
      <path
        fill="#F7EDF7"
        opacity="1.000000"
        stroke="none"
        d="
M153.001221,93.119400 
	C153.091217,93.403297 152.852142,93.619339 152.282074,93.752838 
	C152.192856,93.460770 152.433212,93.254517 153.001221,93.119400 
z"
      />
      <path
        fill="#F0E3F6"
        opacity="1.000000"
        stroke="none"
        d="
M197.999542,57.999401 
	C198.089111,57.962360 198.179138,57.925919 198.135193,57.945198 
	C198.001221,58.000923 198.000000,58.000000 197.999542,57.999401 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M146.963959,86.844604 
	C146.950592,86.641380 147.215576,86.397804 147.745422,86.090347 
	C147.754303,86.285736 147.498322,86.544998 146.963959,86.844604 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M150.021729,84.852234 
	C149.986984,84.649467 150.223038,84.385475 150.712860,84.035614 
	C150.741943,84.230164 150.517227,84.510582 150.021729,84.852234 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M151.184296,83.872269 
	C151.133392,83.690857 151.316696,83.417046 151.735474,83.052872 
	C151.786804,83.234962 151.602646,83.507416 151.184296,83.872269 
z"
      />
      <path
        fill="#F1EDF6"
        opacity="1.000000"
        stroke="none"
        d="
M29.203508,35.197350 
	C29.737371,34.957554 30.223730,34.696888 30.854336,34.193909 
	C37.170143,30.479637 43.341698,27.007681 49.735741,23.265720 
	C50.624447,22.936321 51.290668,22.876926 52.724545,22.884085 
	C55.645245,22.388918 57.798294,21.827196 59.951344,21.265476 
	C60.120049,21.204399 60.288757,21.143322 61.009377,21.014669 
	C62.677814,20.957691 63.794346,20.968292 65.166946,21.286221 
	C69.646645,22.334494 73.870277,23.075436 78.093903,23.816380 
	C84.234711,27.748837 91.134933,30.874891 96.352135,35.788097 
	C106.059952,44.930244 110.453598,56.671848 108.804916,70.682076 
	C108.642105,71.123154 108.586815,71.271729 108.586815,71.271729 
	C99.372864,71.186768 90.158905,71.101807 80.040833,71.004013 
	C77.077332,71.053574 75.017952,71.115974 72.630676,71.133606 
	C71.863579,71.121689 71.424370,71.154541 70.562462,71.101044 
	C68.415215,71.067886 66.690674,71.121094 64.569077,71.121674 
	C63.106918,71.110016 62.041809,71.150986 60.557388,71.111267 
	C58.434566,71.086105 56.731064,71.141609 54.562057,71.143227 
	C46.958950,71.237717 39.821342,71.386108 32.409035,71.498489 
	C31.577780,71.490547 31.021221,71.518616 29.997246,71.476631 
	C27.949471,71.496925 26.369112,71.587265 24.449953,71.488831 
	C21.892149,71.062851 19.673143,70.825638 17.099178,70.550476 
	C17.099178,66.806252 17.099178,63.401051 17.099178,59.995850 
	C20.463562,52.627285 23.827946,45.258720 27.385677,37.593422 
	C27.579027,37.296688 27.822420,37.163376 28.173531,36.919067 
	C28.734396,36.140739 28.944151,35.606728 29.153908,35.072712 
	C29.153906,35.072712 29.156002,35.176476 29.203508,35.197350 
M61.476891,25.095114 
	C61.310955,25.118816 61.145023,25.142517 60.123032,24.997917 
	C44.352554,25.499710 33.947914,34.371395 25.961000,47.011948 
	C25.961000,47.011948 25.931355,46.922333 25.403170,47.270317 
	C25.203596,47.859882 25.004023,48.449448 24.403631,49.618275 
	C21.885801,55.062935 19.968098,60.609791 21.164602,66.880341 
	C49.538860,66.880341 77.588394,66.880341 105.608826,66.880341 
	C106.965508,45.736233 86.014488,24.409578 64.233528,25.011690 
	C63.481453,25.056004 62.729374,25.100317 61.476891,25.095114 
z"
      />
      <path
        fill="#E0FBFB"
        opacity="1.000000"
        stroke="none"
        d="
M77.063683,74.539520 
	C87.387032,74.635895 97.712708,74.642548 108.031647,74.909431 
	C109.941261,74.958824 111.823700,76.058632 113.718689,76.673630 
	C112.708855,78.474968 112.109795,80.793457 110.613312,81.982117 
	C105.840042,85.773537 101.093033,89.956329 95.623589,92.381638 
	C92.218987,93.891350 89.458878,94.720169 88.557228,98.624870 
	C88.367348,99.447151 87.255165,100.056442 85.948486,100.939171 
	C84.886169,101.166718 84.443321,101.218971 83.627419,101.203354 
	C82.503212,101.204750 81.752052,101.274033 80.546371,101.258690 
	C76.373566,101.222794 72.655296,101.271507 68.719055,101.269371 
	C68.501091,101.218521 68.064644,101.318031 67.717331,101.252670 
	C66.899261,101.179230 66.428497,101.171158 65.657806,101.051056 
	C64.942444,100.840179 64.527016,100.741333 64.092110,100.197693 
	C63.996593,94.494263 63.920559,89.235634 63.905991,83.767181 
	C63.967457,83.557358 64.055443,83.129097 64.292183,83.014114 
	C64.689651,82.603203 64.850372,82.307281 65.005547,82.005829 
	C65.000000,82.000305 65.013481,82.007202 65.257538,81.812286 
	C66.312210,80.402321 67.122833,79.187279 68.160324,77.863693 
	C68.585571,77.490120 68.783958,77.225075 69.290527,76.871948 
	C70.730347,76.176781 71.862000,75.569687 73.403755,74.972885 
	C74.897141,74.835297 75.980408,74.687408 77.063683,74.539520 
M82.827202,90.201584 
	C84.216141,90.219635 85.605087,90.237686 87.414772,90.642761 
	C88.278419,90.496880 89.142075,90.351006 90.813187,90.143562 
	C92.543823,89.764008 94.274467,89.384453 96.000000,89.000000 
	C96.000000,89.000000 96.005043,89.004906 96.632172,88.844101 
	C97.723999,87.903946 100.625816,87.174889 96.662178,85.349174 
	C96.100754,85.468513 95.539337,85.587860 94.186302,85.798286 
	C93.127342,85.757538 92.068375,85.716789 90.524567,85.283730 
	C89.346733,85.412476 88.168900,85.541222 86.197815,85.776566 
	C85.133087,85.749321 84.068352,85.722076 82.422798,85.269203 
	C80.951317,85.177170 79.479828,85.085136 77.534019,84.484695 
	C78.811134,83.922539 80.072845,82.916771 81.367928,82.871788 
	C88.584709,82.621094 95.809578,82.603203 103.459946,82.621788 
	C103.768349,82.569168 104.019554,82.420059 104.750755,81.889877 
	C105.554626,81.319580 106.358490,80.749290 107.902908,80.058830 
	C108.537872,79.448830 109.172836,78.838829 109.807800,78.228828 
	C109.141914,77.861855 108.473663,77.176010 107.810539,77.180939 
	C104.180504,77.207855 100.551727,77.404320 96.019966,77.356819 
	C91.040253,77.440308 86.060547,77.523788 80.287666,77.337845 
	C77.768715,77.831589 75.249763,78.325333 72.054756,79.022621 
	C68.375114,81.940071 64.814323,92.859993 68.326180,95.904243 
	C71.481636,98.639565 77.983955,97.838356 83.018387,97.899422 
	C84.699196,97.919815 86.406151,95.784393 88.101158,94.634453 
	C87.805000,94.131607 87.508842,93.628761 87.212677,93.125916 
	C83.566055,93.125916 79.909294,93.276711 76.279060,93.032784 
	C75.208672,92.960861 74.213272,91.772896 74.024773,91.048538 
	C76.681915,90.790192 79.339058,90.531845 82.827202,90.201584 
z"
      />
      <path
        fill="#67C4C5"
        opacity="1.000000"
        stroke="none"
        d="
M63.844528,83.977020 
	C63.920559,89.235634 63.996593,94.494263 64.154633,100.469391 
	C64.810333,101.178299 65.384033,101.170692 65.957733,101.163086 
	C66.428497,101.171158 66.899261,101.179230 67.805511,101.361526 
	C68.240990,101.535751 68.628654,101.591774 68.628654,101.591774 
	C68.628654,101.591774 68.937027,101.320229 68.937027,101.320221 
	C72.655296,101.271507 76.373566,101.222794 80.615829,101.552170 
	C82.093369,101.710579 83.046921,101.490898 84.000473,101.271225 
	C84.443321,101.218971 84.886169,101.166718 85.662338,101.154572 
	C85.996819,102.157410 85.997986,103.120140 85.997993,104.443512 
	C85.244484,104.907234 84.492126,105.010323 83.369705,105.203499 
	C80.930145,105.235764 78.860641,105.177948 75.914124,105.117775 
	C72.008255,105.008652 68.979416,104.901878 65.767410,104.505638 
	C63.078300,104.411499 60.572353,104.606812 57.788258,104.900223 
	C57.330257,105.077782 57.150406,105.157249 56.970551,105.236725 
	C54.583553,105.193428 52.196552,105.150131 49.130180,104.975281 
	C47.625107,104.767517 46.799400,104.691307 45.979134,104.205231 
	C45.992737,102.975601 46.000904,102.155830 46.464432,101.258644 
	C50.610924,101.229607 54.302063,101.278000 58.019501,101.536835 
	C58.200813,101.958839 58.355820,102.170403 58.510826,102.381966 
	C58.676079,102.025436 58.841328,101.668907 59.388092,101.182068 
	C60.489197,99.582947 61.720383,98.151352 61.830471,96.638191 
	C62.135651,92.443367 61.973846,88.214554 62.304741,83.996429 
	C63.023441,83.988129 63.433987,83.982574 63.844528,83.977020 
z"
      />
      <path
        fill="#5CC3BB"
        opacity="1.000000"
        stroke="none"
        d="
M78.296936,23.588564 
	C73.870277,23.075436 69.646645,22.334494 65.208168,20.918148 
	C65.064514,19.506624 65.135712,18.770506 65.206909,18.034388 
	C67.593567,18.039932 69.980209,18.045477 72.718552,18.008701 
	C75.445107,18.657269 77.819962,19.348158 80.591454,20.085905 
	C82.173851,22.762028 80.376511,23.091917 78.296936,23.588564 
z"
      />
      <path
        fill="#70CACC"
        opacity="1.000000"
        stroke="none"
        d="
M77.131851,74.248184 
	C75.980408,74.687408 74.897141,74.835297 73.406464,74.734322 
	C72.999069,74.485458 73.000305,74.007996 73.001755,73.653664 
	C72.988327,72.592346 72.973450,71.885361 72.958572,71.178375 
	C75.017952,71.115974 77.077332,71.053574 79.575470,71.053810 
	C79.076157,72.063240 78.138092,73.010040 77.131851,74.248184 
z"
      />
      <path
        fill="#70CACC"
        opacity="1.000000"
        stroke="none"
        d="
M108.792480,71.632996 
	C108.586815,71.271729 108.642105,71.123154 108.690178,71.061905 
	C109.968407,71.131111 111.198555,71.261566 112.428711,71.392021 
	C112.392601,71.811714 112.356491,72.231407 112.320381,72.651100 
	C111.212967,72.432152 110.105553,72.213203 108.792480,71.632996 
z"
      />
      <path
        fill="#D06294"
        opacity="1.000000"
        stroke="none"
        d="
M154.631302,32.455574 
	C162.173218,38.756927 159.211121,47.098446 158.398926,54.668480 
	C157.850906,59.776073 155.389542,64.678375 153.259705,69.755203 
	C152.331161,69.152596 151.926987,68.467308 151.629623,67.963120 
	C150.646683,68.280876 149.748810,68.571129 148.902100,68.500237 
	C150.955566,60.318802 153.310028,52.562325 154.745697,44.639362 
	C155.176544,42.261681 153.046005,39.419876 152.043915,36.488449 
	C151.643692,35.456207 151.288452,34.726219 150.967926,33.765789 
	C151.002640,33.535347 150.949829,33.072269 150.949829,33.072266 
	C151.392563,32.413368 151.835312,31.754473 152.776733,31.270050 
	C153.727386,31.781540 154.179352,32.118557 154.631302,32.455574 
z"
      />
      <path
        fill="#F4ECFB"
        opacity="1.000000"
        stroke="none"
        d="
M150.638397,32.945915 
	C150.949829,33.072269 151.002640,33.535347 150.673553,33.914822 
	C141.287186,42.678562 137.306183,53.606197 135.108490,65.319878 
	C134.721497,67.382523 135.642487,69.690575 136.022003,72.252792 
	C136.701721,74.473778 137.318893,76.327263 137.575790,78.150726 
	C135.893143,76.967163 134.570770,75.813629 133.248383,74.660095 
	C131.490738,58.620029 137.577133,45.247169 147.475189,33.227501 
	C147.926056,32.679996 149.354080,32.937176 150.638397,32.945915 
z"
      />
      <path
        fill="#D07AA7"
        opacity="1.000000"
        stroke="none"
        d="
M133.153305,75.046234 
	C134.570770,75.813629 135.893143,76.967163 137.751038,78.417648 
	C139.106567,78.671059 139.926559,78.627533 140.806534,78.987686 
	C141.921051,80.282814 142.975586,81.174263 143.797211,82.315102 
	C142.026367,83.118942 140.488464,83.673401 138.950546,84.227859 
	C138.578812,84.116425 138.207077,84.004990 137.547821,83.865753 
	C135.945175,82.871323 134.630051,81.904709 133.067429,80.797897 
	C132.819946,80.657715 132.560211,80.299034 132.348846,79.872498 
	C132.054291,79.078613 131.971069,78.711266 131.887848,78.343925 
	C132.277969,77.373405 132.668091,76.402885 133.153305,75.046234 
z"
      />
      <path
        fill="#FCE6F5"
        opacity="1.000000"
        stroke="none"
        d="
M130.926727,77.664810 
	C130.320999,76.558556 129.665466,75.104736 129.045044,73.242447 
	C129.679062,74.328392 130.278000,75.822815 130.926727,77.664810 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M141.267761,32.811852 
	C141.489319,32.000515 141.991592,31.055746 142.857117,30.102867 
	C142.663071,30.955975 142.105759,31.817192 141.267761,32.811852 
z"
      />
      <path
        fill="#F9F3FB"
        opacity="1.000000"
        stroke="none"
        d="
M151.437790,24.156322 
	C151.914093,23.846441 152.753433,23.507641 153.760712,23.412199 
	C153.219376,23.812838 152.510101,23.970121 151.437790,24.156322 
z"
      />
      <path
        fill="#AED4DC"
        opacity="1.000000"
        stroke="none"
        d="
M154.757080,32.061382 
	C154.179352,32.118557 153.727386,31.781540 153.097092,31.221519 
	C153.528687,30.819674 154.138596,30.640833 154.897034,30.658901 
	C154.991333,31.126270 154.937103,31.396732 154.757080,32.061382 
z"
      />
      <path
        fill="#FCE6F5"
        opacity="1.000000"
        stroke="none"
        d="
M128.822510,69.658310 
	C128.606216,69.466599 128.429123,68.951714 128.396210,68.262192 
	C128.647507,68.503410 128.754593,68.919266 128.822510,69.658310 
z"
      />
      <path
        fill="#FCE6F5"
        opacity="1.000000"
        stroke="none"
        d="
M131.668625,78.246506 
	C131.971069,78.711266 132.054291,79.078613 132.181793,79.727173 
	C131.831863,79.556938 131.437653,79.105476 131.014771,78.339172 
	C130.986130,78.024330 131.449402,78.149086 131.668625,78.246506 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M138.220703,36.923935 
	C138.059753,36.713284 138.197815,36.459522 138.609711,36.138565 
	C138.817169,36.357533 138.695877,36.627354 138.220703,36.923935 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M137.299377,38.062630 
	C137.225494,37.882103 137.382156,37.569065 137.776794,37.132156 
	C137.853134,37.315559 137.691513,37.622841 137.299377,38.062630 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M140.241211,33.942974 
	C140.071091,33.709415 140.233826,33.432713 140.724945,33.110519 
	C140.916077,33.354637 140.756332,33.632908 140.241211,33.942974 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M136.230560,39.966347 
	C136.045776,39.767654 136.156860,39.507641 136.536087,39.162548 
	C136.752625,39.358364 136.660019,39.634220 136.230560,39.966347 
z"
      />
      <path
        fill="#D65D95"
        opacity="1.000000"
        stroke="none"
        d="
M228.177872,27.452412 
	C226.407120,27.618288 224.636353,27.784163 222.193298,27.676746 
	C220.224640,27.669964 218.928268,27.936474 217.313782,28.216480 
	C216.365158,28.288847 215.734650,28.347717 215.104156,28.406586 
	C214.743713,27.339897 214.383270,26.273209 214.006073,24.872948 
	C214.765366,24.406343 215.541412,24.273314 216.981140,24.426931 
	C219.426956,24.480719 221.209091,24.247856 222.991241,24.014996 
	C222.991241,24.014996 223.007935,23.988239 223.406921,24.044060 
	C225.741394,24.399786 227.717285,24.556078 229.589111,25.082434 
	C230.192749,25.252169 230.522598,26.395483 230.977692,27.093428 
	C230.608704,27.143023 230.239731,27.192616 229.387650,27.173790 
	C228.623550,27.140068 228.381317,27.255747 228.177872,27.452412 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M244.998077,25.996399 
	C245.065948,25.163820 245.133804,24.331244 245.115356,23.245953 
	C245.490036,23.155190 245.951019,23.317139 246.900772,23.939350 
	C249.081177,24.553490 250.772812,24.707371 252.464432,24.861252 
	C253.506424,25.080311 254.548416,25.299372 255.761993,26.076643 
	C255.279556,27.443386 254.625519,28.251919 253.728455,29.043133 
	C253.485413,29.025816 253.005447,28.941612 252.967926,28.651604 
	C252.278137,28.261124 251.625870,28.160652 250.644745,28.045052 
	C249.878876,27.990696 249.441879,27.951468 248.929962,27.564121 
	C247.569382,26.809467 246.283737,26.402935 244.998077,25.996399 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M231.314651,27.147236 
	C230.522598,26.395483 230.192749,25.252169 229.589111,25.082434 
	C227.717285,24.556078 225.741394,24.399786 223.419769,24.053211 
	C226.066864,23.762609 229.100082,23.518675 232.569946,23.354694 
	C233.857910,24.437542 234.709244,25.440434 235.759598,26.693256 
	C235.210663,27.035765 234.462692,27.128342 233.352875,27.330750 
	C232.544540,27.360737 232.098068,27.280891 231.314651,27.147236 
z"
      />
      <path
        fill="#CB6697"
        opacity="1.000000"
        stroke="none"
        d="
M252.210083,24.624554 
	C250.772812,24.707371 249.081177,24.553490 247.225769,24.109055 
	C249.022049,21.181955 250.407135,23.487665 252.210083,24.624554 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M241.775421,19.544930 
	C242.673798,21.376249 243.334991,23.358110 239.605011,23.040428 
	C240.024292,21.939213 240.779953,20.816595 241.775421,19.544930 
z"
      />
      <path
        fill="#CB6697"
        opacity="1.000000"
        stroke="none"
        d="
M253.971481,29.060448 
	C254.625519,28.251919 255.279556,27.443386 255.979141,26.317341 
	C256.024689,25.999830 256.504547,25.989033 256.744080,26.002911 
	C256.253296,27.307743 255.522964,28.598696 254.392151,29.654522 
	C253.991684,29.419395 253.971481,29.060448 253.971481,29.060448 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M222.570496,23.960129 
	C221.209091,24.247856 219.426956,24.480719 217.373718,24.493607 
	C218.785004,24.150843 220.467377,24.028051 222.570496,23.960129 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M245.059479,22.647160 
	C244.874557,22.408712 244.730637,21.846367 244.823242,21.152605 
	C245.073349,21.455214 245.086914,21.889236 245.059479,22.647160 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M245.231506,20.967546 
	C245.040970,20.875433 245.035614,20.685308 245.043701,20.209972 
	C245.407486,20.116573 245.757812,20.308382 246.108124,20.500189 
	C245.877640,20.623301 245.647171,20.746418 245.231506,20.967546 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M244.967529,19.634750 
	C244.494003,19.798714 243.921783,19.647459 243.175400,19.248068 
	C243.623764,19.106466 244.246323,19.212999 244.967529,19.634750 
z"
      />
      <path
        fill="#83B4B9"
        opacity="1.000000"
        stroke="none"
        d="
M253.728455,29.043133 
	C253.971481,29.060448 253.991684,29.419395 254.002136,29.598459 
	C252.104813,30.250654 250.197021,30.723783 247.933594,31.315454 
	C244.251678,37.907043 240.925385,44.380093 237.775970,51.373734 
	C236.309540,53.272060 234.666275,54.649803 233.046478,55.650188 
	C237.356064,46.209034 241.642166,37.145237 246.312881,28.054779 
	C247.466614,27.989494 248.235764,27.950865 249.004898,27.912237 
	C249.441879,27.951468 249.878876,27.990696 250.809387,28.295197 
	C251.870422,28.687517 252.437943,28.814564 253.005447,28.941612 
	C253.005447,28.941612 253.485413,29.025816 253.728455,29.043133 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M233.023010,56.027542 
	C234.666275,54.649803 236.309540,53.272060 238.016144,51.582718 
	C240.137924,50.478153 242.196350,49.685196 244.627075,48.926056 
	C241.092178,51.861309 237.184952,54.762745 232.996353,57.302319 
	C232.848557,56.646584 232.951233,56.342281 233.023010,56.027542 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M261.933228,54.629509 
	C261.361908,54.882427 260.972565,54.908165 260.290833,54.952995 
	C260.015747,52.399899 260.912323,50.883446 263.999451,52.095764 
	C263.488525,53.070965 262.801849,53.736649 261.933228,54.629509 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M247.860809,49.725914 
	C247.455078,49.820801 246.895203,49.651302 246.170197,49.231979 
	C246.572266,49.141945 247.139465,49.301739 247.860809,49.725914 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M259.766510,55.076698 
	C259.903870,55.315582 259.737274,55.556854 259.242065,55.753700 
	C259.133270,55.512180 259.316284,55.302116 259.766510,55.076698 
z"
      />
      <path
        fill="#DC5A94"
        opacity="1.000000"
        stroke="none"
        d="
M144.030136,82.065720 
	C142.975586,81.174263 141.921051,80.282814 140.951248,78.823410 
	C141.035995,78.255447 141.384506,77.988853 141.504135,77.933479 
	C141.773849,77.778114 141.884827,77.645386 142.198395,77.225769 
	C144.209793,74.956108 145.979507,72.940620 148.048904,70.799866 
	C148.535919,70.079758 148.723267,69.484909 148.891174,68.893661 
	C148.871750,68.897255 148.850937,68.861374 148.850937,68.861374 
	C149.748810,68.571129 150.646683,68.280876 151.629623,67.963120 
	C151.926987,68.467308 152.331161,69.152596 153.008499,70.005562 
	C152.790771,71.434105 152.299850,72.694977 151.797424,74.347595 
	C151.785904,74.739357 151.834274,74.827538 151.579895,74.915924 
	C151.145996,75.309677 150.966461,75.615044 150.551086,76.072510 
	C149.798264,76.991913 149.281311,77.759224 148.477386,78.691299 
	C146.803665,79.925949 145.416901,80.995834 144.030136,82.065720 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M148.764343,78.526535 
	C149.281311,77.759224 149.798264,76.991913 150.818466,75.994888 
	C151.492569,75.452629 151.663422,75.140083 151.834274,74.827538 
	C151.834274,74.827538 151.785904,74.739357 151.791229,74.688133 
	C152.106339,74.914810 152.416122,75.192711 152.888367,75.736145 
	C152.733093,77.098854 152.415359,78.196037 152.064682,79.649872 
	C151.315674,80.113594 150.599594,80.220665 149.430054,79.907959 
	C148.905823,79.167618 148.835083,78.847076 148.764343,78.526535 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M160.987396,50.545631 
	C160.589035,49.959030 160.492203,49.375546 160.395370,48.792061 
	C160.805878,48.796535 161.216370,48.801010 161.626877,48.805485 
	C161.514236,49.386574 161.401581,49.967663 160.987396,50.545631 
z"
      />
      <path
        fill="#F0E6F7"
        opacity="1.000000"
        stroke="none"
        d="
M314.748230,27.260883 
	C315.125763,27.854971 315.243317,28.677359 315.588715,29.712080 
	C315.896454,30.712036 315.976349,31.499659 316.206696,32.656097 
	C315.301453,34.060402 313.789276,34.907146 313.269501,36.164230 
	C311.083557,41.450912 309.194214,46.860229 307.130127,52.581306 
	C306.385406,52.816624 305.706848,52.695293 304.952454,52.037846 
	C304.876648,51.501728 304.852539,51.018917 304.852539,51.018917 
	C304.852539,51.018917 305.023560,50.576054 305.274353,50.149796 
	C305.666290,49.153347 305.807434,48.583160 305.948578,48.012978 
	C305.948578,48.012974 306.102234,47.634277 306.324890,47.213444 
	C306.670807,46.198875 306.794067,45.605141 306.917328,45.011406 
	C306.917328,45.011406 307.084015,44.632423 307.310730,44.209854 
	C307.666565,43.197914 307.795685,42.608547 307.924805,42.019180 
	C307.924805,42.019176 308.095490,41.677567 308.129578,41.490524 
	C310.271881,36.698715 312.380035,32.093948 314.748230,27.260883 
z"
      />
      <path
        fill="#FBEBF7"
        opacity="1.000000"
        stroke="none"
        d="
M132.000854,43.999348 
	C131.880539,43.858318 131.759338,43.717937 131.638153,43.577557 
	C131.722565,43.627151 131.806961,43.676746 131.945007,43.864006 
	C131.998657,44.001678 132.000000,44.000000 132.000854,43.999348 
z"
      />
      <path
        fill="#70CACC"
        opacity="1.000000"
        stroke="none"
        d="
M16.707520,60.079376 
	C17.099178,63.401051 17.099178,66.806252 17.099178,70.550476 
	C19.673143,70.825638 21.892149,71.062851 24.510860,71.834946 
	C24.920958,73.053856 24.931349,73.737892 24.964680,74.554932 
	C24.987616,74.687927 24.980124,74.957756 24.980124,74.957764 
	C22.038353,74.911797 19.044888,75.174431 16.175915,74.693871 
	C14.668854,74.441422 13.379707,72.888039 12.068295,71.535461 
	C12.624742,70.974625 13.105052,70.800461 13.721460,70.407288 
	C14.676991,66.846489 15.496427,63.504696 16.707520,60.079376 
z"
      />
      <path
        fill="#D65D95"
        opacity="1.000000"
        stroke="none"
        d="
M213.010986,47.000381 
	C212.751709,46.701374 212.492447,46.402370 211.957184,45.980179 
	C211.996368,44.759613 212.311569,43.662243 213.135498,42.114532 
	C214.019943,40.385689 214.395660,39.107174 214.771362,37.828659 
	C214.771362,37.828659 214.693619,37.850021 214.901230,37.817841 
	C215.214813,37.691353 215.320786,37.597042 215.426758,37.502739 
	C215.222717,37.325264 215.018677,37.147785 214.814636,36.970306 
	C215.231552,35.576073 215.648453,34.181843 216.077271,32.132099 
	C216.134415,31.093906 216.179642,30.711229 216.480103,30.258930 
	C217.073410,30.092340 217.411499,29.995371 218.107071,29.962311 
	C219.398010,30.726583 220.331467,31.426947 221.209091,32.405231 
	C220.887527,33.154503 220.621796,33.625851 220.015472,34.242458 
	C219.149765,36.292603 218.624619,38.197495 218.077118,40.060589 
	C218.054764,40.018795 218.145538,40.046139 217.840698,40.239693 
	C217.405350,41.129116 217.274857,41.824986 217.212555,42.632210 
	C217.280731,42.743557 217.157562,42.973820 216.971405,43.040215 
	C216.692596,43.195950 216.505371,43.330185 216.522934,43.367233 
	C216.625366,43.583069 216.775818,43.776108 216.915649,44.263725 
	C216.683746,45.055550 216.446243,45.560497 215.882858,46.151829 
	C214.708328,46.492268 213.859650,46.746326 213.010986,47.000381 
z"
      />
      <path
        fill="#EDD4EF"
        opacity="1.000000"
        stroke="none"
        d="
M217.749573,29.898401 
	C217.411499,29.995371 217.073410,30.092340 216.032303,30.181370 
	C210.602386,30.567801 205.875504,30.962173 201.841263,31.298756 
	C202.165283,31.860773 202.566757,32.557110 202.968216,33.253448 
	C200.602097,33.956032 197.744507,35.745220 197.883942,30.643587 
	C203.402634,29.833824 208.904144,29.326567 214.754913,28.612947 
	C215.734650,28.347717 216.365158,28.288847 217.375000,28.494900 
	C217.752762,29.139347 217.751160,29.518873 217.749573,29.898401 
z"
      />
      <path
        fill="#89BEC3"
        opacity="1.000000"
        stroke="none"
        d="
M203.321228,33.225639 
	C202.566757,32.557110 202.165283,31.860773 201.841263,31.298756 
	C205.875504,30.962173 210.602386,30.567801 215.777069,30.250990 
	C216.179642,30.711229 216.134415,31.093906 216.056717,31.765293 
	C212.942047,32.401333 209.859863,32.748665 206.134186,33.125641 
	C205.490723,33.155281 205.086212,33.270607 205.086212,33.270607 
	C204.615555,33.246353 204.144882,33.222095 203.321228,33.225639 
z"
      />
      <path
        fill="#D36194"
        opacity="1.000000"
        stroke="none"
        d="
M214.487091,38.004971 
	C214.395660,39.107174 214.019943,40.385689 213.333893,41.829525 
	C213.416641,40.723660 213.809723,39.452469 214.487091,38.004971 
z"
      />
      <path
        fill="#EDD4EF"
        opacity="1.000000"
        stroke="none"
        d="
M194.180984,30.022596 
	C194.548630,29.878109 194.971451,29.988319 195.678131,30.264889 
	C195.386703,30.379929 194.811432,30.328613 194.180984,30.022596 
z"
      />
      <path
        fill="#EDD4EF"
        opacity="1.000000"
        stroke="none"
        d="
M205.170807,33.381367 
	C205.086212,33.270607 205.490723,33.155281 205.696930,33.188229 
	C205.903152,33.221176 205.637497,33.524334 205.637497,33.524334 
	C205.637497,33.524334 205.255402,33.492126 205.170807,33.381367 
z"
      />
      <path
        fill="#D36194"
        opacity="1.000000"
        stroke="none"
        d="
M214.724304,37.161293 
	C215.018677,37.147785 215.222717,37.325264 215.426758,37.502739 
	C215.320786,37.597042 215.214813,37.691353 214.888641,37.779549 
	C214.668457,37.773430 214.633957,37.352283 214.724304,37.161293 
z"
      />
      <path
        fill="#F0E6F7"
        opacity="1.000000"
        stroke="none"
        d="
M220.356064,34.097198 
	C220.621796,33.625851 220.887527,33.154503 221.450958,32.273552 
	C222.129059,31.242334 222.509476,30.620716 222.889877,29.999098 
	C223.388092,30.005350 223.886307,30.011600 224.758728,30.038696 
	C218.382996,45.616585 213.665970,61.738754 211.392365,78.934868 
	C210.828918,78.500565 210.510071,77.739563 210.030899,76.367401 
	C210.229965,72.835915 210.589371,69.915588 210.948776,66.995255 
	C212.212830,61.896275 213.476883,56.797295 214.878876,50.973236 
	C215.414124,48.853916 215.811432,47.459682 216.208740,46.065445 
	C216.446243,45.560497 216.683746,45.055550 217.015717,44.022438 
	C217.125961,43.320786 217.141769,43.147301 217.157562,42.973820 
	C217.157562,42.973820 217.280731,42.743557 217.460068,42.385506 
	C217.808121,41.367020 217.976822,40.706577 218.145538,40.046135 
	C218.145538,40.046139 218.054764,40.018795 218.357971,39.838470 
	C219.226151,37.804497 219.791107,35.950848 220.356064,34.097198 
z"
      />
      <path
        fill="#E15893"
        opacity="1.000000"
        stroke="none"
        d="
M210.705948,66.799545 
	C210.589371,69.915588 210.229965,72.835915 209.719543,76.090797 
	C209.568542,76.425346 209.592117,76.597923 209.350220,76.727722 
	C208.739182,77.029114 208.370010,77.200699 208.000854,77.372284 
	C207.836212,77.293083 207.671570,77.213875 207.237885,77.082169 
	C206.653168,75.277969 206.337494,73.526253 206.079422,71.383820 
	C207.155258,68.899025 208.173508,66.804955 209.305954,64.476044 
	C209.737564,65.269707 210.100342,65.936768 210.705948,66.799545 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M215.882874,46.151833 
	C215.811432,47.459682 215.414124,48.853916 214.878571,50.585213 
	C214.177902,49.826603 213.615494,48.730934 213.032043,47.317822 
	C213.859650,46.746326 214.708328,46.492268 215.882874,46.151833 
z"
      />
      <path
        fill="#DEF8F9"
        opacity="1.000000"
        stroke="none"
        d="
M65.155418,17.685165 
	C65.135712,18.770506 65.064514,19.506624 64.952095,20.610817 
	C63.794346,20.968292 62.677814,20.957691 61.274319,20.967388 
	C60.298073,19.100237 59.457317,17.249689 58.984699,15.309467 
	C58.755329,14.367839 59.215366,13.258278 59.672340,12.052738 
	C60.469975,11.912773 60.961784,11.919556 61.446552,11.981488 
	C63.039284,12.184972 64.629463,12.408462 66.220627,12.624264 
	C65.848389,14.194822 65.476158,15.765383 65.155418,17.685165 
z"
      />
      <path
        fill="#F2FBFC"
        opacity="1.000000"
        stroke="none"
        d="
M18.365147,83.898125 
	C18.039440,83.030006 18.031612,82.320564 18.316509,81.312355 
	C19.073645,81.345261 19.538055,81.676926 20.123905,82.221039 
	C20.808420,82.429024 21.371500,82.424545 22.232555,82.392235 
	C22.925959,82.318985 23.321386,82.273575 23.946442,82.292313 
	C24.176069,82.356468 24.645798,82.274452 24.961655,82.476097 
	C26.496885,82.651421 27.716261,82.625092 29.331444,82.661484 
	C30.786875,82.659203 31.846497,82.594215 33.281303,82.645889 
	C34.437725,82.850807 35.218960,82.939072 35.999878,83.379166 
	C35.998352,84.435051 35.997147,85.139099 35.605099,85.905037 
	C34.165028,86.042999 33.115807,86.119072 31.715412,86.143204 
	C27.982571,85.733124 28.501053,87.902641 28.853842,89.964783 
	C25.463570,87.995461 22.073298,86.026131 18.365147,83.898125 
z"
      />
      <path
        fill="#4FC2CA"
        opacity="1.000000"
        stroke="none"
        d="
M29.089661,89.904984 
	C28.501053,87.902641 27.982571,85.733124 32.031441,86.397171 
	C37.001633,86.803444 41.326359,86.694206 45.593849,87.136833 
	C46.812702,87.263252 47.865570,88.989990 48.554314,90.035812 
	C45.746967,90.162498 43.379856,90.236267 40.568802,90.204834 
	C37.082214,90.008705 34.039574,89.917770 30.996929,89.826836 
	C30.439779,89.832954 29.882631,89.839066 29.089661,89.904984 
z"
      />
      <path
        fill="#71C1BF"
        opacity="1.000000"
        stroke="none"
        d="
M31.016594,96.840538 
	C30.588522,96.884926 30.160452,96.929314 29.368616,96.611481 
	C28.936640,95.536987 28.868431,94.824722 28.800220,94.112450 
	C29.511450,93.297562 30.222683,92.482674 31.244217,91.461411 
	C32.709873,91.169693 33.865227,91.084358 35.026955,91.334534 
	C35.023205,92.117279 35.013092,92.564514 34.767136,93.091156 
	C34.232727,93.365021 34.062088,93.639130 33.655113,94.004105 
	C32.532757,94.957069 31.774675,95.898804 31.016594,96.840538 
z"
      />
      <path
        fill="#E1FAF9"
        opacity="1.000000"
        stroke="none"
        d="
M28.403008,94.104187 
	C28.868431,94.824722 28.936640,95.536987 29.045254,96.605240 
	C27.491222,96.174721 25.896786,95.388237 24.159986,94.283791 
	C25.347013,94.009193 26.676403,94.052559 28.403008,94.104187 
z"
      />
      <path
        fill="#B2EEEB"
        opacity="1.000000"
        stroke="none"
        d="
M35.020584,90.999023 
	C33.865227,91.084358 32.709873,91.169693 31.279259,91.122238 
	C30.997261,90.796234 30.990524,90.603027 30.990356,90.118332 
	C34.039574,89.917770 37.082214,90.008705 40.371025,90.389091 
	C40.088924,90.802040 39.560658,90.925537 38.770844,91.039780 
	C38.335075,91.030510 38.160851,91.030502 37.615738,91.015511 
	C36.503429,91.000015 35.762009,90.999519 35.020584,90.999023 
z"
      />
      <path
        fill="#E1FAF9"
        opacity="1.000000"
        stroke="none"
        d="
M23.937531,93.746040 
	C23.757080,93.799850 23.483257,93.619598 23.113256,93.207520 
	C23.292770,93.154457 23.568464,93.333214 23.937531,93.746040 
z"
      />
      <path
        fill="#D65D95"
        opacity="1.000000"
        stroke="none"
        d="
M206.205566,26.604263 
	C207.779068,26.378054 209.097458,26.378054 210.415848,26.378054 
	C210.350311,26.921181 210.284790,27.464308 210.219269,28.007435 
	C208.796326,27.615116 207.373383,27.222795 206.205566,26.604263 
z"
      />
      <path
        fill="#DEF8F9"
        opacity="1.000000"
        stroke="none"
        d="
M59.572311,21.204117 
	C57.798294,21.827196 55.645245,22.388918 53.058151,22.905167 
	C54.813828,22.287380 57.003555,21.715069 59.572311,21.204117 
z"
      />
      <path
        fill="#71CACD"
        opacity="1.000000"
        stroke="none"
        d="
M23.716816,82.228165 
	C23.321386,82.273575 22.925959,82.318985 21.927135,82.259506 
	C20.883316,82.105949 20.442892,82.057266 20.002468,82.008591 
	C19.538055,81.676926 19.073645,81.345261 18.323051,80.956589 
	C17.461651,79.949448 16.886435,78.999313 15.752263,77.125893 
	C19.028351,77.125893 21.571295,77.125893 25.020134,77.122589 
	C33.867901,77.150833 41.809769,77.182373 49.840858,77.483490 
	C50.015594,78.123352 50.101116,78.493637 49.815125,78.941269 
	C48.972797,80.321548 48.501980,81.624496 47.568619,82.929611 
	C43.404110,82.963646 39.702152,82.995499 36.000195,83.027344 
	C35.218960,82.939072 34.437725,82.850807 33.304798,82.293030 
	C32.181423,80.968803 31.172140,79.265160 30.678358,79.403366 
	C28.282019,80.074051 26.023899,81.238579 23.716816,82.228165 
z"
      />
      <path
        fill="#ECFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M49.751640,77.213913 
	C41.809769,77.182373 33.867901,77.150833 25.457226,77.086243 
	C24.988377,76.587547 24.988335,76.121910 24.984207,75.307007 
	C24.980124,74.957756 24.987616,74.687927 25.354263,74.668808 
	C26.782806,74.564529 27.844700,74.479370 29.311285,74.551666 
	C30.850742,74.600334 31.985508,74.491570 33.564182,74.497818 
	C39.051067,74.521271 44.094044,74.429695 49.332100,74.472687 
	C49.527184,74.607246 49.998390,74.658501 50.366982,74.783211 
	C51.509274,74.990044 52.282970,75.072159 53.200283,75.349243 
	C53.343895,75.544212 53.781456,75.758461 53.781456,75.758461 
	C53.781456,75.758461 54.264080,75.690468 54.308266,75.706177 
	C54.352451,75.721893 54.435753,75.765007 54.129807,76.066650 
	C52.466450,76.650162 51.109047,76.932037 49.751640,77.213913 
z"
      />
      <path
        fill="#F1E5F4"
        opacity="1.000000"
        stroke="none"
        d="
M148.477386,78.691299 
	C148.835083,78.847076 148.905823,79.167618 149.074463,79.974350 
	C146.047638,81.978256 142.922913,83.495979 139.393982,85.006882 
	C138.989777,85.000061 138.958603,84.613037 138.954575,84.420441 
	C140.488464,83.673401 142.026367,83.118942 143.797211,82.315094 
	C145.416901,80.995834 146.803665,79.925949 148.477386,78.691299 
z"
      />
      <path
        fill="#61BBBC"
        opacity="1.000000"
        stroke="none"
        d="
M31.339191,96.963318 
	C31.774675,95.898804 32.532757,94.957069 33.912155,94.004990 
	C34.702976,93.968864 34.872486,93.943085 35.066528,94.115631 
	C35.091057,94.313950 35.138741,94.710754 35.109749,95.048553 
	C35.402267,95.933319 35.723782,96.480286 36.158764,97.309326 
	C37.226601,98.627289 38.180973,99.663185 38.999954,101.015945 
	C38.184284,101.925674 37.503998,102.518539 36.823715,103.111412 
	C36.823715,103.111412 36.415855,103.058754 36.092766,102.750786 
	C34.915279,101.603638 34.060886,100.764458 33.206497,99.925278 
	C32.691597,98.978882 32.176693,98.032494 31.339191,96.963318 
z"
      />
      <path
        fill="#E1FAF9"
        opacity="1.000000"
        stroke="none"
        d="
M32.952217,100.035988 
	C34.060886,100.764458 34.915279,101.603638 35.889347,102.720749 
	C35.259502,102.994370 34.509987,102.990051 33.386116,102.976585 
	C32.749813,102.482155 32.487858,101.996872 32.121620,101.255569 
	C32.244202,100.715263 32.471069,100.430984 32.952217,100.035988 
z"
      />
      <path
        fill="#66C1BF"
        opacity="1.000000"
        stroke="none"
        d="
M28.879051,35.196152 
	C28.944151,35.606728 28.734396,36.140739 28.266315,36.815895 
	C28.206724,36.411224 28.405460,35.865410 28.879051,35.196152 
z"
      />
      <path
        fill="#EFEEF9"
        opacity="1.000000"
        stroke="none"
        d="
M148.338440,104.013412 
	C148.017105,103.202560 148.026886,102.424980 148.073166,101.258102 
	C149.541428,101.020508 151.030045,101.505295 152.396118,101.272125 
	C165.385422,99.055038 178.353943,96.716110 192.031860,94.237823 
	C193.848541,93.757973 194.961777,93.452515 196.075027,93.147057 
	C196.817047,93.100494 197.559067,93.053932 199.066299,92.954033 
	C207.184906,91.835159 214.538315,90.769608 221.891708,89.704056 
	C222.721786,89.605453 223.551865,89.506844 225.133667,89.315804 
	C237.451294,88.762352 249.017181,88.301323 261.080994,87.895126 
	C265.383087,87.968521 269.187256,87.987068 272.990234,88.252747 
	C272.988983,88.499878 272.990814,88.994141 272.628784,89.033386 
	C271.120300,89.331787 269.973877,89.590935 268.410675,89.840897 
	C266.326324,89.902908 264.658783,89.974121 262.565369,90.001175 
	C260.430054,90.022224 258.720520,90.087425 256.550537,90.105774 
	C252.781479,90.149406 249.434525,89.948174 246.170639,90.377937 
	C225.731537,93.069168 205.310944,95.901146 184.609711,98.476143 
	C180.847519,99.527229 177.359863,100.788712 173.418655,102.058884 
	C167.183609,102.697243 161.402115,103.326927 155.620621,103.956612 
	C154.965347,103.973999 154.310089,103.991394 153.326797,104.007065 
	C151.555710,104.019112 150.112640,104.032890 148.338440,104.013412 
z"
      />
      <path
        fill="#D35A91"
        opacity="1.000000"
        stroke="none"
        d="
M260.583069,87.840294 
	C249.017181,88.301323 237.451294,88.762352 225.420288,89.169617 
	C229.769608,88.075745 234.551208,86.377335 239.405457,86.135216 
	C248.941284,85.659584 258.517517,85.993866 268.939026,85.994026 
	C270.866547,85.992813 271.932037,85.990623 272.998657,86.189697 
	C272.999786,86.390976 272.999420,86.793236 272.546997,86.864418 
	C268.257416,87.237167 264.420258,87.538727 260.583069,87.840294 
z"
      />
      <path
        fill="#D35A91"
        opacity="1.000000"
        stroke="none"
        d="
M221.580536,89.477219 
	C214.538315,90.769608 207.184906,91.835159 199.422943,92.845886 
	C201.460907,92.105888 203.868622,91.143776 206.362213,90.794098 
	C211.306198,90.100784 216.297562,89.745422 221.580536,89.477219 
z"
      />
      <path
        fill="#EA4A93"
        opacity="1.000000"
        stroke="none"
        d="
M174.671753,94.976822 
	C171.284042,94.585648 168.316681,94.174004 165.349335,93.762360 
	C165.392746,93.377548 165.436172,92.992737 165.479584,92.607925 
	C168.499603,92.412346 171.518906,92.114967 174.540131,92.094170 
	C175.277222,92.089096 176.020538,92.991478 176.761002,93.476624 
	C176.204697,93.969864 175.648392,94.463104 174.671753,94.976822 
z"
      />
      <path
        fill="#E04E92"
        opacity="1.000000"
        stroke="none"
        d="
M272.997559,85.988434 
	C271.932037,85.990623 270.866547,85.992813 269.401855,85.992172 
	C269.001373,84.925728 269.000031,83.862129 268.999359,82.399513 
	C269.750000,82.000229 270.500000,81.999947 271.733154,82.218239 
	C272.415405,82.709412 272.676208,82.895500 272.999512,83.329948 
	C273.002594,84.111305 273.004944,84.557785 273.005157,85.250290 
	C273.002991,85.496307 272.997559,85.988434 272.997559,85.988434 
z"
      />
      <path
        fill="#D35A91"
        opacity="1.000000"
        stroke="none"
        d="
M195.860779,92.859123 
	C194.961777,93.452515 193.848541,93.757973 192.377258,94.051117 
	C193.228317,93.549591 194.437424,93.060387 195.860779,92.859123 
z"
      />
      <path
        fill="#EFEEF9"
        opacity="1.000000"
        stroke="none"
        d="
M148.028534,100.673241 
	C147.821213,100.748192 147.525757,100.569115 147.130493,100.143822 
	C147.333939,100.071472 147.637177,100.245338 148.028534,100.673241 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M37.017143,103.396835 
	C37.503998,102.518539 38.184284,101.925674 39.420708,101.100235 
	C41.987591,101.023788 43.998329,101.179924 46.009071,101.336060 
	C46.000904,102.155830 45.992737,102.975601 45.635811,104.270081 
	C43.863918,104.887314 42.440784,105.029823 41.017651,105.172333 
	C39.748623,104.675644 38.479599,104.178955 37.017143,103.396835 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M56.978161,105.624130 
	C57.150406,105.157249 57.330257,105.077782 58.170635,104.962471 
	C59.866219,104.891228 60.901268,104.855820 61.936321,104.820412 
	C61.982445,105.141617 62.152493,105.508034 62.054504,105.776672 
	C61.438263,107.466141 61.000946,110.406944 60.063881,110.582520 
	C56.511642,111.248085 57.161049,108.173218 56.978161,105.624130 
z"
      />
      <path
        fill="#FEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M65.950569,104.795105 
	C68.979416,104.901878 72.008255,105.008652 75.478088,105.205795 
	C72.522568,108.068253 68.989388,107.565857 65.443039,105.356277 
	C65.485313,104.977394 65.950569,104.795105 65.950569,104.795105 
z"
      />
      <path
        fill="#EDFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M41.177299,105.450722 
	C42.440784,105.029823 43.863918,104.887314 45.630375,104.679955 
	C46.799400,104.691307 47.625107,104.767517 48.686558,105.045258 
	C46.419502,105.810936 44.137978,109.855202 41.177299,105.450722 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M65.767410,104.505646 
	C65.950569,104.795105 65.485313,104.977394 65.241188,105.030975 
	C64.112167,104.860359 63.227268,104.636162 62.139343,104.616196 
	C60.901268,104.855820 59.866219,104.891228 58.448788,104.864380 
	C60.572353,104.606812 63.078300,104.411499 65.767410,104.505646 
z"
      />
      <path
        fill="#79BCBE"
        opacity="1.000000"
        stroke="none"
        d="
M184.884232,98.686539 
	C205.310944,95.901146 225.731537,93.069168 246.170639,90.377937 
	C249.434525,89.948174 252.781479,90.149406 256.552368,90.466125 
	C257.046295,91.355156 257.077820,91.836990 257.090271,92.500984 
	C257.071228,92.683151 257.014404,93.045044 257.014404,93.045044 
	C255.299088,92.998123 253.583771,92.951195 250.987122,92.904144 
	C246.374405,93.164017 242.643051,93.424019 238.911682,93.684021 
	C237.872604,93.799873 236.833542,93.915718 235.051224,94.052177 
	C233.168121,94.307655 232.028275,94.542519 230.888412,94.777397 
	C229.839920,94.864288 228.791412,94.951180 227.017319,95.053467 
	C225.444672,95.341156 224.597626,95.613441 223.750580,95.885727 
	C222.753571,95.904854 221.756546,95.923973 220.392975,96.021210 
	C218.200195,96.186508 216.373947,96.273689 214.254272,96.604050 
	C212.478851,97.024681 210.996902,97.202126 208.799118,97.630447 
	C204.037323,98.557327 199.991348,99.233322 195.945374,99.909317 
	C195.507904,99.940865 195.070435,99.972420 193.918716,100.043686 
	C191.152481,100.477074 189.100510,100.870728 187.048523,101.264389 
	C186.878113,101.230797 186.707718,101.197205 185.946167,101.107452 
	C185.198059,100.263031 185.041138,99.474785 184.884232,98.686539 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M196.203888,100.209984 
	C199.991348,99.233322 204.037323,98.557327 208.511719,97.862198 
	C208.567596,98.499794 208.248001,99.686760 207.814240,99.730064 
	C204.041916,100.106667 200.249100,100.278168 196.203888,100.209984 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M187.280121,101.476059 
	C189.100510,100.870728 191.152481,100.477074 193.598633,100.120934 
	C192.889496,100.770744 191.786194,101.383034 190.341431,102.000076 
	C189.170578,101.899124 188.341156,101.793419 187.280121,101.476059 
z"
      />
      <path
        fill="#76B7BA"
        opacity="1.000000"
        stroke="none"
        d="
M334.966736,91.049324 
	C333.815735,91.634842 332.664642,92.709564 331.513702,92.709419 
	C328.652161,92.709061 325.790680,92.218719 322.380554,91.762512 
	C321.532532,91.670403 321.233185,91.740906 320.933807,91.811409 
	C315.920715,91.651680 310.907623,91.491943 305.447021,91.418839 
	C297.305756,91.601936 289.612030,91.698402 281.374451,91.656891 
	C280.531647,91.628731 280.232697,91.738533 279.933716,91.848335 
	C279.212799,91.861839 278.491852,91.875343 277.223999,91.680069 
	C276.161957,91.340874 275.646851,91.210457 274.823242,91.042862 
	C274.013031,90.687904 273.511414,90.370125 273.388062,90.033821 
	C275.190186,89.774712 276.614075,89.534126 278.401062,89.253571 
	C279.493195,89.232796 280.222260,89.251999 281.169312,89.464294 
	C282.274597,89.523888 283.161804,89.390388 284.512207,89.216095 
	C291.295319,89.178299 297.615234,89.181305 304.049866,89.324814 
	C304.463104,89.371704 304.761627,89.278099 305.505066,89.155426 
	C308.626160,89.178314 311.302368,89.230263 314.274231,89.496414 
	C316.058777,89.787048 317.547638,89.863457 319.456421,89.964157 
	C321.967743,89.675972 324.059082,89.363487 326.547485,89.037064 
	C329.618622,89.698524 332.292694,90.373924 334.966736,91.049324 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M257.183868,93.282242 
	C257.014404,93.045044 257.071228,92.683151 257.504425,92.429474 
	C259.623322,92.104675 261.309052,92.033546 263.447388,92.014236 
	C266.942169,92.101318 269.984375,92.136574 273.026550,92.171837 
	C268.658417,92.779648 264.290253,93.387451 259.461060,93.996262 
	C258.451111,93.837982 257.902222,93.678711 257.183868,93.282242 
z"
      />
      <path
        fill="#79BCBE"
        opacity="1.000000"
        stroke="none"
        d="
M273.341431,92.096893 
	C269.984375,92.136574 266.942169,92.101318 263.449280,91.694794 
	C262.996124,90.897461 262.993683,90.471397 262.991211,90.045334 
	C264.658783,89.974121 266.326324,89.902908 268.828735,89.898918 
	C270.778961,89.994873 271.894379,90.023613 273.009766,90.052353 
	C273.511414,90.370125 274.013031,90.687904 274.772675,91.249519 
	C275.030670,91.493355 274.946564,91.911621 274.946564,91.911621 
	C274.516479,91.948395 274.086426,91.985168 273.341431,92.096893 
z"
      />
      <path
        fill="#AED4DC"
        opacity="1.000000"
        stroke="none"
        d="
M275.296814,91.967743 
	C274.946564,91.911621 275.030670,91.493355 275.081238,91.286697 
	C275.646851,91.210457 276.161957,91.340874 276.863556,91.667526 
	C276.582397,91.917130 276.114746,91.970497 275.296814,91.967743 
z"
      />
      <path
        fill="#AED4DC"
        opacity="1.000000"
        stroke="none"
        d="
M280.224060,91.891739 
	C280.232697,91.738533 280.531647,91.628731 280.955444,91.648544 
	C280.891632,91.830490 280.703033,91.882812 280.224060,91.891739 
z"
      />
      <path
        fill="#D0659B"
        opacity="1.000000"
        stroke="none"
        d="
M335.342346,91.003433 
	C332.292694,90.373924 329.618622,89.698524 326.287811,88.691864 
	C322.109558,88.230782 318.588074,88.100975 314.784393,87.667328 
	C311.339935,87.181557 308.177704,86.999611 305.010803,86.617981 
	C305.006134,86.418304 305.003662,86.018837 305.002411,86.011116 
	C305.001160,86.003395 304.996765,85.988556 305.465637,85.994751 
	C313.881165,86.331635 321.825409,86.753723 329.775238,86.962318 
	C335.501587,87.112579 341.235413,86.978378 347.162628,86.975616 
	C347.359375,86.980171 347.752930,86.987846 347.752930,86.987839 
	C345.742493,88.949135 343.732056,90.910439 341.357635,92.924149 
	C339.235046,92.303543 337.476501,91.630539 335.342346,91.003433 
z"
      />
      <path
        fill="#AED4DC"
        opacity="1.000000"
        stroke="none"
        d="
M321.206787,91.887482 
	C321.233185,91.740906 321.532532,91.670403 321.936707,91.744141 
	C321.854279,91.913445 321.667023,91.938499 321.206787,91.887482 
z"
      />
      <path
        fill="#80BBBE"
        opacity="1.000000"
        stroke="none"
        d="
M155.763977,104.340393 
	C161.402115,103.326927 167.183609,102.697243 173.441040,102.302513 
	C173.916962,102.537460 174.014435,103.017593 173.865189,103.261070 
	C173.321854,104.101501 172.927765,104.698456 172.533661,105.295410 
	C172.533676,105.295418 172.183777,105.262169 172.183777,105.262169 
	C172.183777,105.262169 171.857880,105.393944 171.650955,105.226776 
	C170.613449,104.980026 169.782867,104.900436 168.952286,104.820847 
	C168.205933,104.879791 167.459579,104.938736 166.027618,105.037598 
	C164.765732,105.330040 164.189438,105.582588 163.613159,105.835129 
	C161.044556,105.464813 158.475952,105.094498 155.763977,104.340393 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M174.014435,103.017593 
	C174.014435,103.017593 173.916962,102.537460 173.894592,102.293823 
	C177.359863,100.788712 180.847519,99.527229 184.609711,98.476143 
	C185.041138,99.474785 185.198059,100.263031 185.686630,101.130127 
	C185.238953,101.804779 184.459641,102.400574 183.340149,103.006294 
	C181.258163,103.343155 179.516312,103.670090 177.069458,103.976562 
	C175.581116,103.643272 174.797775,103.330429 174.014435,103.017593 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M173.865189,103.261070 
	C174.797775,103.330429 175.581116,103.643272 176.682220,103.979736 
	C175.645325,104.661484 174.290649,105.319603 172.734802,105.636566 
	C172.927765,104.698456 173.321854,104.101501 173.865189,103.261070 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M168.966995,105.116203 
	C169.782867,104.900436 170.613449,104.980026 171.526474,105.349945 
	C171.031189,105.747101 170.453461,105.853920 169.437561,105.981743 
	C168.993484,105.805687 168.987595,105.608620 168.966995,105.116203 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M163.944214,105.875771 
	C164.189438,105.582588 164.765732,105.330040 165.660706,105.112839 
	C165.411346,105.404259 164.843307,105.660332 163.944214,105.875771 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M239.016449,93.962082 
	C242.643051,93.424019 246.374405,93.164017 250.561249,92.963699 
	C251.016724,93.023392 251.010132,93.517822 251.006348,93.765182 
	C247.585327,94.233162 244.168121,94.453781 240.375458,94.839920 
	C239.707062,94.750343 239.414139,94.495247 239.016449,93.962082 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M231.091324,95.073639 
	C232.028275,94.542519 233.168121,94.307655 234.666534,94.129379 
	C233.781464,94.580612 232.537857,94.975258 231.091324,95.073639 
z"
      />
      <path
        fill="#EAFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M224.101410,95.929504 
	C224.597626,95.613441 225.444672,95.341156 226.630341,95.131020 
	C226.130051,95.453209 225.291138,95.713242 224.101410,95.929504 
z"
      />
      <path
        fill="#F0E3F6"
        opacity="1.000000"
        stroke="none"
        d="
M365.693542,89.259689 
	C369.086975,89.485153 372.107697,89.744133 375.574951,90.011681 
	C376.011780,90.752953 376.002106,91.485672 375.996033,92.584686 
	C369.043701,90.194336 361.801605,94.983818 354.428772,92.209633 
	C353.794006,91.526886 353.579102,90.783386 353.680878,89.957893 
	C357.772003,89.681671 361.546448,89.487442 365.693542,89.259689 
z"
      />
      <path
        fill="#F4EAF5"
        opacity="1.000000"
        stroke="none"
        d="
M335.879272,39.997665 
	C336.726257,42.737751 337.512543,45.499176 338.462860,48.202946 
	C338.701263,48.881241 339.466522,49.374363 340.325195,49.955326 
	C339.697235,51.712872 338.733765,53.467678 337.762939,55.828217 
	C337.755615,56.433952 337.718048,56.899426 337.718048,56.899426 
	C337.718048,56.899426 337.356232,56.865906 336.921631,56.858692 
	C336.321472,56.904026 336.155884,56.956577 335.624878,56.945053 
	C333.832733,56.579704 332.405975,56.278435 331.001221,55.597908 
	C331.663605,52.473743 332.303955,49.728836 332.999268,46.628395 
	C333.995911,44.181133 334.937592,42.089401 335.879272,39.997665 
M335.632538,49.349567 
	C335.145721,50.325901 334.658905,51.302231 334.172089,52.278564 
	C334.750977,52.426838 335.329895,52.575115 335.908783,52.723389 
	C336.037903,51.622185 336.166992,50.520977 335.632538,49.349567 
z"
      />
      <path
        fill="#E14E92"
        opacity="1.000000"
        stroke="none"
        d="
M357.298798,68.510422 
	C357.237335,68.852478 357.175903,69.194534 356.638062,69.767838 
	C355.441040,69.999603 354.720398,70.000107 353.997253,69.591629 
	C354.037567,67.821152 354.080383,66.459656 354.321655,64.943794 
	C354.651154,64.487732 354.782135,64.186043 354.955444,63.660133 
	C354.997711,63.435917 355.196503,63.025146 355.196533,63.025154 
	C356.726440,61.090069 358.256378,59.154976 358.991028,58.225784 
	C362.754944,56.794003 365.710724,55.669640 368.709625,55.068584 
	C367.807129,57.000370 366.861481,58.408848 365.915802,59.817322 
	C365.915802,59.817322 365.917816,59.918835 365.493652,59.937962 
	C361.725128,60.027943 359.846252,61.524780 360.083832,65.080963 
	C360.083832,65.080963 360.023438,65.003456 359.682983,65.048065 
	C358.661285,66.231918 357.980042,67.371170 357.298798,68.510422 
z"
      />
      <path
        fill="#89C1C3"
        opacity="1.000000"
        stroke="none"
        d="
M337.042328,57.857300 
	C338.152679,58.576519 339.263031,59.295742 340.520996,60.434109 
	C338.729248,62.205391 336.789948,63.557526 334.440308,64.930008 
	C331.822845,64.370743 329.615723,63.791138 327.779785,63.222893 
	C330.249786,63.202171 332.416107,63.253902 332.312073,59.978050 
	C333.735260,59.182331 334.863647,58.529087 336.255341,57.910530 
	C336.693176,57.915913 336.867737,57.886608 337.042328,57.857300 
z"
      />
      <path
        fill="#8AB7BE"
        opacity="1.000000"
        stroke="none"
        d="
M355.174561,62.658546 
	C355.196503,63.025146 354.997711,63.435917 354.806091,63.642609 
	C354.614471,63.849297 354.312042,63.839012 354.053406,63.625801 
	C351.530243,63.941563 349.265717,64.470535 346.694031,64.922928 
	C345.970306,64.779228 345.553772,64.712090 344.918945,64.464798 
	C344.147003,64.168182 343.593384,64.051720 343.032104,63.949829 
	C343.024445,63.964401 343.063080,63.949947 343.042114,63.604488 
	C343.018646,62.568981 343.016083,61.878937 343.013519,61.188892 
	C343.828308,60.854149 344.643127,60.519405 346.061981,60.480331 
	C349.774231,60.098133 352.882385,59.420261 355.990570,58.742386 
	C355.711273,59.925571 355.431946,61.108761 355.174561,62.658546 
z"
      />
      <path
        fill="#F3D7F0"
        opacity="1.000000"
        stroke="none"
        d="
M356.179199,58.489201 
	C352.882385,59.420261 349.774231,60.098133 346.394714,60.534332 
	C349.884583,58.448692 353.645813,56.604721 358.085785,54.427994 
	C357.431030,55.879375 356.899445,57.057697 356.179199,58.489201 
z"
      />
      <path
        fill="#EA4A93"
        opacity="1.000000"
        stroke="none"
        d="
M358.980499,45.991318 
	C365.658905,45.840378 362.256073,49.606236 361.630341,52.573593 
	C359.951691,51.868385 358.508392,51.262051 357.139862,50.687126 
	C357.740417,49.395744 358.371948,48.037746 358.992615,46.319935 
	C358.981781,45.960125 359.000916,46.000816 358.980499,45.991318 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M342.751190,61.060486 
	C343.016083,61.878937 343.018646,62.568981 342.625854,63.621361 
	C339.770538,64.292351 337.310577,64.601013 334.850616,64.909668 
	C336.789948,63.557526 338.729248,62.205391 340.828491,60.585564 
	C341.488556,60.522606 341.988708,60.727345 342.751190,61.060486 
z"
      />
      <path
        fill="#D4679D"
        opacity="1.000000"
        stroke="none"
        d="
M366.216919,59.709343 
	C366.861481,58.408848 367.807129,57.000370 368.911316,55.305286 
	C369.520416,55.026325 369.970978,55.033966 370.759186,55.077766 
	C369.570557,56.609741 368.044312,58.105553 366.216919,59.709343 
z"
      />
      <path
        fill="#E14E92"
        opacity="1.000000"
        stroke="none"
        d="
M371.884338,54.120758 
	C371.939056,54.321655 371.743042,54.624744 371.286255,55.010490 
	C371.228180,54.803082 371.430878,54.513012 371.884338,54.120758 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M365.847290,46.206818 
	C366.094513,46.166714 366.276245,46.361450 366.433502,46.574253 
	C366.441650,46.585266 366.241089,46.750523 365.930023,46.891960 
	C365.722351,46.939957 365.776733,46.445377 365.847290,46.206818 
z"
      />
      <path
        fill="#DC6D9B"
        opacity="1.000000"
        stroke="none"
        d="
M337.929688,56.868675 
	C337.718048,56.899426 337.755615,56.433952 337.812683,56.207371 
	C338.059753,56.167515 338.249725,56.354244 338.439697,56.540977 
	C338.340240,56.639969 338.240784,56.738949 337.929688,56.868675 
z"
      />
      <path
        fill="#DC6D9B"
        opacity="1.000000"
        stroke="none"
        d="
M337.029236,57.628178 
	C336.867737,57.886608 336.693176,57.915913 336.257507,57.694069 
	C335.996399,57.442913 335.990295,57.009129 335.990295,57.009129 
	C336.155884,56.956577 336.321472,56.904026 336.743805,56.895905 
	C337.000549,56.940327 337.016113,57.399055 337.029236,57.628178 
z"
      />
      <path
        fill="#76B9B8"
        opacity="1.000000"
        stroke="none"
        d="
M335.917694,39.646870 
	C334.937592,42.089401 333.995911,44.181133 332.744995,46.474705 
	C332.211090,46.349018 331.986359,46.021488 331.785095,45.299210 
	C335.213715,34.307205 338.618835,23.709953 342.254639,12.995760 
	C342.640228,12.584237 342.795227,12.289657 343.212158,11.958452 
	C343.647614,11.890259 343.821075,11.858690 343.994568,11.827123 
	C344.211487,12.099866 344.428406,12.372608 344.837921,12.822474 
	C345.045685,13.166759 345.060883,13.333920 345.033539,14.106291 
	C343.953857,17.458858 342.916687,20.206217 341.879517,22.953577 
	C341.618622,23.438492 341.357758,23.923405 340.977631,25.031902 
	C339.621613,29.104265 338.384827,32.553043 337.148041,36.001820 
	C337.148041,36.001820 336.960876,36.412560 336.741760,36.759003 
	C336.292389,37.336548 336.215454,37.610653 336.291779,37.927765 
	C336.179871,38.383865 336.067993,38.839966 335.917694,39.646870 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M337.479401,35.937984 
	C338.384827,32.553043 339.621613,29.104265 341.031525,25.325520 
	C341.800171,25.770117 342.395721,26.544682 342.710571,27.694046 
	C342.107605,28.374319 341.785248,28.679790 341.462921,28.985266 
	C341.672607,29.167206 341.882324,29.349140 342.092010,29.531073 
	C342.395081,29.020992 342.698151,28.510914 343.001221,28.000839 
	C343.324524,28.101423 343.586060,28.288370 343.894989,28.780479 
	C344.319519,29.786705 344.634888,30.574133 344.973938,31.680649 
	C342.601959,33.291206 340.206360,34.582680 337.479401,35.937984 
z"
      />
      <path
        fill="#FBFEFF"
        opacity="1.000000"
        stroke="none"
        d="
M344.993896,43.999390 
	C344.993896,43.999390 344.990265,43.508820 344.990234,43.263756 
	C345.092621,39.757477 346.284973,38.953888 348.619690,41.694443 
	C347.914886,42.724400 347.463165,43.356762 347.011444,43.989128 
	C347.011444,43.989132 347.003540,43.996510 346.667053,43.998566 
	C345.885010,44.000206 345.439453,43.999798 344.993896,43.999390 
z"
      />
      <path
        fill="#F4EAF5"
        opacity="1.000000"
        stroke="none"
        d="
M347.362000,43.983765 
	C347.463165,43.356762 347.914886,42.724400 348.685120,42.050018 
	C349.003662,42.007999 349.488953,42.010571 349.731262,42.011238 
	C349.219940,42.667408 348.466278,43.322906 347.362000,43.983765 
z"
      />
      <path
        fill="#F4EAF5"
        opacity="1.000000"
        stroke="none"
        d="
M336.460266,37.794876 
	C336.215454,37.610653 336.292389,37.336548 336.707703,36.982101 
	C336.911682,37.161713 336.823669,37.429459 336.460266,37.794876 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M354.123169,65.098167 
	C354.080383,66.459656 354.037567,67.821152 353.997375,69.591324 
	C354.000000,70.000000 354.000305,69.999390 353.654022,70.029121 
	C348.555511,72.967758 345.264771,77.042534 343.623871,82.788597 
	C345.475006,82.630028 347.076599,82.053452 348.451477,81.136810 
	C349.750793,80.270546 350.811951,79.046967 351.982117,78.288025 
	C351.976257,79.004555 351.966187,79.413605 351.956116,79.822662 
	C350.703094,82.031425 349.450073,84.240189 347.974976,86.718399 
	C347.752930,86.987846 347.359375,86.980171 347.098267,86.603722 
	C341.189178,85.659348 335.549286,84.979301 329.889801,84.569824 
	C326.973083,84.358795 321.943085,85.576759 321.457764,84.512306 
	C319.679718,80.612495 316.810089,82.271095 314.416840,82.065727 
	C311.300018,81.798271 308.142456,82.004875 304.994354,81.729965 
	C304.979279,81.267487 304.972321,81.082527 305.109131,80.691177 
	C305.717865,80.304482 306.182831,80.124184 307.134094,79.980423 
	C312.065033,80.265419 316.509674,80.513885 321.379852,80.845009 
	C324.097168,80.999161 326.389008,81.070663 328.951691,81.360077 
	C330.128021,81.641541 331.033508,81.705093 332.352539,81.846710 
	C334.166321,82.002800 335.566559,82.080833 337.220825,82.370651 
	C338.847076,81.791664 340.558624,81.292671 341.532959,80.159706 
	C345.797577,75.200562 349.848572,70.057686 354.013184,65.011185 
	C354.049286,65.040894 354.123169,65.098167 354.123169,65.098167 
z"
      />
      <path
        fill="#D4679D"
        opacity="1.000000"
        stroke="none"
        d="
M360.397858,64.982567 
	C359.846252,61.524780 361.725128,60.027943 365.443054,59.933739 
	C364.115082,61.568314 362.413483,63.226242 360.397858,64.982567 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M353.999878,70.000305 
	C354.720398,70.000107 355.441040,69.999603 356.521179,70.002274 
	C356.239899,71.460289 355.599091,72.915115 354.627686,74.668800 
	C354.297089,74.967659 353.996155,74.970833 353.972717,74.653664 
	C353.966309,72.890793 353.983307,71.445091 354.000305,69.999390 
	C354.000305,69.999390 354.000000,70.000000 353.999878,70.000305 
z"
      />
      <path
        fill="#D4679D"
        opacity="1.000000"
        stroke="none"
        d="
M357.580139,68.342133 
	C357.980042,67.371170 358.661285,66.231918 359.717285,65.087379 
	C359.348511,66.112679 358.605011,67.143265 357.580139,68.342133 
z"
      />
      <path
        fill="#D0659B"
        opacity="1.000000"
        stroke="none"
        d="
M352.224060,79.659119 
	C351.966187,79.413605 351.976257,79.004555 352.027588,77.978653 
	C352.660370,76.864609 353.251953,76.367409 353.888306,75.910576 
	C353.933044,75.950943 354.027069,76.026329 354.027069,76.026329 
	C353.515381,77.182747 353.003693,78.339165 352.224060,79.659119 
z"
      />
      <path
        fill="#D4679D"
        opacity="1.000000"
        stroke="none"
        d="
M353.996155,74.970833 
	C353.996155,74.970833 354.297089,74.967659 354.446533,74.966286 
	C354.654266,75.269753 354.576965,75.535095 354.195557,75.893631 
	C354.027069,76.026329 353.933044,75.950943 353.934875,75.687477 
	C353.936737,75.424011 353.996155,74.970833 353.996155,74.970833 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M342.237671,22.918226 
	C342.916687,20.206217 343.953857,17.458858 345.083618,14.353041 
	C345.687256,14.801380 346.824982,15.924360 346.604218,16.361923 
	C345.458984,18.631887 343.967010,20.726913 342.237671,22.918226 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M345.030701,44.355614 
	C345.439453,43.999798 345.885010,44.000206 346.664673,44.001198 
	C347.837433,44.971432 348.676086,45.941093 349.291412,46.652523 
	C352.538849,44.970936 355.396057,43.491432 358.630310,42.007374 
	C358.004639,43.298073 357.089661,44.674255 355.983459,45.874119 
	C351.888977,50.315178 348.536072,49.934948 345.030701,44.355614 
z"
      />
      <path
        fill="#8AB7BE"
        opacity="1.000000"
        stroke="none"
        d="
M378.525665,93.035095 
	C377.920990,93.026779 377.732025,93.018845 377.259460,93.001068 
	C380.036499,92.572968 383.097168,92.154732 386.157806,91.736488 
	C386.196198,92.169487 386.234589,92.602478 386.272980,93.035477 
	C383.829132,93.035477 381.385254,93.035477 378.525665,93.035095 
z"
      />
      <path
        fill="#F1E8F7"
        opacity="1.000000"
        stroke="none"
        d="
M343.930542,11.496796 
	C343.821075,11.858690 343.647614,11.890259 342.996918,12.087924 
	C342.354462,12.540246 342.189209,12.826473 342.023987,13.112701 
	C338.618835,23.709953 335.213715,34.307205 331.554077,45.581356 
	C330.246185,49.796745 329.192780,53.335239 327.907013,57.133072 
	C327.108063,55.932930 326.541473,54.473442 325.974854,53.013947 
	C327.718994,47.733692 329.463135,42.453434 331.415283,36.607117 
	C331.703857,35.699745 331.784424,35.358433 331.864990,35.017117 
	C331.963226,34.641304 332.061462,34.265491 332.424530,33.332222 
	C333.721008,29.507879 334.752625,26.240995 335.784241,22.974110 
	C335.917389,22.635176 336.050568,22.296242 336.453918,21.442589 
	C336.855682,20.286493 336.987213,19.645117 337.118744,19.003742 
	C337.576874,17.155891 338.035004,15.308041 338.768616,13.230499 
	C339.571381,12.180146 340.098633,11.359488 340.779785,10.273116 
	C341.911316,10.393759 342.888916,10.780114 343.930542,11.496796 
z"
      />
      <path
        fill="#CF6899"
        opacity="1.000000"
        stroke="none"
        d="
M325.599426,53.010635 
	C326.541473,54.473442 327.108063,55.932930 328.224121,57.158817 
	C329.508759,56.609196 330.243988,56.293179 330.979218,55.977161 
	C332.405975,56.278435 333.832733,56.579704 335.624878,56.945053 
	C335.990295,57.009129 335.996399,57.442913 335.994232,57.659378 
	C334.863647,58.529087 333.735260,59.182331 331.945923,60.044876 
	C327.684540,63.916618 324.484680,61.497410 321.277771,59.499527 
	C321.304657,59.405926 321.237610,59.223068 321.237610,59.223068 
	C321.458740,57.415264 321.679871,55.607464 321.990662,53.400826 
	C322.080261,53.001984 322.524384,52.998180 322.746063,52.995949 
	C323.719849,52.998253 324.471924,53.002789 325.599426,53.010635 
z"
      />
      <path
        fill="#D66EA1"
        opacity="1.000000"
        stroke="none"
        d="
M335.446777,23.068802 
	C334.752625,26.240995 333.721008,29.507879 332.436401,33.040329 
	C333.158752,29.925089 334.134033,26.544291 335.446777,23.068802 
z"
      />
      <path
        fill="#D66EA1"
        opacity="1.000000"
        stroke="none"
        d="
M336.834900,19.206509 
	C336.987213,19.645117 336.855682,20.286493 336.493011,21.171795 
	C336.358276,20.746904 336.454681,20.078091 336.834900,19.206509 
z"
      />
      <path
        fill="#D66EA1"
        opacity="1.000000"
        stroke="none"
        d="
M331.651184,35.188606 
	C331.784424,35.358433 331.703857,35.699745 331.428040,36.228928 
	C331.300934,36.064564 331.369141,35.712334 331.651184,35.188606 
z"
      />
      <path
        fill="#D4679D"
        opacity="1.000000"
        stroke="none"
        d="
M302.287659,75.814430 
	C299.686066,73.025986 298.198975,70.211884 301.026459,66.580765 
	C301.795044,65.593735 301.057129,63.433613 301.015442,61.427696 
	C303.189697,59.964653 305.359161,58.885799 307.912170,58.226418 
	C308.707886,59.707146 309.120056,60.768402 309.532227,61.829651 
	C310.510529,68.095322 306.673767,71.954735 302.287659,75.814430 
z"
      />
      <path
        fill="#9CCACC"
        opacity="1.000000"
        stroke="none"
        d="
M309.944916,61.836117 
	C309.120056,60.768402 308.707886,59.707146 308.218353,58.356941 
	C310.464539,57.830082 312.788086,57.592171 315.457520,57.208626 
	C317.556610,57.548222 319.309875,58.033451 321.150391,58.870872 
	C321.237610,59.223068 321.304657,59.405926 321.029236,59.871315 
	C319.157288,60.502922 317.398376,60.311447 316.012054,60.940746 
	C314.745911,61.515480 313.883148,62.978893 312.680115,64.055130 
	C312.517578,64.059608 312.192474,64.064720 312.192474,64.064713 
	C311.580872,63.324001 310.969238,62.583290 309.944916,61.836117 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M307.033325,53.525848 
	C307.430786,53.200974 307.882446,53.142452 308.667938,53.164917 
	C308.363678,53.428005 307.725586,53.610107 307.033325,53.525848 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M307.679077,42.212746 
	C307.795685,42.608547 307.666565,43.197914 307.314758,44.002884 
	C307.205811,43.614429 307.319611,43.010368 307.679077,42.212746 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M306.674316,45.209793 
	C306.794067,45.605141 306.670807,46.198875 306.325500,47.009102 
	C306.212708,46.619793 306.322021,46.013988 306.674316,45.209793 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M305.705933,48.193817 
	C305.807434,48.583160 305.666290,49.153347 305.291260,49.913025 
	C305.192657,49.526566 305.328003,48.950611 305.705933,48.193817 
z"
      />
      <path
        fill="#D66097"
        opacity="1.000000"
        stroke="none"
        d="
M304.711487,51.092537 
	C304.852539,51.018917 304.876648,51.501728 304.780731,51.723450 
	C304.370453,51.711334 304.332306,51.451660 304.711487,51.092537 
z"
      />
      <path
        fill="#FBFCFD"
        opacity="1.000000"
        stroke="none"
        d="
M272.727173,57.013332 
	C272.771942,57.194992 272.572449,57.479141 272.125488,57.860046 
	C272.079651,57.676476 272.281281,57.396149 272.727173,57.013332 
z"
      />
      <path
        fill="#F0E6F7"
        opacity="1.000000"
        stroke="none"
        d="
M233.046478,55.650192 
	C232.951233,56.342281 232.848557,56.646584 232.651947,57.170547 
	C232.588943,57.400642 232.609833,57.552620 232.458527,57.581650 
	C232.307236,57.610680 231.970047,57.841072 231.970062,57.841072 
	C231.555374,56.750992 230.507782,55.410267 230.824753,54.609955 
	C233.722168,47.294037 236.856796,40.072056 240.173630,32.344734 
	C240.666153,31.657518 240.775772,31.393633 240.753906,31.075157 
	C240.753906,31.075157 240.992966,30.645994 241.266739,30.216759 
	C241.720978,29.238359 241.901443,28.689196 242.081894,28.140030 
	C242.504868,28.050365 242.927826,27.960703 243.989197,27.882778 
	C245.061157,27.956823 245.494720,28.019131 245.928284,28.081440 
	C241.642166,37.145237 237.356064,46.209034 233.046478,55.650192 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M233.243805,36.907837 
	C232.926147,36.510559 232.965439,36.085987 233.023224,35.341606 
	C234.033432,33.012283 235.025146,31.002775 236.355743,28.907206 
	C235.663300,31.507612 234.632019,34.194077 233.243805,36.907837 
z"
      />
      <path
        fill="#CB6697"
        opacity="1.000000"
        stroke="none"
        d="
M246.312881,28.054781 
	C245.494720,28.019131 245.061157,27.956823 244.307343,27.818718 
	C244.170532,27.253252 244.353989,26.763584 244.767761,26.135159 
	C246.283737,26.402935 247.569382,26.809467 248.929962,27.564117 
	C248.235764,27.950865 247.466614,27.989494 246.312881,28.054781 
z"
      />
      <path
        fill="#CB6697"
        opacity="1.000000"
        stroke="none"
        d="
M241.890732,27.957548 
	C241.901443,28.689196 241.720978,29.238359 241.314133,29.975750 
	C241.291687,29.367674 241.495621,28.571369 241.890732,27.957548 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M228.138458,48.814056 
	C228.028900,48.385197 228.199738,47.804424 228.622192,47.049156 
	C228.722153,47.470490 228.570511,48.066315 228.138458,48.814056 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M243.899963,54.128990 
	C244.009399,54.380795 243.815613,54.571388 243.316345,54.689095 
	C243.232010,54.456123 243.427277,54.273312 243.899963,54.128990 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M240.577225,31.203171 
	C240.775772,31.393633 240.666153,31.657518 240.245453,31.996729 
	C240.084946,31.822544 240.196518,31.557392 240.577225,31.203171 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M232.057343,58.034851 
	C231.970047,57.841072 232.307236,57.610680 232.504852,57.642803 
	C232.516525,57.859493 232.330582,58.044060 232.057343,58.034851 
z"
      />
      <path
        fill="#83B9BB"
        opacity="1.000000"
        stroke="none"
        d="
M312.081451,64.421951 
	C312.192474,64.064720 312.517578,64.059608 312.895813,64.365234 
	C313.151886,67.085739 313.029724,69.500618 312.918152,71.921478 
	C312.928741,71.927460 312.923157,71.933327 312.646484,72.124657 
	C310.462463,74.858620 308.555145,77.401253 306.647797,79.943886 
	C306.182831,80.124184 305.717865,80.304482 304.795776,80.672623 
	C303.920380,80.851952 303.502045,80.843422 303.083740,80.834900 
	C302.880219,80.569557 302.676727,80.304214 302.294586,79.857773 
	C306.107361,75.178230 311.213959,71.417480 312.081451,64.421951 
z"
      />
      <path
        fill="#DA4F97"
        opacity="1.000000"
        stroke="none"
        d="
M302.799927,81.161179 
	C303.502045,80.843422 303.920380,80.851952 304.652008,80.879028 
	C304.972321,81.082527 304.979279,81.267487 304.993530,82.128044 
	C304.999481,83.865273 304.998138,84.926918 304.996765,85.988556 
	C304.996765,85.988556 305.001160,86.003395 304.798767,86.083092 
	C304.333893,86.301880 304.132385,86.502319 303.529022,86.815155 
	C298.380707,86.807472 293.695251,86.748741 288.882935,86.325516 
	C283.506500,85.642113 278.256927,85.323196 273.007324,85.004272 
	C273.004944,84.557785 273.002594,84.111305 272.999207,83.081055 
	C272.998199,82.497299 272.997559,81.999390 272.997559,81.999390 
	C282.837067,81.828743 292.676605,81.658096 302.799927,81.161179 
z"
      />
      <path
        fill="#DB5996"
        opacity="1.000000"
        stroke="none"
        d="
M272.748169,81.998688 
	C272.997559,81.999390 272.998199,82.497299 272.998474,82.746185 
	C272.676208,82.895500 272.415405,82.709412 272.108154,82.218407 
	C272.000000,82.000000 272.498779,81.997978 272.748169,81.998688 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M222.767685,29.787514 
	C222.509476,30.620716 222.129059,31.242334 221.506790,31.995628 
	C220.331467,31.426947 219.398010,30.726583 218.107056,29.962311 
	C217.751160,29.518873 217.752762,29.139347 217.693130,28.481403 
	C218.928268,27.936474 220.224640,27.669964 221.746429,27.652710 
	C222.196396,28.459953 222.420944,29.017942 222.767685,29.787514 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M228.386475,27.502041 
	C228.381317,27.255747 228.623550,27.140068 229.114624,27.106689 
	C229.137177,27.347660 228.893967,27.495548 228.386475,27.502041 
z"
      />
      <path
        fill="#F0E6F7"
        opacity="1.000000"
        stroke="none"
        d="
M208.067200,77.649765 
	C208.370010,77.200699 208.739182,77.029114 209.402557,76.797340 
	C209.638901,77.294960 209.581024,77.852760 209.523148,78.410568 
	C209.059937,78.249458 208.596741,78.088356 208.067200,77.649765 
z"
      />
      <path
        fill="#E75095"
        opacity="1.000000"
        stroke="none"
        d="
M172.881439,57.316460 
	C173.490768,57.067989 173.919144,57.043831 174.669189,57.008705 
	C177.323273,59.426792 176.755402,60.858517 172.251205,63.053860 
	C171.475677,63.158466 171.066925,63.189560 170.353027,63.252293 
	C170.343018,61.761971 170.638138,60.240013 171.012024,58.406845 
	C171.627365,57.910683 172.163925,57.725731 172.881439,57.316460 
z"
      />
      <path
        fill="#DE5194"
        opacity="1.000000"
        stroke="none"
        d="
M64.985031,25.084127 
	C86.014488,24.409578 106.965508,45.736233 105.608826,66.880341 
	C77.588394,66.880341 49.538860,66.880341 21.164602,66.880341 
	C19.968098,60.609791 21.885801,55.062935 24.891357,49.169720 
	C25.563173,48.121555 25.747265,47.521942 25.931355,46.922333 
	C25.931355,46.922333 25.961000,47.011948 26.268730,46.966949 
	C27.762625,46.172180 28.948788,45.422413 30.263107,44.591637 
	C31.481943,45.684925 32.652184,46.734627 33.673573,47.650806 
	C35.848507,44.869274 37.828796,42.336678 39.829350,39.820511 
	C39.849617,39.836941 39.876682,39.879124 40.224537,39.896126 
	C41.356621,38.129082 46.161152,41.378578 44.655197,36.679039 
	C44.691761,36.676704 44.756195,36.641800 45.130219,36.751686 
	C46.668320,35.901474 47.832401,34.941380 49.309078,33.781757 
	C53.933796,32.658367 58.245922,31.734503 62.839180,30.783226 
	C63.150383,30.179611 63.180450,29.603413 63.123962,28.710152 
	C62.883350,27.983095 62.729286,27.573099 62.927711,27.100910 
	C63.848473,26.387186 64.416748,25.735657 64.985031,25.084127 
z"
      />
      <path
        fill="#51C2C1"
        opacity="1.000000"
        stroke="none"
        d="
M49.137020,74.338120 
	C44.094044,74.429695 39.051067,74.521271 33.520378,74.150009 
	C32.916351,72.969620 32.800041,72.252060 32.683735,71.534492 
	C39.821342,71.386108 46.958950,71.237717 54.827744,71.375565 
	C57.364857,71.505173 59.170776,71.348557 60.976700,71.191940 
	C62.041809,71.150986 63.106918,71.110016 64.649948,71.449585 
	C67.080299,71.615875 69.032738,71.401634 70.985168,71.187393 
	C71.424370,71.154541 71.863579,71.121689 72.630676,71.133606 
	C72.973450,71.885361 72.988327,72.592346 72.639633,73.658249 
	C71.178154,74.998116 70.080254,75.979073 68.982346,76.960022 
	C68.783958,77.225075 68.585571,77.490120 67.784439,77.880447 
	C66.458961,79.339546 65.736221,80.673378 65.013481,82.007202 
	C65.013481,82.007202 65.000000,82.000305 64.777534,82.125412 
	C64.388527,82.543373 64.221985,82.836235 64.055443,83.129097 
	C64.055443,83.129097 63.967457,83.557358 63.905991,83.767189 
	C63.433987,83.982574 63.023441,83.988129 62.173191,83.752541 
	C61.540009,83.006042 61.346539,82.500687 61.293133,81.879532 
	C61.263378,81.527420 61.093552,81.291092 60.947113,81.022598 
	C60.970493,80.990417 60.948658,81.066414 60.868484,80.835236 
	C60.610653,80.315666 60.364441,80.108932 59.984238,80.001907 
	C59.918800,80.019966 59.785194,80.044098 59.830143,79.840439 
	C59.875092,79.636787 59.869164,79.219933 59.899387,78.842484 
	C59.715706,75.922058 59.127319,73.958824 55.736412,75.903015 
	C55.127293,75.841255 54.781525,75.803131 54.435753,75.765007 
	C54.435753,75.765007 54.352451,75.721893 54.269375,75.517975 
	C54.186298,75.314049 54.010204,74.967812 53.922638,74.811264 
	C53.546268,74.720245 53.257458,74.785774 52.970253,74.932983 
	C52.971851,75.014648 53.056671,75.154282 53.056671,75.154282 
	C52.282970,75.072159 51.509274,74.990044 50.298206,74.666206 
	C49.644650,74.215591 49.403378,74.186798 49.137020,74.338120 
z"
      />
      <path
        fill="#CD6996"
        opacity="1.000000"
        stroke="none"
        d="
M39.809082,39.804085 
	C37.828796,42.336678 35.848507,44.869274 33.673573,47.650806 
	C32.652184,46.734627 31.481943,45.684925 30.263107,44.591637 
	C28.948788,45.422413 27.762625,46.172180 26.242477,46.927204 
	C33.947914,34.371395 44.352554,25.499710 60.380882,25.295750 
	C60.093910,26.056118 59.549084,26.518654 58.631729,27.080194 
	C51.945938,29.868893 45.535393,32.358463 39.371304,35.355019 
	C37.164158,36.427982 35.419441,38.739746 39.809082,39.804085 
z"
      />
      <path
        fill="#51C2C1"
        opacity="1.000000"
        stroke="none"
        d="
M28.906593,74.394211 
	C27.844700,74.479370 26.782806,74.564529 25.331326,74.535812 
	C24.931349,73.737892 24.920958,73.053856 24.849659,72.023712 
	C26.369112,71.587265 27.949471,71.496925 29.802738,71.765747 
	C29.685961,72.881355 29.296276,73.637779 28.906593,74.394211 
z"
      />
      <path
        fill="#70CACC"
        opacity="1.000000"
        stroke="none"
        d="
M29.311283,74.551659 
	C29.296276,73.637779 29.685961,72.881355 30.270153,71.835800 
	C31.021221,71.518616 31.577780,71.490547 32.409035,71.498489 
	C32.800041,72.252060 32.916351,72.969620 33.076469,74.034996 
	C31.985508,74.491570 30.850742,74.600334 29.311283,74.551659 
z"
      />
      <path
        fill="#70CACC"
        opacity="1.000000"
        stroke="none"
        d="
M70.562462,71.101036 
	C69.032738,71.401634 67.080299,71.615875 65.046997,71.502205 
	C66.690674,71.121094 68.415215,71.067886 70.562462,71.101036 
z"
      />
      <path
        fill="#70CACC"
        opacity="1.000000"
        stroke="none"
        d="
M60.557388,71.111267 
	C59.170776,71.348557 57.364857,71.505173 55.293243,71.429459 
	C56.731064,71.141609 58.434566,71.086105 60.557388,71.111267 
z"
      />
      <path
        fill="#CD6996"
        opacity="1.000000"
        stroke="none"
        d="
M64.609283,25.047909 
	C64.416748,25.735657 63.848473,26.387186 62.786018,27.104912 
	C62.291840,27.171106 62.037323,27.040068 62.026489,26.724125 
	C62.002872,25.986998 61.990086,25.565813 61.977299,25.144630 
	C62.729374,25.100317 63.481453,25.056004 64.609283,25.047909 
z"
      />
      <path
        fill="#DE5194"
        opacity="1.000000"
        stroke="none"
        d="
M61.727097,25.119873 
	C61.990086,25.565813 62.002872,25.986998 61.646500,26.739311 
	C60.519642,27.040693 59.761951,27.010941 59.004257,26.981190 
	C59.549084,26.518654 60.093910,26.056118 60.808914,25.379900 
	C61.145023,25.142517 61.310955,25.118816 61.727097,25.119873 
z"
      />
      <path
        fill="#CD6996"
        opacity="1.000000"
        stroke="none"
        d="
M25.667263,47.096325 
	C25.747265,47.521942 25.563173,48.121555 25.091766,48.880089 
	C25.004023,48.449448 25.203596,47.859882 25.667263,47.096325 
z"
      />
      <path
        fill="#57C1C1"
        opacity="1.000000"
        stroke="none"
        d="
M73.183510,91.096390 
	C74.213272,91.772896 75.208672,92.960861 76.279060,93.032784 
	C79.909294,93.276711 83.566055,93.125916 87.212677,93.125916 
	C87.508842,93.628761 87.805000,94.131607 88.101158,94.634453 
	C86.406151,95.784393 84.699196,97.919815 83.018387,97.899422 
	C77.983955,97.838356 71.481636,98.639565 68.326180,95.904243 
	C64.814323,92.859993 68.375114,81.940071 72.840401,78.973145 
	C76.110970,78.484863 78.595901,78.046066 81.080833,77.607269 
	C86.060547,77.523788 91.040253,77.440308 96.639801,77.765823 
	C100.560547,78.842888 103.861450,79.510941 107.162361,80.179001 
	C106.358490,80.749290 105.554626,81.319580 104.266342,82.009018 
	C103.485588,82.162201 103.235504,82.288055 103.031677,82.505692 
	C95.809578,82.603203 88.584709,82.621094 81.367928,82.871788 
	C80.072845,82.916771 78.811134,83.922539 78.018158,85.021927 
	C80.002747,85.604378 81.503181,85.649605 83.003624,85.694832 
	C84.068352,85.722076 85.133087,85.749321 86.989044,85.823479 
	C88.856651,85.805611 89.933029,85.740829 91.009415,85.676041 
	C92.068375,85.716789 93.127342,85.757538 94.916153,85.844833 
	C96.097557,85.822464 96.549110,85.753532 97.000656,85.684608 
	C100.625816,87.174889 97.723999,87.903946 96.227097,88.528191 
	C91.142487,87.704193 86.462959,87.196098 81.783432,86.688011 
	C81.746567,87.460304 81.709709,88.232605 81.672844,89.004898 
	C86.450264,89.004898 91.227684,89.004898 96.005104,89.004898 
	C94.274467,89.384453 92.543823,89.764008 90.035492,90.100601 
	C88.503212,90.123665 87.748619,90.189697 86.994026,90.255730 
	C85.605087,90.237686 84.216141,90.219635 81.985031,90.134583 
	C78.489746,90.410515 75.836632,90.753448 73.183510,91.096390 
z"
      />
      <path
        fill="#79CCC5"
        opacity="1.000000"
        stroke="none"
        d="
M107.532639,80.118912 
	C103.861450,79.510941 100.560547,78.842888 97.091080,77.858673 
	C100.551727,77.404320 104.180504,77.207855 107.810539,77.180939 
	C108.473663,77.176010 109.141914,77.861855 109.807800,78.228828 
	C109.172836,78.838829 108.537872,79.448830 107.532639,80.118912 
z"
      />
      <path
        fill="#79CCC5"
        opacity="1.000000"
        stroke="none"
        d="
M80.684250,77.472549 
	C78.595901,78.046066 76.110970,78.484863 73.178429,78.871368 
	C75.249763,78.325333 77.768715,77.831589 80.684250,77.472549 
z"
      />
      <path
        fill="#B2EEEB"
        opacity="1.000000"
        stroke="none"
        d="
M73.604141,91.072464 
	C75.836632,90.753448 78.489746,90.410515 81.569534,90.170532 
	C79.339058,90.531845 76.681915,90.790192 73.604141,91.072464 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M65.257538,81.812286 
	C65.736221,80.673378 66.458961,79.339546 67.557579,77.988976 
	C67.122833,79.187279 66.312210,80.402321 65.257538,81.812286 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M69.290527,76.871948 
	C70.080254,75.979073 71.178154,74.998116 72.638184,74.012581 
	C73.000305,74.007996 72.999069,74.485458 72.996353,74.724030 
	C71.862000,75.569687 70.730347,76.176781 69.290527,76.871948 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M83.627419,101.203346 
	C83.046921,101.490898 82.093369,101.710579 81.070358,101.636780 
	C81.752052,101.274033 82.503212,101.204750 83.627419,101.203346 
z"
      />
      <path
        fill="#B2EEEB"
        opacity="1.000000"
        stroke="none"
        d="
M82.713211,85.482018 
	C81.503181,85.649605 80.002747,85.604378 78.255325,85.276123 
	C79.479828,85.085136 80.951317,85.177170 82.713211,85.482018 
z"
      />
      <path
        fill="#B2EEEB"
        opacity="1.000000"
        stroke="none"
        d="
M90.766991,85.479889 
	C89.933029,85.740829 88.856651,85.805611 87.385666,85.770180 
	C88.168900,85.541222 89.346733,85.412476 90.766991,85.479889 
z"
      />
      <path
        fill="#B2EEEB"
        opacity="1.000000"
        stroke="none"
        d="
M87.204399,90.449249 
	C87.748619,90.189697 88.503212,90.123665 89.631760,90.131378 
	C89.142075,90.351006 88.278419,90.496880 87.204399,90.449249 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M65.657806,101.051056 
	C65.384033,101.170692 64.810333,101.178299 64.174110,100.914200 
	C64.527016,100.741333 64.942444,100.840179 65.657806,101.051056 
z"
      />
      <path
        fill="#B2EEEB"
        opacity="1.000000"
        stroke="none"
        d="
M96.831421,85.516891 
	C96.549110,85.753532 96.097557,85.822464 95.311966,85.799294 
	C95.539337,85.587860 96.100754,85.468513 96.831421,85.516891 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M68.719055,101.269379 
	C68.937027,101.320229 68.628654,101.591774 68.628654,101.591774 
	C68.628654,101.591774 68.240990,101.535751 68.152817,101.426895 
	C68.064644,101.318031 68.501091,101.218521 68.719055,101.269379 
z"
      />
      <path
        fill="#79CDD3"
        opacity="1.000000"
        stroke="none"
        d="
M103.245811,82.563744 
	C103.235504,82.288055 103.485588,82.162201 103.997742,82.151306 
	C104.019554,82.420059 103.768349,82.569168 103.245811,82.563744 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M64.292183,83.014114 
	C64.221985,82.836235 64.388527,82.543373 64.783081,82.130936 
	C64.850372,82.307281 64.689651,82.603203 64.292183,83.014114 
z"
      />
      <path
        fill="#DAF9FB"
        opacity="1.000000"
        stroke="none"
        d="
M61.153065,81.995331 
	C61.346539,82.500687 61.540009,83.006042 61.865036,83.755287 
	C61.973846,88.214554 62.135651,92.443367 61.830471,96.638191 
	C61.720383,98.151352 60.489197,99.582947 59.133961,101.134293 
	C58.329941,101.253342 58.161568,101.289871 57.993198,101.326393 
	C54.302063,101.278000 50.610924,101.229607 46.464432,101.258636 
	C43.998329,101.179924 41.987591,101.023788 39.556099,100.783371 
	C38.180973,99.663185 37.226601,98.627289 36.265442,97.345177 
	C36.258656,97.098961 36.484299,97.097221 36.752335,97.016251 
	C37.714310,96.602791 38.408257,96.270309 39.419277,96.167770 
	C40.674908,96.950600 41.563217,97.863617 42.559521,98.002632 
	C58.645256,100.247093 59.481441,99.476746 58.747971,83.159798 
	C58.180325,82.551491 57.652454,82.261200 57.003593,81.728676 
	C56.598766,81.268204 56.314938,81.049957 56.081184,80.506805 
	C57.053394,79.802345 57.975521,79.422798 58.930344,79.044403 
	C58.963036,79.045555 58.962597,79.110825 59.059738,79.298805 
	C59.321209,79.723412 59.530647,79.909180 59.785194,80.044098 
	C59.785194,80.044098 59.918800,80.019966 60.027504,80.254318 
	C60.407024,80.681252 60.677841,80.873833 60.948658,81.066414 
	C60.948658,81.066414 60.970493,80.990417 60.984707,81.262482 
	C60.998920,81.534538 61.153065,81.995331 61.153065,81.995331 
z"
      />
      <path
        fill="#6CC5C4"
        opacity="1.000000"
        stroke="none"
        d="
M58.019501,101.536835 
	C58.161568,101.289871 58.329941,101.253342 58.752441,101.264595 
	C58.841328,101.668907 58.676079,102.025436 58.510826,102.381966 
	C58.355820,102.170403 58.200813,101.958839 58.019501,101.536835 
z"
      />
      <path
        fill="#F8F8FC"
        opacity="1.000000"
        stroke="none"
        d="
M147.749207,70.925125 
	C145.979507,72.940620 144.209793,74.956108 142.043289,77.218681 
	C141.430649,77.575500 141.343323,77.749870 141.384506,77.988853 
	C141.384506,77.988853 141.035995,78.255447 140.891281,78.419724 
	C139.926559,78.627533 139.106567,78.671059 138.111298,78.447662 
	C137.318893,76.327263 136.701721,74.473778 136.051514,71.862656 
	C136.470505,69.199448 136.913940,67.291786 137.376038,65.388657 
	C139.978073,54.672104 143.915619,44.641491 152.088898,36.790695 
	C153.046005,39.419876 155.176544,42.261681 154.745697,44.639362 
	C153.310028,52.562325 150.955566,60.318802 148.902100,68.500229 
	C148.850937,68.861374 148.871750,68.897255 148.645203,69.072609 
	C148.195496,69.807014 147.972351,70.366066 147.749207,70.925125 
z"
      />
      <path
        fill="#78BABC"
        opacity="1.000000"
        stroke="none"
        d="
M152.043915,36.488449 
	C143.915619,44.641491 139.978073,54.672104 137.376038,65.388657 
	C136.913940,67.291786 136.470505,69.199448 135.988937,71.495155 
	C135.642487,69.690575 134.721497,67.382523 135.108490,65.319878 
	C137.306183,53.606197 141.287186,42.678562 150.638855,34.145264 
	C151.288452,34.726219 151.643692,35.456207 152.043915,36.488449 
z"
      />
      <path
        fill="#CB6697"
        opacity="1.000000"
        stroke="none"
        d="
M252.967926,28.651604 
	C252.437943,28.814564 251.870422,28.687517 251.138275,28.310326 
	C251.625870,28.160652 252.278137,28.261124 252.967926,28.651604 
z"
      />
      <path
        fill="#D06294"
        opacity="1.000000"
        stroke="none"
        d="
M148.048889,70.799858 
	C147.972351,70.366066 148.195496,69.807014 148.664642,69.069016 
	C148.723267,69.484909 148.535919,70.079758 148.048889,70.799858 
z"
      />
      <path
        fill="#D07AA7"
        opacity="1.000000"
        stroke="none"
        d="
M141.504135,77.933479 
	C141.343323,77.749870 141.430649,77.575500 141.801605,77.472847 
	C141.884827,77.645386 141.773849,77.778114 141.504135,77.933479 
z"
      />
      <path
        fill="#F1E5F4"
        opacity="1.000000"
        stroke="none"
        d="
M151.579895,74.915924 
	C151.663422,75.140083 151.492569,75.452629 151.054321,75.842789 
	C150.966461,75.615044 151.145996,75.309677 151.579895,74.915924 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M220.015472,34.242455 
	C219.791107,35.950848 219.226151,37.804497 218.380341,39.880264 
	C218.624619,38.197495 219.149765,36.292603 220.015472,34.242455 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M217.840683,40.239693 
	C217.976822,40.706577 217.808121,41.367020 217.391876,42.274158 
	C217.274857,41.824986 217.405350,41.129116 217.840683,40.239693 
z"
      />
      <path
        fill="#D35F95"
        opacity="1.000000"
        stroke="none"
        d="
M216.971405,43.040215 
	C217.141769,43.147301 217.125961,43.320786 217.010101,43.735554 
	C216.775818,43.776108 216.625366,43.583069 216.522934,43.367233 
	C216.505371,43.330185 216.692596,43.195950 216.971405,43.040215 
z"
      />
      <path
        fill="#ECFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M35.999878,83.379166 
	C39.702152,82.995499 43.404110,82.963646 47.613052,83.201180 
	C48.165096,83.648193 48.210155,83.825821 47.995075,84.308868 
	C43.821938,85.023903 39.908939,85.433533 35.995945,85.843155 
	C35.997147,85.139099 35.998352,84.435051 35.999878,83.379166 
z"
      />
      <path
        fill="#57BEBE"
        opacity="1.000000"
        stroke="none"
        d="
M35.605099,85.905037 
	C39.908939,85.433533 43.821938,85.023903 48.308163,84.373672 
	C49.922459,85.752037 50.963524,87.371010 52.002655,89.225098 
	C52.000717,89.460205 51.998600,89.930199 51.738083,90.005203 
	C51.316578,90.162354 51.155598,90.244507 50.659988,90.275696 
	C49.881756,90.144135 49.438152,90.063522 48.994549,89.982910 
	C47.865570,88.989990 46.812702,87.263252 45.593849,87.136833 
	C41.326359,86.694206 37.001633,86.803444 32.382614,86.449112 
	C33.115807,86.119072 34.165028,86.042999 35.605099,85.905037 
z"
      />
      <path
        fill="#4BC3C4"
        opacity="1.000000"
        stroke="none"
        d="
M23.946444,82.292320 
	C26.023899,81.238579 28.282019,80.074051 30.678358,79.403366 
	C31.172140,79.265160 32.181423,80.968803 32.929611,82.176376 
	C31.846497,82.594215 30.786875,82.659203 29.031883,82.462189 
	C27.106276,82.224937 25.876036,82.249695 24.645798,82.274460 
	C24.645798,82.274452 24.176069,82.356468 23.946444,82.292320 
z"
      />
      <path
        fill="#71CACD"
        opacity="1.000000"
        stroke="none"
        d="
M24.961655,82.476105 
	C25.876036,82.249695 27.106276,82.224937 28.636074,82.399475 
	C27.716261,82.625092 26.496885,82.651421 24.961655,82.476105 
z"
      />
      <path
        fill="#D6FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M20.123905,82.221039 
	C20.442892,82.057266 20.883316,82.105949 21.629158,82.287354 
	C21.371500,82.424545 20.808420,82.429024 20.123905,82.221039 
z"
      />
      <path
        fill="#DEFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M48.554314,90.035812 
	C49.438152,90.063522 49.881756,90.144135 50.853439,90.458847 
	C52.062038,90.866318 52.742565,91.039673 53.474525,91.543198 
	C49.039871,95.447876 43.955963,94.230591 38.972351,93.531647 
	C38.988094,93.372284 38.976372,93.052200 38.990211,92.717918 
	C39.013493,91.938766 39.022938,91.493896 39.032387,91.049026 
	C39.560658,90.925537 40.088924,90.802040 40.814968,90.494293 
	C43.379856,90.236267 45.746967,90.162498 48.554314,90.035812 
z"
      />
      <path
        fill="#DAF9FB"
        opacity="1.000000"
        stroke="none"
        d="
M38.976372,93.052200 
	C38.976372,93.052200 38.988094,93.372284 38.937836,93.855164 
	C38.845345,94.549324 38.803108,94.760612 38.417347,94.980530 
	C37.384247,94.991859 36.694672,94.994560 35.789650,94.922180 
	C35.574203,94.847099 35.138741,94.710754 35.138741,94.710754 
	C35.138741,94.710754 35.091057,94.313950 35.063171,93.889221 
	C35.035290,93.464500 35.002975,93.011749 35.002975,93.011749 
	C35.013092,92.564514 35.023205,92.117279 35.026955,91.334534 
	C35.762009,90.999519 36.503429,91.000015 37.638447,91.312454 
	C38.346817,92.100334 38.661594,92.576263 38.976372,93.052200 
z"
      />
      <path
        fill="#B2EEEB"
        opacity="1.000000"
        stroke="none"
        d="
M34.767136,93.091156 
	C35.002975,93.011749 35.035290,93.464500 35.038643,93.690903 
	C34.872486,93.943085 34.702976,93.968864 34.276424,93.993767 
	C34.062088,93.639130 34.232727,93.365021 34.767136,93.091156 
z"
      />
      <path
        fill="#CBF5F7"
        opacity="1.000000"
        stroke="none"
        d="
M38.990211,92.717918 
	C38.661594,92.576263 38.346817,92.100334 38.009331,91.327454 
	C38.160851,91.030502 38.335075,91.030510 38.770844,91.039780 
	C39.022938,91.493896 39.013493,91.938766 38.990211,92.717918 
z"
      />
      <path
        fill="#4BC3C4"
        opacity="1.000000"
        stroke="none"
        d="
M52.004589,88.989983 
	C50.963524,87.371010 49.922459,85.752037 48.568306,84.068253 
	C48.210155,83.825821 48.165096,83.648193 48.075600,83.199005 
	C48.501980,81.624496 48.972797,80.321548 50.022629,79.001648 
	C50.601643,78.984695 51.022030,79.090355 51.241608,79.335266 
	C52.321682,79.701889 53.182175,79.823593 54.022327,79.971634 
	C54.001984,79.997963 54.040409,79.947678 54.134827,80.182968 
	C54.506481,80.599129 54.783714,80.780006 55.029404,80.955666 
	C54.997856,80.950447 55.057877,80.927696 55.042953,81.319595 
	C57.462734,88.837280 57.462734,88.837280 52.004589,88.989983 
z"
      />
      <path
        fill="#C3F3F3"
        opacity="1.000000"
        stroke="none"
        d="
M51.022030,79.090355 
	C51.022030,79.090355 50.601643,78.984695 50.394142,78.924309 
	C50.101116,78.493637 50.015594,78.123352 49.840858,77.483490 
	C51.109047,76.932037 52.466450,76.650162 54.129807,76.066650 
	C54.781525,75.803131 55.127293,75.841255 55.982933,76.093193 
	C57.294415,77.219093 58.096035,78.131172 58.897652,79.043251 
	C57.975521,79.422798 57.053394,79.802345 55.839775,80.550751 
	C55.548283,80.919601 55.057877,80.927696 55.057877,80.927696 
	C55.057877,80.927696 54.997856,80.950447 54.945297,80.712463 
	C54.608627,80.298882 54.324516,80.123276 54.040409,79.947678 
	C54.040409,79.947678 54.001984,79.997963 53.831734,79.691437 
	C52.781670,79.286736 51.901848,79.188545 51.022030,79.090355 
z"
      />
      <path
        fill="#C3F3F3"
        opacity="1.000000"
        stroke="none"
        d="
M54.010204,74.967812 
	C54.010204,74.967812 54.186298,75.314049 54.225189,75.502258 
	C54.264080,75.690468 53.781456,75.758461 53.781456,75.758461 
	C53.781456,75.758461 53.343895,75.544212 53.200283,75.349243 
	C53.056671,75.154282 52.971851,75.014648 53.227474,74.990631 
	C53.658798,74.967010 53.834503,74.967407 54.010204,74.967812 
z"
      />
      <path
        fill="#70CACC"
        opacity="1.000000"
        stroke="none"
        d="
M49.332100,74.472687 
	C49.403378,74.186798 49.644650,74.215591 49.929615,74.541496 
	C49.998390,74.658501 49.527184,74.607246 49.332100,74.472687 
z"
      />
      <path
        fill="#71C1BF"
        opacity="1.000000"
        stroke="none"
        d="
M35.109749,95.048553 
	C35.138741,94.710754 35.574203,94.847099 35.802265,95.285797 
	C36.181652,96.182068 36.332973,96.639641 36.484299,97.097221 
	C36.484299,97.097221 36.258656,97.098961 36.151978,97.063110 
	C35.723782,96.480286 35.402267,95.933319 35.109749,95.048553 
z"
      />
      <path
        fill="#F2FBFC"
        opacity="1.000000"
        stroke="none"
        d="
M315.066589,87.971161 
	C318.588074,88.100975 322.109558,88.230782 325.890747,88.705795 
	C324.059082,89.363487 321.967743,89.675972 319.186035,89.704613 
	C316.989929,89.374588 315.484253,89.328400 313.978546,89.282211 
	C311.302368,89.230263 308.626160,89.178314 305.223663,89.102028 
	C304.309937,89.113235 304.122559,89.148773 303.935150,89.184311 
	C297.615234,89.181305 291.295319,89.178299 284.129303,89.127129 
	C282.505890,89.143051 281.728607,89.207130 280.951294,89.271210 
	C280.222260,89.251999 279.493195,89.232796 277.989075,89.158478 
	C275.806274,89.066956 274.398560,89.030548 272.990814,88.994141 
	C272.990814,88.994141 272.988983,88.499878 273.459839,88.246338 
	C287.642670,87.985588 301.354614,87.978378 315.066589,87.971161 
z"
      />
      <path
        fill="#FDD9EE"
        opacity="1.000000"
        stroke="none"
        d="
M314.784393,87.667336 
	C301.354614,87.978378 287.642670,87.985588 273.461060,87.999207 
	C269.187256,87.987068 265.383087,87.968521 261.080994,87.895126 
	C264.420258,87.538727 268.257416,87.237167 272.982483,86.920456 
	C275.916412,86.870918 277.962463,86.836525 280.253693,86.846130 
	C280.498840,86.890137 280.986969,86.790733 281.430603,86.838387 
	C284.252777,86.820694 286.631256,86.755348 289.009766,86.690002 
	C293.695251,86.748741 298.380707,86.807472 303.782532,86.875069 
	C304.671082,86.861847 304.843262,86.839752 305.015442,86.817665 
	C308.177704,86.999611 311.339935,87.181557 314.784393,87.667336 
z"
      />
      <path
        fill="#76B7BA"
        opacity="1.000000"
        stroke="none"
        d="
M262.565369,90.001175 
	C262.993683,90.471397 262.996124,90.897461 262.996674,91.642967 
	C261.309052,92.033546 259.623322,92.104675 257.523499,92.247314 
	C257.077820,91.836990 257.046295,91.355156 257.012878,90.512978 
	C258.720520,90.087425 260.430054,90.022224 262.565369,90.001175 
z"
      />
      <path
        fill="#95D6DB"
        opacity="1.000000"
        stroke="none"
        d="
M272.628784,89.033386 
	C274.398560,89.030548 275.806274,89.066956 277.625977,89.198456 
	C276.614075,89.534126 275.190186,89.774712 273.388062,90.033829 
	C271.894379,90.023613 270.778961,89.994873 269.245514,89.908112 
	C269.973877,89.590935 271.120300,89.331787 272.628784,89.033386 
z"
      />
      <path
        fill="#DB5996"
        opacity="1.000000"
        stroke="none"
        d="
M288.882935,86.325516 
	C286.631256,86.755348 284.252777,86.820694 281.332825,86.707596 
	C280.459381,86.355637 280.198425,86.446632 280.008545,86.802132 
	C277.962463,86.836525 275.916412,86.870918 273.434875,86.849274 
	C272.999420,86.793236 272.999786,86.390976 272.998657,86.189705 
	C272.997559,85.988434 273.002991,85.496307 273.005157,85.250290 
	C278.256927,85.323196 283.506500,85.642113 288.882935,86.325516 
z"
      />
      <path
        fill="#95D6DB"
        opacity="1.000000"
        stroke="none"
        d="
M314.274231,89.496422 
	C315.484253,89.328400 316.989929,89.374588 318.766052,89.680328 
	C317.547638,89.863457 316.058777,89.787048 314.274231,89.496422 
z"
      />
      <path
        fill="#95D6DB"
        opacity="1.000000"
        stroke="none"
        d="
M281.169312,89.464294 
	C281.728607,89.207130 282.505890,89.143051 283.666138,89.167923 
	C283.161804,89.390388 282.274597,89.523888 281.169312,89.464294 
z"
      />
      <path
        fill="#95D6DB"
        opacity="1.000000"
        stroke="none"
        d="
M304.049866,89.324814 
	C304.122559,89.148773 304.309937,89.113235 304.778748,89.131088 
	C304.761627,89.278099 304.463104,89.371704 304.049866,89.324814 
z"
      />
      <path
        fill="#E04E92"
        opacity="1.000000"
        stroke="none"
        d="
M305.465668,85.994751 
	C304.998138,84.926918 304.999481,83.865273 305.001648,82.405556 
	C308.142456,82.004875 311.300018,81.798271 314.416840,82.065727 
	C316.810089,82.271095 319.679718,80.612495 321.457764,84.512306 
	C321.943085,85.576759 326.973083,84.358795 329.889801,84.569824 
	C335.549286,84.979301 341.189178,85.659348 346.901550,86.599167 
	C341.235413,86.978378 335.501587,87.112579 329.775238,86.962318 
	C321.825409,86.753723 313.881165,86.331635 305.465668,85.994751 
z"
      />
      <path
        fill="#DC6D9B"
        opacity="1.000000"
        stroke="none"
        d="
M305.010803,86.617981 
	C304.843262,86.839752 304.671082,86.861847 304.245361,86.824020 
	C304.132385,86.502319 304.333893,86.301880 304.800049,86.090813 
	C305.003662,86.018837 305.006134,86.418304 305.010803,86.617981 
z"
      />
      <path
        fill="#8AB7BE"
        opacity="1.000000"
        stroke="none"
        d="
M331.001221,55.597904 
	C330.243988,56.293179 329.508759,56.609196 328.456421,56.899475 
	C329.192780,53.335239 330.246185,49.796745 331.530609,45.976109 
	C331.986359,46.021488 332.211090,46.349018 332.690063,46.830238 
	C332.303955,49.728836 331.663605,52.473743 331.001221,55.597904 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M335.964294,49.384670 
	C336.166992,50.520977 336.037903,51.622185 335.908783,52.723389 
	C335.329895,52.575115 334.750977,52.426838 334.172089,52.278564 
	C334.658905,51.302231 335.145721,50.325901 335.964294,49.384670 
z"
      />
      <path
        fill="#D16196"
        opacity="1.000000"
        stroke="none"
        d="
M354.312042,63.839012 
	C354.312042,63.839012 354.614471,63.849297 354.763794,63.866825 
	C354.782135,64.186043 354.651154,64.487732 354.321655,64.943794 
	C354.123169,65.098167 354.049286,65.040894 354.067627,64.717010 
	C354.161316,64.208427 354.236694,64.023720 354.312042,63.839012 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M328.680817,81.142159 
	C326.389008,81.070663 324.097168,80.999161 321.233154,80.548645 
	C320.660980,78.637047 320.660980,77.104462 320.660980,74.545204 
	C319.032104,75.462425 317.748291,75.834442 316.992950,76.679100 
	C315.036133,78.867348 313.897003,77.763832 313.201324,75.807083 
	C312.783356,74.631462 312.994659,73.232124 312.923157,71.933327 
	C312.923157,71.933327 312.928741,71.927460 313.291748,71.945251 
	C315.906067,72.112694 318.157349,72.262352 320.817596,72.439201 
	C319.001038,69.988052 317.306946,67.702179 315.306213,65.002533 
	C320.069305,64.297653 323.738953,63.754593 327.408600,63.211533 
	C329.615723,63.791138 331.822845,64.370743 334.440308,64.930008 
	C337.310577,64.601013 339.770538,64.292351 342.646790,63.966820 
	C343.063080,63.949947 343.024445,63.964401 343.054993,64.267113 
	C343.769440,64.594872 344.453339,64.619911 345.137268,64.644958 
	C345.553772,64.712090 345.970306,64.779228 346.693481,65.309715 
	C344.770691,69.398041 342.537781,73.020912 340.313202,76.648880 
	C339.189941,78.480774 338.081787,80.321915 336.966797,82.158875 
	C335.566559,82.080833 334.166321,82.002800 332.097351,81.617897 
	C330.512695,81.254730 329.596741,81.198441 328.680817,81.142159 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M327.779785,63.222893 
	C323.738953,63.754593 320.069305,64.297653 315.306213,65.002533 
	C317.306946,67.702179 319.001038,69.988052 320.817596,72.439201 
	C318.157349,72.262352 315.906067,72.112694 313.281189,71.939270 
	C313.029724,69.500618 313.151886,67.085739 313.058350,64.360764 
	C313.883148,62.978893 314.745911,61.515480 316.012054,60.940746 
	C317.398376,60.311447 319.157288,60.502922 321.002380,59.964912 
	C324.484680,61.497410 327.684540,63.916618 331.651184,60.187351 
	C332.416107,63.253902 330.249786,63.202171 327.779785,63.222893 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M337.220825,82.370659 
	C338.081787,80.321915 339.189941,78.480774 340.313202,76.648880 
	C342.537781,73.020912 344.770691,69.398041 347.000671,65.386292 
	C349.265717,64.470535 351.530243,63.941563 354.053406,63.625801 
	C354.236694,64.023720 354.161316,64.208427 354.031555,64.687302 
	C349.848572,70.057686 345.797577,75.200562 341.532959,80.159706 
	C340.558624,81.292671 338.847076,81.791664 337.220825,82.370659 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M344.918945,64.464798 
	C344.453339,64.619911 343.769440,64.594872 343.062622,64.252541 
	C343.593384,64.051720 344.147003,64.168182 344.918945,64.464798 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M342.254608,12.995759 
	C342.189209,12.826473 342.354462,12.540246 342.734955,12.124548 
	C342.795227,12.289657 342.640228,12.584237 342.254608,12.995759 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M343.000610,28.000420 
	C342.698151,28.510914 342.395081,29.020992 342.092010,29.531073 
	C341.882324,29.349140 341.672607,29.167206 341.462921,28.985266 
	C341.785248,28.679790 342.107605,28.374319 342.714355,28.034019 
	C342.998779,27.999191 343.000000,28.000000 343.000610,28.000420 
z"
      />
      <path
        fill="#E14E92"
        opacity="1.000000"
        stroke="none"
        d="
M353.972717,74.653664 
	C353.996155,74.970833 353.936737,75.424011 353.890137,75.647110 
	C353.251953,76.367409 352.660370,76.864609 352.023315,77.671173 
	C350.811951,79.046967 349.750793,80.270546 348.451477,81.136810 
	C347.076599,82.053452 345.475006,82.630028 343.623871,82.788597 
	C345.264771,77.042534 348.555511,72.967758 353.654022,70.029121 
	C353.983307,71.445091 353.966309,72.890793 353.972717,74.653664 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M312.646484,72.124657 
	C312.994659,73.232124 312.783356,74.631462 313.201324,75.807083 
	C313.897003,77.763832 315.036133,78.867348 316.992950,76.679100 
	C317.748291,75.834442 319.032104,75.462425 320.660980,74.545204 
	C320.660980,77.104462 320.660980,78.637047 320.807678,80.465988 
	C316.509674,80.513885 312.065033,80.265419 307.134094,79.980423 
	C308.555145,77.401253 310.462463,74.858620 312.646484,72.124657 
z"
      />
      <path
        fill="#F7F7FB"
        opacity="1.000000"
        stroke="none"
        d="
M328.951691,81.360077 
	C329.596741,81.198441 330.512695,81.254730 331.683777,81.539825 
	C331.033508,81.705093 330.128021,81.641541 328.951691,81.360077 
z"
      />
      <path
        fill="#FDE0F4"
        opacity="1.000000"
        stroke="none"
        d="
M58.631729,27.080193 
	C59.761951,27.010941 60.519642,27.040693 61.657330,27.055256 
	C62.037323,27.040068 62.291840,27.171106 62.433533,27.167105 
	C62.729286,27.573099 62.883350,27.983095 63.015957,29.007771 
	C62.849018,30.018515 62.703533,30.414577 62.558052,30.810640 
	C58.245922,31.734503 53.933796,32.658367 48.970352,33.804230 
	C47.131424,34.898087 45.943810,35.769943 44.756195,36.641800 
	C44.756195,36.641800 44.691761,36.676704 44.337833,36.690063 
	C42.614834,37.761990 41.245758,38.820557 39.876682,39.879124 
	C39.876682,39.879124 39.849617,39.836941 39.829350,39.820511 
	C35.419441,38.739746 37.164158,36.427982 39.371304,35.355019 
	C45.535393,32.358463 51.945938,29.868893 58.631729,27.080193 
z"
      />
      <path
        fill="#CD6996"
        opacity="1.000000"
        stroke="none"
        d="
M40.224537,39.896126 
	C41.245758,38.820557 42.614834,37.761990 44.301270,36.692398 
	C46.161152,41.378578 41.356621,38.129082 40.224537,39.896126 
z"
      />
      <path
        fill="#CD6996"
        opacity="1.000000"
        stroke="none"
        d="
M45.130219,36.751686 
	C45.943810,35.769943 47.131424,34.898087 48.657761,34.003754 
	C47.832401,34.941380 46.668320,35.901474 45.130219,36.751686 
z"
      />
      <path
        fill="#CD6996"
        opacity="1.000000"
        stroke="none"
        d="
M62.839184,30.783226 
	C62.703533,30.414577 62.849018,30.018515 63.102509,29.324833 
	C63.180450,29.603413 63.150383,30.179611 62.839184,30.783226 
z"
      />
      <path
        fill="#67C4C5"
        opacity="1.000000"
        stroke="none"
        d="
M58.930344,79.044403 
	C58.096035,78.131172 57.294415,77.219093 56.246281,76.116837 
	C59.127319,73.958824 59.715706,75.922058 59.674614,78.801567 
	C59.419613,79.138092 58.962597,79.110825 58.962597,79.110825 
	C58.962597,79.110825 58.963036,79.045555 58.930344,79.044403 
z"
      />
      <path
        fill="#67C4C5"
        opacity="1.000000"
        stroke="none"
        d="
M61.293133,81.879532 
	C61.153065,81.995331 60.998920,81.534538 60.961327,81.294655 
	C61.093552,81.291092 61.263378,81.527420 61.293133,81.879532 
z"
      />
      <path
        fill="#67C4C5"
        opacity="1.000000"
        stroke="none"
        d="
M60.868484,80.835236 
	C60.677841,80.873833 60.407024,80.681252 60.092941,80.236252 
	C60.364441,80.108932 60.610653,80.315666 60.868484,80.835236 
z"
      />
      <path
        fill="#C3F3F3"
        opacity="1.000000"
        stroke="none"
        d="
M59.059738,79.298805 
	C58.962597,79.110825 59.419613,79.138092 59.644386,79.179016 
	C59.869164,79.219933 59.875092,79.636787 59.830143,79.840439 
	C59.530647,79.909180 59.321209,79.723412 59.059738,79.298805 
z"
      />
      <path
        fill="#67C4C5"
        opacity="1.000000"
        stroke="none"
        d="
M53.922638,74.811264 
	C53.834503,74.967407 53.658798,74.967010 53.225876,74.908958 
	C53.257458,74.785774 53.546268,74.720245 53.922638,74.811264 
z"
      />
      <path
        fill="#55C0CA"
        opacity="1.000000"
        stroke="none"
        d="
M96.002548,89.002449 
	C91.227684,89.004898 86.450264,89.004898 81.672844,89.004898 
	C81.709709,88.232605 81.746567,87.460304 81.783432,86.688011 
	C86.462959,87.196098 91.142487,87.704193 95.913528,88.608597 
	C96.005043,89.004906 96.000000,89.000000 96.002548,89.002449 
z"
      />
      <path
        fill="#5CC0BE"
        opacity="1.000000"
        stroke="none"
        d="
M55.042953,81.319595 
	C55.057877,80.927696 55.548283,80.919601 55.789696,80.875656 
	C56.314938,81.049957 56.598766,81.268204 57.075649,82.017014 
	C57.775047,82.857658 58.281395,83.167732 58.787743,83.477806 
	C59.481441,99.476746 58.645256,100.247093 42.559521,98.002632 
	C41.563217,97.863617 40.674908,96.950600 39.349129,95.920975 
	C38.894901,95.286797 38.827888,95.129341 38.760872,94.971893 
	C38.803108,94.760612 38.845345,94.549324 38.922092,94.014526 
	C43.955963,94.230591 49.039871,95.447876 53.472145,91.272240 
	C52.945091,90.424149 52.471844,90.177170 51.998600,89.930199 
	C51.998600,89.930199 52.000717,89.460205 52.002655,89.225098 
	C57.462734,88.837280 57.462734,88.837280 55.042953,81.319595 
z"
      />
      <path
        fill="#CBF5F7"
        opacity="1.000000"
        stroke="none"
        d="
M38.417347,94.980530 
	C38.827888,95.129341 38.894901,95.286797 39.032059,95.691040 
	C38.408257,96.270309 37.714310,96.602791 36.752331,97.016251 
	C36.332973,96.639641 36.181652,96.182068 36.017712,95.360870 
	C36.694672,94.994560 37.384247,94.991859 38.417347,94.980530 
z"
      />
      <path
        fill="#C3F3F3"
        opacity="1.000000"
        stroke="none"
        d="
M58.747971,83.159798 
	C58.281395,83.167732 57.775047,82.857658 57.196640,82.259247 
	C57.652454,82.261200 58.180325,82.551491 58.747971,83.159798 
z"
      />
      <path
        fill="#B2EEEB"
        opacity="1.000000"
        stroke="none"
        d="
M51.738083,90.005203 
	C52.471844,90.177170 52.945091,90.424149 53.420712,90.942078 
	C52.742565,91.039673 52.062038,90.866318 51.188065,90.509804 
	C51.155598,90.244507 51.316578,90.162354 51.738083,90.005203 
z"
      />
      <path
        fill="#5CC0BE"
        opacity="1.000000"
        stroke="none"
        d="
M51.241608,79.335266 
	C51.901848,79.188545 52.781670,79.286736 53.852081,79.665115 
	C53.182175,79.823593 52.321682,79.701889 51.241608,79.335266 
z"
      />
      <path
        fill="#5CC0BE"
        opacity="1.000000"
        stroke="none"
        d="
M54.134827,80.182968 
	C54.324516,80.123276 54.608627,80.298882 54.976845,80.717682 
	C54.783714,80.780006 54.506481,80.599129 54.134827,80.182968 
z"
      />
      <path
        fill="#DC6D9B"
        opacity="1.000000"
        stroke="none"
        d="
M280.253693,86.846130 
	C280.198425,86.446632 280.459381,86.355637 280.889191,86.659943 
	C280.986969,86.790733 280.498840,86.890137 280.253693,86.846130 
z"
      />
    </svg>
  )
}

export default OnTheFlyLogo
