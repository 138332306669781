const Deletesvg = () => {
  return (
    <>
      <svg
        width="171"
        height="115"
        viewBox="0 0 171 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_74_2479)">
          <path
            d="M97.5 97C114.345 97 128 83.3447 128 66.5C128 49.6553 114.345 36 97.5 36C80.6553 36 67 49.6553 67 66.5C67 83.3447 80.6553 97 97.5 97Z"
            fill="#E9EDFC"
          />
          <path
            d="M89.534 38.1803C91.8898 39.0053 94.419 39.2119 96.878 38.7803C99.338 38.3487 101.644 37.2934 103.575 35.7161C108.493 31.6038 110.035 24.8287 111.289 18.5553L115 0L107.231 5.32953C101.644 9.16222 95.931 13.1177 92.0628 18.6692C88.1944 24.2206 86.5058 31.7989 89.6139 37.8058"
            fill="#BCD6F6"
          />
          <path
            d="M90.1899 60.5865C89.426 54.8009 88.6405 48.941 89.1764 43.0934C89.6522 37.9 91.176 32.8276 94.278 28.6686C95.925 26.4651 97.928 24.5772 100.199 23.0898C100.791 22.7012 101.336 23.6768 100.746 24.0637C96.818 26.6455 93.779 30.4579 92.0684 34.9512C90.1791 39.948 89.8757 45.3949 90.2013 50.703C90.3982 53.9129 90.8154 57.1018 91.2359 60.2867C91.2691 60.4307 91.2484 60.5825 91.1781 60.7112C91.1078 60.84 90.993 60.9362 90.8571 60.9805C90.7184 61.0193 90.5706 60.9998 90.4457 60.926C90.3208 60.8523 90.2289 60.7303 90.1899 60.5865Z"
            fill="#F2F3F3"
          />
          <path
            d="M67 51.9525C66.0635 53.0813 64.858 53.945 63.4995 54.4603C62.1411 54.9756 60.6754 55.1253 59.2438 54.8948C55.5698 54.2589 52.8016 51.2461 50.3251 48.4046L43 40L48.4602 40.4061C52.3868 40.6981 56.4126 41.0154 59.9852 42.7037C63.5577 44.392 66.636 47.7727 66.8497 51.7844"
            fill="#BCD6F6"
          />
          <path
            d="M72.9897 62.6191C71.7318 59.7082 70.4637 56.7566 68.5339 54.1858C66.82 51.9027 64.6128 49.9733 61.8701 48.9173C60.4155 48.3587 58.8708 48.0483 57.3053 48.0002C56.8968 47.9873 56.8994 48.5963 57.3061 48.6091C60.0171 48.6961 62.6294 49.6088 64.7663 51.2155C67.1361 53.0059 68.8351 55.4561 70.1781 58.0201C70.9902 59.5707 71.6854 61.174 72.3778 62.7764C72.402 62.8529 72.4555 62.9176 72.5275 62.9576C72.5995 62.9977 72.6845 63.0099 72.7655 62.9919C72.8464 62.9706 72.9153 62.9196 72.9572 62.8498C72.9992 62.78 73.0108 62.6971 72.9897 62.6191Z"
            fill="#F2F3F3"
          />
          <path
            d="M98 50.2998C99.01 51.7971 100.399 53.0155 102.032 53.8376C103.665 54.6596 105.488 55.0576 107.325 54.9933C112.045 54.7747 115.98 51.5619 119.522 48.5116L130 39.4895L123.066 39.1659C118.079 38.9332 112.963 38.7161 108.213 40.2159C103.463 41.7157 99.083 45.3251 98.214 50.1199"
            fill="#BCD6F6"
          />
          <path
            d="M88.0726 64.2496C91.8109 57.5656 96.147 50.1372 103.895 47.763C106.049 47.105 108.308 46.8694 110.55 47.0686C111.257 47.1302 111.08 48.2307 110.375 48.1693C106.617 47.854 102.866 48.8567 99.754 51.0085C96.76 53.0682 94.428 55.9317 92.4547 58.9683C91.2459 60.8283 90.1631 62.7672 89.0802 64.7035C88.7341 65.3223 87.7225 64.8756 88.0726 64.2496Z"
            fill="#F2F3F3"
          />
          <path
            d="M56.0837 97C47.7196 97 38.1072 87.9883 29.6027 92.27C3.85307 105.234 7.3976 83.5408 0 75.473L0.24287 75C8.4053 82.1061 18.5964 87.8878 29.7145 91.7198C39.7617 95.1831 49.8843 96.7877 58.9842 96.3643L59 96.9328C58.039 96.9778 57.0669 97.0002 56.0837 97Z"
            fill="#F2F3F3"
          />
          <path
            d="M79.5 27C80.8807 27 82 25.8807 82 24.5C82 23.1193 80.8807 22 79.5 22C78.1193 22 77 23.1193 77 24.5C77 25.8807 78.1193 27 79.5 27Z"
            fill="#3183E3"
          />
          <path
            d="M52.5 115C54.9853 115 57 112.985 57 110.5C57 108.015 54.9853 106 52.5 106C50.0147 106 48 108.015 48 110.5C48 112.985 50.0147 115 52.5 115Z"
            fill="#F2F3F3"
          />
          <path
            d="M168.735 77.2929C162.12 82.3866 148.958 80.7774 144.874 89.5027C132.507 115.92 121.928 95.7964 111.1 93.6202L111 93.0806C121.839 93.9946 133.466 92.5764 144.623 88.9789C154.706 85.7283 163.701 80.8925 170.637 75L171 75.4612C170.268 76.0837 169.513 76.6943 168.735 77.2929Z"
            fill="#F2F3F3"
          />
          <path
            d="M91.5311 50.5149L95.596 50.4834L95.569 47.0166C95.565 46.4812 95.344 45.9597 94.962 45.5847C94.58 45.2085 94.056 44.9959 93.5206 44.9999L73.4769 45.1565C72.9439 45.1614 72.4213 45.3819 72.045 45.7628C71.6687 46.1442 71.458 46.6689 71.4617 47.2048L71.4899 50.6714L75.5528 50.6401L75.5411 49.2058L91.5194 49.0809L91.5311 50.5149Z"
            fill="#3183E3"
          />
          <path
            d="M104.05 71.0035L98.478 109.811C98.308 110.981 97.224 111.795 96.052 111.626C94.879 111.459 94.066 110.372 94.235 109.201L99.807 70.3936C99.977 69.223 101.062 68.4103 102.234 68.579C103.406 68.7475 104.22 69.8317 104.05 71.0035ZM84.2102 111.965C83.0259 111.975 82.0584 111.022 82.0509 109.839L81.7435 70.6352C81.736 69.4512 82.6876 68.4853 83.869 68.4765C85.0545 68.4666 86.022 69.4181 86.0299 70.6016L86.3375 109.805C86.345 110.989 85.3933 111.956 84.2102 111.965ZM72.364 111.811C71.1956 111.997 70.0964 111.201 69.9119 110.034L63.7328 71.3188C63.546 70.1485 64.3412 69.0518 65.512 68.8658C66.68 68.6792 67.7785 69.4738 67.9641 70.6429L74.1428 109.358C74.3296 110.526 73.5325 111.625 72.364 111.811ZM56.8569 63.7119C56.2167 63.7174 55.6107 64.0064 55.2044 64.5018C54.7982 64.9968 54.6335 65.6478 54.7529 66.2761L63.753 113.247C63.9496 114.271 64.8479 115.008 65.8895 115L102.258 114.715C103.294 114.707 104.18 113.963 104.365 112.944L112.948 65.8362C113.063 65.2066 112.888 64.5511 112.476 64.0606C112.065 63.5698 111.448 63.2861 110.808 63.2917L56.8569 63.7119Z"
            fill="#1B6CCA"
          />
          <path
            d="M52.0509 61.7065L115.582 61.2106L115.531 54.7399L109.638 54.7857L109.613 51.3827C109.608 51.1167 109.5 50.8575 109.311 50.6729C109.122 50.4857 108.86 50.3803 108.596 50.3818L95.596 50.4834L91.5311 50.5149L75.5528 50.6401L71.4899 50.6714L59.3704 50.766C59.1057 50.7682 58.8458 50.8775 58.6615 51.0674C58.4739 51.2555 58.3679 51.5165 58.3698 51.7824L58.3955 55.1866L52 55.2362L52.0509 61.7065Z"
            fill="#3183E3"
          />
          <path
            d="M83.869 68.4765C82.6876 68.4853 81.736 69.4512 81.7435 70.6352L82.0509 109.839C82.0584 111.022 83.0259 111.975 84.2102 111.965C85.3933 111.956 86.345 110.989 86.3375 109.805L86.0299 70.6016C86.022 69.4181 85.0545 68.4666 83.869 68.4765Z"
            fill="white"
          />
          <path
            d="M99.808 70.3935L94.235 109.201C94.065 110.372 94.879 111.459 96.052 111.626C97.224 111.794 98.308 110.981 98.478 109.811L104.05 71.0035C104.22 69.8317 103.406 68.7475 102.234 68.579C101.062 68.4102 99.977 69.2229 99.808 70.3935Z"
            fill="white"
          />
          <path
            d="M65.512 68.8658C64.3412 69.0518 63.546 70.1485 63.7328 71.3188L69.9121 110.035C70.0966 111.202 71.1955 111.997 72.364 111.811C73.5325 111.625 74.3296 110.526 74.1428 109.358L67.9641 70.6429C67.7785 69.4738 66.68 68.6792 65.512 68.8658Z"
            fill="white"
          />
        </g>
      </svg>
    </>
  )
}

export default Deletesvg
