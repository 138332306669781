export const SwarmPlotData = [
  {
    id: "0.0",
    group: "group C",
    price: 40,
    volume: 18,
    tooltipData: {
      current: {
        price: {
          value: 40,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 80,
          format: "Currency",
        },
        volume: {
          value: 180,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.1",
    group: "group B",
    price: 384,
    volume: 19,
    tooltipData: {
      current: {
        price: {
          value: 384,
          format: "Currency",
        },
        volume: {
          value: 19,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 364,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.2",
    group: "group B",
    price: 182,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 182,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.3",
    group: "group A",
    price: 270,
    volume: 10,
    tooltipData: {
      current: {
        price: {
          value: 270,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 8,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.4",
    group: "group B",
    price: 257,
    volume: 5,
    tooltipData: {
      current: {
        price: {
          value: 257,
          format: "Currency",
        },
        volume: {
          value: 5,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.5",
    group: "group C",
    price: 307,
    volume: 14,
    tooltipData: {
      current: {
        price: {
          value: 307,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 250,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.6",
    group: "group C",
    price: 0,
    volume: 13,
    tooltipData: {
      current: {
        price: {
          value: 0,
          format: "Currency",
        },
        volume: {
          value: 13,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 100,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.7",
    group: "group A",
    price: 394,
    volume: 7,
    tooltipData: {
      current: {
        price: {
          value: 394,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 6,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.8",
    group: "group C",
    price: 339,
    volume: 13,
    tooltipData: {
      current: {
        price: {
          value: 339,
          format: "Currency",
        },
        volume: {
          value: 13,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 350,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.9",
    group: "group A",
    price: 296,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 296,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 290,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.0",
    group: "group A",
    price: 285,
    volume: 6,
    tooltipData: {
      current: {
        price: {
          value: 285,
          format: "Currency",
        },
        volume: {
          value: 6,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 275,
          format: "Currency",
        },
        volume: {
          value: 5,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.1",
    group: "group B",
    price: 134,
    volume: 16,
    tooltipData: {
      current: {
        price: {
          value: 134,
          format: "Currency",
        },
        volume: {
          value: 16,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 130,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.2",
    group: "group C",
    price: 164,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 164,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 160,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.3",
    group: "group C",
    price: 50,
    volume: 18,
    tooltipData: {
      current: {
        price: {
          value: 50,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 70,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.4",
    group: "group A",
    price: 195,
    volume: 17,
    tooltipData: {
      current: {
        price: {
          value: 195,
          format: "Currency",
        },
        volume: {
          value: 17,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 190,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.5",
    group: "group A",
    price: 145,
    volume: 10,
    tooltipData: {
      current: {
        price: {
          value: 145,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 9,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.0",
    group: "group C",
    price: 40,
    volume: 18,
    tooltipData: {
      current: {
        price: {
          value: 40,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 80,
          format: "Currency",
        },
        volume: {
          value: 180,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.1",
    group: "group B",
    price: 384,
    volume: 19,
    tooltipData: {
      current: {
        price: {
          value: 384,
          format: "Currency",
        },
        volume: {
          value: 19,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 364,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.2",
    group: "group B",
    price: 182,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 182,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.3",
    group: "group A",
    price: 270,
    volume: 10,
    tooltipData: {
      current: {
        price: {
          value: 270,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 8,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.4",
    group: "group B",
    price: 257,
    volume: 5,
    tooltipData: {
      current: {
        price: {
          value: 257,
          format: "Currency",
        },
        volume: {
          value: 5,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.5",
    group: "group C",
    price: 307,
    volume: 14,
    tooltipData: {
      current: {
        price: {
          value: 307,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 250,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.6",
    group: "group C",
    price: 0,
    volume: 13,
    tooltipData: {
      current: {
        price: {
          value: 0,
          format: "Currency",
        },
        volume: {
          value: 13,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 100,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.7",
    group: "group A",
    price: 394,
    volume: 7,
    tooltipData: {
      current: {
        price: {
          value: 394,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 6,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.8",
    group: "group C",
    price: 339,
    volume: 13,
    tooltipData: {
      current: {
        price: {
          value: 339,
          format: "Currency",
        },
        volume: {
          value: 13,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 350,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.9",
    group: "group A",
    price: 296,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 296,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 290,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.0",
    group: "group C",
    price: 50, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 50,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 80,
          format: "Currency",
        },
        volume: {
          value: 180,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.1",
    group: "group B",
    price: 400, // Updated price
    volume: 25, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 25,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 364,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.2",
    group: "group B",
    price: 200, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.3",
    group: "group A",
    price: 300, // Updated price
    volume: 15, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 8,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.4",
    group: "group B",
    price: 300, // Updated price
    volume: 10, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.5",
    group: "group C",
    price: 350, // Updated price
    volume: 16, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 350,
          format: "Currency",
        },
        volume: {
          value: 16,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 250,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.6",
    group: "group C",
    price: 50, // Updated price
    volume: 15, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 50,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 100,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.7",
    group: "group A",
    price: 400, // Updated price
    volume: 10, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 6,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.8",
    group: "group C",
    price: 400, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 350,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.9",
    group: "group A",
    price: 300, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 290,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.0",
    group: "group A",
    price: 300, // Updated price
    volume: 8, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 8,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 275,
          format: "Currency",
        },
        volume: {
          value: 5,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.1",
    group: "group B",
    price: 150, // Updated price
    volume: 22, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 22,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 130,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.2",
    group: "group C",
    price: 180, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 180,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 160,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.3",
    group: "group C",
    price: 60, // Updated price
    volume: 22, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 60,
          format: "Currency",
        },
        volume: {
          value: 22,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 70,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.4",
    group: "group A",
    price: 200, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 190,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.5",
    group: "group A",
    price: 150, // Updated price
    volume: 12, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 140,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.6",
    group: "group C",
    price: 300, // Updated price
    volume: 16, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 16,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 250,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.7",
    group: "group A",
    price: 400, // Updated price
    volume: 25, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 25,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 380,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
    },
  },
]
// export const SwarmPlotData = [
//   {
//     id: "0.0",
//     group: "group A",
//     price: 369,
//     volume: 6,
//   },
//   {
//     id: "0.1",
//     group: "group A",
//     price: 143,
//     volume: 5,
//   },
//   {
//     id: "0.2",
//     group: "group A",
//     price: 277,
//     volume: 12,
//   },
//   {
//     id: "0.3",
//     group: "group A",
//     price: 69,
//     volume: 18,
//   },
//   {
//     id: "0.4",
//     group: "group A",
//     price: 441,
//     volume: 11,
//   },
//   {
//     id: "0.5",
//     group: "group A",
//     price: 306,
//     volume: 6,
//   },
//   {
//     id: "0.6",
//     group: "group A",
//     price: 421,
//     volume: 13,
//   },
//   {
//     id: "0.7",
//     group: "group A",
//     price: 50,
//     volume: 6,
//   },
//   {
//     id: "0.8",
//     group: "group A",
//     price: 456,
//     volume: 12,
//   },
//   {
//     id: "0.9",
//     group: "group A",
//     price: 435,
//     volume: 18,
//   },
//   {
//     id: "0.10",
//     group: "group A",
//     price: 374,
//     volume: 11,
//   },
//   {
//     id: "0.11",
//     group: "group A",
//     price: 70,
//     volume: 6,
//   },
//   {
//     id: "0.12",
//     group: "group A",
//     price: 104,
//     volume: 15,
//   },
//   {
//     id: "0.13",
//     group: "group A",
//     price: 193,
//     volume: 19,
//   },
//   {
//     id: "0.14",
//     group: "group A",
//     price: 27,
//     volume: 6,
//   },
//   {
//     id: "0.15",
//     group: "group A",
//     price: 458,
//     volume: 6,
//   },
//   {
//     id: "0.16",
//     group: "group A",
//     price: 67,
//     volume: 19,
//   },
//   {
//     id: "0.17",
//     group: "group A",
//     price: 15,
//     volume: 11,
//   },
//   {
//     id: "0.18",
//     group: "group A",
//     price: 470,
//     volume: 20,
//   },
//   {
//     id: "0.19",
//     group: "group A",
//     price: 409,
//     volume: 14,
//   },
//   {
//     id: "0.20",
//     group: "group A",
//     price: 182,
//     volume: 17,
//   },
//   {
//     id: "0.21",
//     group: "group A",
//     price: 408,
//     volume: 16,
//   },
//   {
//     id: "0.22",
//     group: "group A",
//     price: 102,
//     volume: 11,
//   },
//   {
//     id: "0.23",
//     group: "group A",
//     price: 234,
//     volume: 11,
//   },
//   {
//     id: "0.24",
//     group: "group A",
//     price: 75,
//     volume: 16,
//   },
//   {
//     id: "0.25",
//     group: "group A",
//     price: 155,
//     volume: 4,
//   },
//   {
//     id: "0.26",
//     group: "group A",
//     price: 46,
//     volume: 20,
//   },
//   {
//     id: "0.27",
//     group: "group A",
//     price: 445,
//     volume: 7,
//   },
//   {
//     id: "0.28",
//     group: "group A",
//     price: 449,
//     volume: 18,
//   },
//   {
//     id: "0.29",
//     group: "group A",
//     price: 45,
//     volume: 20,
//   },
//   {
//     id: "0.30",
//     group: "group A",
//     price: 443,
//     volume: 13,
//   },
//   {
//     id: "0.31",
//     group: "group A",
//     price: 136,
//     volume: 8,
//   },
//   {
//     id: "0.32",
//     group: "group A",
//     price: 471,
//     volume: 20,
//   },
//   {
//     id: "0.33",
//     group: "group A",
//     price: 385,
//     volume: 15,
//   },
//   {
//     id: "0.34",
//     group: "group A",
//     price: 162,
//     volume: 17,
//   },
//   {
//     id: "0.35",
//     group: "group A",
//     price: 333,
//     volume: 7,
//   },
//   {
//     id: "0.36",
//     group: "group A",
//     price: 477,
//     volume: 7,
//   },
//   {
//     id: "0.37",
//     group: "group A",
//     price: 309,
//     volume: 20,
//   },
//   {
//     id: "0.38",
//     group: "group A",
//     price: 135,
//     volume: 16,
//   },
//   {
//     id: "0.39",
//     group: "group A",
//     price: 80,
//     volume: 14,
//   },
//   {
//     id: "0.40",
//     group: "group A",
//     price: 206,
//     volume: 7,
//   },
//   {
//     id: "0.41",
//     group: "group A",
//     price: 200,
//     volume: 19,
//   },
//   {
//     id: "0.42",
//     group: "group A",
//     price: 107,
//     volume: 10,
//   },
//   {
//     id: "0.43",
//     group: "group A",
//     price: 253,
//     volume: 11,
//   },
//   {
//     id: "0.44",
//     group: "group A",
//     price: 41,
//     volume: 12,
//   },
//   {
//     id: "0.45",
//     group: "group A",
//     price: 488,
//     volume: 5,
//   },
//   {
//     id: "0.46",
//     group: "group A",
//     price: 472,
//     volume: 20,
//   },
//   {
//     id: "0.47",
//     group: "group A",
//     price: 343,
//     volume: 20,
//   },
//   {
//     id: "0.48",
//     group: "group A",
//     price: 116,
//     volume: 6,
//   },
//   {
//     id: "0.49",
//     group: "group A",
//     price: 493,
//     volume: 20,
//   },
//   {
//     id: "0.50",
//     group: "group A",
//     price: 367,
//     volume: 9,
//   },
//   {
//     id: "0.51",
//     group: "group A",
//     price: 426,
//     volume: 4,
//   },
//   {
//     id: "0.52",
//     group: "group A",
//     price: 209,
//     volume: 18,
//   },
//   {
//     id: "0.53",
//     group: "group A",
//     price: 198,
//     volume: 20,
//   },
//   {
//     id: "0.54",
//     group: "group A",
//     price: 368,
//     volume: 9,
//   },
//   {
//     id: "0.55",
//     group: "group A",
//     price: 336,
//     volume: 16,
//   },
//   {
//     id: "0.56",
//     group: "group A",
//     price: 452,
//     volume: 18,
//   },
//   {
//     id: "0.57",
//     group: "group A",
//     price: 94,
//     volume: 5,
//   },
//   {
//     id: "0.58",
//     group: "group A",
//     price: 317,
//     volume: 6,
//   },
//   {
//     id: "0.59",
//     group: "group A",
//     price: 11,
//     volume: 14,
//   },
//   {
//     id: "0.60",
//     group: "group A",
//     price: 10,
//     volume: 18,
//   },
//   {
//     id: "0.61",
//     group: "group A",
//     price: 330,
//     volume: 20,
//   },
//   {
//     id: "0.62",
//     group: "group A",
//     price: 356,
//     volume: 7,
//   },
//   {
//     id: "0.63",
//     group: "group A",
//     price: 275,
//     volume: 18,
//   },
//   {
//     id: "0.64",
//     group: "group A",
//     price: 145,
//     volume: 20,
//   },
//   {
//     id: "0.65",
//     group: "group A",
//     price: 409,
//     volume: 7,
//   },
//   {
//     id: "0.66",
//     group: "group A",
//     price: 375,
//     volume: 15,
//   },
//   {
//     id: "0.67",
//     group: "group A",
//     price: 470,
//     volume: 9,
//   },
//   {
//     id: "0.68",
//     group: "group A",
//     price: 335,
//     volume: 8,
//   },
//   {
//     id: "0.69",
//     group: "group A",
//     price: 486,
//     volume: 15,
//   },
//   {
//     id: "1.0",
//     group: "group B",
//     price: 298,
//     volume: 13,
//   },
//   {
//     id: "1.1",
//     group: "group B",
//     price: 305,
//     volume: 19,
//   },
//   {
//     id: "1.2",
//     group: "group B",
//     price: 246,
//     volume: 17,
//   },
//   {
//     id: "1.3",
//     group: "group B",
//     price: 354,
//     volume: 20,
//   },
//   {
//     id: "1.4",
//     group: "group B",
//     price: 470,
//     volume: 20,
//   },
//   {
//     id: "1.5",
//     group: "group B",
//     price: 199,
//     volume: 14,
//   },
//   {
//     id: "1.6",
//     group: "group B",
//     price: 136,
//     volume: 9,
//   },
//   {
//     id: "1.7",
//     group: "group B",
//     price: 324,
//     volume: 5,
//   },
//   {
//     id: "1.8",
//     group: "group B",
//     price: 428,
//     volume: 4,
//   },
//   {
//     id: "1.9",
//     group: "group B",
//     price: 137,
//     volume: 13,
//   },
//   {
//     id: "1.10",
//     group: "group B",
//     price: 216,
//     volume: 9,
//   },
//   {
//     id: "1.11",
//     group: "group B",
//     price: 257,
//     volume: 10,
//   },
//   {
//     id: "1.12",
//     group: "group B",
//     price: 218,
//     volume: 19,
//   },
//   {
//     id: "1.13",
//     group: "group B",
//     price: 120,
//     volume: 6,
//   },
//   {
//     id: "1.14",
//     group: "group B",
//     price: 343,
//     volume: 16,
//   },
//   {
//     id: "1.15",
//     group: "group B",
//     price: 15,
//     volume: 18,
//   },
//   {
//     id: "1.16",
//     group: "group B",
//     price: 401,
//     volume: 13,
//   },
//   {
//     id: "1.17",
//     group: "group B",
//     price: 497,
//     volume: 9,
//   },
//   {
//     id: "1.18",
//     group: "group B",
//     price: 3,
//     volume: 19,
//   },
//   {
//     id: "1.19",
//     group: "group B",
//     price: 84,
//     volume: 13,
//   },
//   {
//     id: "1.20",
//     group: "group B",
//     price: 23,
//     volume: 15,
//   },
//   {
//     id: "1.21",
//     group: "group B",
//     price: 493,
//     volume: 6,
//   },
//   {
//     id: "1.22",
//     group: "group B",
//     price: 348,
//     volume: 19,
//   },
//   {
//     id: "1.23",
//     group: "group B",
//     price: 407,
//     volume: 19,
//   },
//   {
//     id: "1.24",
//     group: "group B",
//     price: 4,
//     volume: 7,
//   },
//   {
//     id: "1.25",
//     group: "group B",
//     price: 342,
//     volume: 10,
//   },
//   {
//     id: "1.26",
//     group: "group B",
//     price: 453,
//     volume: 4,
//   },
//   {
//     id: "1.27",
//     group: "group B",
//     price: 10,
//     volume: 10,
//   },
//   {
//     id: "1.28",
//     group: "group B",
//     price: 179,
//     volume: 7,
//   },
//   {
//     id: "1.29",
//     group: "group B",
//     price: 416,
//     volume: 9,
//   },
//   {
//     id: "1.30",
//     group: "group B",
//     price: 494,
//     volume: 11,
//   },
//   {
//     id: "1.31",
//     group: "group B",
//     price: 135,
//     volume: 8,
//   },
//   {
//     id: "1.32",
//     group: "group B",
//     price: 214,
//     volume: 18,
//   },
//   {
//     id: "1.33",
//     group: "group B",
//     price: 363,
//     volume: 9,
//   },
//   {
//     id: "1.34",
//     group: "group B",
//     price: 120,
//     volume: 20,
//   },
//   {
//     id: "1.35",
//     group: "group B",
//     price: 178,
//     volume: 17,
//   },
//   {
//     id: "1.36",
//     group: "group B",
//     price: 110,
//     volume: 20,
//   },
//   {
//     id: "1.37",
//     group: "group B",
//     price: 347,
//     volume: 16,
//   },
//   {
//     id: "1.38",
//     group: "group B",
//     price: 65,
//     volume: 14,
//   },
//   {
//     id: "1.39",
//     group: "group B",
//     price: 275,
//     volume: 10,
//   },
//   {
//     id: "1.40",
//     group: "group B",
//     price: 77,
//     volume: 17,
//   },
//   {
//     id: "1.41",
//     group: "group B",
//     price: 30,
//     volume: 18,
//   },
//   {
//     id: "1.42",
//     group: "group B",
//     price: 87,
//     volume: 14,
//   },
//   {
//     id: "1.43",
//     group: "group B",
//     price: 455,
//     volume: 7,
//   },
//   {
//     id: "1.44",
//     group: "group B",
//     price: 126,
//     volume: 15,
//   },
//   {
//     id: "1.45",
//     group: "group B",
//     price: 440,
//     volume: 17,
//   },
//   {
//     id: "1.46",
//     group: "group B",
//     price: 205,
//     volume: 18,
//   },
//   {
//     id: "1.47",
//     group: "group B",
//     price: 270,
//     volume: 6,
//   },
//   {
//     id: "1.48",
//     group: "group B",
//     price: 464,
//     volume: 14,
//   },
//   {
//     id: "1.49",
//     group: "group B",
//     price: 267,
//     volume: 13,
//   },
//   {
//     id: "1.50",
//     group: "group B",
//     price: 174,
//     volume: 10,
//   },
//   {
//     id: "1.51",
//     group: "group B",
//     price: 226,
//     volume: 14,
//   },
//   {
//     id: "1.52",
//     group: "group B",
//     price: 317,
//     volume: 11,
//   },
//   {
//     id: "1.53",
//     group: "group B",
//     price: 299,
//     volume: 8,
//   },
//   {
//     id: "1.54",
//     group: "group B",
//     price: 160,
//     volume: 19,
//   },
//   {
//     id: "1.55",
//     group: "group B",
//     price: 436,
//     volume: 18,
//   },
//   {
//     id: "1.56",
//     group: "group B",
//     price: 482,
//     volume: 12,
//   },
//   {
//     id: "1.57",
//     group: "group B",
//     price: 244,
//     volume: 19,
//   },
//   {
//     id: "1.58",
//     group: "group B",
//     price: 478,
//     volume: 5,
//   },
//   {
//     id: "1.59",
//     group: "group B",
//     price: 472,
//     volume: 12,
//   },
//   {
//     id: "1.60",
//     group: "group B",
//     price: 381,
//     volume: 11,
//   },
//   {
//     id: "1.61",
//     group: "group B",
//     price: 195,
//     volume: 12,
//   },
//   {
//     id: "1.62",
//     group: "group B",
//     price: 452,
//     volume: 19,
//   },
//   {
//     id: "1.63",
//     group: "group B",
//     price: 172,
//     volume: 7,
//   },
//   {
//     id: "1.64",
//     group: "group B",
//     price: 154,
//     volume: 17,
//   },
//   {
//     id: "1.65",
//     group: "group B",
//     price: 35,
//     volume: 7,
//   },
//   {
//     id: "1.66",
//     group: "group B",
//     price: 255,
//     volume: 20,
//   },
//   {
//     id: "1.67",
//     group: "group B",
//     price: 462,
//     volume: 5,
//   },
//   {
//     id: "1.68",
//     group: "group B",
//     price: 496,
//     volume: 10,
//   },
//   {
//     id: "1.69",
//     group: "group B",
//     price: 192,
//     volume: 16,
//   },
//   {
//     id: "1.70",
//     group: "group B",
//     price: 358,
//     volume: 19,
//   },
//   {
//     id: "1.71",
//     group: "group B",
//     price: 497,
//     volume: 4,
//   },
//   {
//     id: "1.72",
//     group: "group B",
//     price: 102,
//     volume: 15,
//   },
//   {
//     id: "1.73",
//     group: "group B",
//     price: 444,
//     volume: 13,
//   },
//   {
//     id: "1.74",
//     group: "group B",
//     price: 49,
//     volume: 18,
//   },
//   {
//     id: "1.75",
//     group: "group B",
//     price: 370,
//     volume: 7,
//   },
//   {
//     id: "1.76",
//     group: "group B",
//     price: 377,
//     volume: 5,
//   },
//   {
//     id: "2.0",
//     group: "group C",
//     price: 422,
//     volume: 9,
//   },
//   {
//     id: "2.1",
//     group: "group C",
//     price: 418,
//     volume: 11,
//   },
//   {
//     id: "2.2",
//     group: "group C",
//     price: 391,
//     volume: 7,
//   },
//   {
//     id: "2.3",
//     group: "group C",
//     price: 293,
//     volume: 9,
//   },
//   {
//     id: "2.4",
//     group: "group C",
//     price: 340,
//     volume: 5,
//   },
//   {
//     id: "2.5",
//     group: "group C",
//     price: 453,
//     volume: 18,
//   },
//   {
//     id: "2.6",
//     group: "group C",
//     price: 440,
//     volume: 19,
//   },
//   {
//     id: "2.7",
//     group: "group C",
//     price: 145,
//     volume: 8,
//   },
//   {
//     id: "2.8",
//     group: "group C",
//     price: 176,
//     volume: 4,
//   },
//   {
//     id: "2.9",
//     group: "group C",
//     price: 138,
//     volume: 5,
//   },
//   {
//     id: "2.10",
//     group: "group C",
//     price: 262,
//     volume: 12,
//   },
//   {
//     id: "2.11",
//     group: "group C",
//     price: 107,
//     volume: 20,
//   },
//   {
//     id: "2.12",
//     group: "group C",
//     price: 359,
//     volume: 5,
//   },
//   {
//     id: "2.13",
//     group: "group C",
//     price: 239,
//     volume: 8,
//   },
//   {
//     id: "2.14",
//     group: "group C",
//     price: 406,
//     volume: 8,
//   },
//   {
//     id: "2.15",
//     group: "group C",
//     price: 408,
//     volume: 4,
//   },
//   {
//     id: "2.16",
//     group: "group C",
//     price: 381,
//     volume: 10,
//   },
//   {
//     id: "2.17",
//     group: "group C",
//     price: 109,
//     volume: 19,
//   },
//   {
//     id: "2.18",
//     group: "group C",
//     price: 232,
//     volume: 8,
//   },
//   {
//     id: "2.19",
//     group: "group C",
//     price: 306,
//     volume: 4,
//   },
//   {
//     id: "2.20",
//     group: "group C",
//     price: 250,
//     volume: 6,
//   },
//   {
//     id: "2.21",
//     group: "group C",
//     price: 342,
//     volume: 10,
//   },
//   {
//     id: "2.22",
//     group: "group C",
//     price: 483,
//     volume: 5,
//   },
//   {
//     id: "2.23",
//     group: "group C",
//     price: 292,
//     volume: 10,
//   },
//   {
//     id: "2.24",
//     group: "group C",
//     price: 48,
//     volume: 14,
//   },
//   {
//     id: "2.25",
//     group: "group C",
//     price: 86,
//     volume: 8,
//   },
//   {
//     id: "2.26",
//     group: "group C",
//     price: 471,
//     volume: 19,
//   },
//   {
//     id: "2.27",
//     group: "group C",
//     price: 258,
//     volume: 8,
//   },
//   {
//     id: "2.28",
//     group: "group C",
//     price: 357,
//     volume: 5,
//   },
//   {
//     id: "2.29",
//     group: "group C",
//     price: 463,
//     volume: 18,
//   },
//   {
//     id: "2.30",
//     group: "group C",
//     price: 471,
//     volume: 20,
//   },
//   {
//     id: "2.31",
//     group: "group C",
//     price: 43,
//     volume: 11,
//   },
//   {
//     id: "2.32",
//     group: "group C",
//     price: 31,
//     volume: 14,
//   },
//   {
//     id: "2.33",
//     group: "group C",
//     price: 394,
//     volume: 7,
//   },
//   {
//     id: "2.34",
//     group: "group C",
//     price: 273,
//     volume: 8,
//   },
//   {
//     id: "2.35",
//     group: "group C",
//     price: 48,
//     volume: 4,
//   },
//   {
//     id: "2.36",
//     group: "group C",
//     price: 45,
//     volume: 19,
//   },
//   {
//     id: "2.37",
//     group: "group C",
//     price: 373,
//     volume: 11,
//   },
//   {
//     id: "2.38",
//     group: "group C",
//     price: 367,
//     volume: 14,
//   },
//   {
//     id: "2.39",
//     group: "group C",
//     price: 237,
//     volume: 7,
//   },
//   {
//     id: "2.40",
//     group: "group C",
//     price: 176,
//     volume: 10,
//   },
//   {
//     id: "2.41",
//     group: "group C",
//     price: 159,
//     volume: 13,
//   },
//   {
//     id: "2.42",
//     group: "group C",
//     price: 54,
//     volume: 17,
//   },
//   {
//     id: "2.43",
//     group: "group C",
//     price: 17,
//     volume: 14,
//   },
//   {
//     id: "2.44",
//     group: "group C",
//     price: 43,
//     volume: 7,
//   },
//   {
//     id: "2.45",
//     group: "group C",
//     price: 228,
//     volume: 11,
//   },
//   {
//     id: "2.46",
//     group: "group C",
//     price: 406,
//     volume: 4,
//   },
//   {
//     id: "2.47",
//     group: "group C",
//     price: 211,
//     volume: 14,
//   },
//   {
//     id: "2.48",
//     group: "group C",
//     price: 110,
//     volume: 17,
//   },
//   {
//     id: "2.49",
//     group: "group C",
//     price: 73,
//     volume: 8,
//   },
// ]

export const SwarmPlotParameters = {
  margin: { top: 50, right: 10, bottom: 50, left: 55 },
  // groups: ["Nice", "Vyahare", "Anusha"],
  groups: ["FNU LNU", "Anusha Vyawahare", "Urmila LNU", "Zara LNU"],
  identity: "id",
  // value: "price",
  value: "GrossSales",
  valueFormat: "$.2f",
  valueScale: { type: "linear", min: 0, max: 2000, reverse: false },
  // size: { key: "volume", values: [4, 20], sizes: [6, 6] },
  size: { key: "SalesQuantity", values: [1, 50], sizes: [8, 10] },
  spacing: 1,
  gap: 25,
  enableGridY: false,
  forceStrength: 0.2,
  simulationIterations: 260,
  colorBy: "id",
  borderColor: {
    from: "color",
    modifiers: [
      ["darker", 0],
      ["opacity", 2.1],
    ],
  },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 10,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Employee Name",
    legendPosition: "middle",
    legendOffset: 40,
    truncateTickAt: 0,
  },
  axisLeft: {
    orient: "left",
    tickSize: 10,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Sales By Employee",
    legendPosition: "middle",
    legendOffset: -50,
    truncateTickAt: 0,
  },
}
