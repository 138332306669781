import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import { useTheme } from "../../../../contexts/theme"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import RouteTitle from "../../../RouteTitle"
import CustomPaper from "../../../CustomPaper"

const UserProfileDetails = () => {
  const { theme } = useTheme()

  return (
    <>
      <RouteTitle title="User Profile Details" />
      <Stack padding="15px 30px 15px 30px" gap="20px">
        <CustomPaper
          customStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography color="inherit">Personal Information</Typography>
          <CustomPaper
            elevation={0}
            customStyles={{
              background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  sx={{
                    gap: "20px",
                  }}
                >
                  {[
                    { label: "Name", type: "text" },
                    { label: "Phone Number", type: "tel" },
                    { label: "Email Address", type: "email" },
                    { label: "Street Address", type: "text" },
                    { label: "City", type: "text" },
                    { label: "Apt / Suite No", type: "text" },
                    { label: "State", type: "text" },
                    { label: "Country", type: "text" },
                    { label: "Zip Code", type: "text" },
                    { label: "Rank", type: "number" },
                  ].map((field, index) => (
                    <Box
                      key={index}
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        gap: "10px",
                        minWidth: {
                          sx: "275px",
                          sm: "275px",
                          md: "275px",
                          lg: "375px",
                        },
                      }}
                    >
                      <label htmlFor={field.label}>{field.label}</label>
                      <input
                        type={field.type}
                        id={field.label}
                        name={field.label}
                        required
                        style={{
                          border: "2px solid #6382E9",
                          minHeight: "40px",
                          minWidth: "240px",
                          borderRadius: "12px",
                          paddingInline: "5px",
                          color:
                            theme.palette.mode === "dark" ? "white" : "black",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgba(13, 34, 102, 1)"
                              : "white",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <AccountCircleIcon sx={{ height: "17rem", width: "17rem" }} />
                </Box>
              </Grid>
            </Grid>
          </CustomPaper>
        </CustomPaper>
        <CustomPaper
          customStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography color="inherit">Other Information</Typography>
          <CustomPaper
            elevation={0}
            customStyles={{
              background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
            }}
          >
            <Box
              display="flex"
              flexWrap="wrap"
              sx={{
                gap: "20px",
              }}
            >
              {[
                { label: "Link", type: "text" },
                { label: "Contact Details", type: "text" },
                { label: "Password", type: "password" },
              ].map((field, index) => (
                <Box
                  key={index}
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    gap: "10px",
                    minWidth: {
                      sx: "275px",
                      sm: "275px",
                      md: "275px",
                      lg: "375px",
                    },
                  }}
                >
                  <label htmlFor={field.label}>{field.label}</label>
                  <input
                    type={field.type}
                    id={field.label}
                    name={field.label}
                    required
                    style={{
                      border: "2px solid #6382E9",
                      minHeight: "40px",
                      minWidth: "240px",
                      borderRadius: "12px",
                      paddingInline: "5px",
                      color: theme.palette.mode === "dark" ? "white" : "black",
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? "rgba(13, 34, 102, 1)"
                          : "white",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </CustomPaper>
          <Divider />
          <Box display="flex" gap="10px" justifyContent="flex-end">
            <Button
              variant="outlined"
              sx={{
                border: `1px solid ${theme.palette.accent}`,
                textTransform: "none",
                color: theme.palette.accent,
                borderRadius: "20px",
                "&:hover": {
                  border: `1px solid ${theme.palette.accent}`,
                  color: "",
                  boxShadow: "5px",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: theme.palette.accent,
                textTransform: "none",
                color: "white",
                borderRadius: "20px",
                "&:hover": {
                  bgcolor: theme.palette.accent,
                  boxShadow: "5px",
                },
              }}
            >
              save
            </Button>
          </Box>
        </CustomPaper>
      </Stack>
    </>
  )
}

export default UserProfileDetails
