import React, { useState, useEffect } from "react"
import Slider from "@mui/material/Slider"
import Typography from "@mui/material/Typography"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { useTheme } from "../../../contexts/theme"
import { styled } from "@mui/material/styles"
import CustomTooltip from "../../CustomTooltip"
import { useNavigate } from "react-router-dom"

const formatNumber = (value) => {
  return new Intl.NumberFormat().format(value)
}

const CustomTooltips = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "transparent",
    border: "none",
    padding: 0,
    fontSize: "inherit",
    boxShadow: "none",
    width: "auto",
    maxWidth: "none",
    overflowX: "visible",
    transform: "translateX(-50%)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "transparent",
    backgroundColor: "transparent",
    border: "none",
    margin: "0 auto",
    transform: "translateY(-50%)",
  },
})

const CustomValueLabelComponent = (props) => {
  const { children, open, item, showTooltipPrevious } = props

  const hasTooltipData = item && Object.keys(item).length > 0

  return hasTooltipData ? (
    <CustomTooltips
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={
        <CustomTooltip
          data={item}
          showCurrentPrevDate
          showTooltipPrevious={showTooltipPrevious}
        />
      }
      arrow
    >
      {children}
    </CustomTooltips>
  ) : (
    children
  )
}

const MuiProgressBar = ({
  item,
  defaultValue,
  maxValue,
  beforePointerColor,
  extractedParameters = {},
  tooltipData,
  enableNavigation,
  navigationPath,
  showBubble,
  showTooltipPrevious = true,
}) => {
  const { theme } = useTheme()
  const { margin } = extractedParameters

  const [sliderValue, setSliderValue] = useState(defaultValue)

  useEffect(() => {
    setSliderValue(defaultValue)
  }, [defaultValue])

  const handleMouseDown = (event) => {
    event.preventDefault()
  }

  const handleTouchStart = (event) => {
    event.preventDefault()
  }
  const navigate = useNavigate()

  const bubbleStyles = showBubble
    ? {}
    : {
        border: "none",
        height: "8px",
        width: "8px",
        boxShadow: "none",
      }

  return (
    <>
      <Typography
        variant="body2"
        sx={{ opacity: 0.7, cursor: enableNavigation ? "pointer" : "default" }}
        color={theme.palette.mode === "dark" ? "#fff" : "#333333"}
        onClick={() => {
          if (enableNavigation) {
            navigate(navigationPath, {
              state: { item },
            })
          }
        }}
      >
        {item?.title}
      </Typography>

      <Slider
        value={sliderValue}
        max={maxValue}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        aria-labelledby="progress-bar-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => formatNumber(value)}
        sx={{
          marginTop: "-5px",
          marginBottom: "5px",
          margin: { margin },
          height: "8px",
          "& .MuiSlider-track": {
            backgroundColor: beforePointerColor,
            border: "none",
          },
          "& .MuiSlider-thumb": {
            border: "4px solid #ffffffd1",
            backgroundColor: beforePointerColor,
            "&:hover": {
              boxShadow: `0px 0px 0px 6px ${beforePointerColor}30`,
            },
            ...bubbleStyles,
          },
          "& .MuiSlider-rail": {
            backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#333333",
          },
        }}
        components={{
          ValueLabel: (props) => (
            <CustomValueLabelComponent
              {...props}
              item={item}
              showTooltipPrevious={showTooltipPrevious}
              tooltipData={tooltipData}
            />
          ),
        }}
      />

      {/* <Typography variant="caption">
        Value: {formatNumber(sliderValue)} / {formatNumber(maxValue)}
      </Typography> */}
    </>
  )
}

export default MuiProgressBar
