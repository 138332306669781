import { useState } from "react"
import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Badge from "@mui/material/Badge"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { useTheme } from "../../../../contexts/theme"
import Logo from "../../../../assets/svgs/Logo"

const Notification = ({ count = null }) => {
  const { theme } = useTheme()
  const svgStrokeColor =
    theme.palette.mode === "light" ? theme.palette.accent : "white"

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  return (
    <>
      <Button
        size="large"
        aria-label={`show ${count} new notifications`}
        color="inherit"
        onClick={handleDrawerOpen}
      >
        <Badge badgeContent={count} color="error">
          <svg
            width="21"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M6.16699 18C6.82202 19.7478 8.58945 21 10.667 21C12.7445 21 14.512 19.7478 15.167 18"
              stroke={svgStrokeColor}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              stroke={svgStrokeColor}
              d="M17.4161 8.70957V8.00497C17.4161 4.13623 14.3944 1 10.667 1C6.93955 1 3.91786 4.13623 3.91786 8.00497V8.70957C3.91786 9.5552 3.67671 10.3818 3.22479 11.0854L2.11735 12.8095C1.10581 14.3843 1.87804 16.5249 3.63735 17.0229C8.23973 18.3257 13.0943 18.3257 17.6966 17.0229C19.456 16.5249 20.2282 14.3843 19.2166 12.8095L18.1092 11.0854C17.6573 10.3818 17.4161 9.5552 17.4161 8.70957Z"
              strokeWidth="2"
            />
          </svg>
        </Badge>
      </Button>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: {
            padding: "1.2rem",
            borderRadius: "10px 0px 0px 10px",
            backgroundColor:
              theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
            backgroundImage: "unset",
            width: "450px",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="h5" color="inherit" paddingY="1.2rem">
            Notification
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon color={svgStrokeColor} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              gap: 1,
            }}
          >
            <Typography variant="h4">Coming soon..</Typography>
            <Logo
              fill={theme.palette.text.primary}
              yfill={
                theme.palette.mode === "light" ? theme.palette.accent : "white"
              }
            />
          </Box>
        </Box>

        {/* <Stack rowGap="20px">
          <Alert
            icon={false}
            severity="success"
            sx={{
              borderLeft: "4px solid #32CD32",
              borderRadius: "10px",
            }}
          >
            There are pending orders that have not been processed. Ensure prompt
            handling to avoid delays.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="success"
            sx={{
              borderLeft: "4px solid #32CD32",
              borderRadius: "10px",
            }}
          >
            A customer is exhibiting unusual behaviour or dissatisfaction.
            Notify staff to provide better service
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
        </Stack> */}
      </Drawer>
    </>
  )
}

export default Notification
