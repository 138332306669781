import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

const ToggleBtn = ({ options, selectedOption, handleToggle, themeMode }) => {
  const getButtonStyles = (selected, current) => {
    return {
      borderRadius: 9,
      fontSize: 12,
      padding: "3px 12px",
      textTransform: "none",
      backgroundColor:
        selected === current ? "rgba(28, 69, 201, 1)" : "rgba(28, 69, 201, 0)",
      color:
        themeMode === "light" && selected !== current
          ? "rgba(29, 71, 206, 1)"
          : "rgba(255, 255, 255, 1)",
    }
  }

  return (
    <Box
      style={{
        backgroundColor:
          themeMode === "dark" ? "rgba(3, 8, 22, 1)" : "rgba(233, 237, 252, 1)",
        color:
          themeMode === "dark"
            ? "rgba(166, 166, 166, 1)"
            : "rgba(29, 71, 206, 1)",
        borderRadius: 14,
        padding: "5px 6px",
      }}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          onClick={(event) => {
            event.stopPropagation()
            handleToggle(option.value)
          }}
          style={getButtonStyles(selectedOption, option.value)}
        >
          {option.label}
        </Button>
      ))}
    </Box>
  )
}

export default ToggleBtn
