import {
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid"
import MuiPagination from "@mui/material/Pagination"

const Pagination = ({ page, onPageChange, className }) => {
  const apiRef = useGridApiContext()
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <MuiPagination
      className={className + " MuiPagination"}
      count={pageCount}
      shape="rounded"
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1)
      }}
      showFirstButton
      showLastButton
      sx={{
        "& .MuiButtonBase-root .MuiPaginationItem-root .Mui-selected": {
          backgroundColor: "transparent !important",
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        },
        ".MuiPaginationItem-root": {
          borderRadius: 0,
          backgroundColor: "transparent !important",
          "&:hover": {
            color: "accent",
            borderBottom: 1,
            borderColor: "accent",
          },
        },
        padding: "1.2rem 1.2rem 1.2rem 0rem",
        "& .Mui-selected": {
          backgroundColor: "transparent !important",
          "&:hover": {
            backgroundColor: "transparent !important",
          },
          borderRadius: 0,
          color: "accent",
          borderBottom: 2,
          borderColor: "accent",
        },
      }}
    />
  )
}

function CustomPagination(props) {
  return (
    <GridPagination
      ActionsComponent={Pagination}
      rowsPerPageOptions={[]}
      sx={{
        ".MuiTablePagination-displayedRows": {
          display: "none",
        },
        "& .MuiPaginationItem-page": {
          display: props.hidePageNumbers ? "none" : "flex",
        },
        "& .MuiPaginationItem-ellipsis": {
          display: props.hidePageNumbers ? "none" : "flex",
        },
        "& .MuiPagination-ul": {
          flexWrap: "nowrap",
        },
      }}
      {...props}
    />
  )
}

export default CustomPagination
