import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import "./styles.scss"
import { useLocation, useNavigate } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import CustomPaper from "../../../components/CustomPaper"
import ArrowRise from "../../../assets/svgs/ArrowRise"
import Star from "../../../assets/svgs/Star"
import JobInformation from "./components/JobInformation"
import PayrollSummary from "./components/PayrollSummary"
import TotalLeaves from "./components/TotalLeaves"
import IconButton from "@mui/material/IconButton"
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs"
import DeleteModal from "../../../components/DeleteModal"
import { useState } from "react"
import BarChart from "../../../components/charts/bar/BarChart"
import { setChartData } from "../../../redux/slices/employeeSlice"
import { useSelector } from "react-redux"
import Logo from "../../../assets/svgs/Logo"
import { Backdrop } from "@mui/material"

const InventoryDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  const { item } = location.state || {}
  const [open, setOpen] = useState(false)

  const deleteRecord = () => {
    setOpen(false)
  }

  const handleDelete = (event) => {
    event.stopPropagation()
    setOpen(true)
  }

  const handleEdit = () => {
    navigate(``)
  }

  const EmployeeDetailsCard = ({ title, children }) => (
    <Stack
      display="flex"
      flexDirection="column"
      // justifyContent="space-between"
      // height="100%"
    >
      <Typography color="inherit" variant="subtitle2">
        {title}
      </Typography>
      {children}
    </Stack>
  )

  return (
    <>
      <Box position="relative">
        <DeleteModal
          open={open}
          onCancel={() => setOpen(false)}
          onDelete={deleteRecord}
        />
        <Box p="30px 30px 15px 30px">
          <CustomBreadcrumbs
            breadcrumbItems={[
              {
                text: "All Product",
                // isActive: false,
                clickHandler: () => {
                  navigate("/employees/allemployees")
                },
              },
              {
                text: "Product Details",
                isActive: true,
              },
            ]}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          p="15px 30px 15px 30px"
        >
          <Typography component="div" variant="h6">
            Product Details
          </Typography>
          <Box>
            <IconButton size="small">
              <EditIcon sx={{ color: "inherit" }} onClick={handleEdit} />
            </IconButton>
            <IconButton size="small" onClick={handleDelete}>
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        </Box>
        <Stack gap="20px" px="30px" pb="20px">
          {/* personal Information */}
          <div className="primary-grid-container">
            <CustomPaper className="user-grid-content1 grid-col-span-3">
              <Box>
                {/* Product Information */}

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  borderBottom="2px solid #091743"
                >
                  <Typography mb="10px" color="inherit">
                    Product Information
                  </Typography>
                </Box>

                {/* Product Information Details */}

                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  gap="30px"
                  mt={1.5}
                >
                  {Object.entries({
                    productName: {
                      label: "Product Name",
                      value: item?.title || "Garlic 5Pcs",
                    },
                    productID: {
                      label: "Product ID",
                      value: "GAR123",
                    },
                    price: {
                      label: "Price",
                      value: "$3.99",
                    },
                    quantity: {
                      label: "Quantity",
                      value: "5pcs",
                    },
                    category: {
                      label: "Category",
                      value: "Vegetables",
                    },
                    supplier: {
                      label: "Supplier",
                      value: "Fresh Farms",
                    },
                    availabilityStatus: {
                      label: "Availability Status",
                      value: (
                        <Chip
                          size="small"
                          label="In Stock"
                          sx={{
                            backgroundColor: "rgba(80, 205, 137, 0.3)",
                            color: "rgb(80, 205, 137)",
                            maxWidth: "fit-content",
                          }}
                        />
                      ),
                    },
                  }).map(([key, value]) => (
                    <Stack
                      key={key}
                      rowGap="5px"
                      minWidth={{
                        sx: "180px",
                        sm: "180px",
                        md: "180px",
                        lg: "250px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="inherit"
                        sx={{
                          opacity: "70%",
                          fontSize: "0.85rem",
                        }}
                      >
                        {value.label}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="inherit"
                        sx={{ fontSize: "1.1rem" }}
                      >
                        {value.value}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </CustomPaper>
            <div className="secondary-grid-container">
              <div className="item-4">
                {[
                  {
                    title: "Product Name",
                    value: "Garlic",
                    message: "Freshly harvested, ready to use",
                  },
                  {
                    title: "Price",
                    value: "$3.99",
                    message: (
                      <>
                        Discount: 5% off <ArrowRise />
                      </>
                    ),
                  },
                  {
                    title: "Stock Status",
                    value: (
                      <Chip
                        size="small"
                        label="In Stock"
                        sx={{
                          backgroundColor: "rgba(80, 205, 137, 0.3)",
                          color: "rgb(80, 205, 137)",
                          maxWidth: "fit-content",
                        }}
                      />
                    ),
                  },
                  {
                    title: "Category",
                    value: "Vegetables",
                  },
                  {
                    title: "Supplier",
                    value: "Fresh Farms",
                  },
                  {
                    title: "Rating",
                    value: (
                      <>
                        4.7 <Star />
                      </>
                    ),
                    message: (
                      <>
                        85% positive reviews <ArrowRise />
                      </>
                    ),
                  },
                ].map((item, idx) => (
                  <CustomPaper
                    key={idx}
                    className={`small-grid-${idx}`}
                    watermarkBg={true}
                    customStyles={{ minHeight: "100%" }}
                  >
                    <EmployeeDetailsCard title={item.title}>
                      <Typography color="inherit" variant="h6">
                        {item.value}
                      </Typography>
                      <Typography
                        color="inherit"
                        style={{ fontSize: "0.75rem" }}
                      >
                        {item.message}
                      </Typography>
                    </EmployeeDetailsCard>
                  </CustomPaper>
                ))}
              </div>
            </div>
          </div>

          {/* job Information  */}
          <JobInformation />

          {/* payroll summray */}
          <PayrollSummary />

          {/* Total Leaves */}
          <TotalLeaves />

          {/* Monthly Leaves  */}
          <CustomPaper customStyles={{ mt: "1rem" }}>
            {/* <BarChart
            showLegend={false}
            comparisonPeriod={comparisonPeriod}
            setChartData={setChartData}
            title="Monthly Leaves"
          /> */}
          </CustomPaper>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            zIndex: 6,
          }}
        >
          <Backdrop
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#000000B0",
            }}
            open={true}
          >
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              height="100vh"
            >
              <Typography variant="h5" color="white">
                Coming soon...
              </Typography>
              <Logo width="177" height="80" />
            </Box>
          </Backdrop>
        </Box>
      </Box>
    </>
  )
}

export default InventoryDetails
