import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import IconButton from "@mui/material/IconButton"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import { useTheme } from "../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { setFilters } from "../redux/slices/filtersSlice"
import { styled } from "@mui/material/styles"
import { TransitionGroup } from "react-transition-group"
import Collapse from "@mui/material/Collapse"
import { getFiltersString, getFiltersSQL } from "../utils/utils"

const CustomInputLabel = styled(InputLabel)({
  "&.Mui-focused": {
    color: "#9e9e9e",
  },
})
const CustomSelect = styled(Select)({
  "& .MuiSelect-root.Mui-focused .MuiSelect-icon": {
    color: "#9e9e9e",
  },
})
const CustomTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#9e9e9e",
  },
})

function CustomFilter(props) {
  const [filters, updateFilters] = useState(
    useSelector((state) => {
      return state.filters?.[props.route]?.filters
    }) || []
  )

  const [buttonValidations, setButtonValidations] = useState({
    add: false,
    apply: true,
  })

  const columns = props.columns
    ? props.columns.map((col) => ({
        field: col.field,
        headerName: col.headerName,
      }))
    : []
  const { theme } = useTheme()
  const dispatch = useDispatch()

  const validateButtons = () => {
    const lastFilter = filters[filters.length - 1]

    const isValid =
      !lastFilter ||
      ((filters.length === 1 || lastFilter.logicOperator) &&
        lastFilter.columnField &&
        lastFilter.operator &&
        lastFilter.value)
    setButtonValidations({ add: !isValid, apply: !isValid })
  }

  useEffect(() => {
    if (filters.length === 0) {
      applyFilters()
      setButtonValidations({ add: false, apply: true })
    } else {
      validateButtons()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleAddFilter = () => {
    const lastFilter = filters[filters.length - 1]

    if (
      !lastFilter ||
      (lastFilter.columnField && lastFilter.operator && lastFilter.value)
    ) {
      updateFilters([
        ...filters,
        { logicOperator: "", columnField: "", operator: "", value: "" },
      ])
      // dispatch(
      //   setFilters({
      //     route: props.route,
      //     filters: [
      //       ...filters,
      //       { logicOperator: "", columnField: "", operator: "", value: "" },
      //     ],
      //   })
      // )
    }
  }

  const handleRemoveFilter = (index) => {
    updateFilters([...filters.slice(0, index), ...filters.slice(index + 1)])

    // dispatch(
    //   setFilters({
    //     route: props.route,
    //     filters: [...filters.slice(0, index), ...filters.slice(index + 1)],
    //   })
    // )
  }

  const handleFilterChange = (index, property, value) => {
    updateFilters([
      ...filters.slice(0, index),
      { ...filters[index], [property]: value },
      ...filters.slice(index + 1),
    ])
    // dispatch(
    //   setFilters({
    //     route: props.route,
    //     filters: [
    //       ...filters.slice(0, index),
    //       { ...filters[index], [property]: value },
    //       ...filters.slice(index + 1),
    //     ],
    //   })
    // )
  }

  const applyFilters = () => {
    console.log(
      props.route
        ? `Filters Data for ${props.route}`
        : "Route (props.route) is undefined",
      {
        route: props.route,
        filters: filters,
        filtersString: getFiltersString(filters),
        filtersSql: getFiltersSQL(filters),
      }
    )
    dispatch(
      setFilters({
        route: props.route,
        filters: filters,
        filtersString: getFiltersString(filters),
        filtersSql: getFiltersSQL(filters),
      })
    )
  }

  return (
    <Box p="1.2rem">
      <TransitionGroup>
        {filters.map((filter, index) => (
          <Collapse key={`filter-${index}`}>
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 2.2,
              }}
            >
              <IconButton onClick={() => handleRemoveFilter(index)}>
                <CloseIcon />
              </IconButton>

              {index !== 0 ? (
                <FormControl sx={{ minWidth: "80px" }} margin="none">
                  <CustomInputLabel
                    sx={{ left: "-14px" }}
                    id={`filter-${index}-logicOperator`}
                  >
                    Logic
                  </CustomInputLabel>
                  <CustomSelect
                    labelId={`filter-${index}-logicOperator`}
                    value={filter.logicOperator}
                    onChange={(e) =>
                      handleFilterChange(index, "logicOperator", e.target.value)
                    }
                    variant="standard"
                  >
                    <MenuItem value="AND">AND</MenuItem>
                    <MenuItem value="OR">OR</MenuItem>
                  </CustomSelect>
                </FormControl>
              ) : (
                <Box sx={{ minWidth: "80px" }} />
              )}

              <FormControl fullWidth margin="none">
                <CustomInputLabel
                  sx={{ left: "-14px" }}
                  id={`filter-${index}-column`}
                >
                  Column
                </CustomInputLabel>
                <CustomSelect
                  labelId={`filter-${index}-column`}
                  value={filter.columnField}
                  onChange={(e) =>
                    handleFilterChange(index, "columnField", e.target.value)
                  }
                  variant="standard"
                >
                  {columns.map((col) => (
                    <MenuItem key={col.field} value={col.field}>
                      {col.headerName}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>

              <FormControl fullWidth margin="none">
                <CustomInputLabel
                  sx={{ left: "-14px" }}
                  id={`filter-${index}-operator`}
                >
                  Operator
                </CustomInputLabel>
                <CustomSelect
                  labelId={`filter-${index}-operator`}
                  value={filter.operator}
                  onChange={(e) =>
                    handleFilterChange(index, "operator", e.target.value)
                  }
                  variant="standard"
                >
                  <MenuItem value="=">=</MenuItem>
                  <MenuItem value="!=">!=</MenuItem>
                  <MenuItem value="<">&lt;</MenuItem>
                  <MenuItem value=">">&gt;</MenuItem>
                  <MenuItem value="<=">&lt;=</MenuItem>
                  <MenuItem value=">=">&gt;=</MenuItem>
                  <MenuItem value="contains">contains</MenuItem>
                  <MenuItem value="startsWith">starts with</MenuItem>
                  <MenuItem value="endsWith">ends with</MenuItem>
                  <MenuItem value="doesNotContain">does not contain</MenuItem>
                  <MenuItem value="doesNotStartWith">
                    does not start with
                  </MenuItem>
                  <MenuItem value="doesNotEndWith">does not end with</MenuItem>
                  <MenuItem value="null">is null</MenuItem>
                  <MenuItem value="notNull">is not null</MenuItem>
                  <MenuItem value="in">in</MenuItem>
                  <MenuItem value="notIn">not in</MenuItem>
                  <MenuItem value="between">between</MenuItem>
                  <MenuItem value="notBetween">not between</MenuItem>
                </CustomSelect>
              </FormControl>

              <CustomTextField
                label="Value"
                sx={{ width: "100%" }}
                value={filter.value}
                onChange={(e) =>
                  handleFilterChange(index, "value", e.target.value)
                }
                variant="standard"
              />
            </Box>
          </Collapse>
        ))}
      </TransitionGroup>
      <Box display="flex" gap={1}>
        <Button
          variant="outline"
          disabled={buttonValidations.add}
          sx={{
            textTransform: "none",
            borderRadius: "20px",
            border: `1px solid ${theme.palette.accent}`,
            color: theme.palette.accent,
            "&:hover": {
              border: `1px solid ${theme.palette.accent}`,
            },
            "&:disabled": {
              border: `1px solid ${theme.palette.accent}80`,
              color: `${theme.palette.accent}80`,
            },
          }}
          endIcon={<AddIcon />}
          onClick={handleAddFilter}
        >
          Add Filter
        </Button>
        <Button
          variant="outline"
          disabled={buttonValidations.apply}
          sx={{
            textTransform: "none",
            borderRadius: "20px",
            border: `1px solid ${theme.palette.accent}`,
            color: theme.palette.accent,
            "&:hover": {
              border: `1px solid ${theme.palette.accent}`,
            },
            "&:disabled": {
              border: `1px solid ${theme.palette.accent}80`,
              color: `${theme.palette.accent}80`,
            },
          }}
          onClick={() => applyFilters()}
        >
          Apply Filters
        </Button>
      </Box>
    </Box>
  )
}

export default CustomFilter
