import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { Link } from "react-router-dom"
import { useRouteError } from "react-router-dom"
import { useTheme } from "../contexts/theme"

const NotFound = () => {
  const { theme } = useTheme()
  const error = useRouteError()
  console.warn(error)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Typography variant="h1" align="center" gutterBottom>
        Oops!
      </Typography>
      <Typography variant="subtitle1" color="inherit">
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography variant="subtitle1" color="inherit">
        <i>{error.statusText || error.message}</i>
      </Typography>
      <Button
        component={Link}
        to="/"
        variant="contained"
        // color={theme.palette.primary.main}
        sx={{ mt: 2 }}
      >
        Go Home
      </Button>
    </Box>
  )
}

export default NotFound
