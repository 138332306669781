import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { useTheme } from "../../../../../contexts/theme"
import CustomBreadcrumbs from "../../../../CustomBreadcrumbs"
import CustomPaper from "../../../../CustomPaper"
import PackageDetails from "./PackageDetails"
import { getData } from "../../../../../api/api"

const AllPackages = () => {
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode
  const navigate = useNavigate()
  const location = useLocation()
  const { entitySubscription } = location.state || {}

  const [corePackages, setCorePackages] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedPackageDetails, setselectedPackageDetails] = useState(null)
  useEffect(() => {
    const fetchPackages = async () => {
      setLoading(true)
      try {
        const response = await getData("subscription/packages", {})

        if (!response.success) {
          throw new Error("Failed to fetch packages")
        }

        setCorePackages(response.result)
      } catch (error) {
        console.error("Error fetching packages:", error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchPackages()
  }, [])

  const handleClickOpen = (config) => {
    setselectedPackageDetails(config)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Stack padding="30px 30px 15px 30px" gap="20px">
      <CustomBreadcrumbs
        breadcrumbItems={[
          {
            text: "My Subscription Plan",
            clickHandler: () => navigate("/subscriptionPlan"),
          },
          {
            text: "All Packages",
            isActive: true,
          },
        ]}
      />
      <Typography
        variant="body1"
        style={{
          textAlign: "left",
          marginTop: "0.5rem",
          fontSize: "20px",
          fontWeight: 400,
        }}
        color="inherit"
      >
        All Packages
      </Typography>
      <CustomPaper
        customStyles={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          background:
            ThemeMode === "dark"
              ? "var(--grade, linear-gradient(76deg, #153393 1.45%, #0D2266 95.77%))"
              : "#ffffff",
        }}
      >
        <Typography
          color="inherit"
          sx={{ fontSize: "20px", fontWeight: "700" }}
        >
          Core Packages
        </Typography>

        <Grid container spacing={2}>
          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="10px"
              minHeight="350px"
              width="100%"
            >
              <CircularProgress size={45} color="info" />
              <Typography variant="body2" color="inherit">
                Fetching All Packages, please wait...
              </Typography>
            </Box>
          ) : (
            corePackages.map((config) => {
              const isActivePackage =
                config?.uid ===
                entitySubscription?.PackageDetails[0]?.PackageUid

              const background = isActivePackage
                ? ThemeMode === "dark"
                  ? "linear-gradient(120deg, #FF7935 -28.02%, #0E256D 41.73%)"
                  : "linear-gradient(76deg, #D7E0FF 1.45%, #FFF 95.77%)"
                : ""

              return (
                <Grid item key={config.uid} xs={12} sm={6} md={3} lg={3}>
                  <CustomPaper
                    customStyles={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "5px",
                      height: "100%",
                      background: background, // Apply dynamic background here
                      border:
                        ThemeMode === "dark"
                          ? "1px solid var(--Primary-70, #0044B3)"
                          : "1px solid var(--Primary-20, #BCCAF6)",
                    }}
                  >
                    <Typography
                      color="inherit"
                      sx={{ fontSize: "18px", fontWeight: "700" }}
                    >
                      {config.PackageName}
                    </Typography>

                    <Box display="flex" flexWrap="wrap">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "1.5",
                              color:
                                ThemeMode === "dark" ? "#ffffff" : "#333333",
                              fontWeight: 400,
                              marginTop: "10px",
                            }}
                          >
                            {config.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>

                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        py: "0.1rem",
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.accent,
                        cursor: "pointer",
                        mt: 1,
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => handleClickOpen(config)}
                    >
                      View in Detail
                    </Typography>

                    {open && (
                      <PackageDetails
                        open={open}
                        handleClose={handleClose}
                        selectedPackageDetails={selectedPackageDetails}
                        entitySubscription={entitySubscription}
                        selectedPackageTierId={
                          entitySubscription?.PackageDetails[0]?.PackageTierUid
                        }
                      />
                    )}

                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                      mt={1}
                    >
                      <Button
                        disabled={
                          config?.uid ===
                          entitySubscription?.PackageDetails[0]?.PackageUid
                        }
                        sx={{
                          padding: "10px 16px",
                          height: "40px",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: 700,
                          textTransform: "none",
                          borderRadius: "24px",
                          border: `1px solid ${theme.palette.accent}`,
                          color: theme.palette.accent,
                          "&:hover": {
                            border: `1px solid ${theme.palette.accent}`,
                            color: "#fff",
                            backgroundColor: theme.palette.accent,
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "rgba(191, 191, 191, 1)",
                            color: "rgba(255, 255, 255, 1)",
                            border: `none`,
                          },
                        }}
                        onClick={() => handleClickOpen(config)}
                      >
                        {config?.uid ===
                        entitySubscription?.PackageDetails[0]?.PackageUid
                          ? "Subscribed"
                          : "Buy Now"}
                      </Button>
                    </Box>
                  </CustomPaper>
                </Grid>
              )
            })
          )}
        </Grid>
      </CustomPaper>
    </Stack>
  )
}

export default AllPackages
