import { createSlice } from "@reduxjs/toolkit"

export const inventorySlices = createSlice({
  name: "inventory",
  initialState: { inventory: null, inventory_uid: null, reports: [] },
  reducers: {
    loadData: (state, action) => {
      const { inventory, inventory_uid, reports } = action.payload
      state.inventory = inventory
      state.inventory_uid = inventory_uid
      state.reports = reports
    },
    setChartData: (state, action) => {
      const { reportUid, response, comparisonPeriod, viewBy } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )
      if (selectedReport) selectedReport.reportData = response
      if (selectedReport && comparisonPeriod)
        selectedReport.comparisonPeriod = comparisonPeriod
      if (selectedReport && viewBy) selectedReport.viewBy = viewBy
    },
  },
})

export const { loadData, setChartData } = inventorySlices.actions

export default inventorySlices.reducer
