import { useState } from "react"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Link from "@mui/material/Link"
import IconButton from "@mui/material/IconButton"
import Modal from "@mui/material/Modal"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "../contexts/theme"
import AnalyticaInfoIcon from "../assets/svgs/AnalyticaInfoIcon"
import CustomPaper from "./CustomPaper"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useSelector } from "react-redux"
import LineBarChart from "./charts/lineBar/LineBarChart"
import BarChart from "./charts/bar/BarChart"
import { postData, aiFeedbackCreate, aiFeedbackUpdate } from "../api/api"
import CircularProgress from "@mui/material/CircularProgress"
import LineChart from "./charts/line/LineChart"
import PieChart from "./charts/pie/PieChart"
import StackedBarChart from "./charts/stackedBar/StackedBarChart"
import DashLineChart from "./charts/dashLine/DashLineChart"
import HorizontalBarChart from "./charts/bar/horizontalBarGraph/HorizontalBarChart"
import ScatterPlotChart from "./charts/scatterplot/ScatterPlotChart"
import ProgressBar from "./charts/progressBar/ProgressBarChart"
import HeatMapChart from "./charts/heatmap/HeatMapChart"
import ThumbsDown from "@mui/icons-material/ThumbDownAlt"
import ThumbsUp from "@mui/icons-material/ThumbUpAlt"
import TextField from "@mui/material/TextField"
import SendIcon from "@mui/icons-material/Send"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Collapse from "@mui/material/Collapse"

const Analytica = ({
  description,
  component = "drawer",
  height = "38px",
  width = "39px",
  styleOverrides = {},
  chartDetails = {},
  response,
  reportType,
}) => {
  const { theme } = useTheme()
  const svgStrokeColor =
    theme.palette.mode === "light" ? theme.palette.accent : "white"
  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  const [searchResults, setSearchResults] = useState([])
  const [analysis, setAnalysis] = useState([])
  const [recommendation, setRecommendation] = useState([])
  const [annomiles, setAnnomiles] = useState([])

  const handleClick = (component) => {
    return {
      drawer: handleDrawerOpen,
      chatbot: handleScreenOneOpen,
      modal: handleScreenTwoOpen,
    }[component]
  }
  const [loading, setLoading] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const handleDrawerOpen = () => setDrawerOpen(true)
  const handleDrawerClose = () => setDrawerOpen(false)

  const [screenOneOpen, setScreenOneOpen] = useState(false)
  const handleScreenOneOpen = () => setScreenOneOpen(true)
  const handleScreenOneClose = () => setScreenOneOpen(false)

  const [screenTwoOpen, setScreenTwoOpen] = useState(false)
  const handleScreenTwoOpen = () => setScreenTwoOpen(true)
  const handleScreenTwoClose = () => {
    setScreenTwoOpen(false)
    setSearch("")
    setSearchResults([])
  }

  const [search, setSearch] = useState("")
  const [searchInitiated, setSearchInitiated] = useState(false)

  const handleSearchChange = (e) => setSearch(e.target.value)

  const fetchSearchResults = async () => {
    if (!search.trim()) {
      setSearchResults(["Please enter a question to get an answer."])
      return
    }

    setSearchInitiated(true)
    setLoading(true)

    try {
      const response = await postData("/copilot/liveassistant", {
        userPrompt: search,
        data: chartDetails?.apiResponse,
      })

      if (response?.success && response?.msg) {
        // Clean up the response message

        const analysis = response?.msg?.[0].Analysis?.trim() || ""
        const recommendation = response?.msg?.[1].Recommendations?.trim() || ""
        const anomalies = response?.msg?.[2].Anomalies?.trim() || ""

        const fname = localStorage.getItem("fname") || ""
        const lname = localStorage.getItem("lname") || ""
        const fullName = `${fname} ${lname}`
        const userData = JSON.parse(localStorage.getItem("user"))
        const userId = userData?.userId || null

        setSearchResults([response.msg])
        setAnalysis(analysis)
        setRecommendation(recommendation)
        setAnnomiles(anomalies)

        let feedbackCreationData = {
          userID: userId || 1,
          entityID: localStorage.getItem("entityId"),
          parentURL: window.location.href,
          question: search,
          fullAnswer:
            "Analysis: " +
            analysis?.replace(/'/g, "") +
            "/n" +
            "Recommendations: " +
            recommendation?.replace(/'/g, "") +
            "/n" +
            "Anomalies: " +
            anomalies?.replace(/'/g, ""),
          answerInsights: analysis?.replace(/'/g, ""),
          answerRecommendations: recommendation?.replace(/'/g, ""),
          answerAnomalies: anomalies?.replace(/'/g, ""),
          userName: fullName,
        }

        aiFeedbackCreate(feedbackCreationData).then(
          (feedbackCreationResponse) =>
            setCreateFeedbackResponse(feedbackCreationResponse)
        )
      } else {
        console.error("Unexpected response structure:", response)
        setSearchResults(["An unexpected error occurred."])
      }
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.data)
        setSearchResults(["An error occurred while fetching the data."])
      } else {
        console.error("Error:", error.message)
        setSearchResults(["An error occurred while fetching the data."])
      }
    } finally {
      setLoading(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      fetchSearchResults()
    }
  }

  const handleSearchClick = () => {
    fetchSearchResults()
  }

  const handleQuestionClick = (question) => {
    setSearch(question)
    fetchSearchResults()
  }

  const [createFeedbackResponse, setCreateFeedbackResponse] = useState(false)

  // analysis-start
  const [showAnalysisText, setAnalysisShowText] = useState(false)
  const [analysisfeedback, setAnalysisFeedback] = useState(null)
  const [analysisfeedbackText, setAnalysisFeedbackText] = useState("")
  const [analysisLoading, setAnalysisLoading] = useState(false)
  const [analysisFeedbackMessage, setAnalysisFeedbackMessage] = useState(false)
  const [analysisAlertOpen, setAnalysisAlertOpen] = useState(false)

  const handleAnalysisFeedback = (event, value) => {
    setAnalysisFeedback(value)
    setAnalysisShowText(!!value)
  }
  // analysis-end

  // recommendation-start
  const [showRecommendationText, setRecommendationShowText] = useState(false)
  const [recommendationfeedback, setRecommendationFeedback] = useState(null)
  const [recommendationfeedbackText, setRecommendationFeedbackText] =
    useState("")
  const [recommendationLoading, setRecommendationLoading] = useState(false)
  const [recommendationFeedbackMessage, setRecommendationFeedbackMessage] =
    useState(false)
  const [recommendationAlertOpen, setRecommendationAlertOpen] = useState(false)

  const handleRecommendationFeedback = (event, value) => {
    setRecommendationFeedback(value)
    setRecommendationShowText(!!value)
  }
  // recommendation-end

  // anomaly-start
  const [showAnomalyText, setAnomalyShowText] = useState(false)
  const [anomalyfeedback, setAnomalyFeedback] = useState(null)
  const [anomalyfeedbackText, setAnomalyFeedbackText] = useState("")
  const [anomalyLoading, setAnomalyLoading] = useState(false)
  const [anomalyFeedbackMessage, setAnomalyFeedbackMessage] = useState(false)
  const [anomalyAlertOpen, setAnomalyAlertOpen] = useState(false)

  const handleAnomalyFeedback = (event, value) => {
    setAnomalyFeedback(value)
    setAnomalyShowText(!!value)
  }
  // anomaly-end

  const handleFeedbackUpdate = (type, thumbs, comment) => {
    const enableLoading = {
      1: () => {
        setAnalysisLoading(true)
        setAnalysisFeedbackMessage(false)
      },
      2: () => {
        setRecommendationLoading(true)
        setRecommendationFeedbackMessage(false)
      },
      3: () => {
        setAnomalyLoading(true)
        setAnomalyFeedbackMessage(false)
      },
    }

    const disbaleLoading = {
      1: (success) => {
        setAnalysisLoading(false)
        setAnalysisShowText(false)
        setAnalysisFeedback(null)
        setAnalysisFeedbackText("")
        setAnalysisFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setAnalysisAlertOpen(true)
      },
      2: (success) => {
        setRecommendationLoading(false)
        setRecommendationShowText(false)
        setRecommendationFeedback(null)
        setRecommendationFeedbackText("")
        setRecommendationFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setRecommendationAlertOpen(true)
      },
      3: (success) => {
        setAnomalyLoading(false)
        setAnomalyShowText(false)
        setAnomalyFeedback(null)
        setAnomalyFeedbackText("")
        setAnomalyFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setAnomalyAlertOpen(true)
      },
    }

    enableLoading[type]()

    const feedbackId = createFeedbackResponse?.msg?.Id
    const like = thumbs === "like"

    if (feedbackId && type) {
      aiFeedbackUpdate(type, feedbackId, like, comment)
        .then(
          (feedbackUpdationResponse) => {
            disbaleLoading[type](true)
          }
          // Todo: Handle after successfull update
        )
        .catch((error) => {
          disbaleLoading[type](false)
        })
    } else {
      console.log("Error while submitting feedback")
    }
  }

  const getBackgroundColor = (component, theme) =>
    component === "chatbot"
      ? theme.palette.mode === "dark"
        ? "linear-gradient(75.66deg, #153393 0%, #0D2266 100%)"
        : "linear-gradient(75.66deg, #FAFFFA 0%, #FFFFFF 100%)"
      : "transparent"

  // const getBorder = (component, theme) =>
  //   component === "chatbot"
  //     ? theme.palette.mode === "light"
  //       ? "1px solid #11111150"
  //       : "none"
  //     : "none"

  const getShadow = (component, theme) =>
    component === "chatbot"
      ? theme.palette.mode === "light"
        ? "0 4px 8px #9C27B029"
        : "0 4px 8px rgba(0, 0, 0, 0.3)"
      : "none"

  return (
    <>
      <IconButton
        onClick={() => handleClick(component)()}
        sx={{
          zIndex: 2,
          position: "relative",
          marginLeft: "0px",
          bottom: "5px",
          right: "0px",
          left: "10px",
          p: "1px",
          background: (theme) => getBackgroundColor(component, theme),
          boxShadow: () => getShadow(component, theme),
          ...styleOverrides,
        }}
      >
        <AnalyticaInfoIcon
          mode={theme.palette.mode}
          height={height}
          width={width}
        />
      </IconButton>

      <Modal
        open={screenOneOpen}
        onClose={handleScreenOneClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomPaper
          customStyles={{
            position: "absolute",
            width: "80vw",
            minHeight: {
              xs: "75vh",
              sm: "75vh",
              md: "80vh",
              lg: "80vh",
              xl: "65vh",
            },
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(75.66deg, #153391 1.45%, #0D2266 95.77%)"
                : "#FBFBFE",
            boxShadow: 24,
          }}
        >
          <Stack height="100%" display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
              mb={1}
            >
              <Box />
              <IconButton onClick={handleScreenOneClose}>
                <CloseIcon
                  sx={{
                    color: theme.palette.accent,
                  }}
                />
              </IconButton>
            </Box>
            <Stack height="100%" display="flex" flexDirection="column">
              <Box
                flex={0.8}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box mb={1}>
                  <AnalyticaInfoIcon mode={theme.palette.mode} />
                </Box>
                <Typography variant="subtitle1" color="inherit">
                  How can we help you?
                </Typography>
                <Typography variant="subtitle2" color="inherit">
                  Lorem ipsum dolor sit amet consectetur.
                </Typography>
              </Box>
              <Box flex={0.2}>
                <Stack
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <Box display="flex" flexDirection="row" gap={2}>
                    {[
                      "Sales trend of past week and current week",
                      "Which menu items are trending or frequently ordered recently?",
                      "Can you recommend meal deals or combos based on my order history?",
                      "What are your most popular dishes",
                    ].map((question, idx) => (
                      <CustomPaper
                        key={idx}
                        customStyles={{
                          cursor: "pointer",
                          flex: 1,
                          outline: "1px solid #1C45C9",
                          // bgcolor: "#001D4D",
                          bgcolor:
                            theme.palette.mode === "dark"
                              ? "#1C3588"
                              : // ? "#071339" looks better than #1C3588
                                "#1b4cf00a",
                          "&:hover": { outline: 1, outlineColor: "#6382E9" },
                        }}
                        onClick={() => {
                          setSearch(question)
                          setScreenOneOpen(false)
                          setScreenTwoOpen(true)
                        }}
                      >
                        <Stack
                          display="flex"
                          flexDirection="column"
                          gap="5px"
                          justifyContent="space-between"
                          height="100%"
                        >
                          <Typography variant="body2" color="inherit">
                            {question}
                          </Typography>
                          <Typography
                            color="inherit"
                            fontSize="0.8rem"
                            sx={{
                              opacity: 0.8,
                              marginBottom: "-0.5em",
                            }}
                          >
                            Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                        </Stack>
                      </CustomPaper>
                    ))}
                  </Box>
                  <OutlinedInput
                    id="minValue"
                    name="minValue"
                    value={search}
                    fullWidth
                    onChange={handleSearchChange}
                    // placeholder="Why are my sales down on Jun 16 compared to previous"
                    placeholder="Ask question to Livelytics AI assistant"
                    sx={{
                      outline: "1px solid #6382E9",
                      borderRadius: "12px",
                      height: "40px",
                      minWidth: "240px",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#0D2266" : "#1b4cf00a",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Box spacing={1} mr="-5px">
                          <IconButton>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.00015 9.33203C9.10682 9.33203 10.0002 8.4387 10.0002 7.33203V3.33203C10.0002 2.22536 9.10682 1.33203 8.00015 1.33203C6.89349 1.33203 6.00015 2.22536 6.00015 3.33203V7.33203C6.00015 8.4387 6.89349 9.33203 8.00015 9.33203ZM11.9402 7.33203C11.6135 7.33203 11.3402 7.57203 11.2868 7.8987C11.0135 9.46537 9.64682 10.6654 8.00015 10.6654C6.35349 10.6654 4.98682 9.46537 4.71349 7.8987C4.66015 7.57203 4.38682 7.33203 4.06015 7.33203C3.65349 7.33203 3.33349 7.69203 3.39349 8.09203C3.72015 10.092 5.32015 11.6587 7.33349 11.9454V13.332C7.33349 13.6987 7.63349 13.9987 8.00015 13.9987C8.36682 13.9987 8.66682 13.6987 8.66682 13.332V11.9454C10.6802 11.6587 12.2802 10.092 12.6068 8.09203C12.6735 7.69203 12.3468 7.33203 11.9402 7.33203Z"
                                fill={theme.palette.accent}
                              />
                            </svg>
                          </IconButton>
                          <IconButton>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.33333 7.33333H7.80667L7.62 7.15333C8.27333 6.39333 8.66667 5.40667 8.66667 4.33333C8.66667 1.94 6.72667 0 4.33333 0C1.94 0 0 1.94 0 4.33333C0 6.72667 1.94 8.66667 4.33333 8.66667C5.40667 8.66667 6.39333 8.27333 7.15333 7.62L7.33333 7.80667V8.33333L10.6667 11.66L11.66 10.6667L8.33333 7.33333ZM4.33333 7.33333C2.67333 7.33333 1.33333 5.99333 1.33333 4.33333C1.33333 2.67333 2.67333 1.33333 4.33333 1.33333C5.99333 1.33333 7.33333 2.67333 7.33333 4.33333C7.33333 5.99333 5.99333 7.33333 4.33333 7.33333Z"
                                fill={theme.palette.accent}
                              />
                            </svg>
                          </IconButton>
                        </Box>
                      </InputAdornment>
                    }
                  />
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </CustomPaper>
      </Modal>

      <Modal
        open={screenTwoOpen}
        onClose={handleScreenTwoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
        }}
      >
        <CustomPaper
          customStyles={{
            position: "absolute",
            width: "50vw",
            minWidth: "400px",
            minHeight: "100vh",
            overflowY: "auto",
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(75.66deg, #153393 1.45%, #0D2266 95.77%)"
                : "#FBFBFE",
            boxShadow: 24,
            pt: 0,
          }}
        >
          <Stack spacing={1}>
            <Stack
              spacing={2}
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 5,
                background:
                  theme.palette.mode === "dark"
                    ? "linear-gradient(75.66deg, #153290 1.45%, #0D2266 95.77%)"
                    : "#FBFBFE",
                pt: "1.2rem",
                borderRadius: "0px 0px 12px 12px",
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
              >
                <Box>
                  <AnalyticaInfoIcon mode={theme.palette.mode} />
                </Box>
                <IconButton onClick={handleScreenTwoClose}>
                  <CloseIcon
                    sx={{
                      color: theme.palette.accent,
                    }}
                  />
                </IconButton>
              </Box>
              <OutlinedInput
                id="search-input"
                value={search}
                fullWidth
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                placeholder="Ask question to Livelytics AI assistant"
                sx={{
                  outline: "1px solid #6382E9",
                  borderRadius: "12px",
                  height: "40px",
                  minWidth: "240px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#0D2266" : "#1b4cf00a",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Box spacing={1} mr="-5px">
                      <IconButton>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00015 9.33203C9.10682 9.33203 10.0002 8.4387 10.0002 7.33203V3.33203C10.0002 2.22536 9.10682 1.33203 8.00015 1.33203C6.89349 1.33203 6.00015 2.22536 6.00015 3.33203V7.33203C6.00015 8.4387 6.89349 9.33203 8.00015 9.33203ZM11.9402 7.33203C11.6135 7.33203 11.3402 7.57203 11.2868 7.8987C11.0135 9.46537 9.64682 10.6654 8.00015 10.6654C6.35349 10.6654 4.98682 9.46537 4.71349 7.8987C4.66015 7.57203 4.38682 7.33203 4.06015 7.33203C3.65349 7.33203 3.33349 7.69203 3.39349 8.09203C3.72015 10.092 5.32015 11.6587 7.33349 11.9454V13.332C7.33349 13.6987 7.63349 13.9987 8.00015 13.9987C8.36682 13.9987 8.66682 13.6987 8.66682 13.332V11.9454C10.6802 11.6587 12.2802 10.092 12.6068 8.09203C12.6735 7.69203 12.3468 7.33203 11.9402 7.33203Z"
                            fill={theme.palette.accent}
                          />
                        </svg>
                      </IconButton>
                      <IconButton onClick={handleSearchClick}>
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.33333 7.33333H7.80667L7.62 7.15333C8.27333 6.39333 8.66667 5.40667 8.66667 4.33333C8.66667 1.94 6.72667 0 4.33333 0C1.94 0 0 1.94 0 4.33333C0 6.72667 1.94 8.66667 4.33333 8.66667C5.40667 8.66667 6.39333 8.27333 7.15333 7.62L7.33333 7.80667V8.33333L10.6667 11.66L11.66 10.6667L8.33333 7.33333ZM4.33333 7.33333C2.67333 7.33333 1.33333 5.99333 1.33333 4.33333C1.33333 2.67333 2.67333 1.33333 4.33333 1.33333C5.99333 1.33333 7.33333 2.67333 7.33333 4.33333C7.33333 5.99333 5.99333 7.33333 4.33333 7.33333Z"
                            fill={theme.palette.accent}
                          />
                        </svg>
                      </IconButton>
                    </Box>
                  </InputAdornment>
                }
              />
            </Stack>
            <Accordion
              elevation={0}
              sx={{
                "&:before": { backgroundColor: "transparent" },
                borderRadius: "10px",
                boxShadow: "unset",
                bgcolor:
                  theme.palette.mode === "dark" ? "#071339" : "#1b4cf00a",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#A4B5E9" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography color="inherit" variant="body1">
                  Frequently Asked Questions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection={{ sm: "row", md: "column" }}
                  gap={1}
                  flexWrap="wrap"
                  marginTop="-9px"
                >
                  {[
                    "What were my top-selling items last week compared to this week?",
                    "How did the weather affect my sales this week?",
                    "Are there any significant changes in customer demographics between this week and last week?",
                    "How effective were the marketing campaigns run this week compared to last week?",
                    "What are the peak hours of sales this week versus last week?",
                  ].map((question, idx) => (
                    <CustomPaper
                      key={idx}
                      customStyles={{
                        cursor: "pointer",
                        flex: 1,
                        outline: "1px solid #1C45C9",
                        // bgcolor: "#001D4D",
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? "#071339"
                            : "#1b4cf00a",
                        "&:hover": { outline: 1, outlineColor: "#6382E9" },
                      }}
                      onClick={() => handleQuestionClick(question)}
                    >
                      <Typography variant="body2" color="inherit">
                        {question}
                      </Typography>
                    </CustomPaper>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
            <CustomPaper
              customStyles={{
                bgcolor:
                  theme.palette.mode === "dark" ? "#071339" : "#1b4cf00a",
                overflow: "visible",
              }}
              innerCustomStyles={{ bgcolor: "unset" }}
            >
              <Stack display="flex" flexDirection="column" gap={2}>
                <Typography color="inherit" variant="body1">
                  Results
                </Typography>
                {Object.keys(chartDetails).length > 0 && (
                  <CustomPaper
                    customStyles={{ overflow: "visible" }}
                    innerCustomStyles={{ margin: 0 }}
                  >
                    {
                      {
                        LineBar: (
                          <LineBarChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            focus_mode={false}
                            showAiDropdown={false}
                          />
                        ),
                        Bar: (
                          <BarChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            focus_mode={false}
                            showLegend={chartDetails.showLegend || false}
                            showAiDropdown={false}
                          />
                        ),
                        Pie: (
                          <PieChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            showAiDropdown={false}
                          />
                        ),
                        Line: (
                          <LineChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            showAiDropdown={false}
                          />
                        ),
                        Heatmap: (
                          <HeatMapChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            showAiDropdown={false}
                          />
                        ),
                        ProgressBar: (
                          <ProgressBar
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            showAiDropdown={false}
                          />
                        ),
                        ScatterPlot: (
                          <ScatterPlotChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            showAiDropdown={false}
                          />
                        ),
                        DualHorizontalBar: (
                          <HorizontalBarChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            showAiDropdown={false}
                          />
                        ),
                        DashLine: (
                          <DashLineChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            showAiDropdown={false}
                          />
                        ),
                        StackedBar: (
                          <StackedBarChart
                            route={chartDetails.route}
                            setChartData={chartDetails.setChartData}
                            title={chartDetails.title}
                            description={chartDetails.description}
                            postUrl={chartDetails.postUrl}
                            reportUid={chartDetails.reportUid}
                            parameters={chartDetails.parameters}
                            dateRangeData={chartDetails.dateRangeData}
                            comparisonPeriod={chartDetails.comparisonPeriod}
                            showLegend={chartDetails.showLegend || false}
                            showAiDropdown={false}
                          />
                        ),
                      }[reportType]
                    }
                  </CustomPaper>
                )}
                <CustomPaper
                  customStyles={{
                    p: 1,
                    border: "unset",
                    boxShadow: "unset",
                    outline: "none",
                  }}
                  innerCustomStyles={{
                    border: "none",
                    borderRadius: "10px",
                  }}
                >
                  {!searchInitiated ? (
                    <Typography color="inherit">
                      Ask question to Livelytics AI assistant
                    </Typography>
                  ) : loading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p={2}
                    >
                      <CircularProgress size={45} color="info" />
                    </Box>
                  ) : searchResults.length > 0 ? (
                    searchResults.map((msg, idx) => (
                      <CustomPaper
                        key={idx}
                        customStyles={{
                          p: 0,
                          background: "transparent",
                          border: "unset",
                          boxShadow: "unset",
                        }}
                        innerCustomStyles={{
                          border: "none",
                          borderRadius: "10px",
                        }}
                      >
                        <Accordion
                          defaultExpanded
                          sx={{
                            "&:before": { backgroundColor: "transparent" },
                            background: "unset",
                            boxShadow: "unset",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            display="flex"
                            flexDirection="row"
                            alignContent="center"
                            sx={{
                              "& .MuiAccordionSummary-content": {
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                              },
                              m: 0,
                              p: 0,
                            }}
                          >
                            <Box
                              height="12px"
                              width="12px"
                              borderRadius="4px"
                              bgcolor="#FCBF49"
                            />
                            <Typography variant="body1" color="inherit">
                              Analysis
                            </Typography>
                            <Box
                              height="1px"
                              flexGrow={1}
                              sx={{ bgcolor: theme.palette.divider }}
                            />
                          </AccordionSummary>
                          <AccordionDetails sx={{ m: 0, p: 0 }}>
                            <Alert
                              severity="warning"
                              sx={{
                                borderLeft: `4px solid #FCBF49`,
                                borderRadius: "10px",
                              }}
                              icon={false}
                            >
                              <Stack
                                display="flex"
                                flexDirection="column"
                                gap={1}
                              >
                                <Typography
                                  variant="body2"
                                  color="inherit"
                                  sx={{ whiteSpace: "pre-wrap" }}
                                >
                                  {analysis}
                                </Typography>
                                {createFeedbackResponse && (
                                  <>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      {!!analysisFeedbackMessage ? (
                                        <Collapse in={analysisAlertOpen}>
                                          <Alert
                                            action={
                                              <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                  setAnalysisAlertOpen(false)
                                                }}
                                              >
                                                <CloseIcon fontSize="inherit" />
                                              </IconButton>
                                            }
                                            variant="outlined"
                                            severity={
                                              analysisFeedbackMessage?.severity
                                            }
                                          >
                                            {analysisFeedbackMessage?.message}
                                          </Alert>
                                        </Collapse>
                                      ) : (
                                        <div />
                                      )}
                                      <ToggleButtonGroup
                                        value={analysisfeedback}
                                        exclusive
                                        onChange={handleAnalysisFeedback}
                                        aria-label="feedback buttons"
                                      >
                                        <ToggleButton
                                          value="like"
                                          aria-label="good feedback"
                                        >
                                          <ThumbsUp fontSize="small" />
                                        </ToggleButton>
                                        <ToggleButton
                                          value="dislike"
                                          aria-label="bad feedback"
                                        >
                                          <ThumbsDown fontSize="small" />
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </Box>
                                    {showAnalysisText && (
                                      <TextField
                                        disabled={analysisLoading}
                                        hiddenLabel
                                        size="small"
                                        multiline
                                        rows={2}
                                        value={analysisfeedbackText}
                                        onChange={(e) =>
                                          setAnalysisFeedbackText(
                                            e.target.value
                                          )
                                        }
                                        placeholder="Please let us know your feedback on this response."
                                        sx={{
                                          "& .MuiInputBase-input": {
                                            fontSize: "0.8rem",
                                          },
                                          "& .MuiFormLabel-root": {
                                            fontSize: "0.8rem",
                                          },
                                          minWidth: "300px",
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                disabled={
                                                  analysisfeedback ===
                                                    "dislike" &&
                                                  [
                                                    "",
                                                    null,
                                                    undefined,
                                                  ].includes(
                                                    analysisfeedbackText
                                                  )
                                                }
                                                onClick={() =>
                                                  handleFeedbackUpdate(
                                                    1,
                                                    analysisfeedback,
                                                    analysisfeedbackText
                                                  )
                                                }
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                {analysisLoading ? (
                                                  <CircularProgress
                                                    size="20px"
                                                    sx={{
                                                      color:
                                                        theme.palette.mode ===
                                                        "dark"
                                                          ? "#FFFFFF"
                                                          : "#707070",
                                                    }}
                                                  />
                                                ) : (
                                                  <SendIcon fontSize="small" />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Stack>
                            </Alert>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          defaultExpanded
                          sx={{
                            "&:before": { backgroundColor: "transparent" },
                            background: "unset",
                            boxShadow: "unset",
                            borderColor: "#1C45C9",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                              px: 0,
                              mx: "20px",
                              borderTop: 1,
                              border: "unset",
                              "& .MuiAccordionSummary-content": {
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                              },
                              m: 0,
                              p: 0,
                            }}
                          >
                            <Box
                              height="12px"
                              width="12px"
                              borderRadius="4px"
                              bgcolor="#50CD89"
                            />
                            <Typography variant="body1" color="inherit">
                              Recommendation
                            </Typography>
                            <Box
                              height="1px"
                              flexGrow={1}
                              sx={{ bgcolor: theme.palette.divider }}
                            />
                          </AccordionSummary>
                          <AccordionDetails sx={{ m: 0, p: 0 }}>
                            <Alert
                              severity="success"
                              sx={{
                                borderLeft: `4px solid #50CD89`,
                                borderRadius: "10px",
                              }}
                              icon={false}
                            >
                              <Stack
                                display="flex"
                                flexDirection="column"
                                gap={1}
                              >
                                <Typography
                                  variant="body2"
                                  color="inherit"
                                  sx={{ whiteSpace: "pre-wrap" }}
                                >
                                  {recommendation}
                                </Typography>
                                {createFeedbackResponse && (
                                  <>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      {!!recommendationFeedbackMessage ? (
                                        <Collapse in={recommendationAlertOpen}>
                                          <Alert
                                            action={
                                              <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                  setRecommendationAlertOpen(
                                                    false
                                                  )
                                                }}
                                              >
                                                <CloseIcon fontSize="inherit" />
                                              </IconButton>
                                            }
                                            variant="outlined"
                                            severity={
                                              recommendationFeedbackMessage.severity
                                            }
                                          >
                                            {
                                              recommendationFeedbackMessage.message
                                            }
                                          </Alert>
                                        </Collapse>
                                      ) : (
                                        <div />
                                      )}
                                      <ToggleButtonGroup
                                        value={recommendationfeedback}
                                        exclusive
                                        onChange={handleRecommendationFeedback}
                                        aria-label="feedback buttons"
                                      >
                                        <ToggleButton
                                          value="like"
                                          aria-label="good feedback"
                                        >
                                          <ThumbsUp fontSize="small" />
                                        </ToggleButton>
                                        <ToggleButton
                                          value="dislike"
                                          aria-label="bad feedback"
                                        >
                                          <ThumbsDown fontSize="small" />
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </Box>
                                    {showRecommendationText && (
                                      <TextField
                                        disabled={recommendationLoading}
                                        hiddenLabel
                                        size="small"
                                        multiline
                                        rows={2}
                                        value={recommendationfeedbackText}
                                        onChange={(e) =>
                                          setRecommendationFeedbackText(
                                            e.target.value
                                          )
                                        }
                                        placeholder="Please let us know your feedback on this response."
                                        sx={{
                                          "& .MuiInputBase-input": {
                                            fontSize: "0.8rem",
                                          },
                                          "& .MuiFormLabel-root": {
                                            fontSize: "0.8rem",
                                          },
                                          minWidth: "300px",
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                disabled={
                                                  recommendationfeedback ===
                                                    "dislike" &&
                                                  [
                                                    "",
                                                    null,
                                                    undefined,
                                                  ].includes(
                                                    recommendationfeedbackText
                                                  )
                                                }
                                                onClick={() =>
                                                  handleFeedbackUpdate(
                                                    2,
                                                    recommendationfeedback,
                                                    recommendationfeedbackText
                                                  )
                                                }
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                {recommendationLoading ? (
                                                  <CircularProgress
                                                    size="20px"
                                                    sx={{
                                                      color:
                                                        theme.palette.mode ===
                                                        "dark"
                                                          ? "#FFFFFF"
                                                          : "#707070",
                                                    }}
                                                  />
                                                ) : (
                                                  <SendIcon fontSize="small" />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Stack>
                            </Alert>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          defaultExpanded
                          sx={{
                            "&:before": { backgroundColor: "transparent" },
                            background: "unset",
                            boxShadow: "unset",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                              px: 0,
                              mx: "20px",
                              borderTop: 1,
                              border: "unset",
                              "& .MuiAccordionSummary-content": {
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                              },
                              m: 0,
                              p: 0,
                            }}
                          >
                            <Box
                              height="12px"
                              width="12px"
                              borderRadius="4px"
                              bgcolor="#FF0000"
                            />
                            <Typography variant="body1" color="inherit">
                              Anomalies
                            </Typography>
                            <Box
                              height="1px"
                              flexGrow={1}
                              sx={{ bgcolor: theme.palette.divider }}
                            />
                          </AccordionSummary>
                          <AccordionDetails sx={{ m: 0, p: 0 }}>
                            <Alert
                              severity="error"
                              sx={{
                                borderLeft: `4px solid #FF0000`,
                                borderRadius: "10px",
                              }}
                              icon={false}
                            >
                              <Stack
                                display="flex"
                                flexDirection="column"
                                gap={1}
                              >
                                <Typography
                                  variant="body2"
                                  color="inherit"
                                  sx={{ whiteSpace: "pre-wrap" }}
                                >
                                  {annomiles}
                                </Typography>
                                {createFeedbackResponse && (
                                  <>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      {!!anomalyFeedbackMessage ? (
                                        <Collapse in={anomalyAlertOpen}>
                                          <Alert
                                            action={
                                              <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                  setAnomalyAlertOpen(false)
                                                }}
                                              >
                                                <CloseIcon fontSize="inherit" />
                                              </IconButton>
                                            }
                                            variant="outlined"
                                            severity={
                                              anomalyFeedbackMessage.severity
                                            }
                                          >
                                            {anomalyFeedbackMessage.message}
                                          </Alert>
                                        </Collapse>
                                      ) : (
                                        <div />
                                      )}
                                      <ToggleButtonGroup
                                        value={anomalyfeedback}
                                        exclusive
                                        onChange={handleAnomalyFeedback}
                                        aria-label="feedback buttons"
                                      >
                                        <ToggleButton
                                          value="like"
                                          aria-label="good feedback"
                                        >
                                          <ThumbsUp fontSize="small" />
                                        </ToggleButton>
                                        <ToggleButton
                                          value="dislike"
                                          aria-label="bad feedback"
                                        >
                                          <ThumbsDown fontSize="small" />
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </Box>
                                    {showAnomalyText && (
                                      <TextField
                                        disabled={anomalyLoading}
                                        hiddenLabel
                                        size="small"
                                        multiline
                                        rows={2}
                                        value={anomalyfeedbackText}
                                        onChange={(e) =>
                                          setAnomalyFeedbackText(e.target.value)
                                        }
                                        placeholder="Please let us know your feedback on this response."
                                        sx={{
                                          "& .MuiInputBase-input": {
                                            fontSize: "0.8rem",
                                          },
                                          "& .MuiFormLabel-root": {
                                            fontSize: "0.8rem",
                                          },
                                          minWidth: "300px",
                                          "& label.Mui-focused": {
                                            color: "none",
                                          },
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                disabled={
                                                  anomalyfeedback ===
                                                    "dislike" &&
                                                  [
                                                    "",
                                                    null,
                                                    undefined,
                                                  ].includes(
                                                    anomalyfeedbackText
                                                  )
                                                }
                                                onClick={() =>
                                                  handleFeedbackUpdate(
                                                    3,
                                                    anomalyfeedback,
                                                    anomalyfeedbackText
                                                  )
                                                }
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                {anomalyLoading ? (
                                                  <CircularProgress
                                                    size="20px"
                                                    sx={{
                                                      color:
                                                        theme.palette.mode ===
                                                        "dark"
                                                          ? "#FFFFFF"
                                                          : "#707070",
                                                    }}
                                                  />
                                                ) : (
                                                  <SendIcon fontSize="small" />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Stack>
                            </Alert>
                          </AccordionDetails>
                        </Accordion>
                      </CustomPaper>
                    ))
                  ) : (
                    <Typography color="inherit">No results found</Typography>
                  )}
                </CustomPaper>
              </Stack>
            </CustomPaper>
          </Stack>
        </CustomPaper>
      </Modal>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: {
            padding: "1.2rem",
            borderRadius: "10px 0px 0px 10px",
            backgroundColor:
              theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
            backgroundImage: "unset",
            width: "450px",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="h5" color="inherit" paddingY="1.2rem">
            AI-Recommendation
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon color={svgStrokeColor} />
          </IconButton>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography>{description}</Typography>
        </Box>
        <Stack rowGap="20px">
          <Alert
            icon={false}
            severity="error"
            sx={{
              borderLeft: "4px solid #EE000CCC",
              borderRadius: "10px",
            }}
          >
            Two employee schedules overlap. Please review and adjust the shifts
            to avoid conflicts.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="error"
            sx={{
              borderLeft: "4px solid #EE000CCC",
              borderRadius: "10px",
            }}
          >
            An employee is approaching their maximum allowable working hours.
            Ensure compliance with labor laws.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="warning"
            sx={{
              borderLeft: "4px solid #FFBF00",
              borderRadius: "10px",
            }}
          >
            An employee is late for their shift. Send a reminder or find a
            replacement.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="warning"
            sx={{
              borderLeft: "4px solid #FFBF00",
              borderRadius: "10px",
            }}
          >
            Negative feedback has been received from a customer. Address the
            issue promptly
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="success"
            sx={{
              borderLeft: "4px solid #32CD32",
              borderRadius: "10px",
            }}
          >
            A customer is exhibiting unusual behaviour or dissatisfaction.
            Notify staff to provide better service
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
          <Alert
            icon={false}
            severity="success"
            sx={{
              borderLeft: "4px solid #32CD32",
              borderRadius: "10px",
            }}
          >
            There are pending orders that have not been processed. Ensure prompt
            handling to avoid delays.
            <br />
            <Link
              href="#"
              underline="hover"
              sx={{ color: theme.palette.accent }}
            >
              Check Now →
            </Link>
          </Alert>
        </Stack>
      </Drawer>
    </>
  )
}

export default Analytica
