import RouteTitle from "../../components/RouteTitle"
import Table from "../../components/datagrid/Table"
import Box from "@mui/material/Box"
import DatePicker from "../../components/DatePicker"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const EmployeesList = () => {
  const dateRangeData = useSelector((state) => state.calendar)
  const navigate = useNavigate()
  const handleRowClick = () => {
    // navigate("/employees/allemployees/employee/1")
  }

  const filtersData = useSelector((state) => {
    return state.filters.employeesList
  })

  return (
    <>
      <RouteTitle title={` All Employees ${filtersData?.filtersString}`}>
        <DatePicker />
      </RouteTitle>
      <Box padding="15px 30px 30px 30px">
        <Table
          title="Employees"
          onRowClick={handleRowClick}
          dateRangeData={dateRangeData}
          reportUid="c4d60930-0cc1-4f51-b8bc-4206fa10dde8"
          postUrl="employee/list"
          parameters={[
            {
              parameterType: "COLUMNS",
              reportParameters: [
                {
                  parameterName: "columns",
                  parameterValue: [
                    {
                      field: "EmployeeID",
                      headerName: "Employee ID",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Name",
                      headerName: "Name",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Role",
                      headerName: "Role",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Email",
                      headerName: "Email",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Phone",
                      headerName: "Phone",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                  ],
                },
              ],
            },
          ]}
          route="employeesList"
          showExportButton={true}
          showFiltersButton={true}
        />
      </Box>
    </>
  )
}

export default EmployeesList
