import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import HappySentiments from "../../../assets/svgs/HappySentiments"
import SentimentNeutral from "../../../assets/svgs/SentimentNeutral"
import SentimentDissatisfied from "../../../assets/svgs/SentimentDissatisfied"

const RatingCard = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Box sx={{ mt: "10%" }}>
            <Typography whiteSpace={"nowrap"} sx={{ fontSize: "1rem" }}>
              Total Customers
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "2.6rem" }}>
              12.6k
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={4} textAlign="center">
              <HappySentiments />
              <Typography variant="body1">70 %</Typography>
            </Grid>

            <Grid item xs={4} textAlign="center">
              <SentimentNeutral />
              <Typography variant="body1">28 %</Typography>
            </Grid>

            <Grid item xs={4} textAlign="center">
              <SentimentDissatisfied />
              <Typography variant="body1">2 %</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default RatingCard
