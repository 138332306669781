import { useState } from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setCurrentPage } from "../../../../redux/slices/navigationSlice"
import { useTheme } from "../../../../contexts/theme"
import SubMenu from "../../../../assets/svgs/SubMenu"
import ListItemIcon from "@mui/material/ListItemIcon"
import MngIcon from "../../../../assets/svgs/MngIcon"
// import ConfigIcon from "../../../../assets/svgs/ConfigIcon"
import InterIcon from "../../../../assets/svgs/InterIcon"
import GraphIcon from "../../../../assets/svgs/GraphIcon"

const Left = () => {
  const [customersMenuAnchorEl, setCustomersMenuAnchorEl] = useState(null)
  const [vendorsMenuAnchorEl, setVendorsMenuAnchorEl] = useState(null)
  const [inventoryMenuAnchorEl, setInventoryMenuAnchorEl] = useState(null)
  const [employeesMenuAnchorEl, setEmployeesMenuAnchorEl] = useState(null)
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const activeButton = useSelector((state) => state.navigation.currentPage)
  const { theme } = useTheme()

  const btnStyles = (activeButton, menuItem, listItem) => {
    let isActive
    if (Array.isArray(menuItem)) {
      isActive = menuItem.includes(activeButton) || menuItem.includes(listItem)
    } else {
      isActive = activeButton === menuItem || activeButton === listItem
    }

    return {
      textTransform: "none",
      borderRadius: "0px",
      color: isActive
        ? theme.palette.accent
        : theme.palette.mode === "dark"
        ? "#CCCCCC"
        : "#000",
      borderBottom: isActive ? `2px solid ${theme.palette.accent}` : "none",
    }
  }

  const handleClick = (buttonName) => {
    dispatch(setCurrentPage(buttonName))
  }

  const handleMenuClick = (buttonName, event) => {
    switch (buttonName) {
      case "Customers":
        setCustomersMenuAnchorEl(event.currentTarget)
        break
      case "Vendors":
        setVendorsMenuAnchorEl(event.currentTarget)
        break
      case "Products / Item-PLU":
        setInventoryMenuAnchorEl(event.currentTarget)
        break
      case "Employees":
        setEmployeesMenuAnchorEl(event.currentTarget)
        break
      case "SubMenu":
        setSubMenuAnchorEl(event.currentTarget)
        break
      case "management":
        setSubMenuAnchorEl(event.currentTarget)
        break
      case "integration":
        setSubMenuAnchorEl(event.currentTarget)
        break
      default:
        break
    }
    handleClick(buttonName)
  }

  const handleClose = (buttonName) => {
    switch (buttonName) {
      case "Customers":
        setCustomersMenuAnchorEl(null)
        break
      case "Vendors":
        setVendorsMenuAnchorEl(null)
        break
      case "Products / Item-PLU":
        setInventoryMenuAnchorEl(null)
        break
      case "Employees":
        setEmployeesMenuAnchorEl(null)
        break
      case "SubMenu":
        setSubMenuAnchorEl(null)
        break
      case "management":
        setSubMenuAnchorEl(null)
        break
      case "integration":
        setSubMenuAnchorEl(null)
        break
      default:
        break
    }
  }

  const renderSubMenu = () => (
    <Menu
      id="sub-menu"
      anchorEl={subMenuAnchorEl}
      open={Boolean(subMenuAnchorEl)}
      onClose={() => handleClose("SubMenu")}
    >
      <MenuItem
        component={Link}
        to="/management"
        onClick={() => handleClose("management")}
      >
        <ListItemIcon>
          <MngIcon />
        </ListItemIcon>
        <Typography startIcon={<SubMenu />} variant="subtitle2" color="inherit">
          Management
        </Typography>
      </MenuItem>
      {/* <MenuItem component={Link} to="#" onClick={() => handleClose("SubMenu")}>
        <ListItemIcon>
          <ConfigIcon />
        </ListItemIcon>
        <Typography variant="subtitle2" color="inherit">
          Configuration
        </Typography>
      </MenuItem> */}
      <MenuItem
        component={Link}
        to="/integration"
        onClick={() => handleClose("integration")}
      >
        <ListItemIcon>
          <InterIcon />
        </ListItemIcon>
        <Typography variant="subtitle2" color="inherit">
          Integration
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/submenu/marketing"
        onClick={() => handleClose("SubMenu")}
      >
        <ListItemIcon>
          <GraphIcon />
        </ListItemIcon>
        <Typography variant="subtitle2" color="inherit">
          Marketing Portal
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderCustomersMenu = () => (
    <Menu
      id="customers-menu"
      anchorEl={customersMenuAnchorEl}
      open={Boolean(customersMenuAnchorEl)}
      onClose={() => handleClose("Customers")}
    >
      <MenuItem
        component={Link}
        to="/customers/dashboard"
        onClick={() => handleClose("Customers")}
      >
        <Typography variant="subtitle2" color="inherit">
          Dashboard
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/customers/allcustomers"
        onClick={() => handleClose("Customers")}
      >
        <Typography variant="subtitle2" color="inherit">
          All Customers
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderVendorsMenu = () => (
    <Menu
      id="vendors-menu"
      anchorEl={vendorsMenuAnchorEl}
      open={Boolean(vendorsMenuAnchorEl)}
      onClose={() => handleClose("Vendors")}
    >
      <MenuItem
        component={Link}
        to="/vendors/dashboard"
        onClick={() => handleClose("Vendors")}
      >
        <Typography variant="subtitle2" color="inherit">
          Dashboard
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/vendors/allvendors"
        onClick={() => handleClose("Vendors")}
      >
        <Typography variant="subtitle2" color="inherit">
          All Vendors
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderInventoryMenu = () => (
    <Menu
      id="inventory-menu"
      anchorEl={inventoryMenuAnchorEl}
      open={Boolean(inventoryMenuAnchorEl)}
      onClose={() => handleClose("Products / Item-PLU")}
    >
      <MenuItem
        component={Link}
        to="/products/dashboard"
        onClick={() => handleClose("Products / Item-PLU")}
        sx={{ minWidth: "11.2rem" }}
      >
        <Typography variant="subtitle2" color="inherit">
          Dashboard
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/products/products"
        onClick={() => handleClose("Products / Item-PLU")}
      >
        <Typography variant="subtitle2" color="inherit">
          Products
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderEmployeesMenu = () => (
    <Menu
      id="employees-menu"
      anchorEl={employeesMenuAnchorEl}
      open={Boolean(employeesMenuAnchorEl)}
      onClose={() => handleClose("Employees")}
    >
      <MenuItem
        component={Link}
        to="/employees/dashboard"
        onClick={() => handleClose("Employees")}
      >
        <Typography variant="subtitle2" color="inherit">
          Dashboard
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/employees/allemployees"
        onClick={() => handleClose("Employees")}
      >
        <Typography variant="subtitle2" color="inherit">
          All Employees
        </Typography>
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <Button
        aria-label="SubMenu"
        sx={{
          ...btnStyles(
            activeButton,
            ["submenu/marketing", "management", "integration"],
            ""
          ),
          minWidth: "40px",
          ".MuiButton-endIcon": {
            margin: "unset",
          },
        }}
        onClick={(event) => handleMenuClick("SubMenu", event)}
        aria-controls="sub-menu"
        aria-haspopup="true"
        endIcon={
          <SubMenu
            stroke={
              ["submenu/marketing", "management", "integration"].includes(
                activeButton
              )
                ? theme.palette.accent
                : theme.palette.mode === "dark"
                ? "white"
                : "gray"
            }
          />
        }
      /> 
      {renderSubMenu()}

      {/* <Link
        to="/overview"
        style={{
          textDecoration: "none",
          color: theme.palette.mode === "dark" ? "#CCCCCC" : "#000",
        }}
      >
        <Button
          aria-label="Overview Dashboard"
          startIcon={
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke={theme.palette.accent}
                strokeWidth="2"
                d="M1.66602 10.6712C1.66602 8.76421 1.66602 7.8107 2.09868 7.02025C2.53135 6.22981 3.32181 5.73923 4.90271 4.75808L6.56937 3.72369C8.24051 2.68654 9.0761 2.16797 9.99935 2.16797C10.9226 2.16797 11.7582 2.68654 13.4293 3.72369L15.096 4.75807C16.6769 5.73923 17.4673 6.22981 17.9 7.02025C18.3327 7.8107 18.3327 8.76421 18.3327 10.6712V11.9388C18.3327 15.1895 18.3327 16.8149 17.3563 17.8247C16.3801 18.8346 14.8087 18.8346 11.666 18.8346H8.33268C5.18998 18.8346 3.61864 18.8346 2.64232 17.8247C1.66602 16.8149 1.66602 15.1895 1.66602 11.9388V10.6712Z"
              />
              <g opacity="0.6">
                <path d="M12.5 15.5H7.5H12.5Z" fill="none" />
                <path
                  d="M12.5 15.5H7.5"
                  stroke={theme.palette.accent}
                  strokeWidth="1.6"
                  strokeLinecap="round"
                />
              </g>
            </svg>
          }
          sx={btnStyles(activeButton, "overview")}
          onClick={() => handleClick("overview")}
        >
          Overview
        </Button>
      </Link> */}

      <Link
        to="/businesssynopsis"
        style={{
          textDecoration: "none",
          color: theme.palette.mode === "dark" ? "#CCCCCC" : "#000",
        }}
      >
        <Button
          aria-label="Business Synopsis "
          sx={btnStyles(activeButton, "businesssynopsis")}
          onClick={() => handleClick("businesssynopsis")}
          startIcon={
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_6469_16334)">
                <path
                  d="M5.73242 15.1234V13.3984"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10 15.1258V11.6758V15.1258Z"
                  fill={theme.palette.accent}
                />
                <path
                  d="M10 15.1258V11.6758"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M14.2676 15.1247V9.94141"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M14.2658 4.875L13.8824 5.325C11.7574 7.80833 8.90744 9.56666 5.73242 10.3583"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M11.8242 4.875H14.2659V7.30833"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  opacity="0.6"
                  d="M7.49935 18.3346H12.4993C16.666 18.3346 18.3327 16.668 18.3327 12.5013V7.5013C18.3327 3.33464 16.666 1.66797 12.4993 1.66797H7.49935C3.33268 1.66797 1.66602 3.33464 1.66602 7.5013V12.5013C1.66602 16.668 3.33268 18.3346 7.49935 18.3346Z"
                  stroke={theme.palette.accent}
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6469_16334">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
        >
          Business Synopsis
        </Button>
      </Link>
      <Link
        to="/sales"
        style={{
          textDecoration: "none",
          color: theme.palette.mode === "dark" ? "#CCCCCC" : "#000",
        }}
      >
        <Button
          aria-label="Sales"
          sx={btnStyles(activeButton, "sales")}
          onClick={() => handleClick("sales")}
          // startIcon={
          //   <svg
          //     width="23"
          //     height="24"
          //     viewBox="0 0 23 24"
          //     fill="none"
          //     xmlns="http://www.w3.org/2000/svg"
          //   >
          //     <path
          //       stroke={theme.palette.accent}
          //       strokeWidth="2"
          //       d="M1.66602 10.6712C1.66602 8.76421 1.66602 7.8107 2.09868 7.02025C2.53135 6.22981 3.32181 5.73923 4.90271 4.75808L6.56937 3.72369C8.24051 2.68654 9.0761 2.16797 9.99935 2.16797C10.9226 2.16797 11.7582 2.68654 13.4293 3.72369L15.096 4.75807C16.6769 5.73923 17.4673 6.22981 17.9 7.02025C18.3327 7.8107 18.3327 8.76421 18.3327 10.6712V11.9388C18.3327 15.1895 18.3327 16.8149 17.3563 17.8247C16.3801 18.8346 14.8087 18.8346 11.666 18.8346H8.33268C5.18998 18.8346 3.61864 18.8346 2.64232 17.8247C1.66602 16.8149 1.66602 15.1895 1.66602 11.9388V10.6712Z"
          //     />
          //     <g opacity="0.6">
          //       <path d="M12.5 15.5H7.5H12.5Z" fill="none" />
          //       <path
          //         d="M12.5 15.5H7.5"
          //         stroke={theme.palette.accent}
          //         strokeWidth="1.6"
          //         strokeLinecap="round"
          //       />
          //     </g>
          //   </svg>
          // }
        >
          Sales
        </Button>
      </Link>

      <Button
        aria-label="Customers"
        sx={btnStyles(
          activeButton,
          "customers/dashboard",
          "customers/allcustomers"
        )}
        onClick={(event) => handleMenuClick("Customers", event)}
        aria-controls="customer-menu"
        aria-haspopup="true"
        endIcon={
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8327 8L9.99935 13L4.16602 8"
              stroke={theme.palette.accent}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        Customers
      </Button>
      {renderCustomersMenu()}

      {/* <Button
        aria-label="Vendors"
        sx={btnStyles(activeButton, "vendors/dashboard", "vendors/allvendors")}
        onClick={(event) => handleMenuClick("Vendors", event)}
        aria-controls="vendors-menu"
        aria-haspopup="true"
        endIcon={
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8327 8L9.99935 13L4.16602 8"
              stroke={theme.palette.accent}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        Vendors
      </Button> */}
      {renderVendorsMenu()}

      <Button
        aria-label="Inventory"
        sx={btnStyles(activeButton, "products/dashboard", "products/products")}
        onClick={(event) => handleMenuClick("Products / Item-PLU", event)}
        aria-controls="inventory-menu"
        aria-haspopup="true"
        endIcon={
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8327 8L9.99935 13L4.16602 8"
              stroke={theme.palette.accent}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        Products / Item-PLU
      </Button>
      {renderInventoryMenu()}

      <Button
        aria-label="Employees"
        sx={btnStyles(
          activeButton,
          "employees/dashboard",
          "employees/allemployees"
        )}
        onClick={(event) => handleMenuClick("Employees", event)}
        aria-controls="employees-menu"
        aria-haspopup="true"
        endIcon={
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8327 8L9.99935 13L4.16602 8"
              stroke={theme.palette.accent}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        Employees
      </Button>
      {renderEmployeesMenu()}
    </>
  )
}

export default Left
