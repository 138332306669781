const FreshBook = () => {
  return (
    <svg
      width="139"
      height="30"
      viewBox="0 0 139 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.79395 3.24219H24.4447V26.512H8.79395V3.24219Z" fill="white" />
      <path
        d="M12.6667 0C5.65079 0 0 5.65079 0 12.6667V30H17.3333C24.3492 30 30 24.3492 30 17.3333V0H12.6667ZM22.9206 5.30159C22.9206 7.65079 21.0476 9.42857 18.7937 9.42857H14.2857V13.0159H20.3175V17.1429H14.381V25.7778H9.42857V4.22222H14.2857V8.09524C14.4762 5.93651 16.254 4.22222 18.4127 4.22222H22.9206V5.30159Z"
        fill="#2E6EB6"
      />
      <path
        d="M46.8884 11.0438V22.2819H49.8408V16.1549C49.8408 15.8057 49.8408 14.7263 50.2853 14.2819C50.7297 13.7422 51.3646 13.4882 52.0948 13.5517H52.19V10.758H52.0948C51.2059 10.6628 50.3805 11.012 50.0313 11.393L49.8408 11.5834C49.7456 11.6787 49.6503 11.7739 49.5868 11.8374V11.1073L46.8884 11.0438Z"
        fill="#010202"
      />
      <path
        d="M76.5717 22.3767V16.1863C76.5717 14.472 77.3654 13.4878 78.7305 13.4878C79.2702 13.4878 79.7146 13.6783 80.0638 14.0275C80.5082 14.472 80.794 15.2021 80.794 16.091V22.2815H83.6829V15.2656C83.6829 12.8529 81.9686 10.8529 79.7146 10.6942C78.4448 10.599 77.3654 10.8847 76.5717 11.6783V7.26562H73.6829V22.2815H76.5717V22.3767Z"
        fill="#010202"
      />
      <path
        d="M92.7301 14.0935H92.6349L92.7301 13.9982C93.9047 13.2681 94.2539 11.7443 94.0634 10.6649C93.619 8.50617 91.9047 7.23633 89.2063 7.23633H85.238V22.2522H89.4603C92.6031 22.2522 94.5079 20.9189 95.0476 18.3792C95.2698 16.9824 94.3492 14.7284 92.7301 14.0935ZM88.1587 10.2522H89.4285C89.9682 10.2522 91.0476 10.3474 91.1428 11.522C91.1428 11.8712 91.0476 12.2522 90.7936 12.5062C90.3491 12.9506 89.619 13.0459 88.9841 13.0459H88.0634L88.1587 10.2522ZM91.6507 18.7919C91.2063 19.2363 90.3809 19.522 89.1428 19.522H88.1587V15.9347H89.7777C90.7618 15.9347 91.9364 16.3792 92.0317 17.5538C92.0952 17.9665 91.9364 18.4109 91.6507 18.7919Z"
        fill="#010202"
      />
      <path
        d="M105.587 12.476C104.508 11.3966 102.984 10.7617 101.365 10.7617C98.0317 10.857 95.4285 13.5554 95.5238 16.8887C95.619 20.0316 98.1269 22.6347 101.27 22.73H101.365C102.889 22.73 104.413 22.0951 105.492 21.0157C107.841 18.7617 107.905 14.9839 105.683 12.6665C105.778 12.5712 105.683 12.476 105.587 12.476ZM101.556 19.9363C100.762 19.9363 99.9365 19.6824 99.3968 19.0474C98.7619 18.4125 98.4127 17.6189 98.4127 16.6982C98.4127 14.9839 99.746 13.6506 101.46 13.5554H101.556C103.27 13.5554 104.603 14.8887 104.698 16.603C104.603 18.3173 103.333 19.8728 101.556 19.9363Z"
        fill="#010202"
      />
      <path
        d="M118.255 12.476C117.175 11.3966 115.651 10.7617 114.032 10.7617C110.699 10.857 108.096 13.5554 108.191 16.8887C108.286 20.0316 110.794 22.6347 113.937 22.73H114.032C115.556 22.73 117.08 22.0951 118.159 21.0157C120.508 18.7617 120.572 14.9839 118.35 12.6665C118.35 12.5712 118.255 12.476 118.255 12.476ZM116.381 18.857C115.842 19.4919 115.048 19.8411 114.223 19.8411C113.397 19.8411 112.604 19.5871 112.064 18.9522C111.429 18.3173 111.08 17.5236 111.08 16.603C111.08 14.8887 112.413 13.5554 114.128 13.4601H114.223C115.937 13.4601 117.27 14.7935 117.27 16.5078C117.175 17.5236 116.921 18.3173 116.381 18.857Z"
        fill="#010202"
      />
      <path
        d="M123.651 17.2339L123.841 16.9799H124.095L127.333 22.3767H130.476L126.063 15.0117L130.476 11.0434H126.508L123.619 13.6466V7.26562H120.825V22.3767H123.619L123.651 17.2339Z"
        fill="#010202"
      />
      <path
        d="M138.762 18.957C138.762 16.5443 136.794 15.9094 135.27 15.465C134.286 15.211 133.46 14.9253 133.46 14.2904C133.46 13.8459 133.809 13.5602 134.444 13.5602C134.794 13.5602 135.175 13.6554 135.429 13.9094L135.524 14.0046L137.397 12.1316L137.302 12.0364C136.571 11.3062 135.492 10.957 134.508 10.957C132.159 10.957 130.73 12.2904 130.73 14.3856C130.73 16.7983 132.603 17.338 134.159 17.8142C135.238 18.0681 136.032 18.3539 136.032 19.084C136.032 19.7189 135.397 20.1634 134.508 20.1634C133.619 20.1634 133.079 19.7189 133.079 18.9888V18.7983H130.19V18.9888C130.19 21.1475 132 22.7666 134.508 22.7666C136.952 22.7348 138.762 21.1158 138.762 18.957Z"
        fill="#010202"
      />
      <path
        d="M40.445 10.3157V13.5538H45.3974V16.4426H40.5403V22.3792H37.5879V7.36328H45.6831V10.3157H40.445Z"
        fill="#010202"
      />
      <path
        d="M64.064 16.7959C64.064 13.1133 62.0005 10.8594 58.6672 10.8594C55.2386 10.8594 52.8259 13.2721 52.8259 16.6054C52.8259 20.0975 55.3339 22.7324 58.572 22.7324C60.2862 22.7324 62.0005 22.0022 63.1434 20.7641L63.2386 20.6689L61.1751 18.8594L61.0799 18.9546C60.445 19.7483 59.4608 20.2245 58.4767 20.2245C56.9529 20.2245 55.7783 19.2403 55.5243 17.6213V17.3673H63.9688L64.064 16.7959ZM55.8101 14.8276L55.9053 14.6372C56.445 13.7483 57.3339 13.3038 58.5085 13.3038C59.7783 13.3038 60.7624 13.8435 61.0164 14.8276H55.8101Z"
        fill="#010202"
      />
      <path
        d="M69.4605 15.4625C68.4763 15.2086 67.6509 14.9229 67.6509 14.1927C67.6509 13.7483 68.0002 13.4625 68.6351 13.4625C68.9843 13.4625 69.3652 13.5578 69.6192 13.8118L69.7144 13.907L71.5875 12.034L71.4922 11.9387C70.7621 11.2086 69.6827 10.8594 68.6986 10.8594C66.3494 10.8594 64.9208 12.1927 64.9208 14.2879C64.9208 16.7006 66.7938 17.2403 68.3494 17.7165C69.4287 17.9705 70.2224 18.2562 70.2224 18.9864C70.2224 19.6213 69.5875 20.0657 68.6986 20.0657C67.8097 20.0657 67.27 19.6213 67.27 18.8911V18.7006H64.3811V18.8911C64.3811 21.0499 66.1906 22.6689 68.6986 22.6689C71.2065 22.6689 73.016 21.0499 73.016 18.8911C72.9525 16.5419 70.9843 16.0022 69.4605 15.4625Z"
        fill="#010202"
      />
    </svg>
  );
};

export default FreshBook;
