import { useEffect, useRef, useState } from "react"
import InfoToolTip from "../../InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import { useDispatch, useSelector } from "react-redux"
import NivoRadialBarChart from "./NivoRadialBarChart"
import { aiReportApi, postData } from "../../../api/api"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import LoadingButton from "@mui/lab/LoadingButton"
import DownloadIcon from "@mui/icons-material/Download"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import XLSX from "xlsx"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ExportChartsData from "../../ExportChartsData"
import { getDateFormatFromLocalStorage } from "../../../utils/dateUtils.js"
import dayjs from "dayjs"

const RadialBarChart = ({
  route,
  setChartData,
  title,
  description,
  postUrl,
  comparisonPeriod,
  reportUid,
  parameters,
  dateRangeData = { fromDate: "", toDate: "" },
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary

  const [loading, setLoading] = useState(true)
  const [pdfloading, setPdfLoading] = useState(false)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const dispatch = useDispatch()
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "",
    Recommendations: "",
    Anomalies: "",
  })
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })

  const [anchorEl, setAnchorEl] = useState(null)
  const exportMenu = Boolean(anchorEl)

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

   const exportData = async (type, data = apiResponse) => {
    setPdfLoading(true)
     const aiResponses = await aiReportApi(title, data)

     setAiData({
       Analysis: aiResponses?.msg?.[0]?.["Analysis"],
       Recommendations: aiResponses?.msg?.[1]?.["Recommendations"],
       Anomalies: aiResponses?.msg?.[2]?.["Anomalies"],
     })

     // Check if the data is an array before attempting to map
     const transformedData = Array.isArray(data)
       ? data.map((item) => {
           const flatItem = {}

           for (const key of Object.keys(item)) {
             if (typeof item[key] === "object") {
               flatItem[`${key} Current`] = item[key].current
               flatItem[`${key} Previous`] = item[key].previous
               flatItem[`${key} Change Percent`] = item[key].changePercent
             } else if (typeof item[key] === "string") {
               flatItem[key] = item[key]
             }
           }

           return flatItem
         })
       : [] // If data is not an array, set transformedData as an empty array

     if (type === "pdf") {
       setAnchorEl(null)
       setPdfOpen(true)
       return
     }

     const worksheet = XLSX.utils.json_to_sheet(transformedData)
     const workbook = XLSX.utils.book_new()
     XLSX.utils.book_append_sheet(workbook, worksheet, "Data")
     XLSX.writeFile(workbook, `data.${type}`, { bookType: type })
     setAnchorEl(null)
     setPdfLoading(false)
   }

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const staticData = [
    {
      id: "Supermarket",
      data: [
        {
          x: "Vegetables",
          y: 285,
          tooltipData: {
            current: { Vegetables: { value: 285, format: "currency" } },
            previous: { Vegetables: { value: 70, format: "currency" } },
          },
        },
        {
          x: "Fruits",
          y: 174,
          tooltipData: {
            current: { Fruits: { value: 174, format: "currency" } },
            previous: { Fruits: { value: 50, format: "currency" } },
          },
        },
        {
          x: "Meat",
          y: 291,
          tooltipData: {
            current: { Meat: { value: 291, format: "currency" } },
            previous: { Meat: { value: 85, format: "currency" } },
          },
        },
        {
          x: "Fish",
          y: 108,
          tooltipData: {
            current: { Fish: { value: 108, format: "currency" } },
            previous: { Fish: { value: 40, format: "currency" } },
          },
        },
      ],
    },
  ]

  const tooltip_data = extractedParameters.tooltip_data || [
    { name: "Gross Sales", format: "Currency", color: "" },
    { name: "Customers", format: "Number", color: "" },
    { name: "Transactions", format: "Number", color: "" },
    { name: "Average Ticket", format: "Currency", color: "" },
  ]

  const indexBy = extractedParameters.indexBy || "id"
  const keys = extractedParameters.keys || "Gross Sales"

  // function transformData(input) {
  //   if (!input?.reportDataCurrent) {
  //     console.error("Invalid input data structure:", input)
  //     return []
  //   }

  //   return input.reportDataCurrent.map((currentItem) => {
  //     return {
  //       id: currentItem[indexBy],
  //       data: Object.entries(currentItem)
  //         .filter(([key]) => key === keys)
  //         .map(([key, value]) => ({ x: key, y: value })),
  //       tooltipData: {
  //         current: {},
  //         previous: {},
  //       },
  //     }
  //   })
  // }
  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  function transformData2(response) {
    // Use reportDataCurrent if it exists, otherwise use response directly as data
    const dataSource = Array.isArray(response?.reportDataCurrent)
      ? response.reportDataCurrent
      : response

    if (!Array.isArray(dataSource)) {
      console.error("The provided data is not an array.")
      return [{ id: "", data: [] }]
    }

    if (!dataSource.length) return [{ id: "", data: [] }]

    const combinedData = []
    const ids = []

    // Iterate over the data source (either reportDataCurrent or response)
    dataSource.forEach((entry, index) => {
      const previous = response.reportDataPrevious?.[index] // Safely get corresponding previous entry
      const keys = Object.keys(entry)

      if (keys.length < 2) {
        console.error("Each entry should have at least two keys.")
        return
      }

      const firstKey = keys[0] // First key for id (e.g., name, date, etc.)
      const secKey = keys[1] // Second key for the y-value (e.g., value)

      ids.push(entry[firstKey]) // Push id to ids array

      // Construct tooltipData for current values
      const tooltipData = {
        current: keys.slice(1).reduce((acc, key) => {
          acc[key] = { value: entry[key], format: "currency" }
          return acc
        }, {}),
      }

      // Conditionally add previous values only if reportDataPrevious exists
      if (response.reportDataPrevious) {
        tooltipData.previous = keys.slice(1).reduce((acc, key) => {
          acc[key] = { value: previous?.[key] || null, format: "currency" }
          return acc
        }, {})
      }

      // Push data entry to combinedData
      combinedData.push({
        x: `${entry[firstKey]}`, // X-axis label
        y: entry[secKey], // Y-axis value
        tooltipData,
      })
    })

    // Return the transformed data structure
    return [
      {
        id: ids.filter(Boolean).join(", "), // Concatenated ids as a string
        data: combinedData,
      },
    ]
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = reportUid
        ? await postData(postUrl, {
            entityDb: localStorage.getItem("entityDb"),
            fromDate: combinedState.dateRangeData.fromDate,
            toDate: combinedState.dateRangeData.toDate,
            reportInstanceId: reportUid,
            comparedBy: {
              previousFromDate: combinedState.comparisonPeriod.previousFromDate,
              previousToDate: combinedState.comparisonPeriod.previousToDate,
            },
          })
        : staticData

      setApiResponse(response)

     
      dispatch(
        setChartData({
          reportUid,
          response: transformData2(response),
        })
      )
    } catch (error) {
      setError("Inadequate data points to generate a report")
      setApiResponse(staticData)
      dispatch(
        setChartData({
          reportUid,
          staticData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const chartInput = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    return selectedReport?.reportData
  })

  function transformReportData(apiResponse) {
    const transformedData = []

    const currentData = apiResponse.reportDataCurrent || apiResponse
    const previousData = apiResponse.reportDataPrevious || []

    if (!Array.isArray(currentData)) {
      console.warn("Current data is missing or not an array")
      return transformedData
    }

    const currentLength = currentData.length

    const tooltip_data = extractedParameters.tooltip_data || [
      {
        name: "Gross Sales",
        format: "Currency",
        color: "",
      },
      {
        name: "Customers",
        format: "Number",
        color: "",
      },
      {
        name: "Transactions",
        format: "Number",
        color: "",
      },
      {
        name: "Average Ticket",
        format: "Number",
        color: "",
      },
      {
        name: "value",
        format: "Number",
        color: "",
      },
      {
        name: "Tips",
        format: "Currency",
        color: "",
      },
      {
        name: "Hours",
        format: "Number",
        color: "",
      },
      {
        name: "Sales Quantity",
        format: "Number",
        color: "",
      },
    ]


    const dateFormat = getDateFormatFromLocalStorage() || "MM-DD-YYYY"

    for (let i = 0; i < currentLength; i++) {
      const current = currentData[i]
      const previous = previousData[i] || {}

      const transformedWeek = {
        date: current["id"] || current["Employee"] || current["Product Name"],
      }

      tooltip_data.forEach((tooltip) => {
        const name = tooltip.name

        // Skip if the name is "Product Name", "EmployeeName", or "Wastage Reason"
        if (
          name === "Product Name" ||
          name === "EmployeeName" ||
          name === "Wastage Reason"
        )
          return

        // Check if the key exists in current data before processing
        if (!(name in current)) return

        let currentVal

        if (reportUid === "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0") {
          currentVal = current[name]
        } else {
          currentVal =
            current[name] && typeof current[name] === "string"
              ? Number(
                  Number(current[name].replace(/[^0-9.-]+/g, "")).toFixed(2)
                )
              : Number(Number(current[name] || 0).toFixed(2))
        }

        if (Object.keys(previous).length > 0) {
          let previousVal

          if (reportUid === "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0") {
            previousVal = previous[name]
          } else {
            previousVal =
              previous[name] && typeof previous[name] === "string"
                ? Number(previous[name].replace(/[^0-9.-]+/g, "")).toFixed(2)
                : Number(previous[name] || 0).toFixed(2)
          }

          transformedWeek[name] = {
            current:
              tooltip.format === "Currency"
                ? `$${currentVal.toLocaleString()}`
                : currentVal.toString(),
            previous:
              previousVal > 0
                ? tooltip.format === "Currency"
                  ? `$${previousVal.toLocaleString()}`
                  : previousVal.toString()
                : tooltip.format === "Currency"
                ? "$0"
                : "0",
            changePercent:
              previousVal === 0 ||
              !previousVal ||
              (currentVal - previousVal) / previousVal === Infinity ||
              (currentVal === 0 && previousVal === 0)
                ? "-"
                : (((currentVal - previousVal) / previousVal) * 100).toFixed(
                    2
                  ) + "%",
          }
        } else {
          transformedWeek[name] = {
            current:
              tooltip.format === "Currency"
                ? `$${currentVal.toLocaleString()}`
                : currentVal.toString(),
          }
        }
      })

      transformedData.push(transformedWeek)
    }

    return transformedData
  }

  const tableData = transformReportData(apiResponse)

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
        headerType="ID"
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box display="flex" gap={"2px"}>
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={extractedParameters.view_by_period}
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          {extractedParameters.focus_mode && focus_mode && (
            <Analytica
              description={description}
              component="modal"
              chartDetails={{
                route,
                setChartData,
                title,
                description,
                comparisonPeriod,
                postUrl,
                reportUid,
                parameters,
                dateRangeData,
                apiResponse,
              }}
            />
          )}

          {!loading && (
            <>
              <LoadingButton
                loading={loading}
                sx={{
                  minWidth: "104px",
                  textTransform: "none",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.accent}`,
                  color: theme.palette.accent,
                  "&:hover": {
                    border: `1px solid ${theme.palette.accent}`,
                  },
                  "& .MuiCircularProgress-colorInherit": {
                    color: theme.palette.accent,
                  },
                  "& .MuiLoadingButton-label": {
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  },
                }}
                color="warning"
                onClick={handleExportClick}
              >
                <Typography variant="body2" color="inherit">
                  Export
                </Typography>
                <DownloadIcon fontSize="small" />
              </LoadingButton>
              <Menu
                anchorEl={anchorEl}
                open={exportMenu}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ "aria-labelledby": "export-button" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                 {pdfloading ?
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    alignContent="center"
                    padding="10px"
                  >
                    <CircularProgress size={45} color="info" />
                    <Typography variant="body2" color="inherit">
                      Transforming data into PDF...
                    </Typography>
                  </Box>
                  :
                  <>
                <MenuItem onClick={() => exportData("pdf")}>
                  <ListItemIcon>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 36 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.12">
                        <path
                          d="M3.33301 19.6667C3.33301 16.8665 3.33301 15.4664 3.87798 14.3968C4.35734 13.456 5.12225 12.6911 6.06306 12.2117C7.13261 11.6667 8.53275 11.6667 11.333 11.6667H28.6663C31.4666 11.6667 32.8667 11.6667 33.9363 12.2117C34.8771 12.6911 35.642 13.456 36.1214 14.3968C36.6663 15.4664 36.6663 16.8665 36.6663 19.6667V23.3334C36.6663 24.8834 36.6663 25.6583 36.496 26.2942C36.0336 28.0196 34.6859 29.3674 32.9604 29.8297C32.3246 30.0001 31.5496 30.0001 29.9997 30.0001V28.6667C29.9997 26.7999 29.9997 25.8665 29.6364 25.1534C29.3168 24.5262 28.8069 24.0163 28.1796 23.6967C27.4666 23.3334 26.5332 23.3334 24.6663 23.3334H15.333C13.4662 23.3334 12.5327 23.3334 11.8197 23.6967C11.1925 24.0163 10.6826 24.5262 10.363 25.1534C9.99967 25.8665 9.99967 26.7999 9.99967 28.6667V30.0001C8.44972 30.0001 7.67475 30.0001 7.03891 29.8297C5.31345 29.3674 3.96571 28.0196 3.50338 26.2942C3.33301 25.6583 3.33301 24.8834 3.33301 23.3334V19.6667Z"
                          fill={theme.palette.accent}
                        />
                      </g>
                      <path
                        d="M29.9997 11.6666V8.66659C29.9997 6.79974 29.9997 5.86632 29.6364 5.15328C29.3168 4.52608 28.8069 4.01614 28.1796 3.69656C27.4666 3.33325 26.5332 3.33325 24.6663 3.33325H15.333C13.4662 3.33325 12.5327 3.33325 11.8197 3.69656C11.1925 4.01614 10.6826 4.52608 10.363 5.15328C9.99967 5.86632 9.99967 6.79974 9.99967 8.66659V11.6666M9.99967 29.9999C8.44972 29.9999 7.67475 29.9999 7.03891 29.8295C5.31345 29.3672 3.96571 28.0195 3.50338 26.294C3.33301 25.6582 3.33301 24.8832 3.33301 23.3333V19.6666C3.33301 16.8663 3.33301 15.4662 3.87798 14.3966C4.35734 13.4558 5.12224 12.6909 6.06306 12.2116C7.13261 11.6666 8.53275 11.6666 11.333 11.6666H28.6663C31.4666 11.6666 32.8667 11.6666 33.9363 12.2116C34.8771 12.6909 35.642 13.4558 36.1214 14.3966C36.6663 15.4662 36.6663 16.8663 36.6663 19.6666V23.3333C36.6663 24.8832 36.6663 25.6582 36.496 26.294C36.0336 28.0195 34.6859 29.3672 32.9604 29.8295C32.3246 29.9999 31.5496 29.9999 29.9997 29.9999M24.9997 17.4999H29.9997M15.333 36.6666H24.6663C26.5332 36.6666 27.4666 36.6666 28.1796 36.3033C28.8069 35.9837 29.3168 35.4738 29.6364 34.8466C29.9997 34.1335 29.9997 33.2001 29.9997 31.3333V28.6666C29.9997 26.7997 29.9997 25.8663 29.6364 25.1533C29.3168 24.5261 28.8069 24.0161 28.1796 23.6966C27.4666 23.3333 26.5332 23.3333 24.6663 23.3333H15.333C13.4662 23.3333 12.5327 23.3333 11.8197 23.6966C11.1925 24.0161 10.6826 24.5261 10.363 25.1533C9.99967 25.8663 9.99967 26.7997 9.99967 28.6666V31.3333C9.99967 33.2001 9.99967 34.1335 10.363 34.8466C10.6826 35.4738 11.1925 35.9837 11.8197 36.3033C12.5327 36.6666 13.4662 36.6666 15.333 36.6666Z"
                        stroke={theme.palette.accent}
                        strokeWidth="3.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2" color="inherit">
                      PDF
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => exportData("xlsx", tableData)}>
                  <ListItemIcon>
                    <svg
                      width="20"
                      height="20"
                      viewBox="-2 0 30 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3337 2.78247V9.66671C18.3337 10.6001 18.3337 11.0668 18.5153 11.4234C18.6751 11.737 18.9301 11.9919 19.2437 12.1517C19.6002 12.3334 20.0669 12.3334 21.0003 12.3334H27.8846M21.667 20.6666H8.33366M21.667 27.3333H8.33366M11.667 13.9999H8.33366M18.3337 2.33325H9.66699C6.86673 2.33325 5.4666 2.33325 4.39704 2.87822C3.45623 3.35759 2.69133 4.12249 2.21196 5.0633C1.66699 6.13286 1.66699 7.53299 1.66699 10.3333V27.6666C1.66699 30.4668 1.66699 31.867 2.21196 32.9365C2.69133 33.8773 3.45623 34.6423 4.39704 35.1216C5.4666 35.6666 6.86673 35.6666 9.66699 35.6666H20.3337C23.1339 35.6666 24.5341 35.6666 25.6036 35.1216C26.5444 34.6423 27.3093 33.8773 27.7887 32.9365C28.3337 31.867 28.3337 30.4668 28.3337 27.6666V12.3333L18.3337 2.33325Z"
                        stroke={theme.palette.accent}
                        strokeWidth="3.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2" color="inherit">
                      Excel
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => exportData("csv", tableData)}>
                  <ListItemIcon>
                    <svg
                      width="20"
                      height="20"
                      viewBox="-2 0 30 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3337 2.78247V9.66671C18.3337 10.6001 18.3337 11.0668 18.5153 11.4234C18.6751 11.737 18.9301 11.9919 19.2437 12.1517C19.6002 12.3334 20.0669 12.3334 21.0003 12.3334H27.8846M21.667 20.6666H8.33366M21.667 27.3333H8.33366M11.667 13.9999H8.33366M18.3337 2.33325H9.66699C6.86673 2.33325 5.4666 2.33325 4.39704 2.87822C3.45623 3.35759 2.69133 4.12249 2.21196 5.0633C1.66699 6.13286 1.66699 7.53299 1.66699 10.3333V27.6666C1.66699 30.4668 1.66699 31.867 2.21196 32.9365C2.69133 33.8773 3.45623 34.6423 4.39704 35.1216C5.4666 35.6666 6.86673 35.6666 9.66699 35.6666H20.3337C23.1339 35.6666 24.5341 35.6666 25.6036 35.1216C26.5444 34.6423 27.3093 33.8773 27.7887 32.9365C28.3337 31.867 28.3337 30.4668 28.3337 27.6666V12.3333L18.3337 2.33325Z"
                        stroke={theme.palette.accent}
                        strokeWidth="3.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2" color="inherit">
                      CSV
                    </Typography>
                  </ListItemText>
                </MenuItem>
                </>}
              </Menu>
            </>
          )}
        </Box>
      </Box>

      <Box ref={chartRef}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="350px"
            width="100%"
            gap="10px"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <NivoRadialBarChart
            chartConfig={extractedParameters}
            chartInput={staticData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <NivoRadialBarChart
            chartConfig={extractedParameters}
            chartInput={chartInput}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default RadialBarChart
