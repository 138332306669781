import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector, useDispatch } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import NivoLineChart from "./NivoLineChart"
import { useTheme } from "../../../contexts/theme"
import { postData } from "../../../api/api"
import ViewBy from "../../ViewBy"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import CircularProgress from "@mui/material/CircularProgress"
import {
  LineChartData,
  LineChartParameters,
} from "../../../constants/chartsData"
import InfoToolTip from "../../InfoToolTip"
import Analytica from "../../Analytica"

const LineChart = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  let aiResponse = {
    success: true,
    msg: [
      {
        Analysis:
          "\n1. The actual sales for August 2024 ranged from approximately 6,182 to 15,386 units, with the highest sales recorded on August 4th.\n2. The forecasted sales for the same period ranged from about 7,024 to 14,287 units, peaking on August 11th, 25th, and 31st.\n3. The actual sales surpassed the forecast on several days, particularly on August 4th, 15th, and 25th.\n",
      },
      {
        Recommendations:
          "\n1. Consider investigating the factors that led to the higher than forecasted sales on August 4th, 15th, and 25th, as understanding these could help replicate such success in the future.\n2. Keep a close eye on the days where sales are consistently lower than forecasted to identify any potential issues or trends.\n3. Use this data to refine your forecasting model, taking into account any external factors that may have influenced sales.\n",
      },
      {
        Anomalies:
          "\n1. There was a significant discrepancy between the actual and forecasted sales on August 4th, with actual sales much higher than predicted.\n2. The actual sales on August 11th were lower than forecasted, despite the forecast predicting a peak in sales.\n3. The actual sales on August 25th significantly surpassed the forecast, indicating an unexpected surge in sales.",
      },
    ],
  }

  const svgColor = theme.palette.accentSecondary
  const dispatch = useDispatch()
  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const fetchData = async () => {
    try {
      // const response = await postData(postUrl, {
      //   entityDb: localStorage.getItem("entityDb"),
      //    fromDate: combinedState.dateRangeData.fromDate,
      // toDate: combinedState.dateRangeData.toDate,
      //   reportInstanceId: reportUid,
      //   comparedBy: {
      // previousFromDate: comparisonPeriod?.previousFromDate,
      // previousToDate: comparisonPeriod?.previousToDate,
      //   },
      //   viewBy: viewBy || "Day",
      // })
      const response = false
      setApiResponse(response)
      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(
          setChartData({
            reportUid,
            response: LineChartData,
          })
        )
      } else {
        dispatch(
          setChartData({
            reportUid,
            response,
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: LineChartData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    if (selectedReport) return selectedReport?.reportData
  })

  const transformedData =
    response?.data?.map(({ Date, x }) => ({
      x: Date,
      y: x,
    })) || []

  const chartInput = [
    {
      id: response?.id || "default_id",
      data: transformedData,
    },
  ]
  const legendData = [
    { label: "Forecast", color: "rgba(80, 205, 137, 1)" },
    { label: "Actual", color: "rgba(0, 158, 247, 1)" },
  ]
  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}

          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                reportType="Line"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box minHeight="360px">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <NivoLineChart
            chartInput={LineChartData}
            parameters={LineChartParameters}
            legendsData={legendData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <NivoLineChart
            chartInput={chartInput}
            parameters={parameters}
            legendsData={legendData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default LineChart
