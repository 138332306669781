/* eslint-disable no-eval */
const extractKeyValuePairs = (parameters) => {
  if (parameters === null || parameters === undefined) {
    console.warn("JSON data is null or undefined")
    return {}
  }

  const extractedParameters = {}

  parameters.forEach((paramType) => {
    if (paramType.reportParameters) {
      paramType.reportParameters.forEach((param) => {
        extractedParameters[param.parameterName] = param.parameterValue
      })
    }
  })

  const extractedData = {}

  for (const key in extractedParameters) {
    try {
      const value = extractedParameters[key]
      if (key == "tooltip_fields") extractedData[key] = value
      else extractedData[key] = JSON.parse(value)
    } catch (error) {
      try {
        extractedData[key] = eval(`(${extractedParameters[key]})`)
      } catch (error) {
        extractedData[key] = extractedParameters[key]
      }
    }
  }

  return extractedData
}

export default extractKeyValuePairs
