const CloseIconSubscription = ({ svgColor, handleClose }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClose}
    >
      <path
        d="M3.33398 12.6654L12.6673 3.33203"
        stroke={svgColor || "#F3884A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6673 12.6654L3.33398 3.33203"
        stroke={svgColor || "#F3884A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloseIconSubscription
