import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Chip from "@mui/material/Chip"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import QRCode from "react-qr-code"

import CustomPaper from "../../components/CustomPaper"
import { useTheme } from "../../contexts/theme"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import FilePicker from "../../components/FilePicker"
import Filter from "./Filter"
import Table from "../../components/datagrid/Table"
import {
  TargetedCustomersData,
  TargetedCustomersParameters,
} from "../../constants/chartsData"
import StarIcon from "../../assets/svgs/Star.jsx"
import ArrowRise from "../../assets/svgs/ArrowRise.jsx"
import Reload from "../../assets/svgs/Reload.jsx"
import Qricon from "../../assets/svgs/Qricon.jsx"

const CreateCampaign = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  const [selectedRows, setSelectedRows] = useState([])

  const firstFive = useMemo(() => {
    return selectedRows.slice(0, 5)
  }, [selectedRows])

  const removeSelectedRows = (value) => {
    setSelectedRows((prevCustomers) => {
      return prevCustomers.filter((customer) => customer !== value)
    })
  }

  const handleCancel = () => {
    // Add cancel functionality here
  }

  const handleSaveDraft = () => {
    // Add save draft functionality here
  }

  const handleSubmit = () => {
    // Add submit functionality here
  }

  const renderDetails = (details) =>
    Object.entries(details).map(([key, value]) => (
      <Stack
        key={key}
        rowGap="5px"
        minWidth={{
          sx: "200px",
          sm: "200px",
          md: "200px",
          lg: "280px",
        }}
      >
        <Typography
          variant="body2"
          color="inherit"
          sx={{
            opacity: "70%",
            fontSize: "0.85rem",
          }}
        >
          {value.label}
        </Typography>
        {typeof value.value === "string" ? (
          <Typography
            variant="body1"
            color={
              value.label === "Total Purchased Amount" ? "#50CD89" : "inherit"
            }
            sx={{ fontSize: "1.1rem" }}
          >
            {value.value}
          </Typography>
        ) : (
          value.value
        )}
      </Stack>
    ))

  const personalDetails = {
    name: {
      label: "Name",
      value: "Dianne Russell",
    },
    phone: {
      label: "Phone Number",
      value: "(307) 555-0133",
    },
    email: {
      label: "Email Address",
      value: "diannerussll@example.com",
    },
    streetAddress: {
      label: "Street Address",
      value: "123 Main Street",
    },
    aptAddress: {
      label: "Apartment or Suite Number",
      value: "Apt 4B",
    },
    city: {
      label: "City",
      value: "Anytown",
    },
    state: {
      label: "State",
      value: "New York",
    },
    zip: {
      label: "ZIP Code",
      value: "12345",
    },
    lastVisited: {
      label: "Last Visited On",
      value: "16th Oct 2024",
    },
    totalItemsBought: {
      label: "Total No. of Items Bought",
      value: 242,
    },
  }

  const customerDetails = {
    overallRating: {
      label: "Overall Rating",
      value: (
        <Box display="flex" alignItems="center" gap="5px">
          <Typography variant="h5" color="inherit">
            4.3
          </Typography>
          <StarIcon color="#F2CD4A" />
        </Box>
      ),
    },
    amountSpent: {
      label: "Amount Spent",
      value: (
        <Box display="flex" alignItems="center" gap="10px">
          <Typography
            variant="h5"
            sx={{
              color: " rgba(80, 205, 137, 1)",
              textAlign: "left",
            }}
          >
            $3,054.43
          </Typography>
          <Box display="flex" alignItems="center" gap="2px">
            <Typography variant="body1" color="inherit">
              +11.01%
            </Typography>
            <ArrowRise />
          </Box>
        </Box>
      ),
    },
    topBoughtItems: {
      label: "Top 5 Bought Items",
      value: (
        <ol>
          <li>
            <Typography variant="subtitle1" color="inherit">
              Pizza Margherita
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="inherit">
              Cheeseburger
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="inherit">
              Spaghetti
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="inherit">
              Bolognese
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="inherit">
              Vegetable Pad Thai
            </Typography>
          </li>
        </ol>
      ),
    },
    review: {
      label: "Review",
      value: (
        <Typography variant="subtitle1" color="inherit">
          "The service was impeccable. The staff was attentive, friendly, and
          made us feel welcome."
        </Typography>
      ),
    },
  }

  const [open, setOpen] = useState(false)
  const handleAddMemberOpen = () => setOpen(true)
  const handleAddMemberClose = () => setOpen(false)

  const [nestedOpen, setNestedOpen] = useState(false)
  const handleMemberDetailsOpen = () => setNestedOpen(true)
  const handleMemberDetailsClose = () => setNestedOpen(false)

  const [nestednestedOpen, setNestedNestedOpen] = useState(false)
  const handleMemberDeleteOpen = () => setNestedNestedOpen(true)
  const handleMemberDeleteClose = () => setNestedNestedOpen(false)
  const [showQr, setShowQr] = useState(false)
  const [showCoupon, setShowCoupon] = useState(true)

  const handleQrClick = () => {
    setShowQr(true)
    setShowCoupon(false)
  }

  const handleCouponClick = () => {
    setShowCoupon(true)
    setShowQr(false)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleAddMemberClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomPaper
          customStyles={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 800,
              sm: 800,
              md: 800,
              lg: 800,
              xl: 1200,
            },
            minHeight: "80vh",
            overflowY: "auto",
            bgcolor: theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
            boxShadow: 24,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" color="inherit">
              Add Members
            </Typography>
            <IconButton onClick={handleAddMemberClose}>
              <CloseIcon
                color={
                  theme.palette.mode === "light"
                    ? theme.palette.accent
                    : "white"
                }
              />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={4} lg={3}>
              <CustomPaper customStyles={{ paddingX: "unset" }}>
                <Filter />
              </CustomPaper>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Table
                title="Members"
                showExportButton={false}
                StaticData={TargetedCustomersData}
                StaticParameters={TargetedCustomersParameters}
                buttonName="+ Add Members"
                showButton={() => {
                  handleAddMemberClose()
                  setSelectedRows(selectedRows)
                }}
                onRowClick={() => {
                  handleMemberDetailsOpen()
                }}
                checkboxSelection={true}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </Grid>
          </Grid>
        </CustomPaper>
      </Modal>

      <Modal
        open={nestedOpen}
        onClose={handleMemberDetailsClose}
        aria-labelledby="nested-modal-modal-title"
        aria-describedby="nested-modal-modal-description"
      >
        <CustomPaper
          customStyles={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 800,
              sm: 800,
              md: 800,
              lg: 800,
              xl: 1200,
            },
            minHeight: "80vh",
            overflowY: "auto",
            bgcolor: theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
            boxShadow: 24,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" color="inherit">
              Member Details
            </Typography>
            <Button
              onClick={handleMemberDetailsClose}
              variant="link"
              color="accent"
              sx={{
                textDecoration: "none",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                color: theme.palette.accent,
                gap: "5px",
                p: 1,
              }}
            >
              <NavigateBeforeIcon
                fontSize="medium"
                viewBox="0 0 25 27"
                color="inherit"
              />
              <Typography variant="subtitle1">Back</Typography>
            </Button>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={7} lg={7}>
              <CustomPaper>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  borderBottom="2px solid #091743"
                  mb="15px"
                >
                  <Typography mb="10px" color="inherit">
                    Personal Information
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  gap="30px"
                >
                  {renderDetails(personalDetails)}
                </Box>
              </CustomPaper>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <CustomPaper
                customStyles={{
                  background: "none",
                  border: "1px solid #0D2266",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  gap="30px"
                >
                  {renderDetails(customerDetails)}
                </Box>
              </CustomPaper>
            </Grid>
          </Grid>
        </CustomPaper>
      </Modal>

      <Modal
        open={nestednestedOpen}
        onClose={handleMemberDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomPaper
          customStyles={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 800,
              sm: 800,
              md: 800,
              lg: 800,
              xl: 1200,
            },
            minHeight: "80vh",
            overflowY: "auto",
            bgcolor: theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
            boxShadow: 24,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" color="inherit">
              SMS Recipients
            </Typography>
            <IconButton onClick={handleMemberDeleteClose}>
              <CloseIcon
                color={
                  theme.palette.mode === "light"
                    ? theme.palette.accent
                    : "white"
                }
              />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Table
            title="Members"
            showExportButton={false}
            StaticData={TargetedCustomersData}
            StaticParameters={TargetedCustomersParameters}
            buttonName="+ Add Members"
            showButton={() => {
              setSelectedRows(selectedRows)
              handleMemberDeleteClose()
            }}
            onRowClick={() => {
              handleMemberDeleteOpen()
            }}
            checkboxSelection={true}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </CustomPaper>
      </Modal>

      <Stack padding="30px 30px 15px 30px" gap="20px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "Marketing Portal",
              clickHandler: () => {
                navigate("/submenu/marketing")
              },
            },
            {
              text: "Create Campaign",
              clickHandler: () => {
                navigate("/submenu/marketing/createcampaign")
              },
              isActive: true,
            },
          ]}
        />
        <Typography
          variant="body1"
          style={{
            textAlign: "left",
            marginTop: "0.5rem",
            fontSize: "18px",
          }}
          color="inherit"
        >
          Create Campaign
        </Typography>
        <CustomPaper
          customStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <CustomPaper
            elevation={0}
            customStyles={{
              background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
            }}
          >
            <Typography sx={{ fontSize: "0.8rem" }}>
              Select Recipients Type
              <span style={{ color: "red" }}> *</span>
            </Typography>
            <RadioGroup
              fontSize={"0.8rem"}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Email"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "#FF7935",
                      "&.Mui-checked": {
                        color: "#FF7935",
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>Email</Typography>
                }
              />
              <FormControlLabel
                value="SMS"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "#FF7935",
                      "&.Mui-checked": {
                        color: "#FF7935",
                      },
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: "0.8rem" }}>SMS</Typography>}
              />
              <FormControlLabel
                value="Both"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "#FF7935",
                      "&.Mui-checked": {
                        color: "#FF7935",
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>Both</Typography>
                }
              />
            </RadioGroup>
          </CustomPaper>
        </CustomPaper>
        <CustomPaper
          customStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <CustomPaper
            elevation={0}
            customStyles={{
              background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
            }}
          >
            <Typography sx={{ fontSize: "0.8rem", pb: "0.5rem" }}>
              Recipient Email Addresses
              <span style={{ color: "red" }}> *</span>
            </Typography>
            <Box
              sx={{
                border: "2px solid #6382E9",
                minHeight: "40px",
                width: "100%",
                borderRadius: "12px",
                color: theme.palette.mode === "dark" ? "white" : "black",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(13, 34, 102, 1)"
                    : "white",
              }}
            >
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, p: 1 }}>
                {!selectedRows.length && (
                  <Typography
                    variant="body2"
                    color="accent"
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                        textDecorationThickness: "1px",
                        textUnderlineOffset: "4px",
                      },
                    }}
                    onClick={() => {
                      handleAddMemberOpen()
                    }}
                  >
                    Select members
                  </Typography>
                )}

                {firstFive.map((value) => (
                  <Chip
                    onDelete={() => removeSelectedRows(value)}
                    key={value}
                    label={value}
                    sx={{ borderRadius: "10px" }}
                  />
                ))}

                {selectedRows?.length > 5 && (
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      color="accent"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                          textDecorationThickness: "1px",
                          textUnderlineOffset: "4px",
                        },
                      }}
                      onClick={() => handleMemberDeleteOpen()}
                    >
                      {`+ ${selectedRows?.length - 5} more`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Typography sx={{ fontSize: "0.9rem", py: "0.5rem" }}>
              {selectedRows.length} Customers have been added into the marketing
            </Typography>
            <Typography sx={{ fontSize: "0.8rem", py: "0.5rem" }}>
              Subject
              <span style={{ color: "red" }}> *</span>
            </Typography>
            <input
              aria-label="Subject"
              required
              style={{
                border: "2px solid #6382E9",
                minHeight: "40px",
                width: "100%",
                borderRadius: "12px",
                paddingInline: "15px",
                color: theme.palette.mode === "dark" ? "white" : "black",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(13, 34, 102, 1)"
                    : "white",
              }}
            />
            <Typography sx={{ fontSize: "0.8rem", pb: "0.2rem", pt: "0.8rem" }}>
              Description
              <span style={{ color: "red" }}> *</span>
            </Typography>
            <input
              aria-label="Description"
              required
              style={{
                border: "2px solid #6382E9",
                minHeight: "60px",
                width: "100%",
                borderRadius: "12px",
                paddingInline: "5px",
                color: theme.palette.mode === "dark" ? "white" : "black",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(13, 34, 102, 1)"
                    : "white",
              }}
            />
          </CustomPaper>
        </CustomPaper>
        <CustomPaper
          customStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <CustomPaper
            elevation={0}
            customStyles={{
              background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
            }}
          >
            <FilePicker />
          </CustomPaper>
          <CustomPaper
            elevation={0}
            customStyles={{
              background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
            }}
          >
            <Typography sx={{ fontSize: "0.8rem", pb: "0.2rem" }}>
              Coupon/QR code
              <span style={{ color: "red" }}> *</span>
            </Typography>
            <RadioGroup
              fontSize={"0.8rem"}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Coupon"
                control={
                  <Radio
                    size="small"
                    onClick={handleCouponClick}
                    sx={{
                      color: "#FF7935",
                      "&.Mui-checked": {
                        color: "#FF7935",
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>Coupon</Typography>
                }
              />
              <FormControlLabel
                value="QR code"
                control={
                  <Radio
                    size="small"
                    onClick={handleQrClick}
                    sx={{
                      color: "#FF7935",
                      "&.Mui-checked": {
                        color: "#FF7935",
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>QR code</Typography>
                }
              />
            </RadioGroup>
            <Typography sx={{ fontSize: "0.8rem", py: "0.5rem" }}>
              Campaign offer
              <span style={{ color: "red" }}> *</span>
            </Typography>
            <input
              aria-label="Campaign offer"
              required
              style={{
                border: "2px solid #6382E9",
                minHeight: "40px",
                width: "100%",
                borderRadius: "12px",
                paddingInline: "15px",
                color: theme.palette.mode === "dark" ? "white" : "black",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(13, 34, 102, 1)"
                    : "white",
              }}
              placeholder="20% off on all summer specials menu items"
            />

            <Stack>
              <Stack>
                {showQr && (
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        py: "0.1rem",
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.accent,
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <Qricon />
                      </Box>
                      Generate QR Code
                    </Typography>
                    QR Code
                    <Stack sx={{ mt: 1 }}>
                      <QRCode
                        renderAs="canvas"
                        size={156}
                        bgColor={
                          theme.palette.mode === "dark" ? "#000000" : "#ffffff"
                        }
                        fgColor={
                          theme.palette.mode === "dark" ? "#ffffff" : "#000000"
                        }
                        value="SUMMER20"
                      />
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
            {showCoupon && (
              <stack>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    py: "0.1rem",
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.accent,
                    mt: 1,
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      // mr: 1,
                    }}
                  >
                    <Reload />
                  </Box>
                  Generate Coupon
                </Typography>

                <Typography sx={{ fontSize: "0.8rem", pt: "0.8rem" }}>
                  Coupon Code
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", py: "0.1rem" }}>
                  SUMMER20
                </Typography>
              </stack>
            )}
          </CustomPaper>
          <Divider />
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              sx={{
                border: `1px solid ${theme.palette.accent}`,
                textTransform: "none",
                minWidth: "110px",
                color: theme.palette.accent,
                borderRadius: "20px",
                "&:hover": {
                  border: `1px solid ${theme.palette.accent}`,
                  boxShadow: "5px",
                },
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Box display="flex" gap="10px" justifyContent="space-between">
              <Button
                variant="outlined"
                sx={{
                  border: `1px solid ${theme.palette.accent}`,
                  textTransform: "none",
                  minWidth: "110px",
                  color: theme.palette.accent,
                  borderRadius: "20px",
                  "&:hover": {
                    border: `1px solid ${theme.palette.accent}`,
                    boxShadow: "5px",
                  },
                }}
                onClick={handleSaveDraft}
              >
                Save Draft
              </Button>
              <Button
                variant="contained"
                sx={{
                  minWidth: "110px",
                  bgcolor: theme.palette.accent,
                  textTransform: "none",
                  color: "white",
                  borderRadius: "20px",
                  "&:hover": {
                    bgcolor: theme.palette.accent,
                    boxShadow: "5px",
                  },
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </CustomPaper>
      </Stack>
    </>
  )
}

export default CreateCampaign
