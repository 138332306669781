import { useState, useEffect } from "react"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { useTheme } from "../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedValue } from "../redux/slices/periodComparisonSlice"
import {
  calculatePreviousPeriod,
  calculatePreviousYear,
  formatDate,
  getDateFormatFromLocalStorage,
} from "../utils/dateUtils"

const CompareBy = ({ dateRangeData }) => {
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const selectedValue = useSelector((state) => state.period.selectedValue)

  const [comparisonPeriod, setComparisonPeriod] = useState({
    selectedValue: "Previous Period",
    previousFromDate: 1,
    previousToDate: 1,
  })

  const ComparisonOptions = ["Compared By", "Previous Period", "Previous Year"]

  const formatDateRange = (fromDate, toDate, format) => {
    if (!fromDate || !toDate) return ""
    return `${formatDate(fromDate, format)} - ${formatDate(toDate, format)}`
  }

  useEffect(() => {
    const initializeComparisonPeriod = async () => {
      if (dateRangeData?.fromDate && dateRangeData?.toDate) {
        let initialComparisonPeriod
        if (selectedValue.selectedValue === "Previous Period") {
          initialComparisonPeriod = calculatePreviousPeriod(
            dateRangeData.fromDate,
            dateRangeData.toDate
          )
        } else if (selectedValue.selectedValue === "Previous Year") {
          initialComparisonPeriod = calculatePreviousYear(
            dateRangeData.fromDate,
            dateRangeData.toDate
          )
        } else {
          initialComparisonPeriod = { selectedValue: "Compared By" }
        }

        if (
          initialComparisonPeriod.previousFromDate !==
            comparisonPeriod.previousFromDate ||
          initialComparisonPeriod.previousToDate !==
            comparisonPeriod.previousToDate ||
          initialComparisonPeriod.selectedValue !==
            comparisonPeriod.selectedValue
        ) {
          setComparisonPeriod(initialComparisonPeriod)
          dispatch(
            setSelectedValue({
              selectedValue: initialComparisonPeriod.selectedValue,
              previousFromDate: initialComparisonPeriod.previousFromDate,
              previousToDate: initialComparisonPeriod.previousToDate,
            })
          )
        }
      }
    }
    initializeComparisonPeriod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRangeData.fromDate,
    dateRangeData.toDate,
    selectedValue.selectedValue,
    comparisonPeriod,
    dispatch,
  ])

  const handleChange = async (event) => {
    const selectedOption = event.target.value
    let newComparisonPeriod = { selectedValue: selectedOption }

    if (
      selectedOption === "Previous Period" &&
      dateRangeData?.fromDate &&
      dateRangeData?.toDate
    ) {
      newComparisonPeriod = await calculatePreviousPeriod(
        dateRangeData.fromDate,
        dateRangeData.toDate
      )
    } else if (
      selectedOption === "Previous Year" &&
      dateRangeData?.fromDate &&
      dateRangeData?.toDate
    ) {
      newComparisonPeriod = await calculatePreviousYear(
        dateRangeData.fromDate,
        dateRangeData.toDate
      )
    }

    setComparisonPeriod(newComparisonPeriod)

    dispatch(
      setSelectedValue({
        selectedValue: selectedOption,
        previousFromDate: newComparisonPeriod.previousFromDate,
        previousToDate: newComparisonPeriod.previousToDate,
      })
    )
  }

  const renderComparisonText = () => {
    const { previousFromDate, previousToDate, selectedValue } = comparisonPeriod
    if (!previousFromDate || !previousToDate) return selectedValue

    const dateFormat = getDateFormatFromLocalStorage()

    if (selectedValue === "Previous Period") {
      return `Previous Period (${formatDateRange(
        previousFromDate,
        previousToDate,
        "DD MMM"
      )})`
    } else if (selectedValue === "Previous Year") {
      return `Previous Year (${formatDateRange(
        previousFromDate,
        previousToDate,
        dateFormat
      )})`
    }
    return selectedValue
  }

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: "auto",
        height: "31px",
      }}
    >
      <Select
        value={selectedValue.selectedValue}
        onChange={handleChange}
        displayEmpty
        renderValue={() => renderComparisonText()}
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#112B7F" : "#E9EDFC",
          borderRadius: "10px",
          fontSize: "12px",
          "& .MuiSelect-iconOpen, & .MuiSelect-icon": {
            fontSize: "20px",
            color: theme.palette.accentSecondary,
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        }}
      >
        {ComparisonOptions.map((option, index) => (
          <MenuItem key={index} value={option} sx={{ fontSize: "12px" }}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CompareBy
