import Box from "@mui/material/Box"
import { ResponsiveRadialBar } from "@nivo/radial-bar"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"
import CustomLegend from "../../CustomLegend"

const NivoRadialBarChart = ({
  chartInput = [],
  chartConfig,
  reportTitle,
  reportData,
  showAiDropdown,
  showTooltipPrevious,
}) => {
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: "rgba(29, 71, 206, 0.3)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  const colorScheme = [
    "#36a2eb",
    "#FF6384",
    "#17a2b8",
    "#a17bc8",
    "#8dd3c7",
    "#fccde5",
    "#2271B4",
    "#6E8B3D",
    "#C75D8A",
    "#DB7093",
    "#FF8C00",
    "#9370DB",
    "#FFE4B5",
    "#BA55D3",
    "#B0E0E6",
    "#8B4513",
    "#00CED1",
    "#CD5C5C",
    "#3CB371",
    "#A7CEFA",
    "#BDB76B",
    "#D2691E",
    "#00FA9A",
    "#F4A460",
    "#FF69B4",
    "#8A2BE2",
    "#2E8B57",
  ]

  const legendsData =
    chartInput && Array.isArray(chartInput)
      ? [...chartInput[0].data].map((item, idx) => ({
          label: item?.x,
          color: colorScheme[idx],
        }))
      : []
  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      <Box sx={{ height: "350px" }}>
        <ResponsiveRadialBar
          data={chartInput}
          theme={customTheme}
          valueFormat=">-.2f"
          innerRadius={0.5}
          padding={0.1}
          cornerRadius={2}
          margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
          radialAxisStart={null}
          colors={colorScheme}
          // radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
          circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
          labelsSkipAngle={29}
          labelsRadiusOffset={1.1}
          motionConfig="slow"
          tooltip={(point) => (
            <CustomTooltip
              data={point.bar.data}
              showCurrentPrevDate={true}
              showLegendName={true}
              dataDatum={point.bar}
              label={point.bar.data}
              showTooltipPrevious={showTooltipPrevious}
            />
          )}
        />
      </Box>
      <CustomLegend
        legendsData={legendsData}
        alignment="flex-end"
        reportTitle={reportTitle}
        reportData={reportData}
        showAiDropdown={showAiDropdown}
      />
    </Box>
  )
}

export default NivoRadialBarChart
