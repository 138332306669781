import Box from "@mui/material/Box"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import Logo from "../../assets/svgs/Logo"
import { useTheme } from "../../contexts/theme"

function RouteLoader() {
  const { theme } = useTheme()
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          height="100vh"
        >
          <CircularProgress size={100} thickness={2} color="info" />
          <Logo
            width="177"
            height="94"
            fill={theme.palette.text.primary}
            yfill={
              theme.palette.mode === "light" ? theme.palette.accent : "white"
            }
          />
        </Box>
      </Backdrop>
    </>
  )
}

export default RouteLoader
