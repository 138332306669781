import { createSlice } from "@reduxjs/toolkit"

export const salesSlice = createSlice({
  name: "sales",
  initialState: {
    sales: null,
    sales_uid: null,
    reports: [],
  },
  reducers: {
    loadData: (state, action) => {
      const { sales, sales_uid, reports } = action.payload
      state.sales = sales
      state.sales_uid = sales_uid
      state.reports = reports
    },
    setChartData: (state, action) => {
      const { reportUid, response, comparisonPeriod, viewBy } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )
      if (selectedReport) selectedReport.reportData = response
      if (selectedReport && comparisonPeriod)
        selectedReport.comparisonPeriod = comparisonPeriod
      if (selectedReport && viewBy) selectedReport.viewBy = viewBy
    },
  },
})

export const { loadData, setChartData } = salesSlice.actions

export default salesSlice.reducer
