const Star = ({ color = "#F2CD4A" }) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.7437 0.676886C8.93736 0.0894739 9.78043 0.0894759 9.97409 0.676889L11.5372 5.41905C11.6238 5.68174 11.8721 5.8596 12.1524 5.8596H17.2108C17.8374 5.8596 18.0979 6.64993 17.591 7.01297L13.4986 9.94382C13.2719 10.1061 13.1771 10.394 13.2637 10.6566L14.8268 15.3988C15.0205 15.9862 14.3384 16.4747 13.8315 16.1117L9.7391 13.1808C9.51239 13.0185 9.2054 13.0185 8.97869 13.1808L4.88632 16.1117C4.3794 16.4747 3.69733 15.9862 3.89096 15.3988L5.45411 10.6566C5.5407 10.394 5.44584 10.1061 5.21914 9.94382L1.12676 7.01297C0.619831 6.64993 0.880359 5.8596 1.50695 5.8596H6.56541C6.84566 5.8596 7.09398 5.68174 7.18058 5.41905L8.7437 0.676886Z"
        fill={color}
      />
    </svg>
  )
}

export default Star
