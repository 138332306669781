const YaxisUpArrow = () => {
  return (
    <>
      <svg
        width="13"
        height="90"
        viewBox="0 0 13 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.12212 89.0998L6.85146 1.88066M6.85146 1.88066L11.6827 7.49379M6.85146 1.88066L1.68309 7.41017"
          stroke="#6180B6"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default YaxisUpArrow
