const ApplePay = () => {
  return (
    <svg
      width="72"
      height="30"
      viewBox="0 0 72 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.61143 5.562C10.9614 5.676 12.3114 4.878 13.1574 3.861C13.9884 2.823 14.5374 1.416 14.4024 0C13.1994 0.057 11.7264 0.798 10.8834 1.839C10.1034 2.727 9.44043 4.191 9.61143 5.562ZM6.26943 24.138C7.76343 24.081 8.35143 23.157 10.1514 23.157C11.9634 23.157 12.4914 24.138 14.0664 24.117C15.6984 24.084 16.7214 22.635 17.7204 21.147C18.426 20.0935 18.9757 18.9437 19.3524 17.733C19.3194 17.7 16.2024 16.488 16.1664 12.834C16.1334 9.774 18.6324 8.322 18.7404 8.232C17.3334 6.108 15.1404 5.88 14.3754 5.823L14.3814 5.826C12.4224 5.709 10.7484 6.954 9.82443 6.954C8.87943 6.954 7.46343 5.88 5.90943 5.913C3.89943 5.949 2.03043 7.104 1.00743 8.94C-1.10757 12.627 0.455426 18.075 2.50143 21.078C3.50343 22.56 4.69443 24.195 6.26943 24.138ZM42.7494 12.561H45.8214C46.0794 11.19 47.3274 10.287 49.0404 10.287C51.1224 10.287 52.2924 11.268 52.2924 13.083V14.316L48.0414 14.58C44.0934 14.82 41.9544 16.464 41.9544 19.317C41.9664 22.194 44.1684 24.111 47.3304 24.114C49.4694 24.114 51.4494 23.019 52.3464 21.273H52.4124V23.946H55.5624V12.87C55.5624 9.663 53.0304 7.584 49.1394 7.584C45.5274 7.584 42.8544 9.681 42.7494 12.561ZM52.3044 17.919C52.3044 19.998 50.5584 21.48 48.2544 21.48C46.4424 21.48 45.2844 20.589 45.2844 19.242C45.2844 17.835 46.3974 17.028 48.5244 16.902L52.3044 16.662V17.919ZM25.6734 1.689V23.943H29.0814V16.338H33.7944C38.1024 16.338 41.1174 13.335 41.1174 8.997C41.1174 4.659 38.1504 1.689 33.9054 1.689H25.6734ZM37.6554 9.009C37.6554 11.817 35.9694 13.44 32.9964 13.44H29.0814V4.602H33.0084C35.9664 4.602 37.6554 6.198 37.6554 9.009ZM64.4604 20.94L60.4554 7.8H56.9004L62.6604 24.003L62.3454 24.984C61.8264 26.649 60.9834 27.303 59.4774 27.303C59.2074 27.303 58.6914 27.27 58.4754 27.246V29.919C58.6794 29.967 59.5254 30 59.7834 30L59.7804 29.994C63.0984 29.994 64.6614 28.704 66.0264 24.81L71.9994 7.8H68.5344L64.5294 20.94H64.4604Z"
        fill="#212121"
      />
    </svg>
  );
};

export default ApplePay;
