import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { calculatePreviousPeriod, formatDate } from "../../utils/dateUtils"

const initialState = {
  fromDate: formatDate(
    dayjs().subtract(1, "month").startOf("month"),
    "YYYYMMDD"
  ),
  toDate: formatDate(dayjs().subtract(1, "month").endOf("month"), "YYYYMMDD"),
  label: {
    shortcutLabel: "Last Month",
    formattedRange: `${formatDate(
      dayjs().subtract(1, "month").startOf("month"),
      "MMM D"
    )} - ${formatDate(dayjs().subtract(1, "month").endOf("month"), "MMM D")}`,
  },
}

const initialComparisonPeriod = calculatePreviousPeriod(
  initialState.fromDate,
  initialState.toDate
)

const periodComparisonSlice = createSlice({
  name: "period",
  initialState: {
    selectedValue: {
      selectedValue: "Previous Period",
      previousFromDate: initialComparisonPeriod.previousFromDate,
      previousToDate: initialComparisonPeriod.previousToDate,
    },
  },
  reducers: {
    setSelectedValue: (state, action) => {
      state.selectedValue = action.payload
    },
  },
})

export const { setSelectedValue } = periodComparisonSlice.actions

export default periodComparisonSlice.reducer
