import React, { useEffect, useState } from "react"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { useTheme } from "../../contexts/theme"
import { useNavigate } from "react-router-dom"

const SessionModal = ({ open, onClose, onContinue }) => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  const colors = {
    accentColor: theme.palette.mode === "dark" ? "#F3884A" : "#1D47CE",
    textColor: theme.palette.mode === "dark" ? "#fff" : "#000",
    bgColor: theme.palette.mode === "dark" ? "#0D2266" : "#FFFFFF",
  }

  const [countdown, setCountdown] = useState(30)
  const [timeoutReached, setTimeoutReached] = useState(false)

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timer)
            setTimeoutReached(true)

            localStorage.clear()
          }
          return prevCountdown - 1
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [open])

  const handleOk = () => {
    redirectToLogin()
    onClose()
  }

  const redirectToLogin = () => {
    navigate("/login")
    console.log("Redirecting to login page...")
  }

  const handleClose = () => {
    if (timeoutReached) {
      redirectToLogin()
    } else {
      onClose()
    }
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="inactivity-modal-title"
      aria-describedby="inactivity-modal-description"
      closeAfterTransition
      disableEscapeKeyDown={timeoutReached}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: colors.bgColor,
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          id="inactivity-modal-title"
          sx={{ color: colors.textColor }}
        >
          {timeoutReached ? "Timeout" : "Inactivity Warning"}
        </Typography>

        <Typography
          variant="body1"
          id="inactivity-modal-description"
          gutterBottom
          sx={{
            mb: 1,
            color: colors.textColor,
          }}
        >
          {timeoutReached
            ? "Your session has timed out. Please click 'OK' to continue."
            : `You will be logged out due to inactivity in ${formatTime(
                countdown
              )}. Please click "Continue" to remain logged in.`}
        </Typography>

        {!timeoutReached && (
          <Button
            variant="contained"
            onClick={() => {
              onContinue()
              onClose()
            }}
            sx={{
              py: 0.8,
              width: "10vw",
              backgroundColor: colors.accentColor,
              color: "#fff",
              textAlign: "center",
              borderRadius: "24px",
              textTransform: "none",
            }}
          >
            Continue
          </Button>
        )}

        <Button
          variant="contained"
          onClick={handleOk}
          sx={{
            py: 0.8,
            mt: 2,
            width: "10vw",
            backgroundColor: colors.accentColor,
            color: "#fff",
            textAlign: "center",
            borderRadius: "24px",
            textTransform: "none",
            display: timeoutReached ? "block" : "none",
          }}
        >
          OK
        </Button>
      </Box>
    </Modal>
  )
}

export default SessionModal
