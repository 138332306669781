import Lottie from "react-lottie"
import analyticaLight from "../lotties/analyticaLight.json"
import analyticaDark from "../lotties/analyticaDark.json"

const AnalyticaInfoIcon = ({ mode, height = "42px", width = "43px" }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: mode === "dark" ? analyticaLight : analyticaDark,
    rendererSettings: {},
  }

  return <Lottie options={defaultOptions} height={height} width={width} />
}

export default AnalyticaInfoIcon
