import { ResponsivePie } from "@nivo/pie"
import Box from "@mui/material/Box"
import { useTheme } from "../../../../contexts/theme"
import InfoLogoSvg from "../../../../assets/svgs/InfoLogoSvg"
import InfoToolTip from "../../../../components/InfoToolTip"

const LeavesCategorization = ({ title = "", description="" }) => {
  const data = [
    {
      id: "Contingency (CL)",
      label: "Contingency Leave",
      value: "12",
      innerRadius: 0.7,
    },
    {
      id: "Privilege (PL)",
      label: "Privilege Leave",
      value: "12",
      innerRadius: 0.7,
    },
    {
      id: "Option (OL)",
      label: "Option Leave",
      value: "12",
      innerRadius: 0.7,
    },
  ]
  const customTheme = {
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
  }
  const colorScheme = ["#DE4C08", "#F19004", "#FCBF49"]
  const { theme } = useTheme()

  const svgColor = theme.palette.mode === "dark" ? "#BCCAF6" : "#1D47CE"

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <div className="sales">{title}</div>
          {title !== "" ? (
            <InfoToolTip title={description}>
              <span style={{ height: "16px", cursor: "pointer" }}>
                <InfoLogoSvg svgColor={svgColor} />
              </span>
            </InfoToolTip>
          ) : (
            ""
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-evenly"
        alignContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box
          display="flex"
          flexDirection={{
            sm: "column",
            md: "row",
            lg: "row",
          }}
          justifyContent="center"
          alignContent="center"
          sx={{ height: "250px", width: "400px" }}
          padding="10px"
        >
          <ResponsivePie
            data={data}
            colors={colorScheme}
            theme={customTheme}
            margin={{
              top: 12,
              right: 10,
              bottom: 22,
              left: 68,
            }}
            startAngle={-140}
            innerRadius={0.5}
            padAngle={0}
            cornerRadius={0}
            activeInnerRadiusOffset={0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabel="id"
            arcLinkLabelsTextOffset={10}
            arcLinkLabelsSkipAngle={14}
            arcLinkLabelsTextColor={
              theme.palette.mode === "dark" ? "#fff" : "#333333"
            }
            arcLinkLabelsColor={{ from: "color" }}
            arcLinkLabelsOffset={1}
            arcLinkLabelsDiagonalLength={10}
            arcLinkLabelsStraightLength={6}
            arcLinkLabelsThickness={2}
            enableArcLabels={false}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            animate={true}
          />
        </Box>
      </Box>
    </>
  )
}

export default LeavesCategorization
