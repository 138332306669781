import { useEffect, useState, useRef, useCallback } from "react"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import { useTheme } from "./contexts/theme"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import Container from "@mui/material/Container"
import Header from "./components/header/Header"
import SessionModal from "./routes/authentication/SessionModal"
import Analytica from "./components/Analytica"

const App = () => {
  const { theme, resetColorMode } = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"))
  const [showInactiveModal, setShowInactiveModal] = useState(false)
  const inactiveTimerRef = useRef(null)

  useEffect(() => {
    if (isLoggedIn && location.pathname === "/") {
      navigate("/businesssynopsis", { replace: true })
    }
  }, [isLoggedIn, location, navigate])

  const debounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        func(...args)
      }, delay)
    }
  }

  const resetInactiveTimer = useCallback(
    debounce(() => {
      if (inactiveTimerRef.current) {
        clearTimeout(inactiveTimerRef.current)
      }
      inactiveTimerRef.current = setTimeout(() => {
        setShowInactiveModal(true)
      }, 600000)
    }, 700),
    []
  )

  useEffect(() => {
    resetInactiveTimer()
    window.addEventListener("mousemove", resetInactiveTimer)
    window.addEventListener("keydown", resetInactiveTimer)
    window.addEventListener("scroll", resetInactiveTimer)

    return () => {
      window.removeEventListener("mousemove", resetInactiveTimer)
      window.removeEventListener("keydown", resetInactiveTimer)
      window.removeEventListener("scroll", resetInactiveTimer)
      clearTimeout(inactiveTimerRef.current)
    }
  }, [resetInactiveTimer])

  const handleCloseInactiveModal = () => {
    setShowInactiveModal(false)
  }
  useEffect(() => {
    const storedThemeMode = localStorage.getItem("themeMode")
    if (storedThemeMode) {
      resetColorMode(storedThemeMode)
    }
  }, [resetColorMode])

  const handleLogout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.removeItem("entityDb")
    localStorage.removeItem("userId")
    localStorage.removeItem("fname")
    localStorage.removeItem("lname")
    localStorage.removeItem("tokenExpiry")
    localStorage.removeItem("timeFormat")
    localStorage.removeItem("dataStatus")
    localStorage.removeItem("dateFormat")
    localStorage.removeItem("defaultPage")
    localStorage.removeItem("entityId")
    localStorage.removeItem("APIKey")
    localStorage.removeItem("AccessToken")
    localStorage.removeItem("ClientID")
    localStorage.removeItem("subscriptionStatus")
    localStorage.removeItem("themeMode")
    
    navigate("/login")
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login")
    }
  }, [isLoggedIn, navigate])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header handleLogout={handleLogout} />
        <CssBaseline />
        <Container maxWidth="xl">
          <Outlet />
          {/* Uncomment to enable Chatbot FAB (Floating Action Button) */}
          {/* <Box
            id="chatbot"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              marginLeft: "auto",
              position: "fixed",
              bottom: 20,
              height: "unset",
              right: {
                sm: "59px",
                md: "59px",
                lg: "59px",
                xl: "calc((100vw - 1536px) / 2 + 59px)",
              },
              zIndex: "50",
            }}
          >
            <Analytica
              component="chatbot"
              mode={theme.palette.mode}
              height="48px"
              width="49px"
            />
          </Box> */}
        </Container>
      </ThemeProvider>
      <SessionModal
        open={showInactiveModal}
        onClose={handleCloseInactiveModal}
        onContinue={() => setShowInactiveModal(false)}
      />
    </>
  )
}

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const isLoggedIn = !!localStorage.getItem("token")

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login")
    }
  }, [isLoggedIn, navigate])

  return isLoggedIn ? children : null
}

export default App
