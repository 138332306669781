import { useTheme } from "../../contexts/theme"

const InterIcon = () => {
  const { theme } = useTheme()
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33301 6.33203C3.33301 4.91782 3.33301 4.21071 3.77235 3.77137C4.21169 3.33203 4.9188 3.33203 6.33301 3.33203C7.27582 3.33203 7.74722 3.33203 8.04011 3.62492C8.33301 3.91782 8.33301 4.38922 8.33301 5.33203V6.33203C8.33301 7.27484 8.33301 7.74624 8.04011 8.03913C7.74722 8.33203 7.27582 8.33203 6.33301 8.33203H5.33301C4.3902 8.33203 3.9188 8.33203 3.6259 8.03913C3.33301 7.74624 3.33301 7.27484 3.33301 6.33203Z"
          stroke={theme.palette.accent}
          strokeWidth="2"
        />
        <path
          d="M3.33301 14.668C3.33301 13.2538 3.33301 12.5466 3.77235 12.1073C4.21169 11.668 4.9188 11.668 6.33301 11.668C7.27582 11.668 7.74722 11.668 8.04011 11.9609C8.33301 12.2538 8.33301 12.7252 8.33301 13.668V14.668C8.33301 15.6108 8.33301 16.0822 8.04011 16.3751C7.74722 16.668 7.27582 16.668 6.33301 16.668H5.33301C4.3902 16.668 3.9188 16.668 3.6259 16.3751C3.33301 16.0822 3.33301 15.6108 3.33301 14.668Z"
          stroke={theme.palette.accent}
          strokeWidth="2"
        />
        <path
          d="M11.667 5.33203C11.667 4.38922 11.667 3.91782 11.9599 3.62492C12.2528 3.33203 12.7242 3.33203 13.667 3.33203C15.0812 3.33203 15.7883 3.33203 16.2277 3.77137C16.667 4.21071 16.667 4.91782 16.667 6.33203C16.667 7.27484 16.667 7.74624 16.3741 8.03913C16.0812 8.33203 15.6098 8.33203 14.667 8.33203H13.667C12.7242 8.33203 12.2528 8.33203 11.9599 8.03913C11.667 7.74624 11.667 7.27484 11.667 6.33203V5.33203Z"
          stroke={theme.palette.accent}
          strokeWidth="2"
        />
        <path
          d="M11.667 13.668C11.667 12.7252 11.667 12.2538 11.9599 11.9609C12.2528 11.668 12.7242 11.668 13.667 11.668C15.0812 11.668 15.7883 11.668 16.2277 12.1073C16.667 12.5466 16.667 13.2538 16.667 14.668C16.667 15.6108 16.667 16.0822 16.3741 16.3751C16.0812 16.668 15.6098 16.668 14.667 16.668H13.667C12.7242 16.668 12.2528 16.668 11.9599 16.3751C11.667 16.0822 11.667 15.6108 11.667 14.668V13.668Z"
          stroke={theme.palette.accent}
          strokeWidth="2"
        />
      </svg>
    </>
  )
}

export default InterIcon
