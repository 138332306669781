const TopCust = () => {
  return (
    <>
      <svg
        width="8"
        height="20"
        viewBox="0 0 6 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="6"
          height="11"
          rx="3"
          fill="url(#paint0_linear_1041_29378)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1041_29378"
            x1="3"
            y1="0"
            x2="3"
            y2="11"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.03125" stopColor="#8951FF" />
            <stop offset="1" stopColor="#FF8F3F" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default TopCust
