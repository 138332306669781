const Invoices = ({ svgColor }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5290_27614)">
        <path
          d="M10.6673 0.667969H2.66732C1.93398 0.667969 1.33398 1.26797 1.33398 2.0013V11.3346H2.66732V2.0013H10.6673V0.667969ZM10.0007 3.33464L14.0007 7.33463V14.0013C14.0007 14.7346 13.4007 15.3346 12.6673 15.3346H5.32732C4.59398 15.3346 4.00065 14.7346 4.00065 14.0013L4.00732 4.66797C4.00732 3.93464 4.60065 3.33464 5.33398 3.33464H10.0007ZM9.33398 8.0013H13.0007L9.33398 4.33464V8.0013Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_5290_27614">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Invoices
