import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import CustomPaper from "../../../../components/CustomPaper"

const JobInformation = () => {
  return (
    <CustomPaper>
      <Box>
        {/* Job Information */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          borderBottom="2px solid #091743"
        >
          <Typography mb="10px" color="inherit">
            Job Information
          </Typography>
        </Box>

        {/* Job Information Details */}
        <Box
          display="flex"
          justifyContent="space-between"
          mt={1.5}
          flexWrap="wrap"
          gap="20px"
        >
          {/* Department */}
          <Stack rowGap="5px">
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                opacity: "70%",
                fontSize: "0.85rem",
              }}
            >
              Department
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontSize: "1.1rem" }}
            >
              Kitchen
            </Typography>
          </Stack>

          {/* Group */}
          <Stack rowGap="5px">
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                opacity: "70%",
                fontSize: "0.85rem",
              }}
            >
              Group
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontSize: "1.1rem" }}
            >
              Chef
            </Typography>
          </Stack>

          {/* Date of Joining */}
          <Stack rowGap="5px">
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                opacity: "70%",
                fontSize: "0.85rem",
              }}
            >
              Date of Joining
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontSize: "1.1rem" }}
            >
              1 March 2024
            </Typography>
          </Stack>
        </Box>
      </Box>
    </CustomPaper>
  )
}

export default JobInformation
