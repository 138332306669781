import RouteTitle from "../../components/RouteTitle"
import Table from "../../components/datagrid/Table"
import Box from "@mui/material/Box"
import DatePicker from "../../components/DatePicker"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const VendorsList = () => {
  const dateRangeData = useSelector((state) => state.calendar)
  const navigate = useNavigate()
  const handleRowClick = () => {
    // navigate("/vendors/allvendors/vendor/1")
  }

  const filtersData = useSelector((state) => {
    return state.filters.vendorList
  })

  return (
    <>
      <RouteTitle title={`All Vendors ${filtersData?.filtersString}`}>
        <DatePicker />
      </RouteTitle>

      <Box padding="15px 30px 30px 30px">
        <Table
          title="Vendors"
          onRowClick={handleRowClick}
          dateRangeData={dateRangeData}
          reportUid="90187ae0-526d-4772-808a-2f32bf96b6c5"
          postUrl="vendor/list"
          parameters={[
            {
              parameterType: "COLUMNS",
              reportParameters: [
                {
                  parameterName: "columns",
                  parameterValue: [
                    {
                      field: "ID",
                      headerName: "ID",
                      flex: 1,
                      minWidth: 200,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "Name",
                      headerName: "Name",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                    {
                      field: "City",
                      headerCity: "City",
                      flex: 1,
                      minWidth: 150,
                      headerClassName: "datagrid-header",
                    },
                  ],
                },
              ],
            },
          ]}
          route="vendorList"
          showExportButton={true}
          showFiltersButton={true}
        />
      </Box>
    </>
  )
}

export default VendorsList
