import { useState } from "react"
import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker"
import { useDispatch, useSelector } from "react-redux"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { setDateRangeLabel } from "../redux/slices/calendarSlice"
import { useTheme } from "../contexts/theme"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { formatDate } from "../utils/dateUtils"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"

const Label = ({ text = "", isActive = false }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {isActive && (
          <path d="M1 3.1L4.61111 8L14 1" stroke="#50CD89" strokeWidth="2" />
        )}
      </svg>
      <Typography
        variant="body2"
        color="inherit"
        fontWeight={isActive ? "bold" : "regular"}
        sx={{
          "&:hover": {
            fontWeight: "bold",
          },
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const DatePicker = () => {
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const [showCalendar, setshowCalendar] = useState(false)
  const dateRangeData = useSelector((state) => state.calendar)
  const [dateRange, setDateRange] = useState([])

  const calendarInput = {
    ...dateRangeData,
    fromDate: dayjs(dateRangeData.fromDate),
    toDate: dayjs(dateRangeData.toDate),
  }

  const shortcutsItems = [
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "Today"}
          text="Today"
        />
      ),
      getValue: () => {
        const today = dayjs()
        return [today.startOf("day"), today.endOf("day")]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "Yesterday"}
          text="Yesterday"
        />
      ),
      getValue: () => {
        const yesterday = dayjs().subtract(1, "day")
        return [yesterday.startOf("day"), yesterday.endOf("day")]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "This Week"}
          text="This Week"
        />
      ),
      getValue: () => {
        const today = dayjs()
        return [today.startOf("week"), today.subtract(1, "day").endOf("day")]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "Last 14 Days"}
          text="Last 14 Days"
        />
      ),
      getValue: () => {
        const today = dayjs()
        return [
          today.subtract(13, "day").startOf("day"),
          today.subtract(1, "day").endOf("day"),
        ]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "Last 30 Days"}
          text="Last 30 Days"
        />
      ),
      getValue: () => {
        const today = dayjs()
        return [
          today.subtract(29, "day").startOf("day"),
          today.subtract(1, "day").endOf("day"),
        ]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "This Month"}
          text="This Month"
        />
      ),
      getValue: () => {
        const today = dayjs()
        return [today.startOf("month"), today.subtract(1, "day").endOf("day")]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "Last Month"}
          text="Last Month"
        />
      ),
      getValue: () => {
        const today = dayjs().subtract(1, "month")
        return [today.startOf("month"), today.endOf("month")]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "This Quarter"}
          text="This Quarter"
        />
      ),
      getValue: () => {
        const today = dayjs()
        const currentQuarter = Math.floor((today.month() + 3) / 3)
        let startOfQuarter

        switch (currentQuarter) {
          case 1: // January 1st - March 31st
            startOfQuarter = dayjs().startOf("year")
            break
          case 2: // April 1st - June 30th
            startOfQuarter = dayjs().month(3).startOf("month")
            break
          case 3: // July 1st - September 30th
            startOfQuarter = dayjs().month(6).startOf("month")
            break
          default: // October 1st - December 31st
            startOfQuarter = dayjs().month(9).startOf("month")
            break
        }

        return [startOfQuarter, today.subtract(1, "day").endOf("day")]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "Last Quarter"}
          text="Last Quarter"
        />
      ),
      getValue: () => {
        const today = dayjs()
        const currentQuarter = Math.floor((today.month() + 3) / 3)
        let startOfQuarter
        let endOfQuarter

        switch (currentQuarter) {
          case 1: // Last quarter was October 1st - December 31st
            startOfQuarter = dayjs()
              .month(9)
              .startOf("month")
              .subtract(1, "year")
            endOfQuarter = dayjs().month(11).endOf("month").subtract(1, "year")
            break
          case 2: // Last quarter was January 1st - March 31st
            startOfQuarter = dayjs().startOf("year")
            endOfQuarter = dayjs().month(2).endOf("month")
            break
          case 3: // Last quarter was April 1st - June 30th
            startOfQuarter = dayjs().month(3).startOf("month")
            endOfQuarter = dayjs().month(5).endOf("month")
            break
          default: // Last quarter was July 1st - September 30th
            startOfQuarter = dayjs().month(6).startOf("month")
            endOfQuarter = dayjs().month(8).endOf("month")
            break
        }

        return [startOfQuarter, endOfQuarter]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "This Year"}
          text="This Year"
        />
      ),
      getValue: () => {
        const today = dayjs()
        return [today.startOf("year"), today.subtract(1, "day").endOf("day")]
      },
    },
    {
      label: (
        <Label
          isActive={calendarInput?.label.shortcutLabel === "Last Year"}
          text="Last Year"
        />
      ),
      getValue: () => {
        const today = dayjs().subtract(1, "year")
        return [today.startOf("year"), today.endOf("year")]
      },
    },
  ]

  const handleDateRangeChange = (newValue, context) => {
    setDateRange(newValue)
    const [fromDate, toDate] = newValue
    const shortcutLabel = context?.shortcut?.label?.props?.text

    if (fromDate && toDate && shortcutLabel) {
      const label = {
        shortcutLabel: shortcutLabel,
        formattedRange: `${formatDate(fromDate, "MMM D")} - ${formatDate(
          toDate,
          "MMM D"
        )}`,
      }
      dispatch(
        setDateRangeLabel({
          fromDate,
          toDate,
          label,
        })
      )
    }
    // setshowCalendar(false)
  }

  const handleDateRangeClick = () => {
    const [fromDate, toDate] = dateRange
    if (fromDate && toDate) {
      const label = {
        shortcutLabel: `${formatDate(fromDate, "MMM D")} - ${formatDate(
          toDate,
          "MMM D"
        )}`, // Set as null or a specific value if needed
        formattedRange: `${formatDate(fromDate, "MMM D")} - ${formatDate(
          toDate,
          "MMM D"
        )}`,
      }
      dispatch(
        setDateRangeLabel({
          fromDate,
          toDate,
          label,
        })
      )
    }
    setshowCalendar(false)
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        disableRipple
        variant="contained"
        color="primary"
        sx={{
          "& .MuiTypography-root": {
            color: "inherit",
          },
          textTransform: "none",
          borderRadius: "10px",
          boxShadow: "none",
          padding: "7.5px 14px",
          mt: "-1px",
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#112B7F" : "#E9EDFC",
            boxShadow: "none",
          },
        }}
        endIcon={
          showCalendar ? (
            <ArrowDropUpIcon sx={{ color: theme.palette.accentSecondary }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: theme.palette.accentSecondary }} />
          )
        }
        onClick={() => setshowCalendar((prevState) => !prevState)}
      >
        <Typography
          sx={{ fontSize: "12px" }}
          color={
            theme.palette.mode === "dark" ? "inherit" : theme.palette.accent
          }
        >
          {["This Week", "This Month", "This Quarter", "This Year"].includes(
            dateRangeData?.label?.shortcutLabel
          )
            ? dateRangeData?.label?.formattedRange
            : dateRangeData?.label?.shortcutLabel}
        </Typography>
      </Button>
      {showCalendar && (
        <Box
          display="flex"
          sx={{
            zIndex: 20,
            position: "absolute",
            right: 0,
            top: {
              xs: 70.5,
              sm: 63.5,
              md: 63,
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
              <Box display="flex">
                <StaticDateRangePicker
                  value={[calendarInput.fromDate, calendarInput.toDate]}
                  slotProps={{
                    shortcuts: {
                      items: shortcutsItems,
                    },
                    actionBar: {
                      actions: ["cancel"],
                    },
                  }}
                  calendars={2}
                  sx={{
                    ".MuiDayCalendar-weekContainer": {
                      margin: 0,
                    },
                    ".MuiDateRangeCalendar-monthContainer ": {
                      paddingX: "9px",
                    },
                    ".MuiPickersDay-today": {
                      border: `2px solid ${theme.palette.accent}45 !important`,
                    },
                    ".MuiTypography-overline": {
                      display: "none",
                    },
                    "& .MuiPickersLayout-actionBar": {
                      gridColumnStart: 2,
                      gridColumnEnd: 4,
                      "& .MuiButton-root": {
                        "&:hover": {
                          fontWeight: "bold",
                        },
                      },
                      "& .MuiButton-root > span": {
                        display: "none",
                      },
                    },
                    "& .MuiChip-root": {
                      color:
                        theme.palette.mode === "dark" ? "#fff75" : "#00075",
                      background: "unset",
                      "&:hover": {
                        color: theme.palette.mode === "dark" ? "#fff" : "#000",
                        fontWeight: "bold",
                        background: "unset",
                      },
                    },
                    "& .MuiChip-label": {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                      padding: "unset",
                    },
                    "& .MuiTypography-subtitle1": {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                    "& .MuiPickersToolbar-root": {
                      display: "flex",
                      flexDirection: "row",
                      padding: "-10px -30px -10px  30px",
                      justifyContent: "center !important",
                      "& .MuiPickersToolbar-content": {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center !important",
                      },
                    },
                    "& .MuiList-root": {
                      gridColumn: 1,
                      gridRowStart: 1,
                      gridRowEnd: 4,
                      display: "flex",
                      flexDirection: "column",
                      minHeight: "485px",
                      overflow: "unset",
                      justifyContent: "center",
                      padding: "9px",
                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#1b4cf00a",
                      "& > li > div": {
                        borderRadius: "0px",
                      },
                      "& > li > div *": {
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      },
                      // disable ripple
                      "& > li > div > span:nth-child(2)": {
                        display: "none",
                      },
                      // First border after the third item
                      "& > li:nth-child(3) > div > span > div > p": {
                        borderBottom: "1px solid",
                        borderColor: theme.palette.divider,
                      },
                      // Subsequent borders after every two items starting from the fourth
                      "& > li:nth-child(n+4):nth-child(2n-1) > div > span > div > p":
                        {
                          borderBottom: "1px solid",
                          borderColor: theme.palette.divider,
                        },
                      // Remove border from the last element
                      "& > li:last-child > div > span > div > p": {
                        borderBottom: "none !important",
                      },
                    },
                    "& .MuiListItem-root": {
                      marginRight: "15px",
                    },
                    "& .MuiDateRangePickerToolbar-root": {
                      gridColumn: 2,
                      gridRow: 1,
                    },
                    // MUI License missing message
                    "& .MuiDateRangeCalendar-root": {
                      "& > div[style*='z-index: 100000']": {
                        display: "none",
                      },
                    },
                    "& .MuiDialogActions-root": {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                    ".MuiButton-root": {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                    ".MuiDateRangePickerDay-root": {
                      background: "none",
                      padding: "1px",
                    },
                    ".MuiDayCalendar-weekContain": {
                      margin: "0px",
                      borderRadius: "10px",
                    },
                    ".MuiDateRangePickerDay-notSelectedDate": {
                      borderRadius: "10px",
                    },
                    borderRadius: "10px",
                    alignItems: "start",
                    // Main background
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#040D27" : "#e7ecff",
                    "& .MuiPickersDay-root": {
                      borderRadius: "10px",
                      "&.Mui-selected": {
                        backgroundColor: `${theme.palette.accent} !important`,
                        color: "white",
                      },
                      "&.MuiDateRangePickerDay-dayInsideRangeInterval": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "#ff79353b"
                            : "#1d47ce4f",
                      },
                    },
                  }}
                  onChange={handleDateRangeChange}
                  onAccept={() => setshowCalendar(false)}
                />

                <IconButton
                  onClick={() => setshowCalendar(false)}
                  sx={{
                    zIndex: 20,
                    position: "relative",
                    marginLeft: "-40px",
                    maxHeight: "40px",
                    top: "11px",
                    right: "11px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Button
                onClick={handleDateRangeClick}
                disableRipple
                sx={{
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  float: "right",
                  position: "relative",
                  top: "-45px",
                  right: "86px",
                  height: "39px",
                  minWidth: "50px",
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  "&:hover": {
                    fontWeight: "bold",
                  },
                }}
              >
                ok
              </Button>
            </Box>
          </LocalizationProvider>
        </Box>
      )}
    </Box>
  )
}

export default DatePicker
